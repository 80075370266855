import update from 'immutability-helper';

import { updateState } from '../utils/reducerHelpers';

import {
  FETCH_WORKFLOW_REQUEST,
  FETCH_WORKFLOW_SUCCESS,
  FETCH_WORKFLOW_FAILURE,
  FETCH_WORKFLOWS_REQUEST,
  FETCH_WORKFLOWS_SUCCESS,
  FETCH_WORKFLOWS_FAILURE,
  FETCH_CHAIN_WORKFLOWS_REQUEST,
  FETCH_CHAIN_WORKFLOWS_SUCCESS,
  FETCH_CHAIN_WORKFLOWS_FAILURE,
  FETCH_PROJECT_WORKFLOWS_SUCCESS,
  FETCH_PROJECT_CHAINS_SUCCESS,
  REMOVED_NODES,
  CREATED_NODE,
  RECEIVED_WORKFLOWS,
  FETCH_ACTIVE_WORKFLOWS_REQUEST,
  FETCH_ACTIVE_WORKFLOWS_SUCCESS,
  FETCH_ACTIVE_WORKFLOWS_FAILURE,
  FETCH_PROJECT_CHAINS_AND_WORKFLOWS_SUCCESS,
  FETCH_WORKFLOW_BATCHES_REQUEST,
  FETCH_WORKFLOW_BATCHES_SUCCESS,
  FETCH_WORKFLOW_BATCHES_FAILURE,
  RELEASE_CHAIN_BATCH_SUCCESS,
  ARCHIVE_WORKFLOW_SUCCESS,
  FETCH_PROJECT_WORKFLOWSSCHEMA_REQUEST,
  FETCH_PROJECT_WORKFLOWSSCHEMA_SUCCESS,
  FETCH_PROJECT_WORKFLOWSSCHEMA_FAILURE,
  UPDATE_WORKFLOW_NAME_SUCCESS,
  UPDATE_WORKFLOW_BATCH_NOTIFICATION_REQUEST,
  UPDATE_WORKFLOW_BATCH_NOTIFICATION_SUCCESS,
  UPDATE_WORKFLOW_BATCH_NOTIFICATION_FAILURE
} from '../constants/actionTypes';

const initialState = {
  isFetching: false,
  hasFetched: false,
  items: {},
  workflowItems: null,
};

export default function ( state = initialState, action ) {
  switch ( action.type ) {
    case FETCH_WORKFLOW_REQUEST:
      return updateState.fetchRequest( state, false );
    case FETCH_WORKFLOW_SUCCESS:
      return updateState.fetchSuccess( state, [ action.workflow ] );
    case FETCH_WORKFLOW_FAILURE:
      return updateState.fetchFailure( state );

    case FETCH_WORKFLOWS_REQUEST:
    case FETCH_CHAIN_WORKFLOWS_REQUEST:
      return updateState.fetchRequest( state );
    case FETCH_WORKFLOWS_SUCCESS:
    case FETCH_CHAIN_WORKFLOWS_SUCCESS:
      return updateState.fetchSuccess( state, action.workflows );
    case FETCH_WORKFLOWS_FAILURE:
    case FETCH_CHAIN_WORKFLOWS_FAILURE:
      return updateState.fetchFailure( state );

    case FETCH_ACTIVE_WORKFLOWS_REQUEST:
      return updateState.fetchRequest( state, false );
    case FETCH_ACTIVE_WORKFLOWS_SUCCESS:
      return updateState.fetchSuccess( state, action.workflows );
    case FETCH_ACTIVE_WORKFLOWS_FAILURE:
      return updateState.fetchFailure( state );

    case FETCH_PROJECT_WORKFLOWS_SUCCESS:
    case FETCH_PROJECT_CHAINS_AND_WORKFLOWS_SUCCESS:
      return updateState.fetchSuccess( state, action.workflows );

    // Fetch all project workflows with schema
    case FETCH_PROJECT_WORKFLOWSSCHEMA_REQUEST: {
      return updateState.fetchRequest( state );
    }
    case FETCH_PROJECT_WORKFLOWSSCHEMA_SUCCESS: {
      return updateState.fetchSuccess( state, action.workflows );
    }
    case FETCH_PROJECT_WORKFLOWSSCHEMA_FAILURE: {
      return updateState.fetchFailure( state );
    }

    case RECEIVED_WORKFLOWS:
    case FETCH_PROJECT_CHAINS_SUCCESS:
      const workflows = {};
      action.workflows.forEach( workflow => {
        const prevWorkflow = { ...state[ workflow.id ] };
        workflows[ workflow.id ] = Object.assign( {}, prevWorkflow, workflow );
      } );
      return Object.assign( {}, state, workflows );

    case CREATED_NODE: {
      // If node is not plugin node add chainId to it
      if(!action.node.operation.hasOwnProperty('plugin')) {
        const nodeIsWorkflow = !action.node.operation.startup && action.node.operation.swfWorkflow.entityId;
        if (!!nodeIsWorkflow) {
          return update(state, {
            items: { [action.node.operation.swfWorkflow.entityId]: { $merge: { chainId: action.chainId } } }
          });
        }
      }
      return state;
    }
    case REMOVED_NODES: {
      let updatedWorkflows = {};
      action.nodes.filter(n => !n.operation.plugin).forEach(node => {
        let updatedWorkflow = Object.assign({}, state.items[node.operation.swfWorkflow.entityId]);
        delete updatedWorkflow.chainId;
        updatedWorkflows[ node.operation.swfWorkflow.entityId ] = updatedWorkflow;
      } );
      return update( state, { items: { $merge: updatedWorkflows } } );
    }
    case FETCH_WORKFLOW_BATCHES_REQUEST:
      return updateState.fetchChildrenRequest( state, action.workflowId, 'batches' );
    case FETCH_WORKFLOW_BATCHES_SUCCESS:
      return updateState.fetchChildrenSuccess( state, action.workflowId, 'batches' );
    case FETCH_WORKFLOW_BATCHES_FAILURE:
      return updateState.fetchChildrenSuccess( state, action.workflowId, 'batches' );
    case RELEASE_CHAIN_BATCH_SUCCESS:
      return state;
    case ARCHIVE_WORKFLOW_SUCCESS:
      return updateState.updateSuccess( state, action.workflowId, {
        archived: action.result.archived ? 1 : 0,
        state: action.result.archived ? 'archived' : 'inactive'
      } );
    case UPDATE_WORKFLOW_NAME_SUCCESS:
      return updateState.updateSuccess( state, action.workflowId, { name: action.name } );
    case UPDATE_WORKFLOW_BATCH_NOTIFICATION_REQUEST:
      return updateState.updateRequest( state, action.workflowId );
    case UPDATE_WORKFLOW_BATCH_NOTIFICATION_SUCCESS:
      return updateState.updateSuccess( state, action.workflowId, { batchNotification: action.batchNotification } );
    case UPDATE_WORKFLOW_BATCH_NOTIFICATION_FAILURE:
      return updateState.updateFailure( state, action.workflowId );
    default:
      return state;
  }
}
