export const ledgerState = {
  amount: '',
  invoiceId: '',
  description: '',
  amountError: false,
  invoiceError: false,
  descriptionError: false,
  transactionFee: '',
  transactionFloor: 0,
  applyTransactionFloor: false,
};

export const selectedCompanyState = {
  adminSeats: 1,
  contributorSeats: 0,
};

export const editPlan = {
  transactionFeeRate: 0,
  minimumTransactionFee: 0,
  privateContributorsRespectMinimum: false,
};
