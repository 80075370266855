import React from 'react';

import Label from './Label';

export default ({label, options, handleChange, value, hideDefault, wrapOutputValue}) => {
  return (
    <div style={{marginBottom: 16}}>
      {label && <Label label={label}/>}
      <select
        style={{
          height: 32,
          paddingTop: 0,
          paddingBottom: 0
        }}
        value={value}
        onChange={e => handleChange(e.target.value)}>
        {!hideDefault && <option value="">Please Select</option>}
        {options.map((o, i) => (
          <option value={wrapOutputValue ? '${' + o.value +'}' : o.value} key={i}>{o.label}</option>
        ))}
      </select>
    </div>
  );
};
