import React from 'react';
import PropTypes from 'prop-types';

import colors from '../styles/colors';
import { Label } from './type';

/**
 * IconCount
 * Used to display a small button with a icon and a count.
 *   - Does not handle clicks, wrap it in a handler to do so.
 *   - If count is greater than 0 the icon will be the sites primary color
 */

const IconCount = ({ icon, count }) => {
  return (
    <div style={styles.main}>
      <i className={`fa fa-${icon}`} style={styles.icon(count)}/>
      {!!count && <Label>{count}</Label>}
    </div>
  );
};

/* Styles */
const styles = {
  main: {
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    ':hover': {
      background: '#FBFBFB'
    }
  },
  icon: count => ({
    marginRight: count > 0 ? 10 : 0,
    fontSize: 16,
    color: count > 0 ? colors.primary() : '#3B403E',
  }),
};

/* PropTypes */
IconCount.propTypes = {
  /* A font awesome icon */
  icon: PropTypes.string.isRequired,
  /* The number to display */
  count: PropTypes.number
};

export default IconCount;
