import React from 'react';
import Radium from 'radium';
import Dropzone from 'react-dropzone';

import Settings from '../Settings';
import Input from '../../Form/Input';
import SupportRoleOptions from './SupportRoleOptions';

class Image extends React.Component {
  componentDidMount() {
    if (this.props.selected) {
      this.inline.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.selected && this.props.selected) {
      this.inline.focus();
    }
  }

  onDrop(files) {
    this.props.updateElement({src: null, preview: files[0].preview});
    if (files[0]) {
      this.getSignedRequest(files[0]);
    }
  }

  getSignedRequest(file) {
    let xhr = new XMLHttpRequest();
    xhr.open('GET', '/api/inbox/get-temporary-credentials?fileName=' + Date.now() + file.name + '&fileType=' + file.type);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          let response = JSON.parse(xhr.responseText);
          this.uploadFile(file, response.signedRequest, response.url);
        } else {
          console.log('error', xhr);
        }
      }
    };
    xhr.send();
  }

  uploadFile(file, signed_request, url) {
    let xhr = new XMLHttpRequest();
    xhr.open('PUT', signed_request);
    xhr.setRequestHeader('x-amz-acl', 'public-read');
    xhr.onload = () => {
      if (xhr.status === 200) {
        this.props.updateElement({src: url});
        this.setState({
          src: url,
          preview: null
        });
      }
    };
    xhr.send(file);
  }

  render() {
    const {data = {}, selected, updateElement, handleSelectInput, isSupportRole} = this.props;
    return (
      <div style={[styles.main]}>
        {(!data.src && !data.preview) ?
          <Dropzone
            onDrop={files => this.onDrop(files)}
            style={selected ? styles.dropzoneSelected : styles.dropzone}
            multiple={false}
            disableClick={!selected}>
            <div style={styles.dropzoneBody}>
              <i
                style={styles.uploadIcon}
                className={`fa fa-${selected ? 'upload' : 'picture-o'}`}
              />
              {selected && <div style={styles.uploadTitle}>Drag & Drop</div>}
              {selected &&
              <div style={styles.uploadSubtitle}>
                image here, or&nbsp;
                <span style={styles.uploadLink}>browse</span>
              </div>
              }
            </div>
          </Dropzone>
          :
          <img style={styles.image} src={data.src || data.preview}/>
        }
        <div style={styles.imageFooter}>
          <div style={styles.caption}>{data.caption}</div>
          {data.preview && selected &&
          <div onClick={() => updateElement({preview: null, src: ''})} style={styles.smallBtn}>
            Remove Image
          </div>
          }
        </div>

        <Settings show={selected} customHeight="auto">
          <Input
            ref={ele => this.inline = ele}
            value={data.src}
            disabled={data.preview}
            label="Image URL"
            onChange={src => updateElement({src})}
            onFocus={() => handleSelectInput('src')}
          />
          <div>
            <div style={{display: 'flex'}}>
              <div style={{marginRight: 16}}>
                <Input
                  label="Max Width"
                  value={data.width}
                  onChange={width => updateElement({width})}
                  maxWidth={64}
                  placeholder="Width"
                />
              </div>
              <div>
                <Input
                  label="Max Height"
                  value={data.height}
                  onChange={height => updateElement({height})}
                  maxWidth={64}
                  placeholder="Height"
                />
              </div>
              {}
              <div>
                <label style={{marginBottom: 16, position: 'relative', left: 10, top: 23}}>
                  <input
                    style={{ position: 'relative', top: '2' }}
                    type="checkbox" 
                    checked={data.showFullImage}
                    onChange={e => updateElement({showFullImage: e.target.checked})}
                  />
                  <span style={{fontSize: 13, fontWeight: 600}}>Allow full preview</span>
                </label>
              </div>
            </div>
          </div>
          <Input
            value={data.caption}
            label="Image Caption"
            onChange={caption => updateElement({caption})}
            onFocus={() => handleSelectInput('caption')}
          />
          {isSupportRole &&
          <SupportRoleOptions
            data={data}
            updateElement={updateElement}
            handleSelectInput={handleSelectInput}
          />
          }
        </Settings>
      </div>
    );
  }
}

const styles = {
  main: {
    paddingTop: 16
  },
  dropzone: {
    border: '2px dashed rgba(0,0,0,0.2)',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 32,
    paddingBottom: 32,
    color: '#b3b3b3',
    transition: 'all 0.2s ease',
    height: 116,
    position: 'relative',
    zIndex: -1,
  },
  dropzoneSelected: {
    border: '2px dashed rgba(0,0,0,0.2)',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 32,
    paddingBottom: 32,
    color: '#b3b3b3',
    transition: 'all 0.2s ease',
    height: 186,
    zIndex: 1,
  },
  dropzoneBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  uploadIcon: {
    fontSize: 48
  },
  uploadTitle: {
    textTransform: 'uppercase',
    fontWeight: 800,
    fontSize: 32,
    marginTop: 8,
    marginBottom: 16
  },
  uploadSubtitle: {
    fontSize: 14
  },
  uploadLink: {
    opacity: 0.8,
    textDecoration: 'underline'
  },

  image: {
    width: '100%'
  },

  imageFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 8
  },
  caption: {
    fontSize: 12,
    fontWeight: 600,
    fontStyle: 'italic'
  },
  smallBtn: {
    background: '#33aab9',
    color: 'white',
    padding: '4px 8px',
    borderRadius: 12,
    fontSize: 12
  }
};

export default Radium(Image);
