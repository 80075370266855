import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';

import colors from '../styles/colors';
import { Attention } from './type';

/**
 * PrimaryButton
 * Main CTA on a page
 */
const PrimaryButton = ({ children, disabled, handleClick, loading }) => (
  <div  className={css(styles.button, disabled && styles.disabled)}>
    {loading ? (
        <Attention
          color={!disabled ? 'white' : 'lighter'}>
          <i className="fa fa-spinner fa-spin"/>
        </Attention>
      ) : (
        <Attention
          color={!disabled ? 'white' : 'lighter'}
          handleClick={!disabled && handleClick}>
          {children}
        </Attention>
      )
    }
  </div>
);

const styles = StyleSheet.create({
  button: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 56,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 8,
    background: colors.primary(),
  },
  disabled: {
    background: colors.background,
  }
});

/* PropTypes */
PrimaryButton.propTypes = {
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired, // Label on button
};

PrimaryButton.defaultProps = {
  loading: false,
};

export default PrimaryButton;