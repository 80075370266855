// Packages
import Clipboard from 'clipboard';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Actions
import { fetchChains } from '../../actions/chains';
import { fetchWorkflows } from '../../actions/workflows';

// Components and Containers
import Filter from '../../components/Filter';
import InlineButton from '../../components/InlineButton';
import InlineList from '../../components/InlineList';
import Input from '../../components/Input';
import { Attention, Caption, Content, Label } from '../../components/type';
import RadioCheckbox from '../../components/RadioCheckbox';
import LoadState from '../../containers/LoadState';
import Panel from '../../containers/Panel';

// Misc
import colors from '../../styles/colors';
import objectToArray from '../../utils/_objectToArray';
import { idLookup, overview } from './Constants';

new Clipboard('.copy');

class IdLookup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedName: null,
      selectedGUID: null,
      value: '',
      chain: false,
      copying: false,
      selectedWebhookOption: this.props.webhookEnabled,
      webhookEnabled: false,
      webhookOption: 'disable'
    };
    this.handleSelectChain = this.handleSelectChain.bind(this);
    this.handleSelectWorkflow = this.handleSelectWorkflow.bind(this);
    this.copy = this.copy.bind(this);
  }
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchWorkflows());
    dispatch(fetchChains(false));
  }

  handleWebhookEnabling(webhookEnabled) {
    let webhookOption = '';
    let newWebhookEnabled = false;
    if(!!webhookEnabled) {
      newWebhookEnabled = true;
      webhookOption = 'specific';
    } else if (webhookEnabled === undefined) {
      newWebhookEnabled = false;
      webhookOption = 'default';
    } else {
      newWebhookEnabled = false;
      webhookOption = 'disable';
    }
    this.setState({webhookOption, webhookEnabled: newWebhookEnabled});
  }

  handleSelectChain(id) {
    const { chains, chainWebhooks } = this.props;
    const chain = chains.find(chain => chain.id === id);
    const chainWebhook = chainWebhooks.find(chain => chain.chainId === id) || '';
    this.setState({
      selectedName: chain.name,
      selectedGUID: id,
      chain: true,
      value: chainWebhook.url || '',
    }, this.handleWebhookEnabling(chainWebhook.webhookEnabled)
    );
  }

  handleSelectWorkflow(id) {
    const { workflowWebhooks, workflows } = this.props;
    const workflow = workflows.find(wf => wf.id === parseInt(id));
    const workflowWebhook = workflowWebhooks.find(wf => wf.workflowId === workflow.workflowId) || '';
    this.setState({
      selectedName: workflow.name,
      selectedGUID: workflow.workflowId,
      chain: false,
      value: workflowWebhook.url || '',
      copying: false,
    }, this.handleWebhookEnabling(workflowWebhook.webhookEnabled),
    );
  }

  copy() {
    this.setState({ copying: false });
  }

  render() {
    const {
      chains,
      workflows,
      updating,
      noApiUser,
      updateWorkflowOrChainWebhook,
    } = this.props;
    const {
      selectedName,
      selectedGUID,
      chain,
      value,
      launched,
      copying,
      webhookEnabled,
      webhookOption,
    } = this.state;

    return (
      <div style={{ marginTop: 40 }}>
        <Panel title={idLookup.guidTitle}>
          {!noApiUser ?
            <div>
              {(!chains.length && !workflows.length) ?
                <LoadState /> :
                <div style={{
                  display: 'flex',
                  margin: 20,
                  paddingBottom: 20,
                  borderBottom: `1px solid ${colors.divider}`
                }}>

                  <div style={{ marginRight: 75 }}>
                    <Filter
                      name="Workflow"
                      alignMenu="left"
                      itemType="Workflow Name"
                      searchable
                      options={workflows.filter(workflow => {
                        return workflow.archived.toString() !== '1' && workflow.launched !== null;
                      }).map((workflow, i) => ({
                        key: { i },
                        label: workflow.name,
                        value: workflow.id.toString()
                      }))}
                      handleSelect={this.handleSelectWorkflow}
                    />
                  </div>

                  <div>
                    <Filter
                      name="Workflow Chain"
                      alignMenu="left"
                      itemType="Chain Name"
                      searchable
                      options={chains.map(chain => ({
                        label: chain.name,
                        value: chain.id
                      }))}
                      handleSelect={this.handleSelectChain}
                    />
                  </div>
                </div>
              }
            </div>
            :
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              padding: 20
            }}>
              <Caption>{overview.noApiUser}</Caption>
            </div>
          }

          {!!selectedGUID &&
            <div style={{ paddingBottom: 25 }}>
              <div style={{ margin: 20, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: 120, marginRight: 20 }}>
                  <Label color="light">{chain ? idLookup.workflowChain : idLookup.workflow}</Label>
                </div>
                <div>
                  <Attention>{selectedName}</Attention>
                </div>
              </div>

              <div style={{ margin: 20, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: 120, marginRight: 20 }}>
                  <Label color="light">{idLookup.guid}</Label>
                </div>
                <div style={{ width: 350, marginRight: 20 }}>
                  <Content>{selectedGUID}</Content>
                </div>
                <div className='copy' data-clipboard-text={selectedGUID}>
                  <InlineButton
                    handleClick={() => {
                      this.setState({ copying: true });
                      setTimeout(this.copy, 500);
                    }}
                    loading={copying}>{idLookup.copy}</InlineButton>
                </div>
              </div>

              <div style={{ margin: 20, display: 'flex', alignItems: 'center' }}>
                <InlineList justify='space-between'>
                  <div style={{ display: 'flex' }}>
                    {overview.webhookOptions(chain ? 'Chain' : 'Workflow').map((option, i) => ( 
                      <div style={{ marginRight: 10}} key={i}>
                      <RadioCheckbox
                        disabled={false}
                        checked={webhookOption === option.value}
                        id="disableWebhook"
                        value={option.value}
                        label={option.caption}
                        type="radio"
                        right={true}
                        handleChange={(val) => {
                          let updateWebhook = val === 'disable' || val === 'default';
                          this.setState({ webhookOption: val, webhookEnabled: !updateWebhook }, () => {
                            if(val === 'disable') {
                              updateWorkflowOrChainWebhook(selectedGUID, value, chain, false);
                            } else if (val === 'default') {
                              updateWorkflowOrChainWebhook(selectedGUID, '', chain);
                            }
                          });
                        }}
                      />
                      </div>
                    ))}
                  </div>
                </InlineList>
              </div>

              { webhookEnabled &&
              <div style={{ margin: `20 20 0 20`, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: 120, marginRight: 20 }}>
                  <Label color="light">{idLookup.webhook}</Label>
                </div>
                <div style={{ width: 350, marginRight: 20 }}>
                  <Input
                    height={35}
                    value={value}
                    handleChange={value => this.setState({ value })}
                    disabled={launched || !webhookEnabled} />
                </div>

                <div style={{ display: 'flex' }}>
                  <InlineButton
                    handleClick={() => updateWorkflowOrChainWebhook(selectedGUID, value, chain, webhookEnabled)}
                    loading={updating}
                    disabled={!value}>{overview.save}</InlineButton>
                </div>
              </div>
              }
            </div>
          }
        </Panel>
      </div>
    );
  }
}

IdLookup.propTypes = {
  chainWebhooks: PropTypes.array,
  workflowWebhooks: PropTypes.array,
  updateWorkflowOrChainWebhook: PropTypes.func,
  updating: PropTypes.bool,
  companyWebhook: PropTypes.string,
};

function select(state) {
  return {
    workflows: objectToArray(state.workflows.items),
    chains: objectToArray(state.chains.items),
  };
}

export default connect(select)(IdLookup);