import React from 'react';
import PropTypes from 'prop-types';
import colors from '../styles/colors';

const Toggle = ({toggle, handleToggle, disabled}) => (
  <div
    style={
      Object.assign(
        {}, styles.toggleContainer(disabled), toggle && styles.toggleOn, toggle && {justifyContent: 'flex-end'}
      )
    }
    onClick={!disabled ? handleToggle : undefined}>
    <div style={Object.assign({}, styles.button, toggle && styles.buttonOn)} />
  </div>
);

const styles = {
  toggleContainer: (disabled) => ({
    display: 'flex',
    alignItems: 'center',
    height: 24,
    width: 36,
    borderRadius: 13,
    cursor: 'pointer',
    border: `3px solid ${colors.dark}`,
    backgroundColor: colors.white,
    opacity: disabled ? 0.5 : 1,
  }),
  toggleOn: {
    border: `3px solid ${colors.positive}`,
    backgroundColor: colors.positive
  },
  button: {
    background: 'white',
    border: `3px solid ${colors.dark}`,
    height: 24,
    width: 24,
    borderRadius: '50%',
    marginLeft: -3
  },
  buttonOn: {
    border: `3px solid ${colors.positive}`,
    marginRight: -3,
  },
};

/* PropTypes */
Toggle.propTypes = {
  toggle: PropTypes.bool,
  handleToggle: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Toggle;