import React from 'react';
import PropTypes from 'prop-types';

import Toast from './Toast';

/**
 * ToastContainer
 */
const ToastContainer = ({ toasts, handleClear }) => (
  <div style={styles.toastContainer}>
    {toasts.map((toast, i) => (
      <div key={i} style={styles.toast}>
        <Toast {...toast} handleClear={() => handleClear(i)}/>
      </div>
    ))}
  </div>
);

/* Styles */
const styles = {
  toastContainer: {
    position: 'fixed',
    top: 16,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  toast: {
    width: 480,
    marginBottom: 16,
  },
};

/* PropTypes */
ToastContainer.propTypes = {
  toasts: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['success', 'error']),
  })).isRequired,
  handleClear: PropTypes.func.isRequired,
};

export default ToastContainer;
