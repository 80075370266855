import React, {Component} from 'react';

import colors from '../../../styles/colors';
import IconButton from '../../../components/IconButton';
import {Attention, Label} from '../../../components/type';
import InputOutput from '../../../components/InputOutput';
import Menu from '../../../components/Menu';

class InputGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSection: true,
      openAllMenu: false,
      allSelected: false,
    };
  }

  componentDidMount() {
    let allSelected = true;
    this.props.inputs.forEach(out => {
      if(out.export === false) {
        allSelected = false;
      }
    });
    this.setState({allSelected});
  }

  componentWillReceiveProps(nextProps) {
    let allSelected = true;
    nextProps.inputs.forEach(out => {
      if(out.export === false) {
        allSelected = false;
      }
    });
    this.setState({allSelected});
  }

  render() {
    const { groupTitle, inputs, handleClickCell, handleResetGroup, handleSelectAll, handleUnSelectAll } = this.props;
    const { openSection, openAllMenu, allSelected } = this.state;
    return (
      <div>
        <div style={styles.headerContainer}>
          <div style={styles.centerFlex}>
            <div style={{marginLeft: -25, paddingRight: 5}}>
              <IconButton
                smallIcon={true}
                icon={openSection ? 'chevron-up' : 'chevron-down'}
                handleClick={() => this.setState({openSection: !openSection})} />
            </div>
            <div>
              <div style={styles.menuAction} onClick={() => this.setState({openAllMenu: !openAllMenu})}>
                <div style={{padding: '2 8 4 8'}}>
                  <input
                    style={{height: 12}}
                    type="checkbox"
                    checked={allSelected}
                    readOnly={true} />
                </div>
                <span style={styles.checkboxContainer}>
                <IconButton icon={openAllMenu ? 'caret-up' : 'caret-down'} />
                </span>
              </div>
              {openAllMenu &&
                <div style={styles.menu}>
                  <Menu
                    options={[
                      {label: <div><strong>Select All </strong> inputs</div>, value: 'selectAll'},
                      {label: <div><strong>Unselect All </strong> inputs</div>, value: 'unselectAll'}
                      ]}
                    handleSelect={val => {
                      if(val === 'selectAll') {
                        handleSelectAll('inputs');
                      } else {
                        handleUnSelectAll( 'inputs' );
                      }
                      this.setState({openAllMenu: false});
                    }} />
                </div>
              }
            </div>
            <Attention color="dark">{groupTitle}</Attention>
          </div>
          <div>
            <IconButton
              icon="refresh"
              underline={true}
              handleClick={() => handleResetGroup('inputs')}>
              Reset
            </IconButton>
          </div>
        </div>
        {openSection &&
        <div style={{paddingLeft: 24, borderTop: `1px solid ${colors.border}`}}>
          <div style={styles.columnHeaders}>
            <div style={{ flex: .5, marginRight: 4 }}>
              <Label color="light">
                Export
              </Label>
            </div>
            <div style={{ flex: 4, margin: `0 4` }}>
              <Label color="light">
                Field Name
              </Label>
            </div>
            <div style={{ flex: 4, marginLeft: 4 }}>
              <Label color="light">
                Workflow Name
              </Label>
            </div>
          </div>
          {inputs.map( n => {
            return (
              <InputOutput
                key={n.name}
                name={n.name}
                id={n.nodeId}
                inputOrOutput="input"
                workflowName={n.wfName}
                selected={n.export}
                handleSelect={handleClickCell}
              />
            );
          })}
        </div>
        }
      </div>
    );
  };
}

const styles = {
  columnHeaders: {
    display: 'flex',
    paddingTop: 20,
  },
  menu: {
    position: 'absolute',
    zIndex: 1,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 24,
  },
  centerFlex: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  menuAction: {
    display: 'flex',
    border: `1px solid ${colors.light}`,
    background: colors.shaded,
    borderRadius: 4,
    marginRight: 16
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    borderLeft: `1px solid ${colors.light}`,
    padding: 4
  },
};

export default InputGroup;