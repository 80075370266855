import React from 'react';

import Input from '../../Form/Input';
import InlineInput from '../../Form/InlineInput';
import Settings from '../Settings';
import SupportRoleOptions from './SupportRoleOptions';


class HiddenOutput extends React.Component {
  componentDidMount() {
    if (this.props.selected) {
      this.inline.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.selected && this.props.selected) {
      this.inline.focus();
    }
  }

  render() {
    const {data = {}, selected, updateElement, handleSelectInput, isSupportRole, outputError, handleChangeOutput} = this.props;
    return (
      <div>
        <div style={styles.label}>
          {/* Label */}
          <InlineInput
            ref={ele => this.inline = ele}
            value={data.label}
            selected={selected}
            handleChange={label => updateElement({label})}
            placeholder="Add a label"
            fontSize={16}
            fontWeight={800}
            disabled={!selected}
            onFocus={() => handleSelectInput('label')}
          />
        </div>

        {/* Textarea for Value */}
        <Input
          ref={ele => this.inline = ele}
          disabled={!selected}
          mockTextArea={true}
          placeholder="Add output value"
          value={data.value}
          onChange={value => updateElement({value})}
          onFocus={() => handleSelectInput('value')}
        />

        {/* Settings */}
        <Settings show={selected} customHeight="auto">
          <Input
            value={data.output}
            label="Output Name"
            onChange={output => {
              updateElement({output});
              handleChangeOutput(output);
            }}
            maxWidth={280}
            error={outputError}
          />
          {isSupportRole &&
          <SupportRoleOptions
            data={data}
            updateElement={updateElement}
            handleSelectInput={handleSelectInput}
          />
          }
        </Settings>
      </div>
    );
  }
}

const styles = {
  label: {
    marginBottom: 8
  },
};

export default HiddenOutput;