import React from 'react';
import ToggleDropper from '../../components/ToggleDropper';
import InlineList from '../../components/InlineList';
import Select from '../../components/Select';
import DatePicker from '../../components/DatePicker';
import { Label } from '../../components/type';
import colors from '../../styles/colors';

const FilterByDate = ({ searchData, toggle, handleToggle, updateSearchData }) => (
  <ToggleDropper
    handleToggle={() => handleToggle(!toggle)}
    toggle={toggle}
    label="FILTER BY DATE"
    disabled={!(searchData.workflowId || searchData.chainId)}
  >
    {searchData.dates.map((d, index) =>
      <div key={index}>
        <InlineList spacing={20}>
          <div style={{ flex: 1 }}>
            <Select
              defaultLabel="Select Date Type"
              height={45}
              disabled={false}
              value={d.dateType}
              options={[
                { label: 'Created', value: 'createdOn', disabled: false },
                { label: 'Released', value: 'releasedOn', disabled: searchData.status.includes('staged') },
                { label: 'Completed', value: 'completedOn', disabled: searchData.status.includes('staged') || searchData.status.includes('open') || searchData.status.includes('canceled') },
                { label: 'Canceled', value: 'canceledOn', disabled: searchData.status.includes('staged') || searchData.status.includes('open') || searchData.status.includes('completed') },
              ]}
              handleSelect={value => updateSearchData('dateType', value, index)}
            />
          </div>
          <div style={{ flex: 2 }}>
            <InlineList spacing={20}>
              <label style={{ marginBottom: 0 }}>
                <InlineList>
                  <DatePicker
                    date={d.start && d.start}
                    handleDateChange={(date) => updateSearchData('start', date, index)}
                    selectsStart={true}
                    startDate={d.start}
                    endDate={d.end}
                    disabled={!d.dateType}
                    placeholderText="MM-DD-YYYY"
                  />
                  <i style={styles.calendarIcon} className="fa fa-calendar-o" />
                </InlineList>
              </label>
              <Label>TO</Label>
              <label style={{ marginBottom: 0 }}>
                <InlineList>
                  <DatePicker
                    date={d.end && d.end}
                    handleDateChange={(date) => updateSearchData('end', date, index)}
                    selectsEnd={true}
                    startDate={d.start}
                    endDate={d.end}
                    disabled={!d.dateType}
                    placeholderText="MM-DD-YYYY"
                  />
                  <i style={styles.calendarIcon} className="fa fa-calendar-o" />
                </InlineList>
              </label>
            </InlineList>
          </div>
        </InlineList>
      </div>
    )}
  </ToggleDropper>
);

const styles = {
  calendarIcon: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: 45,
    width: 40,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    color: 'white',
    backgroundColor: colors.light,
    fontSize: 16,
    position: 'relative',
    left: -4,
    cursor: 'pointer',
  },
};

export default FilterByDate;