import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Link } from 'react-router-dom';
import moment from 'moment';
import qs from '../assets/js/utils/query-string-tools';

import colors from '../styles/colors';
import { Label, Caption } from '../components/type';

// Nav -> Section -> Link
const nav = (isAdmin) => {
  const val = moment().subtract(3, 'months').format( 'YYYY-MM-DD' ) + '|' + moment().format( 'YYYY-MM-DD' );
  const taskSearchHref = `/search/tasks?${qs.setParam('submitted', [val], true)}`;
  const workflowItemSearchHref = `/search/workflow-items?${qs.setParam('createdon', [val], true)}`;
  const links = [{
    label: 'Work',
    icon: 'briefcase',
    href: '/projects',
    link: true,
    links: [
      { label: 'Projects', href: '/projects', link: true },
      { label: 'Workflows', href: '/workflows', link: true },
      { label: 'Workflow Chains', href: '/chains', link: true },
      { label: 'Workflow Item Search', href: workflowItemSearchHref },
      { label: 'Task Search', href: taskSearchHref },
      { label: 'Advanced Export', href: '/advanced-export' },
    ]
  },
  {
    label: 'Workforce',
    icon: 'users',
    href: '/teams',
    links: [
      { label: 'Teams', href: '/teams' },
      { label: 'Contributor Monitoring', href: '/company/contributors' },
      { label: 'Contributor Levels', href: '/contributor-levels' },
    ]
  },
  {
    label: 'Reporting',
    icon: 'line-chart',
    href: '/reports',
    links: [
      { label: 'My Reports', href: '/reports' },
      { label: 'Cost Reports', href: '/reports/84b1f4cb-1104-4595-80f7-0ea30c33c475' },
      { label: 'Contributor Reports', href: '/reports/a323dc5a-a5e6-42fe-ac05-174e322e287c' },
      { label: 'Velocity Reports', href: '/reports/7d576c03-1ebf-44f8-bc01-bd3d54a897e9' }
    ]
  }];
  if(isAdmin) {
    links.push({
      label: 'Admin',
      icon: 'cogs',
      link: false,
      links: [
        { label: 'Client Management', href: '/manage/company/support', link: true },
        { label: 'Plugin Library', href: '/manage/plugins', link: true },
        { label: 'Account Management', href: '/manage/company', link: true },
        { label: 'Notification Center', href: '/manage/notification-center', link: true },
      ]
    });
  }
  return links;
};

/**
 * Nav
 * Side navigation used throughout the site
 */
const Nav = ({ isOpen, currentPathname, handleToggleOpen, isAdmin }) => {

  const development = window.location.hostname.includes('projects.dev');
  const local = window.location.hostname.includes('projects.local');
  const validation = window.location.hostname.includes('projects.validation');
  const dev = development || local || validation;
  const navLinks = nav(isAdmin);

  return (
    <div className={`${isOpen ? 'clearfix menu-open' : ''} ${css(styles.nav)}`}>
      <div className={css(styles.row, styles.navTitle)}>
        {/* Toggle */}
        <div
          id="primary-nav-toggle"
          className={css(styles.rowIcon, styles.navToggle, !isOpen && styles.navToggleClosed)}
          onClick={handleToggleOpen}>
          {isOpen ? '✖' : <i className="fa fa-bars" />}
        </div>
      </div>

      {/* Fixed Dashboard Section */}
      <Link
        to="/dashboard"
        className={css(
          styles.row,
          styles.rowLarge,
          ((currentPathname === '/dashboard' || currentPathname === '/') && isOpen) && styles.rowSelected
        )}>
        <Label color="secondary" hoverColor="white" className={css(styles.rowLabel)} isLink={true}>Dashboard</Label>
        <div className={css(styles.rowIcon, styles.dashboardTitle)}>
          <i className="fa fa-tachometer"/>
        </div>
      </Link>

      {navLinks.map((section, i) => (
        <div key={i} className={css(styles.section, !isOpen && styles.sectionClosed)}>
          <div className={css(styles.row, styles.rowMedium, styles.sectionLabel)}>
            <Label className={css(styles.rowLabel)} color="white">
              {section.label}
            </Label>
            <div className={css(styles.rowIcon)}><i className={`fa fa-${section.icon}`}/></div>
          </div>

          {section.links.filter(link => !link.devOnly || dev).map((link, i) => link.link ?
            <Link
              key={i}
              to={link.href}
              className={css(styles.row, currentPathname === link.href && styles.rowSelected)}>
              <Caption className={css(styles.rowLabel)} color="secondary" isLink={true}>
                {link.label}
              </Caption>
            </Link>
            :
            <div
              key={i}
              className={css(styles.row, currentPathname === link.href && styles.rowSelected)}>
              <Caption href={link.href} className={css(styles.rowLabel)} color="secondary">
                {link.label}
              </Caption>
            </div>
          )}
        </div>
      ))}

    </div>
  );
};

/* Styles */
const styles = StyleSheet.create({
  nav: {
    width: '100%', // Open width
    background: colors.dark,
    color: 'white',
    overflow: 'auto',
  },
  section: {
    marginBottom: 24,
    maxHeight: 400,
    overflow: 'hidden', // Hide the links when closed
    transition: 'max-height 0.2s ease'
  },
  sectionClosed: {
    maxHeight: 32 // This is the height of just the section title
  },
  row: {
    display: 'flex',
    height: 24,
    background: 'none',
    textDecoration: 'none', // Remove a tag underline
    transition: 'background 0.2s ease',
    ':hover': {
      background: colors.darker,
    }
  },
  rowLarge: {
    marginBottom: 20,
    marginTop: 26,
    ':hover': {
      background: 'none',
    },
  },
  rowMedium: {
    height: 32,
  },
  rowSelected: {
    background: colors.darker
  },
  rowLabel: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    boxSizing: 'border-box'
  },
  rowIcon: {
    width: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: 16.8,
  },
  dashboardTitle: {
    color: colors.secondary(),
  },
  navTitle: {
    height: 65,
    borderBottom: '1px solid #858484',
    ':hover': {
      background: 'initial',
    },
  },
  navToggle: {
    height: 65,
    width: 64,
    fontSize: 21,
    color: '#f4f5f5',
    background: '#858484',
    cursor: 'pointer',
    transition: 'background 0.2s ease',
    position: 'absolute',
    right: 0,
    ':hover': {
      background: colors.darker
    }
  },
  navToggleClosed: {
    background: colors.secondary(),
    color: colors.dark,
    ':hover': {
      background: colors.secondary()
    }
  },
  linkGroup: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  sectionLabel: {
    ':hover': {
      background: 'none',
    },
  },
});

Nav.propTypes = {
  currentPathname: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  handleToggleOpen: PropTypes.func.isRequired
};

export default Nav;
