import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from './Fields/Text';
import IntegerField from './Fields/Integer';
import CheckboxField from './Fields/Checkbox';
import RadioField from './Fields/Radio';
import BooleanField from './Fields/Boolean';
import DropdownField from './Fields/Dropdown';
import IconButton from '../../../components/IconButton';

export let fields = {
  'text': TextField,
  'integer': IntegerField,
  'checkbox': CheckboxField,
  'radio': RadioField,
  'boolean': BooleanField,
  'dropdown': DropdownField,
};

class FieldPreview extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { data, type, styles, index, updateChainPlugin, readOnly } = this.props;
    const AppropriateFieldToShow = fields[type];
    return (
      <div>
        <AppropriateFieldToShow
          readOnly={readOnly}
          key={index}
          index={index}
          data={data}
          styles={styles}
          updateChainPlugin={updateChainPlugin}
        />
        {(!readOnly && (type === 'checkbox' || type === 'dropdown' || type === 'radio')) &&
        <div style={{ marginBottom: 20 }}>
          <IconButton
            icon="plus-square"
            label="Add"
            handleClick={() => {
              !readOnly ?
              updateChainPlugin('typeSettings|eventOptions', ['addChild', type], index) :
                () => {};
            }}>
            <span style={{ textDecoration: 'underline !important' }}>Add</span>
          </IconButton>
        </div>
        }
      </div>
    );
  }
}

FieldPreview.propTypes = {
  index: PropTypes.number,
  data: PropTypes.array,
  styles: PropTypes.object,
  updateChainPlugin: PropTypes.func,
};

export default FieldPreview;

