import React from 'react';

import ProjectWorkflows from './ProjectWorkflows';
import ProjectChains from './ProjectChains';

const ChainsAndWorkflows = ({ projectId }) => {

  return (
    <div>
      <div>
        <ProjectWorkflows id={projectId}/>
      </div>

      <ProjectChains id={projectId}/>

    </div>
  );
};

export default ChainsAndWorkflows;
