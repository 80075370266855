import {
  FETCH_ALL_RELEASE_NOTES_REQUEST,
  FETCH_ALL_RELEASE_NOTES_SUCCESS,
  FETCH_ALL_RELEASE_NOTES_FAILURE,
  FETCH_RELEASE_NOTE_REQUEST,
  FETCH_RELEASE_NOTE_SUCCESS,
  FETCH_RELEASE_NOTE_FAILURE,
  FETCH_LATEST_RELEASE_NOTES_REQUEST,
  FETCH_LATEST_RELEASE_NOTES_SUCCESS,
  FETCH_LATEST_RELEASE_NOTES_FAILURE
} from '../constants/actionTypes';

import { updateState } from '../utils/reducerHelpers';

const initialState = {
  items: {},
  latestReleaseNotes: {},
  isFetching: false,
  hasFetched: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_RELEASE_NOTES_REQUEST:
      return updateState.fetchRequest(state);
    case FETCH_ALL_RELEASE_NOTES_SUCCESS:
      state.items = action.releaseNotes;
      return {
        ...state,
        isFetching: false,
        hasFetched: true,
      };
    case FETCH_ALL_RELEASE_NOTES_FAILURE:
      return updateState.fetchFailure(state);
    case FETCH_RELEASE_NOTE_REQUEST:
      return updateState.fetchRequest(state);
    case FETCH_RELEASE_NOTE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hasFetched: true,
        updateNote: action.releaseNote,
      };
    case FETCH_RELEASE_NOTE_FAILURE:
      return updateState.fetchFailure(state);
    case FETCH_LATEST_RELEASE_NOTES_REQUEST:
      return updateState.fetchRequest(state);
    case FETCH_LATEST_RELEASE_NOTES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hasFetched: true,
        latestReleaseNotes: action.releaseNotes
      };
    case FETCH_LATEST_RELEASE_NOTES_FAILURE:
      return updateState.fetchFailure(state);

    default:
      return state;
  }
}