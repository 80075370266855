import $ from 'jquery';

const headers = {
  'X-CSRF-TOKEN': $( 'meta[name="csrf-token"]' ).attr( 'content' )
};
const contentType = 'application/json; charset=utf-8';
const dataType = 'json';

$.ajaxSetup({headers, contentType, dataType});

export default $;