import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import numeral from 'numeral';

import { toggleModal } from '../../actions/app';
import { pauseWorkflowItems, resumeWorkflowItems } from '../../actions/workflows';
import { pauseChainBatchItems, resumeChainBatchItems } from '../../actions/chains';
import { PAUSE_ITEMS, RESUME_ITEMS } from '../../constants/modalTypes';
import Button from '../../components/Button';
import { Label } from '../../components/type';

class PauseItems extends Component {
  constructor( props ) {
    super( props );
    this.state = {};
  }

  getPauseAction(type, batch) {
    if(type === 'pause' && !batch.chainId) {
      return pauseWorkflowItems;
    }
    if(type === 'pause' && !!batch.chainId) {
      return pauseChainBatchItems;
    }
    if(type !== 'pause' && !batch.chainId) {
      return resumeWorkflowItems;
    }
    return resumeChainBatchItems;
  }

  pauseItems() {
    const { pauseType, batch, workflowId } = this.props;
    const id = !batch.chainId ? workflowId : batch.chainId;
    const modalType = pauseType === 'pause' ? PAUSE_ITEMS : RESUME_ITEMS;
    const pauseOrResume = this.getPauseAction(pauseType, batch);
    this.setState({ isPausing: true });

    this.props.dispatch(pauseOrResume(id, batch.id))
      .then(() => {
        this.setState({isPausing: false});
        this.props.dispatch(toggleModal(modalType));
      });
  }

  render() {
    const { pauseType, batch } = this.props;
    let itemCount = 0;
    if(pauseType === 'pause'){
      itemCount = batch.items.open;
    }else if(pauseType === 'resume') {
      itemCount = batch.items.paused;
    }

    return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Label>
          {numeral( itemCount ).format( '0,0' )} items will be {pauseType === 'pause' ? 'paused' : 'resumed'}
        </Label>
        <Label
          bottom={16}
          top={16}>
          Are you sure you want to continue?
        </Label>
        <Button
          disabled={this.state.isPausing}
          handleClick={() => this.pauseItems()}>
          {this.state.isPausing
            ? <i className="fa fa-spinner fa-spin"/>
            : <span>{pauseType === 'pause' ? 'Pause Open Items' : 'Resume Available Items'}</span>
          }
        </Button>
        <Label
          top={16}
          bottom={16}
          center={true}
          italic={true}>
          <span>All open items associated with this workflow will be {pauseType === 'pause' ? 'paused' : 'resumed'}.&nbsp;</span>
          <span>This process might take a little time depending on the number of items.</span>
        </Label>
        <Label
          underline={true}
          handleClick={() => this.props.dispatch(toggleModal(PAUSE_ITEMS))}>
          Nevermind! I don't want to {pauseType === 'pause' ? 'pause' : 'resume'} these items.
        </Label>
      </div>
    );
  }
}

PauseItems.propTypes = {
  batch: PropTypes.object,
};
PauseItems.defaultProps = {
  batch: {},
};

function select( state ) {
  const workflowId = state.app.modalMeta.workflowId;
  const pauseType = state.app.modalMeta.type;
  return {
    pauseType,
    workflowId: workflowId,
    batch: state.app.modalMeta.batch || {}
  };
}

export default connect( select )( PauseItems );
