import React from 'react';
import MoneyInput from 'react-currency-input';

import './currencyinput.css';

const CurrencyInput = ({value, handleUpdate, allowNegative, error}) => {
  return (
    <div className={`currency-input ${error ? 'input-error' : ''}`}>
      <MoneyInput
        value={value}
        onChangeEvent={handleUpdate}
        allowNegative={allowNegative}
        allowEmpty={true} />
    </div>
  );
};

export default CurrencyInput;