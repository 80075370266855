import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';

import Wrapper from '../../containers/Wrapper';
import LoadState from '../../containers/LoadState';
import { Heading, Subheading } from '../../components/type';
import SelectLabel from '../../components/SelectLabel';

import { fetchCompaniesList, fetchCompanyLedger } from '../../actions/company';
import objectToArray from '../../utils/_objectToArray';

import CurrentPlanStatic from './CurrentPlanStatic';
import CurrentPlanEdit from './CurrentPlanEdit';
import AddLedger from './AddLedger';

/**
 * Manage Company
 * Allow the OneSpace Account  to edit the Company Plan and Add/Remove funds from the account
 */

class ManageCompany extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      selectedCompanyId: '',
      selectedCompany: null,
      showStaticView: true,
      companyList: [],
    };
    this.handleSelect = this.handleSelect.bind( this );
  }

  componentDidMount() {
    this.props.dispatch( fetchCompaniesList() );
  }

  componentWillReceiveProps( nextProps ) {
    let companyList;
    const nextCompany = nextProps.companyList.find(company => company.companyId == this.state.selectedCompanyId);
    if ( this.props.companyList.length !== nextProps.companyList.length ) {
      companyList = nextProps.companyList.map( ele => {
        return {
          label: !!ele.name ? ele.name : '',
          value: ele.companyId,
        };
      } );
      this.setState( { companyList } );
    }
    if(nextCompany !== this.state.selectedCompany ) {
      this.setState({selectedCompany: nextCompany});
    }
  }

  handleSelect( id ) {
    let selectedCompany = this.props.companyList.find( company => company.companyId == id );
    this.setState( { selectedCompanyId: id, selectedCompany } );
    this.props.dispatch( fetchCompanyLedger( id ) );
  }

  render() {
    if ( this.props.isFetchingCompanies ) {
      return <LoadState/>;
    }
    const {
      selectedCompanyId,
      selectedCompany,
      showStaticView,
      companyList,
    } = this.state;
    const { ledger, isFetchingLedger, hasFetchedLedger, userRole } = this.props;

    return (
      <DocumentTitle title="Manage Companies | OneSpace Project Center">
        <div className="manage-company" style={{ marginBottom: 36 }}>
          <Wrapper>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 24 }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Heading color="dark">Welcome to Client Management</Heading>
                <hr />
                <div style={{ width: 660 }}>
                  <SelectLabel
                    value={selectedCompanyId}
                    label="Select a client to get started"
                    options={companyList}
                    handleSelect={id => this.handleSelect( id )}
                    searchable={true} />
                </div>
                {isFetchingLedger &&
                <LoadState />
                }
                {(!isFetchingLedger && hasFetchedLedger && showStaticView) &&
                  <CurrentPlanStatic
                    userRole={userRole}
                    selectedCompany={selectedCompany}
                    ledger={ledger}
                    handleEdit={() => this.setState( { showStaticView: false, } )} />
                }
                {!showStaticView &&
                <div>
                  <div>
                    <Subheading color="dark">{selectedCompany.name}</Subheading>
                    <hr />
                    <CurrentPlanEdit
                      selectedCompany={selectedCompany}
                      ledger={ledger}
                      handleUpdatePlan={() => this.setState({showStaticView: true})}
                      dispatch={this.props.dispatch}
                      isUpdating={this.props.isUpdatingLedger} />
                    <hr />
                    <AddLedger
                      selectedCompany={selectedCompany}
                      ledger={ledger}
                      handleAddLedger={() => this.setState({showStaticView: true})}
                      dispatch={this.props.dispatch}
                      isUpdating={this.props.isUpdatingLedger} />
                  </div>
                </div>
                }
              </div>
            </div>
          </Wrapper>
        </div>
      </DocumentTitle>
    );
  }
}

function select( state ) {
  const companyList = objectToArray( state.companies.items );
  const ledger = state.ledger;
  return {
    companyList,
    isFetchingCompanies: state.companies.isFetching,
    ledger: objectToArray( ledger.items )[ 0 ],
    isFetchingLedger: ledger.isFetching,
    hasFetchedLedger: ledger.hasFetched,
    isUpdatingLedger: ledger.isUpdating,
    userRole: state.app.role,
  };
}

export default connect( select )( ManageCompany );
