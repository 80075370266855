import {
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
} from '../constants/actionTypes';

export function signUp( key, fname, lname, password, confirmPassword, terms ) {
  return {
    types: [ SIGN_UP_REQUEST, SIGN_UP_SUCCESS, SIGN_UP_FAILURE ],
    fetchRoute: '/api/signup',
    fetchMethod: 'post',
    fetchBody: { key, fname, lname, password, confirmPassword, terms }
  };
}

export function login( username, password ) {
  return {
    types: [ LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE ],
    fetchRoute: '/login',
    fetchMethod: 'post',
    fetchBody: { username, password }
  };
}

export function forgotPassword( email ) {
  return {
    types: [ FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAILURE ],
    fetchRoute: '/api/send-reset',
    fetchMethod: 'post',
    fetchBody: { email }
  };
}