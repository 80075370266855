import React from 'react';
import PropTypes from 'prop-types';

import colors from '../styles/colors';
import { Label } from './type';

/**
 * ShadedTextTile
 * Small component used to display status of an item.
 * Good to use in an ItemList (spacing is usually 8)
 */
const ShadedTextTile = ( { label, href } ) => (
  <Label
    href={href}>
    <div style={styles.tileStyle}>
      {label}
    </div>
  </Label>
);

/* Styles */
const styles = {
  tileStyle: {
    background: colors.shaded,
    borderRadius: 4,
    minWidth: 64,
    textAlign: 'center',
    padding: '4px 8px',
  }
};

/* PropTypes */
ShadedTextTile.propTypes = {
  label: PropTypes.node.isRequired, // Name of the item
  href: PropTypes.string, // Optional link
};

export default ShadedTextTile;