import React from 'react';
import InlineSelect from '../../../../components/InlineSelect';
import { Label } from '../../../../components/type';

const Boolean = ({ data, updateChainPlugin, index, readOnly }) => (
  <div style={{marginBottom: 30}}>
    {data.map((d, idx) => {
      return (
        <div key={idx} style={{ width: 100 }}>
          <Label color="light">Value</Label>
          <InlineSelect
            disabled={readOnly}
            value={d.value}
            defaultLabel="Select"
            height={45}
            options={[
              {'label': 'True', 'value': true},
              {'label': 'False', 'value': false},
            ]}
            handleSelect={val => {
              updateChainPlugin('typeSettings|eventOptions', val, [index, idx]);
            }}
          />
        </div>
      );
    })}
  </div>
);

export default Boolean;

