import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Nav from '../containers/Nav';
import Footer from '../containers/Footer';

/**
 * Shell
 * View wrapper while logged in
 * Contains Navigation on the left and page content on right
 * Also contains Footer at the bottom
 */
class Shell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNavOpen: !JSON.parse(localStorage.getItem('navClosed')),
      isNavLocked: false,
      showNav: true,
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.toggleNavOpen = this.toggleNavOpen.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(e) {
    const distance = e.srcElement.body.scrollTop;
    const { isNavLocked } = this.state;
    if (!isNavLocked && distance >= 90) {
      this.setState({ isNavLocked: true });
    } else if (isNavLocked && distance < 90) {
      this.setState({ isNavLocked: false });
    }
  }

  toggleNavOpen() {
    localStorage.setItem('navClosed', this.state.isNavOpen);
    this.setNavOpen(!this.state.isNavOpen);
  }

  setNavOpen(isNavOpen = true) {
    this.setState({ isNavOpen });
  }

  render() {
    const { isNavOpen, isNavLocked } = this.state;
    const { userRole } = this.props;
    const isAdmin = userRole === 'onespace-support';

    return (
      <div style={styles.shell(isNavLocked, isNavOpen)}>
        <div style={styles.nav(isNavLocked, isNavOpen)}>
          <Nav
            isAdmin={isAdmin}
            isOpen={isNavOpen}
            currentPathname={this.props.location.pathname}
            handleToggleOpen={this.toggleNavOpen}
          />
        </div>
        <div style={styles.body}>
          <div style={styles.contentWrapper}>
            <div style={styles.content}>{this.props.children}</div>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

/* Shell Wrapper */
const styles = {
  shell: (locked, navOpen) => ({
    display: 'flex',
    flex: 1,
    boxSizing: 'border-box',
    paddingLeft: locked ? navOpen ? 256 : 64 : 0,
  }),
  nav: (locked, navOpen) => ({
    position: locked ? 'fixed' : 'relative',
    top: 0,
    left: 0,
    display: 'flex',
    height: locked ? '100vh' : 'initial',
    width: 256,
    marginLeft: navOpen ? 0 : -192,
    transition: 'margin-left 0.2s ease',
  }),
  body: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    position: 'relative',
    minHeight: 750,
  },
  contentWrapper: {
    display: 'block',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 64,
  },
};

/* PropTypes */
Shell.propTypes = {
  pathname: PropTypes.string,
  children: PropTypes.node.isRequired, // Page contents
};

/* Map State To Props */
function select(state) {
  return {
    userRole: state.app.role,
  };
}
export default connect(select)(Shell);
