import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import app from './app';
import batches from './batches';
import chains from './chains';
import projects from './projects';
import profile from './profile';
import users from './users';
import workflows from './workflows';
import workflowDefinitions from './workflow-definitions';
import companies from './companies';
import ledger from './ledger';
import teams from './teams';
import apiCredentials from './apiCredentials';
import releaseNotes from './releaseNotes';
import curatedTeams from './curatedTeams';
import chainPlugins from './chainPlugins';
import assignments from './assignments';
import pluginMetrics from './pluginMetrics';
import advancedExports from './advancedExports';

export default combineReducers({
  routing: routerReducer,
  app,
  batches,
  chains,
  projects,
  profile,
  users,
  workflows,
  workflowDefinitions,
  companies,
  ledger,
  apiCredentials,
  teams,
  releaseNotes,
  curatedTeams,
  chainPlugins,
  assignments,
  advancedExports,
  pluginMetrics,
});