import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import onClickOutside from 'react-onclickoutside';

import colors from '../styles/colors';
import InlineSearch from './InlineSearch';
import InlineButton from './InlineButton';
import { Label } from './type';
import LoadState from '../containers/LoadState';

/**
 * ItemList
 * A searchable list
 */
class ItemList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
    };
    this.setFilter = this.setFilter.bind(this);
    this.checkKey = this.checkKey.bind(this);
  }

  handleClickOutside(e) {
    if (this.props.handleClose) {
      this.props.handleClose(e);
    }
  }

  checkKey({ key }) {
    const { selectedItemIndex } = this.state;
    if (key === 'ArrowUp') {
      return this.setSelectedItem(selectedItemIndex - 1);
    }
    if (key === 'ArrowDown') {
      return this.setSelectedItem(selectedItemIndex + 1);
    }
    if (key === 'Enter') {
      return this.props.handleSelect(this.props.items[selectedItemIndex].value);
    }
  }

  setSelectedItem(selectedItemIndex = this.state.selectedItemIndex) {
    const itemsLength = this.getItemsFiltered().length;

    if (selectedItemIndex >= itemsLength) {
      selectedItemIndex = 0;
    }
    if (selectedItemIndex < 0) {
      selectedItemIndex = itemsLength - 1;
    }

    this.setState({ selectedItemIndex });
  }

  setFilter(filter) {
    this.setState({ filter }, this.setSelectedItem);
  }

  getItemsFiltered() {
    const filter = this.state.filter;
    const doesItemIncludeFilter = (item) => {
      if (item.outside) {
        return false;
      }
      return item.fixed || (item.staticLabel || item.label).toString().toLowerCase().includes(filter.toLowerCase());
    };
    return this.props.items.filter(doesItemIncludeFilter);
  }

  render() {
    const { 
      hideSearch, 
      handleSelect, 
      itemType, 
      buttonText, 
      handleButtonClick, 
      handleClose, 
      disabledButton, 
      smallList, 
      loading, 
      padding, 
      shadow,
      border,
    } = this.props;
    const { filter, selectedItemIndex } = this.state;
    const outsideItems = this.props.items.filter(item => item.outside);
    const itemsFiltered = this.getItemsFiltered();

    return (
      <div
        style={{
          padding: (padding || padding === 0) ? padding : 16,
          boxShadow: !shadow ? 'none' : '0 5px 10px rgba(0,0,0,.2)',
          border: !border ? 'none' : `1px solid ${colors.border}`,
          borderRadius: !border ? 'none' : 5,
        }}
        className={css(styles.itemList)}>
        {loading ? <LoadState/> :
          <div>
            {/* Search Bar */}
            {!hideSearch &&
            <div className={css(styles.search)}>
              <InlineSearch
                value={filter}
                handleChange={this.setFilter}
                handleKeyDown={this.checkKey}
              />
            </div>
            }

            {/* Outside Items */}
            <div>
              {outsideItems.map((item, i) => (
                <Item
                  key={item.value}
                  label={item.label}
                  outside={item.outside}
                  selected={selectedItemIndex === i}
                  handleClick={() => handleSelect(item.value)}
                />
              ))}
            </div>

            {/* List item type label */}
            {itemType &&
            <div className={css(styles.listItemType)}>
              <Label color="grey" uppercase>{itemType}</Label>
            </div>
            }

            {/* Items */}
            <div className={css(styles.items, smallList && styles.smallList)}>
              {itemsFiltered.map((item, i) => (
                <Item
                  key={item.value}
                  label={item.label}
                  selected={selectedItemIndex === i}
                  handleClick={() => handleSelect(item.value)}
                />
              ))}
              {(!itemsFiltered.filter(item => !item.fixed).length && filter) &&
              <Item
                label={`No items matching: ${filter}`}
                handleClick={() => this.setFilter('')}
              />
              }
              {(!this.props.items.length) && <Item label={`No items`} handleClick={() => {}}/>}
            </div>
            {!!buttonText &&
            <div style={{display: 'flex', marginTop: 10}}>
              <InlineButton handleClick={handleButtonClick} disabled={disabledButton}>{buttonText}</InlineButton>
              <div style={{marginLeft: 15, position: 'relative', bottom: -4}}>
                <Label handleClick={handleClose} underline={true}>Cancel</Label>
              </div>
            </div>
            }
          </div>
        }
      </div>
    );
  }
}
ItemList.displayName = 'ItemList';

const Item = ({ label, selected, outside, handleClick }) => (
  <div className={css(
      styles.item,
      selected && styles.selectedItemIndex,
      outside && styles.outsideItem
      )}
      onClick={handleClick}
  >
    <Label isLink={true} color="dark" hoverColor="primary">{label}</Label>
  </div>
);
Item.displayName = 'ItemList-Item';

/* Styles */
const styles = StyleSheet.create({
  itemList: {
    background: 'white',
    boxSizing: 'border-box',
  },
  search: {
    border: `2px solid ${colors.border}`,
    borderRadius: 4,
    marginBottom: 8,
  },
  items: {
    background: 'white',
    borderLeft: `2px solid ${colors.border}`,
    borderRight: `2px solid ${colors.border}`,
    borderBottom: `2px solid ${colors.border}`,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    maxHeight: 400,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  smallList: {
    maxHeight: 200,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    height: 32,
    paddingLeft: 16,
    paddingRight: 16,
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    ':hover': {
      background: colors.divider,
    }
  },
  selectedItemIndex: {
    background: colors.divider,
  },
  outsideItem: {
    height: 56,
    paddingLeft: 0,
    paddingRight: 0,
    background: 'white',
    ':hover': {
      background: 'white',
    }
  },
  listItemType: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    paddingLeft: 16,
    paddingRight: 16,
    border: `2px solid ${colors.border}`,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
});

/* PropTypes */
ItemList.defaultProps = {
  itemType: 'Options',
  smallList: false,
  loading: false,
};

ItemList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.node.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    fixed: PropTypes.bool, // If true, it won't be filtered out
    outside: PropTypes.bool, // If true, it will display at the top
  })).isRequired,
  itemType: PropTypes.string,
  hideSearch: PropTypes.bool,
  handleSelect: PropTypes.func.isRequired,
  handleButtonClick: PropTypes.func,
  handleClose: PropTypes.func,
  buttonText: PropTypes.string,
  smallList: PropTypes.bool,
  loading: PropTypes.bool
};

export default onClickOutside(ItemList);
