import React, { Component } from 'react';
import PropTypes from 'prop-types';

import RuleC from './Rule';
import colors from '../../../styles/colors';
import { Label } from '../../../components/type';
import IconButton from '../../../components/IconButton';
import InlineList from '../../../components/InlineList';
import InlineSelect from '../../../components/InlineSelect';
import Input from '../../../components/Input';

const comparisons = [
  { label: 'is', value: 'equals' },
  { label: 'is not', value: 'not_equals' },
  { label: 'contains', value: 'contains' },
  { label: 'does not contain', value: 'not_contains' },
  { label: 'is blank', value: 'not_exists'},
  { label: 'is not blank', value: 'exists'},
];


/**
 * Rule
 * Select from a list of items, and compare to a provided value
 * Always used inside RuleSet
 */

class Rule extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {rule, LHSFields, operation, handleRemove, deletable, handleChangeOperation, disabledRHS, isLocked} = this.props;
    return (
      <InlineList spacing={16}>
        {/* Displays the operation (AND / OR) or IF if its the first rule */}
        {operation === 'IF' ?
        <div style={styles.operation}>
          <Label center={true} uppercase={true}>{operation}</Label>
        </div> :
        <InlineSelect
          disabled={!handleChangeOperation}
          showDefault={false}
          value={operation}
          options={[
            {label: 'And', value: 'AND'},
            {label: 'Or', value: 'OR'},
          ]}
          handleSelect={operation => handleChangeOperation(operation)}
        />
        }

        {/* Select the LHS value */}
        <InlineSelect
          disabled={isLocked}
          invalid={rule.changed && !rule.getLHS()}
          value={rule.getLHS()}
          options={LHSFields}
          handleSelect={lhs => rule.setLHS(lhs)}
        />

        {/* Select the comparison */}
        <InlineSelect
          disabled={isLocked}
          invalid={rule.changed && !rule.getComparison()}
          value={rule.getComparison()}
          options={comparisons}
          handleSelect={comparison => rule.setComparison(comparison)}
        />

        {/* Input the RHS value */}
        {!disabledRHS &&
          <div style={{flex: 1}}>
            <Input
              height="32"
              invalid={rule.changed && !rule.getRHS()}
              value={rule.getRHS()}
              handleChange={value => rule.setRHS(value)}
              disabled={disabledRHS || isLocked}
            />
          </div>
        }
        {/* Remove the rule */}
        {(deletable && !isLocked) && <IconButton icon="trash" handleClick={handleRemove}/>}
      </InlineList>
    );
  }
}

/* Styles */
const styles = {
  operation: {
    width: 73,
    borderRadius: 4,
    border: `1px solid ${colors.border}`,
    background: 'white',
  },
};

/* PropTypes */
Rule.propTypes = {
  rule: PropTypes.instanceOf(RuleC).isRequired,
  LHSFields: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  handleRemove: PropTypes.func,
  handleChangeOperation: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};
Rule.defaultProps = {
  handleRemove: (() => {}),
  handleChangeOperation: false,
};

export default Rule;
