import React from 'react';
import colors from '../../styles/colors';
import { workflowOutputs, chainOutputs } from './constants';
import RadioCheckbox from '../../components/RadioCheckbox';

const AdditionalOutputs = ({ updateSearchData, selectedId, additionalOutputs, nodeType }) => {
  const workflowOrChainOutputs = nodeType === 'workflow' ? workflowOutputs : chainOutputs;
  return (
    <div style={styles.wrapper}>
      {workflowOrChainOutputs.map((o, idx) => {
        return (
          <div key={idx} style={styles.checkWrap}>
            <RadioCheckbox
              key={idx}
              disabled={!selectedId}
              checked={additionalOutputs.includes(o.value)}
              id={o.value}
              value={o.value}
              label={o.label}
              type="checkbox"
              right={true}
              handleChange={(val) => updateSearchData('additionalOutputs', val)}
            />
          </div>
        );
      })}
    </div>
  );
};

const styles = {
  wrapper: {
    marginLeft: 20,
    marginTop: 20,
  },
  checkWrap: {
    display: 'inline-block',
    width: 200,
    backgroundColor: colors.shaded,
    padding: '15px 20px',
    borderRight: `1px solid ${colors.background}`,
    borderBottom: `1px solid ${colors.background}`,
  }
};

export default AdditionalOutputs;