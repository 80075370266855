import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import colors from '../styles/colors';
import requiredIf from '../utils/requiredIf';
import Input from './Input';

/*
 * SingleInputForm
 * Creates a form with an input field
 *   - Can pass optional 'icon' to show a button
 *   - Calls 'handleSubmit' on enter key or button press
 */

class SingleInputForm extends Component {
  handleSubmit(e) {
    e.preventDefault();
    if (this.props.handleSubmit) {
      this.props.handleSubmit(this.props.value);
    }
  }

  render() {
    const { placeholder, icon, type, handleSubmit, handleChange, value, size, unallowedCharacters, error } = this.props;
    return (
      <form
        className={css(styles.searchBar)}
        onSubmit={e => this.handleSubmit(e)}>
        {icon &&
        <div
          className={`input-action-button ${css(styles.buttonSearch)}`}
          style={{ width: size === 'small' ? 32 : 50, height: size === 'small' ? 32 : 45}}
          onClick={() => handleSubmit(value)}>
          <i className={`fa fa-${icon}`}/>
        </div>
        }
        <Input
          unallowedCharacters={unallowedCharacters}
          type={type}
          value={value}
          error={error}
          placeholder={placeholder}
          handleChange={handleChange}
          height={size === 'small' ? 32 : 45}
        />
      </form>
    );
  }
}

const styles = StyleSheet.create({
  searchBar: {
    width: '100%',
    position: 'relative',
    marginBottom: 0
  },
  buttonSearch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    color: 'white',
    backgroundColor: colors.light,
    ':hover ~ input:focus': {
      borderColor: colors.light
    },
    ':hover': {
      backgroundColor: colors.dark,
      cursor: 'pointer'
    },
  }
});

SingleInputForm.propTypes = {
  placeholder: PropTypes.string,
  /* Font awesome icon: fa-xxxx */
  icon: PropTypes.string,
  type: PropTypes.oneOf(['number', 'text', 'date']),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  // Called when search input field is submitted
  handleSubmit: requiredIf('icon', 'function'),
  handleChange: PropTypes.func
};

SingleInputForm.defaultProps = {};

export default SingleInputForm;
