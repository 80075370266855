import React, { Component } from 'react';
import Radium from 'radium';

class BottomNav extends Component {
  render() {
    return (
      <div>
        <div style={styles.actionWrap}>
          <div style={{display: 'block', justifyContent: 'space-between', marginTop: 32}}>
            <div style={{marginTop: 0, marginRight: 16}} className="btn save-changes">Save</div>
            <div style={{marginTop: 0}} className="btn cancel">Close</div>
            <div className="btn pull-right"
                 onClick={() => App.triggerMethod( 'preview:assignment' )}>
              Preview
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  actionWrap: {
    flex: 1,
  },
};

export default Radium(BottomNav);
