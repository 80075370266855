import React from 'react';
import { Label, Caption } from '../../../components/type';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import TextArea from '../../../components/TextArea';
import PluginSettings from '../PluginSettings';
import IOSettings from '../IOSettings';
import Note from '../../../containers/Note';
import { mappingTypeOptions } from '../PluginSettings/constants';

const SettingsTabContent = ({
                              chainPluginData,
                              styles,
                              updateChainPlugin,
                              saveChainPlugin,
                              saveAndPublishChainPlugin,
                              savingChain,
                              publishingChain,
                              notes,
                            }) => (
  <div style={styles.innerContainer}>
    <div style={styles.formField}>
      <Label color="light">Name</Label>
      <Input
        disabled={false}
        value={chainPluginData.name}
        label="Name"
        handleChange={val => updateChainPlugin('name', val)}
      />
    </div>
    <div style={styles.formField}>
      <Label color="light">Description</Label>
      <TextArea value={chainPluginData.description} handleChange={val => updateChainPlugin('description', val)} rows={4} />
    </div>
    <div style={styles.formField}>
      <Label color="light">Instructions</Label>
      <TextArea value={chainPluginData.instructions} handleChange={val => updateChainPlugin('instructions', val)} rows={4} />
    </div>
    <div style={styles.formField}>
      <Label color="light">Plugin Type</Label>
      <div style={{ display: 'inline-block' }}>
        <label style={{ flex: 1 }}>
          <div style={ styles.radioBtn }>
            <Input
              checked={chainPluginData.type === 'lambda'}
              type="radio"
              value="lambda"
              handleChange={val => updateChainPlugin('type', val)}/>
          </div>
          <div style={styles.labels}>
            <Caption>Lambda Function</Caption>
          </div>
        </label>
      </div>
    </div>
    <div>
      <PluginSettings
        data={chainPluginData.typeSettings}
        updateChainPlugin={(type, val, index) => updateChainPlugin(type, val, index)}
        styles={styles}
      />

      {[{'type':'inputs'}, {'type':'outputs'}].map((item, i) => {
        return (
          <IOSettings
            key={i}
            type={item.type}
            inputMappingType={chainPluginData.inputMappingType}
            outputMappingType={chainPluginData.outputMappingType}
            options={item.type === 'inputs' ? chainPluginData.inputs : chainPluginData.outputs}
            updateChainPlugin={(type, val, index) => updateChainPlugin(type, val, index)}
            mappingTypeOptions={mappingTypeOptions}
            styles={styles}
            chainPluginData={chainPluginData}
          />
        );
      })}
    </div>

    {!!notes &&
      <div>
        {notes.map((note, i) => (
          <div key={i}>
            <div style={{ marginBottom: 1 }}>
              <Note
                type="error"
                note={note}
              />
            </div>
          </div>
        ))}
      </div>
    }

    <div style={{ display: 'inline-block', marginTop: 30 }}>
      <div style={{ marginRight: 20, display: 'inline-block' }}>
        <Button
          loading={!chainPluginData.id ? publishingChain : savingChain}
          disabled={savingChain || publishingChain}
          handleClick={() => saveChainPlugin(chainPluginData)}>
          {'Save Draft'}
        </Button>
      </div>
      {chainPluginData.id &&
      <div style={{ marginRight: 20, display: 'inline-block' }}>
        <Button
          loading={publishingChain}
          disabled={publishingChain || savingChain}
          handleClick={() => saveAndPublishChainPlugin(chainPluginData)}>
          {'Save & Publish'}
        </Button>
      </div>
      }
    </div>
  </div>
);

export default SettingsTabContent;

