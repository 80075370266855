import React from 'react';
import Modal from 'react-modal';

import modalStyle from '../../styles/modal';
import ItemList from '../../components/ItemList';
import Tabs, {Pane} from '../../components/Tabs';
import colors from '../../styles/colors';
import IconButton from '../../components/IconButton';

const NodeSelector = ({ open, nodes, handleSelect, handleClose }) => (
  <Modal
    contentLabel="Modal"
    isOpen={open}
    style={modalStyle}
    onRequestClose={handleClose}
    ariaHideApp={false}>
    <div style={styles.headPanel}>
      <IconButton
        align="right"
        icon="times"
        handleClick={handleClose}
        underline={true}
      >
        <span style={{ position: 'relative', top: 1 }}>Close</span>
      </IconButton>
    </div>
    <div style={styles.processorTabs}>
      <Tabs
        selected={0}
        tabBgColor={colors.background}
        activeTabColor={colors.light}
        activeTabBgColor={'#fff'}
        tabLabelsBgColor={'#fff'}
        borderColor={colors.border}
        tabBottomBorder={colors.border}
        verticalPadding={10}
        horizontalPadding={20}
        bold={true}
        horizontalSpacing={-1}
      >
        {nodes.map((node, i) =>
          <Pane
            key={i}
            label={node.tabName}
            contentBorderColor={colors.border}
            contentPadding={15}
          >
            <div style={{ minWidth: 600, padding: 0 }}>
              <ItemList
                padding={0}
                shadow={false}
                searchable={true}
                items={node.items.map((node, i) => ({key: i, label: node.label ? node.label : node.name, value: node.id}))}
                itemType={node.itemLabel ? node.itemLabel : 'Name'}
                handleSelect={value => {
                  handleSelect(node.items.find(n => n.id.toString() === value.toString()));
                }}
              />
            </div>
          </Pane>)
        }
      </Tabs>
    </div>
  </Modal>
);

const styles = {
  processorTabs: {
    border: `1px solid ${colors.border}`,
    padding: 20,
    backgroundColor: 'white',
  },
  headPanel: {
    padding: 20,
    position: 'relative',
    overflow: 'hidden',
  },
};

export default NodeSelector;
