import {
  FETCH_CHAINPLUGINS_REQUEST,
  FETCH_CHAINPLUGINS_SUCCESS,
  FETCH_CHAINPLUGINS_FAILURE,
  CREATE_CHAINPLUGIN_REQUEST,
  CREATE_CHAINPLUGIN_SUCCESS,
  CREATE_CHAINPLUGIN_FAILURE,
  UPDATE_CHAINPLUGIN_REQUEST,
  UPDATE_CHAINPLUGIN_SUCCESS,
  UPDATE_CHAINPLUGIN_FAILURE,
  FETCH_CHAINPLUGIN_REQUEST,
  FETCH_CHAINPLUGIN_SUCCESS,
  FETCH_CHAINPLUGIN_FAILURE,
  PUBLISH_CHAINPLUGIN_REQUEST,
  PUBLISH_CHAINPLUGIN_SUCCESS,
  PUBLISH_CHAINPLUGIN_FAILURE,
  FETCH_CHAINPLUGINVERSION_REQUEST,
  FETCH_CHAINPLUGINVERSION_SUCCESS,
  FETCH_CHAINPLUGINVERSION_FAILURE,
  FETCH_POPULATENEWINSTANCE_REQUEST,
  FETCH_POPULATENEWINSTANCE_SUCCESS,
  FETCH_POPULATENEWINSTANCE_FAILURE,
} from '../constants/actionTypes';

export function fetchChainPlugins() {
  return {
    types: [FETCH_CHAINPLUGINS_REQUEST, FETCH_CHAINPLUGINS_SUCCESS, FETCH_CHAINPLUGINS_FAILURE],
    fetchRoute: '/manage/api/chains/plugins',
    getFormattedResponse: ( chainPlugins ) => {
      return {
        chainPlugins: chainPlugins.plugins,
      };
    },
  };
}

export function fetchChainPlugin(id) {
  return {
    types: [FETCH_CHAINPLUGIN_REQUEST, FETCH_CHAINPLUGIN_SUCCESS, FETCH_CHAINPLUGIN_FAILURE],
    fetchRoute: `/manage/api/chains/plugins/${id}`,
    getFormattedResponse: ( chainPlugin ) => {
      chainPlugin.plugin.versions = chainPlugin.versions || [];
      return {
        chainPlugin: chainPlugin.plugin,
      };
    },
  };
}

export function fetchChainPluginVersion(id, version, chainId, onInstance) {
  const thisChainId = onInstance && chainId;

  /* If coming from instance and version is null that means that plugin only has one version, so
  set version to 1 */
  if(onInstance && !version) {
    version = 1;
  }

  return {
    types: onInstance ?
      [FETCH_POPULATENEWINSTANCE_REQUEST, FETCH_POPULATENEWINSTANCE_SUCCESS, FETCH_POPULATENEWINSTANCE_FAILURE] :
      [FETCH_CHAINPLUGINVERSION_REQUEST, FETCH_CHAINPLUGINVERSION_SUCCESS, FETCH_CHAINPLUGINVERSION_FAILURE],
    fetchRoute: `/manage/api/chains/plugins/${id}/version/${version}`,
    payload: { thisChainId },
    getFormattedResponse: ( chainPlugin ) => {
      return {
        chainPlugin: chainPlugin.plugin,
      };
    },
  };
}

export function createChainPlugin(plugin) {
  return {
    types: [CREATE_CHAINPLUGIN_REQUEST, CREATE_CHAINPLUGIN_SUCCESS, CREATE_CHAINPLUGIN_FAILURE],
    fetchRoute: `/manage/api/chains/plugins`,
    fetchMethod: 'post',
    fetchBody: plugin,
    getFormattedResponse: ( chainPlugin ) => {
      return {
        plugin: chainPlugin.plugin,
      };
    },
  };
}

export function updateChainPlugin(id, newChainPlugin) {
  return {
    types: [UPDATE_CHAINPLUGIN_REQUEST, UPDATE_CHAINPLUGIN_SUCCESS, UPDATE_CHAINPLUGIN_FAILURE],
    fetchRoute: () => {
      return `/manage/api/chains/plugins`;
    },
    fetchMethod: 'put',
    fetchBody: newChainPlugin,
    payload: { id },
    getFormattedResponse: ( chainPlugin ) => ( chainPlugin ),
  };
}

export function publishChainPlugin(id, newChainPlugin) {
  return {
    types: [PUBLISH_CHAINPLUGIN_REQUEST, PUBLISH_CHAINPLUGIN_SUCCESS, PUBLISH_CHAINPLUGIN_FAILURE],
    fetchRoute: () => {
      return `/manage/api/chains/plugins/${id}/publish`;
    },
    fetchMethod: 'post',
    fetchBody: newChainPlugin,
    payload: { id },
    getFormattedResponse: ( chainPlugin ) => ( chainPlugin ),
  };
}