import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Label} from '../../../../components/type';
import Input from '../../../../components/Input';
import InlineList from '../../../../components/InlineList';
import colors from '../../../../styles/colors';
import InlineButton from '../../../../components/InlineButton';
import IconButton from '../../../../components/IconButton';
import CollapsedInstance from './CollapsedInstance';
import { cancelPluginInstance, createPluginInstance, deletePluginInstance } from '../../../../actions/chains';
import IOSettings from '../../../../pages/ChainPlugin/IOSettings';

const nameError = 'A plugin instance already exists with this name. Please update the name so it is unique.';
const eventError = 'There are blank Event Options, please remove them or provide names to save the plugin instance.';
const blankError = 'There are blank input or output fields, please remove them or provide names to save the plugin instance.';
const noInputOutputError = 'There must be at least one input and one output in order to save the plugin instance.';

class Instance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: !props.unsavedInstance,
      deletingInstance: false,
      savingInstance: false,
      sameName: false,
      blankInputOrOutput: false,
      blankEvent: false,
      oneInputOutput: false,
    };
    this.savePluginInstance.bind(this);
    this.cancelPluginInstance.bind(this);
  }

  savePluginInstance(newInstance, index) {
    let dupName = false;
    let blankInputOutput = false;
    let blankEvent = false;
    let oneInputOutput = false;

    this.props.allInstances.forEach((all) => {
      if (newInstance.name === all.name && newInstance.id !== all.id) {
        dupName = true;
      }
    });

    if(!newInstance.inputs.length || !newInstance.outputs.length) {
      oneInputOutput = true;
    } else {
      newInstance.inputs.forEach((input) => {
        if (!input.name) {
          blankInputOutput = true;
        }
      });
      newInstance.outputs.forEach((output) => {
        if (!output.name) {
          blankInputOutput = true;
        }
      });
    }

    newInstance.typeSettings.eventOptions.forEach(options => {
      options.data.forEach(data => {
        if(!data.value){
          blankEvent = true;
        }
      });
    });

    if( (!dupName && !blankInputOutput && !blankEvent && !oneInputOutput) ) {
      if (newInstance.versions) {
        delete newInstance.versions;
      }
      this.setState({savingInstance: true});
      if (!newInstance.pluginId) {
        this.props.dispatch(createPluginInstance(newInstance, this.props.chainId, index));
      }
    }
    this.setState({ blankEvent: blankEvent, blankInputOrOutput: blankInputOutput, sameName: dupName, oneInputOutput: oneInputOutput });
  }

  deletePluginInstance(instance, instanceIndex) {
    this.setState({ deletingInstance: true });
    this.props.dispatch(deletePluginInstance(instance.id, this.props.chainId, instanceIndex));
  }

  cancelPluginInstance(bool, index, pluginId, chainId) {
    this.props.dispatch(cancelPluginInstance(index, chainId, pluginId));
    if(pluginId) {
      this.setState({ collapsed: bool });
    }
  }

  render() {
    const { instance, styles, updateChainPluginInstance, index, chainId, active, inChain, chainPlugins } = this.props;
    const instanceId = instance.id;
    const pluginId = instance.pluginId;

    if(this.state.collapsed) {
      return <CollapsedInstance
        collapsed={true}
        instance={instance}
        handleExpanding={bool => this.setState({ collapsed: bool })}
        originalPluginName={(chainPlugins && chainPlugins[instance.pluginId]) && chainPlugins[instance.pluginId].name}
      />;
    } else {
      return (
        <div>
          <CollapsedInstance
            instance={instance}
            handleExpanding={bool => this.setState({ collapsed: bool })}
            originalPluginName={(chainPlugins && chainPlugins[instance.pluginId]) && chainPlugins[instance.pluginId].name}
          />
          {!instance.pluginId &&
          <div style={styles.inputField}>
            <Label color="light">Plugin Instance Name</Label>
            <Input
              disabled={!!instance.pluginId}
              handleChange={val => updateChainPluginInstance('instanceName', val, null, instanceId, pluginId && pluginId)}
              error={this.state.sameName ? nameError : ''}
              invalid={this.state.sameName}
              value={instance.name}
            />
          </div>
          }

          {instance.typeSettings.eventOptions.some(opt => opt.scope === 'instance') && !!instance.typeSettings.eventOptions &&
          <div style={{ marginBottom: 30 }}>
            <Label color="light">Event Options Data:</Label>
            <div style={{padding: 15, backgroundColor: colors.divider, borderRadius: 5}}>
              {instance.typeSettings.eventOptions.map((option, i) =>
                <div key={i}>
                  <div style={styles.inputField}>
                    <Label color="light">{option.name}</Label>
                    <Input
                      disabled={option.scope === 'global' || (active && instance.pluginId)}
                      handleChange={val =>
                        updateChainPluginInstance('eventOptions', val, i, instanceId)}
                      type={option.type === 'integer' ? 'number' : 'text'}
                      error=""
                      value={option.data[0].value}
                      unallowedCharacters={[32]}
                      placeholder={`${option.type}_no_spaces`}/>
                    {option.scope === 'global' &&
                      <Label italic={true} color="light">*This event is Globally Defined and must be edited from the
                        Plugin Library page.</Label>
                    }
                  </div>
                </div>
              )}

              {this.state.blankEvent &&
              <Label color="negative" italic={true}>{eventError}</Label>
              }
            </div>
          </div>
          }

          <div>
            <IOSettings
              chainPluginData={instance}
              type="inputs"
              mappingTypeOptions={[]}
              inputMappingType={instance.inputMappingType}
              options={instance.inputs}
              updateChainPlugin={({}, val, index, id) => updateChainPluginInstance('inputs', val, index, id)}
              styles={styles}
              instanceSettings={true}
              readOnly={instance.inputMappingType === 'global' || (active && instance.pluginId)}
              blankInputOrOutput={ this.state.blankInputOrOutput }
            />
          </div>

          <div>
            <IOSettings
              chainPluginData={instance}
              type="outputs"
              mappingTypeOptions={[]}
              outputMappingType={instance.outputMappingType}
              options={instance.outputs}
              updateChainPlugin={({}, val, index, id) => updateChainPluginInstance('outputs', val, index, id)}
              styles={styles}
              instanceSettings={true}
              readOnly={instance.outputMappingType === 'global' || (active && instance.pluginId)}
              blankInputOrOutput={ this.state.blankInputOrOutput }
            />
          </div>

          <div style={{ position: 'relative', marginTop: 10, marginBottom: !!instance.pluginId ? 50 : 0 }}>
            { (this.state.blankInputOrOutput || this.state.oneInputOutput)  &&
            <Label italic={true} color="negative">{this.state.blankInputOrOutput ? blankError : noInputOutputError}</Label>
            }
            <InlineList>
              {!instance.pluginId &&
              <div style={{display: 'flex'}}>
                <div style={{paddingRight: 10}}>
                  {(!instance.pluginId || instance.outputMappingType === 'instance' || instance.inputMappingType === 'instance') &&
                  <InlineButton
                    loading={this.state.savingInstance}
                    disabled={this.state.savingInstance}
                    handleClick={() => this.savePluginInstance(instance, index)}>
                    {this.state.savingInstance ? 'Saving' : 'Save'}
                  </InlineButton>
                  }
                </div>

                {(!instance.pluginId || instance.outputMappingType === 'instance' || instance.inputMappingType === 'instance') &&
                <InlineButton
                  disabled={false}
                  handleClick={() => {
                    this.cancelPluginInstance(!this.state.collapsed, index, pluginId, chainId);
                  }}>Cancel
                </InlineButton>
                }
              </div>
              }
            </InlineList>

            {!!instance.pluginId &&
              <div style={{position: 'absolute', right: 0, top: 13}}>
                <IconButton
                  loading={this.state.deletingInstance}
                  disabled={this.state.deletingInstance || inChain}
                  underline={true}
                  icon="trash"
                  title={inChain && 'Please remove from the chain before deleting.'}
                  iconPosition="left"
                  handleClick={() => this.deletePluginInstance(instance, index)}>
                  {this.state.deletingInstance ? 'Deleting' : 'Delete'}
                </IconButton>
              </div>
            }
          </div>
        </div>
      );
    }
  }
}

/* PropTypes */
Instance.propTypes = {
  instance: PropTypes.object,
  styles: PropTypes.object,
  unsavedInstance: PropTypes.bool,
  updateChainPluginInstance: PropTypes.func,
  chainId: PropTypes.string,
  allInstances: PropTypes.array,
  active: PropTypes.bool,
};

const styles = {
  formField: {
    marginBottom: 20,
  },
  radioBtn: {
    flex: 1,
    width: 15,
    height: 15,
    marginRight: 10,
  },
  labels: {
    marginLeft: 20,
    marginRight: 10,
    position: 'relative',
    top: 1,
  },
};

export default Instance;