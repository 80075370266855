import {StyleSheet} from 'aphrodite';

import colors from './colors';

export const labelText = {
  fontFamily: 'Open Sans',
  fontSize: 12,
  fontWeight: 600,
  lineHeight: 2,
  color: colors.dark
};

export default StyleSheet.create({
  label: labelText
});
