import update from 'immutability-helper';

import { RECEIVED_DEFINITION } from '../constants/actionTypes';

export default function (state = {}, action) {
  switch (action.type) {
    case RECEIVED_DEFINITION: {
      return update(state, {$merge: {
        [action.definition.guid]: action.definition
      }});
    }
    default:
      return state;
  }
}
