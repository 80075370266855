import React, { Component } from 'react';
import Radium from 'radium';
import listensToClickOutside from 'react-onclickoutside';

class Item extends Component {
  handleClickOutside = () => {
    this.props.handleUnselect();
  };

  componentDidMount() {
    this.input.focus();
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.handleSubmit(this.input.value.replace(/^[\W0-9]+|xml|[^\w-\.:\#\[\]]+/gim, ''));
    this.input.value = '';
    this.input.focus();
  }

  render() {
    return (
      <div>
        {this.props.error && <div style={styles.error}>{this.props.error}</div>}
        <form style={styles.main} onSubmit={e => this.handleSubmit(e)}>
          <input
            style={styles.input}
            ref={ele => this.input = ele}
            onChange={e => {
              e.target.value = e.target.value.replace(/^[\W0-9]+|xml|[^\w-\.:\#\[\]]+/gim, '');
            }}
            placeholder="Input Key (example: ArticleSubtitle)"
          />
          <input type="submit" value="+" style={styles.btn}/>
        </form>
      </div>
    );
  }
}

const styles = {
  main: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 2
  },
  input: {
    flex: 1,
    height: 32,
    border: '1px solid #676767',
    borderRadius: 2,
    paddingRight: 32,
    boxSizing: 'border-box'
  },
  btn: {
    position: 'absolute',
    right: 0,
    top: 0,
    background: '#858484',
    color: 'white',
    height: 32,
    width: 32,
    borderTop: '1px solid #676767',
    borderRight: '1px solid #676767',
    borderBottom: '1px solid #676767',
    borderLeft: 'none',
    borderRadius: 0,
    cursor: 'pointer',
    padding: 0
  },
  error: {
    fontSize: 11,
    color: '#f94d1f',
    marginBottom: 4
  }
};

export default listensToClickOutside(Radium(Item));
