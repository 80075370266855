import createTypeComponent from '../utils/createTypeComponent';

export const Heading = createTypeComponent('Heading', 36, 800, 60);
export const ChainHeading = createTypeComponent('Heading', 36, 800, 36);
export const SubSectionHeading = createTypeComponent('subheading', 30, 200, 48);
export const Heading2 = createTypeComponent('heading', 30, 200, 36);
export const Subheading = createTypeComponent('subheading', 24, 800, 36);
export const NavTitle = createTypeComponent('nav-title', 20, 800, 32);
export const BodyInstruction = createTypeComponent('body-instruction', 20, 400, 36);
export const Attention = createTypeComponent('attention', 18, 800, 32);
export const PanelAttention = createTypeComponent('attention', 18, 800, 22);
export const ManagerAttention = createTypeComponent('attention', 18, 600, 32);
export const Header4 = createTypeComponent('header', 18, 400, 22);
export const SmallPanelAttention = createTypeComponent('attention', 14, 800, 22);
export const Title = createTypeComponent('title', 16, 800, 28);
export const Large = createTypeComponent('title', 16, 400, 28);
export const MediumLabel = createTypeComponent('label', 14, 800, 24);
export const Regular = createTypeComponent('label', 13, 400, 20);
export const Label = createTypeComponent('label', 12, 800, 24);
export const Caption = createTypeComponent('caption', 12, 600, 24);
export const Content = createTypeComponent('caption', 12, 400, 21.6);
export const SmallDescription = createTypeComponent('title', 11, 200, 16);
