import React, { Component } from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';

import { costPerItem, getReleasableItemCount } from '../../utils/workflows';
import { ADD_FUNDS, RELEASE_ITEMS } from '../../constants/modalTypes';
import { toggleModal, toast } from '../../actions/app';
import { releaseChainBatchItems } from '../../actions/chains';
import { fetchFunds } from '../../actions/company';
import { fetchDefinition, releaseItems, releaseWorkflowBatchItems } from '../../actions/workflows';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import IconButton from '../../components/IconButton';
import SingleInputForm from '../../components/SingleInputForm';
import { Attention, Label, Title } from '../../components/type';

class ReleaseItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingFunds: true,
      loadingDefinition: true,
      maxItemCount: 0,
    };
    this.setCurrentItemCount = this.setCurrentItemCount.bind(this);
  }

  componentDidMount() {
    if(this.props.workflowId) {
      this.props.dispatch(fetchDefinition(this.props.workflowId))
        .then(() => this.setState({ loadingDefinition: false }));
    }
    this.props.dispatch(fetchFunds())
      .then(() => this.setState({ loadingFunds: false }));
    this.setState({itemCount: this.props.stagedItemCount});
  }

  componentWillUpdate() {
    const maxItemCount = getReleasableItemCount(
      this.props.stagedItemCount,
      costPerItem(this.props.definition, this.props.transactionFeeRate),
      this.props.availableBalance
    );
    if (this.state.maxItemCount === maxItemCount) {
      return;
    }
    this.setState({
      maxItemCount,
      itemCount: this.state.itemCount ? this.state.itemCount : maxItemCount,
    });
  }

  isReleaseable() {
    return this.state.itemCount <= this.props.stagedItemCount && this.state.itemCount > 0;
  }

  getReleaseAction(type, batch, workflowId) {
    if (type === 'workflow') {
      return row_count => releaseItems(workflowId, row_count);
    }
    if (type === 'workflowBatch') {
      return row_count => releaseWorkflowBatchItems(workflowId, batch.id, row_count);
    }
    return row_count => releaseChainBatchItems(batch.id, row_count);
  }

  release() {
    const { batch, workflowId } = this.props;
    const release = this.getReleaseAction(this.props.batchType, batch, workflowId);
    this.setState({ releasingItems: true });
    this.props.dispatch(release(this.state.itemCount))
      .then(() => {
        const releasedItems = this.state.itemCount;
        const toastMessage = `${releasedItems} item${releasedItems > 1 ? 's have' : ' has'} been released to contributors`;
        this.props.dispatch(toast(toastMessage));
        this.props.dispatch(toggleModal(RELEASE_ITEMS));
      });
  }

  setCurrentItemCount(itemCount) {
    if (itemCount < 0 || itemCount > this.props.stagedItemCount) {
      return;
    }
    this.setState({ itemCount });
  }

  render() {
    const { availableBalance, stagedItemCount } = this.props;
    const batchType = this.props.batchType;
    const isWorkflowBatch = batchType === 'workflowBatch' || batchType === 'workflow';
    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
          <Title color="dark">Release</Title>
          <div style={{ width: 80, marginLeft: 4, marginRight: 4 }}>
            <SingleInputForm
              type="number"
              value={this.state.itemCount || 0}
              handleChange={this.setCurrentItemCount}
            />
          </div>
          <Title color="dark">of {stagedItemCount} Staged Items</Title>
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: isWorkflowBatch ? 'space-between' : 'flex-end',
          borderTop: `1px solid ${colors.border}`,
          borderBottom: `1px solid ${colors.border}`,
          paddingTop: 8,
          paddingBottom: 8,
          marginBottom: 16
        }}>
          {isWorkflowBatch &&
          <Label italic={true}>
            Your current available funds will
            cover approximately {this.state.loadingDefinition || this.state.loadingFunds ? 0 : this.state.maxItemCount}
            &nbsp;of your {stagedItemCount} items.
          </Label>
          }
          <IconButton
            icon="plus-square"
            handleClick={() => this.props.dispatch(toggleModal(ADD_FUNDS))}>
            Add Funds
          </IconButton>
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: 16,
          paddingRight: 16,
          height: 64,
          background: colors.background
        }}>
          <Attention color="dark">Available Funds</Attention>
          <Attention color="dark">
            {this.state.loading ?
              <i className="fa fa-spinner fa-spin"/> : numeral(availableBalance).format('$0,0.00')}
          </Attention>
        </div>
        <div
          id="release-items-button-container"
          style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}>
          <Button
            disabled={!this.isReleaseable() || this.state.releasingItems}
            handleClick={() => this.release()}>
            {this.state.releasingItems ? <i className="fa fa-spin fa-spinner"/> : 'Release'}
          </Button>
        </div>
      </div>
    );
  }
}

function select(state) {
  const workflow = state.workflows.items[state.app.modalMeta.workflowId];
  const batch = state.app.modalMeta.batch;
  const stagedItemCount = state.app.modalMeta.stagedItemCount;
  const batchType = state.app.modalMeta.batchType;
  return {
    batchType,
    stagedItemCount,
    workflowId: workflow && workflow.id,
    batch,
    definition: state.workflowDefinitions[workflow && (workflow.s3_filename || workflow.guid)],
    availableBalance: state.app.availableBalance,
    transactionFeeRate: state.app.transactionFeeRate,
  };
}

export default connect(select)(ReleaseItems);
