import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string, PropTypes.bool]),
  selected: PropTypes.bool
};

const height = 66;

class Settings extends Component {
  render() {
    const { show, customHeight } = this.props;
    return (
      <div style={[styles.main, show && styles.selected, {
        height: show ? customHeight || ((this.props.children.length ? this.props.children.length * height : height) + 16) : 0
      }]}>
        <div style={{paddingTop: 16}}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

const styles = {
  main: {
    transition: 'height 0.2s ease-in',
    overflow: 'hidden'
  },
  selected: {}
};

Settings.propTypes = propTypes;
export default Radium(Settings);
