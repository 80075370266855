import React, { Component } from 'react';
import update from 'immutability-helper';
import { connect } from 'react-redux';

import {
  fetchChainBatch,
  fetchChain,
  updateChainBatchExportSettings,
  exportChainBatch,
} from '../../../actions/chains';
import { toggleModal, toast } from '../../../actions/app';
import { EXPORT_CHAIN_BATCH, CONTROL_MODAL_OVERLAY_CLICK } from '../../../constants/modalTypes';

import IconButton from '../../../components/IconButton';
import InlineButton from '../../../components/InlineButton';
import InlineList from '../../../components/InlineList';
import InlineSaveState from '../../../components/InlineSaveState';
import LoadState from '../../../containers/LoadState';
import ExportTable from './ExportTable';
import { Label } from '../../../components/type';
import colors from '../../../styles/colors';

const mergeChainAndBatchIOSchemas = ( chain, batch ) => {
  return batch.ioSchema.map( io => {
    const chainVersion = chain.ioSchema.find( chainIO => {
      return chainIO.nodeId === io.nodeId && chainIO.name === io.name && chainIO.isOutput == io.isOutput &&
        (chainIO.operationLabel ? chainIO.operationLabel === io.operationLabel : chainIO.operationName === io.operationName);
    } );
    return chainVersion || io;
  } );
};

/**
 * ExportSettings
 * Used to select fields the user would like exported
 */
class ExportChainBatch extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      exportCompleted: false,
      outputList: [],
      inputList: [],
    };

    this.saveExportSettings = this.saveExportSettings.bind( this );
    this.exportBatch = this.exportBatch.bind( this );
    this.toggleModal = this.toggleModal.bind( this );
  }

  componentDidMount() {
    Promise.all( [
      this.props.dispatch( fetchChain( this.props.projectId, this.props.chainId ) ),
      this.props.dispatch( fetchChainBatch( this.props.chainId, this.props.batchId ) ),
    ] )
      .then( ( [ { chain }, { batch } ] ) => {
        this.setState(
          { ioSchema: mergeChainAndBatchIOSchemas( chain, batch ),
            outputList: this.getOutputsAsData(),
            inputList: this.getInputsAsData()
          } );
      } );
  }

  saveExportSettings() {
    //Save default export settings for all batches that match that schema
    const { projectId, chainId, batchId } = this.props;
    const ioSchema = this.buildNewIoSchema();
    this.props.dispatch( updateChainBatchExportSettings( projectId, chainId, batchId, ioSchema ) )
      .then( () => {
        this.setState( { ioSchema } );
      } );
  }

  exportBatch() {
    //Export batch based on what's checked and if the completed only flag is checked
    const { projectId, chainId, batchId } = this.props;
    const exportAll = !this.state.exportCompleted;
    this.setState( { exporting: true } );
    this.props.dispatch( {
      type: CONTROL_MODAL_OVERLAY_CLICK,
      enableOverlayClick: false,
    } );
    this.props.dispatch( exportChainBatch( projectId, chainId, batchId, this.buildNewIoSchema(), exportAll ) )
      .then( () => {
        const batch = this.props.batch;
        this.props.dispatch( toast( `You will be emailed a CSV of ${exportAll ? batch.rowCount : batch.items.completed || 0} items` ) );
        this.setState( { exporting: false } );
        this.toggleModal();
      } );
  }

  buildNewIoSchema() {
    //build a deep copy of the ioSchema and iterate through the input/output lists to alter the shouldExport field
    const inputList = [ ...this.state.inputList ];
    const outputList = [ ...this.state.outputList ];
    let localIO = JSON.parse( JSON.stringify( this.state.ioSchema ) );
    localIO.forEach( io => {
      inputList.forEach( n => {
        if ( io.nodeId === n.nodeId && io.name === n.name && n.export ) {
          io.shouldExport = true;
        } else if ( io.nodeId === n.nodeId && io.name === n.name && !n.export ) {
          io.shouldExport = false;
        }
      } );
      outputList.forEach( o => {
        if ( io.nodeId === o.nodeId && io.name === o.name && o.export ) {
          io.shouldExport = true;
        } else if ( io.nodeId === o.nodeId && io.name === o.name && !o.export ) {
          io.shouldExport = false;
        }
      } );
    } );
    return localIO;
  }


  getInputsAsData() {
    //Iterate throught the ioSchema and filter out the inputs, mapping for easier usage down the component tree
    return mergeChainAndBatchIOSchemas( this.props.chain, this.props.batch )
      .filter( t => !t.isOutput )
      .map( n => {
        return ( {
          name: n.name,
          nodeId: n.nodeId,
          export: n.shouldExport,
          wfName: n.operationLabel ? n.operationLabel : n.operationName,
        });
      } );
  }

  getOutputsAsData() {
    //Iterate through the ioSchema and filter out the outputs, mapping for easier usage down the component tree
    let foundFields = [];
    let localIO = mergeChainAndBatchIOSchemas( this.props.chain, this.props.batch );
    return localIO.filter( t => {
      /* Get all outputs that aren't from startup/import node */
      if ( t.isOutput && t.operationName !== 'startup' ) {
        let fieldToken = t.operationName + '|' + t.name;
        if ( !foundFields.includes( fieldToken ) ) {
          foundFields.push( fieldToken );
          return true;
        }
      }
      return false;
    } ).map( hi => {
      return ( {
        name: hi.name,
        nodeId: hi.nodeId,
        export: hi.shouldExport,
        wfName: hi.operationLabel ? hi.operationLabel : hi.operationName,
      } );
    } );
  }

  resetInputGroup() {
    //Reset inputList to match the ioSchema( Defaulted from modal load )
    let inputList = [ ...this.state.inputList ];
    const defaultInputs = this.state.ioSchema.filter( t => !t.isOutput ).map( n => {
      return ( {
        name: n.name,
        nodeId: n.nodeId,
        export: n.shouldExport,
        wfName: n.operationLabel ? n.operationLabel : n.operationName,
      });
    } );

    defaultInputs.forEach( n => {
      const index = inputList.findIndex( item => {
        return (item.nodeId === n.nodeId && item.name === n.name);
      } );
      inputList.splice( index, 1 );
    } );
    this.setState( { inputList: [ ...inputList, ...defaultInputs ] } );
  }

  resetOutputGroup( id ) {
    //Reset outputList to match the ioSchema( Defaulted from modal load )
    let foundFields = [];
    let outputList = [ ...this.state.outputList ];
    const defaultOutputs = this.state.ioSchema.filter( t => {
      /* Get all outputs that aren't from startup/import node */
      if ( t.isOutput && t.operationName !== 'startup' ) {
        let fieldToken = t.operationName + '|' + t.name;
        if ( !foundFields.includes( fieldToken ) ) {
          if ( id === null || t.nodeId == id ) {
            foundFields.push( fieldToken );
            return true;
          }
        }
      }
      return false;
    } ).map( hi => {
      return ( {
        name: hi.name,
        nodeId: hi.nodeId,
        export: hi.shouldExport,
        wfName: hi.operationLabel ? hi.operationLabel : hi.operationName,
      } );
    } );

    defaultOutputs.forEach( o => {
      const index = outputList.findIndex( item => {
        return (item.nodeId === o.nodeId && item.name === o.name);
      } );
      outputList.splice( index, 1 );
    } );
    this.setState( { outputList: [ ...outputList, ...defaultOutputs ] } );
  }

  handleDisablingChainExport( chain, batch ) {
    return this.state.exporting || chain.isUpdating || batch.isUpdating;
  }

  toggleRow( id, name, inputOrOutput ) {
    // Find index in the schema of the cell you're clicking on, different lists based on inputOrOutput
    if ( inputOrOutput === 'output' ) {
      const correctSchemaRow = this.state.outputList.findIndex( item => {
        return ( item.nodeId === id && item.name === name);
      } );

      const cell = this.state.outputList[ correctSchemaRow ];
      // Update ioSchema
      const ioUpdate = { [correctSchemaRow]: { $merge: { export: !cell.export } } };
      this.setState( { outputList: update( this.state.outputList, ioUpdate ), updated: true } );
    } else if ( inputOrOutput === 'input' ) {
      const correctSchemaRow = this.state.inputList.findIndex( item => {
        return ( item.nodeId === id && item.name === name );
      } );

      const cell = this.state.inputList[ correctSchemaRow ];

      // Update ioSchema
      const ioUpdate = { [correctSchemaRow]: { $merge: { export: !cell.export } } };
      this.setState( { inputList: update( this.state.inputList, ioUpdate ), updated: true } );
    }
  }

  getWFOutputs() {
    //Open the multiselect while building the array of outputs
    const schemaOutputs = this.getOutputsAsData();
    let foundFields = [];

    return schemaOutputs.filter( t => {
      if ( !foundFields.includes( t.wfName ) ) {
        foundFields.push( t.wfName );
        return true;
      }
      return false;
    } ).map(wfs => {
      return {
        id: wfs.nodeId,
        name: wfs.wfName,
      };
    });
  }

  toggleModal() {
    this.props.dispatch( toggleModal( EXPORT_CHAIN_BATCH ) );
  }

  resetData( val ) {
    //this function handles calling the proper functions based on what value was passed in the initial call
    if ( val === 'all' ) {
      this.resetInputGroup();
      this.resetOutputGroup( null );
      //implement reset all outputs
    } else if ( val === 'inputs' ) {
      this.resetInputGroup();
    } else {
      this.resetOutputGroup( val );
    }
  }

  toggleAllInputs( toggleType ) {
    //return a new list where the export flag has been toggled to the toggleType( bool )
    let inputList = [ ...this.state.inputList ];
    return inputList.map( n => {
      return ( {
        name: n.name,
        nodeId: n.nodeId,
        export: toggleType,
        wfName: n.wfName,
      });
    } );
  }

  toggleAllOutputs( toggleType ) {
    //return a new list where the export flag has been toggled to the toggleType( bool )
    let outputList = [ ...this.state.outputList ];
    return outputList.map( o => {
      return ( {
        name: o.name,
        nodeId: o.nodeId,
        export: toggleType,
        wfName: o.wfName,
      } );
    } );
  }

  toggleOutputGroup( toggleType, id ) {
    //return a new list where the export flag has been toggled to the toggleType( bool )
    //this function only changes the outputs of a certain workflow
    let outputList = [ ...this.state.outputList ];
    const checkedOutputs = outputList.filter( t => t.nodeId === id ).map( o => {
      return ( {
        name: o.name,
        nodeId: o.nodeId,
        export: toggleType,
        wfName: o.wfName,
      } );
    } );

    checkedOutputs.forEach( o => {
      const index = outputList.findIndex( item => {
        return (item.nodeId === o.nodeId && item.name === o.name);
      } );
      outputList.splice( index, 1 );
    } );
    return [ ...outputList, ...checkedOutputs ];
  }

  selectAll( val ) {
    //call the different functions based on the incoming val into selectAll( all, inputs, wfId )
    if ( val === 'all' ) {
      this.setState( {
        inputList: [ ...this.toggleAllInputs( true ) ],
        outputList: [ ...this.toggleAllOutputs( true ) ]
      } );
    } else if ( val === 'inputs' ) {
      this.setState( { inputList: [ ...this.toggleAllInputs( true ) ] } );
    } else {
      this.setState( { outputList: this.toggleOutputGroup( true, val ) } );
    }
  }

  unSelectAll( val ) {
    //call the different functions based on the incoming val into selectAll( all, inputs, wfId )
    if ( val === 'all' ) {
      this.setState( {
        inputList: [ ...this.toggleAllInputs( false ) ],
        outputList: [ ...this.toggleAllOutputs( false ) ]
      } );
    } else if ( val === 'inputs' ) {
      this.setState( { inputList: [ ...this.toggleAllInputs( false ) ] } );
    } else {
      this.setState( { outputList: this.toggleOutputGroup( false, val ) } );
    }
  }

  render() {
    const { chain, batch } = this.props;
    const {
      exportCompleted,
      inputList,
      outputList,
    } = this.state;

    if ( !this.state.ioSchema ) {
      return (
        <div style={{ padding: '24px 0px' }}>
          <LoadState label="Loading Default Settings"/>
        </div>
      );
    }
    const modalDescription = 'Customize your export by selecting the inputs and outputs below that you would ' +
      'like to export. De-selecting this option will hide this column of data from your export file. ' +
      'You can save these settings as a default if you\'d like.';

    return (
      <div style={styles.exportBorder}>
        <div style={styles.tableWrapper}>
          <Label color="dark" bottom={4}>
            {modalDescription}
          </Label>
          <br/>
          <div style={{ maxHeight: '50%', overflow: 'auto' }}>
            <ExportTable
              inputs={inputList}
              outputs={outputList}
              handleClickCell={( id, name, inputOrOutput ) => this.toggleRow( id, name, inputOrOutput )}
              wfList={this.getWFOutputs()}
              resetData={val => this.resetData( val )}
              selectAll={val => this.selectAll( val )}
              unSelectAll={val => this.unSelectAll( val )}
            />
          </div>
          <div style={{ marginTop: 16, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
              <IconButton
                icon="save"
                underline={true}
                handleClick={this.saveExportSettings}
                disabled={chain.isUpdating || batch.isUpdating} >
                Save As Default
              </IconButton>
            </div>
            <InlineList spacing={16} justify="flex-end">
              <InlineSaveState
                savingLabel={batch.isUpdating ? 'Exporting Batch' : 'Saving default settings'}
                savedLabel="Completed"
                saving={(chain && chain.isUpdating) || batch.isUpdating}
              />
              <IconButton
                icon={exportCompleted ? 'check-square-o' : 'square-o'}
                handleClick={() => this.setState( { exportCompleted: !exportCompleted } )}>
                <div style={{ textDecoration: 'none !important', display: 'inline-block' }}>
                  Only Completed Items
                </div>
              </IconButton>
              <InlineButton
                handleClick={this.exportBatch.bind( null )}
                disabled={this.handleDisablingChainExport( chain, batch )}>
                Export
              </InlineButton>
            </InlineList>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  exportHeader: {
    backgroundColor: colors.dark,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '24px 16px'
  },
  exportBorder: {
    maxHeight: '90%'
  },
  multiSelectWrapper: {
    position: 'absolute',
    top: 65,
    right: 135,
    zIndex: 1
  },
  tableWrapper: {
    padding: 16,
  }
};

function select( state ) {
  const chainId = state.app.modalMeta.chainId;
  const batchId = state.app.modalMeta.batchId;
  return {
    batchId,
    chainId,
    projectId: state.app.modalMeta.projectId,
    chain: state.chains.items[ chainId ],
    workflows: state.workflows.items,
    batch: state.batches.items[ batchId ],
  };
}

export default connect( select )( ExportChainBatch );
