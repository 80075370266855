import React, { Component } from 'react';

import colors from '../../styles/colors';
import InlineList from '../../components/InlineList';
import { Caption, Label } from '../../components/type';

const comparisons = {
  not_equals: 'is not',
  equals: 'is',
  contains: 'contains',
  not_contains: 'does not contain',
  not_exists: 'is blank',
  exists: 'is not blank',
};

class StaticRuleSet extends Component {
  render() {
    const ruleSet = this.props.ruleSet;
    return (
      <div style={styles.staticRuleSet}>
        {ruleSet.getRules().map((rule, i) => (
        <InlineList key={i} spacing={8}>
          <div style={styles.operation}>
            <Caption
              lowercase={true}
              color="light">
              {i > 0 ? ruleSet.getOperation() : 'IF'}
            </Caption>
          </div>
          <Label color="light">{rule.getLHS().split('|')[2]}</Label>
          <Caption color="grey">{comparisons[rule.getComparison()]}</Caption>
          <div style={styles.opName}>
            <Label color="light">{rule.getRHS()}</Label>
          </div>
        </InlineList>
        ))}
      </div>
    );
  }
}

const styles = {
  operation: {
    textTransform: 'lowercase',
  },
  staticRuleSet: {
    borderTop: `1px solid ${colors.divider}`,
    padding: 16,
  },
};

export default StaticRuleSet;
