import uuid from 'uuid';

export default function createNodeFromEntity(node, sources, existingNodeId) {
  const isImportNode = node.operation && !!node.operation.startup;
  const importNodeObject = isImportNode && node.operation.startup;
  const isPluginNode = node.hasOwnProperty('pluginId');

  let type = isImportNode ? 'IMPORTNODE' : isPluginNode ? 'PLUGINNODE' : 'WORKFLOWNODE';

  switch (type) {
    case 'IMPORTNODE':
      return {
        id: existingNodeId ? existingNodeId : uuid(),
        name: node.name,
        operation: {
          startup: {
            inputs: importNodeObject.inputs && importNodeObject.inputs,
            outputs: importNodeObject.outputs && importNodeObject.outputs,
          }
        },
        destinations: [],
        sources: sources || [],
        routes: [],
      };

    case 'PLUGINNODE':
      return {
        id: existingNodeId ? existingNodeId : uuid(),
        name: node.name,
        operation: {
          plugin: {
            inputs: node.inputs && node.inputs,
            outputs: node.outputs && node.outputs,
            id: node.id,
          }
        },
        destinations: [],
        sources: sources || [],
        routes: [],
      };

    default:
      return {
        id: existingNodeId ? existingNodeId : uuid(),
        name: node.name,
        operation: {
          swfWorkflow: {
            workflowId: node.workflowId,
            definitionId: node.definitionId,
            entityId: node.entityId
          },
        },
        destinations: [],
        sources: sources || [],
        routes: [],
      };
  }
}