import React from 'react';

const ItemListLabel = ({option, selected}) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <input
      type="checkbox"
      checked={selected}
      readOnly={true}
      style={{ marginRight: 4, marginTop: 0 }}
    />
    <span>&nbsp;{option.label}</span>
  </div>
);

export default ItemListLabel;