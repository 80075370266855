import React, { Component } from 'react';
import { connect } from 'react-redux';

import DocumentTitle from 'react-document-title';
import HelpPanel from '../../containers/HelpPanel';
import Wrapper from '../../containers/Wrapper';
import BreadCrumb from '../../containers/BreadCrumb';
import { Heading, Label, Large } from '../../components/type';
import Panel from '../../containers/Panel';
import TextArea from '../../components/TextArea';
import Button from '../../components/Button';
import Note from '../../containers/Note';
import {cancelWorkflowItems} from '../../actions/workflows';

const pageDescription = `Bulk Item Canceling allows you to cancel workflow items in bulk by copying and pasting a 
list of workflow item IDs into the box below. Velocity reports such as the Workflow Item Aging report can help you 
determine workflow items that need to be canceled. Canceling a workflow item will make it unable for contributors 
to work on.`;
const helpPanelLinks = [
  {
    label: 'Reporting The Basics',
    href: 'https://onespace.helpdocs.com/reporting/reporting-the-basics'
  },
  {
    label: 'Using Workflow Item Search',
    href: 'https://onespace.helpdocs.com/managing-your-work/using-workflow-item-search'
  },
  {
    label: 'Workflow Item Flags. How it Works',
    href: 'https://onespace.helpdocs.com/managing-your-work/flagging-tasks-how-it-works'
  },
];

class BulkWorkflowCancel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workflowIds: '',
      note: {
        text: '',
        type: ''
      },
      cancelling: false
    };

  }

  render() {
    const {workflowId} = this.props;
    let cancelling = this.state.cancelling;
    return (
      <DocumentTitle title="Bulk Item Cancel | OneSpace Project Center">
        <div className="bulk-cancel">
          <HelpPanel
            pageTitle="Bulk Item Cancel"
            pageDescription={pageDescription}
            links={helpPanelLinks}
          />
          <Wrapper>
            <BreadCrumb
              links={[
              { href: `/workflows/${workflowId}`, label: 'Manage Workflow', refresh: true },
              { href: `/workflows/${workflowId}/batch`, label: 'Batch Management', refresh: true },
              { label: 'Bulk Item Cancel'},
            ]}
            />
            <Heading color="darker">Bulk Item Cancel</Heading>
            <Panel
              includeHeader={false}>
              <div style={{padding: 20, paddingTop: 15}}>
                <Large>To cancel workflow items in bulk, paste the workflow item IDs in the box below.</Large>
                <Label italic={true}>Workflow item IDs can be found in the Workflow Item Cost reports</Label>
                <hr/>
                <Label color="lighter" top={10}>Paste workflow item IDs with an ID on each line.</Label>
                <TextArea value={this.state.workflowIds} handleChange={e => this.setState({workflowIds: e})} rows={15} />
                {!!this.state.note.text &&
                  <div className={this.state.note.type === 'success' ? 'success-note' : 'error-note'}>
                    <Note note={this.state.note.text} type={this.state.note.type} />
                  </div>
                }
                <hr/>
                {cancelling ? (
                    <Button>
                      <i className="fa fa-spinner fa-spin"/>
                      Cancelling Items
                    </Button>
                ) : (
                  <Button handleClick={() => {
                  this.cancelWorkflowItems();
                  }}>
                  Cancel Items
                  </Button>
                )}
              </div>
            </Panel>
          </Wrapper>
        </div>
      </DocumentTitle>
    );
  }

  cancelWorkflowItems() {
    let note = { text: '', type: ''};
    this.setState({note: note});
    this.setState({cancelling: !this.state.cancelling});
    const filteredIds = this.state.workflowIds.replace(/ /g,'').split(/[\n,]+/).filter(id => id !== '');
    this.props.dispatch(cancelWorkflowItems(filteredIds))
      .then((response) => {
        if(response.success == true) {
          if(response.data.length === 0) {
            note = { text: 'Bulk Cancellation Complete: ' + filteredIds.length + ' item(s) have been canceled.' ,
              type: 'success'};
            this.setState({workflowIds: ''});
          } else {
            note = { text: 'We were unable to cancel ' + response.data.length + ' of the workflow items due to an ' +
            'error. Please review the item ID(s) above and ensure they are valid.', type: 'error'};
            this.setState({workflowIds: response.data.toString().replace(/,/g, '\n')});
          }
        } else if(response.success === false && response.code === 400) {
          note = {text: 'Please limit your request to under 2500 IDs and try again.', type: 'error'};
        } else {
          note = { text: 'An error occurred trying to process your request. Please try again later.', type: 'error'};
        }
        this.setState({note: note});
        this.setState({cancelling: false});
      });
  }
}



function select(state, rr) {
  const { workflow_id } = rr.match.params;

  return {
    workflowId: workflow_id,
  };
}

export default connect(select)(BulkWorkflowCancel);