import update from 'immutability-helper';

import objectToArray from '../utils/_objectToArray';
import { updateState } from '../utils/reducerHelpers';
import {
  FETCH_PROJECT_CHAINS_SUCCESS,
  FETCH_PROJECT_CHAINS_AND_WORKFLOWS_SUCCESS,
  RECEIVED_CHAIN,
  CREATED_CHAIN,
  CREATED_NODE,
  REMOVED_NODES,
  UPDATED_NODE,
  LAUNCHED_CHAIN,
  FETCH_CHAINS_REQUEST,
  FETCH_CHAINS_SUCCESS,
  FETCH_CHAINS_FAILURE,
  FETCH_CHAIN_REQUEST,
  FETCH_CHAIN_SUCCESS,
  CREATE_CHAIN_SUCCESS,
  UPDATE_CHAIN_REQUEST,
  UPDATE_CHAIN_SUCCESS,
  UPDATE_CHAIN_FAILURE,
  LAUNCH_CHAIN_REQUEST,
  LAUNCH_CHAIN_FAILURE,
  LAUNCH_CHAIN_SUCCESS,
  FETCH_CHAIN_BATCHES_REQUEST,
  FETCH_CHAIN_BATCHES_SUCCESS,
  FETCH_CHAIN_BATCHES_FAILURE,
  UPDATE_CHAIN_BATCH_REQUEST,
  UPDATE_CHAIN_BATCH_SUCCESS,
  CONNECT_NODES,
  CREATE_PLUGININSTANCE_FAILURE,
  CREATE_PLUGININSTANCE_REQUEST,
  CREATE_PLUGININSTANCE_SUCCESS,
  DELETE_PLUGININSTANCE_FAILURE,
  DELETE_PLUGININSTANCE_REQUEST,
  DELETE_PLUGININSTANCE_SUCCESS,
  FETCH_POPULATENEWINSTANCE_REQUEST,
  FETCH_POPULATENEWINSTANCE_SUCCESS,
  FETCH_POPULATENEWINSTANCE_FAILURE,
  UPDATE_PLUGININSTANCE,
  CANCEL_UNSAVEDPLUGININSTANCE,
  FETCH_IS_CHAIN_ACTIVE_REQUEST,
  FETCH_IS_CHAIN_ACTIVE_SUCCESS,
  FETCH_IS_CHAIN_ACTIVE_FAILURE,
  FETCH_CHAIN_METRICS_REQUEST,
  FETCH_CHAIN_METRICS_SUCCESS,
  FETCH_CHAIN_METRICS_FAILURE,
  ARCHIVE_CHAIN_REQUEST,
  ARCHIVE_CHAIN_SUCCESS,
  ARCHIVE_CHAIN_FAILURE,
  UNARCHIVE_CHAIN_REQUEST,
  UNARCHIVE_CHAIN_SUCCESS,
  UNARCHIVE_CHAIN_FAILURE,
  UPDATE_CHAIN_BATCH_NOTIFICATION_REQUEST,
  UPDATE_CHAIN_BATCH_NOTIFICATION_SUCCESS,
  UPDATE_CHAIN_BATCH_NOTIFICATION_FAILURE,
} from '../constants/actionTypes';

const initialState = {
  items: {},
  isFetching: false,
  hasFetched: false,
};

/**
 * Chains Reducer
 * Stores Chains and their Nodes
 *
 * Action Types handled:
 * FETCH_PROJECT_CHAINS_SUCCESS - Adds an array of chains
 * CREATED_CHAIN/RECEIVED_CHAIN - Add a single chain
 * UPDATED_CHAIN - Override a single chain
 * LAUNCHED_CHAIN - Override a single chain with the launched version
 * CREATED_NODE - Adds a node to a chain, and updates source nodes
 * REMOVED_NODES - Remove a node from the chain, and updates the source nodes
 * UPDATE_NODE - Updates a single node under a chain
 */

export default function ( state = initialState, action ) {
  switch ( action.type ) {

    /* Fetch all chains for the company */
    case FETCH_CHAINS_REQUEST:
      return updateState.fetchRequest( state );
    case FETCH_CHAINS_SUCCESS:
      return updateState.fetchSuccess( state, action.chains );
    case FETCH_CHAINS_FAILURE:
      return updateState.fetchFailure( state );

    case FETCH_CHAIN_REQUEST:
      return updateState.fetchRequest( state );
    case FETCH_CHAIN_SUCCESS:
      return updateState.fetchSuccess( state, [ action.chain ] );

    case CREATE_CHAIN_SUCCESS:
      return updateState.createSuccess( state, action.chain );


    case FETCH_PROJECT_CHAINS_SUCCESS:
    case FETCH_PROJECT_CHAINS_AND_WORKFLOWS_SUCCESS:
      if ( !action.chains ) {
        return state;
      }
      return updateState.fetchSuccess( state, action.chains );

    case UPDATE_CHAIN_REQUEST:
    case LAUNCH_CHAIN_REQUEST:
      return updateState.updateRequest( state, action.id );
    case UPDATE_CHAIN_SUCCESS:
    case LAUNCH_CHAIN_SUCCESS:
      return updateState.updateSuccess( state, action.id, action.chain );
    case UPDATE_CHAIN_FAILURE:
    case LAUNCH_CHAIN_FAILURE:
      return updateState.updateFailure( state, action.id );

    case FETCH_CHAIN_BATCHES_REQUEST:
      return updateState.fetchChildrenRequest( state, action.chainId, 'batches' );
    case FETCH_CHAIN_BATCHES_SUCCESS:
      return updateState.fetchChildrenSuccess( state, action.chainId, 'batches' );
    case FETCH_CHAIN_BATCHES_FAILURE:
      return updateState.fetchChildrenSuccess( state, action.chainId, 'batches' );

    case FETCH_CHAIN_METRICS_REQUEST:
      return updateState.updateRequest( state, action.chainId );
    case FETCH_CHAIN_METRICS_SUCCESS:
      return updateState.updateSuccess( state, action.chainId, { metrics: action.metrics } );
    case FETCH_CHAIN_METRICS_FAILURE:
      return updateState.updateFailure( state, action.chainId );

    /* Received a single chain */
    case CREATED_CHAIN:
    case RECEIVED_CHAIN: {
      return update( state, { items: { [action.chain.id]: { $set: Object.assign( {}, state.items[ action.chain.id ], action.chain ) } } } );
    }

    /* Overwrite a chain with an updated version */
    case LAUNCHED_CHAIN:
      return update( state, { [action.chain.id]: { $set: action.chain } } );

    /* Connecting two existing nodes together */
    case CONNECT_NODES: {
      const destination = state.items[ action.chainId ].nodes.find( node => node.id === action.route.destinationNode );
      const sourceNodeUpdates = {
        destinations: [ ...action.source.destinations, destination.id ],
        routes: [ ...action.source.routes, action.route ],
      };
      const destinationNodeUpdates = {
        sources: [ ...destination.sources, action.source.id ],
      };
      const newNodesArray = state.items[ action.chainId ].nodes.map( node => {
        if ( node.id === action.source.id ) {
          return Object.assign( {}, node, sourceNodeUpdates );
        }
        if ( node.id === destination.id ) {
          return Object.assign( {}, node, destinationNodeUpdates );
        }
        return node;
      } );
      return update( state, {
        items: {
          [action.chainId]: {
            nodes: { $set: newNodesArray }
          }
        }
      } );
    }

    /* Add a node to a chain & update the source nodes' routes */
    case CREATED_NODE: {
      // Add the node to the chain
      const nodeUpdate = { $push: [ action.node ] };
      if ( action.node.sources.length ) { // Find and update the source node
        const sourceId = action.node.sources[ 0 ];
        const sourceNodeIndex = state.items[ action.chainId ].nodes.findIndex( node => node.id === sourceId );
        const sourceNode = getNode( sourceId, state );

        // Add a new route and a destination to the source node
        nodeUpdate[ sourceNodeIndex ] = {
          $merge: {
            routes: [ ...sourceNode.routes || [], action.route ],
            destinations: [ ...sourceNode.destinations || [], action.route.destinationNode ],
          },
        };
      }
      return update( state, { items: { [action.chainId]: { nodes: nodeUpdate } } } );
    }

    /* Remove a node and it's tree of children */
    case REMOVED_NODES: {
      const originalNodes = state.items[ action.chainId ].nodes;
      // Find each node to delete
      action.nodes.forEach( node => {
        // "node" is going to be deleted
        const nodeToDelete = originalNodes.find( n => n.id === node.id ); // Get the full node ref

        // Remove this "node"
        originalNodes.splice( originalNodes.findIndex( n => n.id === node.id ), 1 );
        // Loop through each source of the "node" and remove this "node" as a destination & route
        nodeToDelete.sources && nodeToDelete.sources.forEach( sourceId => {
          const sourceNode = originalNodes.find( node => node.id === sourceId );
          if ( sourceNode ) {
            sourceNode.destinations.splice( sourceNode.destinations.indexOf( node.id ), 1 );
            sourceNode.routes.splice( sourceNode.routes.findIndex( route => route.destinationNode === node.id ), 1 );
          }
        } );
      } );
      return update( state, { items: { [action.chainId]: { nodes: { $set: originalNodes || [] } } } } );
    }

    /* Find the node in the chain and update it */
    case UPDATED_NODE: {
      const nodeIndex = state.items[ action.chainId ].nodes.findIndex( node => node.id === action.node.id );
      return update( state, { items: { [action.chainId]: { nodes: { [nodeIndex]: { $set: action.node } } } } } );
    }

    case UPDATE_CHAIN_BATCH_REQUEST:
      return updateState.updateRequest( state, action.chainId );
    case UPDATE_CHAIN_BATCH_SUCCESS:
      return updateState.updateSuccess( state, action.chainId, { ioSchema: action.settings.workflowChain.ioSchema } );

    case CREATE_PLUGININSTANCE_REQUEST:
      return state;
    case CREATE_PLUGININSTANCE_SUCCESS:
      return update(state, { items: { [action.chainId]: { plugins: {[action.index]: { $set: action.instance } } } } });
    case CREATE_PLUGININSTANCE_FAILURE:
      return state;

    case DELETE_PLUGININSTANCE_REQUEST:
      return state;
    case DELETE_PLUGININSTANCE_SUCCESS:
      return update(state, { items: { [action.chainId]: { plugins: { $splice: [[action.instanceIndex, 1]] } } } });
    case DELETE_PLUGININSTANCE_FAILURE:
      return state;

    case FETCH_POPULATENEWINSTANCE_REQUEST:
      return state;
    case FETCH_POPULATENEWINSTANCE_SUCCESS:
      return update(state, { items: { [action.thisChainId]: { plugins: { $unshift: [action.chainPlugin] } } } });
    case FETCH_POPULATENEWINSTANCE_FAILURE:
      return state;

    case CANCEL_UNSAVEDPLUGININSTANCE: {
      if(!action.pluginId) {
        return update(
          state,
          {
            items: {
              [action.thisChainId]: {
                plugins:  {$splice: [[action.index, 1]]}
              }
            }
          }
        );
      }
    }

    case UPDATE_PLUGININSTANCE: {
      if (action.propertyName === 'instanceName') {
        return update(
          state,
          {
            items: {
              [action.thisChainId]: {
                plugins: {[action.index[0]]: {name: {$set: action.update}}}
              }
            }
          }
        );
      }
      if (action.propertyName === 'eventOptions') {
        return update(
          state,
          {
            items: {
              [action.thisChainId]: {
                plugins: {
                  [action.index[0]]: {
                    typeSettings: {
                      eventOptions: {
                        [action.index[1]]: {data: {[0]: {value: {$set: action.update}}}}
                      }
                    }
                  }
                }
              }
            }
          }
        );
      }
      if (action.propertyName === 'inputs' || action.propertyName === 'outputs') {
        if (action.index[1] === null) {
          return update(
            state,
            {
              items: {
                [action.thisChainId]: {
                  plugins: {[action.index[0]]: {[action.propertyName]: {$push: [action.update]}}}
                }
              }
            }
          );
        } else if (action.update === 'removeIO') {
          // Delete input/output
          return update(
            state,
            {
              items: {
                [action.thisChainId]: {
                  plugins: {[action.index[0]]: {[action.propertyName]: {$splice: [[action.index[1], 1]]}}}
                }
              }
            }
          );
        } else {
          return update(
            state,
            {
              items: {
                [action.thisChainId]: {
                  plugins: {
                    [action.index[0]]: {
                      [action.propertyName]: {
                        [action.index[1]]: {[action.update[0]]: {$set: action.update[1]}}
                      }
                    }
                  }
                }
              }
            }
          );
        }
      }
    }
    case FETCH_IS_CHAIN_ACTIVE_REQUEST:
      return updateState.updateRequest( state, action.chainId );
    case FETCH_IS_CHAIN_ACTIVE_SUCCESS:
      if ( state.items[ action.chainId ].isActive !== action.status ) {
        return updateState.updateSuccess( state, action.chainId, { isActive: action.status } );
      }
      return state;
    case FETCH_IS_CHAIN_ACTIVE_FAILURE:
      return updateState.fetchFailure( state );
    case UPDATE_CHAIN_BATCH_NOTIFICATION_REQUEST:
      return updateState.updateRequest( state, action.chainId );
    case UPDATE_CHAIN_BATCH_NOTIFICATION_SUCCESS:
      return updateState.updateSuccess( state, action.chainId, { batchNotification: action.batchNotification } );
    case UPDATE_CHAIN_BATCH_NOTIFICATION_FAILURE:
      return updateState.updateFailure(state, action.chainId);

    case ARCHIVE_CHAIN_REQUEST:
    case UNARCHIVE_CHAIN_REQUEST:
      return updateState.updateRequest(state, action.chainId);

    case ARCHIVE_CHAIN_SUCCESS:
      return updateState.updateSuccess( state, action.chainId, { archived: true, state: 'inactive', ioSchema: [], inputs:[] });
    case UNARCHIVE_CHAIN_SUCCESS:
      return updateState.updateSuccess( state, action.chainId, { archived: false, state: 'inactive', ioSchema: [], inputs:[], nodes: [] });

    case ARCHIVE_CHAIN_FAILURE:
    case UNARCHIVE_CHAIN_FAILURE:
      return updateState.fetchFailure(state);

    default:
      return state;
  }
}


/**
 * getNode
 * Returns a node found in the chains reducer
 *
 * Params:
 * - nodeId - The ID of the node you want returned
 * - state - A state object like {chainID: chain}
 *
 * Returns: A single node element or null if the node is not in state
 */
function getNode( nodeId, state ) {
  const chains = objectToArray( state.items );
  let matchingNode = null;

  if ( !nodeId || typeof nodeId !== 'string' ) {
    throw new Error( 'nodeId must be a string for getNode' );
  }

  chains.forEach( chain => {
    if ( !matchingNode && chain.nodes ) {
      chain.nodes.forEach( node => {
        if ( !matchingNode && node.id === nodeId ) {
          matchingNode = node;
        }
      } );
    }
  } );

  return matchingNode;
}
