import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';

import { fetchChain, fetchChainWorkflows } from '../../actions/chains';

import objectToArray from '../../utils/_objectToArray';

import Panel from '../../containers/Panel';
import HelpPanel from '../../containers/HelpPanel';
import WorkflowMetrics from '../../containers/WorkflowMetrics';
import Wrapper from '../../containers/Wrapper';
import LoadState from '../../containers/LoadState';
import BreadCrumb from '../../containers/BreadCrumb';
import EmptyState from '../../containers/EmptyState';
import { Heading } from '../../components/type';

const pageDescription = `This page gives you a detailed look at the status and performance of your  
individual workflows. The workflows listed here are sorted by batch date. The workflow with the  
newest batch is located at the top. Here, you can manage your workflow items and diagnose  
performance issues. Click on a workflow’s name to make changes to it. Click on Monitor Assignments  
to track the individual assignments that make up a workflow.`;
const helpPanelLinks = [
  {
    label: 'Workflow Monitoring: How It Works',
    href: 'https://onespace.helpdocs.com/managing-your-work/workflow-monitoring-how-it-works'
  },
  {
    label: 'Assignment Monitoring',
    href: 'https://onespace.helpdocs.com/managing-your-work/assignment-monitoring-how-it-works'
  },
];

class Chains extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchingChain: true,
    };
  }

  componentDidMount() {
    const projectId = this.props.match.params.project_id;
    const id = this.props.match.params.chain_id;

    // Get the workflows to display
    this.props.dispatch(fetchChainWorkflows(projectId, id));

    // Get the chain (only using name)
    this.props.dispatch(fetchChain(projectId, id))
      .then(() => this.setState({ fetchingChain: false }));
  }

  render() {
    const { chain, chainWorkflows, fetchingChainWorkflows } = this.props;
    return (
      <DocumentTitle title="Chain Workflow Monitoring | OneSpace Project Center">
        <div className="t-chain-workflows">
          {/* Help Panel */}
          <HelpPanel
            pageTitle="Workflow Monitoring"
            links={helpPanelLinks}
            pageDescription={pageDescription}
          />

          <Wrapper>
            {/* Show loading if fetching chain workflows */}
            {fetchingChainWorkflows &&
            <LoadState label="Loading your workflows"/>
            }

            {/* Show content when fetching is complete*/}
            {!fetchingChainWorkflows &&
            <div>
              <BreadCrumb links={[
                { label: 'Workflow Monitoring', href: '/workflows' },
                { label: chain.name }
              ]}/>

              {/* Page Title */}
              <Heading color="dark" bottom={24}>{chain.name}</Heading>

              {/* Render all the workflow monitors */}
              {chainWorkflows.map(workflow => (
                <div key={workflow.id} style={{marginTop: 16}}>
                  <Panel
                    title={workflow.name}
                    actions={[{icon: 'cogs', href: `/workflows/${workflow.id}`, label: 'Manage'}]}>
                    <div style={{padding: 16}}>
                      <WorkflowMetrics workflow={workflow} />
                    </div>
                  </Panel>
                </div>
              ))}
            </div>
            }

            {/* Show an empty state if the chain doesn't have any workflows */}
            {(!fetchingChainWorkflows && !chainWorkflows.length) &&
            <EmptyState label={`${chain.name} does not contain any workflows.`}/>
            }
          </Wrapper>
        </div>
      </DocumentTitle>
    );
  }
}

const select = (state, rr) => {
  const chainWorkflows = objectToArray(state.workflows.items)
    .filter(workflow => (workflow.id_workflow_chain === rr.match.params.chain_id) && workflow.itemStatus && workflow.archived.toString() === '0');
  return {
    chain: state.chains.items[rr.match.params.chain_id] || {},
    chainWorkflows,
    fetchingChainWorkflows: state.workflows.isFetching,
  };
};

export default connect(select)(Chains);