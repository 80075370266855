import { updateState } from '../utils/reducerHelpers';
import {
  FETCH_CHAINPLUGINS_REQUEST,
  FETCH_CHAINPLUGINS_SUCCESS,
  FETCH_CHAINPLUGINS_FAILURE,
  CREATE_CHAINPLUGIN_REQUEST,
  CREATE_CHAINPLUGIN_SUCCESS,
  CREATE_CHAINPLUGIN_FAILURE,
  UPDATE_CHAINPLUGIN_REQUEST,
  UPDATE_CHAINPLUGIN_SUCCESS,
  UPDATE_CHAINPLUGIN_FAILURE,
  FETCH_CHAINPLUGIN_REQUEST,
  FETCH_CHAINPLUGIN_SUCCESS,
  FETCH_CHAINPLUGIN_FAILURE,
  FETCH_CHAINPLUGINVERSION_REQUEST,
  FETCH_CHAINPLUGINVERSION_SUCCESS,
  FETCH_CHAINPLUGINVERSION_FAILURE,
  PUBLISH_CHAINPLUGIN_REQUEST,
  PUBLISH_CHAINPLUGIN_SUCCESS,
  PUBLISH_CHAINPLUGIN_FAILURE,
} from '../constants/actionTypes';

const initialState = {
  items: {},
  isFetching: false,
  hasFetched: false,
};

export default function (state = initialState, action) {
  switch (action.type) {

    /* Fetch all chains for the company */
    case FETCH_CHAINPLUGINS_REQUEST:
      return updateState.fetchRequest(state);
    case FETCH_CHAINPLUGINS_SUCCESS:
      return updateState.fetchSuccess(state, action.chainPlugins);
    case FETCH_CHAINPLUGINS_FAILURE:
      return updateState.fetchFailure(state);

    case FETCH_CHAINPLUGIN_REQUEST:
      return updateState.fetchRequest(state);
    case FETCH_CHAINPLUGIN_SUCCESS:
      return updateState.fetchSuccess(state, [action.chainPlugin]);
    case FETCH_CHAINPLUGIN_FAILURE:
      return updateState.fetchFailure(state);

    case FETCH_CHAINPLUGINVERSION_REQUEST:
      return updateState.fetchRequest(state);
    case FETCH_CHAINPLUGINVERSION_SUCCESS:
      return updateState.fetchVersionSuccess(state, action.chainPlugin);
    case FETCH_CHAINPLUGINVERSION_FAILURE:
      return updateState.fetchFailure(state);

    case CREATE_CHAINPLUGIN_REQUEST:
      return updateState.createRequest(state);
    case CREATE_CHAINPLUGIN_SUCCESS:
      return updateState.createSuccess(state, action.plugin);
    case CREATE_CHAINPLUGIN_FAILURE:
      return updateState.createFailure(state);

    case UPDATE_CHAINPLUGIN_REQUEST:
      return updateState.updateRequest(state, action.id);
    case UPDATE_CHAINPLUGIN_SUCCESS:
      return updateState.updateSuccess(state, action.id, action.plugin.plugin);
    case UPDATE_CHAINPLUGIN_FAILURE:
      return updateState.updateFailure(state, action.id);

    case PUBLISH_CHAINPLUGIN_REQUEST:
      return updateState.createRequest(state, action.id);
    case PUBLISH_CHAINPLUGIN_SUCCESS:
      return updateState.createVersionSuccess(state, action.published);
    case PUBLISH_CHAINPLUGIN_FAILURE:
      return updateState.createFailure(state);

    default:
      return state;
  }
}