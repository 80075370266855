import React from 'react';
import PropTypes from 'prop-types';

import colors from '../styles/colors';
import { Caption } from './type';

/**
 * Tile
 * Small component used to display status of an item.
 * Good to use in an ItemList (spacing is usually 8)
 */
const Tile = ({ label, href, incomplete, active, failed }) => (
  <Caption
    isLink={!!href}
    href={href}
    color={failed ? 'negative' : incomplete ? 'lighter' : 'dark'}>
    <div style={styles(active, incomplete)}>
      {label}
    </div>
  </Caption>
);

/* Styles */
const styles = (active, incomplete) => ({
  background: active ? colors.positiveLight : incomplete ? colors.background : colors.background,
  border: `1px ${incomplete ? 'dashed' : 'solid'} ${incomplete ? colors.placeholder : colors.border}`,
  borderRadius: 4,
  paddingLeft: 8,
  paddingRight: 8,
  minWidth: 64,
  textAlign: 'center',
});

/* PropTypes */
Tile.propTypes = {
  label: PropTypes.node.isRequired, // Name of the item
  href: PropTypes.string, // Optional link
  incomplete: PropTypes.bool, // If true, border will be dashed
  active: PropTypes.bool, // If true background will be light-green
  failed: PropTypes.bool, // If true color will be red
};

export default Tile;
