import React, { Component } from 'react';
import { DragSource } from 'react-dnd';
import listensToClickOutside from 'react-onclickoutside';
import Radium from 'radium';

import LayoutOneColumn from './Components/LayoutOneColumn';
import LayoutTwoColumn from './Components/LayoutTwoColumn';
import LayoutThreeColumn from './Components/LayoutThreeColumn';
import LayoutFourColumn from './Components/LayoutFourColumn';
import HorizontalRule from './Components/HorizontalRule';
import Heading from './Components/Heading';
import Paragraph from './Components/Paragraph';
import Image from './Components/Image';
import Link from './Components/Link';
import CheckBox from './Components/CheckBox';
import DropList from './Components/DropList';
import TextField from './Components/TextField';
import TextArea from './Components/TextArea';
import RadioButton from './Components/RadioButton';
import TextDifference from './Components/TextDifference';
import Email from './Components/Email';
import Upload from './Components/Upload';
import HiddenInput from './Components/HiddenInput';
import HiddenOutput from './Components/HiddenOutput';
import { Label } from '../../../../../components/type';

import PreviewTarget from './PreviewTarget';
import { ItemTypes, ElementTypes, ElementOptions } from '../constants';

import colors from '../../../../../styles/colors';

export let elements = {
  [ElementTypes.ONECOLUMNLAYOUT]: LayoutOneColumn,
  [ElementTypes.TWOCOLUMNLAYOUT]: LayoutTwoColumn,
  [ElementTypes.THREECOLUMNLAYOUT]: LayoutThreeColumn,
  [ElementTypes.FOURCOLUMNLAYOUT]: LayoutFourColumn,
  [ElementTypes.HR]: HorizontalRule,
  [ElementTypes.HEADING]: Heading,
  [ElementTypes.PARAGRAPH]: Paragraph,
  [ElementTypes.IMAGE]: Image,
  [ElementTypes.LINK]: Link,
  [ElementTypes.CHECKBOX]: CheckBox,
  [ElementTypes.DROPLIST]: DropList,
  [ElementTypes.TEXTFIELD]: TextField,
  [ElementTypes.TEXTAREA]: TextArea,
  [ElementTypes.RADIOBUTTON]: RadioButton,
  [ElementTypes.TEXTDIFFERENCE]: TextDifference,
  [ElementTypes.EMAIL]: Email,
  [ElementTypes.UPLOAD]: Upload,
  [ElementTypes.HIDDENINPUT]: HiddenInput,
  [ElementTypes.HIDDENOUTPUT]: HiddenOutput,
};

export const sortAtoZ = (a, b) => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
};

const elementSource = {
  beginDrag(props) {
    props.handleUnselect();
    return {
      type: props.element.type,
      index: props.index,
    };
  },
  canDrag(props) {
    return !props.selected;
  }
};

function isClickOnPreview(el) {
  while ((el = el.parentElement) && !el.classList.contains('tui-preview'));
  return el;
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

function objCompare(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}

function compareIndex(index1, index2) {
  let indexDoesntMatch = false;
  if(index1.length) {
    index1.forEach((a, i) => {
      if( a !== index2[i]) {
      indexDoesntMatch = true;
      }
    });
  } else if(index1 !== index2){
    indexDoesntMatch = true;
  }
  return indexDoesntMatch;
}

class Preview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outputError: null,
      requiredError: null,
      outputIsInUse: null
    };
    this.verifyOutput = this.verifyOutput.bind(this);
  }
  
  shouldComponentUpdate(nextProps, nextState) {
    if (!objCompare(nextState, this.state) || nextProps.selected || this.props.selected || nextProps.isDragging || this.props.isDragging || compareIndex(nextProps.index, this.props.index)) {
      return true;
    }
    return false;
  }

  handleClickOutside(e) {
    this.setState({ outputIsInUse: null, requiredError: null, outputError: null });
    if (!this.props.selected || (e && e.x >= window.innerWidth - 45)) {
      return false;

    }
    if (this.props.selected && this.props.handleUnselect) {
      const elementOption = ElementOptions.find(ele => ele.type === this.props.element.type);
      if (elementOption.output) {
        if (!this.props.element.data.output) {
          return this.setState({ outputError: 'Please provide an output name' });
        }
        if (this.props.outputs.filter(output => output && output.output === this.props.element.data.output).length >= 2) {
          return this.setState({ outputError: 'This output name is already in use' });
        }
        if (this.props.inputs.indexOf(this.props.element.data.output) >= 0) {
          return this.setState({ outputError: 'This name is already an input' });
        }
      }
      if (e.target.classList.contains('tui-preview') || isClickOnPreview(e.target)) {
        return false;
      }
      if (ElementOptions.find(ele => ele.type === this.props.element.type).output) {
        if (!this.props.element.data.output) {
          return this.setState({ outputError: 'Please provide an output name' });
        } else if (this.state.outputError) {
          this.setState({ outputError: null });
        }
      }

      if (this.props.element.type === 'PARAGRAPH') {
        if (!this.props.element.data._innerValue) {
          return this.setState({ requiredError: 'Required element property is missing: paragraph text' });
        }
      }

      if (this.props.element.type === 'TEXTDIFFERENCE') {
        if (!this.props.element.data.origin && !this.props.element.data.edited) {
          return this.setState({ requiredError: 'Please provide an Original Value and a Latest Value' });
        }
        if (!this.props.element.data.origin) {
          return this.setState({ requiredError: 'Please provide an Original Value' });
        }
        if (!this.props.element.data.edited) {
          return this.setState({ requiredError: 'Please provide a Latest Value' });
        }
      }
      if (e) {
        this.props.handleUnselect(e);
      }
    }
  };

  verifyOutput(newOutput) {
    const {inputs, outputs} = this.props;
    this.setState({outputError: null});
    const elementOption = ElementOptions.find(ele => ele.type === this.props.element.type);
    const expandedInputs = [];
    inputs.forEach(input => {
      let matches = input.match(/\[[0-9]+(-[0-9]+)]/);
      expandedInputs.push(input);
      if(!!matches) {
        const iNumbers = matches[0].split(/\[|\]|\-/);
        for(let i = +iNumbers[1]; i <= iNumbers[2]; i++) {
          expandedInputs.push(input.replace(matches[0], i));
        }
      }
    });

    if (elementOption.output) {
      if (outputs.filter(output => output && output.output === newOutput && output.tempId !== this.props.element.tempId).length >= 1) {
        return this.setState({ outputError: 'This output name is already in use' });
      }
      if (expandedInputs.indexOf(newOutput) >= 0) {
        return this.setState({ outputError: 'This name is already an input' });
      }
    }
  }

  render() {
    const {
      element,
      connectDragSource,
      isDragging,
      selected,
      outputs,
      inputs,
      previousOutputs,
      handleSelect,
      handleChange,
      handleUnselect,
      handleNewElement,
      handleMoveElement,
      handleAddToLibrary,
      handleMakeRepeatable,
      handleUpdateElement,
      handleDeleteElement,
      handleSelectInput,
      index,
      selectedChild,
      dispatch,
      selectedColumn,
      inputIndex,
      isSupportRole,
      locked,
      numDuplicatesForChildren,
    } = this.props;

    const Element = elements[element.type];
    const elementLocked = locked && !isSupportRole;
    const isRepeatable = ['ONECOLUMNLAYOUT', 'TWOCOLUMNLAYOUT', 'THREECOLUMNLAYOUT', 'FOURCOLUMNLAYOUT'].indexOf(element.type) >= 0;
    const numDuplicates = element.numberOfDuplicates;

    return connectDragSource(
      <div>
        <PreviewTarget
          elementType={element.type}
          index={index}
          handleNewElement={handleNewElement}
          handleMoveElement={handleMoveElement}>
          <div
            className="tui-preview"
            onClick={(e) => {
              if (elementLocked) {
                return e.preventDefault();
              }
              return handleSelect(e);
            }}
            style={
              [
                styles.main(numDuplicates),
                (selected && !locked) && styles.selected,
                isDragging && styles.dragging,
                elementLocked && styles.noEvents,
              ]
            }>
            <div style={styles.typeLabelWrap}>
              <div
                style={[styles.type, selected && styles.typeSelected]}>
                {element.name ? element.name : element.type}
              </div>
              {elementLocked &&
              <div
                title="This element is locked."
                style={[styles.type, styles.typeLocked]}>
                Locked
                <i style={{ marginLeft: 6 }} className="fa fa-lock"/>
              </div>
              }
            </div>
            <Element
              className={element.type}
              data={element.data}
              selected={selected && (!locked || isSupportRole)}
              updateElement={handleChange}
              handleUnselect={handleUnselect}
              handleSelectInput={handleSelectInput}
              outputs={outputs}
              inputs={inputs}
              previousOutputs={previousOutputs}
              outputError={this.state.outputError}
              handleChangeOutput={(newOutput) => this.verifyOutput(newOutput)}
              parentIndex={index}
              dispatch={dispatch}
              selectedColumn={selectedColumn}
              selectedChild={selectedChild}
              inputIndex={inputIndex}
              isSupportRole={isSupportRole}
              numDuplicates={numDuplicates}
              numDuplicatesForChildren={numDuplicatesForChildren}
            />

            {(!!element.customId && !locked) &&
              <div
                onClick={handleUpdateElement}
                title="Update Element"
                style={[styles.actionIcon, styles.updateElement, selected && styles.deleteSelected]}>
                <i className="fa fa-floppy-o" />
              </div>
            }
            {(!locked || isSupportRole) &&
            <div>
              {isRepeatable &&
              <div
                onClick={handleMakeRepeatable}
                title="Make Repeatable Layout"
                style={[styles.actionIcon, selected && styles.repeatableLayout(!!element.customId)]}>
                <i style={{position: 'relative', top: 4}} className="fa fa-clone"/>
                {numDuplicates &&
                <div style={styles.repeatableCount}>
                  <Label center={true} color={'white'}>{element.numberOfDuplicates}</Label>
                </div>
                }
              </div>
              }
              <div
                onClick={handleAddToLibrary}
                title="Add to Library"
                style={[styles.actionIcon, styles.addToLibrary, selected && styles.deleteSelected]}>
                <i className="fa fa-plus"/>
              </div>
              <div
                onClick={handleDeleteElement}
                title="Delete"
                style={[styles.actionIcon, selected && styles.deleteSelected]}>
                <i className="fa fa-trash"/>
              </div>
            </div>
            }
            {this.state.requiredError && <div style={styles.error}>{this.state.requiredError}</div>}
          </div>
        </PreviewTarget>
      </div>);
  }
}

const styles = {
  main: (numDuplicates) => ({
    position: 'relative',
    backgroundColor: `${colors.background}`,
    border: `2px solid ${colors.typeLight}`,
    padding: '16px 24px 8px 24px',
    boxSizing: 'border-box',
    borderRadius: 5,
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    boxShadow: numDuplicates ? `-3px 3px ${colors.white}, -10px 10px ${colors.tealBorder}` : 'none',
    ':hover': {
      boxShadow: numDuplicates ? `-3px 3px ${colors.white}, -10px 10px ${colors.tealBorder}` : `0 1px 2px ${colors.hooverShadow}`
    },
    zIndex: 1,
  }),
  selected: (numDuplicates) => ({
    border: colors.typeSelected,
    boxShadow: numDuplicates ? `-3px 3px ${colors.white}, -10px 10px ${colors.tealBorder}` : `0 2px 0 ${colors.hooverShadow}`
  }),
  dragging: {
    opacity: 0
  },
  noEvents: {
    pointerEvents: 'none',
  },
  typeLabelWrap: {
    position: 'absolute',
    top: -12,
    left: 8,
    display: 'flex',
  },
  type: {
    backgroundColor: `${colors.typeLight}`,
    borderRadius: 4,
    color: '#707070',
    fontSize: 10,
    fontWeight: 800,
    textTransform: 'uppercase',
    marginRight: 6,

    height: 24,
    lineHeight: '24px',
    paddingLeft: 8,
    paddingRight: 8,
    transition: 'all 0.2s ease',
  },
  typeLocked: {
    backgroundColor: `${colors.typeLocked}`,
  },
  typeSelected: {
    backgroundColor: `${colors.typeSelected}`,
    color: 'white'
  },
  actionIcon: {
    position: 'absolute',
    cursor: 'pointer',
    top: -28,
    right: 0,
    background: `${colors.typeSelected}`,
    color: 'white',
    height: 28,
    padding: '4px 16px',
    textAlign: 'center',
    borderRadius: 5,
    opacity: 0,
    display: 'none',
    fontSize: 13,
    lineHeight: '22px',
    transition: 'box-shadow 0.2s ease-in',
    boxShadow: 'none'
  },
  addToLibrary: {
    right: 44,
  },
  repeatableLayout: (updateAvailable) => ({
    right: updateAvailable ? 133 : 88,
    display: 'flex',
    opacity: 1,
  }),
  repeatableCount: {
    background: colors.primary(),
    borderRadius: 50,
    width: 20,
    marginLeft: 5,
  },
  updateElement: {
    right: 87,
  },
  deleteSelected: {
    opacity: 1,
    display: 'inline-block'
  },
  error: {
    fontSize: 12,
    fontWeight: 800,
    color: 'white',
    background: '#f94d1f',
    padding: 16,
    borderRadius: 4
  }
};

export default DragSource(ItemTypes.ELEMENT, elementSource, collect)(Radium(listensToClickOutside(Preview)));
