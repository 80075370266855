import React from 'react';
import Radium from 'radium';
import Layout from './Layout';

class LayoutTwoColumn extends React.Component {

  render() {
    const { data, dispatch, selected, parentIndex, isStatic, updateElement, handleSelectInput, isSupportRole, numDuplicates } = this.props;
    return (
      <div>
        <Layout
          numDuplicates={numDuplicates}
          numberOfColumns={2}
          data={data}
          dispatch={dispatch}
          selected={selected}
          parentIndex={parentIndex}
          elementType={'TWOCOLUMNLAYOUT'}
          isStatic={isStatic}
          updateElement={updateElement}
          handleSelectInput={handleSelectInput}
          isSupportRole={isSupportRole}
        />
      </div>
    );
  }
}

export default Radium(LayoutTwoColumn);
