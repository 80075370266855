export const config = {
  consumeRightClick: false,
  layout: {
    type: 'Hierarchical',
    parameters: {
      orientation: 'vertical',
      padding: [100, 10]
    }
  },
  enablePanButtons: false,
  view: {
    nodes: {
      'ghost': {
        parent: 'default',
        template: 'ghost'
      },
      'startup': {
        parent: 'default',
        template: 'startup'
      }
    },
    edges: {
      'normal': {
        connector: [ 'Flowchart', { gap: 5, cornerRadius: 10 } ],
        overlays:[
          [ 'Arrow', { location: 1, width: 12, length: 8 }],
          [ 'Label', {
            cssClass: 'connection-label',
            label: function(connection) {
              let sourceId = connection.component.source.dataset.jtkNodeId;
              let targetId = connection.component.target.dataset.jtkNodeId;
              return `<i class='fa fa-ellipsis-v' data-source='${sourceId}' data-target='${targetId}'/>`;
            }
          }
          ]
        ],
        endpoints: [
          ['Blank', {radius: 1}],
          ['Blank', {radius: 1}]
        ]
      },
      'ghost': {
        connector: [ 'Flowchart', { cornerRadius: 10 } ],
        paintStyle: {
          strokeWidth: 1,
          stroke: '#d9d9d9',
          dashstyle: '1 1'
        },
        endpoints: [ [ 'Blank', { radius: 0 } ], 'Blank' ]
      },
      'invalid': {
        parent: 'normal',
        overlays:[
          [ 'Arrow', { location: 1, width: 12, length: 8 }],
          [ 'Label', {
            cssClass: 'connection-label red',
            label: function(connection) {
              let sourceId = connection.component.source.dataset.jtkNodeId;
              let targetId = connection.component.target.dataset.jtkNodeId;
              return `<i class='fa fa-ellipsis-v' data-source='${sourceId}' data-target='${targetId}'/>`;
            }
          }
          ]
        ],
        paintStyle: {
          stroke: '#F94D1E',
        }
      }
    }
  },
  events: {
    modeChanged: function (mode) {
      jsPlumb.removeClass(jsPlumb.getSelector('[mode]'), 'selected-mode');
      jsPlumb.addClass(jsPlumb.getSelector('[mode="' + mode + '"]'), 'selected-mode');
    }
  },
  elementsDraggable: false,
  jsPlumb: {
    Anchors: ['Right', 'Left'],
    Connector: ['Flowchart', {alwaysRespectStubs: true, cornerRadius: 3, stub: 6}],
    PaintStyle: {strokeWidth: 3, stroke: '#d9d9d9'},
    // HoverPaintStyle: {stroke: '#DBDAD9', strokeWidth: 4},
    Endpoints: [
      ['Dot', {radius: 1}],
      ['Dot', {radius: 1}]
    ],
    EndpointStyle: {fill: '#DBDAD9'},
    EndpointHoverStyle: {fill: '#FF6600'}
  }

};

export default config;
