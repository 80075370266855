import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { StyleSheet, css } from 'aphrodite';

import objectToArray from '../../utils/_objectToArray';
import Wrapper from '../../containers/Wrapper';
import BreadCrumb from '../../containers/BreadCrumb';
import { Caption, Heading, Label, Large } from '../../components/type';
import Panel from '../../containers/Panel';
import TextArea from '../../components/TextArea';
import Button from '../../components/Button';
import Note from '../../containers/Note';
import Select from '../../components/Select.jsx';
import Input from '../../components/Input.jsx';
import HelpPanel from '../../containers/HelpPanel';
import LoadState from '../../containers/LoadState';
import { promoteContributors, fetchAssignments } from '../../actions/assignments';
import colors from '../../styles/colors';

const pageDescription = `This page allows you to change the proficiency levels of contributors in bulk.
Contributor reports such as the Contributor Performance report can help identify contributors
to promote or demote. You can also find contributor IDs in a workflow batch export by checking
“Include Contributor Information” when you export the workflow batch. Changing a contributors
proficiency level can impact their access to work on the related assignments.`;

const helpPanelLinks = [
  {
    href: 'https://onespace.helpdocs.com/teams-contributors/managing-contributors-taking-actions',
    label: 'Managing Contributors: Taking Action',
  },
  {
    href: 'https://onespace.helpdocs.com/teams-contributors/communicating-with-contributors',
    label: 'Communicating With Contributors',
  },
  {
    href: 'https://docs.google.com/document/d/1gag-cGX4v60sjj4Qkaz2dGMoPRvVWuTzCcKz3hOH5m8/edit',
    label: 'Reporting The Basics',
  }
];

const radioOptions =[
  { name: 'level', value: 1, caption: 'Beginner' },
  { name: 'level', value: 2, caption: 'Intermediate' },
  { name: 'level', value: 3, caption: 'Advanced' },
  { name: 'level', value: 4, caption: 'Expert' },
  { name: 'level', value: -1, caption: 'Blocked (Once a contributor is blocked they' +
  ' will no longer have any access to the assignment.)', highlight: true },
];

class BulkPromoteDemote extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      level: '',
      contributorNote: '',
      internalNote: '',
      workerIds: '',
      assignmentId: props.initialAssignmentId,
      skillName: '',
      successNote: {
        text: '',
        type: ''
      },
      errorNote: {
        text: '',
        type: ''
      },
      selectedAssignment: '',
      promoting: false
    };
  }

  componentDidMount() {
    this.props.dispatch( fetchAssignments() );
  }

  promoteContributors() {
    const {
      assignmentId,
      level,
      contributorNote,
      internalNote,
      workerIds
    } = this.state;
    const { assignments } = this.props;
    this.setState({
      assignmentIdError: '',
      levelError: '',
      contributorNoteError: '',
      internalNoteError: '',
      workerIdsError: '',
    });
    if( !assignmentId ) {
      this.setState({
        assignmentIdError: 'Please select an assignment.',
        promoting: false,
      });
    }
    if( !level ) {
      this.setState({
        levelError: 'Please select the desired proficiency level.',
        promoting: false,
      });
    }
    if( !contributorNote ) {
      this.setState({
        contributorNoteError: 'Please provide a message to the contributor.',
        promoting: false,
      });
    }
    if ( !internalNote ) {
      this.setState({
        internalNoteError: 'Please provide a note for internal use.',
        promoting: false,
      });
    }
    if ( !workerIds ) {
      this.setState({
        workerIdsError: 'Please provide at least one contributor ID.',
        promoting: false,
      });
    }
    if ( assignmentId && level && contributorNote && internalNote && workerIds ) {
      let successNote = { text: '', type: '' };
      let errorNote = { text: '', type: '' };
      this.setState({ promoting: !this.state.promoting });
      const skillName = assignments.find( assignment => {
        return assignment.id === assignmentId;
      });
      const filteredIds = workerIds.replace(/ /g, '').split(/[\n,]+/).filter(id => id !== '');
      if ( filteredIds.length > 2500 ) {
        errorNote = {
          text: 'Please limit your request to under 2500 IDs and try again.',
          type: 'error'
        };
        this.setState({
          errorNote,
          promoting: false
        });
      } else {
        this.props.dispatch(
          promoteContributors(
            filteredIds,
            level,
            contributorNote,
            internalNote,
            assignmentId,
            skillName.name
          )
        ).then((response) => {
          if(response.code === 400){
            errorNote = {
              text: `${filteredIds.length} ${(( filteredIds.length === 1 ) ? `contributor` : `contributors` )} could not be leveled due to an error. Please review the Contributor ID(s) above and ensure they are valid.`,
              type: 'error'
            };
          } else {
            const promoted = response.promotedWorkersCount;
            const demoted = response.demotedWorkersCount;
            const stayedSame = response.sameLevelWorkersCount;
            const blocked = response.blockedWorkersCount;
            const skill = response.skillStanding;
            const failedIds = [];
            response.failedWorkers.forEach( x => {
              failedIds.push(x.workerId);
            });
            if ( response.failedWorkers.length === 0 ) {
              successNote = {
                text: `Leveling Complete: 
                 ${(( promoted > 0 ) ? `${promoted} ${(( promoted ===1 ) ? `contributor was` : `contributors were`)} promoted. ` : ``)}
                 ${(( demoted > 0 ) ? `${demoted} ${(( demoted === 1 ) ? `contributor was` : `contributors were`)} demoted. ` : ``)}
                 ${(( stayedSame > 0  && skill !== -1) ? `${stayedSame} ${(( stayedSame === 1 ) ? `contributor` : `contributors`)} stayed at the same level.` : ``)}             
                 ${(( blocked > 0 ) ? `${blocked} ${(( blocked === 1 ) ? `contributor was` : `contributors were`)} blocked.` : ``)}
                 ${(( skill === -1 && stayedSame > 0 ) ? `${stayedSame} ${(( stayedSame === 1 ) ? `contributor` : `contributors`)} stayed blocked.` : ``)}`,
                type: 'success'
              };
            } else if ( filteredIds.length === failedIds.length ) {
              errorNote = {
                text: `${failedIds.length} ${(( failedIds.length === 1 ) ? `contributor` : `contributors` )} could not be leveled due to an error. Please review the Contributor ID(s) above and ensure they are valid.`,
                type: 'error'
              };
            } else if ( failedIds.length > 0 && filteredIds.length > failedIds.length ) {
              successNote = {
                text: `Leveling Complete: 
                 ${(( promoted > 0 ) ? `${promoted} ${(( promoted ===1 ) ? `contributor was` : `contributors were`)} promoted. ` : ``)}
                 ${(( demoted > 0 ) ? `${demoted} ${(( demoted === 1 ) ? `contributor was` : `contributors were`)} demoted. ` : ``)}
                 ${(( stayedSame > 0  && skill !== -1) ? `${stayedSame} ${(( stayedSame === 1 ) ? `contributor` : `contributors`)} stayed at the same level.` : ``)}             
                 ${(( blocked > 0 ) ? `${blocked} ${(( blocked === 1 ) ? `contributor was` : `contributors were`)} blocked.` : ``)}
                 ${(( skill === -1 && stayedSame > 0 ) ? `${stayedSame} ${(( stayedSame === 1 ) ? `contributor` : `contributors`)} stayed blocked.` : ``)}`,
                type: 'success'
              };
              errorNote = {
                text: `${failedIds.length} ${(( failedIds.length === 1 ) ? `contributor` : `contributors` )} could not be leveled due to an error. Please review the Contributor ID(s) above and ensure they are valid.`,
                type: 'error'
              };
            } else {
              errorNote = {
                text: 'An error occurred trying to process your request. Please try again later.',
                type: 'error'
              };
            }
            this.setState({workerIds: failedIds.toString().replace(/,/g, '\n')});
          }
          this.setState({
            successNote,
            errorNote,
            promoting: false
          });
        });
      }
    }
  }

  render() {
    const {
      promoting,
      assignmentId,
      assignmentIdError,
      levelError,
      contributorNote,
      contributorNoteError,
      internalNote,
      internalNoteError,
      workerIds,
      workerIdsError,
      successNote,
      errorNote
    } = this.state;

    const {
      assignments,
      loadingAssignments,
      initialAssignmentId
    } = this.props;

    return (
      <DocumentTitle title="Contributor Levels | OneSpace Project Center">
        <div>
          <HelpPanel
            pageTitle="Contributor Levels"
            pageDescription={pageDescription}
            links={helpPanelLinks}
          />
          <Wrapper>
            <div>
            {!!initialAssignmentId &&
              <BreadCrumb
                links={[
                    { href: `/company/contributors/`, label: 'Contributor Monitoring', refresh: true },
                    { href: `/assignments/${initialAssignmentId}/contributors`, label: 'Manage Assignment', refresh: true },
                    { label: 'Contributor Levels'},
                  ]}
              />
            }
            </div>
            <Heading color="dark">Manage Contributor Proficiency Levels</Heading>
            <Panel includeHeader={false}>
              <div style={{padding: 20}}>
                <Large>
                  To change the proficiency level for a group of contributors, select the desired
                  assignment and proficiency level, then paste the IDs* for the contributors you would
                  like to promote or demote.
                </Large>
                <Label italic={true}>
                  *Contributor IDs can be found in the Contributor Proficiency Levels or Contributor Performance reports.
                </Label>
                <hr/>
                  { loadingAssignments ? <LoadState/> :
                    <Select
                      options={assignments.map(assignment => {
                        return {
                          label: `${assignment.name}`,
                          value: assignment.id
                        };
                      })}
                      searchable={true}
                      value={assignmentId}
                      handleSelect={assignmentId => this.setState({ assignmentId })}
                      itemType="ASSIGNMENT NAME"
                      smallList={true} />
                  }
                {!!assignmentIdError &&
                <Label italic={true} color="negative">{assignmentIdError}</Label>
                }
                <Label
                  color="light"
                  top={15}>
                  Select the desired proficiency level below:
                </Label>
                <div>
                  {radioOptions.map((option) => (
                    <label key={option.value} className={css( styles.background, option.highlight && styles.highlightBackground )}>
                      <div className={css( styles.radioBtn, option.highlight && styles.highlightBtn )}>
                        <Input
                          type="radio"
                          value={option.value}
                          name={option.name}
                          handleChange={e => this.setState({level: e})}/>
                      </div>
                      <div className={css( styles.labels, option.highlight && styles.highlightLabel )}>
                        <Caption >{option.caption}</Caption>
                      </div>
                    </label>
                  ))
                  }
                  {!!levelError &&
                  <Label italic={true} color="negative">{levelError}</Label>
                  }
                </div>
                <hr/>
                <Label
                  color="light"
                  top={15}>
                  Let the contributors know why you&rsquo;re changing their level:
                </Label>
                <TextArea
                  rows={5}
                  value={contributorNote}
                  error={contributorNoteError}
                  handleChange={e => this.setState({contributorNote: e})} />
                <Label
                  color="light"
                  top={15} >
                  Provide a note for internal use:
                </Label>
                <TextArea
                  rows={5}
                  value={internalNote}
                  error={internalNoteError}
                  handleChange={e => this.setState({internalNote: e})} />
                <Label
                  color="light"
                  top={15} >
                  Paste the Contributors IDs below with a single ID on each line.
                </Label>
                <TextArea
                  rows={10}
                  value={workerIds}
                  error={workerIdsError}
                  handleChange={e => this.setState({workerIds: e})}/>
                {!!successNote.text &&
                  <div style={{marginBottom: 2}} className={successNote.type === 'success' ? 'success-note' : 'error-note'}>
                    <Note
                      note={successNote.text}
                      type={successNote.type}
                      handleClose={() => this.setState({successNote: ''})}/>
                  </div>
                }
                {!!errorNote.text &&
                <div className={errorNote.type === 'success' ? 'success-note' : 'error-note'}>
                  <Note
                    note={errorNote.text}
                    type={errorNote.type}
                    handleClose={() => this.setState({errorNote: ''})}/>
                </div>
                }
                <div style={{marginTop: 15}}>
                  { promoting ? (
                    <Button >
                      <i style={{marginRight: 5}} className="fa fa-spinner fa-spin"/>
                      Changing Levels
                    </Button>
                  ) : (
                    <Button handleClick={() => {
                      this.promoteContributors();
                    }}>
                      Change Level
                    </Button>
                  )}
                </div>
              </div>
            </Panel>
          </Wrapper>
        </div>
      </DocumentTitle>
    );
  }
}

const styles = StyleSheet.create({
  background: {
    display: 'block',
    flex: 1,
    height: 25,
  },
  highlightBackground: {
    height: 40,
    background: colors.background,
    position: 'relative',
    marginTop: 20,
    borderRadius: 5,
    width: 700,
    left: -10,
    top: -5,
    marginBottom: -5,
  },
  radioBtn: {
    flex: 1,
    width: 15,
    height: 15,
    marginRight: 10,
  },
  highlightBtn: {
    position: 'relative',
    top: -7,
    left: 10,
  },
  labels: {
    marginLeft: 20,
    position: 'relative',
    top: 1,
  },
  highlightLabel: {
    top: -7,
    left: 10,
  }
});

function select( state, rr ) {
  const assignments = objectToArray(state.assignments.items);
  const initialAssignmentId = rr.match.params.assignment_id || null;
  return {
    assignments,
    loadingAssignments: state.assignments.isFetching,
    initialAssignmentId,
  };
}

export default connect( select )( BulkPromoteDemote );
