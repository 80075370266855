import React from 'react';
import Radium from 'radium';
import Layout from './Layout';

class LayoutThreeColumn extends React.Component {

  render() {
    const { data, dispatch, selected, parentIndex, isStatic, updateElement, handleSelectInput, isSupportRole } = this.props;
    return (
      <div>
        <Layout
          numberOfColumns={3}
          data={data}
          dispatch={dispatch}
          selected={selected}
          parentIndex={parentIndex}
          elementType={'THREECOLUMNLAYOUT'}
          isStatic={isStatic}
          updateElement={updateElement}
          handleSelectInput={handleSelectInput}
          isSupportRole={isSupportRole}
        />
      </div>
    );
  }
}

export default Radium(LayoutThreeColumn);
