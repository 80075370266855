import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { connect } from 'react-redux';

import InlineButton from '../../../components/InlineButton';
import FlatButton from '../../../components/FlatButton';

import './release-notes.css';


class ReleaseNotification extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      body: '',
      noteIndex: 0,
    };
  }

  componentDidMount() {
    if ( this.props.preview ) {
      return this.setState( { body: this.props.releaseNotes[ 0 ] } );
    } else if ( !this.props.preview ) {
      this.setState( { body: this.props.releaseNotes[ 0 ].body, disableNextButton: true } );
      if ( this.props.releaseNotes.length === 1 ) {
        this.setState( { disablePreviousButton: true, disableNextButton: true } );
      }
    }
  }

  showPreviousRelease() {
    if(this.state.noteIndex === 0) {
      this.setState( {
        body: this.props.releaseNotes[1].body,
        disableNextButton: false,
        disablePreviousButton: false,
        noteIndex: 1
      } );
    } else {
      this.setState( {
        body: this.props.releaseNotes[2].body,
        disableNextButton: false,
        disablePreviousButton: true,
        noteIndex: 2
      } );
    }
  }

  showNextRelease() {
    if(this.state.noteIndex === 1) {
      this.setState( {
        body: this.props.releaseNotes[0].body,
        disableNextButton: true,
        disablePreviousButton: false,
        noteIndex: 0
      } );
    } else {
      this.setState( {
        body: this.props.releaseNotes[1].body,
        disableNextButton: false,
        disablePreviousButton: false,
        noteIndex: 1
      } );
    }
  }

  render() {
    const { body, disableNextButton, disablePreviousButton } = this.state;
    return (
      <div>
        <div
          className="releaseNotesBody"
          style={{maxHeight: '70%', marginRight: -16, overflow: 'auto', padding: '14px 20px 14px 4px'}}
          dangerouslySetInnerHTML={{__html: body}}>
        </div>
        {!this.props.preview &&
        <div>
          <hr/>
          <div className={css(styles.releaseNav)}>
            <div style={{display: 'flex'}}>
              <div style={{marginRight: 8}}>
                <InlineButton
                  disabled={disablePreviousButton}
                  handleClick={() => this.showPreviousRelease()}>
                  Previous
                </InlineButton>
              </div>
              <InlineButton
                disabled={disableNextButton}
                handleClick={() => this.showNextRelease()}>
                Next
              </InlineButton>
            </div>
            <a href="https://onespace.helpdocs.com/platform-release-updates" target="_blank">
              <FlatButton >Support Site</FlatButton>
            </a>
          </div>
        </div>
        }
      </div>
    );
  }
}

const styles = StyleSheet.create( {
  releaseNav: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  releaseNoteBody: {
    fontFamily: 'Open Sans',
  }
} );


ReleaseNotification.propTypes = {
  noteBody: PropTypes.string,
  previousNoteBody: PropTypes.string,
};

function select( state ) {
  let releaseNotes = [];
  const preview = state.app.modalMeta.preview;
  if ( state.app.modalMeta.preview ) {
    releaseNotes.push( state.app.modalMeta.body );
  } else {
    releaseNotes = state.releaseNotes.latestReleaseNotes;
  }
  return { releaseNotes, preview };
}

export default connect( select )( ReleaseNotification );
