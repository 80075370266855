import React from 'react';

import {Caption} from '../../components/type';
import InlineButton from '../../components/InlineButton';
import colors from '../../styles/colors';

const EmptyChainPanel = ({projectId, chainId}) => (
  <div style={styles.emptyContainer}>
    <div style={styles.emptyContent}>
      <div style={{display: 'flex', justifyContent: 'center', marginBottom: 5, padding: '0 16px'}}>
        <Caption color='white' center={true}>
          There are no workflows associated with this chain. Get started by adding a workflow to your chain.
        </Caption>
      </div>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <InlineButton handleClick={() => window.location = `/projects/${projectId}/chains/${chainId}?activeTab=editor`} >
          Resume Setup
        </InlineButton>
      </div>
    </div>
  </div>
);

const styles = {
  emptyContainer: {
    display: 'flex',
    flex: 6,
    background: colors.light,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0 0 8px 8px',
    height: 200,
  },
  emptyContent: {
    background: colors.dark,
    height: 160,
    width: 300,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 5,
    flexDirection: 'column',
  },
};

export default EmptyChainPanel;