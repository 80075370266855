function isValidDate(string) {
  if (!string) {
    return false;
  }
  return !!Date.parse(string);
}

function toISOString(date) {
  if (!isValidDate(date)) {
    throw new Error('Invalid date:', date);
  }
  return isISOString(date) ? date : `${date}Z`;
}

function isISOString(date) {
  return isValidDate(date) && date[date.length - 1] === 'Z';
}

export function newestFirst(a, b) {
  const aDate = a.createdAt || a.created_at;
  const bDate = b.createdAt || b.created_at;
  return new Date(toISOString(bDate)) - new Date(toISOString(aDate));
}
