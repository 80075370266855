import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import numeral from 'numeral';

import {toggleModal} from '../../actions/app';
import {cancelChainBatch} from '../../actions/chains';
import {cancelWorkflowBatch} from '../../actions/workflows';
import {CANCEL_ITEMS} from '../../constants/modalTypes';
import Button from '../../components/Button';
import { Label } from '../../components/type';

class CancelItems extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const batch = this.props.batch;
    const pausedItemCount = !!batch.items && !!batch.items.paused ? batch.items.paused : 0;
    const openItemCount = !!batch.items && !!batch.items.open ? batch.items.open : 0;
    const stagedItemCount = !!batch.items && !!batch.items.staged ? batch.items.staged : 0;
    const cancelableItemCount = pausedItemCount + openItemCount + stagedItemCount;

    return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Label>{numeral(cancelableItemCount).format('0,0')} items will be cancelled.</Label>
        <Label
          bottom={16}
          top={16}>
          Are you sure you want to continue?
        </Label>
        <Button
          disabled={this.state.isCancelling}
          handleClick={() => {
            this.setState({isCancelling: true});
            const cancelBatch = this.props.batchType === 'workflowBatch' ? cancelWorkflowBatch : cancelChainBatch;
            this.props.dispatch(cancelBatch(batch.id))
              .then(() => this.setState({isCancelling: false}))
              .then(() => this.props.dispatch(toggleModal(CANCEL_ITEMS)));
          }}>
          {this.state.isCancelling
            ? <i className="fa fa-spinner fa-spin"/>
            : <span>Cancel Items</span>
          }
        </Button>
        <Label
          top={16}
          bottom={16}
          center={true}
          italic={true}>
          <span>All open items associated with this workflow will be cancelled.&nbsp;</span>
          <span>This process might take a little time depending on the number of items.</span>
        </Label>
        <Label
          underline={true}
          handleClick={() => this.props.dispatch(toggleModal(CANCEL_ITEMS))}>
          Nevermind! I don't want to cancel these items.
        </Label>
      </div>
    );
  }
}

CancelItems.propTypes = {
  batch: PropTypes.object,
};
CancelItems.defaultProps = {
  batch: {},
};

function select(state) {
  const batchType = state.app.modalMeta.batchType;
  return {
    batchType,
    batch: state.app.modalMeta.batch || {}
  };
}

export default connect(select)(CancelItems);
