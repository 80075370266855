import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import PropTypes from 'prop-types';

import LoadState from './LoadState';

import colors from '../styles/colors';
import { Label } from '../components/type';

//This component is a copy of the TabSection component with some exceptions
//-This component only takes in content as a child and displays it. New content is fetched through handlers and
// brought in through props
//-There are also style changes on this component
//When the My Projects page is removed/redesigned we should replace the original TabSection component and rename this one
class LazyTabSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingContent: false,
    };

    this.getNewContent = this.getNewContent.bind(this);
  }

  getNewContent(tabLabel, tabIndex) {
    this.setState({loadingContent: true}, () => this.props.handleTabClick(tabLabel, tabIndex));
  }

  render() {
    const { tabLabels, tabContent, tabClasses, activeTab } = this.props;
    return (
      <div>
        <div className={css(styles.tabs)}>
          {tabLabels.map((label, i) => (
            <Label
              key={i}
              handleClick={() => this.getNewContent(label, i)}
              underline={activeTab !== i}
              color={activeTab === i ? 'dark' : 'light'}
              className={
                `${css(styles.tab,
                  activeTab === i && styles.activeTab,
                  activeTab === (i-1) && styles.inactiveRight,
                  activeTab === (i+1) && styles.inactiveLeft)}
                  ${tabClasses[i]}`
              }>
              {label}
            </Label>
          ))}
        </div>
        <div className={css(styles.content)}>
          {tabContent === null ? <LoadState/> : tabContent}
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  tabs: {
    display: 'flex',
    alignItems: 'flex-end',
    borderBottom: `2px solid ${colors.divider}`,
  },
  tab: {
    display: 'flex',
    alignItems: 'center',
    height: 30,
    paddingLeft: 16,
    paddingRight: 16,
    marginBottom: -2,
  },
  activeTab: {
    background: 'white',
    border: `2px solid ${colors.divider}`,
    borderBottom: 'none',
  },
  inactiveLeft: {
    background: colors.shaded,
    border: `2px solid ${colors.divider}`,
    borderRight: 'none',
  },
  inactiveRight: {
    background: colors.shaded,
    border: `2px solid ${colors.divider}`,
    borderLeft: 'none',
  },
  content: {},
});

LazyTabSection.propTypes = {
  tabLabels: PropTypes.array,
  tabContent: PropTypes.node,
  tabClasses: PropTypes.array,
  activeTab: PropTypes.number,
};
LazyTabSection.defaultProps = {
  tabLabels: [],
  tabClasses: [],
  activeTab: 0,
};

export default LazyTabSection;