import React, { Component } from 'react';
import { connect } from 'react-redux';

import stateHelpers from '../../utils/stateHelpers';
import objectToArray from '../../utils/_objectToArray';
import { fetchProjectChainsAndWorkflows } from '../../actions/projects';
import { createChain } from '../../actions/chains';
import Panel from '../../containers/Panel';
import LoadState from '../../containers/LoadState';
import Tile from '../../components/Tile';
import InlineList from '../../components/InlineList';
import EmptyState from '../../containers/EmptyState';

class ProjectChains extends Component {
  constructor(props) {
    super(props);
    this.state = {
      panelActions: [],
    };
  }
  componentWillMount() {
    this.props.dispatch(fetchProjectChainsAndWorkflows(this.props.id)).then(() => this.getPanelActions());
  }

  getPanelActions() {
    let actions = [
      {
        icon: 'plus-square',
        handleClick: () => this.handleCreateChain(),
        label: 'Add Chain'
      }
    ];
    if(this.props.chains.some(chain => {return chain.archived;})) {
      actions.unshift({
        icon: 'archive',
        href: `/chains?sort=last-created&projects=${this.props.id}&projects=all&status=archived`,
        label: 'View Archived'
      });
    }
    this.setState({panelActions: actions});
  }

  handleCreateChain() {
    const {project} = this.props;
    this.props.dispatch( createChain( {
      name: `${project.name} - ${new Date().toLocaleString()}`,
      projectId: project.id,
    } ) ).then( ( { chain } ) => {
      this.setState( { isCreatingProject: false } );
      window.location = `/projects/${project.id}/chains/${chain.id}`;
      // this.props.history.push(`/projects/${this.props.selectedProject.id}/chains/${chain.id}`);
    } );
  }

  render() {
    const { panelActions } = this.state;
    const { chains, userCanEdit } = this.props;
    return (
      <Panel
        actions={ userCanEdit ? panelActions : [] }
        title="Workflow Chains"
        simple={true}>
        {this.props.loadingChains &&
        <LoadState />
        }
        {(!this.props.loadingChains && (!chains.length || (chains.filter(c => c.archived).length === chains.length))) &&
        <EmptyState label="No Workflow Chains"/>
        }
        {(!this.props.loadingChains && !!chains.length) &&
        <div style={{ padding: '16px 16px 8px 16px' }}>
          <InlineList spacing={8} flexWrap={true}>
            {chains.filter(c => !c.archived).map(chain => (
              <div key={chain.id} style={{ marginBottom: 8 }}>
                <Tile
                  label={chain.name}
                  href={userCanEdit ? `/projects/${this.props.id}/chains/${chain.id}` : null}
                />
              </div>
            ))}
          </InlineList>
        </div>
        }
      </Panel>
    );
  }
}


function isLoading(state) {
  const project = state.projects.items[projectId];
  return !project || !project.chains || project.chains.isFetching;
}
function getProjectChains(project, chains) {
  return objectToArray(chains)
    .filter(chain => chain.projectId.toString() === project.toString());
}
let projectId;
function select(state, { id }) {
  if (id) {
    projectId = id;
  }
  return {
    chains: getProjectChains(projectId, state.chains.items),
    loadingChains: isLoading(state),
    userCanEdit: stateHelpers.canEdit(state.projects.items[projectId], state),
    project: state.projects.items[projectId],
  };
}
export default connect(select)(ProjectChains);
