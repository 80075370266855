import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Label} from '../../../../components/type';
import InlineList from '../../../../components/InlineList';
import InlineSelect from '../../../../components/InlineSelect';
import InlineButton from '../../../../components/InlineButton';
import colors from '../../../../styles/colors';
import { fetchChainPluginVersion, fetchChainPlugins } from '../../../../actions/chainPlugins';
import { updatePluginInstance } from '../../../../actions/chains';
import Instance from './Instance';

const getOptionFields = (chainPlugins) => {
  return Object.keys(chainPlugins).map(key => {
    return {'label': chainPlugins[key].name, 'value': chainPlugins[key].id};
  });
};

const getVersionOptionFields = (chainPlugins, selectedPluginId) => {
  const versions = [...Array(chainPlugins[selectedPluginId].version-1).keys()];
  return Object.keys(versions).map(key => {
    return {'label': (Number(key) + 1).toString(), 'value': Number(key)+1};
  });
};

class ChainPlugins extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPluginId: null,
      selectedVersion: null,
      loadingPluginsDropdown: false,
      addingPluginInstance: false,
    };
    this.handlePopulatingInstance.bind(this);
  }

  componentDidMount() {
    if(this.props.chainPlugins && !Object.keys(this.props.chainPlugins).length) {
      this.setState({ loadingPluginsDropdown: true });
      this.props.dispatch(fetchChainPlugins()).then(() => this.setState({ loadingPluginsDropdown: false }));
    }
  }

  handlePopulatingInstance() {
    // Set second argument to true to be able distinguish between fetching plugin version or fetching
    //plugin version for instance.
    this.setState({ addingPluginInstance: true });
    this.props.dispatch(fetchChainPluginVersion(this.state.selectedPluginId, this.state.selectedVersion, this.props.chainId, true)).then(() => {
      this.setState({ addingPluginInstance: false, selectedPluginId: null, selectedVersion: null });
    });
  }

  render() {
    const { chainPlugins, chainId, dispatch, chainPluginInstances, active, chainNodes } = this.props;
    const showPluginDropdown = chainPluginInstances.some(instance => !instance.pluginId);
    const areMultipleVersionsAvailable = !!this.state.selectedPluginId && !!chainPlugins && chainPlugins[this.state.selectedPluginId].version > 1;

    return(
      <div style={{position: 'relative'}}>
        {!showPluginDropdown &&
          <div style={{ marginBottom: 30, marginTop: 15 }}>
            <InlineList>
              <div style={{ flex: 1 }}>
                <Label color="light">Select a Plugin</Label>
                <InlineSelect
                  height={45}
                  disabled={this.state.loadingPluginsDropdown || this.state.addingPluginInstance || active}
                  value={!this.state.selectedPluginId ? 'Select' : this.state.selectedPluginId}
                  defaultLabel={this.state.loadingPluginsDropdown ? 'Loading Available Plugins' : 'Select'}
                  handleSelect={val => {
                    this.setState({ selectedPluginId: val !== 'Select' ? val : null, selectedVersion: null });
                  }}
                  options={getOptionFields(chainPlugins || [])}
                />
              </div>
              <div style={{ flex: 1 }}>
                <div style={{ paddingLeft: 30, position: 'relative', top: 10 }}>
                  <InlineButton
                    loading={this.state.loadingPluginsDropdown || this.state.addingPluginInstance}
                    disabled={!this.state.selectedPluginId || this.state.addingPluginInstance || (areMultipleVersionsAvailable && !this.state.selectedVersion)}
                    handleClick={() => {
                      this.handlePopulatingInstance();
                    }}>
                    {this.state.loadingPluginsDropdown ? 'Please wait...' : this.state.addingPluginInstance ? 'Adding plugin instance to your chain...' : 'Add New Plugin Instance'}
                  </InlineButton>
                </div>
              </div>
            </InlineList>
            {areMultipleVersionsAvailable &&
              <div style={{ width: '50%' }}>
                <Label color="light">Select a Version</Label>
                <InlineSelect
                  height={45}
                  disabled={this.state.loadingPluginsDropdown || this.state.addingPluginInstance}
                  value={!this.state.selectedVersion ? 'Select' : this.state.selectedVersion}
                  defaultLabel="Select"
                  handleSelect={val => {
                    this.setState({ selectedVersion: val !== 'Select' ? val : null });
                  }}
                  options={getVersionOptionFields(chainPlugins, this.state.selectedPluginId)}
                />
              </div>
            }

          </div>
        }

        {chainPluginInstances.filter(inst => !!inst.active).map((instance, idx) =>
          <div key={instance.id} style={Object.assign({}, styles.instanceContainer, !instance.pluginId && styles.noTopBorder)}>
            <Instance
              allInstances={chainPluginInstances}
              dispatch={dispatch}
              chainId={chainId}
              inChain={chainNodes.some(node => !!node.operation.plugin && node.operation.plugin.id === instance.id)}
              unsavedInstance={this.state.selectedPluginId === instance.id}
              instance={instance}
              styles={styles}
              index={idx}
              active={active}
              handlePopulatingPluginInstances={plugin => this.handlePopulatingPluginInstances(plugin)}
              updateChainPluginInstance={(type, val, index, instanceId) => {
                const pluginId = !!instance.pluginId ? instance.pluginId : null;
                this.props.dispatch(updatePluginInstance(type, val, [idx, index], instanceId, pluginId, chainId));
              }}
              chainPlugins={chainPlugins}
            />
          </div>
        )}
      </div>
    );
  }
}

function select(state, rr) {
  const chainId = rr.chainId;
  return {
    chainPlugins: state.chainPlugins.items && state.chainPlugins.items,
    chainPluginInstances: state.chains.items[chainId] && state.chains.items[chainId].plugins || [],
  };
}

/* PropTypes */
ChainPlugins.propTypes = {
  chainPlugins: PropTypes.object,
  chainId: PropTypes.string,
  chainPluginInstances: PropTypes.array,
  active: PropTypes.bool,
};

const styles = {
  instanceContainer: {
    borderTop: `1px solid ${colors.border}`,
    paddingTop: 20,
    marginBottom: 20,
  },
  noTopBorder: {
    borderTop: 'none',
    paddingTop: 0,
  },
  inputField: {
    margin: '15px 0 20px',
  },
  radioBtn: {
    flex: 1,
    width: 15,
    height: 15,
    marginRight: 10,
  },
  labels: {
    marginLeft: 20,
    marginRight: 10,
    position: 'relative',
    top: 1,
  },
};

export default connect(select)(ChainPlugins);