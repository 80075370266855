import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDropZone from 'react-dropzone';

import colors from '../styles/colors';
import { Label, Caption } from '../components/type';

/**
 * DropZone
 * Area to drop files for upload
 */
class DropZone extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleDrop = this.handleDrop.bind(this);
    this.clearError = this.clearError.bind(this);
  }

  handleDrop(files, rejects) {
    if (this.props.locked) {
      return;
    }

    const filetypeErrorMsg = !this.props.preprocessorEnabled ?
      `Please upload a .csv file. If you have a spreadsheet, save it as a CSV file before uploading.` :
      `Only the following file extensions are allowed: .csv, .xlsx, .xlst, .xls, .json, .xml, .html, doc, .docx, .zip, .odt and .ods.`;

    if (rejects.length) {
      return this.setState({ error: filetypeErrorMsg });
    }
    const file = files[0];
    return this.props.handleDrop(file);
  }

  clearError() {
    this.setState({ error: null });
  }

  render() {
    return (
      <ReactDropZone
        ref={node => {
          if (node && !node.fileInputEl.className) {
            node.fileInputEl.className = 't-dropzone';
          }
        }}
        accept={this.props.accept}
        style={styles.dropZone(this.props.locked)}
        multiple={false}
        onDrop={this.handleDrop}
        disableClick={this.props.locked}>
        <i
          className="fa fa-upload"
          style={{ fontSize: 20, color: !this.props.locked ? colors.dark : colors.lighter }}
        />
        {!this.state.error ?
          // Drop Message
          !this.props.locked ?
            this.props.dropzoneLabel ?
            <Label isLink={true} color="light">{this.props.dropzoneLabel}</Label> :
          <Label isLink={true} color="light">
            Drop your CSV file here or&nbsp;
            <Label inline={true} color="dark" underline={true} isLink={true}>browse files</Label>
            &nbsp;to import a batch of items
          </Label> :
          <Label color="lighter">{this.props.lockedLabel}</Label> :
          // Error Message
          <div>
            <Label color="negative">{this.state.error}</Label>
            <Caption center={true} underline={true} color="light" handleClick={this.clearError}>
              Remove
            </Caption>
          </div>
        }
      </ReactDropZone>
    );
  }
}

/* Styles */
const styles = {
  dropZone: locked => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 112,
    background: !locked ? colors.highlight : colors.background,
    border: `1px dashed ${colors.lighter}`,
    borderRadius: 4,
    cursor: !locked ? 'pointer' : 'no-drop',
  }),
};

/* PropTypes */
DropZone.propTypes = {
  accept: PropTypes.string,
  locked: PropTypes.bool,
  lockedLabel: PropTypes.string,
  handleDrop: PropTypes.func.isRequired,
};
DropZone.defaultProps = {
  accept: '.csv',
};

export default DropZone;
