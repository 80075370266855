import {
  ADD_LIBRARY_ELEMENT_REQUEST,
  ADD_LIBRARY_ELEMENT_SUCCESS,
  ADD_LIBRARY_ELEMENT_FAILURE,
  DELETE_LIBRARY_ELEMENT_REQUEST,
  DELETE_LIBRARY_ELEMENT_SUCCESS,
  DELETE_LIBRARY_ELEMENT_FAILURE,
  FETCH_LIBRARY_ELEMENTS_REQUEST,
  FETCH_LIBRARY_ELEMENTS_SUCCESS,
  FETCH_LIBRARY_ELEMENTS_FAILURE,
  UPDATE_LIBRARY_ELEMENT_REQUEST,
  UPDATE_LIBRARY_ELEMENT_SUCCESS,
  UPDATE_LIBRARY_ELEMENT_FAILURE,
  ELEMENT_CHANGED,
} from '../actionTypes';

import { correspondingIcons } from '../constants';

// get window.workflow.projectId if available, otherwise use 8733 or some other static id for testing on /taskui
const projectId = (window.workflow && window.workflow.projectId) ? window.workflow.projectId : '8733';

export function elementChanged(change) {
  return {
    type: ELEMENT_CHANGED,
    change,
  };
}

export function fetchLibraryElements() {
  return {
    types: [FETCH_LIBRARY_ELEMENTS_REQUEST, FETCH_LIBRARY_ELEMENTS_SUCCESS, FETCH_LIBRARY_ELEMENTS_FAILURE],
    fetchRoute: `/api/projects/${projectId}/library`,
    fetchMethod: 'get',
    getFormattedResponse: ( result ) => {
      const elementsArray = [];
      result.forEach(el => {
        el.element.customId = el._id;
        el.element.cat = 'custom';
        el.element.icon = correspondingIcons[el.element.type];
        elementsArray.push(el.element);
      });

      return ({elements: elementsArray});
    }
  };
}

export function addLibraryElement(element, allProjectsSelected) {
  return {
    types: [ADD_LIBRARY_ELEMENT_REQUEST, ADD_LIBRARY_ELEMENT_SUCCESS, ADD_LIBRARY_ELEMENT_FAILURE],
    fetchRoute: `/api/projects/${projectId}/library`,
    fetchMethod: 'post',
    fetchBody: {element, allProjectsSelected},
    getFormattedResponse: ( result ) => {
      result.element.customId = result._id;
      result.element.cat = 'custom';
      result.element.icon = correspondingIcons[result.element.type];
      // Return element type and element because the type gets set by default to action type
      return ({ elementType: result.element.type, element: result.element });
    }
  };
}

export function deleteLibraryElement(id) {
  return {
    types: [DELETE_LIBRARY_ELEMENT_REQUEST, DELETE_LIBRARY_ELEMENT_SUCCESS, DELETE_LIBRARY_ELEMENT_FAILURE],
    fetchRoute: `/api/projects/${projectId}/library/${id}`,
    fetchMethod: 'delete',
    payload: { id },
  };
}

export function updateLibraryElement(element, id) {
  return {
    types: [UPDATE_LIBRARY_ELEMENT_REQUEST, UPDATE_LIBRARY_ELEMENT_SUCCESS, UPDATE_LIBRARY_ELEMENT_FAILURE],
    fetchRoute: `/api/projects/${projectId}/library/${id}`,
    fetchMethod: 'put',
    fetchBody: {element},
    payload: {id},
    getFormattedResponse: ( result ) => ({ element: result.element }),
  };
}
