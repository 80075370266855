import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';

import colors from '../styles/colors';
import Menu from '../components/Menu';

/**
 * Hamburger Menu
 * Used for small inline menus
 */

class HamburgerMenu extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      openMenu: false,
    };
    this.toggleMenu = this.toggleMenu.bind( this );
  }

  handleClickOutside() {
    this.setState( { openMenu: false } );
  }

  toggleMenu() {
    this.setState( { openMenu: !this.state.openMenu } );
  }

  render() {
    const { openMenu } = this.state;
    const { options, handleSelect } = this.props;
    return (
      <div style={{ position: 'relative', cursor: 'pointer' }}>
        <div onClick={() => {
          this.toggleMenu();
        }}>
          <div style={styles.hamburger}></div>
          <div style={styles.hamburger}></div>
          <div style={styles.hamburger}></div>
        </div>
        {openMenu &&
        <div style={styles.itemList} onClick={() => {
          this.toggleMenu();
        }}>
          <Menu
            width={200}
            options={options}
            handleSelect={handleSelect}/>
        </div>
        }
      </div>
    );
  }
}

/* Styles */
const styles = {
  hamburger: {
    width: 16,
    height: 3,
    backgroundColor: colors.dark,
    margin: '2px 0px',
    boxSizing: 'border-box',
    borderRadius: 4,
  },
  itemList: {
    position: 'absolute',
    right: 0,
    zIndex: 9
  }
};

/* PropTypes */
HamburgerMenu.propTypes = {
  handleSelect: PropTypes.func,
  options: PropTypes.array,
};

export default onClickOutside( HamburgerMenu );