import React, {Component} from 'react';
import {DropTarget} from 'react-dnd';
import classNames from 'classnames';
import Radium from 'radium';

import {ItemTypes, nonselfdroppablelayoutTypes} from '../constants';

const spacerTarget = {
  canDrop(props, monitor) {
    const { type } = monitor.getItem();
    return !(nonselfdroppablelayoutTypes.includes(props.elementType) && nonselfdroppablelayoutTypes.includes(type));
  },
  drop(props, monitor) {
    const { type, index, element } = monitor.getItem();
    if (Number.isInteger(index) || Array.isArray(index)) {
      return props.handleMoveElement(index, props.index);
    }
    props.handleNewElement(type, element);
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  };
}

class Spacer extends Component {

  render() {
    const { connectDropTarget, isOver, empty, canDrop } = this.props;
    return connectDropTarget(
      <div
        style={[styles.main, isOver && styles.open, empty && styles.empty, empty && isOver && styles.openEmpty]}>
        {empty &&
        <div style={[styles.emptyState, isOver && styles.emptyStateOpen]}>
          <i
            style={styles.emptyStateIcon}
            className={classNames('fa', {
              'animate-attention': isOver,
              'fa-hand-paper-o': isOver,
              'fa-hand-rock-o': canDrop,
              'fa-hand-pointer-o': !canDrop
            })}
          />
          <div>{isOver ? 'Drop to add the element' : 'Drag an element here'}</div>
        </div>
        }
      </div>
    );
  }
}

const styles = {
  main: {
    height: 80,
    width: '100%',
    borderRadius: 2,
    flex: '1 0 0'
  },
  open: {},
  empty: {
    flex: '1 0 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    border: '2px dashed rgba(0, 0, 0, 0.12)',
    transition: 'all 0.2s ease-in',
    marginTop: 16,
    textAlign: 'center',
  },
  openEmpty: {
    border: '2px dashed rgb(0, 196, 255)'
  },

  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontWeight: 800,
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.54)',
    transition: 'all 0.2s ease-in'
  },
  emptyStateOpen: {
    color: 'rgb(0, 196, 255)'
  },
  emptyStateIcon: {
    fontSize: 32,
    marginBottom: 24
  }
};

export default DropTarget(ItemTypes.ELEMENT, spacerTarget, collect)(Radium(Spacer));
