import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import { Label, Large, NavTitle } from '../../components/type';
import IconButton from '../../components/IconButton';
import colors from '../../styles/colors';

class TeamDetails extends Component {
  render() {
    const {teamName, teamDescription, memberCount, activeAssignments, teamId, teamSwfId, handleClick} = this.props;
    const teamInfo = {
      teamId: teamSwfId,
      teamName: teamName
    };
    return(
      <div>
        <NavTitle
          href={`/teams/${teamId}`}
          underline={true}
          hoverColor="primary">
          {teamName}
        </NavTitle>
        <div className={css(styles.description)}>
          <div style={{maxWidth: '80%'}}>
            <Large>{teamDescription}</Large>
          </div>
          <IconButton
            icon="envelope"
            handleClick={() => handleClick(teamInfo)} >
            Message Entire Team
          </IconButton>
        </div>
        <div className={css(styles.countsContainer)}>
          <div className={css(styles.flexBoxOne)}>
            <Label color="light">Members</Label>
            <div style={{marginLeft: 5}}>
              <Label
                href={`/teams/${teamId}`}
                underline={true} hoverColor="primary">
                {memberCount}
              </Label>
            </div>
          </div>
          <div className={css(styles.flexBoxOne)}>
            <Label color="light">Active Assignments</Label>
            <div style={{marginLeft: 5}}>
              <Label color="light">{activeAssignments}</Label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  description: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  countsContainer: {
    display: 'flex',
    background: colors.background,
    padding: '24px 20px',
  },
  flexBoxOne: {
    display: 'flex',
    flex: 1,
  }
});

TeamDetails.propTypes = {
  teamName: PropTypes.string.isRequired,
  teamDescription: PropTypes.string,
  memberCount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  activeAssignments: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  teamId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  teamSwfId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  handleClick: PropTypes.func,
};

TeamDetails.defaultProps = {
  handleClick: () => {},
};


export default TeamDetails;