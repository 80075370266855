const uri = require( 'urijs' );

const QueryStringTools = {
  toJSON: function() {
    let decodedURI = decodeURI(window.location.search);
    const decodedObj = new uri( decodedURI ).search( true );
    let arrayNormalizedObj = {};
    Object.entries(decodedObj).forEach(([k,v]) => {
      if(k.includes('[]')) {
        arrayNormalizedObj[k.replace('[]', '')] = [v];
      } else {
        arrayNormalizedObj[k] = v;
      }
    });
    return arrayNormalizedObj;
  },

  setParam: function( param, val, reset=false ) {
    let p = param === 'q' ? 'q' : param + '[]';
    if ( val ) {
      if (reset) {
        return new uri( '' ).setSearch( p, val ).query();
      }
      return new uri( window.location.search ).setSearch( p, val ).query();
    }
    return new uri( window.location.search ).removeSearch( p ).query();
  },

  setQuery: function( q ) {
    window.history.pushState( null, document.title, q ? `?${q}` : window.location.pathname );
  },

  getParam: function( param ) {
    return new uri( window.location.search ).search( true )[ param ];
  }
};

export default QueryStringTools;
