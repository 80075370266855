import React from 'react';
import PropTypes from 'prop-types';

import TextArea from './TextArea';
import { Label } from './type';

const TextAreaLabel = (props) => (
  <div>
    <Label color="light">{props.label}</Label>
    <TextArea {...props} />
  </div>
);

/* PropTypes */
TextAreaLabel.propTypes = {
  label: PropTypes.string.isRequired, // Label above text area
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default TextAreaLabel;
