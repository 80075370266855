import React from 'react';
import update from 'immutability-helper';
import Radium from 'radium';

import InlineCheckbox from '../../Form/InlineCheckbox';
import InlineInput from '../../Form/InlineInput';
import Input from '../../Form/Input';
import Select from '../../Form/Select';
import Settings from '../Settings';
import Switch from '../../Form/Switch';
import SupportRoleOptions from './SupportRoleOptions';

import {sortAtoZ} from '../../Preview';

class RadioButton extends React.Component {
  componentDidMount() {
    if (this.props.selected) {
      this.inline.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.selected && !this.props.selected) {
      this.props.data.options.forEach((option, i) => {
        if(!option.label && !option.key) {
          this.props.updateElement({options: update(this.props.data.options, {$splice: [[i, 1]]})});
        }
      });
    }
    if (!prevProps.selected && this.props.selected) {
      this.inline.focus();
      this.props.handleChangeOutput(this.props.data.output);
    }
  }

  render() {
    const {data = {}, selected, updateElement, outputError, handleSelectInput, inputs, previousOutputs, isSupportRole, handleChangeOutput} = this.props;
    let defaultInputs = inputs ? inputs.map(i => ({label: i, value: i})) : [];
    defaultInputs = previousOutputs ? [...defaultInputs, ...previousOutputs.map(i => ({label: i.label, value: i.label}))] : defaultInputs;
    return (
      <div>
        <div style={styles.label}>
          <InlineInput
            ref={ele => this.inline = ele}
            value={data.label}
            selected={selected}
            handleChange={label => updateElement({label})}
            placeholder="Add a label"
            fontSize={16}
            fontWeight={800}
            disabled={!selected}
            onFocus={() => handleSelectInput('label')}
            maxLength={150}
          />
        </div>
        <div style={styles.label}>
          <InlineInput
            value={data.instructions}
            selected={selected}
            handleChange={instructions => updateElement({instructions})}
            placeholder="Add instructions or tips for the contributors"
            fontSize={13}
            fontWeight={600}
            disabled={!selected}
            onFocus={() => handleSelectInput('instructions')}
          />
        </div>
        {data.options && data.options.map((option, i) => (
          <InlineCheckbox
            sortAlphabetically={true}
            disabled={data.selectedvalue}
            key={i}
            selected={selected}
            radio={true}
            label={option.label}
            value={option.key}
            checked={option.selected === 'selected'}
            onSelect={() => {
              const ud = {};
              const selectedIndex = data.options.findIndex(s => s.selected === 'selected');
              data.options.forEach((o, n) => {
                ud[n] = {$merge: {selected: null}};
              });
              if(i !== selectedIndex) {
                ud[i] = {$merge: {selected: 'selected'}};
              }
              return updateElement({options: update(data.options, ud)});
            }}
            onChangeLabel={label => updateElement({options: update(data.options, {[i]: {$merge: {label}}})})}
            onChangeValue={key => updateElement({options: update(data.options, {[i]: {$merge: {key}}})})}
            onDelete={() => updateElement({options: update(data.options, {$splice: [[i, 1]]})})}
            onFocus={key => handleSelectInput(key, i)}
          />
        ))}
        <div style={[styles.footer, !selected && styles.footerHidden]}>
          <div
            style={styles.smallBtn}
            onClick={() => {
              let options = [...data.options];
              options.sort(sortAtoZ);
              updateElement({options: update(data.options, {$set: options})});
            }}
          >
            Sort List Alphabetically
          </div>
          <div
            style={styles.smallBtn}
            onClick={() => updateElement({options: update(data.options, {$push: [{}]})})}>
            + Add Radio Button
          </div>
        </div>

        <Settings show={selected} customHeight="auto">
          <Select
            label="Select a default input value (optional)"
            value={data.selectedvalue}
            handleChange={selectedvalue => { 
              if(!!selectedvalue) {
                let options = [...data.options];
                options.forEach(option => option.selected = null);
                updateElement({selectedvalue, options: update(data.options, {$set: options})});
              }
              updateElement({selectedvalue});
              }}
            options={defaultInputs}
            wrapOutputValue={true}
          />
          <Select
            label="Select the number of columns to display (optional)"
            value={data.columns}
            handleChange={columns => updateElement({columns})}
            options={[
              {label: '1', value: '1'},
              {label: '2', value: '2'},
              {label: '3', value: '3'},
              {label: '4', value: '4'},
              {label: '5', value: '5'},
            ]}
            wrapOutputValue={false}
          />
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Input
              value={data.output}
              label="Output Name"
              onChange={output => {
                handleChangeOutput(output);
                updateElement({output});
              }}
              maxWidth={280}
              error={outputError}
            />
            <Switch label="Required" isOn={data.required}
                    handleToggle={() => updateElement({required: !data.required})}/>
          </div>
          {isSupportRole &&
          <SupportRoleOptions
            data={data}
            updateElement={updateElement}
            handleSelectInput={handleSelectInput}
          />
          }
        </Settings>
      </div>
    );
  }
}

const styles = {
  smallBtn: {
    background: '#33aab9',
    color: 'white',
    padding: '4px 8px',
    borderRadius: 12,
    fontSize: 12,
    display: 'inline-block'
  },
  footer: {
    marginTop: 8,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 16
  },
  footerHidden: {
    display: 'none',
    marginBottom: 0
  },
  label: {
    marginBottom: 8
  },
  instructions: {
    fontSize: 12,
    marginBottom: 8
  }
};

export default Radium(RadioButton);
