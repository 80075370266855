import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-modal';
import { Label, BodyInstruction, Caption, Title } from '../../../../../components/type';
import Input from '../../../../../components/Input';

const headerMessage = 'Repeat Layout',
      messageBody = `Repeating a layout will dynamically generate the whole layout a set number of ` +
                    `times. Each iteration will include every element within the layout.`,
      learnMore = `Learn more about repeating layouts.`,
      learnMoreLink = `https://onespace.helpdocs.com/setting-up-assignments/task-ui-builder-reusing-and-repeating-elements`,
      duplicateBody = `Include #{count} in the output name of any collect elements contained in the ` +
                      `layout. This will automatically generate unique output names. By default, ` +
                      `these dynamic numbers will start at 1. However, you can choose to start with ` + 
                      `another number if you would like.`,
      numOfDuplicates = 'Number of Repetitions',
      outputRange = 'Numeric Range for Outputs',
      submit = 'Submit';

/**
 * Make Repeatable Modal
 * 
 */

const MakeRepeatableModal = ({
  isOpen,
  saveDuplicate,
  numberOfDuplicates,
  startRange,
  setDuplicate,
  setRange,
  closeIt,
}) => (
  <div>
    <Modal
      isOpen={isOpen}
      style={modalStyles}
      onRequestClose={() => closeIt(true)}
      shouldCloseOnOverlayClick={true}
      contentLabel="Make Layout Repeatable Modal"
    >
      <div style={{display: 'flex', width: 800, borderBottom: `1px solid black`, justifyContent: 'space-between', marginBottom: 16}}>
        <Title uppercase={true} left={16} bottom={16}>{headerMessage}</Title>
        <i className="fa fa-times" style={{marginRight: 16, cursor: 'pointer'}} onClick={() => closeIt(true)}/>
      </div>
      <BodyInstruction center={true} >{messageBody} <a href={learnMoreLink} >{learnMore}</a></BodyInstruction>
      <div style={{borderBottom: `1px solid black`, margin: 20, width: '100%'}}></div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <Label right={10}>{numOfDuplicates}</Label>
        <Input
          width={75}
          min={0}
          value={numberOfDuplicates}
          type={'number'}
          handleChange={num => setDuplicate(num)}/>
      </div>
      <Caption top={10} bottom={10} center={true}>{duplicateBody}</Caption>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <Label right={10} color={numberOfDuplicates < 2 ? 'grey' : 'darker'}>{outputRange}</Label>
        <Input
          width={75}
          min={1}
          value={startRange}
          type={'number'}
          disabled={numberOfDuplicates < 2 ? true : false}
          handleChange={num => setRange(num)}/>
        <Label right={10} left={10}>-</Label>
        <Input
          width={75}
          disabled={true}
          value={+numberOfDuplicates + +startRange - 1}
          disabled={numberOfDuplicates < 2 ? true : false}
          handleChange={() => {}}/>
      </div>
      <div style={{borderBottom: `1px solid black`, margin: 20, width: '100%'}}></div>
      <div
        className={'btn pull-right'}
        onClick={() => saveDuplicate()
      }>
        <span>{submit}</span>
      </div>
    </Modal>
  </div>
);

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    zIndex: 10001,
  },
  content: {
    position: 'relative',
    top: '50%',
    left: 0,
    right: 'initial',
    bottom: 'initial',
    border: 0,
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    padding: `20px 70px`,
    maxWidth: 800,
    maxHeight: '100%',
    margin: 'auto',
    transform: 'translateY(-50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
};

/* PropTypes */
MakeRepeatableModal.propTypes = {
  isOpen: PropTypes.bool,
  closeIt: PropTypes.func,
  saveDuplicate: PropTypes.func,
  numberOfDuplicates: PropTypes.string,
  startRange: PropTypes.string,
  setDuplicate: PropTypes.func,
  setRange: PropTypes.func,
};

export default MakeRepeatableModal;
