import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import PropTypes from 'prop-types';

import colors from '../styles/colors';


export default function (name, fontSize, fontWeight, lineHeight) {
  const styles = StyleSheet.create({
    [name]: {
      fontFamily: 'Open Sans',
      fontSize,
      lineHeight: `${lineHeight}px`,
      fontWeight,
      '-webkitFontSmoothing': 'antialiased',
      MozMacOsxFontSmoothing: 'antialiased',
    },
    white: { color: 'white' },
    darker: { color: colors.darker },
    grey: { color: colors.grey, ':visited': { color: colors.grey } },
    dark: { color: colors.dark, ':visited': { color: colors.dark } },
    light: { color: colors.light },
    lighter: { color: colors.lighter },
    primary: { color: colors.primary() },
    secondary: { color: colors.secondary() },
    positive: { color: colors.positive },
    negative: { color: colors.negative },
    orange: { color: colors.orange },
    blue: { color: colors.blue },
    purple: { color: colors.purple },
    green: { color: colors.green },
    whiteHover: { ':hover': { color: 'white' } },
    darkerHover: { ':hover': { color: colors.darker } },
    darkHover: { ':hover': { color: colors.dark } },
    greyHover: { ':hover': { color: colors.grey } },
    lightHover: { ':hover': { color: colors.light } },
    lighterHover: { ':hover': { color: colors.lighter } },
    primaryHover: { ':hover': { color: colors.primary() } },
    secondaryHover: { ':hover': { color: colors.secondary() } },
    hoverUnderline: { ':hover': { textDecoration: 'underline'} }
  });
  const TypeComponent = ({
    color,
    hoverColor,
    top,
    bottom,
    left,
    right,
    href,
    center,
    underline,
    uppercase,
    lowercase,
    italic,
    inline,
    handleClick,
    children,
    className,
    isLink,
    target,
    title,
    hoverUnderline
  }) => {
    const style = {
      marginTop: top,
      marginBottom: bottom,
      marginLeft: left,
      marginRight: right,
      textAlign: center ? 'center' : 'initial',
      textDecoration: underline ? 'underline' : 'none',
      fontStyle: italic ? 'italic' : 'normal',
      cursor: handleClick || isLink || href ? 'pointer' : 'initial',
      textTransform: uppercase ? 'uppercase' : lowercase ? 'lowercase' : 'initial',
      display: inline ? 'inline' : 'block',
    };
    const classes = `${css(styles[name], styles[color], styles[`${hoverColor || color}Hover`], styles[`${hoverUnderline && 'hoverUnderline'}`])} ${className}`;
    if (href) {
      return (
        <a
          style={style}
          target={href.indexOf('www') >= 0 || target ? '_blank' : '_self'}
          className={classes}
          href={href}>
          {children}
        </a>
      );
    }
    return (
      <div
        style={style}
        className={classes}
        title={title ? title : ''}
        onClick={handleClick ? handleClick : undefined}>
        {children}
      </div>
    );
  };

  TypeComponent.propTypes = {
    color: PropTypes.oneOf([
      'darker', 'dark', 'grey', 'light', 'lighter', 'primary', 'secondary', 'positive', 'negative', 'white', 'orange', 'green', 'blue', 'purple',
    ]),
    hoverColor: PropTypes.oneOf([
      'darker', 'dark', 'grey', 'light', 'lighter', 'primary', 'secondary', 'positive', 'negative', 'white', 'orange', 'green', 'blue', 'purple',
    ]),
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
    href: PropTypes.string,
    handleClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
  };
  TypeComponent.defaultProps = {
    color: 'darker',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  };

  return TypeComponent;
}
