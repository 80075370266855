import React from 'react';

import InlineInput from '../../Form/InlineInput';
import Select from '../../Form/Select';
import Settings from '../Settings';
import SupportRoleOptions from './SupportRoleOptions';

const HeadingStyles = [
  {label: 'Heading', value: 'heading'},
  {label: 'Subheading', value: 'subheading'}
];

class Heading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasLink: !!props.data.linkurl || !!props.data.linktext
    };
  } 

  componentDidMount() {
    if (this.props.selected) {
      this.inline.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.selected && this.props.selected) {
      this.inline.focus();
    }
  }

  render() {
    const {data = {}, selected, updateElement, handleSelectInput, isSupportRole } = this.props;
    return (
      <div>
        <div style={{ marginBottom: 10 }}>
          <InlineInput
            ref={ele => this.inline = ele}
            value={data._innerValue}
            selected={selected}
            handleChange={_innerValue => updateElement({_innerValue})}
            placeholder="Add a heading"
            fontSize={data.style === 'heading' ? 18 : 12}
            fontWeight={800}
            inlineNoBorder={true}
            disabled={!selected}
            onFocus={() => handleSelectInput('_innerValue')}
          />
        </div>
        <div style={{display: 'flex', alignItems: 'center', borderBottom: selected ? '1px solid rgb(80, 85, 83)' : 0}}>
          {this.state.hasLink &&
            <div style={styles.addLinkText}>{this.props.data.linktext || 'Add link text'}</div>
          }
          {this.state.hasLink &&
            <div style={styles.addLinkUrl}>{this.props.data.linkurl || 'Add link URL'}</div>
          }
        </div>
        <Settings show={selected} customHeight="auto">
          {this.state.hasLink &&
            <div style={{marginBottom: 15}}>
              <InlineInput
                ref={ele => this.input = ele}
                value={data.linkurl}
                selected={selected}
                handleChange={_innerValue => updateElement({linkurl: _innerValue})}
                placeholder="Add link URL"
                height={10}
                disabled={!selected}
                onFocus={() => handleSelectInput('linkurl')}
              />
            </div>
          }
          {this.state.hasLink &&
            <div style={{marginBottom: 15}}>
              <InlineInput
                ref={ele => this.input = ele}
                value={data.linktext}
                selected={selected}
                handleChange={_innerValue => updateElement({linktext: _innerValue})}
                placeholder="Add link text"
                height={10}
                disabled={!selected}
                onFocus={() => handleSelectInput('linktext')}
              />
            </div>
          }
          <Select
            label="Heading Style"
            value={data.style}
            options={HeadingStyles}
            handleChange={style => updateElement({style})}
          />
          <label>
            <input type="checkbox" onChange={e => this.setState({hasLink: e.target.checked})}/>
            <span style={{fontSize: 13, fontWeight: 600}}>Add link</span>
          </label>
          {isSupportRole &&
          <div style={{ marginTop: 16 }}>
            <SupportRoleOptions
              data={data}
              updateElement={updateElement}
              handleSelectInput={handleSelectInput}
            />
          </div>
          }
        </Settings>
      </div>
    );
  }
}

const styles = {
  addLinkText: {
    color: '#4472C4',
    textDecoration: 'underline',
    fontWeight: 600,
    flex: 2,
  },
  addLinkUrl: {
    color: '#555',
    fontStyle: 'italic',
    marginLeft: 10,
    fontSize: 12,
    wordBreak: 'break-word',
    flex: 8,
  },
};

export default Heading;
