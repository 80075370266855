import { SELECT_ELEMENT, SELECT_INPUT } from '../actionTypes';

export function selectElement(index, parentIndex, columnIndex, isChild) {
  return {
    type: SELECT_ELEMENT,
    index,
    parentIndex,
    columnIndex,
    isChild,
  };
}

export function selectInput(input, inputIndex) {
  return {
    type: SELECT_INPUT,
    input,
    inputIndex,
  };
}
