import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Label } from './type';

/**
 * InlineSaveState
 * Show text inline that says saving and saved
 */
class InlineSaveState extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillUpdate(next) {
    if (this.props.saving && !next.saving) {
      this.setState({ saved: true }, () => setTimeout(() => {
        this.setState({ saved: false });
        this.props.savedCallback();
      }, 2000));
    }
  }

  renderSavingState() {
    return (
      <Label color="light">
        <i style={{ marginRight: 6 }} className="fa fa-spin fa-spinner"/>
        <span>{this.props.savingLabel}</span>
      </Label>
    );
  }

  renderSavedState() {
    return (
      <Label color="positive">
        <i style={{ marginRight: 6 }} className="fa fa-check"/>
        <span>{this.props.savedLabel}</span>
      </Label>
    );
  }

  render() {
    if (this.props.saving) {
      return this.renderSavingState();
    }
    if (this.state.saved && !this.props.hasErrors) {
      return this.renderSavedState();
    }
    return <div style={{ display: 'none' }}/>;
  }
}

/* PropTypes */
InlineSaveState.propTypes = {
  saving: PropTypes.bool,
  savingLabel: PropTypes.string,
  savedLabel: PropTypes.string,
  hasErrors: PropTypes.bool,
  savedCallback: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
  ])
};
InlineSaveState.defaultProps = {
  savingLabel: 'Saving',
  savedLabel: 'Saved',
  savedCallback: () => {},
  hasErrors: false
};

export default InlineSaveState;
