import React, {Component} from 'react';
import {StyleSheet, css} from 'aphrodite';
import {connect} from 'react-redux';


import { RESET_API_PASSWORD } from '../../constants/modalTypes';
import { toggleModal } from '../../actions/app';
import { Title, Label } from '../../components/type';
import { createAPICredentials } from '../../actions/company';
import InlineButton from '../../components/InlineButton';
import colors from '../../styles/colors';
import TransparentInlineButton from '../../components/TransparentInlineButton';
import fetch, { checkStatus, parseJSON } from '../../utils/fetch';

class APICredentials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiUsername: null,
      showFullCredentials: false,
      workerId: null,
    };

    this.generateAPIUser = this.generateAPIUser.bind(this);
    this.renderView = this.renderView.bind(this);
  }

  componentDidMount() {
    fetch(`/company/${this.props.companyId}/apiuser`)
      .then(checkStatus)
      .then(parseJSON)
      .then(res => {
        this.setState({apiUsername: res.username, workerId: res.workerId});
      });
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.apiPassword !== nextProps.apiPassword){
      this.setState({showFullCredentials: true});
    }
  }

  generateAPIUser() {
    this.props.dispatch(createAPICredentials(this.props.swfId));
  }

  renderView() {
    const {apiUsername, showFullCredentials, workerId} = this.state;
    if(!!apiUsername && !showFullCredentials) {
      return(
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div style={{display: 'flex', paddingRight: 15}}>
            <Label color="light">Username:</Label>
            <p className={css(styles.credentialStyles)}>
              {apiUsername}
            </p>
          </div>
          <InlineButton
            handleClick={() => {this.props.dispatch(toggleModal(RESET_API_PASSWORD, {workerId}));}}>
            Reset API Password
          </InlineButton>
        </div>
      );
    }
    if(showFullCredentials) {
      return(
        <div style={{display: 'flex', alignItems: 'center'}}>
          <TransparentInlineButton
            handleClick={() => {this.props.dispatch(toggleModal(RESET_API_PASSWORD, {workerId}));}}>
            Reset API Password
          </TransparentInlineButton>
          <div style={{display: 'flex', paddingRight: 15, paddingLeft: 15}}>
            <Label color="light">Username:</Label>
            <p className={css(styles.credentialStyles)}>
              {apiUsername || this.props.apiUsername}
            </p>
          </div>
          <div style={{display: 'flex', paddingRight: 15}}>
            <Label color="light">Password:</Label>
            <p className={css(styles.credentialStyles)}>
              {this.props.apiPassword}
            </p>
          </div>
        </div>
      );
    }
    return(
      <InlineButton
        handleClick={this.generateAPIUser}>
        Generate API User
      </InlineButton>
    );
  }

  render() {
    return (
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Title color="light">API Credentials</Title>
        {
          this.renderView()
        }
      </div>
    );
  }
}

const styles = StyleSheet.create({
  credentialStyles: {
    paddingLeft: 5,
    paddingTop: 3,
    fontFamily: 'Courier new',
    fontSize: 12,
    fontWeight: 400,
    color: colors.dark,
    marginBottom: 0
  }
});

function select(state) {
  const apiUsername = state.apiCredentials.username;
  const apiPassword = state.apiCredentials.password;
  const workerId = state.apiCredentials.workerId;
  return{
    apiUsername,
    apiPassword,
    workerId,
  };
}

export default connect(select)(APICredentials);