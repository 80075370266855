import React, {Component} from 'react';
import Radium from 'radium';

import Label from './Label';

class Radio extends Component {
  render() {
    const {label, options, labelBold, display} = this.props;
    return (
      <div style={styles.main}>
        {label && <Label label={label} labelBold={labelBold}/>}

        <div style={[styles.list, display === 'block' ? styles.blocker : '']}>
          {options.map((o, i) => (
            <div key={i} style={styles.option}>
              <input type="radio" checked={o.checked} onChange={o.onChange}/>
              <div
                className="task-ui-checkbox-label"
                checked={o.checked}
                onClick={o.onChange}
                style={styles.label}>
                {o.label}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const styles = {
  main: {
    marginBottom: 16
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 13,
    fontWeight: 600
  },
  blocker: {
    display: 'block',
  },
  list: {
    height: 32,
    display: 'flex',
    alignItems: 'center'
  },
  label: {
    marginLeft: 4,
    marginRight: 16,
    position: 'relative',
    top: 2,
  }
};

Radio.defaultProps = {
  options: []
};
export default Radium(Radio);
