import React, {Component} from 'react';
import TinyMCE from 'react-tinymce';

import './instructions-content.css';

/**
 * Tile
 * Small component used to display status of an item.
 * Good to use in an ItemList (spacing is usually 8)
 */
class TinyMCEComponent extends Component {
  constructor(props){
    super(props);
    this.state = {
      body: props.body,
      fieldName: null,
    };
    this.openUploader = this.openUploader.bind(this);
  }

  shouldComponentUpdate() {
    return false;
  }

  openUploader(fieldName) {
    this.setState({fieldName});
    let input = document.getElementById('fileUploader');
    input.click();
  }

  inputChange(){
    const file = document.getElementById('fileUploader').files[0];
    if(file){
      this.getSignedRequest(file);
    }
  }

  getSignedRequest(file) {
    let xhr = new XMLHttpRequest();
    xhr.open('GET', '/api/inbox/get-temporary-credentials?fileName=' + Date.now() + file.name + '&fileType=' + file.type);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          let response = JSON.parse(xhr.responseText);
          this.uploadFile(file, response.signedRequest, response.url);
        } else {
          console.log('error', xhr);
        }
      }
    };
    xhr.send();
  }

  uploadFile(file, signed_request, url) {
    let xhr = new XMLHttpRequest();
    xhr.open('PUT', signed_request);
    xhr.setRequestHeader('x-amz-acl', 'public-read');
    xhr.onload = () => {
      if (xhr.status === 200) {
        document.getElementById(this.state.fieldName).value = url;
      }
    };
    xhr.send(file);
  }

  render() {
    return (
      <div>
        <input type="file" id="fileUploader" onChange={() => this.inputChange()} style={{display: 'none'}} />
        <TinyMCE
          content={this.props.body}
          config={{
            menubar: false,
            height: 400,
            plugins: 'link image advlist lists table hr textcolor',
            table_appearance_options: false,
            table_class_list: [
              { title: 'None', value: '' },
              { title: 'Transparent Table', value: 'trans-table' }
            ],
            file_browser_callback: this.openUploader,
            default_link_target: '_blank',
            toolbar: [
              'removeformat | undo redo | styleselect | bold italic | link image | outdent indent | bullist numlist | table | hr | forecolor'
            ],
            style_formats: [
              {
                title: 'Style',
                items: [
                  { title: 'Heading 1', block: 'h1' },
                  { title: 'Heading 2', block: 'h2' },
                  { title: 'Heading 3', block: 'h3' },
                  { title: 'Paragraph', block: 'p' },
                  { title: 'Custom Paragraph', block: 'h6' },
                  { title: 'Blockquote', block: 'blockquote', wrapper: true }
                ]
              },
              {
                title: 'Alignment',
                items: [
                  { title: 'Left', icon: 'alignleft', format: 'alignleft' },
                  { title: 'Center', icon: 'aligncenter', format: 'aligncenter' },
                  { title: 'Right', icon: 'alignright', format: 'alignright' },
                  { title: 'Justify', icon: 'alignjustify', format: 'alignjustify' }
                ]
              }
            ]
          }}
          onChange={(input) => {this.setState({body: input.target.getContent()});}}
        />
      </div>
    );
  }
}

export default TinyMCEComponent;
