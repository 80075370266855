import React, { Component } from 'react';
import Radium from 'radium';

import Label from './Label';

class Switch extends Component {
  render() {
    const { isOn, label, handleToggle } = this.props;
    return (
      <div style={styles.main}>
        {label && <Label label={label}/>}
        <div onClick={handleToggle} style={styles.toggle}>
          <span style={[styles.btn, isOn && styles.on]} />
          <i style={[styles.mark, styles.check]} className="fa fa-check" />
          <i style={[styles.mark, styles.x]} className="fa fa-times" />
        </div>
      </div>
    );
  }
}

const styles = {
  main: {
    marginBottom: 16
  },
  toggle: {
    position: 'relative',
    height: 32,
    width: 64,
    background: '#B9B9B9',
    borderRadius: 16
  },
  btn: {
    height: 24,
    width: 24,
    borderRadius: '50%',
    position: 'absolute',
    transition: 'all 0.14s ease',
    top: 4,
    left: 4,
    background: '#636363',
    zIndex: 1
  },
  on: {
    background: '#88C745',
    left: 36
  },
  mark: {
    position: 'absolute',
    top: 7
  },
  check: {
    left: 8,
    color: '#858484'
  },
  x: {
    right: 8,
    color: '#858484'
  }
};

export default Radium(Switch);
