import update from 'immutability-helper';

import { NEW_ELEMENT, NEW_CHILD_ELEMENT, MOVE_ELEMENT, UPDATE_ELEMENT, DELETE_ELEMENT, REPEATABLE_ELEMENT } from '../actionTypes';

export default function (state = {}, action) {
  const newState = {};
  const elementCount = Object.keys(state).length;
  let i = elementCount;

  switch (action.type) {
    case NEW_ELEMENT:

      for (; i >= action.newElementIndex; i--) {
        if (action.element.type === 'HR') {
          action.element.shouldExport = false;
          action.element.data.noData = true;
        }
        if (i === action.newElementIndex) {
          newState[i] = {$set: action.element};
        } else {
          newState[i] = {$set: state[i - 1]};
        }
      }

      return update(state, newState);

    case NEW_CHILD_ELEMENT:

      if (action.element.type === 'HR') {
        action.element.shouldExport = false;
        action.element.data.noData = true;
      }

      return update(state, {[action.targetIndex]: { data: { columns: { [action.columnIndex]: { children: { $splice: [[action.newElementIndex, -1, action.element]]}}}}}});

    case MOVE_ELEMENT:
      if(Array.isArray(action.to) || Array.isArray(action.from)) {
         let movingElement = null;
         let updated = false;
         if(Array.isArray(action.from)) {
           // from Array is: [fromIndex, fromColumn, fromParent]
           // grab from a layout
           movingElement = state[action.from[2]].data.columns[action.from[1]].children[action.from[0]];
           const fromChildren = state[action.from[2]].data.columns[action.from[1]].children;
           updated =  [
             ...fromChildren.slice(0,action.from[0]),
             ...fromChildren.slice(action.from[0]+1)
           ];
         } else {
            // grab from top level
            movingElement = state[action.from];
         }

         // We have the element we are moving
         if(Array.isArray(action.to)) {
            // put in as a child of a layout
           if(Array.isArray(action.from)) {
             // Check if we are putting the element somewhere within the same layout & column
             if(action.to[2] == action.from[2] && action.to[1] == action.from[1]) {
               updated.splice(action.to[0] > action.from[0] ? action.to[0]-1: action.to[0], -1, movingElement);
               return update(state, {[action.to[2]]: { data: { columns: { [action.to[1]]: { children: { $set: updated}}}}}});
             } else {
               // Not moving to the same column, use updated to update old column
               newState[action.from[2]] = { data: { columns: { [action.from[1]]: { children: { $set: updated }}}}};
               let destColumn = state[action.to[2]].data.columns[action.to[1]].children;
               let newColumn = [
                 ...destColumn.slice(0,action.to[0]),
                 movingElement,
                 ...destColumn.slice(action.to[0])
               ] ;
               if(action.to[2] == action.from[2]) {
                 newState[action.to[2]].data.columns[action.to[1]] = { children: { $set: newColumn } };
               } else {
                 newState[action.to[2]] = { data: { columns: { [action.to[1]]: { children: { $set: newColumn }}}}};
               }
               return update(state, newState);
             }
           } else {
             for(let o = i - 2; o >= action.from; o--) {
               newState[o] = {$set: state[o + 1]};
             }

             let newDestIndex = action.to[2] >= action.from ? action.to[2] -1 : action.to[2];

             let destColumn = state[action.to[2]].data.columns[action.to[1]].children;
             let newColumn = [
               ...destColumn.slice(0,action.to[0]),
               movingElement,
               ...destColumn.slice(action.to[0])
             ];

             if(newState[newDestIndex]) {
               newState[newDestIndex].$set.data.columns[action.to[1]].children = newColumn;
             } else {
               newState[newDestIndex] = { data: { columns: { [action.to[1]]: { children: { $set: newColumn }}}}};
             }

             const rootElementsWithExtra = update(state, newState);
             delete rootElementsWithExtra[i - 1];
             return rootElementsWithExtra;
           }

         } else {
           // This should always be true, but for readability I'm putting it here
           if(Array.isArray(action.from)) {
             newState[action.to] = { $set: movingElement };

             for(var x = 0; x < elementCount; x++) {
               if(x >= action.to) {
                 newState[x+1] = { $set: state[x] };
               } else {
                 newState[x] = { $set: state[x] };
               }
             }

             const newFromIndex = action.to <= action.from[2] ? action.from[2]+1 : action.from[2];
             newState[newFromIndex].$set.data.columns[action.from[1]].children = updated;

             return update(state, newState);
           }
         }
      }

      if(elementCount > 1 && (action.to <= elementCount && action.from >= 0) && action.to !== action.from) {
        newState[action.to] = {$set: state[action.from]};
        for (var x = action.from; x > action.to; x--) {
          if (state[x]) {
            newState[x] = {$set: state[x - 1]};
          }
        }
        for (var n = action.from + 1; n <= action.to; n++) {
          newState[n - 1] = {$set: state[n]};
        }
        return update(state, newState);
      }
      return state;

    case UPDATE_ELEMENT:
      let uElement;
      let theElement;
      if(Array.isArray(action.index)) {
        uElement = update(
          state,
          {
            [action.index[2]]: {
              data: {
                columns: {
                  [action.index[1]]: {
                    children: {
                      [action.index[0]]: {
                        data: {
                          $merge: action.update
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        );
        theElement = uElement[action.index[2]].data.columns[action.index[1]].children[action.index[0]];
      } else {
        uElement = update(state, {[action.index]: {data: {$merge: action.update}}});
        theElement = uElement[action.index];
      }

      if(action.update.minwords > 0 || action.update.min > 0) {
        theElement.data.required = true;
      }
      if(action.update.maxchars === null) {
        delete theElement.data.maxchars;
      }
      if(action.update.minchars === null) {
        delete theElement.data.minchars;
      }
      if(action.update.minwords === null) {
        delete theElement.data.minwords;
      }
      if(action.update.maxwords === null) {
        delete theElement.data.maxwords;
      }
      if(action.update.required === false) {
        if(theElement.data.minwords) {
          delete theElement.data.minwords;
        }
        delete theElement.data.required;
      }
      if(action.update.spellcheck === false) {
        delete theElement.data.spellcheck;
      }
      if(action.update.allowmarkdown === false) {
        delete theElement.data.allowmarkdown;
      }
      return uElement;

    case DELETE_ELEMENT:
      if(Number.isInteger(action.selectedChild) && Number.isInteger(action.colIdx)) {
        const childElements = state[action.index].data.columns[action.colIdx].children;

        const newChildren = [
          ...childElements.slice(0,action.selectedChild),
          ...childElements.slice(action.selectedChild+1)
        ];

        return update(state, {[action.index]: { data: { columns: { [action.colIdx]: { children: { $set: newChildren}}}}}});
      }

      for(let o = i - 2; o >= action.index; o--) {
        newState[o] = {$set: state[o + 1]};
      }

      const stateWithDeleted = update(state, newState);
      delete stateWithDeleted[i - 1];
      return stateWithDeleted;
      
    case REPEATABLE_ELEMENT:
      if(action.num == 0){
        delete state[action.index].numberOfDuplicates;
        delete state[action.index].startRange;
      } else {
        state = update(state, {
          [action.index]: {
            $merge: {
              numberOfDuplicates: action.num,
              startRange: action.range,
            }
          }
        });
      }
      return state;

    default:
      return state;
  }
}
