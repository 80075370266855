import React from 'react';
import PropTypes from 'prop-types';

/**
 * Logo
 * Renders the logo with optional "OneSpace"
 */
const Logo = ({ primary, secondary, showText }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${showText ? 123 : 37}px`}
    height="42"
    viewBox={`0 0 ${showText ? 139 : 37} 48`}
    style={{display: 'inherit'}}
  >
    <path
      fill={primary}
      d="M12.8112112,35.8481013 L22.7315315,29.8632911 C16.3107107,23.878481 9.88988989,17.9544304 3.52992993,11.9392405 L0,23.9088608 L12.8112112,35.8481013 Z"
    />
    <polygon
      fill={secondary}
      points="12.8112112 35.8481013 22.7011011 29.8936709 16.2802803 23.9088608 19.8102102 11.9696203 32.6214214 23.9088608 29.1219219 35.8481013 9.31171171 47.8177215"
    />
    <polygon
      fill={primary}
      points="23.3097097 0 19.8102102 11.9696203 26.231031 17.9240506 36.1209209 11.9696203"
    />
    <polygon
      fill={secondary}
      points="3.4994995 11.9696203 9.92032032 17.9240506 19.8102102 11.9696203 23.3097097 0"
    />
    {showText &&
    <OneSpace />
    }
  </svg>
);

const OneSpace = () => (
  <g className="logo-text" transform="translate(44.000000, 16.000000)" fill="#6A6A6A">
    <path
      d="M6.38743091,15.9097413 C2.68285677,15.9097413 0.0896548723,12.7925151 0.0896548723,8.01100715 C0.0896548723,3.22949917 2.70931801,0.112272977 6.41389215,0.112272977 C10.1184663,0.112272977 12.7116682,3.17666483 12.7116682,7.95817281 C12.7116682,12.7396808 10.092005,15.9097413 6.38743091,15.9097413 L6.38743091,15.9097413 Z M6.38743091,1.03687397 C3.37084911,1.03687397 1.09518214,3.65217391 1.09518214,7.95817281 C1.09518214,12.2641717 3.37084911,14.9851403 6.41389215,14.9851403 C9.4569352,14.9851403 11.7061409,12.3434232 11.7061409,8.01100715 C11.7061409,3.67859108 9.40401271,1.03687397 6.38743091,1.03687397 L6.38743091,1.03687397 Z"
    />
    <path
      d="M23.4020107,15.6719868 L23.4020107,8.80352229 C23.4020107,6.00330215 22.1847935,4.78811227 20.0943552,4.78811227 C18.4008356,4.78811227 16.6279323,5.79196478 16.6279323,8.77710512 L16.6279323,15.6719868 L15.6753275,15.6719868 L15.6753275,4.18051734 L16.6279323,4.18051734 L16.6279323,6.135388 C17.2100796,4.73527793 18.5596031,3.9427628 20.2795839,3.9427628 C22.7140184,3.9427628 24.3546155,5.3164557 24.3546155,8.61860209 L24.3546155,15.6719868 L23.4020107,15.6719868 Z"
    />
    <path
      d="M36.5797101,10.2036324 L28.3767246,10.2036324 C28.4561083,13.2944414 29.9643992,15.0643919 32.1077599,15.0643919 C33.3345196,15.0914878 34.5384862,14.7308994 35.5477216,14.0341222 L35.9975628,14.7209686 C34.8267043,15.5323959 33.4266894,15.948924 32.001915,15.9097413 C29.7527092,15.9097413 27.4241198,14.1133737 27.4241198,9.88662631 C27.4241198,6.135388 29.4616355,3.9427628 32.0812987,3.9427628 C34.8597293,3.9427628 36.6326326,6.16180517 36.6326326,9.67528894 C36.6061714,9.91304348 36.5797101,10.0715465 36.5797101,10.2036324 L36.5797101,10.2036324 Z M32.0548374,4.78811227 C30.0437829,4.78811227 28.5090308,6.37314254 28.3767246,9.41111723 L35.6800279,9.41111723 C35.6006441,6.5052284 34.2511207,4.78811227 32.0548374,4.78811227 L32.0548374,4.78811227 Z"
    />
    <path
      d="M44.1476259,15.9097413 C42.3328529,15.9310083 40.5762696,15.2710296 39.2258345,14.0605394 L39.9138269,13.3208586 C41.1575053,14.3775454 42.5070288,14.9851403 44.2005484,14.9851403 C46.2909866,14.9851403 47.9315838,13.9020363 47.9315838,11.8679141 C47.9315838,10.1772152 46.978979,9.30544854 43.9094747,8.19592735 C40.6018192,6.98073748 39.5962919,6.00330215 39.5962919,3.91634562 C39.5962919,1.72372042 41.3162728,0.138690149 44.1740871,0.138690149 C45.7884551,0.0902776396 47.3694271,0.603938182 48.6460373,1.59163456 L47.9845062,2.3841497 C46.8915137,1.48422112 45.5111281,1.00610948 44.0947034,1.03687397 C41.6867302,1.03687397 40.6018192,2.3841497 40.6018192,3.83709411 C40.6018192,5.39570721 41.2898116,6.16180517 44.4651608,7.29774353 C47.8522,8.56576775 48.937111,9.6224546 48.937111,11.8150798 C48.937111,14.3247111 46.9260565,15.9097413 44.1476259,15.9097413 L44.1476259,15.9097413 Z"
    />
    <path
      d="M56.637333,15.9097413 C55.1758888,15.9080735 53.8094357,15.1864435 52.9856813,13.9812878 L52.9856813,19.2647221 L52.0330766,19.8194827 L52.0330766,4.18051734 L52.9856813,4.18051734 L52.9856813,5.89763346 C53.8577137,4.67184789 55.2637723,3.93563286 56.7696392,3.91634562 C59.2305349,3.91634562 61.0034382,5.65987892 61.0034382,9.78095762 C61.0034382,13.9548707 59.1511512,15.9097413 56.637333,15.9097413 L56.637333,15.9097413 Z M56.531488,4.78811227 C55.0761196,4.78811227 53.8589024,5.63346175 52.9856813,6.87506879 L52.9856813,12.8981838 C53.7795186,14.1133737 55.0761196,15.0379747 56.5844105,15.0379747 C58.7806937,15.0379747 60.0243722,13.3472757 60.0243722,9.80737479 C60.0243722,6.39955971 58.6748488,4.78811227 56.531488,4.78811227 L56.531488,4.78811227 Z"
    />
    <path
      d="M70.9793272,15.6719868 L70.9793272,13.9548707 C70.0600036,15.1810125 68.6234892,15.9127072 67.0895243,15.9361585 C65.210776,15.9361585 63.2791052,15.090809 63.2791052,12.3962576 C63.2791052,9.86020914 65.316621,8.6978536 67.5922879,8.6978536 C68.77473,8.68924769 69.9400525,8.98009309 70.9793272,9.54320308 L70.9793272,7.82608696 C70.9793272,5.63346175 70.0267224,4.7616951 67.9892066,4.7616951 C66.826758,4.76575963 65.6859057,5.0755546 64.6815511,5.65987892 L64.3375549,4.86736379 C65.4851477,4.25494022 66.7673397,3.93719571 68.0685903,3.9427628 C70.5559473,3.9427628 71.9583932,4.9466153 71.9583932,7.6940011 L71.9583932,15.6719868 L70.9793272,15.6719868 Z M70.9793272,10.309301 C69.9315287,9.71915568 68.7418342,9.42679557 67.5393655,9.46395157 C65.6076947,9.46395157 64.2052487,10.4413869 64.2052487,12.3170061 C64.2052487,14.3511282 65.6076947,15.1172262 67.2218305,15.1172262 C68.8359664,15.1172262 70.1325673,14.2190424 70.9793272,12.9774353 L70.9793272,10.309301 Z"
    />
    <path
      d="M79.7115376,15.9097413 C77.2771032,15.9097413 74.974975,14.0605394 74.974975,9.96587782 C74.974975,5.76554761 77.4094094,3.9427628 79.7909214,3.9427628 C81.0724828,3.91812261 82.3174406,4.37008055 83.2838055,5.21078701 L82.7016582,5.92405063 C81.8813596,5.23720418 81.0875223,4.78811227 79.7379989,4.78811227 C77.7269443,4.78811227 75.9805022,6.55806274 75.9805022,9.86020914 C75.9805022,13.2151899 77.6210994,15.0379747 79.7644601,15.0379747 C80.8767501,15.0520184 81.9468089,14.613262 82.7281194,13.8227848 L83.3102668,14.4567969 C82.360918,15.4131394 81.059901,15.9384096 79.7115376,15.9097413 L79.7115376,15.9097413 Z"
    />
    <path
      d="M94.5298342,10.2036324 L86.3268486,10.2036324 C86.4062323,13.2944414 87.9145232,15.0643919 90.057884,15.0643919 C91.2846437,15.0914878 92.4886102,14.7308994 93.4978457,14.0341222 L93.9476868,14.7209686 C92.7768283,15.5323959 91.3768134,15.948924 89.952039,15.9097413 C87.7028333,15.9097413 85.3742438,14.1133737 85.3742438,9.88662631 C85.3742438,6.135388 87.4117596,3.9427628 90.0314227,3.9427628 C92.8098533,3.9427628 94.5827567,6.16180517 94.5827567,9.67528894 C94.5838998,9.85277716 94.5661595,10.0298847 94.5298342,10.2036324 L94.5298342,10.2036324 Z M90.0314227,4.78811227 C88.0203682,4.78811227 86.4856161,6.37314254 86.3533098,9.41111723 L93.6830744,9.41111723 C93.5772294,6.5052284 92.227706,4.78811227 90.0314227,4.78811227 L90.0314227,4.78811227 Z"
    />
  </g>
);

/* PropTypes */
Logo.propTypes = {
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.string.isRequired,
  showText: PropTypes.bool
};

export default Logo;
