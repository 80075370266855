import React from 'react';
import PropTypes from 'prop-types';

const InputLabel = function ({label, value, handleChange}) {
  return (
    <div className="input-label">
      <span>{label}</span>
      <input value={value} onChange={e => handleChange(e.target.value)}/>
    </div>
  );
};

InputLabel.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func
};
InputLabel.defaultProps = {
  handleChange: () => {
  }
};

export default InputLabel;
