import fetch from 'isomorphic-fetch';

function doFetch(url, body, method) {
  const csrf = $('meta[name=\'csrf-token\']').attr('content');
  if (!window.Promise) {
    require('es6-promise').polyfill();
  }
  body._token = csrf;

  return fetch(url, {
    method: method,
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });
}

export function put(url, body = {}) {
  return doFetch(url, body, 'put');
}

export default function (url, body = {}) {
  return doFetch(url, body, 'post');
};
