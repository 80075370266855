import React, { Component } from 'react';
import Radium from 'radium';

import { elements as allElements } from './Preview';

class Static extends Component {
  render() {
    const { elements = [], isFourColumn, columnIndex } = this.props;
    return (
      <div style={styles.wrapper(isFourColumn, columnIndex)}>
        {!elements.length &&
        <div>No Elements</div>
        }
        {elements.map((element, i) => {
          const Element = allElements[element.type];
          return (
            <div key={i}>
              <Element key={i} data={element.data} isStatic={true} />
            </div>
          );
        })}
        <div className="task-ui-error-wrapper" style={styles.error}>
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: (isFourColumn, columnIndex) => ({
    background: 'white',
    borderRadius: 2,
    boxSizing: 'border-box',
    width: '100%',
    paddingTop: 32,
    paddingBottom: 32,
    paddingLeft: (isFourColumn && columnIndex !== 0) ? 16 : 32,
    paddingRight: (isFourColumn && columnIndex !== 3) ? 16 : 32,
  }),
  error: {
    display: 'none',
    background: '#f94d1f',
    color: 'white',
    fontWeight: 600,
    padding: '24px 16px 16px 16px',
    fontSize: 13,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6
  }
};

export default Radium(Static);
