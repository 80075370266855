import React, { Component } from 'react';
import InlineSelect from '../../components/InlineSelect';
import SingleInputForm from '../../components/SingleInputForm';
import { form } from '../../styles';
import addInputsAndOutputsToImportNode from './_addInputsAndOutputsToImportNode';
import { createNewInputObject } from './_miscHelpers';

class IOMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customOutputValue: '',
      error: '',
      availableOptions: [{ label: props.selectedOutput, value: props.selectedOutput}],
    };
  }

  render() {
    const {
      input,
      inputName,
      outputs,
      destinationName,
      selectedOutput,
      handleChangeMap,
      mappableNodes,
      selectedNode,
      mapping,
      importNode,
      isImportNode,
      isLocked,
    } = this.props;

    /* Check if output has '[[]]' or it doesn't exist and if it does make it invalid */
    const invalidOutput = selectedOutput === undefined || selectedOutput.substring(0, 4) === '[[]]';

    const mapKeys = Object.keys(mapping);
    const mappedNames = [];

    mapKeys.forEach(m => {
      mappedNames.push(m);
    });

    const availableOutputs = outputs[selectedNode].filter(o => {
      return selectedOutput === o.name || !mappedNames.includes(`${selectedNode}|${o.isOutput ? 'OUTPUT' : 'INPUT'}|${o.name}`);
    });

    const outputSelectOptions = availableOutputs.map(o => {
      return ({
        label: o.name,
        value: o.name,
      });
    });

    return (
      <div style={styles.inputsOutputsContainer}>
        {/* Source Schema Selector */}
        <div
          style={Object.assign({}, styles.outputs, invalidOutput && form.invalidSelect)}
          className={!selectedOutput ? 't-invalid' : ''}>
          {/* Select the comparison */}
          <div style={{ flex: 1, marginRight: 32 }}>
            <InlineSelect
              title={selectedNode}
              value={selectedNode}
              disabled={isLocked}
              options={mappableNodes.map(n => {
                if(n.name === destinationName) {
                  return ({
                    label: n.name,
                    value: n.id,
                    hide: true,
                  });
                }
                return ({
                  label: n.name,
                  value: n.id,
                });
              })}
              handleSelect={value => {
                handleChangeMap(
                  '',
                  inputName,
                  selectedNode,
                  value,
                );
              }}
              defaultLabel={'Select'}
              useLabelAsValue={true}
            />
          </div>

          <div style={{ flex: 1 }}>
            <InlineSelect
              value={selectedOutput}
              options={this.state.availableOptions}
              disabled={isLocked}
              handleSelect={value => {
                handleChangeMap(
                  outputs[selectedNode].find(o => o.name === value),
                  inputName,
                  selectedNode,
                );
              }}
              handleOnMouseDown={() =>  {
                this.setState({ availableOptions: outputSelectOptions });
              }}
              defaultLabel={'Select'}
            />

            {(isImportNode && !isLocked) &&
              <div style={{ marginBottom: 10 }}>
                <SingleInputForm
                  unallowedCharacters={[32]}
                  placeholder='Add new output'
                  icon='plus'
                  size='small'
                  value={this.state.customOutputValue}
                  handleChange={customOutputValue => this.setState({ customOutputValue })}
                  handleSubmit={() => {
                    const value = this.state.customOutputValue;
                    if(!value) {
                      return false;
                    }
                    // Check if first character is number or special character
                    if(/[\s.;,?%0-9_-]/.test(value.charAt(0))) {
                      this.setState({ error: 'First character invalid.'});
                      return false;
                      // Check if value has special characters
                    } else if (value.match(/[^a-zA-Z0-9_-]/g)) {
                      this.setState({ error: 'No special characters.'});
                      return false;
                    }

                    // Create input object from the input to the right
                    const newInput = createNewInputObject( input, this.state.customOutputValue );

                    // Add inputs back to the importNode to make it available
                    addInputsAndOutputsToImportNode(importNode, newInput);

                    // Handle mapping change
                    handleChangeMap(
                      outputs[selectedNode].find(o => o.name === this.state.customOutputValue),
                      inputName,
                      selectedNode,
                    );

                    // Clear the text field
                    this.setState({ customOutputValue: '', error: ''});
                  }}
                  error={this.state.error}
                />
              </div>
            }
          </div>

        </div>
        {/* Destination Input */}
        <div style={styles.inputs}>
          <i style={{ position: 'relative', top: 4 }} className="fa fa-arrow-right"/>
          <span>{inputName}</span>
        </div>
      </div>
    );
  }
}

const styles = {
  inputsOutputsContainer: {
    marginTop: 5,
    overflow: 'hidden',
    display: 'flex',
    flex: '1',
  },
  outputs: {
    flex: 2,
    marginRight: 32,
    display: 'flex',
  },
  inputs: {
    flex: 1,
  },
};

export default IOMap;
