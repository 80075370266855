import 'isomorphic-fetch';

function doFetch(url, body, method) {
  if (!window.Promise) {
    require('es6-promise').polyfill();
  }
  const request = {
    method: method,
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    },
  };

  if (method !== 'get') {
    const tokenElement = document.querySelector('meta[name="csrf-token"]');
    body._token = tokenElement.getAttribute('content');
    request.body = JSON.stringify(body);
  }

  return fetch(url, request);
}

export default function (url, method = 'get', body = {}) {
  return doFetch(url, body, method.toLowerCase());
};


export function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  if (response.status === 401 && window.location.pathname !== '/') {
    // Trigger a page reload so that the server redirects to login page
    window.location.reload();
  }
  throw response.json();
}

export function parseJSON(response) {
  return response.json();
}