import React from 'react';
import PropTypes from 'prop-types';

import colors from '../../styles/colors';
import { Label, Attention } from '../../components/type';
import IconButton from '../../components/IconButton';

const numberWithCommas = ( x ) => {
  const num = x.toString().replace( /\B(?=(\d{3})+(?!\d))/g, ',' );
  if (num.length < 8) {
    return num;
  } else {
    const index = num.indexOf( ',', num.indexOf( ',' ) +1 ) +1;
    return num.substring(0, index) + ' ' + num.substring(index);
  }
};

const ItemCountContainer = ( { color, title, count, titleHref, itemHref, reviewHref, isReviewStep } ) => (
  <div style={styles(color)}>
    <div>
      <Label color="light" href={titleHref}>{title}</Label>
      <div style={{display: 'flex', alignItems: 'center', maxWidth: 66.4, wordWrap: 'break-word'}}>
        <Attention
          hoverUnderline={true}
          hoverColor="primary"
          color="dark"
          href={itemHref}>
          {numberWithCommas(count)}
        </Attention>
        {(isReviewStep && count > 0) &&
          <div style={{ paddingLeft: 5}}>
            <IconButton icon='gavel' href={reviewHref} label="" target="_blank" />
          </div>
        }
      </div>
    </div>
  </div>
);

const styles = ( color ) => (
  {
    backgroundColor: color === 'shaded' ? colors.shaded : color === 'blue' ? colors.secondary() : colors.green,
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-start',
    flexDirection: 'column',
    padding: '8px 12px',
  }
);

ItemCountContainer.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.number,
  titleHref: PropTypes.string,
  itemHref: PropTypes.string,
  reviewHref: PropTypes.string,
  isReviewStep: PropTypes.bool,
};

ItemCountContainer.defaultProps = {
  isReviewStep: false,
};

export default ItemCountContainer;