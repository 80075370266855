import React from 'react';

import Input from '../../Form/Input';
import InlineInput from '../../Form/InlineInput';
import Switch from '../../Form/Switch';
import Select from '../../Form/Select';
import Settings from '../Settings';
import SupportRoleOptions from './SupportRoleOptions';

const LimitTypes = [
  {label: 'Words', value: 'words'},
  {label: 'Characters', value: 'characters'}
];

const WidthTypes = [
  {label: 'Large', value: 'large', size: 100},
  {label: 'Medium', value: 'medium', size: 64},
  {label: 'Small', value: 'small', size: 32}
];

class TextField extends React.Component {
  componentDidMount() {
    this.inline.focus();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.selected && this.props.selected) {
      this.inline.focus();
      this.props.handleChangeOutput(this.props.data.output);
    }
  }

  render() {
    const {data = {}, selected, updateElement, handleSelectInput, outputError, isSupportRole, handleChangeOutput} = this.props;
    return (
      <div style={styles.main}>
        <div style={styles.label}>
          <InlineInput
            ref={ele => this.inline = ele}
            value={data.label}
            selected={selected}
            handleChange={label => updateElement({label})}
            placeholder="Add a label"
            fontSize={16}
            fontWeight={800}
            disabled={!selected}
            onFocus={() => handleSelectInput('label')}
          />
        </div>

        <div style={styles.label}>
          <InlineInput
            value={data.instructions}
            selected={selected}
            handleChange={instructions => updateElement({instructions})}
            placeholder="Add instructions or tips for the contributors"
            fontSize={13}
            fontWeight={600}
            disabled={!selected}
            onFocus={() => handleSelectInput('instructions')}
          />
        </div>
        <div style={{width: WidthTypes.find(w => w.value === data.fieldwidth).size + '%'}}>
          <Input
            value={data.value}
            placeholder="Add an input or default text for contributors (optional)"
            onChange={value => updateElement({value})}
            onFocus={() => handleSelectInput('value')}
          />
        </div>
        <Settings show={selected} customHeight="auto">
          <Select
            label="Field Width"
            value={data.fieldwidth}
            options={WidthTypes}
            handleChange={fieldwidth => updateElement({fieldwidth})}
          />
          <div style={{display: 'flex'}}>
            <div style={{marginRight: 16}}>
              <Input
                label="Minimum"
                value={data.limitType === 'words' ? data.minwords : data.minchars}
                onChange={min => data.limitType === 'words' ? updateElement({minwords: min ? min : null}) : updateElement({minchars: min ? min : null})}
                maxWidth={64}
                placeholder="Min"
              />
            </div>
            <div style={{marginRight: 16}}>
              <Input
                label="Maximum"
                value={data.limitType === 'words' ? data.maxwords : data.maxchars}
                onChange={max => data.limitType === 'words' ? updateElement({maxwords: max ? max : null}) : updateElement({maxchars: max ? max : null})}
                maxWidth={64}
                placeholder="Max"
              />
            </div>
            <div>
              <Select
                label="Type"
                value={data.limitType}
                options={LimitTypes}
                hideDefault={true}
                handleChange={limitType => {
                  if(data.limitType === 'words' && limitType === 'characters') {
                    return updateElement({maxchars: data.maxwords, minchars: data.minwords, minwords: null, maxwords: null, limitType: 'characters'});
                  }
                  if(data.limitType === 'characters' && limitType === 'words') {
                    return updateElement({maxchars: null, minchars: null, minwords: data.minchars, maxwords: data.maxchars, limitType: 'words'});
                  }
                }}
              />
            </div>
          </div>
          
          <label style={{marginBottom: 16}}>
            <input 
              type="checkbox" 
              checked={data.allowmarkdown|| false} 
              onChange={e => updateElement({allowmarkdown: e.target.checked})}/>
            <span style={{fontSize: 13, fontWeight: 600}}>Allow Markdown</span>
          </label>

          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Input
              value={data.output}
              label="Output Name"
              onChange={output => {
                handleChangeOutput(output);
                updateElement({output});
              }}
              maxWidth={280}
              error={outputError}
            />
            <Switch
              label="Required"
              isOn={data.required}
              handleToggle={() => updateElement({required: !data.required})}
            />
          </div>
          {isSupportRole &&
          <SupportRoleOptions
            data={data}
            updateElement={updateElement}
            handleSelectInput={handleSelectInput}
          />
          }
        </Settings>
      </div>
    );
  }
}

const styles = {
  main: {
    paddingTop: 8
  },
  label: {
    marginBottom: 8
  }
};

export default TextField;
