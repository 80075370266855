import React, { Component } from 'react';
import { connect } from 'react-redux';

import {toggleModal} from '../../actions/app';
import { archiveUnarchiveChain, fetchChainBatches } from '../../actions/chains';
import {ARCHIVE_UNARCHIVE_CHAIN} from '../../constants/modalTypes';
import Button from '../../components/Button';
import { Content, Heading2, MediumLabel, Large } from '../../components/type';

class ArchiveUnarchiveChain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isArchiving: false,
    };
  }

  archiveChain() {
    const { chainId, projectId, archive } = this.props;
    this.setState({isArchiving: true});
    this.props.dispatch(archiveUnarchiveChain(chainId, archive))
      .then(() => {
        if(archive) {
          this.props.dispatch(fetchChainBatches(projectId, chainId));
        }
        this.props.dispatch(toggleModal(ARCHIVE_UNARCHIVE_CHAIN));
      });
  }

  render() {
    const { archive } = this.props;
    const archiveBodyHeader = 'Archive Workflow Chain';
    const archiveBodyText = 'Archiving a workflow chain will remove all of the logic you have built for this chain.' +
      ' The chain logic cannot be recovered once the chain is archived. The workflows within the chain' +
      ' will not be archived and will remain active. Any open items associated with this workflow chain will be canceled.';
    const archiveBodyQuestion = 'Are you sure you want to archive this workflow chain?';
    const unarchiveBodyHeader = 'Unarchive Workflow Chain';
    const unarchiveBodyText = 'Unarchiving a workflow chain will unlock the chain and allow you to re-build' +
      ' the chain with new chain logic. After re-building your chain, you can upload and release new' +
      ' batches of work.';
    const unarchiveBodyQuestion = 'Are you sure you want to unarchive this workflow chain?';

    return (
      <div style={styles.container}>
        <Heading2 color='dark' top={16} bottom={16}>
          { archive ? archiveBodyHeader : unarchiveBodyHeader }
        </Heading2>
        <Large center={true}>
          { archive ? archiveBodyText : unarchiveBodyText }
        </Large>
        <MediumLabel top={16} center={true}>
          { archive ? archiveBodyQuestion : unarchiveBodyQuestion }
        </MediumLabel>
        <div style={styles.buttons}>
          <Button
            style
            loading={this.state.isArchiving}
            handleClick={() => this.archiveChain()}>
            Yes
          </Button>
          <Content
            left={20}
            underline={true}
            handleClick={() => this.props.dispatch(toggleModal(ARCHIVE_UNARCHIVE_CHAIN))}>
            Cancel
          </Content>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttons: {
    margin: '20px 0px',
    display: 'flex',
    alignItems: 'center'
  }
};


function select(state) {
  const chainId = state.app.modalMeta.chainId;
  const projectId =  state.app.modalMeta.projectId;
  const archive = state.app.modalMeta.archive;
  return {
    chainId,
    projectId,
    archive
  };
}

export default connect(select)(ArchiveUnarchiveChain);