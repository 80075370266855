export const config = {
  consumeRightClick: false,
  layout: {
    type: 'Hierarchical',
    parameters: {
      orientation: 'vertical',
      padding: [100, 10]
    }
  },
  enablePanButtons: false,
  view: {
    nodes: {
      'startup': {
        parent: 'default',
        template: 'staged-node'
      },
      'stats': {
        parent: 'default',
        template: 'workflow-node',
      },
      'completed': {
        parent: 'default',
        template: 'completed-node',
      },
      'plugin': {
        parent: 'default',
        template: 'plugin-node',
      },
    },
    edges: {
      'normal': {
        connector: [ 'Flowchart', { gap: 5, cornerRadius: 10 } ],
        overlays:[
          [ 'Arrow', { location: 1, width: 12, length: 8 }],
        ],
        endpoints: [
          ['Blank', {radius: 1}],
          ['Blank', {radius: 1}]
        ]
      },
      'completed': {
        connector: [ 'Flowchart', { gap: 5, cornerRadius: 10 } ],
        overlays:[
          [ 'Arrow', { location: 1, width: 12, length: 8 }],
        ],
        endpoints: [
          ['Blank', {radius: 1}],
          ['Blank', {radius: 1}]
        ],
      },
    }
  },
  events: {
    modeChanged: function (mode) {
      jsPlumb.removeClass(jsPlumb.getSelector('[mode]'), 'selected-mode');
      jsPlumb.addClass(jsPlumb.getSelector('[mode="' + mode + '"]'), 'selected-mode');
    }
  },
  elementsDraggable: false,
  jsPlumb: {
    Anchors: ['Right', 'Left'],
    Connector: ['Flowchart', {alwaysRespectStubs: true, cornerRadius: 3, stub: 6}],
    PaintStyle: {strokeWidth: 3, stroke: '#d9d9d9'},
    // HoverPaintStyle: {stroke: '#DBDAD9', strokeWidth: 4},
    Endpoints: [
      ['Dot', {radius: 1}],
      ['Dot', {radius: 1}]
    ],
    EndpointStyle: {fill: '#DBDAD9'},
    EndpointHoverStyle: {fill: '#FF6600'}
  }
};

export default config;
