import React, { Component } from 'react';
import { DragSource } from 'react-dnd';
import Radium from 'radium';

import { ItemTypes } from '../constants';
import colors from '../../../../../styles/colors';

const elementSource = {
  beginDrag() {
    return {};
  },
  endDrag(props) {
    if(props.canClick) {
      props.handleClick();
    }
  },
  canDrag(props) {
    return props.canClick;
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

class Item extends Component {
  render() {
    const { label, from, canClick, handleClick, connectDragSource, required, hasDuplicates } = this.props;
    return connectDragSource(
      <div
        className="tui-preview"
        onClick={() => canClick && handleClick()}
        style={[styles.main, !canClick && styles.disabled(hasDuplicates)]}>
        {label}
        {from && <div style={[styles.from, canClick && styles.fromActive]}>{`From ${from} Step`}</div>}
        {required && <i className="fa fa-check" style={styles.required} />}
      </div>
    );
  }
}

const styles = {
  main: {
    position: 'relative',
    background: colors.primary(),
    color: 'white',
    height: 40,
    lineHeight: '40px',
    paddingLeft: 16,
    borderRadius: 4,
    marginBottom: 16,
    cursor: 'pointer',
    fontWeight: 800,
    fontSize: 12,
    transition: 'all 0.2s ease',
    boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
    ':hover': {
      boxShadow: '0 2px 4px rgba(0,0,0,0.32)'
    },
    ':active': {
      boxShadow: '0 0px 2px rgba(0,0,0,0.1)'
    }
  },
  disabled: (hasDuplicates) => ({
    background: 'white',
    color: colors.dark,
    cursor: 'initial',
    boxShadow: hasDuplicates ? `-2px 2px rgba(215,215,215), -7px 7px rgba(60, 169, 183, 0.75)` : 'none',
    ':hover': {
      boxShadow: hasDuplicates ? `-2px 2px rgba(215,215,215), -7px 7px rgba(60, 169, 183, 0.75)` : 'none'
    }
  }),
  from: {
    position: 'absolute',
    bottom: 0,
    right: 8,
    fontSize: 11,
    fontWeight: 600,
    transition: 'all 0.2s ease',
    color: 'rgba(255,255,255,0.8)'
  },
  fromActive: {
    color: 'rgba(0,0,0,0.8)'
  },
  required: {
    color: '#b4b4b4',
    fontSize: 16,
    position: 'absolute',
    right: 12,
    bottom: 12
  }
};

export default DragSource(ItemTypes.INPUT, elementSource, collect)(Radium(Item));
