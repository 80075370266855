import React from 'react';
import { css, StyleSheet } from 'aphrodite';

import FacetItem from './FacetItem';
import colors from '../styles/colors';

const Facet = ( { facets } ) => (
  <div className={css(styles.facetContainer)}>
    {facets.map( ( facet, i ) =>
      <FacetItem
        key={i}
        expanded={facet.expanded}
        facet={facet}
        titleStyle={i === 0 ? titleStyles.topBorder : i === facets.length -1 ? titleStyles.bottomBorder : {}}/>
    )}
  </div>
);

const titleStyles = {
  topBorder: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    border: `1px solid ${colors.border}`,
    borderBottom: 'none',
  },
  bottomBorder: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    border: `1px solid ${colors.border}`,
  }
};

const styles = StyleSheet.create( {
  facetContainer: {
    borderRadius: 6,
  }
} );

export default Facet;