import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';

import isChildFetching from '../../utils/isChildFetching';
import objectToArray from '../../utils/_objectToArray';
import { toggleModal } from '../../actions/app';
import { BATCH_NOTIFICATIONS } from '../../constants/modalTypes';
import { fetchWorkflow, fetchWorkflowBatches } from '../../actions/workflows';
import { logErrorMessage } from '../../actions/projects';
import { importWorkflowBatch } from '../../actions/workflows';
import BreadCrumb from '../../containers/BreadCrumb';
import HelpPanel from '../../containers/HelpPanel';
import Wrapper from '../../containers/Wrapper';
import { Heading } from '../../components/type';

import WorkflowBatchManagement from './WorkflowBatchManagement';

const sortByCreatedAt = (a, b) => new Date(b.created_at) - new Date(a.created_at);

const pageDescription = `A batch is a file of items that you import into a specific workflow.
A batch contains your inputs, which are the values you give to your contributors so they can 
complete each workflow item. On this page, you can import and release batches to contributors
as well as export completed batches.`;

const failedWorkflowUpload = (statusText) => `${statusText}. Your file upload failed. Please try to 
upload the file again. If the issue persists, contact <a target="_blank" href="mailto:support@onespace.com">client support</a>.`;

/**
 * Workflow Batch Management
 *
 */

class WorkflowBatchManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadErrors: [],
    };

    this.batchOptions = this.batchOptions.bind(this);
  }

  componentDidMount() {
    // Fetch workflow batches
    const workflow_id = this.props.match.params.workflow_id;
    this.props.dispatch(fetchWorkflowBatches(workflow_id));
    this.props.dispatch(fetchWorkflow(workflow_id));
  }

  batchOptions(val) {
    const { dispatch, workflowId, batchNotification } = this.props;
    switch(val) {
      case 'csv':
        return window.location.href = `/api/workflow/${workflowId}/template`;
      case 'advancedExport':
        return window.location.href = `/advanced-export?workflowId=${workflowId}`;
      case 'batchNote':
         return dispatch( toggleModal( BATCH_NOTIFICATIONS, {
           workflowId,
           enableOverlayClick: true,
           batchNotificationStatus: batchNotification,
         } ) );
      case 'bulkCancel':
        return window.location.href = `/workflows/${workflowId}/bulkcancel`;
      default:
        break;
    }
  }

  render() {
    const { batches, match, isLoadingBatches, workflowName, projectId, isArchived, batchNotification } = this.props;
    return (
      <DocumentTitle title="Workflow Batch Management | OneSpace Project Center">
        <div className='t-batch-management'>
          <HelpPanel
            pageTitle="Workflow Batch Management"
            pageDescription={pageDescription}
            links={[
              {
                href: 'https://onespace.helpdocs.com/managing-your-work/batch-management-best-practices',
                label: 'Batch Management Best Practices',
              }, {
                href: 'https://onespace.helpdocs.com/setting-up-workflows/workflow-data-the-ins-and-outs-of-inputs-and-outputs',
                label: 'Workflow Data: The Ins and Outs of Inputs and Outputs'
              }
            ]}
          />
          <Wrapper>
            <div className="chains chain-editor">
              <BreadCrumb
                links={[
                { href: `/workflows/${match.params.workflow_id}`, label: 'Manage Workflow', refresh: true },
                { label: 'Batch Management' },
              ]}
              />
            </div>

            {/* Page Title */}
            <Heading color="dark">Workflow Batch Management</Heading>

            {/* Batch Management */}
            <WorkflowBatchManagement
              uploadErrors={this.state.uploadErrors}
              projectId={projectId}
              loading={isLoadingBatches}
              batches={batches.sort(sortByCreatedAt)}
              batchNotification={batchNotification}
              handleOptionsSelect={val => this.batchOptions(val)}
              workflowName={workflowName}
              workflowId={match.params.workflow_id}
              archived={isArchived}
              handleUploadBatch={file => {
                this.props.dispatch(importWorkflowBatch(projectId, match.params.workflow_id, file))
                  .then(() => {
                    this.setState({
                      uploadErrors: [],
                    });
                  })
                  .catch(xhr => {
                    this.setState({
                      uploadErrors: [{
                        type: 'error',
                        note: failedWorkflowUpload(xhr.statusText),
                      }],
                    });
                    this.props.dispatch(logErrorMessage(`/workflows/${this.props.match.params.workflow_id}/log/failedUpload`, xhr.responseText));
                });
              }}
            />
          </Wrapper>
        </div>
      </DocumentTitle>
    );
  }
}

/* PropTypes */
WorkflowBatchManager.propTypes = {
  batches: PropTypes.arrayOf(PropTypes.object),
};
WorkflowBatchManager.defaultProps = {
  batches: [],
};

function select(state, rr) {
  const { workflow_id } = rr.match.params;
  const workflow = state.workflows.items[workflow_id];

  return {
    batches: workflow && objectToArray(state.batches.items).filter(b => {
      return !!b.tempId || (b.id_workflow.toString() === workflow_id.toString());
    }),
    isLoadingBatches: isChildFetching(workflow, 'batches'),
    workflowName: workflow && workflow.label,
    projectId: workflow && workflow.projectId,
    isArchived: workflow && workflow.archived,
    batchNotification: workflow && !!workflow.batchNotification,
    workflowId: workflow_id,
  };
}

export default connect(select)(WorkflowBatchManager);
