import React from 'react';
import PropTypes from 'prop-types';

import { Caption } from './type';

/**
 * FlatButton
 * Button that has no background
 */
const FlatButton = ({ disabled, handleClick, children, noStyle }) => (
  <div style={noStyle ? null : styles}>
    <Caption underline={true} handleClick={disabled ? undefined : handleClick}>
      {children}
    </Caption>
  </div>
);

/* Styles */
const styles = {
  height: 32,
  paddingLeft: 8,
  paddingRight: 8,
  minWidth: 56,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
};

/* PropTypes */
FlatButton.propTypes = {
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

FlatButton.defaultProps = {
  disabled: false,
};

export default FlatButton;
