import React from 'react';
import ToggleDropper from '../../components/ToggleDropper';
import InlineList from '../../components/InlineList';
import RadioCheckbox from '../../components/RadioCheckbox';
import { statuses } from './constants';

const FilterByStatus = ({ searchData, toggle, handleToggle, updateSearchData, styles, handleStatusCheckboxes }) => (
  <ToggleDropper
    handleToggle={() => handleToggle(!toggle)}
    toggle={toggle}
    label="FILTER BY STATUS"
    disabled={!(searchData.workflowId || searchData.chainId)}
  >
    <div style={Object.assign({}, styles.shadedGrayBg, { marginLeft: 0, maxWidth: 500 })}>
      <InlineList spacing={30}>
        {statuses.map((status, idx) =>
          <RadioCheckbox
            key={idx}
            disabled={handleStatusCheckboxes(searchData, status)}
            checked={searchData.status.includes(status.value)}
            id={status.value}
            value={status.value}
            label={status.label}
            type="checkbox"
            tooltip
            right={true}
            tooltipText={status.tooltipText}
            handleChange={(val) => updateSearchData('status', val)}
          />
        )}
      </InlineList>
    </div>
  </ToggleDropper>
);

export default FilterByStatus;