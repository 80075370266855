import React from 'react';
import PropTypes from 'prop-types';
import colors from '../styles/colors';
import { Title } from './type';

/**
 * GreenOverlay
 * Cover a component with a positive message
 */
const GreenOverlay = ({ content }) => (
  <div style={styles.exportedBatchOverview}>
    <Title center={true} color="white">
      {content}
    </Title>
  </div>
);

/* Styles */
const styles = {
  exportedBatchOverview: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: colors.positive,
    zIndex: 1,
  },
};

/* PropTypes */
GreenOverlay.propTypes = {
  content: PropTypes.string,
};

export default GreenOverlay;
