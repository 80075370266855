import React from 'react';
import PropTypes from 'prop-types';

import colors from '../styles/colors';
import { Title } from '../components/type';
import InlineList from '../components/InlineList';

/**
 * ButtonGroup
 * Large Inline Buttons
 */
const ButtonGroup = ({ options, handleSelect, size }) => (
  <div style={styles.buttonGroup}>
    <InlineList>
      {options.map((option, i) => (
        <div key={i} style={option.selected ? styles.selectedOption[size] : styles.option[size]}>
          <Title
            center={true}
            uppercase={true}
            handleClick={() => handleSelect(option.value)}>
            {option.label}
          </Title>
        </div>
      ))}
    </InlineList>
  </div>
);

/* Styles */
const styles = {
  buttonGroup: {
    border: `1px solid ${colors.border}`,
    borderRadius: 4,
    overflow: 'hidden',
  },
  option: {
    small: {
      padding: '0 4px',
      flex: 1,
      background: 'white',
    },
    large: {
      padding: '4px 8px',
      flex: 1,
      background: 'white',
    },
  },
  selectedOption: {
    small: {
      padding: '0 4px',
      flex: 1,
      background: 'white',
      boxShadow: `${colors.border} 0px 1px 5px 0px inset`,
    },
    large: {
      padding: '4px 8px',
      flex: 1,
      background: 'white',
      boxShadow: `${colors.border} 0px 1px 5px 0px inset`,
    },
  }
};

/* PropTypes */
ButtonGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    selected: PropTypes.bool,
  })),
  size: PropTypes.oneOf(['small', 'large']),
  handleSelect: PropTypes.func.isRequired,
};
ButtonGroup.defaultProps = {
  options: [],
  size: 'small'
};

export default ButtonGroup;