import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import colors from '../styles/colors';
import { Label } from './type';

class LoginInput  extends Component{
  constructor(props) {
    super(props);
    this.state = {
      inputFocused: false,
    };
  }

  render() {
    const { value, label, name, type, disabled, handleChange,
      checked, placeholder, handleGetInput, error } = this.props;
    const { inputFocused } = this.state;
    return (
      <div className= {css(styles.div)}>
        <label
          className={css(styles.label, value !== '' && styles.labelSelected, inputFocused && styles.labelSelected )}>
          {label}
        </label>
        <input
          checked={checked}
          name={name}
          type={type}
          value={value}
          className={css(styles.input, value && styles.inputSelected, inputFocused && styles.inputSelected, error && styles.inputError )}
          placeholder={placeholder}
          disabled={disabled}
          onChange={e => !disabled && handleChange(e.target.value)}
          ref={input => handleGetInput && handleGetInput(input)}
          onFocus={() => this.setState({inputFocused: true})}
          onBlur={() => this.setState({inputFocused: false})}
        />
        {error && <Label italic={true} color="negative">{error}</Label>}
      </div>
    );
  }
}
/* Styles */
const styles = StyleSheet.create({
  div: {
    position: 'relative',
    padding: `0.75em 0 0`,
    marginBottom: '2em',
    background: `colors.negative`,
    ':focus': {
      backgroundColor: colors.negative,
    },
  },
  label:{
    color: colors.placeholder,
    // fontSize: '0.85em',
    fontWeight: 600,
    position: 'relative',
    left: 0,
    top: 25,
    transition: 'all 0.3s ease',
    fontFamily: 'Open Sans',
  },
  labelSelected: {
    color: colors.light,
    top: 0,
  },
  input: {
    position: 'relative',
    zIndex: 1,
    border: 0,
    borderBottom: `1px solid ${colors.placeholder}`,
    borderRadius: 0,
    boxShadow: 'none',
    backgroundColor: colors.transparent,
    marginBottom: 0,
    width: '100%',
    fontFamily: 'Open Sans',
    outline:'none',
    fontSize: 12,
    fontWeight: 600,
    height: 30,
    transition: 'border-bottom 1s ease',
  },
  inputSelected: {
    borderBottom: `2px solid ${colors.placeholder}`,
  },
  inputError: {
    borderBottom: `2px solid ${colors.negative}`,
  }
});

/* PropTypes */
LoginInput.propTypes = {
  name: PropTypes.string, // Input name attribute
  type: PropTypes.oneOf(['text', 'password', 'number', 'email', 'radio']),
  value: PropTypes.string,
  checked: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleGetInput: PropTypes.func, // This component will pass itself to this function on mount
  label: PropTypes.string.isRequired,
};

export default LoginInput;
