import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { toggleModal, toast } from '../../actions/app';
import colors from '../../styles/colors';
import InlineList from '../../components/InlineList';
import IconPanel from '../../components/IconPanel';
import { exportBatch } from '../../actions/workflows';
import LoadState from '../../containers/LoadState';
import IconButton from '../../components/IconButton';

class ExportWorkflowBatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exporting: false,
      includeContributorInfo: false,
    };
  }

  handleExportBatch(exportType) {
    const { workflowId, batchId } = this.props;
    const { includeContributorInfo } = this.state;
    this.setState({exporting: true});
    this.props.dispatch(exportBatch(workflowId, batchId, exportType, includeContributorInfo))
      .then(() => {
        this.props.dispatch(toast('This batch has been queued for export. You will receive an email with instructions to download your export.', 'success', 2000));
        this.setState({exporting: false});
        this.props.dispatch(toggleModal());
      });
  }

  render() {
    const { includeContributorInfo } = this.state;
    return (
      <div>
      <div style={styles.cardContainer}>
        {this.state.exporting ?
          (<LoadState/>) :
          (
          <InlineList spacing={16} makeChildrenFill={true}>
            <div
              style={styles.iconPanel}
              onClick={() => {
              this.handleExportBatch('completed');
            }}>
              <IconPanel
                icon="check"
                label="Export Completed"/>
            </div>
            <div
              style={styles.iconPanel}
              onClick={() => {
              this.handleExportBatch('all');
            }}>
              <IconPanel icon="arrow-down" label="Export All"/>
            </div>
          </InlineList>
          )
        }
      </div>
        <div style={{paddingTop: 16}}>
          <IconButton
            icon={includeContributorInfo ? 'check-square-o' : 'square-o'}
            handleClick={() => this.setState({includeContributorInfo: !this.state.includeContributorInfo})}>
            Include Contributor Information
          </IconButton>
        </div>
      </div>
    );
  }
}

/* Styles */
const styles = {
  cardContainer: {
    padding: 16,
    boxSizing: 'border-box',
    border: `1px solid ${colors.border}`,
    borderRadius: 4,
    background: colors.background,
  },
  iconPanel: {
    flex: 1,
  },
};

ExportWorkflowBatch.propTypes = {
  batch: PropTypes.object,
};
ExportWorkflowBatch.defaultProps = {
  batch: {},
};

function select(state) {
  const batchId = state.app.modalMeta.batchId;
  const workflowId = state.app.modalMeta.workflowId;
  return {
    batchId,
    workflowId,
  };
}

export default connect(select)(ExportWorkflowBatch);
