import React, {Component} from 'react';
import PropTypes from 'prop-types';

import InlineList from './InlineList';
import ItemList from './ItemList';
import Menu from './Menu';
import {Label} from './type';

/*
 * Returns a String to use as the label for the filter component
 * If no option is selected it will return 'Select'
 * If multiple options are selected then we return 'Multiple'
 * Otherwise we return the selected option's label
 */
const getLabelForSelected = (selected, options) => {
  const multiple = Array.isArray(selected);
  if ((!selected || !selected.length) && selected !== '') {
    return 'Select';
  }
  if (multiple && selected.length > 1) {
    return 'Multiple';
  }

  const selectedOption = options.find(option => option.value === (multiple ? selected[0] : selected));
  try {
    return selectedOption.label;
  } catch (_) {
    return 'Select';
  }
};

// Returns an array of options for the ItemList
const getMultipleOptions = (selected, options, multiSelect) => {
  return options.map(option => ({
    value: option.value,
    staticLabel: option.label,
    fixed: option.fixed,
    outside: option.outside,
    label: multiSelect
      ? (
        <ItemListLabel
          option={option}
          selected={selected && !!selected.find(value => value === option.value)}
        />
      )
      : option.label,
  }));
};

// This is the button you see that opens and closes the filter drop down
const Toggle = ({name, selected, options, active}) => (
  <InlineList spacing={4}>
    <Label color="light">{name}:</Label>
    <Label isLink={true}>
      {getLabelForSelected(selected, options)}
      <i style={styles.indicator(active)} className={`fa fa-chevron-down`}/>
    </Label>
  </InlineList>
);

// This is an item for when the filter is multiple=true
const ItemListLabel = ({option, selected}) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <input
      type="checkbox"
      checked={selected}
      readOnly={true}
      style={{ marginRight: 4, marginTop: 0 }}
    />
    <span>&nbsp;{option.label}</span>
  </div>
);

/**
 * Filter
 * This component is a way to filter items on a page.
 * It has two modes: Multiple and Single.
 * If you pass multiple=true then each option will have a checkbox and the user will be able to
 * select more than one option. If you pass multiple=false then the user will see a Menu component
 * and will be able to select only one option.
 */
class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleSelect = this.handleSelect.bind(this);
  }

  // If the filter drop down is open it'll be closed, otherwise it will be opened
  toggleOpen = () => {
    this.setState({open: !this.state.open});
  };


  // Close the filter's drop down unless the parent's handleSelect method returns true
  handleSelect = (value) => !this.props.handleSelect(value) && this.toggleOpen();

  render() {
    const {options, multiple, selected, alignMenu, itemType, searchable, shadow, border} = this.props;
    return (
      <div style={styles.filter}>
        {/* Toggle */}
        <div onClick={this.toggleOpen} style={(this.state.open) ? styles.disabled : {}}>
          <Toggle
            name={this.props.name}
            selected={this.props.selected}
            options={this.props.options}
            active={this.state.open}
          />
        </div>

        {/* Drop Down */}
        <div style={styles.drop[alignMenu](this.state.open)}>
          {multiple &&
          <ItemList
            items={getMultipleOptions(selected, options, true)}
            shadow={shadow}
            border={border}
            itemType={itemType}
            handleSelect={this.handleSelect}
            handleClose={() => this.state.open && this.toggleOpen()}
          />
          }
          {searchable &&
          <ItemList
            items={getMultipleOptions(selected, options)}
            shadow={shadow}
            border={border}
            itemType={itemType}
            handleSelect={this.handleSelect}
            handleClose={() => this.state.open && this.toggleOpen()}
          />
          }
          {!multiple && !searchable &&
            <Menu
              options={options}
              handleClose={() => this.state.open && this.toggleOpen()}
              handleSelect={this.handleSelect}
            />
          }
        </div>

      </div>
    );
  }
}

/* Styles */
const styles = {
  filter: {
    position: 'relative',
  },
  disabled: {
    pointerEvents: 'none',
  },
  drop: {
    left: open => ({
      position: 'absolute',
      top: 24,
      left: 0,
      display: open ? 'block' : 'none',
      zIndex: 1,
    }),
    right: open => ({
      position: 'absolute',
      top: 24,
      right: 0,
      display: open ? 'block' : 'none',
      zIndex: 1,
    })
  },
  indicator: active => ({
    marginLeft: 4,
    transition: 'transform 0.2s ease',
    transform: active ? 'rotate(180deg)' : 'initial',
  }),
};

/* PropTypes */
Filter.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    fixed: PropTypes.bool,
    outside: PropTypes.bool,
  })).isRequired,
  multiple: PropTypes.bool,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  alignMenu: PropTypes.oneOf(['left', 'right']),
  handleSelect: PropTypes.func.isRequired,
  itemType: PropTypes.string,
  searchable: PropTypes.bool,
};
Filter.defaultProps = {
  alignMenu: 'left',
};

export default Filter;
