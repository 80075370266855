import React, { Component } from 'react';
import { connect } from 'react-redux';

import objectToArray from '../../utils/_objectToArray';
import DocumentTitle from 'react-document-title';
import HelpPanel from '../../containers/HelpPanel';
import Wrapper from '../../containers/Wrapper';
import { Heading } from '../../components/type';
import Button from '../../components/Button';
import InlineButton from '../../components/InlineButton';
import IconButton from '../../components/IconButton';
import colors from '../../styles/colors';
import TeamDetails from './TeamDetails';
import LoadState from '../../containers/LoadState';
import stateHelpers from '../../utils/stateHelpers';
import ComposeMessage from '../../assets/js/components/MessageCenter/components/ComposeMessage';
import { toast } from '../../actions/app';
import { fetchTeamsList, fetchCuratedTeams } from '../../actions/company';
import Note from '../../containers/Note';
import ItemList from '../../components/ItemList';
import ItemListLabel from '../../containers/ItemListLabel';

const pageDescription = `This page contains a comprehensive list of all of your teams. 
Want to view the contributors on each team and the assignments they have access to? 
You can do that here. You can also create a new team.`;

const helpPanelLinks = [
  {
    label: 'Creating a Team',
    href: 'https://onespace.helpdocs.com/teams-contributors/creating-a-team'
  },
  {
    label: 'Managing Your Teams',
    href: 'https://onespace.helpdocs.com/teams-contributors/managing-your-teams'
  },
  {
    label: 'OneSpace Curated Teams',
    href: 'https://onespace.helpdocs.com/teams-contributors/onespace-curated-teams'
  },
  {
    label: 'Using Search to Find the Right Contributor',
    href: 'https://onespace.helpdocs.com/teams-contributors/using-search-to-find-the-right-contributor'
  },
];

class Teams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allTeams: true,
      openCompose: false,
      teamId: '',
      teamName: null,
      sendingMessage: false,
      sentMessage: false,
      openCuratedList: false,
      selectedTeams: [],
      teamsToMessage: [],
    };

    this.toggleAllTeams = this.toggleAllTeams.bind(this);
    this.shouldShowTeam = this.shouldShowTeam.bind(this);
    this.openComposeModal = this.openComposeModal.bind(this);
    this.sentMessage = this.sentMessage.bind(this);
    this.sendingMessage = this.sendingMessage.bind(this);
    this.messageCuratedTeam = this.messageCuratedTeam.bind(this);
    this.curatedTeamsList = this.curatedTeamsList.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchTeamsList());
  }

  toggleAllTeams() {
    this.setState({allTeams: !this.state.allTeams});
  }

  shouldShowTeam(team) {
    const creator = team.id_creator.toString() === this.props.currentUserId.toString();
    return this.state.allTeams || creator;
  }

  openComposeModal(teamInfo) {
    const team = [teamInfo];
    this.setState({teamsToMessage: team, openCompose: true});
  }

  sentMessage() {
    this.setState({sendingMessage: false, sentMessage: true});
    setTimeout(() => this.setState({sentMessage: false}), 4000);
  }

  sendingMessage() {
    this.setState({sendingMessage: true});
  }

  messageCuratedTeam() {
    const { selectedTeams } = this.state;
    let mappedCuratedTeams = selectedTeams.map(team => {
      return {teamId: team.value, teamName: team.label};
    });
    this.setState({
      openCuratedList: !this.state.openCuratedList,
      selectedTeams: [],
      teamsToMessage: mappedCuratedTeams,
      openCompose: true,
    });

  }

  toggleCuratedTeams(val) {
    const { selectedTeams } = this.state;
    const { curatedTeams } = this.props;
    let newSelectedTeams = [...selectedTeams];
    let selectedIndex = null;
    newSelectedTeams.forEach((team, i) => {
      if(team.value === val){
        selectedIndex = i;
      }
    });
    if(selectedIndex !== null){
      newSelectedTeams.splice(selectedIndex, 1);
    }else {
      let selectedTeam = curatedTeams.find(team => {
        return(team.teamId === val);
      });
      newSelectedTeams.push({label: selectedTeam.name, value: selectedTeam.teamId});
    }
    this.setState({selectedTeams: newSelectedTeams});
  }

  getMultipleOptions = (selected, options) => {
    return options.map(option => ({
      value: option.value,
      staticLabel: option.label,
      fixed: option.fixed,
      outside: option.outside,
      label: <ItemListLabel
                option={option}
                selected={selected && !!selected.find(value => value === option.value)} />
    }));
  };

  curatedTeamsList() {
    this.props.dispatch(fetchCuratedTeams());
    this.setState({
      openCuratedList: true,
    });
  }


  render() {
    const {allTeams, openCompose, sendingMessage, sentMessage, openCuratedList, selectedTeams} = this.state;
    const {teams, loadingTeams, loadingCuratedTeams, hasFetchedCuratedTeams} = this.props;
    let curatedTeams = [];
    if(!loadingCuratedTeams && hasFetchedCuratedTeams){
      curatedTeams = this.props.curatedTeams.map(team => {
        const label = `${team.name} (${team.contributorCount})`;
        return {label, value: team.teamId};
      });
    }
    return(
      <DocumentTitle title="Teams | OneSpace Project Center">
        <div className="teams">
          <HelpPanel
            pageTitle="Teams"
            pageDescription={pageDescription}
            links={helpPanelLinks}
          />
          <Wrapper>
            <div style={styles.title}>
              <Heading color="dark" >Teams</Heading>
              <a href="/create-team" style={{ textDecoration: 'none' }}>
                <Button>Create Team</Button>
              </a>
            </div>
            {(sendingMessage || sentMessage) &&
              <div style={{marginBottom: -40, color: colors.dark}} >
                <Note note={sentMessage ? 'Sent' : 'Sending...'} type="notification" />
              </div>
            }
            <div style={styles.allCompanyToggle}>
              <IconButton
                color="white"
                hoverColor="white"
                icon={allTeams ? 'check-square-o' : 'square-o'}
                handleClick={this.toggleAllTeams}>
                <div style={{ textDecoration: 'none !important', display: 'inline-block' }}>
                  View All Company Teams
                </div>
              </IconButton>
              <div style={{position: 'relative'}}>
                <InlineButton handleClick={this.curatedTeamsList}>Message a Curated Team</InlineButton>
                {openCuratedList &&
                  //fix the right style
                    <div style={{marginTop: 5, position: 'absolute', right: 0, width: 450}}>
                      <ItemList
                        items={this.getMultipleOptions(selectedTeams.map(team => {return team.value;}), curatedTeams, true)}
                        handleSelect={value => this.toggleCuratedTeams(value)}
                        handleClose={() => this.setState({openCuratedList: false, selectedTeams: []})}
                        handleButtonClick={this.messageCuratedTeam}
                        disableButton={!!selectedTeams}
                        buttonText="Message Teams"
                        smallList={true}
                        border={true}
                        shadow={true}
                        loading={!!loadingCuratedTeams}
                        itemType="TEAM NAME"/>
                    </div>
                }
              </div>
            </div>
            <div style={styles.projectDetails} className="my-teams-region">
              {loadingTeams ? <LoadState label="Loading Teams" top={20} /> :
                teams.filter(this.shouldShowTeam).map((team, i) => (
                <div key={team.id}>
                  <TeamDetails
                    teamName={team.name}
                    teamDescription={team.description}
                    memberCount={team.member_count}
                    activeAssignments={team.active_assignments}
                    teamId={team.id}
                    teamSwfId={team.id_swf}
                    handleClick={teamInfo => this.openComposeModal(teamInfo)}
                  />
                  {i < (teams.length - 1) &&
                    <hr />
                  }
                </div>
              ))}
            </div>
            {openCompose &&
              <div className="teams-style" style={{fontFamily: 'Open Sans'}} >
                <ComposeMessage
                  handleClose={() => {this.setState({openCompose: false});}}
                  teams={this.state.teamsToMessage}
                  handleSentMessage={() => this.sentMessage()}
                  handleSendingMessage={() => this.sendingMessage()}
                  handleSendFail={() => {this.props.dispatch(toast('Message Failed to Send!', 'error', 4000));}}
                />
              </div>
            }
          </Wrapper>
        </div>
      </DocumentTitle>
    );
  }
}

const styles = {
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  allCompanyToggle: {
    height: 76,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 32,
    paddingRight: 32,
    boxSizing: 'border-box',
    background: colors.dark,
    marginTop: 40,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  projectDetails: {
    border: '1px solid #ebebeb',
    padding: '32px 16px',
  },
};

/* Map State To Props */
function select(state) {
  const teams = objectToArray(state.teams.items);
  const curatedTeams = state.curatedTeams.items;
  return {
    curatedTeams,
    teams,
    loadingTeams: state.teams.isFetching,
    currentUserId: stateHelpers.getUser(state).id,
    hasFetchedCuratedTeams: state.curatedTeams.hasFetched,
    loadingCuratedTeams: state.curatedTeams.isFetching,
  };
}

export default connect(select)(Teams);
