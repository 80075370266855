import React, { Component } from 'react';
import { Label, Caption } from '../../../components/type';
import Input from '../../../components/Input';
import InlineList from '../../../components/InlineList';
import InlineSelect from '../../../components/InlineSelect';
import Panel from '../../../containers/Panel';
import IconButton from '../../../components/IconButton';
import TextField from './Fields/Text';
import IntegerField from './Fields/Integer';
import CheckboxField from './Fields/Checkbox';
import RadioField from './Fields/Radio';
import BooleanField from './Fields/Boolean';
import DropdownField from './Fields/Dropdown';
import FieldPreview from './FieldPreview';
import colors from '../../../styles/colors';

export let fields = {
  'text': TextField,
  'integer': IntegerField,
  'checkbox': CheckboxField,
  'radio': RadioField,
  'boolean': BooleanField,
  'dropdown': DropdownField,
};

class PluginSettings extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { styles, updateChainPlugin, data, readOnly } = this.props;
    const eventOptions = data && data.eventOptions ? data.eventOptions : [];
    return (
      <div style={{ marginBottom: 30 }}>
        <Panel title="Plugin Settings" color="light" small={true}>
          <div style={{padding: 16}}>
            <div style={styles.formField}>
              <Label color="light">Function Call (ARN:string)</Label>
              <Input
                disabled={readOnly}
                value={data.functionName}
                label="Function Call"
                unallowedCharacters={[32]}
                handleChange={val => updateChainPlugin('typeSettings|functionName', val)}
              />
            </div>
            <div style={styles.innerContainer}>
              <div style={{ marginBottom: 20 }}>
                <Label color="light" uppercase={true}>Event Options</Label>
              </div>
              {eventOptions.map((option, index) => {
                return (
                  <div key={index} style={{ paddingTop: 30, borderBottom: `1px solid ${colors.border}`, position: 'relative' }}>
                    {!readOnly &&
                      <div style={{ position: 'absolute', top: 10, right: 0 }}>
                        <IconButton
                          icon="trash-o"
                          label="Delete Option"
                          handleClick={() => updateChainPlugin('typeSettings|eventOptions', 'delete', index)}>
                          <span style={{ textDecoration: 'underline !important' }}>Delete Option</span>
                        </IconButton>
                      </div>
                    }
                    <InlineList>
                      <div style={{flex: 2}}>
                        <div style={styles.formField}>
                          <Label color="light">Name</Label>
                          <Input
                            placeholder="name_no_spaces_allowed"
                            disabled={readOnly}
                            value={option.name}
                            label="Name"
                            unallowedCharacters={[32]}
                            handleChange={val => !readOnly ? updateChainPlugin('typeSettings|eventOptions|name', val, index) : () => {}}
                          />
                        </div>
                      </div>
                      <div style={{flex: 2, paddingLeft: 30, marginTop: 25}}>
                        <div style={styles.formField}>
                          <InlineSelect
                            disabled={readOnly}
                            value={option.type}
                            defaultLabel="Select"
                            height={45}
                            handleSelect={val => {
                              !readOnly ?
                                updateChainPlugin('typeSettings|eventOptions', ['refreshChildren', val], index) :
                                () => {};
                            }}
                            options={[
                              {'label': 'Text', 'value': 'text'},
                              {'label': 'Integer', 'value': 'integer'},
                              {'label': 'Checkbox', 'value': 'checkbox'},
                              {'label': 'Radio', 'value': 'radio'},
                              {'label': 'True/False', 'value': 'boolean'},
                              {'label': 'Dropdown', 'value': 'dropdown'},
                            ]}
                          />
                        </div>
                      </div>
                      {(option.type === 'text' || option.type === 'integer') ?
                      <div style={{flex: 1, paddingLeft: 30, height: 45}}>
                        <div style={styles.formField}>
                          <div style={{display: 'flex'}}>
                            <label style={{flex: 1}}>
                              <div style={styles.radioBtn}>
                                <Input
                                  disabled={readOnly}
                                  checked={option.scope === 'global'}
                                  type="radio"
                                  value="global"
                                  handleChange={val => !readOnly ? updateChainPlugin('typeSettings|eventOptions|scope', val, index) : () => {}}
                                />
                              </div>
                              <div style={styles.labels}>
                                <Caption>Global</Caption>
                              </div>
                            </label>
                            <label style={{flex: 1}}>
                              <div style={styles.radioBtn}>
                                <Input
                                  disabled={readOnly}
                                  checked={option.scope === 'instance'}
                                  type="radio"
                                  value="instance"
                                  handleChange={val => !readOnly ? updateChainPlugin('typeSettings|eventOptions|scope', val, index) : () => {}}
                                />
                              </div>
                              <div style={styles.labels}>
                                <Caption>Instance</Caption>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                        :
                        <div style={{flex: 1, paddingLeft: 30}}>&nbsp;</div>
                      }
                    </InlineList>
                    {(!option.scope || option.scope === 'global') &&
                    <FieldPreview
                      readOnly={readOnly}
                      key={index}
                      index={index}
                      data={option.data}
                      type={option.type}
                      styles={styles}
                      updateChainPlugin={updateChainPlugin}
                    />
                    }
                  </div>
                );
              })}
              {!readOnly &&
                <div style={{ marginTop: 30 }}>
                  <IconButton
                    icon="plus-square"
                    label="Add Event Option"
                    handleClick={() => updateChainPlugin('typeSettings|eventOptions', 'add', null)}>
                    <span style={{ textDecoration: 'underline !important' }}>Add Event Option</span>
                  </IconButton>
                </div>
              }
            </div>
          </div>
        </Panel>
      </div>
    );
  }
}

export default PluginSettings;

