import React from 'react';
import update from 'immutability-helper';
import Radium from 'radium';

import InlineCheckbox from '../../Form/InlineCheckbox';
import InlineInput from '../../Form/InlineInput';
import Select from '../../Form/Select';
import Label from '../../Form/Label';
import Input from '../../Form/Input';
import Settings from '../Settings';
import SupportRoleOptions from './SupportRoleOptions';

import {sortAtoZ} from '../../Preview';

class CheckBox extends React.Component {
  componentDidMount() {
    if (this.props.selected) {
      this.inline.focus();
    }
    if(this.props.minselected && this.props.minselected > 0) {
      this.props.data.required = this.props.minselected > 0;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected && !this.props.selected) {
      this.props.data.options.forEach((option, i) => {
        if (!option.label && !option.key) {
          this.props.updateElement({options: update(this.props.data.options, {$splice: [[i, 1]]})});
        }
      });
    }
    if (!prevProps.selected && this.props.selected) {
      this.inline.focus();
      this.props.handleChangeOutput(this.props.data.output);
    }
  }

  render() {
    const {
      data = {},
      selected,
      updateElement,
      outputError,
      handleChangeOutput,
      handleSelectInput,
      inputs,
      previousOutputs,
      isSupportRole,
    } = this.props;
    let defaultInputs = inputs ? inputs.map(i => ({label: i, value: i})) : [];
    defaultInputs = previousOutputs ? [...defaultInputs, ...previousOutputs.map(i => ({label: i.label, value: i.label}))] : defaultInputs;

    return (
      <div>
        <div style={styles.label}>
          <InlineInput
            ref={ele => this.inline = ele}
            value={data.label}
            selected={selected}
            handleChange={label => updateElement({label})}
            placeholder="Add a label"
            fontSize={16}
            fontWeight={800}
            disabled={!selected}
            onFocus={() => handleSelectInput('label')}
            maxLength={150}
          />
        </div>
        <div style={styles.label}>
          <InlineInput
            value={data.instructions}
            selected={selected}
            handleChange={instructions => updateElement({instructions})}
            placeholder="Add instructions or tips for the contributors"
            fontSize={13}
            fontWeight={600}
            disabled={!selected}
            onFocus={() => handleSelectInput('instructions')}
          />
        </div>
        {data.options && data.options.map((option, i) => (
          <InlineCheckbox
            key={i}
            disabled={data.selectedvalue}
            selected={selected}
            label={option.label}
            value={option.key}
            checked={option.selected}
            onSelect={(selected) => updateElement({options: update(data.options, {[i]: {$merge: {selected: selected ? 'selected': null}}})})}
            onChangeLabel={label => updateElement({options: update(data.options, {[i]: {$merge: {label}}})})}
            onChangeValue={key => updateElement({options: update(data.options, {[i]: {$merge: {key}}})})}
            onDelete={() => updateElement({options: update(data.options, {$splice: [[i, 1]]})})}
            onFocus={key => handleSelectInput(key, i)}
          />
        ))}
        <div style={[styles.footer, !selected && styles.footerHidden]}>
          <div
            style={styles.smallBtn}
            onClick={() => {
              let options = [...data.options];
              options.sort(sortAtoZ);
              updateElement({options: update(data.options, {$set: options})});
            }}
          >
            Sort List Alphabetically
          </div>
          <div
            style={styles.smallBtn}
            onClick={() => updateElement({options: update(data.options, {$push: [{}]})})}>
            + Add Checkbox
          </div>
        </div>

        <Settings show={selected} customHeight="auto">
          <Select
            label="Select a default input value (optional)"
            value={data.selectedvalue}
            handleChange={selectedvalue => { 
            if(!!selectedvalue) {
              let options = [...data.options];
              options.forEach(option => option.selected = null);
              updateElement({selectedvalue, options: update(data.options, {$set: options})});
            }
            updateElement({selectedvalue});
            }}
            options={defaultInputs}
            wrapOutputValue={true}
          />
          <Select
            label="Select the number of columns to display (optional)"
            value={data.columns}
            handleChange={columns => updateElement({columns})}
            options={[
                    {label: '1', value: '1'},
                    {label: '2', value: '2'},
                    {label: '3', value: '3'},
                    {label: '4', value: '4'},
                    {label: '5', value: '5'},
                  ]}
            wrapOutputValue={false}
          />
          <Input
            value={data.output}
            label="Output Name"
            onChange={output => {
              handleChangeOutput(output);
              updateElement({output});
            }}
            maxWidth={280}
            error={outputError}
          />
          <div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Label label="Requirements"/>
              <div style={styles.subLabel}>Set limits on how many choices can be selected</div>
            </div>
            <div style={{display: 'flex'}}>
              <div style={{marginRight: 16}}>
                <Input
                  value={data.minselected}
                  onChange={minselected => updateElement({minselected, required: minselected > 0})}
                  maxWidth={64}
                  placeholder="Min"
                />
              </div>
              <div>
                <Input
                  value={data.maxselected}
                  onChange={maxselected => updateElement({maxselected})}
                  maxWidth={64}
                  placeholder="Max"
                />
              </div>
            </div>
          </div>
          {isSupportRole &&
          <SupportRoleOptions
            data={data}
            updateElement={updateElement}
            handleSelectInput={handleSelectInput}
          />
          }
        </Settings>
      </div>
    );
  }
}

const styles = {
  smallBtn: {
    background: '#33aab9',
    color: 'white',
    padding: '4px 8px',
    borderRadius: 12,
    fontSize: 12,
    display: 'inline-block'
  },
  footer: {
    marginTop: 8,
    display: 'flex',
    justifyContent: 'space-between',
  },
  footerHidden: {
    display: 'none'
  },
  label: {
    marginBottom: 8
  },
  subLabel: {
    fontSize: 12,
    marginLeft: 8,
    marginBottom: 2
  },
  instructions: {
    fontSize: 12,
    marginBottom: 8
  }
};

export default Radium(CheckBox);
