import { updateState } from '../utils/reducerHelpers';
import update from 'immutability-helper';

import {
  FETCH_CURATED_TEAMS_REQUEST,
  FETCH_CURATED_TEAMS_SUCCESS,
  FETCH_CURATED_TEAMS_FAILURE
} from '../constants/actionTypes';

const initialState = {
  items: {},
  isFetching: false,
  hasFetched: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CURATED_TEAMS_REQUEST:
      return updateState.fetchRequest(state);
    case FETCH_CURATED_TEAMS_SUCCESS:
      return update(state, {
        items: {$set: action.teams.data.teams},
        isFetching: {$set: false},
        hasFetched: {$set: true}
      });
    case FETCH_CURATED_TEAMS_FAILURE:
      return updateState.fetchFailure(state);
    default:
      return state;
  }
}