import React, { Component } from 'react';
import Radium from 'radium';

import Element from './Element';
import { ElementOptions } from '../constants';

import Tabs, { Pane } from '../../../../../components/Tabs';

class ElementSelector extends Component {
  constructor(props) {
    super(props);
  }

  handleTabLabel(label) {
    return (
      <div style={styles.title}>{label}</div>
    );
  }

  confirmDeletion() {
    const confirmed = confirm('Are you sure you want to delete this custom element?');
    return confirmed ? confirmed : false;
  }

  handleCategories(elementList, category) {
    return (
      <div style={styles.elements}>
        {elementList.filter(el => el.cat === category).map((element, i) => {
          return (
            <div key={i} style={{ position: 'relative', display: 'inline-block' }}>
              {element.customId &&
                <i
                  className="fa fa-trash"
                  onClick={() => {
                    if(this.confirmDeletion()) {
                      this.props.deleteLibraryElement(element.customId);
                    }
                  }}
                  style={styles.trashIcon}
                />
              }
              <Element
                isDeleting={element.isDeleting}
                key={i}
                index={i}
                element={element}
                quickAdd={() => this.props.quickAdd(element.type, element)}
              />
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const { libraryElements } = this.props;
    const layoutContent = this.handleCategories(ElementOptions, 'layout');
    const layoutLabel = this.handleTabLabel('Layout Elements');

    const displayContent = this.handleCategories(ElementOptions, 'display');
    const displayLabel = this.handleTabLabel('Display Elements');

    const collectContent = this.handleCategories(ElementOptions, 'collect');
    const collectLabel = this.handleTabLabel('Collect Elements');

    const libraryElementsContent = this.handleCategories(libraryElements, 'custom');
    const libraryElementsLabel = this.handleTabLabel('My Library Elements');

    const tabs = [{
      name: layoutLabel,
      content: layoutContent,
    },{
      name: displayLabel,
      content: displayContent,
    }, {
      name: collectLabel,
      content: collectContent,
    },{
      name: libraryElementsLabel,
      content: libraryElementsContent,
    },];

    return (
      <div style={styles.main}>
        <Tabs
          selected={0}
          tabBgColor={'#242726'}
          activeTabBgColor={'#444847'}
          tabLabelsBgColor={'#242726'}
          borderColor={'#717573'}
        >
          {tabs.map((tab, i) =>
            <Pane
              key={i}
              label={tab.name}
            >
              {tab.content}
            </Pane>)
          }
        </Tabs>
      </div>
    );
  }
}

const styles = {
  main: {
    zIndex: 9,
    width: '100%',
    top: -220,
    transition: 'top 0.6s ease',
    flex: 1,
    background: '#242726',
    padding: 4,
  },
  title: {
    height: 20,
    padding: '4px 80px',
    boxSizing: 'border-box',
    lineHeight: '12px',
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: 9,
    fontWeight: 700,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  titleSmall: {
    width: 'calc(100% / 12 * 4 + 2px)',
    background: '#3b3f3e',
    borderRight: '2px solid rgba(255, 255, 255, 0.54)'
  },
  trashIcon: {
    fontSize: 12,
    position: 'absolute',
    right: 0,
    top: 10,
    color: 'rgba(255,255,255,0.8)',
  },
  elements: {
    whiteSpace: 'nowrap',
    display: 'block',
    overflowX: 'auto',
    backgroundColor: '#444847',
    border: '1px solid #717573',
  }
};

export default Radium(ElementSelector);
