import React, { Component } from 'react';
import Radium from 'radium';

import Item from './Item';
import NewInput from './NewInput';
import { nonselfdroppablelayoutTypes } from '../constants';

class IO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addingInput: false,
      error: null,
      toggledSidebar: false,
    };
  }

  validateAddInput(label) {
    const { inputs } = this.props;
    let expandedInputs = [];
    let expandedLabel = [];

    inputs.forEach(input => {
      let matches = input.match(/\[[0-9]+(-[0-9]+)]/);
      expandedInputs.push(input);
      if(!!matches) {
        const iNumbers = matches[0].split(/\[|\]|\-/);
        for(let i = +iNumbers[1]; i <= iNumbers[2]; i++) {
          expandedInputs.push(input.replace(matches[0], i));
        }
      }
    });
    // close if nothing is submitted
    if(!label) {
     this.setState({addingInput: false});
    }
    // validate against other inputs
    if(expandedInputs.find(function(i) { return i === label; })) {
      this.setState({error: `${label} is in use.`});
      return false;
    }
    // validate if using '[ ]'
    if(!!label.match(/\[|\]/)) {
      const matches = label.match(/\[[0-9]+(-[0-9]+)]/);
      // validate correct syntax
      if(!matches ) {
        this.setState({error: `${label} is not properly formatted`});
        return false;
      } else {
        let errorFree = true;
        const iNumbers = matches[0].split(/\[|\]|\-/);
        for(let i = +iNumbers[1]; i <= iNumbers[2]; i++) {
          expandedLabel.push(label.replace(matches[0], i));
        }
        expandedLabel.forEach(newLabel => {
          const validate = this.validateAddInput(newLabel, true);
          if(!validate){
            errorFree = false;
          }
        });
          return errorFree;
      }
    }
    return true;
  }

  render() {
    const { inputs, previousOutputs, elements, selectedInput, handleClick, handleAddLabel, assignmentName } = this.props;
    const outputs = [];
    const outputsReq = [];
    elements.forEach(e => {

      /* If type is one of the 4 layout types, loop through columns and children and get outputs */
      if (nonselfdroppablelayoutTypes.includes(e.type)) {
        e.data.columns.forEach(col => {
          col.children.forEach(ele => {
            if (ele.data.output) {
              let output = ele.data.output;
              let endRange = +e.startRange + +e.numberOfDuplicates - 1;
              outputs.push(output.replace('#{count}', `[${e.startRange}-${endRange}]`));
              outputsReq.push(ele.data.required ? true : false);
            }
          });
        });
      }

      /* If regular element with data output */
      if (e.data.output) {
        outputs.push(e.data.output);
        outputsReq.push(e.data.required ? true : false);
      }

    });
    return (
      <div style={[styles.main, this.state.toggledSidebar && styles.mainCollapsed]}>
        <div style={styles.collapser}>
          <i
            style={[styles.chevron, this.state.toggledSidebar && styles.chevronCollapsed]}
            className="fa fa-chevron-right"
            onClick={() => {
              this.setState({toggledSidebar: !this.state.toggledSidebar});
            }}
          />
        </div>
        {window.workflow &&
        <div style={[styles.section, this.state.toggledSidebar && styles.sectionCollapsed]}>
          <div>
            <span style={styles.nameTitle}>Workflow</span>
            <span style={styles.nameText}>{window.workflow.label}</span>
          </div>
          {assignmentName.stepName &&
          <div style={{ marginTop: 12 }}>
            <span style={styles.nameTitle}>Assignment</span>
            <span style={styles.nameText}>{assignmentName.stepName}</span>
          </div>
          }
        </div>
        }
        <div style={[styles.section, this.state.toggledSidebar && styles.sectionCollapsed]}>
          <div style={styles.header}>
            <div style={styles.title}>You Provide</div>
            <div style={styles.label}>Inputs</div>
          </div>
          <div style={styles.itemList}>
            {inputs.map((input, i) => (
              <Item
                handleClick={() => handleClick(input)}
                canClick={selectedInput}
                key={i}
                label={input}
              />
            ))}
            {previousOutputs.map((input, i) => (
              <Item
                handleClick={() => handleClick(input.label)}
                canClick={selectedInput}
                key={i}
                label={input.label}
                from={input.from}
              />
            ))}
            {this.state.addingInput &&
            <NewInput
              error={this.state.error}
              handleUnselect={() => this.setState({addingInput: false})}
              handleSubmit={label => {
                this.setState({error: null});
                {this.validateAddInput(label) && 
                  handleAddLabel(label);
                }
              }}
            />
            }
            { ( !this.state.addingInput && !inputs.length ) &&
            <div style={styles.errorMessage}>
              You must add at least one input
            </div>
            }
          </div>
          <div style={styles.footer}>
            {!this.state.addingInput &&
            <div
              style={styles.smallBtn}
              onClick={() => this.setState({addingInput: !this.state.addingInput})}>
              + Add Input
            </div>
            }
          </div>
          <div style={styles.subTitle}>NOTE:</div>
          <div style={styles.note}>
            You can add inputs without surfacing them in the assignment interface. You can only delete inputs if they aren’t currently being used in the assignment interface. Delete unused inputs on the launch page.
          </div>
        </div>
        <div style={[styles.section, styles.sectionLast, this.state.toggledSidebar && styles.sectionCollapsed]}>
          <div style={styles.header}>
            <div style={styles.title}>They Deliver</div>
            <div style={styles.label}>Outputs</div>
          </div>
          <div style={styles.itemList}>
            {!outputs.length &&
            <i style={styles.emptyState} className="fa fa-exclamation-circle"/>
            }
            {outputs.map((input, i) => (
              <div key={i}>
                <Item label={input} required={outputsReq[i]} hasDuplicates={input.indexOf('[') >= 0 ? true : false} />
              </div>
            ))}
          </div>
          <div style={styles.note}>
            Add outputs by selecting a collection element above, adding it to the assignment interface and defining an output name. Delete outputs by removing the associated collection element from the assignment interface.
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  main: {
    width: 360,
    background: '#d7d7d7',
    boxSizing: 'border-box',
    overflowY: 'auto',
    transition: 'all 0.1s ease',
  },
  mainCollapsed: {
    width: 20,
    overflow: 'hidden',
    transition: 'all 0.1s ease',
  },
  section: {
    padding: 20,
    boxSizing: 'border-box',
    borderBottom: '2px solid #e3e4e4',
  },
  sectionCollapsed: {
    whiteSpace: 'nowrap',
    display: 'none',
  },
  sectionLast: {
    borderBottom: 'none'
  },
  collapser: {
    borderBottom: '1px solid #e3e4e4',
    color: 'white',
  },
  chevron: {
    backgroundColor: '#444847',
    fontSize: 12,
    padding: '8px 6px',
  },
  chevronCollapsed: {
    transform: 'rotate(180deg)',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 24
  },
  title: {
    fontSize: 18,
    fontWeight: 800
  },
  label: {
    fontWeight: 800,
    fontSize: 10,
    textTransform: 'uppercase'
  },
  nameTitle: {
    textTransform: 'uppercase',
    display: 'block',
    fontSize: 12,
    fontWeight: 800,
    marginBottom: 4,
  },
  nameText: {
    display: 'block',
    fontSize: 10,
    fontStyle: 'italic',
  },
  itemList: {
    marginBottom: 16
  },
  subTitle: {
    fontSize: 12,
    fontWeight: 800,
    marginBottom: 8
  },
  note: {
    fontSize: 12,
    lineHeight: 2,
    fontStyle: 'italic',
    fontWeight: 600
  },

  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 8
  },
  smallBtn: {
    cursor: 'pointer',
    background: '#33aab9',
    color: 'white',
    padding: '4px 8px',
    borderRadius: 12,
    fontSize: 12
  },
  errorMessage: {
    fontSize: 11,
    color: '#f94d1f',
    marginBottom: 4
  },
};

export default Radium(IO);
