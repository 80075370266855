import React from 'react';
import update from 'immutability-helper';

import Input from '../../Form/Input';
import Select from '../../Form/Select';
import InlineInput from '../../Form/InlineInput';
import Switch from '../../Form/Switch';
import Settings from '../Settings';
import SupportRoleOptions from './SupportRoleOptions';

class DropList extends React.Component {
  componentDidMount() {
    if (this.props.selected) {
      this.inline.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.selected && !this.props.selected) {
      this.props.data.options.forEach((option, i) => {
        if(!option.label && !option.key) {
          this.props.updateElement({options: update(this.props.data.options, {$splice: [[i, 1]]})});
        }
      });
    }
    if (!prevProps.selected && this.props.selected) {
      this.inline.focus();
      this.props.handleChangeOutput(this.props.data.output);
    }
  }

  render() {
    const {data = {}, selected, updateElement, handleSelectInput, outputError, inputs, previousOutputs, isSupportRole, handleChangeOutput} = this.props;
    let defaultInputs = inputs ? inputs.map(i => ({label: i, value: i})) : [];
    defaultInputs = previousOutputs ? [...defaultInputs, ...previousOutputs.map(i => ({label: i.label, value: i.label}))] : defaultInputs;
    return (
      <div style={styles.main}>
        <div style={styles.label}>
          <InlineInput
            ref={ele => this.inline = ele}
            value={data.label}
            selected={selected}
            handleChange={label => updateElement({label})}
            placeholder="Add a label"
            fontSize={16}
            fontWeight={800}
            disabled={!selected}
            onFocus={() => handleSelectInput('label')}
            maxLength={150}
          />
        </div>
        <div style={styles.label}>
          <InlineInput
            value={data.instructions}
            selected={selected}
            handleChange={instructions => updateElement({instructions})}
            placeholder="Add instructions or tips for the contributors"
            fontSize={13}
            fontWeight={600}
            disabled={!selected}
            onFocus={() => handleSelectInput('instructions')}
          />
        </div>
        <div style={styles.mockSelect}>
          <i className="fa fa-caret-down"/>
        </div>
        <div style={styles.options}>
          {data.options.map((option, i) => (
            <div key={i} style={styles.option}>
              { selected ?
                <InlineInput
                  value={option.label}
                  selected={selected}
                  handleChange={label => updateElement({options: update(data.options, {[i]: {$merge: {label, key: option.key}}})})}
                  placeholder="Add a label"
                  fontSize={13}
                  fontWeight={800}
                  disabled={!selected}
                  onFocus={() => handleSelectInput('label', i)}
                  maxLength={150}
                />
                :
                <div style={styles.staticOption}>{option.label}</div>
              }
              {selected &&
              <InlineInput
                value={option.key}
                selected={selected}
                handleChange={key => updateElement({options: update(data.options, {[i]: {$merge: {label: option.label, key}}})})}
                placeholder="Key (if different than label)"
                fontSize={13}
                fontWeight={400}
                disabled={!selected}
                onFocus={() => handleSelectInput('key', i)}
              />
              }
              {selected &&
              <i className="fa fa-trash-o"
                 onClick={() => updateElement({options: update(data.options, {$splice: [[i, 1]]})})}/>
              }
            </div>
          ))}
        </div>
        <Settings show={selected} customHeight="auto">
          <div style={[styles.footer, !selected && styles.footerHidden]}>
            <div
              style={styles.smallBtn}
              onClick={() => updateElement({options: update(data.options, {$push: [{}]})})}>
              + Add Option
            </div>
          </div>
          <Select
            label="Select a default input value (optional)"
            value={data.selectedvalue}
            handleChange={selectedvalue => updateElement({selectedvalue})}
            options={defaultInputs}
            wrapOutputValue={true}
          />

          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Input
              value={data.output}
              label="Output Name"
              onChange={output => {
                handleChangeOutput(output);
                updateElement({output});
              }}
              maxWidth={280}
              error={outputError}
            />
            <Switch
              label="Required"
              isOn={data.required}
              handleToggle={() => updateElement({required: !data.required})}
            />
          </div>
          {isSupportRole &&
          <SupportRoleOptions
            data={data}
            updateElement={updateElement}
            handleSelectInput={handleSelectInput}
          />
          }
        </Settings>
      </div>
    );
  }
}

const styles = {
  main: {
    paddingTop: 8
  },
  label: {
    marginBottom: 8
  },
  mockSelect: {
    background: 'white',
    borderRadius: 2,
    height: 40,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: 8,
    boxSizing: 'border-box'
  },
  options: {
    background: 'white',
    boxShadow: '0 1px 2px rgba(0,0,0,0.32)',
    borderTop: '1px solid rgba(0,0,0,0.2)'
  },
  option: {
    display: 'flex',
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 4,
    paddingBottom: 4,
    borderBottom: '1px solid rgba(0,0,0,0.2)',
    boxSizing: 'border-box'
  },
  staticOption: {
    fontSize: 13,
    fontWeight: 800,
    lineHeight: '16px',
    paddingTop: 5,
    paddingBottom: 5
  },
  smallBtn: {
    background: '#33aab9',
    color: 'white',
    padding: '4px 8px',
    borderRadius: 12,
    fontSize: 12,
    display: 'inline-block'
  },
  footer: {
    marginTop: 8,
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 16
  },
  footerHidden: {
    display: 'none',
    marginBottom: 0
  },
  instructions: {
    fontSize: 12,
    marginBottom: 8
  }
};

export default DropList;
