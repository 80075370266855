import React, { Component } from 'react';
import { connect } from 'react-redux';

import { toggleModal } from '../../actions/app';
import { archiveWorkflow } from '../../actions/workflows';
import { ARCHIVE_WORKFLOW, UNARCHIVE_WORKFLOW } from '../../constants/modalTypes';
import Button from '../../components/Button';
import { Label, BodyInstruction, Caption } from '../../components/type';

const archiveConfirmation = (archive) => {
  return `Are you sure you want to ${archive ? 'archive' : 'unarchive'} this workflow?`;
};

const archiveDesc = (archive) => {
  return archive ? 'Archiving a workflow will hide it from your monitoring and project views. '  +
    'Plus, any open items associated with this workflow will be cancelled.' :
    'Unarchiving a workflow will allow you to upload work and make the workflow active.' +
    ' You should rename your workflow and assignments when unarchiving.';
};

const archiveButton = (archive) => {
  return `Yes! ${archive ? 'Archive' : 'Unarchive'} Workflow.`;
};

const dontArchive = (archive) => {
  return `Nevermind! I don\'t want to ${archive ? 'archive' : 'unarchive'} this workflow`;
};


class ArchiveWorkflow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isArchiving: false,
    };
  }

  archiveWorkflow() {
    const { archive } = this.props;
    this.setState({isArchiving: true});
    this.props.dispatch(archiveWorkflow(this.props.workflowId, archive))
      .then(() => {
        if(archive) {
          this.props.dispatch(toggleModal(ARCHIVE_WORKFLOW));
        } else {
          this.props.dispatch(toggleModal(UNARCHIVE_WORKFLOW));
        }
      });
  }

  render() {
    const { archive } = this.props;
    return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <BodyInstruction
          bottom={16}
          top={16}>
          {archiveConfirmation(archive)}
        </BodyInstruction>
        <Caption center={true}>
          {archiveDesc(archive)}
        </Caption>
        <div style={{margin: '16px 0px'}}>
          <Button
            disabled={this.state.isArchiving}
            loading={this.state.isArchiving}
            handleClick={() => this.archiveWorkflow()}>
            {archiveButton(archive)}
          </Button>
        </div>
        <Label
          underline={true}
          handleClick={() => this.props.dispatch(toggleModal(ARCHIVE_WORKFLOW))}>
          {dontArchive(archive)}
        </Label>
      </div>
    );
  }
}

function select(state) {
  return {
    workflowId: state.app.modalMeta.workflowId,
    archive: state.app.modalMeta.archive,
  };
}

export default connect(select)(ArchiveWorkflow);