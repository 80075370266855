import React from 'react';
import moment from 'moment';
import Modal from 'react-modal';
import Panel from '../../../containers/Panel';
import modalStyle from '../../../styles/modal';
import { MediumLabel, SmallDescription, Label } from '../../../components/type';
import InlineList from '../../../components/InlineList';
import InlineButton from '../../../components/InlineButton';
import colors from '../../../styles/colors';
import SettingsReadOnly from './SettingsReadOnly';
import LoadState from '../../../containers/LoadState';

const getVersionData = (clickedVersionData) => clickedVersionData;

const VersionHistory = ({
                          viewVersion,
                          toggleVersionModal,
                          handleCloseModal,
                          isClosing,
                          styles,
                          clickedVersionData,
                          chainPluginVersions,
                        }) => (
  <div>
    {!chainPluginVersions.length ?
      <div>No versions yet</div> :
      <div>
        <div style={{ marginBottom: 10 }}>
          <InlineList>
            <div style={{ flex: 3 }}>
              <MediumLabel uppercase={true} color="dark">CREATED</MediumLabel>
            </div>
            {/*<div style={{ flex: 2 }}>*/}
            {/*<MediumLabel uppercase={true} color="dark">MODIFIED BY</MediumLabel>*/}
            {/*</div>*/}
            <div style={{ flex: 1 }}>&nbsp;</div>
          </InlineList>
        </div>

        {chainPluginVersions.map((v, i) =>
          <div key={i} style={{ borderTop: `1px solid ${colors.border}`, padding: '7px 0' }}>
            <InlineList>
              <div style={{ flex: 3 }}>
                <Label>version {v.version}</Label>
                <SmallDescription color="dark">{moment.utc(v.updatedAt).fromNow()}</SmallDescription>
              </div>
              {/*<div style={{ flex: 2 }}>*/}
              {/*<SmallDescription color="dark">{v.creator}</SmallDescription>*/}
              {/*</div>*/}
              <div style={{ flex: 1, textAlign: 'right' }}>
                <InlineButton
                  title={'Version ' + v.version}
                  disabled={false}
                  handleClick={() => {
                    viewVersion(!toggleVersionModal, v.version);
                  }}>
                  {'View'}
                </InlineButton>
              </div>
            </InlineList>
          </div>
        )}
        <Modal
          contentLabel="Modal"
          style={modalStyle}
          isOpen={toggleVersionModal}
          overlayClassName={isClosing ? 'ReactModal__Overlay--closing' : ''}
          onRequestClose={() => handleCloseModal}
        >
          <Panel
            title=""
            actions={[
              {
                icon: 'times',
                handleClick: () => viewVersion(!toggleVersionModal),
                label: 'Close'
              }
            ]}
            simple={true}>
            {(!!getVersionData(clickedVersionData) && !getVersionData(clickedVersionData)._id) ?
              <LoadState/> : <SettingsReadOnly
                readOnly={true}
                chainPluginData={getVersionData(clickedVersionData)}
                styles={styles}
              />
            }
          </Panel>
        </Modal>
      </div>
    }
  </div>
);

export default VersionHistory;