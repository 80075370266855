import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';

import colors from '../styles/colors';
import { Label } from '../components/type';


//This component should be replaced with LazyTabSection when the my projects page is removed/redesigned
class TabSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.activeTab || 0,
      loadingContent: false,
    };
  }

  render() {
    const { tabLabels, tabContent, tabClasses } = this.props;
    const { activeTab } = this.state;
    return (
      <div>
        <div className={css(styles.tabs)}>
          {tabLabels.map((label, i) => (
            <Label
              key={i}
              handleClick={() => this.setState({ activeTab: i })}
              underline={activeTab !== i}
              color={activeTab === i ? 'dark' : 'dark'}
              className={
                `${css(styles.tab, activeTab === i && styles.activeTab)} ${tabClasses[i]}`
              }>
              {label}
            </Label>
          ))}
        </div>
        <div className={css(styles.content)}>
          {tabContent[activeTab]}
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  tabs: {
    display: 'flex',
    alignItems: 'flex-end',
    height: 64,
    borderBottom: '1px solid #ebebeb',
  },
  tab: {
    display: 'flex',
    alignItems: 'center',
    height: 38,
    marginLeft: -1,
    paddingLeft: 16,
    paddingRight: 16,
    background: colors.background,
  },
  activeTab: {
    background: 'white',
    border: '1px solid #ebebeb',
    marginBottom: -1
  },
  content: {},
});

export default TabSection;
