import React from 'react';
import { connect } from 'react-redux';
import Radium from 'radium';
import { newChildElementAt, moveElementTo, updateTaskUIElement, deleteElement } from '../../actions/elements';
import { addLibraryElement, elementChanged, updateLibraryElement } from '../../actions/libraryElements';
import { selectElement, selectInput } from '../../actions/editor';
import Editor from '../../Editor';
import Static from '../../Static';
import SaveToLibraryModal from '../../modals/SaveToLibrary';
import SupportRoleOptions from './SupportRoleOptions';

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      libraryElement: '',
      allProjects: true,
      libraryElementSaving: false,
    };
  }

  render() {
    const {
      data,
      dispatch,
      selected,
      selectedChild,
      selectedColumn,
      parentIndex,
      numberOfColumns,
      elementType,
      isStatic,
      inputs,
      isSupportRole,
      updateElement,
      handleSelectInput,
      outputs,
      numDuplicates,
    } = this.props;

    let editorCols = [];

    if(isStatic) {
      for (let colIdx = 0; colIdx < numberOfColumns; colIdx++) {
        editorCols.push(
          <Static
            key={colIdx}
            elements={Object.keys(data.columns[colIdx].children).map(order => data.columns[colIdx].children[order])}
            isStatic={true} 
            isFourColumn={numberOfColumns === 4 ? true : false}
            columnIndex={colIdx}
            />
        );
      }
      return <div style={styles.editorBox}>{editorCols}</div>;

    } else {
      for (let colIdx = 0; colIdx < numberOfColumns; colIdx++) {
        editorCols.push(
          <div style={styles.editorBox} key={colIdx}>
            <div style={styles.editorBoxWrap}>
              <Editor
                key={colIdx}
                numDuplicatesForChildren={numDuplicates}
                elements={Object.keys(data.columns[colIdx].children).map(order => data.columns[colIdx].children[order])}
                inputs={inputs}
                previousOutputs={outputs}
                selected={selected == parentIndex && selectedColumn === colIdx}
                selectedChild={selectedChild}
                handleNewElement={(type, eleIdx, nada, element) => {
                  return dispatch(newChildElementAt(eleIdx, type, parentIndex, colIdx, element));
                }}
                handleAddToLibrary={(element) => {
                  this.setState({ openModal: true, libraryElement: element });
                }}
                handleUpdateElement={(element) => {
                  return dispatch(updateLibraryElement(element, element.customId)).then(() => {
                    dispatch(elementChanged('updated'));
                    setTimeout(() => {
                      dispatch(elementChanged(''));
                    }, 1500);
                  });
                }}
                handleMoveElement={(from, to) => {
                  if (data.makeUneditable && !isSupportRole) {
                    return;
                  }
                  dispatch(moveElementTo(to, from, parentIndex, selectedChild, colIdx));
                  if(Array.isArray(to)){
                    let indexOfParent = parentIndex;
                    let indexOfElement = 0;
                    if(Array.isArray(from)){
                      indexOfElement = (from[0] < to[0] && from[1] === to[1] && from[2] === to[2]) ? to[0] - 1 : to[0];
                    } else {
                      indexOfParent = from < to[2] ? to[2] - 1 : to[2];
                      indexOfElement = from < to[0] ? to[0] - 1 : to[0];
                    }
                    dispatch(selectElement(indexOfElement, indexOfParent, colIdx, true));
                  } else {
                    dispatch(selectElement(to, parentIndex, colIdx, false));
                  }
                }}
                handleChange={(index, change) => dispatch(updateTaskUIElement([index, colIdx, parentIndex], change))}
                handleDeleteElement={() => {
                  return dispatch(deleteElement(parentIndex, selectedChild, colIdx));
                }}
                handleSelectElement={(index) => {
                  if (data.makeUneditable && !isSupportRole) {
                    return;
                  }
                  return dispatch(selectElement(index, parentIndex, colIdx, 'true'));
                }}
                handleSelectInput={(input, inputIndex) => dispatch(selectInput(input, inputIndex))}
                hideBottomNav={true}
                isLayout={parentIndex}
                columnIndex={colIdx}
                elementType={elementType}
                disableVerticalScroll={true}
                isSupportRole={isSupportRole}
              />
            </div>
            <SaveToLibraryModal
              isOpen={this.state.openModal}
              libraryElement={this.state.libraryElement}
              allProjects={this.state.allProjects}
              allProjectsSelected={allProjectsSelected => {
                this.setState({ allProjects: allProjectsSelected });
              }}
              saveElement={element => {
                this.setState({ libraryElementSaving: true });
                dispatch(addLibraryElement(element, this.state.allProjects)).then(() => {
                  dispatch(elementChanged('saved'));
                  this.setState({
                    openModal: false,
                    libraryElementSaving: false,
                  });
                  setTimeout(() => {
                    dispatch(elementChanged(''));
                  }, 1500);
                });
              }}
              getName={name => {
                const newElement = this.state.libraryElement;
                let newerElement = Object.assign({}, newElement, { name });
                this.setState({ libraryElement: newerElement });
              }}
              closeIt={() => this.setState({ openModal: false })}
              savingElement={this.state.libraryElementSaving}
            />
          </div>
        );
      }
      return (
        <div>
          <div style={styles.editorBox}>{editorCols}</div>
          {(isSupportRole && selected == parentIndex) &&
          <div style={{ marginTop: 16 }}>
            <SupportRoleOptions
              data={data}
              updateElement={updateElement}
              handleSelectInput={handleSelectInput}
            />
          </div>
          }
        </div>
      );
    }
  }
}

const styles = {
  editorBox: {
    display: 'flex',
    flex: 1,
    backgroundColor: 'white',
  },
  editorBoxWrap: {
    flex: 1,
  },
};

function select(state) {
  return {
    selectedColumn: state.editor.selectedColumn,
    selectedChild: state.editor.selectedChild,
    selected: state.editor.selected,
    input: state.editor.input,
    inputIndex: state.editor.inputIndex,
    elements: Object.keys(state.elements).map(order => state.elements[order]),
    inputs: state.inputOutputs.inputs,
    outputs: state.inputOutputs.outputs
  };
}

const connected = connect(select)(Layout);
export default Radium(connected);
