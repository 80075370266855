/* Editor */
export const SELECT_ELEMENT = 'SELECT_ELEMENT';
export const SELECT_INPUT = 'SELECT_INPUT';
export const REPEATABLE_ELEMENT = 'REPEATABLE_ELEMENT';

/* Elements */
export const NEW_ELEMENT = 'NEW_ELEMENT';
export const NEW_CHILD_ELEMENT = 'NEW_CHILD_ELEMENT';
export const MOVE_ELEMENT = 'MOVE_ELEMENT';
export const UPDATE_ELEMENT = 'UPDATE_ELEMENT';
export const DELETE_ELEMENT = 'DELETE_ELEMENT';

/* IO */
export const ADD_INPUT = 'ADD_INPUT';

/* LIBRARY ELEMENTS */
export const FETCH_LIBRARY_ELEMENTS_REQUEST = 'FETCH_LIBRARY_ELEMENTS_REQUEST';
export const FETCH_LIBRARY_ELEMENTS_SUCCESS = 'FETCH_LIBRARY_ELEMENTS_SUCCESS';
export const FETCH_LIBRARY_ELEMENTS_FAILURE = 'FETCH_LIBRARY_ELEMENTS_FAILURE';

export const ADD_LIBRARY_ELEMENT_REQUEST = 'ADD_LIBRARY_ELEMENT_REQUEST';
export const ADD_LIBRARY_ELEMENT_SUCCESS = 'ADD_LIBRARY_ELEMENT_SUCCESS';
export const ADD_LIBRARY_ELEMENT_FAILURE = 'ADD_LIBRARY_ELEMENT_FAILURE';

export const DELETE_LIBRARY_ELEMENT_REQUEST = 'DELETE_LIBRARY_ELEMENT_REQUEST';
export const DELETE_LIBRARY_ELEMENT_SUCCESS = 'DELETE_LIBRARY_ELEMENT_SUCCESS';
export const DELETE_LIBRARY_ELEMENT_FAILURE = 'DELETE_LIBRARY_ELEMENT_FAILURE';

export const UPDATE_LIBRARY_ELEMENT_REQUEST = 'UPDATE_LIBRARY_ELEMENT_REQUEST';
export const UPDATE_LIBRARY_ELEMENT_SUCCESS = 'UPDATE_LIBRARY_ELEMENT_SUCCESS';
export const UPDATE_LIBRARY_ELEMENT_FAILURE = 'UPDATE_LIBRARY_ELEMENT_FAILURE';

export const ELEMENT_CHANGED = 'ELEMENT_CHANGED';
