export const Fields = [
  {
    'type': 'text',
    'name': '',
    'scope': 'global',
    'data': [
      {
        'value': '',
      }
    ]
  },
  {
    'type': 'integer',
    'name': 'integer_name_here',
    'data': [
      {
        'value': '',
      },
    ]
  },
  {
    'type': 'checkbox',
    'name': 'checkbox_name_here',
    'data': [
      {
        'label': '',
        'value': '',
        'default': false
      }
    ]
  },
  {
    'type': 'radio',
    'name': 'radio_name_here',
    'data': [
      {
        'label': '',
        'value': '',
        'default': false
      }
    ]
  },
  {
    'type': 'boolean',
    'name': 'boolean_name_here',
    'data': [
      {
        'value': true,
      },
    ]
  },
  {
    'type': 'dropdown',
    'name': 'dropdown_name_here',
    'data': [
      {
        'label': '',
        'value': '',
        'default': false
      }
    ]
  },
];

export const defaultState = {
  'name': '',
  'description': '',
  'instructions': '',
  'type': '',
  'typeSettings': {
    'eventOptions': []
  },
  'active': '',
  'inputMappingType': '',
  'outputMappingType': '',
  'inputs': [],
  'outputs': [],
};

export const mappingTypeOptions = [
  {'type': 'global', 'label': 'Globally Defined'},
  {'type': 'instance', 'label': 'Instance Defined'},
  // {'type': 'mapping', 'label': 'Mapping Based (Dynamic)'},
];