import React from 'react';

import * as types from '../../constants/modalTypes';
import ModalWrapper from '../ModalWrapper';
import AddFunds from './AddFunds';
import CancelItems from './CancelItems';
import DeleteProject from './DeleteProject';
import ExportChainBatch from './ChainExports/ExportChainBatch';
import ReleaseItems from './ReleaseItems';
import ExportWorkflowBatch from './ExportWorkflowBatch';
import ResetAPIPassword from './ResetAPIPassword';
import ReleaseNotification from './ReleaseNotes/ReleaseNotification';
import PauseItems from './PauseItems';
import ArchiveUnarchiveChain from './ArchiveUnarchiveChain';
import ArchiveWorkflow from './ArchiveWorkflow';
import BatchNotifications from './BatchNotifications';

const createModal = (title, ModalContent) => (
  <ModalWrapper title={title} >
    <ModalContent/>
  </ModalWrapper>
);

export default {
  [types.ADD_FUNDS]: createModal('Add Funds', AddFunds),
  [types.CANCEL_ITEMS]: createModal('Cancel Items', CancelItems),
  [types.DELETE_PROJECT]: createModal('Delete Project', DeleteProject),
  [types.EXPORT_CHAIN_BATCH]: createModal('Workflow Chain Export Settings', ExportChainBatch),
  [types.RELEASE_ITEMS]: createModal('Release to Contributors', ReleaseItems),
  [types.EXPORT_WORKFLOW_BATCH]: createModal('Export Workflow Batch', ExportWorkflowBatch),
  [types.RESET_API_PASSWORD]: createModal('Reset API Password', ResetAPIPassword),
  [types.RELEASE_NOTIFICATION_MODAL]: createModal('', ReleaseNotification),
  [types.PAUSE_ITEMS]: createModal('Pause Items', PauseItems),
  [types.RESUME_ITEMS]: createModal('Resume Items', PauseItems),
  [types.UNARCHIVE_WORKFLOW]: createModal('Unarchive Workflow', ArchiveWorkflow),
  [types.ARCHIVE_CHAIN]: createModal('Archive Workflow Chain', ArchiveUnarchiveChain),
  [types.UNARCHIVE_CHAIN]: createModal('Unarchive Workflow Chain', ArchiveUnarchiveChain),
  [types.BATCH_NOTIFICATIONS]: createModal('Batch Notifications', BatchNotifications),
  [types.ARCHIVE_WORKFLOW]: createModal('Archive Workflow', ArchiveWorkflow),
};
