import React from 'react';
import PropTypes from 'prop-types';

import Select from './Select';
import { Label } from './type';

/**
 * Puts a label above a Select Component
 */
const SelectLabel = (props) => (
  <div style={styles} className="t-selectLabel">
    <Label color="light">{props.label}</Label>
    <Select {...props} />
  </div>
);

/* Styles */
const styles = {
  marginBottom: 16,
};

/* PropTypes */
SelectLabel.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array, // See Select
  searchable: PropTypes.bool,
  handleSelect: PropTypes.func,
};

export default SelectLabel;
