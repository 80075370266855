export default function addInputsAndOutputsToImportNode(importNode, destinationNodeSchema) {
  destinationNodeSchema.forEach(schemaItem => {
    if (!schemaItem.isOutput &&
      !importNode.operation.startup.inputs.some(e => e.name === schemaItem.name) &&
      !importNode.operation.startup.outputs.some(e => e.name === schemaItem.name)) {
      importNode.operation.startup.inputs.push(schemaItem);
      let schemaItemOutput = {...schemaItem};
      schemaItemOutput.isOutput = true;
      importNode.operation.startup.outputs.push(schemaItemOutput);
    }
  });
}