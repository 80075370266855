import React from 'react';
import PropTypes from 'prop-types';

import Input from './Input';
import { Label } from './type';

/**
 * InputLabel
 * Input component with label over top
 */
const InputLabel = (props) => (
  <div style={styles}>
    <Label color="light">{props.label}</Label>
    <Input {...props} />
  </div>
);

/* Styles */
const styles = {
  marginBottom: 16,
};

/* PropTypes */
InputLabel.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default InputLabel;
