import React, { Component } from 'react';
import PropTypes from 'prop-types';

import createMappingName from './_createMappingName';
import colors from '../../styles/colors';
import InlineList from '../../components/InlineList';
import InlineSelect from '../../components/InlineSelect';
import Input from '../../components/Input';
import { Label, Caption } from '../../components/type';
import RuleSet from '../../containers/RuleSet';
import RuleSetC from '../../containers/RuleSet/RuleSet';

class Rules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: !!props.ruleSet.getRuleSets().length,
    };
    this.handleRadioButtons = this.handleRadioButtons.bind(this);
  }

  componentDidMount() {
    if (!this.props.canBeDefault && !this.props.ruleSet.getRuleSets().length) {
      this.props.ruleSet.addRuleSet({ rules: [{}] });
    }
  }

  handleRadioButtons(e) {
    const selectedOption = e === 'yes';
    if (this.state.selectedOption !== selectedOption) {
      this.setState({ selectedOption });
      if (e === 'yes') {
        this.props.ruleSet.addRuleSet({ rules: [{}] });
      } else {
        this.props.ruleSet.reset();
      }
    }
  }

  getStartupInputs(destinationNode, sourceNode) {
    const destNodeSchema = destinationNode.hasOwnProperty('pluginId') ?
      destinationNode.inputs :
      (!!destinationNode.operation && destinationNode.operation.plugin) ?
        destinationNode.operation.plugin.inputs :
      destinationNode.schema;
    return destNodeSchema.filter(fi => !fi.isOutput).map(item => ({
      label: item.name,
      value: createMappingName(sourceNode.id, item)
    }));
  }

  getSchemaItems(sourceNode, sourceWorkflow) {
    /* If Node is a plugin node concat inputs and outputs to create mapping */
    if(sourceNode && sourceNode.operation.plugin) {
      const pluginNodeSchema = [...sourceNode.operation.plugin.inputs].concat([...sourceNode.operation.plugin.outputs]);
      return pluginNodeSchema.map(item => ({
        label: item.name,
        value: createMappingName(sourceNode.id, item)
      }));
    }
    /* Else use workflow schema if it's a workflow type node */
    return sourceWorkflow.schema.map(item => ({
      label: item.name,
      value: createMappingName(sourceNode.id, item)
    }));
  }

  render() {
    const {
      canBeDefault,
      ruleSet,
      sourceNode,
      sourceWorkflow,
      destinationWorkflow,
      isLocked,
    } = this.props;
    const ruleSets = ruleSet.getRuleSets();
    const applyRules = this.state.selectedOption || !canBeDefault;
    const schemaIOs = sourceNode.operation.startup ? this.getStartupInputs(destinationWorkflow, sourceNode) : this.getSchemaItems(sourceNode, sourceWorkflow);

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={styles.connectionOverview}>
          <Label color="light" uppercase={true}>Connect</Label>
          <div style={styles.nodeName}>
            <Label>{sourceNode.name}</Label>
          </div>
          <Label color="light" uppercase={true}>to</Label>
          <div style={styles.nodeName}>
            <Label>{destinationWorkflow.name}</Label>
          </div>
        </div>

        {/* Toggle default connection state */}
        <div>
          <Caption>Would you like to apply rule(s) to this connection?</Caption>
          <InlineList>
            <div>
              <div style={styles.radioBtn}>
                <Input
                  checked={applyRules}
                  type="radio"
                  value="yes"
                  disabled={isLocked}
                  handleChange={this.handleRadioButtons}/>
              </div>
            </div>
            <div>
              <div style={{ marginRight: 12 }}>
                <Caption>Yes</Caption>
              </div>
            </div>
            <div>
              <div style={styles.radioBtn}>
                <Input
                  checked={!applyRules}
                  disabled={!canBeDefault || isLocked}
                  type="radio"
                  value="no"
                  handleChange={this.handleRadioButtons}/>
              </div>
            </div>
            <div>
              <div style={{ marginRight: 4 }}>
                <Caption>No</Caption>
              </div>
            </div>
            <Caption italic>(make this the default connection)</Caption>
          </InlineList>
        </div>

        {!canBeDefault &&
        <Label>
          Another connection to {sourceNode.name} already has no rules defined.
          If you'd like this connection to be the default, please add a rule to the other
          connection.
        </Label>
        }

        <div>

          {/* Display rule sets */}
          {ruleSets.map((rs, i) => (
            <div key={i}>
              <RuleSet
                index={i}
                deletable={canBeDefault || ruleSets.length > 1}
                ruleSet={rs}
                ruleLHSFields={schemaIOs}
                isLocked={isLocked}
              />

              {/* Show "And/Or" select between rule sets */}
              {(ruleSets.length > 1 && i < ruleSets.length - 1) &&
              <div style={styles.operation}>
                <InlineSelect
                  disabled={i > 0 || isLocked}
                  showDefault={false}
                  value={ruleSet.getOperation()}
                  options={[
                    { label: 'And', value: 'AND' },
                    { label: 'Or', value: 'OR' },
                  ]}
                  handleSelect={operation => ruleSet.setOperation(operation)}
                />
              </div>
              }
            </div>
          ))}

        </div>
      </div>
    );
  }
}

const styles = {
  nodeName: {
    padding: '4px 8px',
    marginLeft: 8,
    marginRight: 8,
    background: colors.divider,
    borderRadius: 4,
  },
  operation: {
    width: 89,
  },
  divider: {
    position: 'relative',
    textAlign: 'center',
  },
  dividerOperation: {
    background: 'white',
    display: 'inline-block',
  },
  connectionOverview: {
    display: 'flex',
    alignItems: 'center',
    borderTop: `1px solid ${colors.divider}`,
    borderBottom: `1px solid ${colors.divider}`,
    padding: '12px 0',
    margin: '28px 0 16px',
  },
  radioBtn: {
    marginRight: 4,
    width: 16,
    position: 'relative',
    top: -2,
  },
};

Rules.propTypes = {
  sourceNode: PropTypes.object,
  destinationWorkflow: PropTypes.object,
  ruleSets: PropTypes.arrayOf(PropTypes.instanceOf(RuleSetC)),
};

export default Rules;
