import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Label, Caption } from '../../components/type';
import Input from '../../components/Input';
import InlineList from '../../components/InlineList';
import Panel from '../../containers/Panel';
import IconButton from '../../components/IconButton';

class IOSettings extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { mappingTypeOptions, inputMappingType, options, styles, updateChainPlugin, outputMappingType, type, readOnly, chainPluginData, instanceSettings, blankInputOrOutput } = this.props;
    const mappingType = type === 'inputs' ? inputMappingType : outputMappingType;
    const pluginId = chainPluginData.id;

    return (
      <div style={{ marginBottom: type === 'inputs' ? 30 : 0 }}>
        <Panel title={`${type === 'inputs' ? 'Input' : 'Output'} Settings`} color="light" small={true}>
          <div style={{ display: 'flex' }}>
            <div style={{padding: 16, flex: 6}}>
              {!instanceSettings &&
                <div style={styles.formField}>
                  <Label color="light">How will {type} be defined?</Label>
                  <div style={{ display: 'inline-block' }}>
                    {mappingTypeOptions.map((item, idx) => {
                      return (
                        <label key={idx} style={{ flex: 1 }}>
                          <div style={ styles.radioBtn }>
                            <Input
                              disabled={readOnly}
                              checked={mappingType === item.type}
                              type="radio"
                              value={item.type}
                              handleChange={val => !readOnly ?
                                updateChainPlugin(type === 'inputs' ? 'inputMappingType' : 'outputMappingType', val) :
                                () => {}}/>
                          </div>
                          <div style={styles.labels}>
                            <Caption>{item.label}</Caption>
                          </div>
                        </label>
                      );
                    })}
                  </div>
                </div>
              }
              { (
                  (chainPluginData.inputMappingType === 'global' && type === 'inputs') ||
                  (chainPluginData.outputMappingType === 'global' && type === 'outputs') ||
                  (instanceSettings && chainPluginData.outputMappingType === 'instance' && type === 'outputs') ||
                  (instanceSettings && chainPluginData.inputMappingType === 'instance' && type === 'inputs')
                ) &&
              <div>
                <InlineList>
                  <div style={{ flex: readOnly ? 4 : 1 }}>
                    <div style={styles.formField}>
                      <Label color="light">{type === 'inputs' ? 'Input' : 'Output'} Names</Label>
                    </div>
                  </div>
                  <div style={{ flex: 1, paddingLeft: 30 }}>
                    <div style={styles.formField}>
                      <Label color="light">Required</Label>
                    </div>
                  </div>
                </InlineList>
                {options.map((item, i) => {
                  return (
                    <div key={i} style={{ position: 'relative' }}>
                      <InlineList>
                        <div style={{ flex: readOnly ? 4 : 1 }}>
                          <div style={styles.formField}>
                            <Input
                              disabled={readOnly}
                              value={item.name}
                              label={item.name}
                              handleChange={val => !readOnly ?
                                updateChainPlugin(type, ['name', val], i, instanceSettings && pluginId) :
                                () => {}}
                              unallowedCharacters={[32]}
                              invalid={blankInputOrOutput && !item.name}
                            />
                          </div>
                        </div>
                        <div style={{ flex: 1, paddingLeft: 30 }}>
                          <div style={styles.formField}>
                            <div style={{ width: 15, marginLeft: 22 }}>
                              <Input
                                type="checkbox"
                                disabled={readOnly}
                                checked={item.required}
                                label={item.required}
                                value={item.required}
                                handleChange={() => !readOnly ?
                                  updateChainPlugin(type, ['required', !item.required], i, instanceSettings && pluginId) :
                                  () => {}}
                              />
                            </div>
                          </div>
                        </div>
                      </InlineList>
                      {!readOnly &&
                        <div style={{ position: 'absolute', right: 0, top: 15 }}>
                          <IconButton
                            icon="minus-square"
                            label="Add"
                            handleClick={() => {
                              updateChainPlugin(type, 'removeIO', i, instanceSettings && pluginId);
                            }}>
                            <span style={{ textDecoration: 'underline !important' }}>Remove</span>
                          </IconButton>
                        </div>
                      }
                    </div>
                  );
                })}
                {!readOnly &&
                  <div>
                    <IconButton
                      icon="plus-square"
                      label={type === 'inputs' ? 'Add Input' : 'Add Output'}
                      handleClick={() => updateChainPlugin(type, {'name':'', 'required':true, 'isOutput': type !== 'inputs'}, null, instanceSettings && pluginId)}>
                      <span style={{ textDecoration: 'underline !important' }}>Add {type === 'inputs' ? 'Input' : 'Output'}</span>
                    </IconButton>
                  </div>
                }
              </div>
              }
            </div>
            {(readOnly && instanceSettings)  &&
              <div style={{padding: '16px 30px 16px', flex: 3 }}>
                <Label
                  italic={true}
                  color="dark"
                  fontWeight={700}>
                    *These {type === 'inputs' ? 'inputs' : 'outputs'} are Globally Defined and must
                  be edited from the Plugin Library page.
                </Label>
              </div>
            }
          </div>
        </Panel>
      </div>
    );
  }
}

/* PropTypes */
IOSettings.propTypes = {
  mappingTypeOptions: PropTypes.array,
  inputMappingType: PropTypes.string,
  outputMappingType: PropTypes.string,
  options: PropTypes.array,
  styles: PropTypes.object,
  updateChainPlugin: PropTypes.func,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  chainPluginData: PropTypes.object,
  instanceSettings: PropTypes.bool,
  blankInputOrOutput: PropTypes.bool,
};

export default IOSettings;

