import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { parse } from 'qs';

import HelpPanel from '../../containers/HelpPanel';
import Wrapper from '../../containers/Wrapper';
import LazyTabSection from '../../containers/LazyTabSection';
import BreadCrumb from '../../containers/BreadCrumb';
import LoadState from '../../containers/LoadState';
import InlineEdit from '../../components/InlineEdit';
import HamburgerMenu from '../../components/HamburgerMenu';

import { fetchChainBatches, fetchChain, updateChain, validateChainName } from '../../actions/chains';
import { fetchProjectWorkflowsSchema } from '../../actions/workflows';
import ChainMonitor from '../ChainMonitor';
import ChainEditor from '../ChainEditor';
import { ARCHIVE_CHAIN } from '../../constants/modalTypes';
import { toggleModal } from '../../actions/app';

const pageDescription = `On this page, you can manage all aspects of your workflow chain. Build, edit, 
and launch your chain. Upload and download work from the batches section below. Monitor the performance 
and status of your workflow chain items on the Monitor Activity tab.`;

const helpPanelLinks = [
  {
    label: 'Workflow Chains: The Basics',
    href: 'https://onespace.helpdocs.com/setting-up-workflow-chains/workflow-chains-the-basics'
  },
  {
    label: 'Chaining Workflows: How It Works',
    href: 'https://onespace.helpdocs.com/setting-up-workflow-chains/chaining-workflows-how-it-works'
  },
  {
    label: 'Launching a Workflow Chain',
    href: 'https://onespace.helpdocs.com/setting-up-workflow-chains/launching-a-workflow-chain'
  },
];

/**
 * Workflow Chain Manager
 * Edit, save, launch, and upload batches to a chain
 * Monitor step by step chain activity
 */

// Attach $ to window for JSPlumb to work.
if ( !window.$ ) {
  window.$ = require( 'jquery' );
}

const chainingTabs = [ 'editor', 'monitor' ];

class WorkflowChaining extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      activeTab: 0,
      tabContent: null,
      editingChainName: false,
      updatingStats: false,
    };

    this.tabContent = this.tabContent.bind( this );
    this.changeTab = this.changeTab.bind( this );
    this.handleCheckingFilters = this.handleCheckingFilters.bind( this );
    this.updateName = this.updateName.bind( this );
    this.handleChainOptions = this.handleChainOptions.bind(this);
  }

  componentDidMount() {
    const { projectId, chainId, dispatch } = this.props;
    dispatch( fetchProjectWorkflowsSchema( projectId ) )
      .then( () => {
        dispatch( fetchChain( projectId, chainId ) );
        dispatch( fetchChainBatches( projectId, chainId ) );
      } )
      .then( () => {
        this.handleCheckingFilters();
      } );
  }

  componentDidUpdate({}, prevState) {
    const { location } = this.props;
    const locationQuery = parse(location.search, {ignoreQueryPrefix: true});
    if(
      locationQuery.activeTab &&
      chainingTabs.indexOf(locationQuery.activeTab) !== prevState.activeTab &&
      locationQuery.activeTab !== 'batches'
    ) {
      this.handleCheckingFilters();
    }
  }

  handleCheckingFilters() {
    const { location } = this.props;
    const newLocationQuery = parse(location.search, {ignoreQueryPrefix: true});
    if ( newLocationQuery.activeTab === 'batches' ) {
      return this.tabContent( true, chainingTabs.findIndex( tab => tab === 'editor' ) );
    }
    const locationQuery = newLocationQuery.activeTab ? newLocationQuery.activeTab : 'editor';
    const activeTab = chainingTabs.findIndex( tab => tab === locationQuery );
    this.tabContent( false, activeTab );
  }

  //Using same change tab function since we will be splitting into more tabs there will be different params soon
  tabContent( loadToBatches = false, activeTab ) {
    const { chain, chainId, projectId, isLoadingWorkflows } = this.props;
    let tabContent;
    if ( activeTab === 0 ) {
      tabContent =
        <ChainEditor
          isLoadingWorkflows={isLoadingWorkflows}
          chain={!!chain && chain}
          loadToBatches={loadToBatches}
          chainId={chainId}
          projectId={projectId} />;
    } else if ( activeTab === 1 ) {
      tabContent =
        <ChainMonitor
          handleClickRelease={() => this.changeTab( 0, true )}
          handleClickUpload={() => this.changeTab( 0, true )}
          handleClickDownload={() => this.changeTab( 0, true )}
          handleReturnToEdit={() => this.changeTab( 0, false )}
          chainId={chainId}
          projectId={projectId} />;
    }
    this.setState( { tabContent, activeTab } );
  }

  // Setting up handle clicks to handle their own event although they perform the same action.
  // In the future they will be performing different actions.
  changeTab( activeTab, loadToBatches ) {
    const { location } = this.props;
    const history = this.props.history;
    history.push( {
      pathname: `${location.pathname}`,
      search: `?activeTab=${chainingTabs[ activeTab ]}`
    } );
    if ( chainingTabs[ activeTab ] === 'monitor' ) {
      this.setState( { updatingStats: true }, () => {
        this.tabContent( loadToBatches, activeTab );
      } );
    } else {
      this.tabContent( loadToBatches, activeTab );
    }
  }

  // Update the name of this chain
  updateName( name ) {
    this.setState( { isUpdatingName: true } );
    this.props.dispatch( validateChainName( this.props.chainId, name ) )
      .then( ( res ) => {
        if ( !res.success ) {
          this.setState( { isUpdatingName: false, updateNameError: res.message } );
          return false;
        }
        this.props.dispatch( updateChain( this.props.chain.id, { name } ) )
          .then( () => this.setState( { isUpdatingName: false, editingChainName: false } ) )
          .catch( err => {
            this.setState( {
              editingChainName: false,
              isUpdatingName: false,
              updateNameError: err.data.name.message || 'This name is already in use'
            } );
          } );
      } ).catch( ( err ) => {
      this.setState( { updateNameError: err } );
    } );
  }

  handleChainOptions(option) {
    const { chain } = this.props;
    switch(option) {
      case 'edit':
        return this.setState({ editingChainName: true });
      case 'archive':
        return this.props.dispatch(toggleModal(ARCHIVE_CHAIN, {
          chainId: chain.id,
          projectId: chain.projectId,
          archive: true,
          enableOverlayClick: true,
        } ) );
      default:
        break;
    }
  }

  render() {
    const { chain, projectId, fetchingChains, userRole } = this.props;
    const { editingChainName, updateNameError, isUpdatingName, updatingStats } = this.state;
    const isSupportRole = userRole === 'onespace-support';

    if ( fetchingChains && !updatingStats ) {
      return (<div style={{ marginTop: 50 }}><LoadState/></div>);
    }

    return (
      <DocumentTitle title="Manage Chain | OneSpace Project Center">
        <div>
          <HelpPanel
            pageTitle="Manage Chain"
            pageDescription={pageDescription}
            links={helpPanelLinks}
          />
          <Wrapper>
            {chain &&
            <div className="chains chain-editor">
              <BreadCrumb
                links={[
                  { href: '/projects', label: 'My Projects' },
                  {
                    href: `/chains?project=${projectId}`,
                    label: 'Workflow Chains'
                  },
                  { label: `${chain.name}` },
                ]}
              />
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} id='chainName'>
                <InlineEdit
                  hide={true}
                  label={chain.name ? chain.name : ''}
                  error={updateNameError}
                  isSaving={isUpdatingName}
                  isEditing={editingChainName}
                  handleSave={this.updateName}
                  handleClearError={() => this.setState( { updateNameError: null, editingChainName: false } )}
                />
                {( !editingChainName && (isSupportRole || !chain.isActive) && !chain.archived )  &&
                  <HamburgerMenu
                    options={[
                      { label: 'Edit Name', value: 'edit' },
                      { label: 'Archive', value: 'archive' }
                      ]}
                    handleSelect={option => this.handleChainOptions(option)}
                  />
                }
                {!editingChainName && (!isSupportRole && chain.isActive) && !chain.archived &&
                  <HamburgerMenu
                  options={[
                  { label: 'Archive', value: 'archive' }
                ]}
                  handleSelect={option => this.handleChainOptions(option)}
                  />
                }
              </div>
              <LazyTabSection
                handleTabClick={ ( tabLabel, tabIndex ) => this.changeTab( tabIndex, false ) }
                tabLabels={[ 'Edit Chain', 'Monitor Activity' ]}
                tabClasses={[
                  'edit-chain',
                  'monitor-activity',
                ]}
                activeTab={this.state.activeTab}
                tabContent={this.state.tabContent}
              />
            </div>
            }
          </Wrapper>
        </div>
      </DocumentTitle>
    );
  }
}

function select( state, rr ) {
  const chainId = rr.match.params.chain_id;
  const chain = state.chains.items[ chainId ];
  return {
    chain,
    chainId,
    userRole: state.app.role,
    allChains: state.chains.items,
    projectId: rr.match.params.project_id,
    fetchingChains: state.chains.isFetching,
    isLoadingWorkflows: state.workflows && state.workflows.isFetching,
  };
}

export default connect( select )( WorkflowChaining );
