import React, { Component } from 'react';
import { connect } from 'react-redux';

import { DELETE_PROJECT } from '../../constants/modalTypes';
import { toggleModal } from '../../actions/app';
import Panel from '../../containers/Panel';
import { Large, Caption } from '../../components/type';
import EditProject from './EditProject';
import ChainsAndWorkflows from './ChainsAndWorkflows';
import { updateProject } from '../../actions/projects';


class ProjectDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      saving: false,
      open: false,
      note: ''
    };
    this.handleClickEdit = this.handleClickEdit.bind(this);
    this.handleClickDelete = this.handleClickDelete.bind(this);
    this.handleCancelEdit = this.handleCancelEdit.bind(this);
    this.getProjectDetails = this.getProjectDetails.bind(this);
  }

  handleClickEdit() {
    this.setState({ editing: true });
  }

  handleCancelEdit() {
    this.setState({ editing: false, note: '' });
  }

  handleClickDelete() {
    const meta = { projectId: this.props.project.id.toString() };
    this.props.dispatch(toggleModal(DELETE_PROJECT, meta));
  }

  getProjectDetails() {
    return [
      { label: 'Project Admin', value: this.getProjectAdminName() },
      { label: 'Project Description', value: this.props.project.description },
    ];
  }

  getProjectAdminName() {
    const admin = this.props.project.admin;
    return admin ? admin.name : 'Unknown';
  }

  getActions() {
    const actions = [];
    if (this.props.userCanEdit) {
      actions.push({
        icon: 'pencil-square-o',
        handleClick: this.handleClickEdit,
        label: 'Edit Project'
      });
    }
    if (this.props.userCanEdit && this.props.project.status === 'draft') {
      actions.push({
        icon: 'trash',
        handleClick: this.handleClickDelete,
        label: 'Delete Project'
      });
    }
    return actions;
  }

  updateProject(id, update) {
    return (
      this.props.dispatch(updateProject(id, update))
        .then((err) => {this.handleErrorMessage(err);})
    );
  }

  handleErrorMessage(error) {
    if(!!error.name) {
      this.setState({note: (error.name).toString()});
    } else {
      this.setState({note: ''});
    }
  }

  render() {
    const { project, companyAdmins } = this.props;
    const { open } = this.state;
    return (
      <div>
        {!this.state.editing &&
        <Panel
          title={project.name}
          actions={this.getActions()}
          simple={true}>
          <div style={styles.panel}>
            <Large color="dark" bottom={15}>{project.description}</Large>
            <Caption color="light" inline={true}>
              Project Admin:&nbsp;
              <Caption color="darker" inline={true}>{project.admin.name}</Caption>
            </Caption>

            <div style={styles.toggle} onClick={() => this.setState({ open: !open })}>
              <Caption isLink={true}>Details&nbsp;
                <span style={open ? styles.extraToggleOpen : styles.extraToggle}>
                  <i className="fa fa-chevron-down"/>
                </span>
              </Caption>
            </div>
            {open &&
            <div style={open ? styles.extraContentOpen : styles.extraContent}>
              <ChainsAndWorkflows projectId={project.id}/>
            </div>
            }
          </div>
        </Panel>
        }
        {this.state.editing &&
        <EditProject
          project={project}
          companyAdmins={companyAdmins}
          handleSave={update => this.updateProject(project.id, update)}
          handleCancel={this.handleCancelEdit}
          note={this.state.note}
        />
        }
      </div>
    );
  }
}

const styles = {
  panel: {
    padding: 15,
  },
  toggle: {
    height: 40,
    marginTop: 24,
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #ebebeb',
    borderBottom: '1px solid #ebebeb',
    cursor: 'pointer',
  },

  extraContent: {
    maxHeight: 0,
    overflow: 'hidden',
    transition: 'min-height 0.32s ease',
    paddingTop: 16,
  },
  extraContentOpen: {
    maxHeight: 2800,
    overflow: 'hidden',
    transition: 'min-height 0.32s ease',
    paddingTop: 16,
  },
  extraToggle: {
    transform: 'rotate(0)',
    transition: 'all 0.2s ease',
    marginLeft: 6,
    display: 'inline-block',
  },
  extraToggleOpen: {
    transform: 'rotate(180deg)',
    transition: 'all 0.2s ease',
    marginLeft: 6,
    display: 'inline-block',
  },
};

const select = () => ({});
export default connect(select)(ProjectDetails);
