import React from 'react';
import PropTypes from 'prop-types';

/**
 * InlineList
 * Used to display items in a row with equal spacing around each item.
 * The top level div "consumes" the outer spacing
 */
const InlineList = ({ spacing, justify, flexWrap, children }) => {
  const halfSpacing = spacing / 2;
  const childrenWithSpacing = React.Children.map(children, (child) => {
    if (!child) {
      return null;
    }
    const sideSpacing = { marginLeft: halfSpacing, marginRight: halfSpacing };
    if (!child.props.style) {
      // Child is another component, can't pass in styles
      return <div style={sideSpacing}>{child}</div>;
    }
    const style = Object.assign({}, child.props.style, sideSpacing);
    return React.cloneElement(child, { style });
  });
  return (
    <div
      style={{
        ...styles,
        marginLeft: -halfSpacing,
        marginRight: -halfSpacing,
        flexWrap: flexWrap ? 'wrap' : 'nowrap',
        justifyContent: justify,
      }}>
      {childrenWithSpacing}
    </div>
  );
};

/* Styles */
const styles = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
};

/* PropTypes */
InlineList.propTypes = {
  spacing: PropTypes.number,
  // If true, items will be justified flex-end
  justify: PropTypes.oneOf(['flex-end', 'flex-start', 'center', 'space-between']),
  // If true, items will wrap
  flexWrap: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
InlineList.defaultProps = {
  spacing: 0,
  justify: 'flex-start',
};

export default InlineList;
