import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { removeUnusedIOsFromImportNode } from './_miscHelpers';

import isChildFetching from '../../utils/isChildFetching';
import objectToArray from '../../utils/_objectToArray';
import stateHelpers from '../../utils/stateHelpers';
import {
  updateChain,
  updateNode,
  addNodeToChain,
  removeNodesFromChain,
  launchChain,
  fetchChainBatches,
  importChainBatch,
  isChainActive,
} from '../../actions/chains';
import { fetchProjectChainsAndWorkflows, logErrorMessage } from '../../actions/projects';
import { launchWorkflow } from '../../actions/workflows';
import { toggleModal } from '../../actions/app';
import { BATCH_NOTIFICATIONS } from '../../constants/modalTypes';

import colors from '../../styles/colors';
import { Label } from '../../components/type';
import InlineList from '../../components/InlineList';
import IconButton from '../../components/IconButton';
import InlineSaveState from '../../components/InlineSaveState';
import LoadState from '../../containers/LoadState';
import Note from '../../containers/Note';
import InlineButton from '../../components/InlineButton';

import ChainBatchManagement from './ChainBatchManagement';
import AddWorkflow from './AddWorkflow';
import Chain from './Chain';

import ChainPlugins from './SupportRoleSettings/TabContent/ChainPlugins';
import Processors from './SupportRoleSettings/TabContent/Processors';
import Tabs, {Pane} from '../../components/Tabs';
import { UNARCHIVE_CHAIN } from '../../constants/modalTypes';
import ArchivedChain from './ArchivedChain';

const failedBatchUpload = (statusText) => `${statusText}. Your file upload failed. Please try to 
upload the file again. If the issue persists, contact <a target="_blank" href="mailto:support@onespace.com">client support</a>.`;

const isChainLaunchable = chain => {
  return (!(chain.launched || !chain.nodes.length || chain.validationResult.length));
};

const sortByCreatedAt = ( a, b ) => new Date( b.created_at ) - new Date( a.created_at );
const doesBatchHaveStagedItems = b => b.items && b.items.staged;
const defaultProcessorsData = { importFile: null, pre: null, post: null };

const acceptedFileTypes =
  '.csv, ' +
  'text/csv, ' +
  'text/x-csv,' +
  'application/vnd.ms-excel, ' +
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, ' +
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document, ' +
  'application/json, ' +
  'application/xml, ' +
  'text/html, ' +
  'application/msword, ' +
  'application/zip, ' +
  'application/octet-stream, ' +
  'application/x-zip-compressed, ' +
  'application/vnd.oasis.opendocument.text, ' +
  'application/vnd.oasis.opendocument.spreadsheet, ' +
  'application/pdf';

const defaultFileTypes = '.csv';

/**
 * ChainEditor
 * Edit, save, launch, and upload batches to a chain
 *
 */

class ChainEditor extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      name: '',
      selectedSourceId: false,
      notes: [],
      uploadErrors: [],
      unavailableNodes: [],
      processors: !!(props.chain && props.chain.processors) ? props.chain.processors : defaultProcessorsData,
      importFileError: '',
      collapsed: true,
      currentBatchesPage: 1,
      preError: '',
      postError: '',
      disableSave: false,
      loadToBatches: false,
    };

    this.updateNodes = this.updateNodes.bind( this );
    this.handleAddNode = this.handleAddNode.bind( this );
    this.handleUpdateNode = this.handleUpdateNode.bind( this );
    this.launchChain = this.launchChain.bind( this );
    this.launchWorkflowsInChain = this.launchWorkflowsInChain.bind( this );
    this.validateChain = this.validateChain.bind( this );
    this.canSaveChain = this.canSaveChain.bind( this );
    this.getLockedReason = this.getLockedReason.bind( this );
    this.getDisabledLabel = this.getDisabledLabel.bind( this );
    this.handleRemovingImportInputsAndOutputs = this.handleRemovingImportInputsAndOutputs.bind( this );
    this.unarchiveChain = this.unarchiveChain.bind( this );
    this.thereArePluginsWithoutPluginId = this.thereArePluginsWithoutPluginId.bind( this );
  }

  componentDidMount() {
    // Fetch the chain and workflows from the parent project

    if ( this.props.chain && !this.props.isLoadingWorkflows ) {
      this.setState( { chainDataLoaded: true } );
    }

    this.validateChain( this.props.chain );
    this.handleErrorMessages( this.props.chain );
  }

  componentWillUpdate( nextProps ) {
    if ( this.isFirstTimeReady( nextProps ) ) {
      this.setState( { chainDataLoaded: true } );
    }
    if(nextProps.loadToBatches && (this.state.loadToBatches !== nextProps.loadToBatches)) {
      const element = document.getElementById('batchesAnchor');
      if(element) {
        this.setState({loadToBatches: nextProps.loadToBatches}, () => element.scrollIntoView());
      }
    }
  }

  componentDidUpdate( prevProps ) {
    if ( prevProps.chain && prevProps.chain.id ) {
      const nowActive = !prevProps.chain.isActive && this.props.chain.isActive;
      const nowInactive = prevProps.chain.isActive && !this.props.chain.isActive;
      const previouslyStaged = prevProps.batches.find( doesBatchHaveStagedItems );
      const nowStaged = this.props.batches.find( doesBatchHaveStagedItems );
      if ( this.chain && this.chain.chainBuilder ) {
        if ( nowActive || (!previouslyStaged && nowStaged) && this.props.userRole !== 'onespace-support' ) {
          this.chain.chainBuilder.setChainState( false );
        }
        if ( nowInactive || (previouslyStaged && !nowStaged) || this.props.userRole === 'onespace-support' ) {
          this.chain.chainBuilder.setChainState( true );
        }
      }
    }
  }

  canSaveChain() {
    return this.state.isUpdating || !this.state.isChainSavable || !!this.state.launchStatus;
  }

  getLockedReason( editingDisabled ) {
    if ( editingDisabled ) {
      return this.props.userRole === 'onespace-support' ? 
      `There is active work in this chain. You can make edits to your chain, however, proceed with 
      caution. Removing nodes from the chain will result in blank data in your output file.`
      :
      `You may not make changes to a chain with active work.`;
    }
    if ( this.props.userRole !== 'admin' && this.props.chain.createdBy !== this.props.user.id && this.props.userRole !== 'onespace-support' ) {
      return 'Only the project and full admins may make changes to a chain.';
    }
  }

  getDisabledLabel( canEdit, isUpdating, archived ) {
    if( canEdit ) {
      if( !this.canSaveChain() || isUpdating ) {
        return 'Please save your chain before uploading a batch';
      } else {
        return 'This chain is invalid. Please correct the issues before uploading batches';
      }
    } else {
      if ( !!archived ) {
        return 'Your chain is currently archived';
      } else if( this.props.userRole === 'onespace-support' ) {
        return 'Save changes to your chain before you can upload work.';
      } else {
        return 'Only company admins can edit this chain.';
      }
    }
  }

  handleUpdateProcessors(val, property) {
    if (property === 'importFile') {
      if(!!val.length) {
        if(val.substring(0, 7) === 'http://' || val.substring(0, 8) === 'https://' || val.length === 0) {
          this.setState({
            importFileError: '',
            disableSave: false,
          } );
        } else {
          this.setState( {
            importFileError: 'Url must start with http:// or https://',
            disableSave: true,
          } );
        }
      } else {
        this.setState( {
          importFileError: '',
          disableSave: false,
        } );
      }
    } else {
      const validationItems =
        (!val.includes( ':' )) ||
        (val.match( /:/gi ) && val.match( /:/gi ).length > 1) ||
        (val.match( /:/gi ) && !val.split( ':' )[ 1 ]) ||
        (val.charAt( 0 ) === ':');

      const errorMsg = 'Invalid lambda function name';

      if ( property === 'pre' ) {
        if ( !!val.length ) {
          if ( !validationItems ) {
            this.setState( { preError: '', disableSave: false } );
          } else {
            this.setState( { preError: errorMsg, disableSave: true } );
          }
        } else {
          this.setState( { preError: '', disableSave: false } );
        }

      } else {
        if ( !!val.length ) {
          if ( !validationItems ) {
            this.setState( { postError: '', disableSave: false } );
          } else {
            this.setState( { postError: errorMsg, disableSave: true } );
          }
        } else {
          this.setState( { postError: '', disableSave: false } );
        }
      }
    }

    let newProcessor = { ...this.state.processors };
    newProcessor[ property ] = val;
    this.setState( { processors: newProcessor } );
  }

  handleRemovingImportInputsAndOutputs() {
    const importNode = this.props.chain.nodes.find( n => !!n.operation.startup );
    // Once nodes removed, handle removing inputs/outputs from importNode
    removeUnusedIOsFromImportNode( this.props.chain.nodes, importNode );
  }

  unarchiveChain(chain) {
    return this.props.dispatch(toggleModal(UNARCHIVE_CHAIN, {
      chainId: chain.id,
      projectId: chain.projectId,
      archive: false,
      enableOverlayClick: true,
    } ) );
  }

  batchOptions( option ) {
    const { dispatch, chain } = this.props;
    switch ( option ) {
      case 'csv': {
        window.location.href = chain.processors.importFile ?
          chain.processors.importFile :
          `/api/projects/${chain.projectId}/chains/${chain.id}/template`;
        break;
      }
      case 'advancedExport': {
        return window.location.href = `/advanced-export?chainId=${chain.id}&projectId=${chain.projectId}`;
      }
      case 'batchNote': {
        dispatch( toggleModal( BATCH_NOTIFICATIONS, {
          chainId: chain.id,
          enableOverlayClick: true
        } ) );
        break;
      }
      default:
        break;
    }
  }

  render() {
    const { chain, batches, userRole, user, isLoadingBatches, workflows, batchNotification, projectId, chainId } = this.props;
    const { isUpdating, selectedSourceId, launchStatus } = this.state;
    const admin = (userRole === 'admin' || userRole === 'onespace-support');
    const supportUser = userRole === 'onespace-support';
    const owner = chain.createdBy === user.id;
    const batchesOpen = batches.some(
      batch => batch.state === 'open' || batch.state === 'staged' || batch.state === 'paused'
    );
    const active = chain.isActive || batchesOpen;
    const archived  =  chain.archived;

    const canEdit = ((admin || owner) && (!active && !archived));

    const needsLaunching = chain.nodes ? chain.nodes.find( n => {
        if ( n.operation.swfWorkflow ) {
          return n.operation.swfWorkflow.workflowId === null;
        }
      } ) : [];

    // Support Role Settings tabs
    const processorLabel = 'Pre & Post Processors';
    const processorContent =
      <Processors
        data={this.state.processors}
        handleUpdateProcessors={(val, field) => this.handleUpdateProcessors(val, field)}
        disabled={!canEdit && !supportUser}
        importFileError={this.state.importFileError}
        preError={this.state.preError}
        postError={this.state.postError}
      />;

    const pluginsLabel = 'Plugins';
    const pluginsContent =
      <ChainPlugins
        chainId={chain.id}
        active={!canEdit && !supportUser}
        chainNodes={chain.nodes}
      />;

    const tabs = [{
      name: processorLabel,
      content: processorContent,
    }, {
      name: pluginsLabel,
      content: pluginsContent,
    }];

    return (
      <div>
        {!chain.id && <LoadState />}
        {chain.id &&
        <div className="chains chain-editor">
          {/* Chain Update Error */}
          {this.state.errorMsg &&
          <Alert bsStyle="danger">
            <strong>{this.state.errorMsg}</strong>
          </Alert>
          }

          {/* Main Chain Component */}
          {(this.state.chainDataLoaded && !archived) &&
          <Chain
            workflows={workflows}
            ref={chain => this.chain = chain}
            editable={canEdit || supportUser}
            lockedReason={this.getLockedReason( !canEdit )}
            handleReady={chainBuilder => this.chainBuilder = chainBuilder}
            chain={chain}
            handleClickNode={( nodeId ) => this.handleClickNode( nodeId )}
            handleClickAddNode={( sourceId, unavailableNodes ) => this.setState( {
              selectedSourceId: sourceId,
              unavailableNodes
            } )}
            handleRemovedNodes={( nodes ) => {
              const fulRemovedNodes = [];
              this.props.chain.nodes.map( node => {
                nodes.map( n => {
                  if ( n.id === node.id ) {
                    fulRemovedNodes.push( node );
                  }
                } );
              } );
              this.setState( { isChainSavable: true } );
              this.props.dispatch( removeNodesFromChain( this.props.chain.id, fulRemovedNodes ) );
              this.handleRemovingImportInputsAndOutputs( nodes );
            }}
            handleClickConnectionLabel={( selectedSourceId, selectedDestinationId, unavailableNodes ) => {
              this.setState( { selectedSourceId, selectedDestinationId, unavailableNodes } );
            }}
          />
          }

          {!!archived &&
          <ArchivedChain
            handleSelect={() => this.unarchiveChain( chain )}
          />
          }
          {/* Launch errors/success notes */}
          {!!this.state.notes &&
          <div style={{ marginTop: 16 }}>
            { this.state.notes.map( ( note, i ) => (
              <div key={i} style={{ marginBottom: 1 }}>
                <Note
                  type={note.type}
                  note={note.note}
                  handleClose={() => {
                    const notes = [ ...this.state.notes ];
                    notes.splice( i, 1 );
                    this.setState( { notes } );
                  }}
                />
              </div>
            ) )}
          </div>
          }

          {/* Save/Launch Buttons plus Legend */}
          <div style={styles.legendContainer}>
            {(canEdit || supportUser) &&
            <div style={styles.key}>
              <div style={styles.keyItem}>
                <div style={Object.assign( {}, styles.keyColor, { background: colors.positiveLight } )}/>
                <Label uppercase={true} color="light">Active</Label>
              </div>
              <div style={styles.keyItem}>
                <div style={Object.assign( {}, styles.keyColor, { background: colors.background } )}/>
                <Label uppercase={true} color="light">Inactive</Label>
              </div>
              <div style={styles.keyItem}>
                <div
                  style={Object.assign( {}, styles.keyColor, styles.keyInactive, { background: colors.background } )}/>
                <Label uppercase={true} color="light">Draft</Label>
              </div>
            </div>
            }
            <InlineList spacing={16} top={16} justify="flex-end">
              <InlineSaveState
                saving={isUpdating || !!launchStatus}
                savingLabel={launchStatus || 'Saving Chain'}
                savedLabel="Complete"
              />

              <InlineButton
                disabled={(!canEdit && !supportUser)|| isUpdating || !!this.state.importFileError || this.state.disableSave}
                handleClick={() => {
                  if(!this.thereArePluginsWithoutPluginId(chain)) {
                    return (chain.launched && needsLaunching) ? this.launchChain() : this.updateNodes();
                  }
                }}>
                Save Changes
              </InlineButton>
              <InlineButton
                disabled={!isChainLaunchable( chain ) || !!this.state.launchStatus}
                handleClick={this.launchChain}>
                {chain.launched ? 'Launched' : 'Launch Chain'}
              </InlineButton>
            </InlineList>
          </div>

          {(userRole === 'onespace-support' && this.state.chainDataLoaded && !isLoadingBatches) &&
          <div>
            <div style={styles.collapser}>
              <IconButton
                icon={this.state.collapsed ? 'chevron-down' : 'chevron-up'}
                iconPosition="right"
                underline={false}
                align="left"
                handleClick={() => {
                  this.setState({collapsed: !this.state.collapsed});
                }}>
                Custom Processor & Plugin Settings
              </IconButton>
            </div>
            <div style={{height: this.state.collapsed ? 0 : 'auto', overflow: 'hidden'}}>
              <div style={styles.processorTabs} ref="main">

                <Tabs
                  selected={0}
                  tabBgColor={colors.background}
                  activeTabColor={colors.light}
                  activeTabBgColor={'#fff'}
                  tabLabelsBgColor={'#fff'}
                  borderColor={colors.border}
                  tabBottomBorder={colors.border}
                  verticalPadding={10}
                  horizontalPadding={20}
                  bold={true}
                  horizontalSpacing={-1}
                >
                  {tabs.map((tab, i) =>
                    <Pane
                      key={i}
                      label={tab.name}
                      contentBorderColor={colors.border}
                      contentPadding={15}
                    >
                      {tab.content}
                    </Pane>)
                  }
                </Tabs>
              </div>
            </div>
          </div>
          }
        </div>
        }

        {/* Batch Management */}
        <a name="batchesAnchor" id="batchesAnchor"/>
        {chain.launched &&
        <ChainBatchManagement
          acceptedUploadFileTypes={
            (!!this.state.processors.pre && !!this.state.processors.pre.length) ? acceptedFileTypes : defaultFileTypes
          }
          uploadErrors={this.state.uploadErrors}
          chain={chain}
          batches={batches.sort( sortByCreatedAt )}
          getBatches={data => {
            this.setState( { currentBatchesPage: data.selected + 1 } );
            this.props.dispatch( fetchChainBatches( this.props.projectId, this.props.chainId, data.selected + 1 ) )
              .then( () => this.props.dispatch( isChainActive( this.props.chainId ) ) );
          }}
          pageCount={this.props.pageCount}
          currentPage={this.state.currentBatchesPage}
          loadingBatches={this.props.isLoadingBatches}
          handleUploadBatch={file => {
            this.setState( { notes: [] } );
            this.props.dispatch( importChainBatch( chain.projectId, chain.id, file ) ).then( () => {
              this.setState( {
                uploadErrors: [],
              } );
              this.props.dispatch( fetchProjectChainsAndWorkflows( chain.projectId, 'workflows' ) ).then( () => {
                this.handleErrorMessages( this.props.chain );
                this.validateChain( this.props.chain );
              } );
            } ).catch( ( xhr ) => {
              this.setState( {
                uploadErrors: [ {
                  type: 'error',
                  note: failedBatchUpload( xhr.statusText ),
                } ],
              } );
              this.props.dispatch( logErrorMessage( `/chains/${chain.id}/log/failedUpload`, xhr.responseText ) );
            } );
          }}
          projectId={this.props.projectId}
          disabled={!!chain.validationResult.length || !(admin || owner) || (!this.canSaveChain() || isUpdating) || archived}
          disabledLabel={this.getDisabledLabel( canEdit, isUpdating, archived)}
          batchNotification={batchNotification}
          handleOptionsSelect={option => this.batchOptions( option )}
        />
        }

        {/* Modal */}
        {selectedSourceId &&
        <AddWorkflow
          chainId={chainId}
          projectId={projectId}
          unavailableNodes={this.state.unavailableNodes}
          sourceNodeId={this.state.selectedSourceId}
          selectedDestinationId={this.state.selectedDestinationId}
          handleAddNode={this.handleAddNode}
          handleUpdateNode={this.handleUpdateNode}
          handleCancel={() => this.setState( {
            selectedSourceId: null,
            selectedDestinationId: null,
          } )}
        />
        }

      </div>
    );
  }

  handleClickNode( nodeId ) {
    const node = this.props.chain.nodes.find( node => node.id === nodeId );
    const workflowId = !node.operation.plugin && node.operation.swfWorkflow.entityId;
    if ( workflowId ) {
      this.openWorkflowInNewTab( workflowId );
    }
  }

  openWorkflowInNewTab( workflowId ) {
    let workflowUrl;
    const workflow = this.props.workflows[ workflowId ];

    if ( workflow.workflowId ) {
      workflowUrl = `/workflows/${workflowId}`;
    } else if ( workflow.steps > 0 ) {
      workflowUrl = `/workflows/${workflowId}/steps`;
    } else {
      workflowUrl = `/projects/${workflow.id_project}/workflows/${workflowId}`;
    }

    window.open( workflowUrl, '_blank' );
  }

  handleAddNode( node, routes ) {
    return this.addNodeToChain( node, routes );
  }

  addNodeToChain( node, routes ) {
    this.chainBuilder.addNode( node );
    !!node.sources.length && this.chainBuilder.connectNodes( node.sources[ 0 ], node.id );
    this.props.dispatch( addNodeToChain( this.props.chain.id, node, routes ) );

    // Ensure new nodes show correct state if workflow
    if ( node.operation.swfWorkflow ) {
      const workflowId = Object.keys( this.props.workflows ).find( id => this.props.workflows[ id ].id === node.operation.swfWorkflow.entityId );
      const workflow = this.props.workflows[ workflowId ];
      if ( workflow && workflow.state === 'draft' ) {
        $( `[data-jtk-node-id="${node.id}"]` ).addClass( 'draft' );
      }
    }
    if ( node.operation.plugin ) {
      $( `[data-jtk-node-id="${node.id}"]` ).addClass( 'type-plugin' );
    }

    this.setState( { selectedSourceId: null, isChainSavable: true } );
  }

  handleUpdateNode( route ) {
    const selectedNode = this.props.chain.nodes.find( n => n.id === this.state.selectedSourceId );
    /* Once valid and clicked 'connect' run to validate edges */
    this.chainBuilder.validateEdge( route.destinationNode );
    this.props.dispatch( updateNode( this.state.selectedSourceId, {
      routes: [ ...selectedNode.routes.filter( r => r.destinationNode !== route.destinationNode ), route ]
    } ) );
    this.setState( {
      selectedSourceId: null,
      selectedDestinationId: null,
      isChainSavable: true
    } );
  }

  // Update the chain with nodes in state
  // On completion show "Saved" for 1200ms
  updateNodes() {
    const nodePositions = this.chainBuilder.getNodePositions();
    this.setState( { isUpdating: true } );
    this.updateChain( { nodes: this.props.chain.nodes, nodePositions, processors: this.state.processors } )
      .then( () => this.setState( { isUpdating: false, hasUpdated: true }, () => setTimeout( () => {
        this.setState( { hasUpdated: false, isChainSavable: false } );
        let edges = this.getUpdatedEdges( this.props.chain.nodes );
        this.chainBuilder.revalidateEdges( edges, this.props.chain.validationResult );
        this.handleErrorMessages( this.props.chain );
      }, 1200 ) ) )
      .catch( () => this.setState( { isUpdating: false } ) );
  }

  thereArePluginsWithoutPluginId(chain) {
    // If chain has plugins, make sure they all contain pluginId, otherwise throw errors before updating
    if(chain.plugins.length) {
      const someMissingIds = chain.plugins.some(plugin => !plugin.pluginId);

      if(someMissingIds) {
        const newNotes = [...this.state.notes];
        newNotes.push({'type': 'error', note: 'Please make sure you save an instance of a plugin before you save a chain.' });
        this.setState({ notes: newNotes });
        return true;
      }

      return false;
    }
  }

  // Update the chain
  updateChain( update = {} ) {
    return this.props.dispatch( updateChain( this.props.chain.id, update ) ).then( ( res ) => {
      if ( !res.success && res.data && res.data.hasOwnProperty( 'processors.pre' ) ) {
        let msg = res.message;
        let fieldVal = res.data[ 'processors.pre' ].value;
        let friendlyMsg = msg.slice( msg.indexOf( fieldVal ) + fieldVal.length );
        this.setState( { notes: [ { type: 'error', note: friendlyMsg } ] } );
      }
    } );
  }

  /** Once nodes updated, loop through and get all edges **/
  getUpdatedEdges( edges ) {
    let updatedEdges = [];
    edges.forEach( ( node ) => {
      if ( node.destinations.length > 0 ) {
        node.destinations.forEach( n => {
          updatedEdges.push( { source: node.id, target: n } );
        } );
      }
    } );
    return updatedEdges;
  }

  // Launch the chain
  launchChain() {
    const chain = this.props.chain;
    const project_id = this.props.projectId;
    this.setState( { notes: [], launchStatus: `Launching chain: ${chain.name}` } );
    // First try to launch the workflows
    this.launchWorkflowsInChain( chain )
      .then( this.validateChain( chain ) )
      .then( () => {
        this.setState( { launchStatus: `Launching chain: ${chain.name}` } );
        this.props.dispatch( launchChain( chain.id ) )
          .then( ( err ) => {
            let errorMessage;
            if ( !err.success ) {
              this.validateChain( err.workflowChain );
              this.props.dispatch( fetchProjectChainsAndWorkflows( project_id, 'workflows' ) );
              if ( err.workflowChain && err.workflowChain.validationResult.length > 0 ) {
                errorMessage = err.workflowChain.validationResult.map( e => {
                  return { type: 'error', note: e.message };
                } );
              } else {
                errorMessage = [ { type: 'error', note: 'Internal Server Error' } ];
              }

              this.setState( {
                notes: errorMessage,
                launchStatus: null,
              } );
            } else {
              this.setState( {
                notes: [ {
                  type: 'success',
                  note: 'Validation Complete: Upload a batch of work below.'
                } ],
                launchStatus: null,
              } );
            }
          } );
      } );
  }

  validateChain( chain ) {
    if ( this.chainBuilder ) {
      const edges = this.getUpdatedEdges( this.props.chain.nodes );
      this.chainBuilder.revalidateEdges( edges, chain.validationResult );
      return chain;
    }
  }

  handleErrorMessages( chain ) {
    const validationResult = chain.validationResult;
    if ( !!validationResult.length && validationResult[ 0 ].type !== 'NoChainNodes' ) {
      const errorMessage = validationResult.map( err => {
        return { type: 'error', note: err.message };
      } );
      this.setState( {
        notes: errorMessage,
        launchStatus: null,
      } );
    }
  }

  launchWorkflowsInChain( chain ) {
    return new Promise( ( resolve, reject ) => {
      if ( !chain ) {
        throw new Error( 'Please provide chain to launch' );
      }
      if ( !chain.nodes || !chain.nodes.length ) {
        throw new Error( `Chain ${chain.id} does not have any nodes to launch` );
      }

      // Get startup node
      const startupNode = chain.nodes.find( node => node.operation.startup );

      // Get all nodes that have swfWorkflow but no swfWorkflow.workflowId
      const workflowNodes = chain.nodes.filter( node => (
        node.operation.swfWorkflow && !node.operation.swfWorkflow.workflowId
      ) );

      // If no workflowNodes resolve
      if ( !workflowNodes.length ) {
        return resolve( chain );
      }

      this.setState( { launchStatus: `Launching ${workflowNodes.length} workflows` } );

      let index = 0;

      // Make copy of nodes and filter only workflow nodes
      const nodes = [ ...chain.nodes ].filter( node => !node.operation.startup );

      const t = this;

      function handleLaunchWorkflow() {
        const workflow = workflowNodes[ index ];

        t.setState( { launchStatus: `Launching workflow: ${workflow.name}` } );
        t.props.dispatch( launchWorkflow( workflow.operation.swfWorkflow.entityId, true ) )
          .then( workflow => {
            // Update each node with the new workflow ID
            // const nodeIndex = nodes.findIndex(node => node.operation.swfWorkflow.entityId === workflow.id.toString());
            // nodes[nodeIndex].operation.swfWorkflow.workflowId = workflow.id_swf;

            nodes.forEach( ( node, idx ) => {
              if ( node.operation.swfWorkflow && node.operation.swfWorkflow.entityId === workflow.id.toString() ) {
                node.operation.swfWorkflow.workflowId = workflow.id_swf;
                /*
                 Once workflow launched successfully remove draft class and add active class to node
                 */
                $( `[data-jtk-node-id="${nodes[ idx ].id}"]` ).removeClass( 'draft' );
                $( `[data-jtk-node-id="${nodes[ idx ].id}"]` ).addClass( 'inactive' );
              }
            } );

            index++;

            // If all nodes launched, add startup node to beginning and update chain
            if ( index >= workflowNodes.length ) {
              nodes.unshift( startupNode );
              return resolve( t.updateChain( { nodes } ) );
            }
            handleLaunchWorkflow();
          } )
          .catch( err => {
            const workflow = t.props.workflows[ err.workflow ];
            t.setState( {
              launchStatus: null,
              notes: [
                {
                  type: 'error',
                  note: `<span>
                            Error: <b><a href="/workflows/${err.workflow}/steps">${workflow.name}</a></b>
                            is incomplete and not ready to be launched.
                        </span>`
                }
              ]
            } );
            reject();
          } );
      }

      // Handle launching a workflow
      handleLaunchWorkflow();
    } );
  }

  // Check if the chain and workflows have loaded for the first time
  isFirstTimeReady( nextProps ) {
    const { chain, isLoadingWorkflows } = nextProps;
    const chainLoaded = !this.props.chain && chain && !isLoadingWorkflows;
    const workflowsLoaded = this.props.isLoadingWorkflows && !isLoadingWorkflows && !!chain;
    return chainLoaded || workflowsLoaded;
  }
}

const styles = {
  legendContainer: {
    margin: '16px 0px',
    padding: '28px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: colors.shaded,
    borderRadius: 5,
  },
  key: {
    padding: '10px 16px',
    marginRight: -8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    border: `2px solid ${colors.divider}`,
    borderRadius: 5,
  },
  keyItem: {
    marginRight: 8,
    marginLeft: 8,
    display: 'flex',
    alignItems: 'center',
  },
  keyColor: {
    height: 16,
    width: 16,
    border: `1px solid ${colors.border}`,
    marginRight: 6,
  },
  keyInactive: {
    border: `1px dashed ${colors.border}`,
  },
  collapser: {
    borderTop: `1px solid ${colors.border}`,
    borderBottom: `1px solid ${colors.border}`,
    margin: '30px 0',
    padding: '10px 0',
    overflow: 'hidden',
  },
  processorTabs: {
    border: `1px solid ${colors.border}`,
    padding: '30px 15px 15px',
    backgroundColor: 'white',
  },
};

/* PropTypes */
ChainEditor.propTypes = {
  chain: PropTypes.object,
  batches: PropTypes.arrayOf( PropTypes.object ),
};
ChainEditor.defaultProps = {
  chain: {},
  batches: [],
};

function select( state, props ) {
  const chain_id = props.chainId;
  const project_id = props.projectId;
  const chain = state.chains.items[ chain_id ];
  return {
    chain,
    user: stateHelpers.getUser( state ),
    userRole: stateHelpers.getUserRole( state ),
    workflows: state.workflows.items,
    batches: chain && objectToArray( state.batches.items ).filter( b => {
      const batchChainId = b.chainId || b.id_chain || '';
      return (batchChainId.toString() === chain_id.toString()) && !b.id_chain_batch;
    } ),
    projectId: project_id,
    chainId: chain_id,
    isLoadingBatches: isChildFetching( chain, 'batches' ),
    pageCount: state.batches.pageCount,
    batchNotification: !!chain.batchNotification,
  };
}

export default connect( select )( ChainEditor );
