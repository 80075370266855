import React from 'react';
import PropTypes from 'prop-types';

import { newestFirst } from '../../utils/sorts';
import BatchOverview from '../../containers/BatchOverview';
import DropZone from '../../containers/DropZone';
import Panel from '../../containers/Panel';
import Note from '../../containers/Note';
import Pagination from '../../components/Paginator/index';

function isMostRecentBatch(batches, batch) {
  return batches[0].id == batch.id;
}

/**
 * ChainBatchManagement
 */
const ChainBatchManagement = ( {
  batches,
  projectId,
  handleUploadBatch,
  disabled,
  disabledLabel,
  uploadErrors,
  getBatches,
  pageCount,
  currentPage,
  loadingBatches,
  acceptedUploadFileTypes,
  preprocessorEnabled,
  handleOptionsSelect,
  batchNotification,
} ) => {

  return (
    <Panel
      title="Chain Batch Management"
      options={[
        { label: 'Download CSV Template', value: 'csv' },
        { label: 'Advanced Export', value: 'advancedExport' },
        { label: `Batch Notifications: ${batchNotification ? 'On' : 'Off'}`, value: 'batchNote' },
      ]}
      handleSelectOption={ handleOptionsSelect }>
      <div style={styles.chainBatchManagement}>
        <DropZone
          preprocessorEnabled={preprocessorEnabled}
          accept={acceptedUploadFileTypes}
          locked={disabled}
          lockedLabel={disabledLabel}
          handleDrop={handleUploadBatch}
        />

        {!!uploadErrors &&
        <div>
          {uploadErrors.map( ( err, i ) => (
            <div key={i} style={{ marginBottom: 1 }}>
              <Note
                type={err.type}
                note={err.note}
              />
            </div>
          ) )}
        </div>
        }


        <Pagination
          loading={loadingBatches}
          pageCount={pageCount}
          currentPage={currentPage}
          changePage={getBatches}>
          {batches.sort(newestFirst).map(batch => {
            if((isMostRecentBatch(batches, batch) && batch.state == 'failed') || batch.state != 'failed') {
              return (
                <div key={batch.id || batch.tempId} style={styles.batch}>
                  <BatchOverview
                    batch={batch}
                    projectId={projectId}
                    disabled={disabled}
                  />
                </div>
              );
            }}
          )}
        </Pagination>


      </div>
    </Panel>
  );
};

/* Styles */
const styles = {
  chainBatchManagement: {
    padding: 16,
  },
  batch: {
    marginTop: 16,
  }
};

/* PropTypes */
ChainBatchManagement.propTypes = {
  chain: PropTypes.shape( {
    nodes: PropTypes.arrayOf( PropTypes.shape( {
      operation: PropTypes.object,
    } ) ).isRequired,
  } ).isRequired,
  batches: PropTypes.arrayOf( PropTypes.shape( {} ) ).isRequired,
  projectId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  disabledLabel: PropTypes.string,
  handleUploadBatch: PropTypes.func.isRequired,
};

export default ChainBatchManagement;
