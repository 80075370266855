import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import moment from 'moment';

import { RELEASE_NOTIFICATION_MODAL } from '../../constants/modalTypes';
import { toggleModal } from '../../actions/app';
import { createReleaseNote } from '../../actions/releaseNotes';

import Wrapper from '../../containers/Wrapper';
import BreadCrumb from '../../containers/BreadCrumb';
import { Heading, Label, BodyInstruction, Content } from '../../components/type';
import Panel from '../../containers/Panel';
import Input from '../../components/Input';
import InlineButton from '../../components/InlineButton';
import Button from '../../components/Button';
import IconButton from '../../components/IconButton';
import Select from '../../components/Select';
import colors from '../../styles/colors';
import TinyMCEComponent from '../../components/TinyMCE/index';
import DatePickerComponent from '../../components/DatePicker/index';


class CreateNotification extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      postNow: false,
      title: '',
      body: '',
      date: null,
      startTime: '',
      amOrPm: 0,
    };

    this.handleDateChange = this.handleDateChange.bind( this );
    this.handleSave = this.handleSave.bind( this );
  }

  handleDateChange( date ) {
    this.setState( { date } );
  }

  handleSave( saveType ) {
    const { title, date, startTime, amOrPm, postNow } = this.state;
    const body = this.refs.editorBody.state.body;
    let fullDate = '';
    if ( postNow ) {
      const c = moment();
      const roundUp = c.minute() || c.second() || c.millisecond() ? c.add( 1, 'hour' ).startOf( 'hour' ) : c.startOf( 'hour' );
      fullDate = moment( roundUp ).format( 'YYYY/MM/DD hh:mm A' );
    } else {
      const amPm = (amOrPm === 2 ? 'pm' : 'am');
      fullDate = `${moment( date ).format( 'YYYY/MM/DD' )} ${startTime}:00 ${amPm}`;
    }

    switch ( saveType ) {
      case 'save':
        return (this.props.dispatch( createReleaseNote( title, body, null ) )
            .then( res => {
              if ( res.success === true ) {
                window.location.href = `/manage/notification-center/${res.releaseNote.id}`;
              }
            } )
        );
      case 'saveClose':
        return (this.props.dispatch( createReleaseNote( title, body, null ) )
            .then( res => {
              if ( res.success === true ) {
                window.location.href = '/manage/notification-center';
              }
            } )
        );
      case 'scheduleNotification':
        return (this.props.dispatch( createReleaseNote( title, body, fullDate ) )
            .then( res => {
              if ( res.success === true ) {
                window.location.href = '/manage/notification-center';
              }
            } )
        );
    }
  }

  render() {
    const { title, body, date, postNow, amOrPm, startTime } = this.state;
    return (
      <DocumentTitle title="Create Notification | OneSpace Project Center">
        <div className="create-notification" style={{marginTop: 64}}>
          <Wrapper>
            <BreadCrumb links={[
                { href: '/manage/notification-center', label: 'Release Notification Center', refresh: true },
                { label: 'Create Notification' },
              ]}/>
            <Heading color="dark">Release Notification Center</Heading>
            <Panel title="Create Notification">
              <div style={{padding: 20}}>
                <div style={{marginBottom: 16}}>
                  <Label>Title</Label>
                  <div style={styles.inputWidth}>
                    <Input
                      handleChange={title => this.setState({title})}
                      value={title}/>
                  </div>
                </div>
                <Label>Body</Label>
                <div style={styles.inputWidth}>
                  <TinyMCEComponent
                    ref="editorBody"
                    body={body}/>
                </div>
                <div style={styles.saveButtonsContainer}>
                  <div style={{marginRight: 8}}>
                    <InlineButton
                      handleClick={() => this.handleSave('save')}>
                      Save
                    </InlineButton>
                  </div>
                  <InlineButton
                    handleClick={() => this.handleSave('saveClose')}>
                    Save & Close
                  </InlineButton>
                  <div style={styles.verticalLine}></div>
                  <InlineButton
                    handleClick={() => {
                      this.props.dispatch(toggleModal(RELEASE_NOTIFICATION_MODAL, {
                        preview: true,
                        body: this.refs.editorBody.state.body,
                        enableOverlayClick: true,
                      }));
                    }}>
                    Preview
                  </InlineButton>
                </div>
                <div style={{marginTop: 36}}>
                  <BodyInstruction>Schedule or post your notification</BodyInstruction>
                  <Content>
                    Some more info on the difference between posting now vs scheduling a post. For example. What<br/>
                    happens to the current notification if you post another one.
                  </Content>
                  <hr/>
                </div>
                {postNow ? (
                    <Button handleClick={() => {this.handleSave('scheduleNotification');}}>Post Live Now</Button>
                  ) : (
                    <div style={styles.dateContainer}>
                      <div>
                        <Label color="light">Date</Label>
                        <DatePickerComponent
                          handleDateChange={this.handleDateChange}
                          date={date}/>
                      </div>
                      <div style={{marginLeft: 16, maxWidth: 75}}>
                        <Label color="light">Time</Label>
                        <Input
                          handleChange={startTime => this.setState({startTime})}
                          type="number"
                          value={startTime}
                          min="1"
                          max="12"/>
                      </div>
                      <div style={{minWidth: 100, marginTop: 23, marginLeft: 16}}>
                        <Select
                          options={[{label: 'Select', value: 0}, {label: 'AM', value: 1}, {label: 'PM', value: 2}]}
                          handleSelect={(e) => {this.setState({amOrPm: e});}}
                          value={amOrPm}/>
                      </div>
                      <div style={{marginLeft: 16, marginTop: 24}}>
                        <Button
                          handleClick={() => this.handleSave('scheduleNotification')}>
                          Schedule Notification
                        </Button>
                      </div>
                    </div>
                  )}
                <div style={{marginTop: 16}}>
                  <IconButton
                    icon={postNow ? 'check-square-o' : 'square-o'}
                    label=""
                    handleClick={() => {this.setState({postNow: !this.state.postNow});}}>
                    <div style={{textDecoration: 'none !important', display: 'inline-block'}}>
                      Post notification now
                    </div>
                  </IconButton>
                </div>
              </div>
            </Panel>
          </Wrapper>
        </div>
      </DocumentTitle>
    );
  }
}

const styles = {
  verticalLine: {
    height: 32,
    background: colors.border,
    width: 1,
    marginRight: 8,
    marginLeft: 8
  },
  inputWidth: {
    maxWidth: 750,
  },
  saveButtonsContainer: {
    display: 'flex',
    marginTop: 20,
  },
  dateContainer: {
    display: 'flex',
    alignItems: 'center'
  }
};

function select() {
  return {};
}

export default connect( select )( CreateNotification );