import React from 'react';
import colors from '../../../../../../styles/colors';
import SupportRoleOptions from './SupportRoleOptions';
import Settings from '../Settings';

class HorizontalRule extends React.Component {
  componentDidMount() {
    if (this.props.selected) {
      this.hr.scrollIntoView();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.selected && this.props.selected) {
      this.hr.scrollIntoView();
    }
  }

  render() {
    const {data, isSupportRole, selected, updateElement, handleSelectInput} = this.props;
    return (
      <div>
        <hr style={styles} ref={ele => this.hr = ele} />
        <Settings show={selected} customHeight="auto">
          {isSupportRole &&
          <SupportRoleOptions
            data={data}
            updateElement={updateElement}
            handleSelectInput={handleSelectInput}
          />
          }
        </Settings>
      </div>
    );
  }
}

const styles = {
  borderTop: `1px solid ${colors.dark}`,
};

export default HorizontalRule;
