const getUserRole = (state) => {
  const user = getUser(state);
  const company = state.app.company;

  if(!user || !company) {
    return null;
  }

  if(state.app.role === 'onespace-support' || state.app.role === 'onespace-account') {
    return state.app.role;
  }

  const admin = company.admins.find(admin => admin.id === user.id);
  if(!admin) {
    return null;
  }

  return admin.role;
};

const isApiAdmin = (state) => {
  return getUser(state).isApiAdmin.toString() === '1' || state.app.role === 'onespace-support';
};

const getUser = (state) => {
  if(!state.users.items || !state.app.user) {
    return null;
  }
  return state.users.items[state.app.user];
};

const canEdit = (item, state) => {
  if (!item || !state) {
    throw new Error('Please provide an item and state');
  }

  const user = getUser(state);
  const role = getUserRole(state);

  const admin = (role === 'admin' || role === 'onespace-support');
  const creator = (item.creatorId && item.creatorId.toString() === user.id_worker.toString()) ||
    (item.id_creator && item.id_creator.toString() === user.id.toString());
  const projectManager = item.id_project_manager && item.id_project_manager.toString() === user.id.toString();

  return admin || creator || projectManager;
};

const getCompanyName = (state) => {
  if(!state.app.company){
    return null;
  }
  return state.app.company.name;
};

export default {
  canEdit,
  getUserRole,
  getUser,
  getCompanyName,
  isApiAdmin,
};
