import React, { Component} from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Link } from 'react-router-dom';

import Logo from '../components/Logo';
import colors from '../styles/colors';


class BasicShell extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div>
        <div className={css(styles.topBar)}>
          <Link to="/">
            <Logo primary={colors.primary()} secondary={colors.secondary()} showText={true}/>
          </Link>
        </div>
        <div className={css(styles.bodyStyle)}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  bodyStyle: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    position: 'absolute',
    width: '100%',
    left: 0,
  },
  topBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    height: 90,
    width: '100%',
    paddingLeft: 15,
    paddingRight: 15,
    boxSizing: 'border-box',

    background: 'white',
    borderBottom: '1px solid #ebebeb'
  },
});


export default BasicShell;