import makeActionCreator from '../utils/_makeActionCreator';
import { TOGGLE_MODAL, ADD_TOAST, CLEAR_TOAST } from '../constants/actionTypes';

/**
 * toggleModal Action
 * Update state.app.modal. If modal is already open it will be closed.
 *
 * Params:
 *   modal - Name of the modal. Imported from constants/modalTypes.
 *   meta - Object of extra props to be passed to the modal component
 *
 * Example:
 *   import { ADD_FUNDS } from '../constants/modalTypes';
 *   this.props.dispatch(toggleModal(ADD_FUNDS, { accountBalance ));
 */
export const toggleModal = makeActionCreator(TOGGLE_MODAL, 'modal', 'meta');

/**
 * toast Action
 * Adds a toast object to the app state
 * Params:
 *   label - String to display
 *   toastType - Success or Error
 */
export const toast = (label, toastType, toastTime) => {
  return dispatch => {
    dispatch({
      type: ADD_TOAST,
      toastType,
      label,
    });
    setTimeout(() => {
      dispatch({
        type: CLEAR_TOAST,
      });
    }, toastTime ? toastTime : 8000);
  };
};

export const removeToastAtIndex = makeActionCreator(CLEAR_TOAST, 'index');
