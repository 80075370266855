export default {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)'
  },
  content: {
    position: 'relative',
    top: '50%',
    left: '0',
    maxWidth: 800,
    maxHeight: '100%',
    right: 'initial',
    bottom: 'initial',
    border: 'none',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    padding: 0,
    margin: 'auto',
    transform: 'translateY(-50%)',
  }
};
