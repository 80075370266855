import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import colors from '../styles/colors';
import { Label } from './type';

/**
 * TextArea
 * Stylized <textarea>
 */
const TextArea = ({ value, disabled, rows, handleChange, error }) => {
  return (
    <div>
      <textarea
        value={value}
        disabled={disabled}
        className={css(styles.select, error && styles.textAreaError)}
        onChange={e => handleChange(e.target.value)}
        rows={rows}
      />
      {error && <Label italic={true} color="negative">{error}</Label>}
    </div>
  );
};

/* Styles */
const styles = StyleSheet.create({
  select: {
    width: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    boxSizing: 'border-box',
    border: `solid 2px ${colors.divider}`,
    borderRadius: 8,
    backgroundColor: 'white',
    fontFamily: 'Open Sans',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 2,
    transition: 'border-color 0.2s ease',
    ':focus': {
      borderColor: colors.dark,
      outline: 0
    }
  },
  textAreaError: {
    border: `2px solid ${colors.negative}`,
  },
});

/* PropTypes */
TextArea.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  rows: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
};
TextArea.defaultProps = {
  rows: 4,
};

export default TextArea;
