import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import moment from 'moment';

import { RELEASE_NOTIFICATION_MODAL } from '../../constants/modalTypes';
import { fetchReleaseNote, updateReleaseNote } from '../../actions/releaseNotes';
import { toggleModal } from '../../actions/app';

import Wrapper from '../../containers/Wrapper';
import BreadCrumb from '../../containers/BreadCrumb';
import { Heading, Label, BodyInstruction, Content } from '../../components/type';
import Panel from '../../containers/Panel';
import Input from '../../components/Input';
import InlineButton from '../../components/InlineButton';
import Button from '../../components/Button';
import IconButton from '../../components/IconButton';
import Select from '../../components/Select';
import colors from '../../styles/colors';
import ShadedTextTile from '../../components/ShadedTextTile';
import DatePickerComponent from '../../components/DatePicker/index';
import TinyMCEComponent from '../../components/TinyMCE/index';


class UpdateNotification extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      postNow: false,
      title: '',
      body: '',
      date: null,
      startTime: '',
      amOrPm: 0,
      fetchedUpdateNote: false,
      showStaticDate: true,
    };

    this.handleDateChange = this.handleDateChange.bind( this );
    this.handleUpdate = this.handleUpdate.bind( this );
    this.removeFromSchedule = this.removeFromSchedule.bind( this );
  }

  componentDidMount() {
    this.props.dispatch( fetchReleaseNote( this.props.notificationId ) )
      .then( () => {
        const updateNote = this.props.updateNote;
        this.setState( {
          title: updateNote.title,
          body: updateNote.body,
          date: updateNote.active_at.toString() !== '0' ? moment.unix( updateNote.active_at ) : null,
          startTime: updateNote.active_at.toString() !== '0' ? moment.unix( updateNote.active_at ).format( 'hh' ) : null,
          amOrPm: updateNote.active_at.toString() !== '0' ? (moment.unix( updateNote.active_at ).format( 'A' ) === 'PM' ? 2 : 1) : 0,
          fetchedUpdateNote: true,
          showStaticDate: updateNote.active_at.toString() !== '0' ,
        } );
      } );
  }

  handleDateChange( date ) {
    this.setState( { date } );
  }

  handleUpdate( updateType ) {
    const { title, date, startTime, amOrPm, postNow } = this.state;
    const { notificationId } = this.props;
    const body = this.refs.editorBody.state.body;
    let fullDate = '';
    if ( postNow ) {
      const c = moment();
      const roundUp = c.minute() || c.second() || c.millisecond() ? c.add( 1, 'hour' ).startOf( 'hour' ) : c.startOf( 'hour' );
      fullDate = moment( roundUp ).format( 'YYYY/MM/DD hh:mm A' );
    } else if(!!date) {
      const amPm = (amOrPm === 2 ? 'pm' : 'am');
      fullDate = `${moment( date ).format( 'YYYY/MM/DD' )} ${startTime}:00 ${amPm}`;
    } else {
      fullDate = null;
    }

    switch ( updateType ) {
      case 'update':
        return (this.props.dispatch( updateReleaseNote( notificationId, title, body, fullDate ) )
            .then( res => {
              if ( res.success === true ) {
                window.location.href = `/manage/notification-center/${res.releaseNote.id}`;
              }
            } )
        );
      case 'updateClose':
        return (this.props.dispatch( updateReleaseNote( notificationId, title, body, fullDate ) )
            .then( res => {
              if ( res.success === true ) {
                window.location.href = '/manage/notification-center';
              }
            } )
        );
    }
  }

  removeFromSchedule() {
    this.setState( {
      showStaticDate: false,
      date: null,
      startTime: null,
      amOrPm: 0,
    } );
  }

  render() {
    const { title, body, date, postNow, amOrPm, startTime, fetchedUpdateNote, showStaticDate } = this.state;

    return (
      <DocumentTitle title="Update Notification | OneSpace Project Center">
        <div className="update-notification" style={{marginTop: 64}}>
          <Wrapper>
            {fetchedUpdateNote &&
            <div>
              <BreadCrumb links={[
                  { href: '/manage/notification-center', label: 'Release Notification Center', refresh: true },
                  { label: this.props.updateNote.title},
                ]}/>
              <Heading color="dark">Release Notification Center</Heading>
              <Panel title="Update Notification">
                <div style={{padding: 20}}>
                  <div style={{marginBottom: 16}}>
                    <Label>Title</Label>
                    <div style={styles.inputWidth}>
                      <Input
                        handleChange={title => this.setState({title})}
                        value={title}/>
                    </div>
                  </div>
                  <Label>Body</Label>
                  <div style={styles.inputWidth}>
                    <TinyMCEComponent
                      ref="editorBody"
                      body={body}/>
                  </div>
                  <div style={styles.updateButtonsContainer}>
                    <div style={{marginRight: 8}}>
                      <InlineButton
                        handleClick={() => this.handleUpdate('update')}>
                        Update
                      </InlineButton>
                    </div>
                    <InlineButton
                      handleClick={() => this.handleUpdate('updateClose')}>
                      Update & Close
                    </InlineButton>
                    <div style={styles.verticalLine}></div>
                    <InlineButton
                      handleClick={() => {
                        this.props.dispatch(toggleModal(RELEASE_NOTIFICATION_MODAL, {
                          preview: true,
                          body: this.refs.editorBody.state.body,
                          enableOverlayClick: true,
                        }));
                      }}>
                      Preview
                    </InlineButton>
                  </div>
                  <div style={{marginTop: 36}}>
                    <BodyInstruction>Schedule or post your notification</BodyInstruction>
                    <Content>
                      Some more info on the difference between posting now vs scheduling a post. For example. What<br/>
                      happens to the current notification if you post another one.
                    </Content>
                    <hr/>
                  </div>
                  {!showStaticDate && (postNow ? (
                      <Button handleClick={() => this.handleUpdate('updateClose')}>Post Live Now</Button>
                    ) : (
                      <div style={styles.dateContainer}>
                        <div>
                          <Label color="light">Date</Label>
                          <DatePickerComponent
                            handleDateChange={this.handleDateChange}
                            date={date}/>
                        </div>
                        <div style={{marginLeft: 16, maxWidth: 75}}>
                          <Label color="light">Time</Label>
                          <Input
                            handleChange={startTime => this.setState({startTime})}
                            type="number"
                            value={startTime}
                            min="1"
                            max="12"/>
                        </div>
                        <div style={{minWidth: 100, marginTop: 23, marginLeft: 16}}>
                          <Select
                            options={[{label: 'Select', value: 0},{label: 'AM', value: 1}, {label: 'PM', value: 2}]}
                            handleSelect={e => this.setState({amOrPm: e})}
                            value={amOrPm}/>
                        </div>
                        <div style={{marginLeft: 16, marginTop: 24}}>
                          <Button
                            handleClick={() => this.handleUpdate('updateClose')}>
                            Schedule Notification
                          </Button>
                        </div>
                      </div>
                    ))
                  }
                  {showStaticDate &&
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{marginRight: 8}}>
                      <ShadedTextTile label={!!date ? date.format('MM/DD/YYYY') : ''}/>
                    </div>
                    <div style={{marginRight: 8}}>
                      <ShadedTextTile label={startTime}/>
                    </div>
                    <div>
                      <ShadedTextTile label={amOrPm === 1 ? 'AM' : 'PM'}/>
                    </div>
                    <div style={{marginLeft: 8, marginRight: 8}}>
                      <IconButton
                        icon="pencil-square-o"
                        handleClick={() => this.setState({showStaticDate: false})}>
                        Edit Schedule
                      </IconButton>
                    </div>
                    <IconButton
                      icon="times"
                      handleClick={() => this.removeFromSchedule()}>
                      Remove from Schedule
                    </IconButton>
                  </div>
                  }
                  {!showStaticDate &&
                  <div style={{marginTop: 16}}>
                    <IconButton
                      icon={postNow ? 'check-square-o' : 'square-o'}
                      label=""
                      handleClick={() => {this.setState({postNow: !this.state.postNow});}}>
                      <div style={{textDecoration: 'none !important', display: 'inline-block'}}>
                        Post notification now
                      </div>
                    </IconButton>
                  </div>
                  }
                </div>
              </Panel>
            </div>
            }
          </Wrapper>
        </div>
      </DocumentTitle>
    );
  }
}

const styles = {
  verticalLine: {
    height: 32,
    background: colors.border,
    width: 1,
    marginRight: 8,
    marginLeft: 8,
  },
  inputWidth: {
    maxWidth: 750,
  },
  updateButtonsContainer: {
    display: 'flex',
    marginTop: 20,
  },
  dateContainer: {
    display: 'flex',
    alignItems: 'center'
  }
};

UpdateNotification.propTypes = {
  notificationId: PropTypes.string.isRequired,
  updateNote: PropTypes.shape( {
    title: PropTypes.string,
    id: PropTypes.number,
    body: PropTypes.string,
    active_at: PropTypes.number,
  } ),
  hasFetchedReleaseNote: PropTypes.bool,
};

function select( state, rr ) {
  const notificationId = rr.match.params.notification_id;
  const updateNote = state.releaseNotes.updateNote;
  const hasFetchedReleaseNote = state.releaseNotes.isFetching;
  return {
    notificationId,
    updateNote,
    hasFetchedReleaseNote,
  };
}

export default connect( select )( UpdateNotification );