import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import configureStore from './reducers/configureStore';
import BrowserHistory from './utils/history';

import App from './pages/App';

import BasicShell from './pages/BasicShell';
import Shell from './pages/Shell';

import Dashboard from './pages/Dashboard';
import Projects from './pages/Projects';
import Chains from './pages/Chains';
import ChainManager from './pages/ChainManager';
import WorkflowChaining from './pages/WorkflowChaining/index';
import WorkflowBatchManagement from './pages/WorkflowBatchManagement';
import StyleGuide from './pages/StyleGuide';
import Workflows from './pages/Workflows';
import TaskUI from './pages/TaskUI';
import CompanySupport from './pages/CompanySupport';
import BulkCancel from './pages/BulkWorkflowCancel';
import Teams from './pages/Teams';
import NotificationCenter from './pages/NotificationCenter';
import CreateNotification from './pages/NotificationCenter/CreateNotification';
import UpdateNotification from './pages/NotificationCenter/UpdateNotification';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import ChainPluginLibrary from './pages/ChainPluginLibrary';
import ChainPlugin from './pages/ChainPlugin';
import BulkPromoteDemote from './pages/ContributorLevels';
import AdvancedExports from './pages/AdvancedExports';
import ManageCompany from './pages/ManageCompany';
import ApiAdmin from './pages/ApiAdmin';

import reducers from './reducers'; // Or wherever you keep your reducers

const is = document.getElementById('is') && document.getElementById('is').innerHTML;
const store = configureStore(JSON.parse(is), reducers);
const user = store.getState().app.user;
const userRole = store.getState().app.role;

const Routes = () => {
  return (
    <Router history={BrowserHistory}>
      <Route render={matchProps => {
        if(user === null) {
          return (
            <BasicShell {...matchProps}>
              <Switch>
                <Route exact path="/" component={Login} />
                <Route path="/login" component={Login} />
                <Route path="/signup" component={SignUp} />
                <Route path="/forgotpassword" component={ForgotPassword} />
                <Route component={Login} />
              </Switch>
            </BasicShell>
          );
        }
        if (userRole === 'onespace-account') {
          return (
            <BasicShell {...matchProps}>
              <Switch>
                <Route path='/manage/company' component={ManageCompany} />
              </Switch>
            </BasicShell>
          );
        }
        return (
          <App>
            <Shell {...matchProps}>
              <Switch>
                <Route exact path='/' component={Dashboard}/>
                <Route path='/login' component={Dashboard} />
                <Route path='/dashboard' component={Dashboard} />
                <Route path= '/projects/:project_id/chains/:chain_id/monitor' component={Chains} />
                <Route path= '/projects/:project_id/chains/:chain_id' component={WorkflowChaining} />
                <Route path= '/projects' component={Projects} />
                <Route path= '/chains' component={ChainManager} />
                <Route path= '/workflows/:workflow_id/batch' component={WorkflowBatchManagement} />
                <Route path= '/styleguide' component={StyleGuide} />
                <Route path= '/workflows/:workflow_id/bulkcancel' component={BulkCancel} />
                <Route path= '/workflows' component={Workflows} />
                <Route path= '/taskui' component={TaskUI} />
                <Route path= '/manage/company/support' component={CompanySupport} />
                <Route path= '/teams' component={Teams} />
                <Route path= '/manage/notification-center/create' component={CreateNotification} />
                <Route path= '/manage/notification-center/:notification_id' component={UpdateNotification} />
                <Route path= '/manage/notification-center' component={NotificationCenter} />
                <Route path= '/manage/plugins/create' component={ChainPlugin} />
                <Route path= '/manage/plugins/:plugin_id' component={ChainPlugin} />
                <Route path= '/manage/plugins' component={ChainPluginLibrary} />
                <Route path= '/contributor-levels/:assignment_id' component={BulkPromoteDemote} />
                <Route path= '/contributor-levels' component={BulkPromoteDemote} />
                <Route path= '/advanced-export' component={AdvancedExports} />
                <Route path= '/company/api-admin' component={ApiAdmin} />
              </Switch>
            </Shell>
          </App>
        );

      }} />
    </Router>
  );
};

function select() {
  return {};
}

export default connect(select)(Routes);
