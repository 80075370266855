import React, { Component } from 'react';
import numeral from 'numeral';
import PropTypes from 'prop-types';

import { Title, Attention, Label } from '../../components/type';
import Panel from '../../containers/Panel';
import CurrencyInput from '../../components/CurrencyInput';
import Input from '../../components/Input';
import Button from '../../components/PrimaryButton';
import TextArea from '../../components/TextArea';

import colors from '../../styles/colors';
import { updateCompanyLedger } from '../../actions/company';
import { ledgerState } from './Constants';

class AddLedger extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      ledgerState: ledgerState,
      updating: false,
      updateError: '',
    };
  }

  handleAddLedger() {
    const { selectedCompany } = this.props;
    const {  updating } = this.state;
    let ledgerState = {...this.state.ledgerState};

    if(!this.ledgerValidation()) {
      return;
    }
    const formattedAmount = numeral(ledgerState.amount).value();
    this.setState({updating: !updating}, () => {
      this.props.dispatch( updateCompanyLedger(
          selectedCompany.companyId,
          selectedCompany.swfId,
          formattedAmount,
          ledgerState.description,
          ledgerState.invoiceId))
        .then(() => {
          this.props.handleAddLedger();
        })
        .catch(() => {
          this.setState({
            updating: false,
            updateError: 'There was an error updating the ledger.'
          });
        });
    });
  }

  ledgerValidation() {
    const { ledger } = this.props;
    let ledgerState = {...this.state.ledgerState};
    if(!ledgerState.description || !ledgerState.invoiceId || (ledgerState.amount < (0 - ledger.availableBalance))) {
      if(!ledgerState.description) {
        ledgerState.descriptionError = true;
      }
      if(!ledgerState.invoiceId) {
        ledgerState.invoiceError = true;
      }
      if(ledgerState.amount < (0 - ledger.availableBalance)) {
        ledgerState.amountError = true;
      }
      this.setState({ledgerState});
      return false;
    }
    return true;
  }

  handleInput(key, value) {
    let newState = {...this.state.ledgerState};
    newState[key] = value;
    this.setState({ledgerState: newState});
  }

  render() {
    const { updating, updateError } = this.state;
    const {
      invoiceId,
      amount,
      amountError,
      description,
      invoiceError,
      descriptionError
    } = this.state.ledgerState;

    return (
      <Panel title="Add Ledger" color="light">
        <div style={{ padding: 16 }}>
          <ul style={styles.unorderedList}>
            <li style={styles.listItem}>
              <Title color="light">Account Balance</Title>
              <Title color="dark">{numeral( this.props.ledger.availableBalance ).format( '$0,0.00' )}</Title>
            </li>
            <li style={styles.listItem}>
              <Title color="light">Invoice ID</Title>
              <div style={{ width: 225 }}>
                <Input
                  invalid={invoiceError}
                  value={invoiceId}
                  handleChange={invoiceId => this.handleInput('invoiceId', invoiceId)} />
              </div>
            </li>
            <li style={styles.listItem}>
              <Title color="light">Amount</Title>
              <div style={{ display: 'flex' }}>
                <div style={styles.moneySign}>
                  <Attention color="light" center>$</Attention>
                </div>
                <CurrencyInput
                  error={amountError}
                  value={amount}
                  allowNegative={true}
                  handleUpdate={ ( event, amount ) => {
                    this.handleInput('amount', amount);
                  } }/>
              </div>
            </li>
            <li style={{padding: '16px 0'}}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Title color="light">Message</Title>
              </div>
              <TextArea
                value={description}
                rows={5}
                error={descriptionError}
                handleChange={description => this.handleInput('description', description)} />
            </li>
          </ul>
          <div style={{ marginTop: 16, display: 'flex', alignItems: 'center' }}>
            <Button loading={updating} handleClick={() => this.handleAddLedger()}>
              Apply Changes
            </Button>
            {!!updateError &&
              <div style={{marginLeft: 16}}>
                <Label>{updateError}</Label>
              </div>
            }
          </div>
        </div>
      </Panel>
    );
  }
}

AddLedger.propTypes = {
  selectedCompany: PropTypes.object,
  ledger: PropTypes.object,
  handleAddLedger: PropTypes.func,
  isUpdating: PropTypes.bool,
};

/* Styles */
const styles = {
  listItem: {
    borderBottom: `1px solid ${colors.shaded}`,
    padding: '2em 0 1em',
    display: 'flex',
    justifyContent: 'space-between'
  },
  unorderedList: {
    backgroundColor: colors.background,
    listStyle: 'none',
    marginBottom: 0,
    padding: '0px 16px'
  },
  moneySign: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px solid ${colors.divider}`,
    borderRight: 'none',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    width: 48,
    marginRight: -2
  }
};

export default AddLedger;