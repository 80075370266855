import update from 'immutability-helper';

import {
  FETCH_FUNDS_REQUEST,
  FETCH_FUNDS_SUCCESS,
  FETCH_FUNDS_FAILURE,
  FETCH_INBOX_REQUEST,
  FETCH_INBOX_SUCCESS,
  FETCH_INBOX_FAILURE,
  FETCH_COMPANY_SUCCESS,
  TOGGLE_MODAL,
  RECEIVED_COMPANY_ADMINS,
  ADD_TOAST,
  CLEAR_TOAST,
} from '../constants/actionTypes';

import {
  CONTROL_MODAL_OVERLAY_CLICK,
} from '../constants/modalTypes';

const initialState = {
  toasts: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    // Funds
    case FETCH_FUNDS_REQUEST:
      return update(state, { $merge: { hasFetchedFunds: true, isFetchingFunds: true } });
    case FETCH_FUNDS_SUCCESS:
      return update(state, {
        $merge: {
          isFetchingFunds: false,
          availableBalance: action.availableBalance,
          balance: action.balance,
          usableBalance: action.usableBalance,
          transactionFeeRate: action.transactionFeeRate,
        }
      });
    case FETCH_FUNDS_FAILURE:
      return update(state, { $merge: { isFetchingFunds: false } });
    // Company
    case FETCH_COMPANY_SUCCESS:
      return update(state, { $merge: { company: action.company } });

    // Admins
    case RECEIVED_COMPANY_ADMINS:
      return update(state, { $merge: { companyAdmins: action.admins } });

    // Inbox
    case FETCH_INBOX_REQUEST:
      return update(state, { $merge: { inboxFetching: true, inboxFetched: true } });
    case FETCH_INBOX_SUCCESS:
      return update(state, {
        $merge: {
          inboxFetching: false,
          unreadInboxCount: action.unreadCount,
        },
      });
    case FETCH_INBOX_FAILURE:
      return update(state, { $merge: { inboxFetching: false } });

    // Application State
    case TOGGLE_MODAL:
      return update(state, {
        $merge: {
          modal: state.modal === action.modal ? null : action.modal,
          modalMeta: action.meta || {}
        }
      });

    case CONTROL_MODAL_OVERLAY_CLICK:
      const modalMeta = Object.assign({}, state.modalMeta, {enableOverlayClick: action.enableOverlayClick});
      return update(state, {
        $merge: {
          modalMeta: modalMeta,
        }
      });

    case ADD_TOAST:
      const newToast = { label: action.label, type: action.toastType };
      if (!state.toasts) {
        return update(state, { toasts: { $set: [newToast] } });
      }
      return update(state, { toasts: { $push: [newToast] } });
    case CLEAR_TOAST:
      return update(state, { toasts: { $splice: [[action.index || 0, 1]] } });

    default:
      return state;
  }
}
