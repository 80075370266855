import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { parse, stringify } from 'qs';

import HelpPanel from '../../containers/HelpPanel';
import Wrapper from '../../containers/Wrapper';
import { Heading, Subheading, ManagerAttention, Caption } from '../../components/type';
import colors from '../../styles/colors';
import Panel from '../../containers/Panel';
import LoadState from '../../containers/LoadState';
import Facet from '../../components/Facet';
import Filter from '../../components/Filter';
import WorkflowMetrics from '../../containers/WorkflowMetrics';
import CreateWorkflow from './CreateWorkflow';
import InlineButton from '../../components/InlineButton';

import { newestFirst } from '../../utils/sorts';
import stateHelpers from '../../utils/stateHelpers';
import objectToArray from '../../utils/_objectToArray';
import { toggleModal } from '../../actions/app';
import { BATCH_NOTIFICATIONS, ARCHIVE_WORKFLOW } from '../../constants/modalTypes';
import { fetchWorkflows, validateWorkflowName, updateWorkflowName } from '../../actions/workflows';
import { fetchProjects } from '../../actions/projects';
import { fetchChains } from '../../actions/chains';


const pageDescription = `This page provides you with direct access to all of your workflows. 
Create new workflows, finish drafts, or get  a detailed look at the status and performance of 
your active workflows. The workflows listed here are sorted by batch date. The workflow with 
the newest batch is located at the top. Here, you can manage your workflow items and diagnose 
performance issues. Click on a workflow’s name to make changes to it. Click on Monitor Assignments 
to track the individual assignments that make up a workflow.`;
const helpPanelLinks = [
  {
    label: 'Workflow Monitoring: How It Works',
    href: 'https://onespace.helpdocs.com/managing-your-work/workflow-monitoring-how-it-works'
  },
  {
    label: 'Assignment Monitoring',
    href: 'https://onespace.helpdocs.com/managing-your-work/assignment-monitoring-how-it-works'
  },
];

const statusFilters = {
  active: [ 'active' ],
  inactive: [ 'pending', 'inactive' ],
  archived: [ 'archived' ],
  draft: [ 'draft' ],
};

const sortByCreatedAt = ( a, b ) => new Date( b.created_at ) - new Date( a.created_at );
function getWorkflowHref( workflow ) {
  if ( workflow.workflow_steps > 0 ) {
    return `/workflows/${workflow.id}/steps`;
  }
  return `/projects/${workflow.id_project}/workflows/${workflow.id}`;
}

class Workflows extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      selectedProjects: [],
      selectedStatus: [],
      selectedSort: 'last-batch',
      allCompanyProjects: true,
      isFetchingWorkflows: true,
      workflowNameError: ''
    };

    this.sort = this.sort.bind( this );
    this.filter = this.filter.bind( this );
    this.shouldShowProject = this.shouldShowProject.bind( this );
    this.handleSelectChain = this.handleSelectChain.bind( this );
    this.handleUrlParams = this.handleUrlParams.bind( this );
    this.handleCheckingFilters = this.handleCheckingFilters.bind( this );
  }

  componentDidMount() {
    const { dispatch, location, history } = this.props;

    dispatch(fetchProjects());
    dispatch(fetchWorkflows()).then(() => {
      this.setState({isFetchingWorkflows: false});
    });
    dispatch(fetchChains(false));

    // set search param to whatever the default is
    if ( !location.search ) {
      history.push( {
        pathname: `${location.pathname}`,
        search: `?sort=${this.state.selectedSort}&status=active&status=inactive`,
      } );
    }
    this.handleCheckingFilters();
  }

  componentDidUpdate( {}, prevState ) {
    if (
      this.state.selectedProjects.length !== prevState.selectedProjects.length ||
      this.state.selectedStatus.length !== prevState.selectedStatus.length ||
      this.state.selectedSort !== prevState.selectedSort
    ) {
      this.handleUrlParams();
    }
  }

  handleCheckingFilters() {
    const { location } = this.props;
    const newLocationQuery = parse(location.search, {ignoreQueryPrefix: true});

    /* If these query properties exist, use them and push them into a state which automagically
     * checks what's needed. If a query property has only one item, it's a string so I'm checking
     * for that and turning it into an array with one object. */
    const queryProjects = newLocationQuery.projects ?
      Array.isArray( newLocationQuery.projects ) ? newLocationQuery.projects : [ newLocationQuery.projects ] :
      [ 'all' ];
    const queryStatus =
      newLocationQuery.status ?
        Array.isArray( newLocationQuery.status ) ? newLocationQuery.status : [ newLocationQuery.status ] :
        [ 'active', 'inactive' ];

    this.setState(
      {
        selectedProjects: queryProjects,
        selectedStatus: queryStatus,
        selectedSort: newLocationQuery.sort ? newLocationQuery.sort : 'last-batch',
      }
    );
  }

  handleUrlParams() {
    const { history, location } = this.props;
    const { selectedStatus, selectedProjects, selectedSort } = this.state;
    /* When any of the filters/facets changed, match url params with new changes
     * Whenever new filters or anything added, make sure they get added to query below. */
    history.replace({
      pathname: `${location.pathname}`,
      search: `?sort=${selectedSort}&${stringify({ status: selectedStatus, projects: selectedProjects })}`,
      query: { status: selectedStatus, projects: selectedProjects },
    } );
  }

  handleSelectChain( id ) {
    const projectId = this.props.chains.find( chain => chain.id === id ).projectId;
    const location = `/projects/${projectId}/chains/${id}/monitor`;
    this.props.history.push( location );
  }

  filter( workflow ) {
    const { selectedProjects, selectedStatus } = this.state;
    const projectsFilter = selectedProjects.filter( s => s !== 'all' );
    if ( projectsFilter.length && !projectsFilter.includes( workflow.id_project.toString() ) ) {
      return false;
    }

    let temp = false;
    selectedStatus.forEach( s => {
      if ( s === 'draft' && workflow.launched === null ) {
        temp = true;
      } else if ( statusFilters[ s ].includes( workflow.state ) && workflow.launched !== null ) {
        temp = true;
      }
    } );

    if ( selectedStatus.length > 0 && !temp ) {
      return false;
    }

    if ( !this.state.allCompanyProjects && workflow.id_project_manager.toString() !== this.props.currentUserId.toString() ) {
      return false;
    }
    return true;
  }

  sort( a, b ) {
    const selectedSort = this.state.selectedSort;
    if ( selectedSort === 'last-batch' ) {
      return new Date( b.last_batch ) - new Date( a.last_batch );
    }
    if ( selectedSort === 'last-submission' ) {
      return new Date( b.lastSubmission ) - new Date( a.lastSubmission );
    }
    if ( selectedSort === 'rejection-rate' ) {
      return b.rejectionRate > a.rejectionRate ? 1 : -1;
    }
    if ( selectedSort === 'cost-per-item' ) {
      return b.avgCostPerItem > a.avgCostPerItem ? 1 : -1;
    }
    if ( selectedSort === 'trending-velocity' ) {
      return a.trendingVelocity > b.trendingVelocity ? 1 : -1;
    }
    if ( selectedSort === 'a-z' ) {
      return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    }
    if ( selectedSort === 'z-a' ) {
      return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1;
    }
    throw new Error( `We are not handling ${selectedSort} as a sort` );
  }

  shouldShowProject( project ) {
    const manager = project.id_project_manager.toString() === this.props.currentUserId.toString();
    const allCompany = this.state.allCompanyProjects;
    return allCompany || manager;
  }

  toggleAllCompanyProjects() {
    this.setState( { allCompanyProjects: !this.state.allCompanyProjects } );
  }

  getPanelContent( workflow ) {
    if ( workflow.launched === null ) {
      return (
        <div style={styles.draftContainer}>
          <div style={styles.draftContent}>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 12 }}>
              <Caption color='white' center={true}>
                This workflow is still in draft mode.<br />
                Complete the setup and launch it to<br /> make it active.
              </Caption>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <InlineButton
                handleClick={() => {
                  window.location.href = getWorkflowHref( workflow );
                }}>
                Complete Setup
              </InlineButton>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <WorkflowMetrics workflow={workflow}/>
      );
    }
  }

  handleMenuOption( option, workflow ) {
    switch ( option ) {
      case 'manage':
        window.location.href = `/workflows/${workflow.id}`;
        break;
      case 'archive':
        this.props.dispatch( toggleModal( ARCHIVE_WORKFLOW, { workflowId: workflow.id, archive: true } ) );
        break;
      case 'batchNote':
        this.props.dispatch( toggleModal( BATCH_NOTIFICATIONS, {
          workflowId: workflow.id,
          enableOverlayClick: true,
          batchNotificationStatus: workflow.batchNotification,
        } ) );
        break;
      default:
        break;
    }
  }

  render() {
    const { workflows, chains, projects, location, fetchingProjects } = this.props;
    const { selectedProjects, selectedSort, selectedStatus, isFetchingWorkflows } = this.state;

    const newLocationQuery = parse(location.search, {ignoreQueryPrefix: true});

    const viewableProjects = projects.filter( this.shouldShowProject ).sort( sortByCreatedAt );
    const displayedWorkflows = workflows.filter( this.filter ).sort( this.sort );

    const noWorkflowTextHeader = 'It looks like you do not have any workflows.';
    const noWorkflowTextBody = 'Create a new workflow by clicking the button above or adjust your ' +
      'filters in the rail';

    if(isFetchingWorkflows) {
      return <LoadState label="Loading Workflows" top={50} />;
    }

    return (
      <DocumentTitle title="Workflows | OneSpace Project Center">
        <div className="t-workflows">
          {/* Help Panel */}
          <HelpPanel
            pageTitle="Workflows"
            links={helpPanelLinks}
            pageDescription={pageDescription}
          />
          <Wrapper>
            <div style={styles.headerContainer}>
              <Heading color="dark">Workflows</Heading>
              <div style={{ marginBottom: 12 }}>
                <Filter
                  name="Chain"
                  alignMenu="right"
                  itemType="Chain Name"
                  searchable
                  shadow={true}
                  border={true}
                  options={chains.filter(chain => !chain.archived).sort(newestFirst).map(chain => ({
                  label: chain.name,
                  value: chain.id
                  }))}
                  handleSelect={this.handleSelectChain}
                />
              </div>
            </div>
            <div style={styles.workflowCount}>
              <Subheading color="dark">{displayedWorkflows.length} Workflows</Subheading>
              <CreateWorkflow
                projects={projects}
                handleCreateProject={
                  ( projectName, projectDesc ) => this.createProject( projectName, projectDesc )
                }/>
            </div>
            <div style={{ display: 'flex' }}>
              {fetchingProjects ?
                <div style={{ flex: 1, paddingRight: 8, maxWidth: '33%' }}>
                  <LoadState/>
                </div> :
                <div style={{ flex: 1, paddingRight: 8, maxWidth: '33%' }}>
                  <Facet
                    facets={[
                      {
                        name: 'Project',
                        multiple: true,
                        itemType: 'Project Name',
                        options: [
                          {
                            label: 'View All Company Projects',
                            fixed: true,
                            value: 'all',
                            outside: true,
                            bold: true,
                          },
                          ...viewableProjects.map( project => ({
                            label: project.name,
                            value: project.id.toString(),
                          }) )
                        ],
                        expanded: !!newLocationQuery.projects,
                        handleSelect: projectId => {
                          const selected = [ ...selectedProjects ];
                          let i;
                          (i = selectedProjects.indexOf( projectId )) < 0 ?
                            selected.push( projectId ) : selected.splice( i, 1 );
                          this.setState( { selectedProjects: selected } );
                          if ( projectId === 'all' ) {
                            this.toggleAllCompanyProjects();
                          }
                        },
                        selected: selectedProjects,
                      },
                      {
                        name: 'Status',
                        hideSearch: true,
                        multiple: true,
                        options: [
                          { label: 'Active', value: 'active' },
                          { label: 'Inactive', value: 'inactive' },
                          { label: 'Draft', value: 'draft' },
                          { label: 'Archived', value: 'archived' },
                        ],
                        expanded: !!newLocationQuery.status,
                        handleSelect: selected => {
                          const statuses = [ ...selectedStatus ];
                          let i;
                          (i = selectedStatus.indexOf( selected )) < 0 ?
                            statuses.push( selected ) : statuses.splice( i, 1 );
                          this.setState( { selectedStatus: statuses } );
                        },
                        selected: selectedStatus,
                      }
                    ]}
                  />
                </div>
              }
              <div style={styles.metricsContainer}>
                <div style={styles.sortFilter}>
                  <Filter
                    name="Sort"
                    options={
                      [
                        { label: 'Last Batch', value: 'last-batch' },
                        { label: 'Last Submission', value: 'last-submission' },
                        { label: 'Highest Rejection Rate', value: 'rejection-rate' },
                        { label: 'Highest Cost Per Item', value: 'cost-per-item' },
                        { label: 'Lowest Trending Velocity', value: 'trending-velocity' },
                        { label: 'A to Z', value: 'a-z' },
                        { label: 'Z to A', value: 'z-a' }
                      ]
                    }
                    handleSelect={ selectedSort => this.setState( { selectedSort } ) }
                    selected={selectedSort}/>
                </div>
                {workflows.filter(this.filter).sort(this.sort).length === 0 ?
                  <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 24, flexDirection: 'column' }}>
                    <ManagerAttention color="dark" center={true}>{noWorkflowTextHeader}</ManagerAttention>
                    <Caption color="dark" center={true}>{noWorkflowTextBody}</Caption>
                  </div>
                  :
                  displayedWorkflows.map( workflow =>
                    <div key={workflow.id} style={{ marginTop: 16 }}>
                      <Panel
                        title={workflow.name}
                        saveTitle={name => this.props.dispatch( updateWorkflowName( name, workflow.id ) )}
                        validateWorkflowName={name => this.props.dispatch( validateWorkflowName( name ) )}
                        options={
                          (workflow.state === 'archived' || workflow.launched === null) ?
                            []
                            :
                            [
                              { label: 'Manage Workflow', value: 'manage' },
                              { label: 'Edit Name', value: 'edit' },
                              { label: 'Archive Workflow', value: 'archive' },
                              {
                                label: `Batch Notifications: ${workflow.batchNotification ? 'On' : 'Off'}`,
                                value: 'batchNote'
                              }
                            ]
                        }
                        handleSelectOption={option => {
                          this.handleMenuOption( option, workflow );
                        }}>
                        {this.getPanelContent( workflow )}
                      </Panel>
                    </div>
                  )}

              </div>
            </div>
          </Wrapper>
        </div>
      </DocumentTitle>
    );
  }
}


const styles = {
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 24,
    alignItems: 'flex-end',
  },
  metricsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    paddingLeft: 8,
  },
  workflowCount: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 16,
    height: 75,
    background: colors.background,
    paddingLeft: 16,
    paddingRight: 16,
    boxSizing: 'border-box',
    borderRadius: 5,
    alignItems: 'center'
  },
  sortFilter: {
    border: `1px solid ${colors.border}`,
    borderLeft: 'none',
    borderRight: 'none',
    padding: '16px 0px',
  },
  draftContainer: {
    height: 200,
    display: 'flex',
    flex: 6,
    background: colors.light,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8
  },
  draftContent: {
    background: colors.dark,
    height: 160,
    width: 300,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 5,
    flexDirection: 'column',
  },
};

const select = ( state, rr ) => ({
  currentUserId: stateHelpers.getUser( state ).id,
  projects: objectToArray( state.projects.items ).filter( project => project.id ),
  workflows: objectToArray( state.workflows.items ),
  chains: objectToArray( state.chains.items ).filter( chain => chain.launched ),
  fetchingProjects: state.projects.isFetching,
  history: rr.history,
});
export default connect( select )( Workflows );
