import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import onClickOutside from 'react-onclickoutside';

import { Caption } from './type';
import colors from '../styles/colors';

/**
 * Menu
 * Menu with a list of options
 *   - Supports clicking outside to update closed (props.handleClose)
 */
class Menu extends Component {
  handleClickOutside(e) {
    if (this.props.handleClose) {
      this.props.handleClose(e);
    }
  }

  render() {
    return (
      <div className={css(styles.main)} style={{width: this.props.width}}>
        {this.props.options.map((option, i) => option.href ?
          <a key={i} className={css(styles.link)} href={option.href}>
            <Option label={option.label} last={i === this.props.options.length - 1}/>
          </a> :
          <div
            style={{ opacity: !option.disabled ? 1 : 0.5 }}
            key={i}
            onClick={() => !option.disabled ? this.props.handleSelect(option.value) : () => {}}
          >
            <Option label={option.label} last={i === this.props.options.length - 1}/>
          </div>
        )}
      </div>
    );
  }
}

const Option = ({ label, last }) => (
  <Caption
    color="darker"
    hoverColor="darker"
    className={css(styles.option, !last && styles.optionDivider)}>
    {label}
  </Caption>
);
Option.displayName = 'Menu-Option';

/* Styles */
const styles = StyleSheet.create({
  main: {
    minWidth: 180,
    background: 'white',
    borderRadius: 5,
    boxShadow: '0 5px 10px rgba(0,0,0,.2)',
    border: `1px solid ${colors.border}`
  },
  link: {
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'none',
    }
  },
  option: {
    minHeight: 48,
    paddingLeft: 16,
    paddingRight: 16,
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    cursor: 'pointer',
    ':hover': {
      background: '#EEEEEE'
    }
  },
  optionDivider: {
    borderBottom: `1px solid ${colors.divider}`,
  },
});

/* PropTypes */
Menu.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]).isRequired,
    href: PropTypes.string,
    value: PropTypes.string, // Required if handleClick is passed
  })).isRequired,
  handleClose: PropTypes.func,
  handleSelect: PropTypes.func, // Only called for non-href options
};

Menu.defaultProps = {
  options: [],
};

export default onClickOutside(Menu);
