import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';

import colors from '../styles/colors';
import text from '../styles/text';
import requiredIf from '../utils/requiredIf';
import IconCount from '../components/IconCount';
import Logo from '../components/Logo';
import SingleInputForm from '../components/SingleInputForm';
import Menu from '../components/Menu';

function getMenuOptions(fullAdmin, apiAdmin) {
  const defaultOptions = [
    { label: 'Manage Account', href: '/account' },
    { label: 'Profile', href: '/admin-profile' },
    { label: 'Help & Support', href: 'https://onespace.helpdocs.com' },
    { label: 'Logout', href: '/logout' }
  ];

  const fullAdminOptions = [
    { label: 'Company Settings', href: '/company' },
    { label: 'Plan & Funding', href: '/company/plan' }
    ];

  const apiAdminOptions = [
    { label: 'API Administration', href: '/company/api-admin' },
  ];

  return [
    ...(apiAdmin ? apiAdminOptions : []),
    ...(fullAdmin ? fullAdminOptions : []),
    ...defaultOptions
  ];
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      search: ''
    };
  }

  render() {
    const { userName, userPicture, fullAdmin, apiAdmin, handleSubmitSearch, userRole, companyName } = this.props;
    const { menuOpen, search } = this.state;
    return (
      <div className={css(styles.topBar)}>
        {/* Left side of header */}
        <div className={css(styles.side)}>
          <Link to="/">
            <Logo primary={colors.primary()} secondary={colors.secondary()} showText={!userName}/>
          </Link>
          {userName &&
          <div id="global-search" style={{ marginLeft: 32, width: 436 }}>
            <SingleInputForm
              placeholder='Find Contributors'
              icon='search'
              value={search}
              handleChange={search => this.setState({ search })}
              handleSubmit={() => handleSubmitSearch(search)}
            />
          </div>
          }
        </div>

        {/* Right side of header */}
        {userName &&
        <div className={css(styles.side)}>
          {userRole === 'onespace-support' &&
            <div className={css(text.label)} style={{marginRight: 16}} >
              {companyName}
            </div>
          }
          <a href="/inbox" style={{ textDecoration: 'none', marginRight: 16 }}>
            <IconCount icon="envelope" count={0}/>
          </a>
          <div style={{ height: 16, background: '#dddbdb', width: 1, marginRight: 4 }}/>
          <div
            className={css(styles.accountButton) + ' account-button'}
            onClick={() => this.setState({ menuOpen: !menuOpen })}>
            <div className={css(text.label)}>{userName}</div>
            <span
              style={{
                backgroundImage: `url("${userPicture || '/no-user-image.gif'}")`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: 50,
                height: 50,
                marginLeft: 12,
                marginRight: 8,
                borderRadius: '50%',
                border: '3px solid #f4f5f5',
              }}
            />
            <div className={css(styles.chevron, menuOpen && styles.chevronFlip)}>
              <i className="fa fa-chevron-down"/>
            </div>
          </div>
          {menuOpen &&
          <div className={css(styles.menu)}>
            <Menu
              handleClose={(e) => {
                let el = e.target;
                let isButton = false;

                while (el.parentNode && !isButton) {
                  el.className.indexOf('accountButton') >= 0 ? isButton = true : el = el.parentNode;
                }
                if (!isButton) {
                  this.setState({ menuOpen: false });
                }
              }}
              options={getMenuOptions(fullAdmin, apiAdmin)}
            />
          </div>
          }
        </div>
        }
      </div>
    );
  }
}

const styles = StyleSheet.create({
  topBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    height: 90,
    width: '100%',
    paddingLeft: 15,
    paddingRight: 15,
    boxSizing: 'border-box',

    background: 'white',
    borderBottom: '1px solid #ebebeb'
  },
  side: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  accountButton: {
    height: 48,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 12,
    paddingRight: 12,
    cursor: 'pointer',
  },
  chevron: {
    fontSize: 16,
    color: colors.dark,
    transition: 'all 0.2s ease',
    marginRight: 30,
  },
  chevronFlip: {
    transform: 'rotate(180deg)'
  },
  menu: {
    position: 'absolute',
    top: 50,
    right: 0,
    zIndex: 2
  }
});


Header.propTypes = {
  userName: PropTypes.string,
  userPicture: PropTypes.string,
  messageCount: requiredIf('userName', 'number'),
  handleSubmitSearch: PropTypes.func,
  fullAdmin: PropTypes.bool,
};

export default Header;
