import update from 'immutability-helper';

import fetch, { checkStatus, parseJSON } from '../utils/fetch';
import { uploadChainBatch } from '../actions/projects';
import {
  FETCH_CHAINS_REQUEST,
  FETCH_CHAINS_SUCCESS,
  FETCH_CHAINS_FAILURE,
  UPDATED_NODE,
  REMOVED_NODES,
  CREATED_NODE,
  CANCELED_BATCH,
  FETCH_CHAIN_REQUEST,
  FETCH_CHAIN_SUCCESS,
  FETCH_CHAIN_FAILURE,
  FETCH_CHAIN_WORKFLOWS_REQUEST,
  FETCH_CHAIN_WORKFLOWS_SUCCESS,
  FETCH_CHAIN_WORKFLOWS_FAILURE,
  CREATE_CHAIN_REQUEST,
  CREATE_CHAIN_SUCCESS,
  CREATE_CHAIN_FAILURE,
  UPDATE_CHAIN_REQUEST,
  UPDATE_CHAIN_SUCCESS,
  UPDATE_CHAIN_FAILURE,
  LAUNCH_CHAIN_REQUEST,
  LAUNCH_CHAIN_SUCCESS,
  LAUNCH_CHAIN_FAILURE,
  FETCH_CHAIN_BATCHES_REQUEST,
  FETCH_CHAIN_BATCHES_SUCCESS,
  FETCH_CHAIN_BATCHES_FAILURE,
  IMPORT_CHAIN_BATCH_REQUEST,
  IMPORT_CHAIN_BATCH_SUCCESS,
  IMPORT_CHAIN_BATCH_FAILURE,
  FETCH_CHAIN_BATCH_REQUEST,
  FETCH_CHAIN_BATCH_SUCCESS,
  FETCH_CHAIN_BATCH_FAILURE,
  RELEASE_CHAIN_BATCH_REQUEST,
  RELEASE_CHAIN_BATCH_SUCCESS,
  RELEASE_CHAIN_BATCH_FAILURE,
  UPDATE_CHAIN_BATCH_REQUEST,
  UPDATE_CHAIN_BATCH_SUCCESS,
  UPDATE_CHAIN_BATCH_FAILURE,
  EXPORT_CHAIN_BATCH_REQUEST,
  EXPORT_CHAIN_BATCH_SUCCESS,
  EXPORT_CHAIN_BATCH_FAILURE,
  CONNECT_NODES,
  CREATE_PLUGININSTANCE_REQUEST,
  CREATE_PLUGININSTANCE_SUCCESS,
  CREATE_PLUGININSTANCE_FAILURE,
  DELETE_PLUGININSTANCE_REQUEST,
  DELETE_PLUGININSTANCE_SUCCESS,
  DELETE_PLUGININSTANCE_FAILURE,
  FETCH_POPULATENEWINSTANCE_REQUEST,
  FETCH_POPULATENEWINSTANCE_SUCCESS,
  FETCH_POPULATENEWINSTANCE_FAILURE,
  UPDATE_PLUGININSTANCE,
  CANCEL_UNSAVEDPLUGININSTANCE,
  FETCH_IS_CHAIN_ACTIVE_REQUEST,
  FETCH_IS_CHAIN_ACTIVE_SUCCESS,
  FETCH_IS_CHAIN_ACTIVE_FAILURE,
  FETCH_CHAIN_METRICS_REQUEST,
  FETCH_CHAIN_METRICS_SUCCESS,
  FETCH_CHAIN_METRICS_FAILURE,
  PAUSE_CHAIN_BATCH_REQUEST,
  PAUSE_CHAIN_BATCH_SUCCESS,
  PAUSE_CHAIN_BATCH_FAILURE,
  RESUME_CHAIN_BATCH_REQUEST,
  RESUME_CHAIN_BATCH_SUCCESS,
  RESUME_CHAIN_BATCH_FAILURE,
  ARCHIVE_CHAIN_REQUEST,
  ARCHIVE_CHAIN_SUCCESS,
  ARCHIVE_CHAIN_FAILURE,
  UNARCHIVE_CHAIN_REQUEST,
  UNARCHIVE_CHAIN_SUCCESS,
  UNARCHIVE_CHAIN_FAILURE,
  UPDATE_CHAIN_BATCH_NOTIFICATION_REQUEST,
  UPDATE_CHAIN_BATCH_NOTIFICATION_SUCCESS,
  UPDATE_CHAIN_BATCH_NOTIFICATION_FAILURE,
} from '../constants/actionTypes';

import makeActionCreator from '../utils/_makeActionCreator';

export const formatBatch = batch => Object.assign({}, batch, {
  filename: batch.filename || batch.original_filename,
  created_at: batch.created || batch.created_at,
});

export const standardizeWorkflow = (workflow, projectId) => {
  workflow.id = workflow.id.toString();
  workflow.id_project = projectId.toString();
  workflow.name = workflow.label || workflow.name;
  return workflow;
};

export function fetchChains(includeArchived) {
  return {
    types: [FETCH_CHAINS_REQUEST, FETCH_CHAINS_SUCCESS, FETCH_CHAINS_FAILURE],
    fetchRoute: '/api/chains?includeArchived='+ includeArchived,
    getFormattedResponse: ({ chains }) => ({ chains: chains.workflowChains }),
  };
}

export function fetchChain(projectId, chainId) {
  return {
    types: [FETCH_CHAIN_REQUEST, FETCH_CHAIN_SUCCESS, FETCH_CHAIN_FAILURE],
    fetchRoute: `/api/projects/${projectId}/chains/${chainId}`,
    getFormattedResponse: ({ workflowChain }) => ({ chain: workflowChain }),
  };
}

export function fetchChainWorkflows(projectId, id) {
  return {
    types: [FETCH_CHAIN_WORKFLOWS_REQUEST, FETCH_CHAIN_WORKFLOWS_SUCCESS, FETCH_CHAIN_WORKFLOWS_FAILURE],
    fetchRoute: `/api/projects/${projectId}/chains/${id}/workflow-stats`,
    getFormattedResponse: ({ workflows, plugins }) => ({
      workflows: workflows.map(workflow => standardizeWorkflow(workflow, projectId)),
      plugins,
    })
  };
}

export function fetchChainMetrics(chainId) {
  return {
    types: [FETCH_CHAIN_METRICS_REQUEST, FETCH_CHAIN_METRICS_SUCCESS, FETCH_CHAIN_METRICS_FAILURE],
    fetchRoute: `/api/chain/${chainId}/metrics`,
    payload: { chainId },
    getFormattedResponse: ({ metrics }) => ({ metrics }),
  };
}

export function createChain(chain) {
  return {
    types: [CREATE_CHAIN_REQUEST, CREATE_CHAIN_SUCCESS, CREATE_CHAIN_FAILURE],
    fetchRoute: `/api/projects/${chain.projectId}/chains`,
    fetchMethod: 'post',
    fetchBody: chain,
    getFormattedResponse: ({ workflowChain }) => ({ chain: workflowChain }),
  };
}

export function updateChain(id, updates) {
  return {
    types: [UPDATE_CHAIN_REQUEST, UPDATE_CHAIN_SUCCESS, UPDATE_CHAIN_FAILURE],
    fetchRoute: getState => {
      const previousChain = getState().chains.items[id];
      return `/api/projects/${previousChain.projectId}/chains/${previousChain.id}`;
    },
    fetchMethod: 'put',
    fetchBody: getState => {
      const previousChain = getState().chains.items[id];
      return Object.assign({}, previousChain, updates);
    },
    payload: { id },
    getFormattedResponse: ({ workflowChain }) => ({ chain: workflowChain }),
  };
}

export function launchChain(id) {
  return {
    types: [LAUNCH_CHAIN_REQUEST, LAUNCH_CHAIN_SUCCESS, LAUNCH_CHAIN_FAILURE],
    fetchRoute: getState => {
      const chain = getState().chains.items[id];
      return `/api/projects/${chain.projectId}/chains/${chain.id}/launch`;
    },
    fetchMethod: 'post',
    payload: { id },
    getFormattedResponse: ({ success, result, message }) => {
      if (!success) {
        throw new Error(`Unable to launch: ${message}`);
      }
      return ({ chain: result.chain, success });
    }
  };
}

export function isChainActive(chainId) {
  return {
    types: [FETCH_IS_CHAIN_ACTIVE_REQUEST, FETCH_IS_CHAIN_ACTIVE_SUCCESS, FETCH_IS_CHAIN_ACTIVE_FAILURE],
    fetchRoute: `/api/chain/${chainId}/active`,
    fetchMethod: 'get',
    getFormattedResponse: ({status}) => ({status, chainId}),
  };
}

export function archiveUnarchiveChain(chainId, archive) {
  return {
    types: archive ?
      [ARCHIVE_CHAIN_REQUEST, ARCHIVE_CHAIN_SUCCESS, ARCHIVE_CHAIN_FAILURE] :
      [UNARCHIVE_CHAIN_REQUEST, UNARCHIVE_CHAIN_SUCCESS, UNARCHIVE_CHAIN_FAILURE],
    fetchRoute: `/api/chain/${chainId}/archive`,
    payload: { chainId },
    fetchBody: { archive },
    fetchMethod: 'post',
  };
}

////////////////////////////////////////
//             Chain Nodes            //
////////////////////////////////////////

export const addNodeToChain = makeActionCreator(CREATED_NODE, 'chainId', 'node', 'route');

export const connectNodes = makeActionCreator(CONNECT_NODES, 'chainId', 'source', 'route');

export const removeNodesFromChain = makeActionCreator(REMOVED_NODES, 'chainId', 'nodes');

export const updatePluginInstance = makeActionCreator(UPDATE_PLUGININSTANCE, 'propertyName', 'update', 'index', 'instanceId', 'pluginId', 'thisChainId');

export const cancelPluginInstance = makeActionCreator(CANCEL_UNSAVEDPLUGININSTANCE, 'index', 'thisChainId', 'pluginId');

export function updateNode(id, updates) {
  return (dispatch, getState) => {
    const chains = getState().chains.items;
    const parentChain = chains[Object.keys(chains).find(chainId => chains[chainId].nodes.find(node => node.id === id))];
    const previousNode = parentChain.nodes.find(node => node.id === id);

    if (!previousNode) {
      throw new Error('Source node not found');
    }
    dispatch({
      type: UPDATED_NODE,
      chainId: parentChain.id,
      node: update(previousNode, { $merge: updates })
    });
  };
}

////////////////////////////////////////
//             Chain Batches          //
////////////////////////////////////////

export function fetchChainBatches(projectId, chainId, page = 1) {
  return {
    types: [FETCH_CHAIN_BATCHES_REQUEST, FETCH_CHAIN_BATCHES_SUCCESS, FETCH_CHAIN_BATCHES_FAILURE],
    fetchRoute: `/api/projects/${projectId}/chains/${chainId}/batches?page=${page}`,
    payload: { chainId, projectId },
    getFormattedResponse: ({ batches, pageCount }) => ({ batches: batches.map(formatBatch), pageCount }),
  };
}

export function importChainBatch(projectId, chainId, batch) {
  return dispatch => {
    const tempId = Date.now();
    dispatch({ type: IMPORT_CHAIN_BATCH_REQUEST, chainId, file: batch, tempId });
    return dispatch(uploadChainBatch(batch, projectId))
      .then((data) => {
        const s3_filename = data.filename;
        return dispatch({
          types: [IMPORT_CHAIN_BATCH_REQUEST, IMPORT_CHAIN_BATCH_SUCCESS, IMPORT_CHAIN_BATCH_FAILURE],
          fetchRoute: `/api/projects/${projectId}/chains/${chainId}/batches`,
          fetchMethod: 'post',
          fetchBody: { original_filename: batch.name, s3_filename },
          payload: {
            file: batch,
            projectId,
            chainId,
            tempId,
          },
          getFormattedResponse: ({ batch }) => {
            // ChainId is not coming back on creation
            batch.chainId = chainId;
            batch.filename = batch.original_filename;
            return { batch };
          }
        }).then((err) => {
          if (!err.success && err.dataType === 'InvalidChain') {
            return dispatch(fetchChain(projectId, chainId));
          }
        });
      }).catch(xhr => xhr);
  };
}

export function fetchChainBatch(chainId, batchId) {
  return {
    types: [FETCH_CHAIN_BATCH_REQUEST, FETCH_CHAIN_BATCH_SUCCESS, FETCH_CHAIN_BATCH_FAILURE],
    fetchRoute: `/api/chains/${chainId}/batch/${batchId}`,
  };
}

export function releaseChainBatchItems(batchId, row_count) {
  return {
    types: [RELEASE_CHAIN_BATCH_REQUEST, RELEASE_CHAIN_BATCH_SUCCESS, RELEASE_CHAIN_BATCH_FAILURE],
    fetchRoute: (getState) => {
      const { batches, chains } = getState();
      const batch = batches.items[batchId];
      const chain = chains.items[batch.chainId];
      return `/api/projects/${chain.projectId}/chains/${chain.id}/batch/${batch.id}/release`;
    },
    fetchMethod: 'post',
    fetchBody: { row_count },
    payload: { batchId },
  };
}

export function cancelChainBatch(id) {
  return (dispatch, getState) => new Promise((resolve) => {
    const batch = getState().batches.items[id];
    const chain = getState().chains.items[batch.chainId];
    const projectId = chain.projectId.toString();
    const chainId = chain.id.toString();
    fetch(`/api/projects/${projectId}/chains/${chainId}/batch/${id}/cancel`, 'post')
      .then(() => {
        resolve();
        dispatch({
          type: CANCELED_BATCH,
          batchId: id,
        });
        // pollBatch(chain.id, id);
      });
  });
}

export function pauseChainBatchItems(chainId, batchId) {
  return {
    types: [PAUSE_CHAIN_BATCH_REQUEST, PAUSE_CHAIN_BATCH_SUCCESS, PAUSE_CHAIN_BATCH_FAILURE],
    fetchRoute: `/api/chains/${chainId}/batch/${batchId}/pause`,
    fetchMethod: 'post',
    payload: {batchId},
  };
}

export function resumeChainBatchItems(chainId, batchId) {
  return {
    types: [RESUME_CHAIN_BATCH_REQUEST, RESUME_CHAIN_BATCH_SUCCESS, RESUME_CHAIN_BATCH_FAILURE],
    fetchRoute: `/api/chains/${chainId}/batch/${batchId}/resume`,
    fetchMethod: 'post',
    payload: {batchId},
  };
}

export function updateChainBatchExportSettings(projectId, chainId, batchId, settings) {
  return {
    types: [UPDATE_CHAIN_BATCH_REQUEST, UPDATE_CHAIN_BATCH_SUCCESS, UPDATE_CHAIN_BATCH_FAILURE],
    fetchRoute: `/api/projects/${projectId}/chains/${chainId}/batch/${batchId}/export-settings`,
    fetchMethod: 'put',
    fetchBody: { settings },
    payload: { projectId, chainId, batchId },
  };
}

export function exportChainBatch(projectId, chainId, batchId, settings, exportAll) {
  return {
    types: [EXPORT_CHAIN_BATCH_REQUEST, EXPORT_CHAIN_BATCH_SUCCESS, EXPORT_CHAIN_BATCH_FAILURE],
    fetchRoute: `/api/projects/${projectId}/chains/${chainId}/batch/${batchId}/export`,
    fetchMethod: 'post',
    fetchBody: { settings, exportAll },
    payload: { projectId, chainId, batchId },
  };
}

export function createPluginInstance(plugin, chainId, index) {
    return {
      types: [CREATE_PLUGININSTANCE_REQUEST, CREATE_PLUGININSTANCE_SUCCESS, CREATE_PLUGININSTANCE_FAILURE],
      fetchRoute: `/manage/api/chains/${chainId}/plugins/${plugin.id}/instance`,
      fetchMethod: plugin.pluginId ? 'put' : 'post',
      fetchBody: plugin,
      payload: {chainId, index},
      getFormattedResponse: ( res ) => {
        return {instance: res.instance};
      },
    };
}

export function deletePluginInstance(instanceId, chainId, instanceIndex) {
    return {
      types: [DELETE_PLUGININSTANCE_REQUEST, DELETE_PLUGININSTANCE_SUCCESS, DELETE_PLUGININSTANCE_FAILURE],
      fetchRoute: `/manage/api/chains/${chainId}/plugins/${instanceId}`,
      fetchMethod: 'delete',
      payload: {chainId, instanceIndex},
      getFormattedResponse: ( res ) => {
        return {success: res.success};
      },
    };
}

export function populateNewInstance(id) {
    return {
      types: [FETCH_POPULATENEWINSTANCE_REQUEST, FETCH_POPULATENEWINSTANCE_SUCCESS, FETCH_POPULATENEWINSTANCE_FAILURE],
      fetchRoute: `/manage/api/chains/plugins/${id}`,
      getFormattedResponse: ( chainPlugin ) => {
        chainPlugin.plugin.versions = chainPlugin.versions || [];
        return {
            chainPlugin: chainPlugin.plugin,
        };
      },
    };
}

export function validateChainName(chainId, chainName) {
  return () => new Promise((resolve, reject) => {
    fetch(`/api/chain/${chainId}/validate-name?name=${chainName}`, 'get')
      .then(checkStatus)
      .then(parseJSON)
      .then(res => {
        resolve(res);
      })
      .catch(reject);
  });
}

export function chainBatchNotification(chainId, batchNotification) {
  return {
    types: [ UPDATE_CHAIN_BATCH_NOTIFICATION_REQUEST, UPDATE_CHAIN_BATCH_NOTIFICATION_SUCCESS, UPDATE_CHAIN_BATCH_NOTIFICATION_FAILURE ],
    fetchRoute: `/api/chain/${chainId}/batch-notification`,
    fetchMethod: 'put',
    fetchBody: { batchNotification },
    payload: { chainId, batchNotification },
  };
}
