import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import onClickOutside from 'react-onclickoutside';

import colors from '../styles/colors';
import InlineSearch from './InlineSearch';
import { Caption, Label } from './type';

/**
 * FacetList
 */
class FacetList extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      filter: '',
    };
    this.setFilter = this.setFilter.bind( this );
    this.checkKey = this.checkKey.bind( this );
  }

  handleClickOutside( e ) {
    if ( this.props.handleClose ) {
      this.props.handleClose( e );
    }
  }

  checkKey( { key } ) {
    const { selectedItemIndex } = this.state;
    if ( key === 'ArrowUp' ) {
      return this.setSelectedItem( selectedItemIndex - 1 );
    }
    if ( key === 'ArrowDown' ) {
      return this.setSelectedItem( selectedItemIndex + 1 );
    }
    if ( key === 'Enter' ) {
      return this.props.handleSelect( this.props.items[ selectedItemIndex ].value );
    }
  }

  setSelectedItem( selectedItemIndex = this.state.selectedItemIndex ) {
    const itemsLength = this.getItemsFiltered().length;

    if ( selectedItemIndex >= itemsLength ) {
      selectedItemIndex = 0;
    }
    if ( selectedItemIndex < 0 ) {
      selectedItemIndex = itemsLength - 1;
    }

    this.setState( { selectedItemIndex } );
  }

  setFilter( filter ) {
    this.setState( { filter }, this.setSelectedItem );
  }

  getItemsFiltered() {
    const filter = this.state.filter;
    const doesItemIncludeFilter = ( item ) => {
      if ( item.outside ) {
        return false;
      }
      return item.fixed || (item.staticLabel || item.label).toString().toLowerCase().includes( filter.toLowerCase() );
    };
    return this.props.items.filter( doesItemIncludeFilter );
  }

  render() {
    const { hideSearch, handleSelect } = this.props;
    const { filter, selectedItemIndex } = this.state;
    const outsideItems = this.props.items.filter(item => item.outside);
    const itemsFiltered = this.getItemsFiltered();

    return (
      <div className={css(styles.facetList)}>

        {/* Search Bar */}
        {!hideSearch &&
        <div className={css(styles.search)}>
          <InlineSearch
            value={filter}
            handleChange={this.setFilter}
            handleKeyDown={this.checkKey}
          />
        </div>
        }

        {/* Outside Items */}
        <div>
          {outsideItems.map((item, i) => (
            <Item
              key={item.value}
              label={item.label}
              outside={item.outside}
              selected={selectedItemIndex === i}
              handleClick={() => handleSelect(item.value)}
              bold={item.bold}
            />
          ))}
        </div>

        {outsideItems.length > 0 &&
          <div className={css(styles.outsideBottomBorder)}></div>
        }

        {/* Items */}
        <div className={css(styles.items)}>
          {itemsFiltered.map( ( item, i ) => (
            <Item
              key={item.value}
              label={item.label}
              outside={item.outside}
              selected={selectedItemIndex === i}
              handleClick={() => handleSelect(item.value)}
            />
          ) )}
          {(!itemsFiltered.filter( item => !item.fixed ).length && filter) &&
          <Item
            label={`No items matching: ${filter}`}
            handleClick={() => this.setFilter('')}
          />
          }
          {(!this.props.items.length) && <Item label={`No items`} handleClick={() => {}}/>}
        </div>
      </div>
    );
  }
}
FacetList.displayName = 'FacetList';

const Item = ( { label, selected, outside, handleClick, bold } ) => (
  <div
    className={css(styles.item, selected && styles.selectedItemIndex, outside && styles.outsideItem)}
    onClick={handleClick}
    >
    {bold ?
      <Label isLink={true} hoverColor="primary">{label}</Label>
      :
      <Caption isLink={true} hoverColor="primary">{label}</Caption>
    }

  </div>
);
Item.displayName = 'ItemList-Item';

/* Styles */
const styles = StyleSheet.create( {
  facetList: {
    padding: '8px 4px',
  },
  search: {
    border: `2px solid ${colors.border}`,
    borderRadius: 4,
    margin: '0 16px 8px 16px',
  },
  items: {
    background: 'white',
    borderRadius: 4,
    maxHeight: 400,
    overflowY: 'auto',
    overflowX: 'auto',
  },
  border: {
    border: `2px solid ${colors.border}`,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    height: 32,
    paddingLeft: 16,
    paddingRight: 16,
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    ':hover': {
      background: colors.divider,
    }
  },
  selectedItemIndex: {
    background: colors.divider,
  },
  outsideItem: {
    height: 56,
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: -8,
    background: 'white',
    ':hover': {
      background: 'white',
    }
  },
  listItemType: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    paddingLeft: 16,
    paddingRight: 16,
    border: `2px solid ${colors.border}`,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  outsideBottomBorder: {
    borderBottom: `1px solid ${colors.border}`,
    margin: '0 16px 16px 16px'
  }
} );

/* PropTypes */
FacetList.defaultProps = {
  itemType: 'Options',
};

FacetList.propTypes = {
  items: PropTypes.arrayOf( PropTypes.shape( {
    label: PropTypes.node.isRequired,
    value: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ).isRequired,
    fixed: PropTypes.bool, // If true, it won't be filtered out
    outside: PropTypes.bool, // If true, it will display at the top
    bold: PropTypes.bool, //If true, outside item will use Label type else caption
  } ) ).isRequired,
  itemType: PropTypes.string,
  hideSearch: PropTypes.bool,
  handleSelect: PropTypes.func.isRequired,
};

export default onClickOutside( FacetList );
