import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Label } from '../../components/type';
import IconButton from '../../components/IconButton';
import colors from '../../styles/colors';
import RuleSetC from './RuleSet';
import Rule from './Rule/index';

class RuleSet extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { ruleSet, ruleLHSFields, index, deletable, isLocked } = this.props;
    const rules = ruleSet.getRules();
    return (
      <div className='t-rule-set' style={styles.ruleSet}>
        {/* Rule number display */}
        <div style={styles.ruleSetHeading}>
          <Label color="grey" uppercase={true}>Rule {index + 1}</Label>
        </div>

        {/* List of rules */}
        {rules.map((rule, i) => (
          <div key={i} style={styles.ruleWrap}>
            <Rule
              deletable={deletable || rules.length > 1}
              isLocked={isLocked}
              rule={rule}
              operation={i === 0 ? 'IF' : ruleSet.getOperation()}
              handleChangeOperation={
                i > 1 ? false : operation => ruleSet.setOperation(operation)
              }
              LHSFields={ruleLHSFields}
              handleRemove={() => ruleSet.removeRule(i)}
              disabledRHS={rule.isComparisonBlank()} />
          </div>
        ))}

        {/* Adds another rule to the ruleSet */}
        { !isLocked &&
        <div style={styles.addRule}>
          <IconButton icon="plus-square" handleClick={() => ruleSet.addRule()}>Add Condition</IconButton>
        </div>
        }
      </div>
    );
  }
}

const styles = {
  ruleSet: {
    padding: '30px 0',
    marginTop:12,
    marginBottom: 30,
    borderTop: `2px solid ${colors.border}`,
    borderBottom: `2px solid ${colors.border}`,
  },
  ruleWrap: {
    margin: '4px 0'
  },
  ruleSetHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  addRule: {
    marginTop: 16,
  },
};

/* PropTypes */
RuleSet.propTypes = {
  ruleSet: PropTypes.instanceOf(RuleSetC),
};

export default RuleSet;
