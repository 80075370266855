import React, { Component } from 'react';
import Modal from 'react-modal';
import { Tabs, Tab } from 'react-bootstrap';

import modalStyle from '../../styles/modal';
import Button from '../../components/Button';
import IconButton from '../../components/IconButton';
import InlineList from '../../components/InlineList';
import colors from '../../styles/colors';
import { Label } from '../../components/type';
import WorkflowSchemaMapping from './WorkflowSchemaMapping';
import Rules from './Rules';
import RuleSet from '../../containers/RuleSet/RuleSet';
import StaticRuleSet from '../../containers/RuleSet/StaticRuleSet';
import createMappingName from './_createMappingName';
import uuid from 'uuid';
import Note from '../../containers/Note';

const buttonStates = {
  '1': 'Next',
  '2': 'Connect'
};

const getInputsFromDestinationObject = destinationObject => {
  if(!!destinationObject.pluginId) {
    return destinationObject.inputs;
  }
  return destinationObject.schema ?
    destinationObject.schema :
    destinationObject.operation.startup ?
      destinationObject.operation.startup.inputs :
      destinationObject.operation.plugin.inputs;
};

const getOutputsFromDestinationObject = (sourceObject, destinationObject) => {
  // If connecting plugin node to a startup node, plugin nodes inputs become startup node outputs
  if(!!destinationObject.pluginId && (!!sourceObject.operation && !!sourceObject.operation.startup)) {
    return destinationObject.inputs;
  }
  return sourceObject && sourceObject.schema ?
    sourceObject.schema :
    sourceObject && sourceObject.operation.plugin ?
      sourceObject.operation.plugin.outputs :
      destinationObject.schema;
};

class MappingSelector extends Component {
  constructor(props) {
    super(props);
    const conditions = this.getConditions();
    const isSourceStartupNode = props.sourceNode.operation.startup;
    this.state = {
      activeTab: !isSourceStartupNode ? 1 : 2,
      mapping: this.getMapping(),
      conditions: conditions,
      outputs: this.getOutputs(),
    };
    this.ruleSet = new RuleSet(conditions[0] || {}, {
      handleChange: this.handleChangeRuleSet.bind(this)
    });
    this.getInputs.bind(this);
    this.getOutputs.bind(this);
  }

  handleChangeRuleSet() {
    this.setState({ conditions: this.ruleSet.ruleSets.length > 0 ? [this.ruleSet.toJSON()] : [] });
  }

  handleSubmit() {
    const { handleFillingImportIOs, sourceNode, destinationNode, destinationWorkflow } = this.props;

    if (this.state.activeTab === 1) {
      return this.setState({ activeTab: 2 });
    }

    // When adding a new node
    if (!destinationNode) {
      // If connection to an importNode add inputs and outputs to import node
      if (sourceNode.operation.startup) {
        handleFillingImportIOs(sourceNode, !destinationWorkflow.hasOwnProperty('pluginId') ? destinationWorkflow.schema : destinationWorkflow.inputs);
      }
      const sources = [this.props.sourceNode.id];
      return this.props.handleNewNode(sources, this.state.mapping, this.state.conditions);
    }

    // When updating mapping or rules on existing connection
    this.props.handleSubmitConnection({
      conditions: this.state.conditions,
      destinationNode: this.props.destinationNode.id,
      mapping: this.state.mapping
    });
  }

  handleCloseModal() {
    this.setState({ isClosing: true });
    setTimeout(() => this.props.handleClose(), 300);
  }

  getSiblingConnections() {
    const destinationNode = this.props.destinationNode;
    return this.props.sourceNode.routes
      .filter(route => route.destinationNode !== (destinationNode && destinationNode.id))
      .map(route => ({
        node: this.props.chain.nodes.find(n => n.id === route.destinationNode),
        ruleSet: new RuleSet(route.conditions[0] || {})
      }));
  }

  getInputs() {
    const { destinationWorkflow } = this.props;
    const getInputs = destinationWorkflow && getInputsFromDestinationObject(destinationWorkflow);

    return destinationWorkflow && (
      getInputs.filter(item => !item.isOutput)
    );
  }

  getOutputs() {
    const { allWorkflows, mappableNodes } = this.props;

    let outputObject = {};

    mappableNodes.forEach(n => {
      if(n.operation.startup) {
        outputObject[n.id] = n.operation.startup.inputs;
      } else if(n.operation.plugin) {
        outputObject[n.id] = n.operation.plugin.outputs;
      } else {
        outputObject[n.id] = allWorkflows.find(
          w => w.id === n.operation.swfWorkflow.entityId
        ).schema;
      }
    });

    return outputObject;
  }

  render() {
    const { open, sourceWorkflow, sourceNode, destinationWorkflow, mappableNodes, chain, supportUser } = this.props;
    const siblingConnections = this.getSiblingConnections();
    const uniqId = uuid();

    return (
      <Modal
        contentLabel="Modal"
        isOpen={open}
        style={modalStyle}
        overlayClassName={this.state.isClosing ? 'ReactModal__Overlay--closing' : ''}
        onRequestClose={this.handleCloseModal.bind(this)}
        ariaHideApp={false}>
        <div style={{ minWidth: 740, padding: '16px 16px 30px' }} className="clearfix node-connection-rules-comp">
          {chain.isActive &&
          <Note
            note={supportUser ? 
            `There is active work in this chain. You can make edits to your chain, however, proceed
             with caution. Changing logic could result in items that are unable to complete the chain.`
            :
            `Read Only <span style=font-style:italic;font-weight:600>You may not make changes to a
             chain with active work.</span>`}
            type="notification"/>
          }
          <Tabs
            onSelect={tab => this.setState({ activeTab: Number(tab) })}
            activeKey={this.state.activeTab}
            animation={false}
            id="node-connection-tabs">
            {!(sourceNode.operation.startup && chain.nodes.length === 1) &&
            /* Map Data Tab */
            <Tab eventKey={1} title="1. Map Data">
              {sourceNode.id !== '0' &&
              <WorkflowSchemaMapping
                sourceWorkflow={sourceWorkflow}
                sourceNode={sourceNode}
                destination={destinationWorkflow}
                mapping={this.state.mapping}
                isLocked={chain.isActive && !supportUser}
                handleChangeMap={(output, input, outputMap, oldNode, newNode) => {
                  const originalMapping = { ...this.state.mapping };

                  delete originalMapping[outputMap];

                  originalMapping[createMappingName(
                    newNode ? newNode : oldNode, output ? output : `[[]]${uniqId}`, input
                  )] = input;

                  this.setState({ mapping: originalMapping });
                }}
                mappableNodes={mappableNodes}
                chainNodes={chain.nodes}
                outputs={this.state.outputs}
                inputs={this.getInputs()}
              />
              }
            </Tab>
            }
            {/* Advanced Rules Tab */}
            <Tab
              eventKey={2}
              title={!sourceNode.operation.startup ? '2. Advanced Rules' : 'Advanced Rules'}
              disabled={!this.isMappingValid()}
            >
              <Rules
                sourceNode={sourceNode}
                sourceWorkflow={sourceWorkflow}
                destinationWorkflow={destinationWorkflow}
                ruleSet={this.ruleSet}
                canBeDefault={!this.hasEmptySibling()}
                isLocked={chain.isActive && !supportUser}
              />
              {this.ruleSet.ruleSets.length > 0 &&
              <div>

                {/* "Add another rule" button */}
                { (chain.isActive && !supportUser) &&
                <div style={styles.addRuleBtn}>
                  <IconButton
                    icon="plus-square"
                    handleClick={() => {
                      this.ruleSet.addRuleSet({ rules: [{}] });
                    }}>
                    Add another rule
                  </IconButton>
                </div>
                }

                {/* "Then" statement */}
                <InlineList spacing={16}>
                  <div style={styles.operation}><Label uppercase={true} center={true}>Then</Label></div>
                  <Label>send data to</Label>
                  <div style={styles.nodeName}>
                    <Label>{destinationWorkflow.name}</Label>
                  </div>
                </InlineList>

              </div>
              }

              {/* Display the sibling connection rules for reference */}
              <div style={{ marginTop: 32 }}>
                <Label>Other Connected Workflows</Label>
                {siblingConnections.map((connection, i) => (
                  <div key={i} style={styles.siblingConnection}>

                    <div style={styles.staticRuleTitle}>
                      <InlineList spacing={8}>
                        <Label color="grey">CONNECT</Label>
                        <div style={styles.nodeNameOther}>
                          <Label color="light">{sourceNode.name}</Label>
                        </div>
                        <Label color="grey">TO</Label>
                        <div>
                          <Label color="light">{connection.node.name}</Label>
                        </div>
                      </InlineList>
                    </div>

                    {/* Show static rule sets */}
                    {connection.ruleSet.getRuleSets().map((set, i) => (
                      <div key={i} style={{ position: 'relative' }}>
                        <StaticRuleSet
                          ruleSet={set}
                          operation={connection.ruleSet.getOperation()}
                        />
                        {i < connection.ruleSet.getRuleSets().length - 1 &&
                        <div style={styles.staticOperation}>
                          <Label
                            color="grey"
                            center
                            lowercase>
                            {connection.ruleSet.getOperation()}
                          </Label>
                        </div>
                        }
                      </div>
                    ))}

                  </div>
                ))}
              </div>
            </Tab>

          </Tabs>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disabled={this.shouldDisableButton()}
              handleClick={e => this.handleSubmit(e)}>
              {buttonStates[this.state.activeTab] || 'Next'}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  isAlreadyMapped() {
    const { destinationNode, sourceNode } = this.props;
    return destinationNode && sourceNode.destinations.includes(this.props.destinationNode.id);
  }

  getMapping() {
    const { destinationWorkflow, sourceWorkflow, sourceNode } = this.props;
    if (this.isAlreadyMapped()) {
      const { destinationNode, sourceNode } = this.props;
      return sourceNode.routes.find(route => route.destinationNode === destinationNode.id).mapping;
    }
    const mapping = {};

    if (destinationWorkflow) {
      const outputsDict = {};
      getOutputsFromDestinationObject(sourceWorkflow ? sourceWorkflow : sourceNode, destinationWorkflow).forEach( o => { outputsDict[o.name] = o; });
      getInputsFromDestinationObject(destinationWorkflow).filter(item => !item.isOutput).forEach(input => {
        const matchingOutput = outputsDict[input.name];
        if(matchingOutput) {
          mapping[createMappingName(sourceNode.id, matchingOutput)] = input.name;
        }
      });
    }
    return mapping;
  }

  getConditions() {
    if (this.isAlreadyMapped()) {
      const { destinationNode, sourceNode } = this.props;
      const route = sourceNode.routes.find(route => route.destinationNode === destinationNode.id);
      return route.conditions || [];
    }
    return [];
  }

  isMappingValid() {
    const { destinationWorkflow } = this.props;

    let invalid = false;

    getInputsFromDestinationObject(destinationWorkflow).filter(s => !s.isOutput).forEach((input) => {
      if (!Object.keys(this.state.mapping).find(mapId => {
          return this.state.mapping[mapId] === input.name && mapId.split('|')[2].substring(0, 4) !== '[[]]';
        })) {
        invalid = true;
      }
    });
    return !invalid;
  }

  hasEmptySibling() {
    return !!this.getSiblingConnections().find(connection => {
      return !connection.ruleSet.hasRulesOrRuleSets();
    });
  }

  shouldDisableButton() {
    const activeTab = this.state.activeTab;

    if (activeTab === 1) {
      return !this.isMappingValid();
    }

    if (activeTab === 2) {
      const isEmpty = !this.ruleSet.hasRulesOrRuleSets();
      const hasEmptySibling = this.hasEmptySibling();
      // Can't move on if rule set is invalid,
      // or if no rules/sets AND has a sibling connection already without rules/sets
      return !this.ruleSet.isValid() || (isEmpty && hasEmptySibling) || (this.props.chain.isActive && !this.props.supportUser);
    }
  }
}

const styles = {
  addRuleBtn: {
    paddingBottom: 30,
    marginBottom: 30,
    borderBottom: `2px solid ${colors.border}`,
  },
  nodeName: {
    background: colors.divider,
    borderRadius: 4,
    padding: '4px 8px',
  },
  nodeNameOther: {
    fontWeight: 900,
  },
  operation: {
    borderRadius: 4,
    border: `1px solid ${colors.border}`,
    width: 64,
  },
  siblingConnection: {
    border: `1px solid ${colors.divider}`,
    marginTop: 8,
    marginBottom: 16,
    borderRadius: 4,
  },
  staticRuleTitle: {
    padding: '8px 12px',
    background: colors.divider,
  },
  staticOperation: {
    position: 'absolute',
    left: 12,
    bottom: -12,
    width: 36,
    border: `1px solid ${colors.divider}`,
    borderRadius: 4,
    background: 'white',
    zIndex: 1,
  },
};

export default MappingSelector;