import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Title, Attention, Label } from '../../components/type';
import Panel from '../../containers/Panel';
import IconButton from '../../components/IconButton';
import Button from '../../components/PrimaryButton';
import Input from '../../components/Input';
import CurrencyInput from '../../components/CurrencyInput';

import colors from '../../styles/colors';
import { updateCompanyPlan } from '../../actions/company';
import { selectedCompanyState, editPlan } from './Constants';

class CurrentPlanEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCompany: props.selectedCompany,
      ledgerState: props.ledger,
      updating: false,
      updateError: '',
      adminSeatError: false,
      contributorSeatError: false,
      transactionFeeError: false,
      transactionFloorError: false,
    };
  }

  handleUpdatePlan() {
    const { selectedCompany } = this.props;
    const { adminSeats, contributorSeats } = this.state.selectedCompany;
    const { transactionFeeRate, minimumTransactionFee, privateContributorsRespectMinimum} = this.state.ledgerState;
    const { updating } = this.state;

    if(!this.planValidation()) {
      return;
    }

    let plan = {
      admin_seats: adminSeats,
      contributor_seats: contributorSeats,
      transaction_fee: transactionFeeRate,
      transaction_floor: minimumTransactionFee,
      privateContributorsRespectMinimum: privateContributorsRespectMinimum,
    };
    this.setState({updating: !updating}, () => {
      this.props.dispatch( updateCompanyPlan(selectedCompany.companyId, selectedCompany.swfId, plan))
        .then(() => {
        this.props.handleUpdatePlan();
        })
        .catch(() => {
          this.setState({
            updating: false,
            updateError: 'There was an error updating the plan.'
          });
        });
    });

  }

  planValidation() {
    const { transactionFeeRate, minimumTransactionFee } = this.state.ledgerState;
    const { adminSeats, contributorSeats } = this.state.selectedCompany;
    let adminSeatError = false;
    let contributorSeatError = false;
    let transactionFeeError = false;
    let transactionFloorError = false;
    if(adminSeats < 1 || contributorSeats < 0 || transactionFeeRate < 0 || minimumTransactionFee < 0) {
      if(adminSeats < 1) {
        adminSeatError = true;
      }
      if(contributorSeats < 0 ) {
        contributorSeatError = true;
      }
      if(transactionFeeRate < 0) {
        transactionFeeError = true;
      }
      if(minimumTransactionFee < 0) {
        transactionFloorError = true;
      }
      this.setState({adminSeatError, contributorSeatError, transactionFeeError, transactionFloorError});
      return false;
    }
    return true;
  }

  handleInput(key, value) {
    let newSelectedCompanyState = {...this.state.selectedCompany};
    let newLedgerState = {...this.state.ledgerState};
    if(key === 'adminSeats' || key === 'contributorSeats') {
      newSelectedCompanyState[key] = value;
    } else {
      newLedgerState[key] = value;
    }
    this.setState({selectedCompany: newSelectedCompanyState, ledgerState: newLedgerState});
  }

  render() {
    const {
      updating,
      updateError,
      adminSeatError,
      contributorSeatError,
      transactionFeeError,
      transactionFloorError,
    } = this.state;
    const { adminSeats, contributorSeats } = this.state.selectedCompany;
    const { transactionFeeRate, minimumTransactionFee, privateContributorsRespectMinimum} = this.state.ledgerState;

    return (
      <Panel title="Current Plan" color="light">
        <div style={{ padding: 16 }}>
          <ul style={styles.unorderedList}>
            <li style={styles.listItem}>
              <Title color="light">Administrator Seats</Title>
              <Input
                type="number"
                value={adminSeats}
                invalid={adminSeatError}
                handleChange={val => this.handleInput('adminSeats', val)}/>
            </li>
            <li style={styles.listItem}>
              <Title color="light">Private Contributor Seats</Title>
              <Input
                type="number"
                value={contributorSeats}
                invalid={contributorSeatError}
                handleChange={val => this.handleInput('contributorSeats', val)}/>
            </li>
            <li style={styles.listItem}>
              <Title color="light">Transaction Fee</Title>
              <Input
                type="number"
                value={transactionFeeRate}
                invalid={transactionFeeError}
                handleChange={val => this.handleInput('transactionFeeRate', val)}/>
            </li>
            <li style={{ display: 'flex', flexDirection: 'column', padding: '2em 0 1em', }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Title color="light">Minimum Transaction Floor</Title>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: `2px solid ${colors.divider}`,
                    borderRight: 'none',
                    borderTopLeftRadius: 5,
                    borderBottomLeftRadius: 5,
                    width: 48,
                    marginRight: -2
                  }}>
                    <Attention color="light" center>$</Attention>
                  </div>
                  <CurrencyInput
                    value={minimumTransactionFee}
                    error={transactionFloorError}
                    allowNegative={true}
                    handleUpdate={( event, val ) => this.handleInput('minimumTransactionFee', val)}/>
                </div>
              </div>
              {minimumTransactionFee.toString() !== '0' &&
              <IconButton
                icon={privateContributorsRespectMinimum ? 'check-square-o' : 'square-o'}
                label=""
                handleClick={() => this.handleInput('privateContributorsRespectMinimum', !privateContributorsRespectMinimum)}>
                <div style={{ textDecoration: 'none !important', display: 'inline-block' }}>
                  Apply the floor to tasks completed by private contributors
                </div>
              </IconButton>
              }
            </li>
          </ul>
          <div style={{ marginTop: 16, display: 'flex' }}>
            <Button loading={updating} handleClick={() => this.handleUpdatePlan()}>
              Update
            </Button>
            {!!updateError &&
              <Label>{updateError}</Label>
            }
          </div>
        </div>
      </Panel>
    );
  }
}

/* Styles */
const styles = {
  listItem: {
    borderBottom: `1px solid ${colors.shaded}`,
    padding: '2em 0 1em',
    display: 'flex',
    justifyContent: 'space-between'
  },
  unorderedList: {
    backgroundColor: colors.background,
    listStyle: 'none',
    marginBottom: 0,
    padding: '0px 16px'
  }
};

CurrentPlanEdit.propTypes = {
  selectedCompany: PropTypes.object,
  ledger: PropTypes.object,
  handleUpdatePlan: PropTypes.func,
  isUpdating: PropTypes.bool,
};

CurrentPlanEdit.defaultProps = {
  selectedCompany: selectedCompanyState,
  ledger: editPlan,
};

export default CurrentPlanEdit;