import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { StyleSheet, css } from 'aphrodite';
import { connect } from 'react-redux';
import { parse } from 'qs';

import { signUp } from '../../actions/login';

import { Heading, Label } from '../../components/type';
import InputCaption from '../../components/InputCaption';
import Button from '../../components/Button';
import IconButton from '../../components/IconButton';
import colors from '../../styles/colors';
import Toast from '../../containers/Toast';

const errorCodes = [
  { code: 59647, message: 'There is a problem with your invitation URL. Please check the link URL and try again.' },
  { code: 51729, message: 'An account with that email address already exists.' },
  { code: 68292, message: 'Invitation has been revoked.' },
];

class SignUp extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      firstName: '',
      firstNameError: null,
      lastName: '',
      lastNameError: null,
      password: '',
      passwordError: null,
      confirmPassword: '',
      confirmPasswordError: null,
      termsOfService: false,
      termsOfServiceError: null,
      submitting: false,
      errors: '',
      formErrors: false,
    };

    this.validateSubmitForm = this.validateSubmitForm.bind( this );
  };

  validateSubmitForm(e){
    if(!!e){
      e.preventDefault();
    }
    const { firstName, lastName, password, confirmPassword, termsOfService } = this.state;
    const passwordCompare = password.match(/^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W\_])|(?=.*[\W\_])(?=.*\d))|(?=.*[\W\_])(?=.*[A-Z])(?=.*\d)).{8,}$/g);
    const signUpKey = parse(this.props.location.search, {ignoreQueryPrefix: true}).key || '';
    let formErrors = false;
    this.setState({
      errors: '',
      firstNameError: null,
      lastNameError: null,
      passwordError: null,
      confirmPasswordError: null,
      termsOfServiceError: null,
      submitting: true,
    });
    if(signUpKey === ''){
      this.setState({errors: 'There is a problem with your invitation URL. Please check the link URL and try again.'});
    }
    if(firstName === '') {
      formErrors = true;
      this.setState({firstNameError: 'Please enter your first name'});
    }
    if(lastName === '') {
      formErrors = true;
      this.setState({lastNameError: 'Please enter your last name'});
    }
    if(password === '') {
      formErrors = true;
      this.setState({passwordError: 'Please enter a password'});
    }
    if(!!password && password.length < 8) {
      formErrors = true;
      this.setState({passwordError: 'Password must be at least 8 characters'});
    }
    if(!!password && passwordCompare === null){
      formErrors = true;
      this.setState({passwordError: 'Password must be at least 8 characters and contain at least three of the ' +
      'following four categories: digits, uppercase letters, lowercase letters, and non-alphanumeric symbols.'});
    }
    if(confirmPassword === '') {
      formErrors = true;
      this.setState({confirmPasswordError: 'Please confirm your password'});
    }
    if(confirmPassword !== '' && confirmPassword !== password) {
      formErrors = true;
      this.setState({confirmPasswordError: 'Passwords don\'t match'});
    }
    if(termsOfService === false) {
      formErrors = true;
      this.setState({termsOfServiceError: 'Please read and accept the terms of service'});
    }
    if(!formErrors) {
      this.submitSignUp();
    } else {
      this.setState({submitting: false});
    }
  }

  submitSignUp() {
    const { firstName, lastName, password, confirmPassword, termsOfService } = this.state;
    const signUpKey = parse(this.props.location.search, {ignoreQueryPrefix: true}).key;

    this.props.dispatch( signUp( signUpKey, firstName, lastName, password, confirmPassword, termsOfService ) )
      .then(res => {
        if(res.success === false){
          const error = errorCodes.find(errorCode => {
            return errorCode.code === res.code;
          });
          const errorMessage = !!error ? error.message : 'An error has occurred.';
          return this.setState({
            errors: errorMessage,
            submitting: false,
          });
        }
        window.location.href = '/';
      });
  }

  render() {
    const { firstName, lastName, password, confirmPassword, termsOfService, errors, submitting,
      firstNameError, lastNameError, passwordError, confirmPasswordError, termsOfServiceError } = this.state;

    return (
      <DocumentTitle title="Sign Up | OneSpace Project Center">
        <div style={{ display: 'flex', justifyContent: 'center'}}>
          <form style={{ width: '33%' }} onSubmit={this.validateSubmitForm}>
            <div style={{ marginTop: 36, marginBottom: 36 }}>
              <Heading color="dark">Sign Up</Heading>
            </div>
            {errors !== '' &&
              <div style={{paddingBottom: 10}}>
                <Toast
                  label={errors}
                  type="error"
                  bold={false} />
              </div>
            }
            <InputCaption
              handleChange={firstName => this.setState({firstName})}
              label={'First Name'}
              value={firstName}
              invalid={firstNameError !== null}
              error={firstNameError} />
            <InputCaption
              handleChange={lastName => this.setState({lastName})}
              label={'Last Name'}
              value={lastName}
              invalid={lastNameError !== null}
              error={lastNameError} />
            <InputCaption
              handleChange={password => this.setState({password})}
              label={'Password'}
              value={password}
              type="password"
              invalid={passwordError !== null}
              error={passwordError} />
            <InputCaption
              handleChange={confirmPassword => this.setState({confirmPassword})}
              label={'Confirm Password'}
              value={confirmPassword}
              type="password"
              invalid={confirmPasswordError !== null}
              error={confirmPasswordError} />
            <div style={{ marginBottom: 24 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  icon={termsOfService ? 'check-square-o' : 'square-o'}
                  hoverColor={'dark'}
                  label=""
                  handleClick={() => {this.setState({termsOfService: !this.state.termsOfService});}}>
                  <div style={{textDecoration: 'none !important', display: 'inline-block', color: colors.placeholder}}>
                    Accept the
                  </div>
                </IconButton>
                <a
                  style={{ fontFamily: 'Open Sans', fontSize: 12 }}
                  className={css(styles.tosLink)}
                  target="_blank" href="https://www.onespace.com/terms-of-service/">
                  Terms of Service
                </a>
              </div>
              {termsOfServiceError &&
                <Label italic={true} color="negative">{termsOfServiceError}</Label>
              }
            </div>
            <Button handleClick={this.validateSubmitForm} loading={submitting}>
              Submit
            </Button>
          </form>
        </div>
      </DocumentTitle>
    );
  }
}

const styles = StyleSheet.create( {
  tosLink: {
    paddingLeft: 5,
    cursor: 'pointer',
    color: colors.typeSelected,
    textDecoration: 'underline',
    ':hover': {
      color: colors.primary()
    },
  }
} );

/* Map state to Props */
function select( ) {
  return {};
}

export default connect( select )( SignUp );