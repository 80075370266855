import React from 'react';

import {Caption} from '../../components/type';
import InlineButton from '../../components/InlineButton';
import colors from '../../styles/colors';

const EmptyChainManager = () => (
  <div style={styles.emptyChainsContainer}>
    <div style={styles.emptyChainsContent}>
      <div style={{display: 'flex', justifyContent: 'center', marginBottom: 5, padding: '0 16px'}}>
        <Caption color='white' center={true}>
          You do not currently have any workflow chains. Get Started by creating a project with workflows.
        </Caption>
      </div>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <InlineButton handleClick={() => window.location = `/projects/new`} >
          Create a Project
        </InlineButton>
      </div>
    </div>
  </div>
);

const styles = {
  emptyChainsContainer: {
    display: 'flex',
    flex: 6,
    background: colors.light,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
    maxHeight: 200,
    border: `2px solid ${colors.divider}`,
    marginTop: 16
  },
  emptyChainsContent: {
    background: colors.dark,
    height: 160,
    width: 300,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 5,
    flexDirection: 'column',
  },
};

export default EmptyChainManager;