import React from 'react';
import Input from '../../../../components/Input';
import { Label } from '../../../../components/type';

const Text = ({ data, updateChainPlugin, index, readOnly }) => (
  <div style={{marginBottom: 30}}>
    {data.map((d, idx) => {
      return (
        <div key={idx}>
          <Label color="light">Value</Label>
          <Input
            disabled={readOnly}
            value={d.value}
            handleChange={val => {
              updateChainPlugin('typeSettings|eventOptions', val, [index, idx]);
            }}
            unallowedCharacters={[32]}
          />
        </div>
      );
    })}
  </div>
);

export default Text;

