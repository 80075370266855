import React from 'react';

import Settings from '../Settings';
import Input from '../../Form/Input';
import InlineInput from '../../Form/InlineInput';
import Switch from '../../Form/Switch';
import Radio from '../../Form/Radio';
import SupportRoleOptions from './SupportRoleOptions';

class Upload extends React.Component {
  componentDidMount() {
    if (this.props.selected) {
      this.inline.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.selected && this.props.selected) {
      this.inline.focus();
      this.props.handleChangeOutput(this.props.data.output);
    }
  }

  render() {
    const {data = {}, selected, updateElement, handleSelectInput, outputError, isSupportRole, handleChangeOutput} = this.props;
    return (
      <div style={styles.main}>
        <div style={styles.label}>
          <InlineInput
            ref={ele => this.inline = ele}
            value={data.label}
            selected={selected}
            handleChange={label => updateElement({label})}
            placeholder="Add a label"
            fontSize={16}
            fontWeight={800}
            disabled={!selected}
            onFocus={() => handleSelectInput('label')}
            maxLength={150}
          />
        </div>
        <div style={styles.label}>
          <InlineInput
            value={data.instructions}
            selected={selected}
            handleChange={instructions => updateElement({instructions})}
            placeholder="Add instructions or tips for the contributors"
            fontSize={13}
            fontWeight={600}
            disabled={!selected}
            onFocus={() => handleSelectInput('instructions')}
          />
        </div>
        <div style={styles.dropzone}>
          <i style={styles.icon} className="os-cloud"/>
          <div style={styles.dropzoneTitle}>UPLOAD A FILE</div>
        </div>
        <Settings show={selected} customHeight="auto">
          <Radio
            label="Is this for images or documents"
            options={[
              {label: 'Images', checked: data.type === 'image', onChange: () => updateElement({type: 'image'})},
              {label: 'Documents', checked: data.type === 'document', onChange: () => updateElement({type: 'document'})}
            ]}
          />
          <Input
            value={data.value}
            label="Set a default input value (optional)"
            onChange={value => updateElement({value})}
            onFocus={() => handleSelectInput('value')}
          />
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Input
              value={data.output}
              label="Output Name"
              onChange={output => {
                handleChangeOutput(output);
                updateElement({output});
              }}
              maxWidth={280}
              error={outputError}
            />
            <Switch
              label="Required"
              isOn={data.required}
              handleToggle={() => updateElement({required: !data.required})}
            />
          </div>
          {isSupportRole &&
          <SupportRoleOptions
            data={data}
            updateElement={updateElement}
            handleSelectInput={handleSelectInput}
          />
          }
        </Settings>
      </div>
    );
  }
}

const styles = {
  main: {
    paddingTop: 8
  },
  label: {
    marginBottom: 8
  },
  dropzone: {
    border: '2px dashed rgba(0,0,0,0.2)',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 32,
    paddingBottom: 32,
    color: '#b3b3b3',
    marginBottom: 8
  },
  icon: {
    fontSize: 48
  },
  dropzoneTitle: {
    textTransform: 'uppercase',
    fontWeight: 800,
    fontSize: 32,
    marginTop: 8
  },
  title: {
    fontSize: 16,
    fontWeight: 800
  }
};

export default Upload;
