import React from 'react';

import colors from '../../styles/colors';
import IconButton from '../../components/IconButton';

const IconButtonContainer = ( { color, icon, text, href, handleSelect } ) => (
  <div style={container(color)}>
    <IconButton
      label={text}
      handleClick={handleSelect}
      icon={icon}
      href={href}
      underline={true} >
      {text}
    </IconButton>
  </div>
);

const container = ( color ) => (
  {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: color === 'shaded' ? colors.shaded : color === 'blue' ? colors.secondary() : colors.green,
    marginTop: 1,
    paddingTop: 8,
    paddingBottom: 8
  }
);

export default IconButtonContainer;