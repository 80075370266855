import { updateState } from '../utils/reducerHelpers';

import {
  CREATE_API_CREDENTIALS_REQUEST,
  CREATE_API_CREDENTIALS_SUCCESS,
  CREATE_API_CREDENTIALS_FAILURE,
  UPDATE_API_CREDENTIALS_REQUEST,
  UPDATE_API_CREDENTIALS_SUCCESS,
  UPDATE_API_CREDENTIALS_FAILURE,
} from '../constants/actionTypes';

export default function (state= {}, action) {
  switch(action.type) {
    case CREATE_API_CREDENTIALS_REQUEST:
      return {...state, isFetching: true, hasFetched: false};
    case CREATE_API_CREDENTIALS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hasFetched: true,
        workerId: action.workerId,
        username: action.username,
        password: action.password,
      };
    case CREATE_API_CREDENTIALS_FAILURE:
      return updateState.fetchFailure(state);
    case UPDATE_API_CREDENTIALS_REQUEST:
      return {...state, isFetching: true, hasFetched: false};
    case UPDATE_API_CREDENTIALS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hasFetched: true,
        workerId: action.workerId,
        username: action.username,
        password: action.password,
      };
    case UPDATE_API_CREDENTIALS_FAILURE:
      return updateState.fetchFailure(state);

    default:
      return state;
  }
}