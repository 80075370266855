export const idLookup = {
  guidTitle: 'ID Lookup',
  workflowChain: 'Workflow Chain',
  workflow: 'Workflow',
  guid: 'ID',
  webhook: 'Webhook',
  update: 'Update',
  copy: 'Copy',
  delete: 'Delete'
};

export const overview = {
  apiTitle: 'API Credentials',
  resetAPI: 'Reset API Password',
  username: 'Username:',
  password: 'Password:',
  documentation: 'Documentation',
  viewDocs: 'View API Docs',
  webhookSettings: 'Company Webhook Settings',
  defaultWebhooks: 'Company Default Webhooks',
  specificWebhooksPossible: 'Workflow or chain specific webhooks are possible. See the ID ' +
    'Lookup tab for details.',
  securityOptions: 'Security Options',
  save: 'Save',
  hmacTokenTitle: 'HMAC Token',
  generateApi: 'Generate API User',
  copy: 'Copy',
  url: 'oAuth URL:',
  accessToken: 'Access Token Field',
  emailError: 'Email Required',
  passwordError: 'Password Required',
  urlError: 'oAuth URL Required',
  accessError: 'Access Token Required',
  companyWebhookError: 'A Company Default Webhook is Required',
  tokenTooltip: 'When authenticating against the oAuth URL to retrieve an authentication token, ' +
    'this label tells us which field of the response to use as the access token for a given session.',
  noApiUser: 'You must generate an API account before saving webhooks.',
  companyWebhookOptions: [
    { name: 'webhookOptions', value: false, caption: 'Disable Webhook' },
  ],
  webhookOptions:(type) => {
    return [
    { name: 'webhookOptions', value: 'default', caption: 'Use Default Webhook' },
    { name: 'webhookOptions', value: 'specific', caption: `Enable ${type} Webhook` },
    { name: 'webhookOptions', value: 'disable', caption: `Disable ${type} Webhook` },
  ];
  }, 
  radioOptions: [
    { name: 'options', value: 1, caption: 'HMAC' },
    { name: 'options', value: 2, caption: 'oAuth' },
    { name: 'options', value: 0, caption: 'None' },
  ],
  defaultWebhookTooltip: 'When the Company Default Webhook is disabled, any webhooks set directly ' +
    'on an individual chain or workflow will remain enabled.',
};

export const apiAdminIndex = {
  pageDescription: 'This page allows you to update your password, set webhooks, and look up workflow and workflow chain IDs.',
  pageTitle: 'API Administration',
  docTitle: 'API Administration | OneSpace Project Center',
  helpPanelLinks: [
    {
      href: 'http://developer.onespace.com/',
      label: 'OneSpace Project Center API',
    }
  ],
};