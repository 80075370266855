import React from 'react';
import numeral from 'numeral';
import PropTypes from 'prop-types';

import { Subheading, Title } from '../../components/type';
import Panel from '../../containers/Panel';

import colors from '../../styles/colors';

const getPanelActions = (userRole, handleEdit) => {
  if(userRole === 'onespace-account') {
    return [ { label: 'Edit', icon: 'pencil-square-o', handleClick: handleEdit } ];
  }
  return [];
};

const CurrentPlanStatic = ({selectedCompany, ledger, handleEdit, userRole}) => {
  return (
    <div>
      <Subheading color="dark">{selectedCompany.name}</Subheading>
      <hr />
      <Panel
        title="Current Plan"
        color="light"
        actions={getPanelActions(userRole, handleEdit)}>
        <div style={{ padding: 16 }}>
          <ul style={styles.unorderedList}>
            <li style={styles.listItem}>
              <Title color="light">Administrator Seats</Title>
              <Title color="dark">{selectedCompany.adminSeats}</Title>
            </li>
            <li style={styles.listItem}>
              <Title color="light">Private Contributor Seats</Title>
              <Title color="dark">{selectedCompany.contributorSeats}</Title>
            </li>
            <li style={styles.listItem}>
              <Title color="light">Transaction Fee</Title>
              <Title color="dark">{ledger.transactionFeeRate * 100}%</Title>
            </li>
            <li style={styles.listItem}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Title color="light">Minimum Transaction Floor</Title>
              </div>
              <Title color="dark">{numeral( ledger.minimumTransactionFee ).format( '$0,0.00' )}</Title>
            </li>
            <li style={styles.listItem}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Title color="light">Balance</Title>
              </div>
              <Title color="dark">{numeral( ledger.availableBalance ).format( '$0,0.00' )}</Title>
            </li>
          </ul>
        </div>
      </Panel>
    </div>
  );
};

/* Styles */
const styles = {
  listItem: {
    borderBottom: `1px solid ${colors.shaded}`,
    padding: '2em 0 1em',
    display: 'flex',
    justifyContent: 'space-between'
  },
  unorderedList: {
    backgroundColor: colors.background,
    listStyle: 'none',
    marginBottom: 0,
    padding: '0px 16px'
  }
};

CurrentPlanStatic.propTypes = {
  selectedCompany: PropTypes.object,
  ledger: PropTypes.object,
  handleEdit: PropTypes.func,
  userRole: PropTypes.string,
};

export default CurrentPlanStatic;