import { combineReducers } from 'redux';

import elements from './elements';
import libraryElements from './libraryElements';
import inputOutputs from './inputOutputs';
import editor from './editor';

export default combineReducers({
  elements,
  libraryElements,
  inputOutputs,
  editor
});
