import fetch, { checkStatus, parseJSON } from './fetch';

export default function callAPIMiddleware({ dispatch, getState }) {
  return next => action => {
    const {
      types,
      shouldCallAPI = () => true,
      getFormattedResponse,
      payload,
      fetchRoute,
      fetchMethod,
      fetchBody,
    } = action;

    if (!types) {
      // Normal action: pass it on
      return next(action);
    }
    const hasBadTypes = !Array.isArray(types) || types.length !== 3 || !types.every(type => typeof type === 'string');
    if (hasBadTypes) {
      throw new Error('Expected an array of three string types.');
    }

    if (!shouldCallAPI(getState())) {
      return new Promise(resolve => resolve());
    }

    const [requestType, successType, failureType] = types;

    dispatch(Object.assign({}, payload, {
      type: requestType
    }));
    const route = typeof fetchRoute === 'function' ? fetchRoute(getState) : fetchRoute;
    const body = typeof fetchBody === 'function' ? fetchBody(getState) : fetchBody;
    return fetch(route, fetchMethod, body)
      .then(checkStatus)
      .then(parseJSON)
      .then(res => {
        const response = getFormattedResponse ? getFormattedResponse(res) : res;
        return dispatch(Object.assign({}, payload, response, { type: successType }));
      })
      .catch(error => {
        try {
          return error.then((err) => {
            dispatch(Object.assign({}, payload, { error: err.data || err.error, dataType: err.dataType, type: failureType }));
            throw err;
          });
        } catch (e) {
          console.log(error);
          dispatch(Object.assign({}, payload, { error, type: failureType }));
          throw error;
        }
      });
  };
};
