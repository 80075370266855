import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Note from '../../containers/Note';

import ChainBuilder from './ChainBuilder';

class Chain extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {
      chain,
      editable,
      handleClickAddNode,
      handleRemovedNodes,
      handleClickConnectionLabel,
      handleClickNode,
      handleReady,
      workflows,
    } = this.props;
    // Create a new Chain Builder component
    const chainState = editable ? 'editable' : null;
    this.chainBuilder = new ChainBuilder(chain, this.canvas, chainState, workflows, this.mini);
    // Listen to when the user clicks a workflow
    this.chainBuilder.addListener('clickNode', handleClickNode);
    // Listen to when the user clicks "Add Workflow"
    this.chainBuilder.addListener('clickAddNode', handleClickAddNode);
    // Listen to when the user clicks to remove a node
    this.chainBuilder.addListener('removedNodes', handleRemovedNodes);
    // List to when the user clicks the connection between nodes
    this.chainBuilder.addListener('clickConnectionLabel', handleClickConnectionLabel);
    if (handleReady) {
      handleReady(this.chainBuilder);
    }
  }

  componentDidUpdate() {
    if (!this.props.editable) {
      this.chainBuilder.updateData(this.props.chain);
    }
    this.chainBuilder.setChainState(this.props.editable);
  }

  render() {
    const { chain, editable, lockedReason, preview, handleClick, showMiniView } = this.props;
    return (
      <div style={{ position: 'relative' }}>
        {lockedReason &&
        <Note note={lockedReason} type="notification"/>
        }
        <div
          ref={ele => this.canvas = ele}
          id={`canvas-${chain.id}`}
          className="jtk-demo-canvas">
            <div ref={element => this.mini = element} className={`miniview ${showMiniView ? '' : 'hideMini'}`} />
        </div>
        {preview &&
        <div
          className="chain-lock-overlay"
          style={{
            display: editable ? 'none' : 'block',
            cursor: editable ? 'initial' : 'pointer',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }}
          onClick={handleClick}
        />
        }
      </div>
    );
  }
}

function requiredIfEditable(props, propName, componentName) {
  if (props.editable && !props[propName]) {
    return new Error(`${propName} is required unless ${componentName} editable={false}`);
  }
}

Chain.propTypes = {
  handleClick: PropTypes.func,
  handleClickAddNode: requiredIfEditable,
  handleRemovedNodes: requiredIfEditable,
  handleClickConnectionLabel: requiredIfEditable,
  handleClickNode: requiredIfEditable,
  preview: PropTypes.bool,
};

Chain.defaultProps = {
  showMiniView: true,
  editable: true
};

export default Chain;
