import { FETCH_INBOX_REQUEST, FETCH_INBOX_SUCCESS, FETCH_INBOX_FAILURE } from '../constants/actionTypes';

export function fetchInbox() {
  return {
    types: [FETCH_INBOX_REQUEST, FETCH_INBOX_SUCCESS, FETCH_INBOX_FAILURE],
    fetchRoute: '/api/inbox/load',
    shouldCallAPI: state => !state.app.inboxFetched,
    getFormattedResponse: ({ data }) => {
      return {
        unreadCount: data.counts.unread.readFilter.doc_count,
        folders: data.folders,
        messages: data.messages,
      };
    },
  };
}
