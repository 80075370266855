import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

import Preview from '../Preview';
import Spacer from './Spacer';
import BottomNav from '../BottomNav';

class Editor extends Component {
  render() {
    const {
      elements,
      inputs,
      handleChange,
      handleNewElement,
      handleMoveElement,
      handleAddToLibrary,
      handleMakeRepeatable,
      handleUpdateElement,
      handleDeleteElement,
      handleSelectElement,
      handleSelectInput,
      selected,
      previousOutputs,
      hideBottomNav,
      padding,
      borderRight,
      isLayout,
      columnIndex,
      elementType,
      disableVerticalScroll,
      dispatch,
      selectedColumn,
      selectedChild,
      inputIndex,
      isSupportRole,
      numDuplicatesForChildren,
    } = this.props;
    return (
      <div style={
        Object.assign({}, styles.main, {borderRight: borderRight ? borderRight : 0, padding: padding ? padding : 0})
      }>
        <div style={[
          styles.canvasWrap,
          disableVerticalScroll == true && styles.noVerticalScroll,
        ]}>
          <div style={styles.wrapper(elementType, columnIndex)}>
            {elements.length ?
              <div>
                {elements.map((element, i) => (
                  <div key={element.tempId}>
                    <Preview
                      tempId={element.tempId}
                      numDuplicatesForChildren={numDuplicatesForChildren}
                      index={Number.isInteger(isLayout) ? [i, columnIndex, isLayout] : i}
                      element={element}
                      selected={
                        selected === i ||
                        (selected === true && selectedChild == i) || 
                        (Array.isArray(selected) && selected[1] === selectedColumn && selectedChild == i)
                      }
                      handleSelect={(e) => {
                        return handleSelectElement(i, e);
                      }}
                      handleUnselect={() => handleSelectElement(null)}
                      handleChange={change => {
                        handleChange(i, change);
                      }}
                      handleNewElement={(type, index, targetIndex, element) => {
                        if(typeof targetIndex == 'undefined') {
                          handleNewElement(type, index, targetIndex, element);
                          handleSelectElement(index);
                        }
                      }}
                      outputs={elements.map((ele) => {
                        const output = ele.data.output;
                        const tempId = ele.tempId;
                        if(!!output) {
                          return {'output': output, 'tempId': tempId};
                        };
                      })}
                      inputs={inputs}
                      previousOutputs={previousOutputs}
                      handleMoveElement={handleMoveElement}
                      handleAddToLibrary={() => {
                        handleAddToLibrary(element);
                      }}
                      handleMakeRepeatable={() => {
                        handleMakeRepeatable(element);
                      }}
                      handleUpdateElement={() => {
                        handleUpdateElement(element);
                      }}
                      handleDeleteElement={() => {
                        handleDeleteElement(i, element);
                      }}
                      handleSelectInput={handleSelectInput}
                      dispatch={dispatch}
                      selectedColumn={selectedColumn}
                      selectedChild={selectedChild}
                      inputIndex={inputIndex}
                      isSupportRole={isSupportRole}
                      locked={element.data.makeUneditable}
                    />
                  </div>
                ))}
              </div>
              :
              <Spacer
                index={Number.isInteger(isLayout) ? [0, columnIndex, isLayout] : 0}
                elementType={elementType}
                empty={true}
                handleNewElement={(type, element) => {
                  handleSelectElement(Number.isInteger(isLayout) ? [0, columnIndex, isLayout] : elements.length);
                  handleNewElement(type, Number.isInteger(isLayout) ? [0, columnIndex, isLayout] : elements.length, null, element);
                }}
                handleMoveElement={handleMoveElement}
              />
            }
          </div>
        </div>
        {!hideBottomNav &&
          <div>
          <div className="task-ui-error-wrapper consensusError" style={styles.error}></div>
          <BottomNav />
          </div>
        }
      </div>
    );
  }
}

const styles = {
  main: {
    flex: 1,
    display: 'flex',
    boxSizing: 'border-box',
    background: '#b3b3b3',
    flexDirection: 'column',

    //delete when done dev
    minHeight: 200,
  },
  canvasWrap: {
    flex: 11,
    overflowY: 'auto',
    backgroundColor: 'white',
  },
  noVerticalScroll: {
    overflowY: 'initial',
  },
  wrapper: (elementType, columnIndex) => ({
    display: 'flex',
    flexDirection: 'column',
    background: '#ffffff',
    minHeight: 200,
    boxSizing: 'border-box',
    borderRadius: 3,
    paddingTop: 16,
    paddingBottom: 32,
    paddingLeft: ( elementType === 'FOURCOLUMNLAYOUT' && columnIndex !== 0) ? 16 : 32,
    paddingRight: (elementType === 'FOURCOLUMNLAYOUT' && columnIndex !== 3) ? 16 : 32,
  }),
  actionWrap: {
    flex: 1,
  },
  error: {
    display: 'none',
    background: '#f94d1f',
    color: 'white',
    fontWeight: 600,
    padding: '24px 16px 16px 16px',
    fontSize: 13,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6
  }
};

Editor.propTypes = {
  elements: PropTypes.array,
  handleChange: PropTypes.func,
  handleNewElement: PropTypes.func,
  handleMoveElement: PropTypes.func,
  handleDeleteElement: PropTypes.func,
  handleSelectElement: PropTypes.func,
  handleSelectInput: PropTypes.func,
  selected: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.array,
  ]),
};
Editor.defaultProps = {
  elements: []
};
export default Radium(Editor);
