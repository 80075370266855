import React, { Component } from 'react';
import { connect } from 'react-redux';

import NodeSelector from './NodeSelector';
import MappingSelector from './MappingSelector';
import createNodeFromEntity from './_createNodeFromEntity';
import addInputsAndOutputsToImportNode from './_addInputsAndOutputsToImportNode';

const sortByCreatedAt = (a, b) => new Date(b.created_at) - new Date(a.created_at);

class AddWorkflow extends Component {
  constructor(props) {
    super(props);
    const selectedDestinationNode = props.chain.nodes.find(node => node.id === props.selectedDestinationId);
    const destinationWorkflow = (selectedDestinationNode && !!selectedDestinationNode.operation.swfWorkflow) && props.workflows.find(
      w => w.id === selectedDestinationNode.operation.swfWorkflow.entityId
    );
    this.state = {
      destinationNode: selectedDestinationNode,
      destinationWorkflow: destinationWorkflow ? destinationWorkflow : selectedDestinationNode,
      mappableNodes: this.getMappableNodes(),
    };
    this.getMappableNodes = this.getMappableNodes.bind(this);
    this.handleSubmitConnection = this.handleSubmitConnection.bind(this);
  }

  handleSelectWorkflow(handleAddNode, {}, node, chain) {
    // Creates import node if one doesn't exist
    if (!chain.nodes.length) {
      return handleAddNode(createNodeFromEntity(node, []));
    }

    /* When selecting a fresh workflow, destination node should be empty */
    this.setState({
      destinationWorkflow: node,
      destinationNode: null,
    });
  }

  handleSubmitConnection(route) {
    const { chain, sourceNodeId, handleUpdateNode, handleAddNode } = this.props;
    const sourceNode = chain.nodes.find(node => node.id === sourceNodeId);
    const existingConnection = sourceNode.destinations.includes(route.destinationNode);
    if (existingConnection) {
      return handleUpdateNode(route);
    }
    handleAddNode(sourceNode, route);
  }

  handleNodesAttachedToImport(sourceNode, chain, usableWorkflows) {
    /* Get workflows to not show in the list */
    const workflowsAttachedToImport = chain.nodes.filter(node => {
      /* Filter out the nodes that have sources array and
       get once whose source id matches sourceNode id */
      return node.sources.length && node.sources.find(n => n === sourceNode.id);
    }).map(node => {
      /* Get workflows from nodes that shouldn't show */
      return node.operation.swfWorkflow && node.operation.swfWorkflow.entityId;
    });
    return usableWorkflows.filter(uw => {
      return !workflowsAttachedToImport.includes(uw.id);
    });
  }

  getMappableNodes() {
    /* Get only nodes that can be mapped to a sourceNode */
    let mappableNodes = this.props.chain.nodes.filter(node => this.props.unavailableNodes.includes(node.id));

    if(this.props.selectedDestinationId) {
      const destinationIdIndex = mappableNodes.findIndex(n => n.id === this.props.selectedDestinationId);
      mappableNodes = mappableNodes.slice();
      mappableNodes.splice(destinationIdIndex, 1);
    }

    return mappableNodes;
  }

  render() {
    const { destinationWorkflow, destinationNode } = this.state;
    const { chain, workflows, sourceNodeId, handleAddNode, handleCancel, unavailableNodes, plugins, userRole } = this.props;
    const sourceNode = chain.nodes.find(node => node.id === sourceNodeId);
    const sourceWorkflowId = (sourceNode && !sourceNode.operation.startup && !sourceNode.operation.plugin) && sourceNode.operation.swfWorkflow.entityId;
    const sourcePluginId = (sourceNode && !sourceNode.operation.swfWorkflow && !sourceNode.operation.startup) && sourceNode.operation.plugin.id;

    const sourceWorkflow = workflows.find(workflow => workflow.id === sourceWorkflowId);

    /* All workflows but the source workflow */
    const workflowsMinusSourceWorkflow = workflows.filter(
      w => w.id !== sourceWorkflowId
    );

    /* All plugins minus but the source plugin */
    const usablePlugins = plugins.filter(
      plugin => plugin.id !== sourcePluginId
    );

    let usableWorkflows = [];

    // Push all the workflows minus the source wf
    workflowsMinusSourceWorkflow.forEach(w => {
      if (!w.chainId || w.chainId === chain.id) {
        usableWorkflows.push(w);
      }
    });

    /* If the node  you're clicking on has a operation.startup */
    if (sourceNode && sourceNode.operation.startup) {
      usableWorkflows = this.handleNodesAttachedToImport(sourceNode, chain, usableWorkflows);
    }

    return (
      <div>
        <NodeSelector
          nodes={
            [
              {'tabName':'Workflows', 'items': usableWorkflows.sort(sortByCreatedAt), itemLabel: 'Workflow Name'},
              {'tabName':'Plugins', 'items': usablePlugins, itemLabel: 'Node Name'}
            ]
          }
          handleClose={handleCancel}
          open={!(destinationWorkflow || destinationNode)}
          handleSelect={node => this.handleSelectWorkflow(handleAddNode, workflows, node, chain, unavailableNodes)}
        />

        {!!(destinationWorkflow || destinationNode) &&
        <MappingSelector
          open={!!destinationWorkflow || !!destinationNode}
          chain={chain}
          sourceNode={sourceNode}
          sourceWorkflow={sourceWorkflow}
          destinationNode={destinationNode}
          destinationWorkflow={destinationWorkflow ? destinationWorkflow : destinationNode}
          handleNewNode={(sources, mapping, conditions) => {
            const newNode = createNodeFromEntity(
              destinationWorkflow ? destinationWorkflow : destinationNode,
              sources,
            );
            handleAddNode(newNode, { conditions, destinationNode: newNode.id, mapping });
          }}
          handleSubmitConnection={this.handleSubmitConnection}
          handleClose={handleCancel}
          mappableNodes={this.state.mappableNodes}
          workflows={usableWorkflows}
          allWorkflows={workflows}
          handleFillingImportIOs={(importNode, destinationNodeSchema) => {
            addInputsAndOutputsToImportNode(importNode, destinationNodeSchema);
          }}
          supportUser={userRole === 'onespace-support'}
        />
        }
      </div>
    );
  }
}

function select(state, props) {
  const { chainId, projectId } = props;
  const chain = state.chains.items[chainId];
  return {
    chain,
    workflows: Object.keys(state.workflows.items).map(id => state.workflows.items[id]).filter(w => w.id_project === projectId),
    plugins: state.chains.items[chainId] && state.chains.items[chainId].plugins || [],
    userRole: state.app.role,
  };
}
export default connect(select)(AddWorkflow);