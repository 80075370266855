import React from 'react';
import TaskUIComponent from '../../assets/js/components/taskui';

const elementsArray = [
  {
    'type': 'HEADING',
    'data': {
      'style': 'heading',
      '_innerValue': 'Assignment Details',
    },
    'shouldExport': true,
    'tempId': '78f6f585-aa31-4c7f-b57b-c0b530c813fd'
  },
  {
    'type': 'HEADING',
    'data': {
      'style': 'heading',
      '_innerValue': 'Heading Locke3d',
      'makeUneditable': true,
    },
    'shouldExport': true,
    'tempId': '78f6f585-aa31-4c7f-b57b-c0b530c813f2'
  },
  {
    'type': 'TWOCOLUMNLAYOUT',
    'data': {
      'columns': [
        {
          data: {},
          children: [
            {
              'type': 'HEADING',
              'data': {
                'style': 'heading',
                '_innerValue': 'Assignment Details 1'
              },
              'shouldExport': true,
              'tempId': '78f6f585-aa31-4c7f-b57b-c0b530c813fg'
            }
          ]
        },
        {
          data: {},
          children: [
            {
              'type': 'HEADING',
              'data': {
                'style': 'heading',
                '_innerValue': 'Assignment Details',
              'makeUneditable': true,
              },
              'shouldExport': true,
              'tempId': '78f6f585-aa31-4c7f-b57b-c0b530c813fd'
            }
          ]
        },
      ],
      'makeUneditable': true,
    },
    'name': 'two column',
    'shouldExport': true,
    'tempId': '8a2642b9-3f0d-4df6-8b7a-fc08379f435d'
  }
];

let inputsArray = [
  'article_title',
  'article_body'
];
let outputsArray = [];

export default function() {
  return (
  React.createElement( TaskUIComponent( {
      inputs: inputsArray,
      outputs: outputsArray,
    },
    elementsArray ), {
    edit: true,
    handleAddInput: name => {
      inputsArray.add( {
        name,
        required: true,
        type: 'input'
      } );
    },
    handleChangeElements: () => {

    },
    clickComponent: () => {

    },
    toggleTaskUI: ''
  } ));
}

