import React from 'react';
import PropTypes from 'prop-types';
import {StyleSheet, css} from 'aphrodite';

import { Label, Caption } from './type';
import colors from '../styles/colors';

const InputOutput = ({ selected, inputOrOutput, name, workflowName, id, handleSelect }) => {
  return (
    <div key={id} className={css(styles.line)}>
      <div className={css(styles.checkboxContainer, selected && styles.checkedBackground)}>
        <div style={{marginLeft: 8}}>
          <input
            type="checkbox"
            className={css(styles.checkbox)}
            checked={selected}
            onChange={() => handleSelect(id, name, inputOrOutput)} />
        </div>
      </div>
      <div className={css(styles.fieldName, selected && styles.checkedBackground)}>
        {inputOrOutput === 'input' &&
        <span className={css(styles.input)}>
          <Caption>input</Caption>
        </span>
        }
        <span className={css(styles.name)}>
          <Label>{name}</Label>
        </span>
      </div>
      <div className={css(styles.workflowName)}>
        <Caption>{workflowName}</Caption>
      </div>
    </div>
  );

};

const styles = StyleSheet.create({
  line: {
    display: 'flex',
    marginBottom: 20,
  },
  background: {
    border: `1px solid ${colors.light}`,
    borderRadius: 3,
    display: 'inherit',
  },
  checkedBackground: {
    background: colors.green,
  },
  checkboxContainer: {
    background: colors.shaded,
    border: `1px solid ${colors.light}`,
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    flex: .5,
    marginRight: 4,
  },
  checkbox: {
    position: 'relative',
    top: '25%',
    left: '35%',
    margin: 0,
  },
  fieldName: {
    background: colors.shaded,
    border: `1px solid ${colors.light}`,
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    flex: 4,
    margin: '0px 4px',
  },
  input: {
    marginLeft: 20,
    padding: '0px 4px',
    display: 'flex',
    alignItems: 'center',
    background: colors.shaded,
    height: 16,
    borderRadius: 3,
  },
  name: {
    paddingLeft: 20,
  },
  workflowName: {
    flex: 4,
    marginLeft: 4,
  }
});

InputOutput.propTypes = {
  selected:PropTypes.bool,
  input: PropTypes.string,
  output: PropTypes.string,
  name: PropTypes.string,
  workflowName: PropTypes.string,
  id: PropTypes.string,
};

export default InputOutput;