import React from 'react';
import PropTypes from 'prop-types';

import Note from '../../containers/Note';
import colors from '../../styles/colors';
import { Caption } from '../../components/type';
import InlineButton from '../../components/InlineButton';

const ArchivedChain = ({ handleSelect }) => (
  <div style={{ position: 'relative' }}>
    <Note note='You may not make changes to an archived chain.' type="notification"/>
    <div style={styles.archivedContainer}>
      <div style={styles.archivedContent}>
        <div style={styles.archiveMessage}>
          <Caption color='white' center={true}>
            This workflow chain is currently archived.
          </Caption>
        </div>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <InlineButton handleClick={() => handleSelect()}>
            Unarchive Chain
          </InlineButton>
        </div>
      </div>
    </div>
  </div>
);

const styles = {
  archivedContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 400,
    border: `2px solid ${colors.divider}`,
    background: colors.light,
    borderTop: 'none',
    borderRadius: '0 0 5px 5px',
    overflow: 'auto',
    justifyContent: 'center',
  },
  archivedContent: {
    background: colors.dark,
    height: 150,
    width: 300,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 5,
    flexDirection: 'column',

  },
  archiveMessage: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 5,
    padding: '0 16px'
  },
};

ArchivedChain.propTypes = {
  handleSelect: PropTypes.func
};

export default ArchivedChain;