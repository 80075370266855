import React from 'react';
import PropTypes from 'prop-types';

import colors from '../../styles/colors';
import {Caption} from '../../components/type';
import InlineButton from '../../components/InlineButton';

const ArchivedChainPanel = ( { unarchive } ) => (
  <div style={{ position: 'relative', padding: `15 15 0 15` }}>
    <div style={styles.archivedContainer}>
      <div style={styles.archivedContent}>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: 5,
          padding: '0 16px'
        }}>
          <Caption color='white' center={true}>
            This workflow chain is currently archived.
          </Caption>
        </div>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <InlineButton handleClick={() => unarchive()}>
            Unarchive Chain
          </InlineButton>
        </div>
      </div>
    </div>
  </div>
);

const styles = {
  archivedContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 120,
    background: colors.light,
  },
  archivedContent: {
    background: colors.dark,
    height: 90,
    width: 300,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 5,
    flexDirection: 'column',
  },
};


ArchivedChainPanel.propTypes = {
  chain: PropTypes.object,
  unarchive: PropTypes.func,
};

export default ArchivedChainPanel;
