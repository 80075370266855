import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import colors from '../styles/colors';
import Wrapper from './Wrapper';
import { Attention, Content } from '../components/type';

/**
 * HelpPanel
 * Used at the top of page to help explain what's going on
 * Can show a description and a list of links
 * This component maintains its own state for Open and Closed
 */
class HelpPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.toggleOpen = this.toggleOpen.bind(this);
    this.setOpen = this.setOpen.bind(this);
  }

  toggleOpen() {
    this.setOpen(!this.state.open);
  }

  setOpen(open = true) {
    this.setState({ open });
  }

  render() {
    const { pageTitle, pageDescription, links } = this.props;
    return (
      <div style={styles.helpPanel}>
        {/* Toggle */}
        <div style={styles.toggle} onClick={this.toggleOpen}>
          <i className={`fa fa-${this.state.open ? 'close' : 'info-circle'}`}/>
        </div>

        <div className={css(styleSheet.helpPanel, this.state.open && styleSheet.open)}>
          <Wrapper>
            <div style={styles.contents}>

              {/* Left Side */}
              <div className='t-help-panel' style={styles.left}>
                <Attention color="dark">{pageTitle}</Attention>
                <Content color="dark">{pageDescription}</Content>
              </div>

              {/* Right Side */}
              <div style={{ width: 320 }}>
                <Attention color="dark">Want to Learn More?</Attention>
                {links.map((link, i) => (
                  <Content key={i} target="_blank" href={link.href} underline={true} bottom={-5} color="dark">
                    {link.label}
                  </Content>
                ))}
              </div>
            </div>
          </Wrapper>
        </div>
      </div>
    );
  }
}

/* Styles */
const styleSheet = StyleSheet.create({
  helpPanel: {
    maxHeight: 0,
    overflow: 'hidden',
    transition: 'max-height 0.32s ease-in',
    background: colors.background,
    boxSizing: 'border-box',
  },
  open: {
    maxHeight: 400,
    borderBottom: `4px solid #ebebeb`,
    marginBottom: 50,
  },
});

const styles = {
  helpPanel: {
    position: 'relative',
    minHeight: 64,
  },
  contents: {
    display: 'flex',
    paddingTop: 24,
    paddingBottom: 24,
    boxSizing: 'border-box',
  },
  toggle: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    width: 40,
    background: colors.primary(),
    color: 'white',
    cursor: 'pointer',
    fontSize: 18,
  },
  left: {
    flex: 1,
    paddingRight: 16,
  },
};

/* PropTypes */
HelpPanel.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  pageDescription: PropTypes.node.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }))
};

export default HelpPanel;