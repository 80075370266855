import update from 'immutability-helper';

import { SELECT_ELEMENT, SELECT_INPUT, NEW_ELEMENT, DELETE_ELEMENT } from '../actionTypes';

const initialState = {
  selected: null,
  input: null,
  inputIndex: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SELECT_ELEMENT:
    case NEW_ELEMENT:
      if(
        (action.index === state.selected && !action.isChild) ||
        (action.isChild && action.columnIndex == state.selectedColumn && action.index == state.selectedChild)
      ) {
        return state;
      }
      const selectedChildIdx = action.parentIndex == null ? null : action.index;
      if (action.isChild) {
        return update(
          state, {
            $merge: {
              selected: action.parentIndex,
              selectedChild: selectedChildIdx,
              selectedColumn: action.columnIndex,
              input: null,
            }
          }
        );
      }
      return update(state, {
        $merge: {
          selected: action.index,
          selectedChild: selectedChildIdx,
          input: null,
        }
      });
    case DELETE_ELEMENT:
      return update(state, {
        $merge: {
          selected: null
        }
      });
    case SELECT_INPUT:
      return update(state, {$merge: {input: action.input, inputIndex: action.inputIndex}});
    default:
      return state;
  }
}
