import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import numeral from 'numeral';

import { ADD_FUNDS } from '../../constants/modalTypes';
import { toggleModal } from '../../actions/app';
import { addFunds, fetchFunds } from '../../actions/company';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import FlatButton from '../../components/FlatButton';
import InlineButton from '../../components/InlineButton';
import InlineList from '../../components/InlineList';
import InlineSaveState from '../../components/InlineSaveState';
import Input from '../../components/SingleInputForm';
import SelectLabel from '../../components/SelectLabel';
import TextAreaLabel from '../../components/TextAreaLabel';
import { Attention, Label, Title } from '../../components/type';

/**
 * AddFunds
 * Modal to submit request for funds
 */
class AddFunds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: '',
      message: '',
      payment: 'ach',
    };

    this.handleSave = this.handleSave.bind(this);
    this.canSubmit = this.canSubmit.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchFunds());
  }

  handleSave() {
    if (!this.state.saving && this.state.amount && this.state.payment) {
      this.setState({ saving: true });
      this.props.dispatch(addFunds({
        amount: this.state.amount,
        message: this.state.message,
        payment: this.state.payment,
      }))
        .then(() => {
          this.setState({ saving: false, saved: true }, () => {
            setTimeout(() => {
              this.setState({ complete: true });
            }, 1200);
          });
        });
    }
  }

  canSubmit() {
    return this.state.saving || !this.state.amount || !this.state.payment;
  }

  renderComplete() {
    return (
      <div
        style={{
          paddingTop: 32,
          paddingBottom: 32,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Label bottom={24}>
          Thanks! A member of our account team will contact you shortly about adding funds to your
          account.
        </Label>
        <Button handleClick={() => this.props.dispatch(toggleModal(ADD_FUNDS))}>Got It</Button>
      </div>
    );
  }

  render() {
    if (this.state.complete) {
      return this.renderComplete();
    }
    return (
      <div className={css(styles.addFunds)}>
        <Label bottom={24}>
          Submit a request to add funds to your account below. Simply enter the amount you would
          like added to your account balance and our team will respond as soon as possible.
        </Label>

        <div className={css(styles.table)}>

          <div className={css(styles.column, styles.balanceLabel)}>
            <div className={css(styles.tableHead)}>
            </div>
            <Title color="light" className={css(styles.tableBody)}>
              Balance
            </Title>
          </div>

          <div
            className={css(styles.column)}
            style={{
              borderLeft: `1px solid ${colors.border}`,
              borderRight: `1px solid ${colors.border}`
            }}>
            <Title color="light" className={css(styles.tableHead)}>
              CURRENT BALANCE
            </Title>
            <Attention color="dark" className={css(styles.tableBody)}>
              {!this.props.isFetchingFunds ?
                numeral(this.props.availableBalance).format('$0,0.00')
                :
                <i className="fa fa-spin fa-spinner"/>
              }
            </Attention>
          </div>

          <div className={css(styles.column)}>
            <Title color="light" className={css(styles.tableHead)}>
              ENTER AMOUNT TO INCREASE BALANCE
            </Title>
            <div className={css(styles.tableBody)}>
              <Input
                value={this.state.amount}
                handleChange={amount => this.setState({ amount })}
              />
            </div>
          </div>
        </div>

        <div style={{ marginTop: 24 }}>
          <SelectLabel
            label="Select Preferred Payment Method:"
            value={this.state.payment}
            handleSelect={payment => this.setState({ payment })}
            options={[
              { label: 'ACH', value: 'ach' },
              { label: 'Credit Card', value: 'credit-card' },
              { label: 'Check', value: 'check' },
              { label: 'PayPal', value: 'paypal' },
              { label: 'Wire Transfer', value: 'wire-transfer' },
            ]}
          />
        </div>
        <div style={{ marginTop: 24 }}>
          <TextAreaLabel
            label="Leave a message (optional)"
            value={this.state.message}
            handleChange={message => this.setState({ message })}
          />
        </div>

        {/* Footer */}
        <div className={css(styles.footer)}>
          <InlineList spacing={16}>
            <InlineSaveState
              saving={this.state.saving}
              savedLabel="Request Submitted!"
              savingLabel="Submitting Request"
            />
            <InlineButton
              disabled={this.canSubmit()}
              handleClick={this.handleSave}>
              Submit
            </InlineButton>
            <FlatButton
              disabled={this.state.saving || this.state.saved}
              handleClick={() => this.props.dispatch(toggleModal(ADD_FUNDS))}>
              Cancel
            </FlatButton>
          </InlineList>
        </div>

      </div>
    );
  }
}

const styles = StyleSheet.create({
  addFunds: {
    display: 'flex',
    flexDirection: 'column',
  },
  table: {
    display: 'flex',
    borderTop: `2px solid ${colors.border}`,
    borderBottom: `2px solid ${colors.border}`,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableBody: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    boxSizing: 'border-box',
  },
  tableHead: {
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `1px solid ${colors.border}`,
    paddingLeft: 16,
    paddingRight: 16,
  },
  balanceLabel: {
    flex: 1,
  },
  footer: {
    alignSelf: 'flex-end',
    paddingTop: 16,
  },
});

function select(state) {
  return {
    availableBalance: state.app.availableBalance,
    isFetchingFunds: state.app.isFetchingFunds,
  };
}
export default connect(select)(AddFunds);
