import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { connect } from 'react-redux';

import stateHelpers from '../../utils/stateHelpers';

import objectToArray from '../../utils/_objectToArray';
import { fetchProjectWorkflows } from '../../actions/projects';
import colors from '../../styles/colors';
import Panel from '../../containers/Panel';
import LoadState from '../../containers/LoadState';
import EmptyState from '../../containers/EmptyState';
import Tile from '../../components/Tile';
import InlineList from '../../components/InlineList';
import { Label } from '../../components/type';

function getWorkflowHref(workflow) {
  if (workflow.id_swf) {
    return `/workflows/${workflow.id}`;
  }
  if (workflow.workflow_steps > 0) {
    return `/workflows/${workflow.id}/steps`;
  }
  return `/projects/${workflow.id_project}/workflows/${workflow.id}`;
}

function getWorkflowLabel({ name, launched, s3_filename }) {
  return (
    <span>
      {name}
      {!launched ? s3_filename ?
          <span>: <span style={{ textDecoration: 'underline' }}>Resume Setup</span></span> :
          <span>: <span style={{ textDecoration: 'underline' }}>Set Up</span></span> : ''
      }
    </span>
  );
}

class ProjectWorkflows extends Component {
  constructor(props) {
    super(props);
    this.state = {
      panelActions: [],
    };
  }
  componentWillMount() {
    this.props.dispatch(fetchProjectWorkflows(this.props.id)).then(() => this.getPanelActions());
  }

  getPanelActions() {
    let actions = [
      {
        icon: 'plus-square',
        href: `/projects/${this.props.id}/create-workflow`,
        label: 'Add Workflow'
      }
    ];
    if(this.props.workflows.some(wf => {return wf.archived.toString() === '1';})) {
      actions.unshift({
        icon: 'archive',
        href: `/workflows?sort=last-batch&projects=${this.props.id}&status=archived`,
        label: 'View Archived'
      });
    }
    this.setState({panelActions: actions});
  }

  render() {
    const { panelActions } = this.state;
    const { workflows, userCanEdit } = this.props;
    return (
      <Panel
        actions={ userCanEdit ? panelActions : [] }
        title="Workflows"
        simple={true}>
        <div className={css(styles.panel)}>
          {this.props.loadingWorkflows &&
          <LoadState />
          }
          {(!this.props.loadingWorkflows && !workflows.filter(w => w.state !== 'archived').length) &&
          <EmptyState label="No Workflows"/>
          }
          {(!this.props.loadingWorkflows && !!workflows.length) &&
          <div className={css(styles.tiles)}>
            <InlineList spacing={8} flexWrap={true}>
              {workflows.filter(w => w.state !== 'archived').map(workflow => (
                <div
                  key={workflow.id}
                  style={{ marginBottom: 8 }}
                  className="project-workflow">
                  <Tile
                    label={getWorkflowLabel(workflow)}
                    href={userCanEdit ? getWorkflowHref(workflow) : null}
                    incomplete={['pending', 'draft'].indexOf(workflow.state) >= 0}
                    active={workflow.state === 'active'}
                  />
                </div>
              ))}
            </InlineList>
          </div>
          }
          <div className={css(styles.key)}>
            <div className={css(styles.keyItem)}>
              <div className={css(styles.keyColor)} style={{ background: colors.positiveLight }}/>
              <Label uppercase={true} color="light">Active</Label>
            </div>
            <div className={css(styles.keyItem)}>
              <div
                className={css(styles.keyColor)}
                style={{ background: colors.background }}
              />
              <Label uppercase={true} color="light">Inactive</Label>
            </div>
            <div className={css(styles.keyItem)}>
              <div
                className={css(styles.keyColor, styles.keyInactive)}
                style={{ background: colors.background }}
              />
              <Label uppercase={true} color="light">Draft</Label>
            </div>
          </div>
        </div>
      </Panel>
    );
  }
}

const styles = StyleSheet.create({
  tiles: {
    marginBottom: -8,
    padding: 16,
    boxSizing: 'border-box',
  },
  key: {
    padding: '8px 16px',
    marginRight: -8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${colors.divider}`,
  },
  keyItem: {
    marginRight: 8,
    marginLeft: 8,
    display: 'flex',
    alignItems: 'center',
  },
  keyColor: {
    height: 16,
    width: 16,
    border: `1px solid ${colors.border}`,
    marginRight: 6,
  },
  keyInactive: {
    border: `1px dashed ${colors.border}`,
  },
});

let projectId;
function isLoading(state) {
  const project = state.projects.items[projectId];
  return !project || !project.workflows || project.workflows.isFetching;
}
function select(state, { id }) {
  if (id) {
    projectId = id;
  }
  return {
    workflows: objectToArray(state.workflows.items).filter(workflow => {
      const workflowProjectId = workflow.id_project || workflow.projectId;
      return !!workflowProjectId && (workflowProjectId.toString() === projectId.toString());
    }),
    loadingWorkflows: isLoading(state),
    userCanEdit: stateHelpers.canEdit(state.projects.items[projectId], state),
  };
}
export default connect(select)(ProjectWorkflows);
