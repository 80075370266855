import React, { Component } from 'react';
import { connect } from 'react-redux';
import { css, StyleSheet } from 'aphrodite';
import numeral from 'numeral';

import { estimateTimeToCompletion } from '../../utils/workflows';
import IconButton from '../../components/IconButton';
import colors from '../../styles/colors';
import ItemCountContainer from './ItemCountContainer';
import IconButtonContainer from './IconButtonContainer';
import DisabledPanel from './DisabledPanel';
import { Label, Attention, Caption } from '../../components/type';
import { RELEASE_ITEMS, UNARCHIVE_WORKFLOW } from '../../constants/modalTypes';
import { toggleModal } from '../../actions/app';


class WorkflowMetrics extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      openAdvancedMetrics: false,
    };
  };

  handleClickReleaseItems( workflow ) {
    const stagedItems = workflow.itemStatus.staged;
    const meta = {
      workflowId: workflow.id,
      stagedItemCount: stagedItems ? stagedItems : 0,
      batchType: 'workflow',
    };
    this.props.dispatch( toggleModal( RELEASE_ITEMS, meta ) );
  }

  getPanelContent() {
    const { workflow } = this.props;
    return (
      <DisabledPanel
        description={'This workflow is currently archived.'}
        btnText={'Unarchive Workflow'}
        handleClick={() => this.props.dispatch( toggleModal( UNARCHIVE_WORKFLOW, {workflowId: workflow.id, archive: false} ) )}
      />
    );
  }

  render() {
    const { openAdvancedMetrics } = this.state;
    const { workflow } = this.props;
    const wfStepCount = workflow.workflow_steps && workflow.workflow_steps.toString();
    const statuses = workflow.itemStatus || {};
    const staged = statuses.staged || 0;
    const paused = statuses.paused || 0;
    const step1 = statuses['step 1'] || 0;
    const step2 = statuses['step 2'] || 0;
    const step3 = statuses['step 3'] || 0;
    const canceled = statuses.canceled || 0;
    const completed = statuses.completed || 0;
    const archived = workflow.archived.toString() !== '0';

    return (
      <div style={Object.assign({}, style.metricsContainer, archived && {background: colors.shaded})}>
        <div className={css(styles.itemsContainer)}>
          {archived ?
            this.getPanelContent()
            :
            <div style={{ display: 'flex', flex: 6 }}>
              <div className={css( styles.itemStatus )}>
                <ItemCountContainer
                  title="Staged"
                  count={staged}
                  color="shaded"
                  itemHref={`/search/workflow-items?status[]=staged&workflow[]=${workflow.id_swf}`}/>
                <IconButtonContainer
                  color="shaded"
                  icon="upload"
                  text="Upload"
                  href={`/workflows/${workflow.id}/batch`}/>
              </div>
              <div className={css( styles.releaseContainer )}>
                {!!staged ?
                  <IconButton
                    label="Release"
                    handleClick={() => this.handleClickReleaseItems( workflow )}
                    icon="arrow-right"
                    underline={true}>
                    Release
                  </IconButton> :
                  <div style={{ fontSize: 22, color: colors.dark }}>
                    <i className='fa fa-arrow-right fa-3'/>
                  </div>
                }
              </div>
              <div className={css( styles.itemStatus )}>
                <ItemCountContainer
                  title="Paused"
                  count={paused}
                  color="shaded"
                  itemHref={`/search/workflow-items?status[]=paused&workflow[]=${workflow.id_swf}`}/>
                {!!paused ?
                  <IconButtonContainer
                    color="shaded"
                    icon="play-circle-o"
                    text="Resume"
                    href={`/workflows/${workflow.id}/batch`}/>
                  :
                  <IconButtonContainer
                    color="shaded"
                    icon="pause-circle-o"
                    text="Pause"
                    href={`/workflows/${workflow.id}/batch`}/>
                }
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', flex: 3, margin: '0px 2px 1px' }}>
                <div style={{display: 'flex', flexGrow: 1}}>
                  <div style={{flexGrow: 1, background: colors.secondary()}}>
                    <ItemCountContainer
                      title="Step 1"
                      count={step1}
                      color="blue"
                      itemHref={`/search/workflow-items?status[]=step+1&workflow[]=${workflow.id_swf}`}
                      isReviewStep={false}
                      reviewHref={`${this.props.workstationUrl}find-work%3Fsearch%3D${workflow.review_assignment_name}`} />
                  </div>
                  {wfStepCount >= 2 &&
                  <div style={{ flexGrow: 1, marginLeft: 1, background: colors.secondary() }}>
                    <ItemCountContainer
                      title="Step 2"
                      count={step2}
                      color="blue"
                      itemHref={`/search/workflow-items?status[]=step+2&workflow[]=${workflow.id_swf}`}
                      isReviewStep={wfStepCount === '2'}
                      reviewHref={`${this.props.workstationUrl}find-work%3Fsearch%3D${workflow.review_assignment_name}`} />
                  </div>
                  }
                  {wfStepCount >= 3 &&
                  <div style={{ flexGrow: 1, marginLeft: 1, background: colors.secondary() }}>
                    <ItemCountContainer
                      title="Step 3"
                      count={step3}
                      color="blue"
                      itemHref={`/search/workflow-items?status[]=step+3&workflow[]=${workflow.id_swf}`}
                      isReviewStep={wfStepCount === '3'}
                      reviewHref={`${this.props.workstationUrl}find-work%3Fsearch%3D${workflow.review_assignment_name}`} />
                  </div>
                  }
                </div>
                <IconButtonContainer
                  color="blue"
                  icon="bar-chart"
                  text="Monitor Steps"
                  href={`/workflows/${workflow.id}/assignments/monitor`}/>
              </div>
            </div>
          }
          <div className={css(styles.itemStatus)}>
            <ItemCountContainer
              title="Completed"
              count={completed}
              color="green"
              itemHref={`/search/workflow-items?status[]=completed&workflow[]=${workflow.id_swf}`}/>
            <IconButtonContainer
              color="green"
              icon="download"
              text="Download"
              href={`/workflows/${workflow.id}/batch`}/>
          </div>
        </div>
        {!archived &&
          <div>
            <IconButton
              iconPosition="right"
              handleClick={() => this.setState((prevState) => {
                if(archived) {
                  return;
                }
                return {openAdvancedMetrics: !prevState.openAdvancedMetrics};
              })}
              icon={openAdvancedMetrics ? 'chevron-up' : 'chevron-down'}>
              <div style={{textDecoration: 'none !important'}}>Advanced Metrics</div>
            </IconButton>
          </div>
        }
        {openAdvancedMetrics &&
        <div className={css(styles.advancedMetricsContainer)}>
          <div style={{display: 'flex', borderBottom: `1px solid ${colors.border}`}}>
            <div style={{display: 'flex', flexDirection: 'column', flex: 1, padding: '16px 32px 16px 16px'}}>
              <Label color="light">Avg. Cost per Item</Label>
              <Attention color="dark">{numeral( workflow.avgCostPerItem ).format( '$0,0.00' )}</Attention>
            </div>
            <div
              style={{display: 'flex', flexDirection: 'column',flex: 1, borderRight: `1px solid ${colors.border}`, borderLeft: `1px solid ${colors.border}`, padding: '16px 16px 16px 32px'}}>
              <Label color="light">Rejection Rate</Label>
              <Attention color="dark">{numeral( workflow.rejectionRate ).format( '0.00%' )}</Attention>
            </div>
            <div style={{display: 'flex', flexDirection: 'column',flex: 1, padding: '16px 16px 16px 32px'}}>
              <Label color="light">Trending Velocity</Label>
              <div style={{display: 'flex', alignItems: 'flex-end'}}>
                <Attention color="dark">{numeral( workflow.trendingVelocity ).format( '0,0' )}</Attention>
                <div style={{marginLeft: 5}}>
                  <Caption color="dark">/ day</Caption>
                </div>
              </div>
            </div>
          </div>
          <div style={{display: 'flex'}}>
            <div style={{display: 'flex', flexDirection: 'column',flex: 1, padding: '16px 32px 16px 16px'}}>
              <Label color="light">Est. Time Remaining</Label>
              <div style={{display: 'flex', alignItems: 'flex-end'}}>
                <Attention color="dark">{estimateTimeToCompletion( workflow )}</Attention>
              </div>
            </div>
            <div
              style={{display: 'flex', flexDirection: 'column',flex: 1, borderRight: `1px solid ${colors.border}`, borderLeft: `1px solid ${colors.border}`, padding: '16px 16px 16px 32px'}}>
              <Label color="light">Canceled Items</Label>
              <Attention color="dark"
                         href={`/search/workflow-items?status[]=canceled&workflow[]=${workflow.id_swf}`}>{canceled}</Attention>
            </div>
            <div style={{flex: 1, padding: '16px 16px 16px 32px'}}>
              &nbsp;
            </div>
          </div>
        </div>
        }
      </div>
    );
  }
}

const styles = StyleSheet.create( {
  itemsContainer: {
    display: 'flex',
    borderBottom: `1px solid ${colors.border}`,
    paddingBottom: 16,
    marginBottom: 16
  },
  advancedMetricsContainer: {
    borderTop: `1px solid ${colors.border}`,
    paddingTop: 16,
    marginTop: 16,
  },
  itemStatus: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    margin: '0px 2px 1px',
  },
  releaseContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    margin: '0px 2px 1px'
  },
} );

const style = {
  metricsContainer: {
    padding: 16,
    borderRadius: '0px 0px 5px 5px'
  },
};

function select(state) {
  return {
    workstationUrl: state.app.workstationUrl,
  };
}

export default connect( select )( WorkflowMetrics );