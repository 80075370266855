import React from 'react';
import PropTypes from 'prop-types';

import { Label } from './type';

/**
 * InlineButton
 * Smaller than "Button". Used inline with other components
 */
const TransparentInlineButton = ({ children, disabled, handleClick }) => (
  <div style={styles.button} onClick={e => !disabled && handleClick(e)}>
    <Label
      color={'light'}
      isLink={!disabled}>
      {children}
    </Label>
  </div>
);

/* Styles */
const styles = {
  button: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 32,
    minWidth: 32,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 8,
    cursor: 'pointer',
    border: '2px solid #858484'
  },
};

/* PropTypes */
TransparentInlineButton.propTypes = {
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
  children: PropTypes.node,
};

export default TransparentInlineButton;