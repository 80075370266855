export default function (requiredProp, type) {
  return function (props, propName, componentName) {
    if (props[requiredProp] && props[propName] === undefined) {
      return new Error(`${propName} required when ${requiredProp} is present in ${componentName}`);
    }
    if (props[propName] && typeof props[propName] !== type) {
      return new Error(`${propName} must be a ${type}`);
    }
  };
}
