import uuid from 'uuid';

import {ElementOptions} from '../constants';

import {
  NEW_ELEMENT,
  NEW_CHILD_ELEMENT,
  MOVE_ELEMENT,
  UPDATE_ELEMENT,
  DELETE_ELEMENT,
  REPEATABLE_ELEMENT,
} from '../actionTypes';

export function newElementAt(newElementIndex, type, targetIndex, element) {
  if(element.customId && element.data.columns && element.type !== 'CHECKBOX' && element.type !== 'RADIOBUTTON') {
    element.data.columns.map(column => {
      column.children.map(child => {
        child.tempId = uuid.v4();
      });
    });
  }
  return {
    type: NEW_ELEMENT,
    newElementIndex,
    element: {
      type,
      tempId: uuid.v4(),
      name: element.customId ? element.name : ElementOptions.find(e => e.type === type).name,
      data: element.customId ? JSON.parse(JSON.stringify(element.data)) : JSON.parse(JSON.stringify(ElementOptions.find(e => e.type === type).defaultData)),
      customId: element.customId ? element.customId : null,
    }
  };
}

export function newChildElementAt(newElementIndex, type, targetIndex, columnIndex, element) {
  return {
    type: NEW_CHILD_ELEMENT,
    newElementIndex,
    targetIndex,
    columnIndex,
    element: {
      type,
      tempId: uuid.v4(),
      name: element.customId ? element.name : ElementOptions.find(e => e.type === type).name,
      data: element.customId ? JSON.parse(JSON.stringify(element.data)) : JSON.parse(JSON.stringify(ElementOptions.find(e => e.type === type).defaultData)),
      customId: element.customId ? element.customId : null,
    }
  };
}

export function moveElementTo(to, from) {
  return {
    type: MOVE_ELEMENT,
    to,
    from,
  };
}
export function updateTaskUIElement(index, update) {
  if (update.output && !Array.isArray(index)) {
    update.output = update.output.replace(/^[\W0-9]+|xml|[^\w-\.:]+/gim, '');
  } else if (update.output && Array.isArray(index)) {
    update.output = update.output.replace(/^[\W0-9]+|xml|[^\w-\.:\#\{\}]+/gim, '');
  }
  return {
    type: UPDATE_ELEMENT,
    index,
    update
  };
}

export function deleteElement(index, selectedChild, colIdx) {
  return {
    type: DELETE_ELEMENT,
    index,
    selectedChild,
    colIdx,
  };
}

export function makeRepeatable(index, num, range) {
  return {
    type: REPEATABLE_ELEMENT,
    index,
    num,
    range,
  };
}
