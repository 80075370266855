import React, {Component} from 'react';

import colors from '../../styles/colors';
import CodeToggle from 'react-code-toggle';

import * as type from '../../components/type';
import Button from '../../components/Button';


import ButtonGroup from '../../components/ButtonGroup';
import Filter from '../../components/Filter';
import FlatButton from '../../components/FlatButton';
import GreenOverlay from '../../components/GreenOverlay';
import IconButton from '../../components/IconButton';
import IconCount from '../../components/IconCount';
import IconPanel from '../../components/IconPanel';
import InlineButton from '../../components/InlineButton';
import TransparentInlineButton from '../../components/TransparentInlineButton';
import InlineEdit from '../../components/InlineEdit';
import InlineList from '../../components/InlineList';
import InlineSaveState from '../../components/InlineSaveState';
import InlineSearch from '../../components/InlineSearch';
import InlineSelect from '../../components/InlineSelect';
import Input from '../../components/Input';
import ItemList from '../../components/ItemList';
import Logo from '../../components/Logo';
import Menu from '../../components/Menu';
import Select from '../../components/Select';
import Table, {Row, Column} from '../../components/Table';
import TextArea from '../../components/TextArea';
import Tile from '../../components/Tile';
import ShadedTextTile from '../../components/ShadedTextTile';
import TinyMCE from '../../components/TinyMCE/index';
import DatePicker from '../../components/DatePicker/index';

const lotsOfOptions = [];
for (let i = 0; i < 100; i++) {
  lotsOfOptions.push({label: `Foo${i}`, value: `foo${i}`});
}

const Color = ({color, name, dark}) => (
  <div
    style={{
      width: 220,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginBottom: 16,
      height: 72,
      background: typeof color === 'function' ? color() : color,
      paddingLeft: 8,
      paddingRight: 8,
      boxSizing: 'border-box',
    }}>
    <type.Label color={!dark ? 'white' : 'light'}>{color}</type.Label>
    <type.Regular color={!dark ? 'white' : 'light'}>{name}</type.Regular>
  </div>
);

const CompNote = ({children}) => (
  <div style={{ padding: '16px 8px', background: colors.background, marginBottom: 16 }}>
    <type.Regular>{children}</type.Regular>
  </div>
);

const CodeBlock = ({code}) => (
  <div
    style={{
      padding: 8,
      background: colors.darker,
      marginBottom: 40,
      marginTop: 8,
      borderRadius: 2,
    }}>

    {code.map((c, i) => {
      const firstOrLast = typeof c === 'string' && (i === 0 || i === code.length - 1);
      const openIndex = typeof c === 'string' && c.indexOf('{');
      const closeIndex = typeof c === 'string' && c.indexOf('}');
      let codeString;
      if (openIndex && closeIndex && openIndex < closeIndex) {
        codeString = (
          <div style={{ display: 'flex' }}>
            {c.slice(0, openIndex)}
            <type.Caption color="negative">{c.slice(openIndex, closeIndex + 1)}</type.Caption>
            {c.slice(closeIndex + 1)}
          </div>
        );
      } else {
        codeString = c;
      }
      return (
        <div key={i} style={{ marginLeft: firstOrLast ? 0 : 16 }}>
          <type.Caption
            color={firstOrLast ? 'blue' : 'green'}>{codeString}</type.Caption>
        </div>
      );
    })}
  </div>
);

class StyleGuide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      shoeBrand: '',
      aboutMe: '',
      name: 'cat',
      fooBar: 'foo',
    };
  }

  render() {
    return (
      <div style={{ padding: 32, position: 'relative' }}>
        <type.Heading bottom={16}>OneSpace Style Guide</type.Heading>
        <div style={{ marginBottom: 48 }}>
          <InlineList spacing={16}>
            <IconButton
              icon={this.state.b ? 'check-square-o' : 'square-o'}
              handleClick={() => this.setState({ b: !this.state.b })}>
              Baseline Grid
            </IconButton>
          </InlineList>
        </div>
        {this.state.b &&
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            backgroundImage: 'url("http://i.imgur.com/Xz8x96a.png")',
            pointerEvents: 'none',
            opacity: 0.6,
          }}
        />
        }


        {/* Colors */}
        {/* Brand */}
        <type.Title color="light" uppercase={true}>Brand Colors</type.Title>
        <type.Regular bottom={24}>
          Primary brand colors are used for elements that reflect the brand.
        </type.Regular>
        <InlineList spacing={16} flexWrap={true}>
          <div style={{ width: 220 }}>
            <Color name="Primary" color={colors.primary()}/>
          </div>
          <div style={{ width: 220 }}>
            <Color name="Secondary" color={colors.secondary()} dark={true}/>
          </div>
        </InlineList>
        {/* Type */}
        <type.Title color="light" uppercase={true} top={32}>Type Colors</type.Title>
        <type.Regular bottom={24}>
          These are the different levels of grey we currently use for type.
        </type.Regular>
        <InlineList spacing={16} flexWrap={true}>
          <Color name="Darker" color={colors.darker}/>
          <Color name="Dark" color={colors.dark}/>
          <Color name="Grey" color={colors.grey}/>
          <Color name="Light" color={colors.light}/>
          <Color name="Lighter" color={colors.lighter}/>
        </InlineList>
        {/* UI */}
        <type.Title color="light" uppercase={true} top={32}>UI Colors</type.Title>
        <type.Regular bottom={24}>
          UI colors give shape and meaning to components throughout our UI.
        </type.Regular>
        <InlineList spacing={16} flexWrap={true}>
          <Color name="Placeholder" color={colors.placeholder}/>
          <Color name="Border" color={colors.border} dark={true}/>
          <Color name="Divider" color={colors.divider} dark={true}/>
          <Color name="Shaded" color={colors.shaded} dark={true}/>
          <Color name="Background" color={colors.background} dark={true}/>
        </InlineList>
        <InlineList spacing={16} flexWrap={true}>
          <Color name="Positive" color={colors.positive}/>
          <Color name="Positive Light" color={colors.positiveLight} dark={true}/>
          <Color name="Negative" color={colors.negative}/>
          <Color name="Negative Light" color={colors.negativeLight}/>
          <Color name="Highlight" color={colors.highlight} dark={true}/>
        </InlineList>
        {/* Status */}
        <type.Title color="light" uppercase={true} top={32}>Status Colors</type.Title>
        <type.Regular bottom={24}>
          These colors are used to indicate the status of items.
        </type.Regular>
        <InlineList spacing={16} flexWrap={true}>
          <Color name="Orange" color={colors.orange}/>
          <Color name="Green" color={colors.green}/>
          <Color name="Blue" color={colors.blue}/>
          <Color name="Purple" color={colors.purple}/>
        </InlineList>

        {/* Type */}
        <type.Title color="light" uppercase={true} top={96} bottom={8}>Typography</type.Title>

        <type.Heading>Heading 36px 800</type.Heading>
        <CompNote>Used at the top of pages. Use colors.dark</CompNote>
        <type.Subheading>Subheading 24px 800</type.Subheading>
        <CompNote>Used at the top of large sections</CompNote>
        <type.Attention>Attention 18px 800</type.Attention>
        <CompNote>Used on CTAs</CompNote>
        <type.Title>Title 16px 800</type.Title>
        <CompNote>Used as top-level text on most components</CompNote>
        <type.Regular>Regular 13px 400</type.Regular>
        <CompNote>Main font used on bodies of text</CompNote>
        <type.Label>Label 12px 800</type.Label>
        <CompNote>Used to label things</CompNote>
        <type.Caption>Caption 12px 600</type.Caption>
        <CompNote>Used to display data</CompNote>


        {/* Components */}
        {/* Brand */}
        <type.Title color="light" uppercase={true} top={96} bottom={16}>Brand Components
        </type.Title>

        <CodeToggle>
          <Logo primary={colors.primary()} secondary={colors.secondary()} showText={true}/>
        </CodeToggle>

        <CodeToggle>
          <Logo primary="rgb(26, 142, 251)" secondary="rgb(148, 203, 255)"/>
        </CodeToggle>


        {/* Buttons */}
        <type.Title color="light" uppercase={true} top={96} bottom={16}>Buttons</type.Title>

        <Button handleClick={() => alert('click')}>Button</Button>

        <InlineButton handleClick={() => alert('click')}>InlineButton</InlineButton>
        <CodeBlock code={[
          '<InlineButton',
          'disabled={Boolean}',
          'handleClick={Function(event)}',
          'children={Node}',
          '/>',
        ]}/>

        <TransparentInlineButton handleClick={() => alert('click')}>TransparentInlineButton</TransparentInlineButton>
        <CodeBlock code={[
          '<TransparentInlineButton',
          'disabled={Boolean}',
          'handleClick={Function(event)}',
          'children={Node}',
          '/>',
        ]}/>

        <FlatButton handleClick={() => alert('click')}>FlatButton</FlatButton>
        <CodeBlock code={[
          '<FlatButton',
          'disabled={Boolean}',
          'handleClick={Function(event)}',
          '/>'
        ]}/>

        <IconButton icon="bell" handleClick={() => alert('click')}>Silver Bells</IconButton>
        <CodeBlock code={[
          '<IconButton',
          'icon={String}',
          'iconPosition={"left", "right"}',
          'href={String}',
          'disabled={Boolean}',
          'children={Node}',
          'handleClick={Function(event)}',
          '/>',
        ]}/>


        <div style={{ marginBottom: 8 }}>
          <type.Regular>Small:</type.Regular>
          <ButtonGroup
            options={[
              { label: 'Foo', value: 'foo', selected: this.state.fooBar === 'foo' },
              { label: 'Bar', value: 'bar', selected: this.state.fooBar === 'bar' }
            ]}
            handleSelect={x => this.setState({ fooBar: x })}
          />
        </div>
        <type.Regular>Large:</type.Regular>
        <ButtonGroup
          options={[
            { label: 'Hot', value: 'hot', selected: this.state.fooBar === 'hot' },
            { label: 'Dog', value: 'dog', selected: this.state.fooBar === 'dog' }
          ]}
          size="large"
          handleSelect={x => this.setState({ fooBar: x })}
        />
        <CodeBlock code={[
          '<ButtonGroup',
          'options={{label: String, value: String, selected: Boolean}}',
          'size={"small", "large"}',
          'handleSelect={Function(selectedValue)}',
          '/>',
        ]}/>

        <IconPanel icon="bell" label="Bells" href="teachok.com"/>
        <CodeBlock code={[
          '<IconPanel',
          'icon={String}',
          'label={String}',
          'href={String}',
          '/>',
        ]}/>

        {/* Layout Components */}
        <type.Title color="light" uppercase={true} top={96} bottom={16}>Layout Components
        </type.Title>
        <InlineList spacing={24}>
          <type.Label>Item 1</type.Label>
          <type.Label>Item 2</type.Label>
          <type.Label>Item 3</type.Label>
        </InlineList>
        <CodeBlock code={[
          '<InlineList',
          'spacing={Number}',
          'justify={["flex-end", "flex-start", "center", "space-between"]}',
          'flexWrap={Boolean}',
          'children={[Node]}',
          '/>',
        ]}/>

        <CodeToggle>
          <Table headerCols={
            [
              {'label': 'OneHeader', 'width': '50%'},
              {'label': 'TwoHeader', 'width': '50%'}
            ]
          }>
            {[{'label': 'one'}, {'label': 'two'}].map((item, idx) => (
              <Row fresh={false} key={idx}>
                <Column first={true}>
                  <div>{item.label}</div>
                </Column>
                <Column>
                  <div>{item.label} or any other component</div>
                </Column>
              </Row>
            ))}
          </Table>
        </CodeToggle>

        <Filter
          multiple={true}
          name="Dogs"
          options={[{ label: 'Hound', value: 'hound' }, { label: 'Beagle', value: 'bea' }]}
          handleSelect={dog => console.log(dog)}
        />
        <Filter
          name="Cats"
          options={[{ label: 'Persian', value: 'persian' }, { label: 'House', value: 'house' }]}
          handleSelect={cat => console.log(cat)}
        />
        <CodeBlock
          code={[
            '<Filter',
            'name={String}',
            'options={[{label: String, value: String}]}',
            'multiple={Boolean}',
            'selected={Boolean || [Boolean]}',
            'alignMenu={"left", "right"}',
            'handleSelect={Function(selectedValue)}',
            '/>',
          ]}
        />


        {/* Status Components */}
        <type.Title color="light" uppercase={true} top={96} bottom={16}>Status Components
        </type.Title>
        <IconCount icon="bell" count={0}/>
        <IconCount icon="bell" count={4}/>
        <CodeBlock code={[
          '<IconCount',
          'icon={String}',
          'count={Number}',
          '/>'
        ]}/>

        <div style={{ position: 'relative', height: 80 }}>
          <GreenOverlay content="Hello World"/>
        </div>
        <CodeBlock
          code={[
            '<GreenOverlay',
            'content={String}',
            '/>',
          ]}
        />

        <InlineList spacing={8}>
          <Tile label="Hello World" href="github.com"/>
          <Tile label="Hello World" href="github.com" incomplete={true}/>
          <Tile label="Hello World" href="github.com" active={true}/>
          <Tile label="Hello World" href="github.com" failed={true}/>
        </InlineList>
        <CodeBlock code={[
          '<Tile',
          'label={String}',
          'href={String}',
          'incomplete={Boolean}',
          'active={Boolean}',
          'failed={Boolean}',
          '/>',
        ]}/>

        <div style={{maxWidth: 130}}>
          <ShadedTextTile label="ShadedTextTile"/>
        </div>
        <CodeBlock
          code={[
            '<ShadedTextTile',
            'label={String}',
            'href={String}',
            '/>'
          ]}
        />

        {/* Form Components */}

        <type.Title color="light" uppercase={true} top={96} bottom={16}>Form Components</type.Title>
        <Input
          value={this.state.shoeBrand}
          placeholder="Favorite Shoe Brand"
          error={this.state.shoeError && 'Just do it'}
          handleChange={shoeBrand => this.setState({ shoeBrand, shoeError: shoeBrand === 'nike' })}
        />
        <CodeBlock code={[
          '<Input',
          'name={String}',
          'type={"text", "password", "number", "email", "radio"}',
          'value={String}',
          'placeholder={String}',
          'disabled={Boolean}',
          'handleChange={Function(change)}',
          'handleGetInput={Function(ref)}',
          '/>',
        ]}/>

        <TextArea value={this.state.aboutMe} handleChange={aboutMe => this.setState({ aboutMe })}/>
        <CodeBlock
          code={[
            '<TextArea',
            'value={String}',
            'disabled={Boolean}',
            'rows={Number}',
            'handleChange={Function(change)}',
            '/>',
          ]}
        />


        <TinyMCE body={'This is a Tiny MCE Component'}/>
        <CodeBlock
          code={[
            '<RichTextEditor',
            'ref={string}',
            'body={string}',
            '/>',
          ]}
        />

        <Select
          searchable={true}
          value={this.state.selected}
          options={lotsOfOptions}
          handleSelect={selected => this.setState({ selected })}
        />
        <CodeBlock code={[
          '<Select',
          'value={String | Number}',
          'options={[{value: String, label: String, fixed: Boolean}]}',
          'searchable={Boolean}',
          '/>',
        ]}/>

        <InlineSelect
          defaultLabel="Choose One"
          value={this.state.selected}
          options={[
            { label: 'Foo', value: 'foo' },
            { label: 'Bar', value: 'bar' },
          ]}
          handleSelect={selected => this.setState({ selected })}
        />
        <CodeBlock code={[
          '<InlineSelect',
          'value={String}',
          'options={[{label: String, value: String}]}',
          'disabled={Boolean}',
          'defaultLabel={String}',
          'showDefault={Boolean}',
          'handleSelect={Function(value)}',
          '/>',
        ]}/>

        <InlineEdit
          label={this.state.name}
          editLabel="Edit"
          error={this.state.nameError}
          saving={this.state.isSaving}
          handleSave={name => this.setState({
            name,
            isSaving: true
          }, () => setTimeout(() => this.setState({ isSaving: false }), 2000))}
          handleChange={name => name === 'dog' && this.setState({ nameError: 'This is a bad name!' })}
          handleClearError={() => this.setState({ nameError: null })}
        />
        <CodeBlock code={[
          '<InlineEdit',
          'label={String}',
          'saving={Boolean}',
          'error={String}',
          'handleChange={Function(change)}',
          'handleSave={Function}',
          'handleClearError={Function}',
          '/>',
        ]}/>

        <InlineList spacing={16}>
          <InlineButton
            handleClick={() => this.setState({ saving: true }, () => {
              setTimeout(() => this.setState({ saving: false }), 4000);
            })}>
            Click to see this component
          </InlineButton>
          <InlineSaveState
            saving={this.state.saving}
            savedLabel="Saved It!"
            savingLabel="Saving!!!"
          />
        </InlineList>
        <CodeBlock code={[
          '<InlineSaveState',
          'saving={Boolean}',
          'savingLabel={String}',
          'savedLabel={String}',
          'savedCallback={Function}',
          '/>',
        ]}/>

        <InlineSearch value={this.state.search} handleChange={search => this.setState({ search })}/>
        <CodeBlock code={[
          '<InlineSearch',
          'value={String}',
          'handleChange={Function(change)}',
          'handleKeyDown={Function(event)}',
          '/>',
        ]}/>

        <ItemList
          searchable={true}
          items={[
            { label: 'Outside', value: 'outsides', outside: true },
            { label: 'Foo', value: 'foo' },
            { label: 'Bar', value: 'bar' }
          ]}
          handleSelect={value => console.log(value)}
        />
        <CodeBlock code={[
          '<ItemList',
          'items={[{label: String, value: String, labelStatic: String, fixed: Boolean, outside: Boolean}]}',
          'hideSearch={Boolean}',
          'handleSelect={Function(value)}',
          '/>',
        ]}/>

        <Menu
          options={[
            { label: 'Who Let The', href: 'google.com' },
            { label: 'Dogs Out', href: 'http://www.bahamen.com/' },
          ]}
        />
        <CodeBlock code={[
          '<Menu',
          'options={[{label: String, href: String, value: String}]}',
          'handleSelect={Function(value)}',
          'handleClose={Function}',
          '/>',
        ]}/>


        <DatePicker />
        <CodeBlock
          code={[
            '<DatePicker',
            'date={moment()}',
            'handleDateChange={Function(date)}',
            '/>',
          ]} />

      </div>
    );
  }
}

export default StyleGuide;
