import React, { Component } from 'react';

import colors from '../../styles/colors';
import IOMap from './IOMap';
import { Label, BodyInstruction, Regular, Caption } from '../../components/type';

function getOutputName(map) {
  return map.split('|')[2];
}

class WorkflowSchemaMapping extends Component {

  render() {
    const {
      sourceNode,
      destination,
      mapping,
      handleChangeMap,
      mappableNodes,
      inputs,
      outputs,
      chainNodes,
      isLocked,
    } = this.props;

    return (
      <div>
        <hr style={styles.divider} />
        {/* Description */}
        <Regular>
          <div style={styles.titleText}>
            <BodyInstruction color="dark">Please review the data mapping between your workflows below.</BodyInstruction>
          </div>
          <Caption color="dark">The mapping below determines how that will flow from one workflow to the other. It is
          important to make sure that the right outputs from the previous workflow are mapped to
          the correlated inputs of the next workflow.</Caption>
        </Regular>

        <div style={styles.mapContainer}>
          <div style={styles.titleCol}>
            <div style={styles.colHdr}>
              <div style={{ flex: 1 }}>
                <Label uppercase={true}>Output From:</Label>
                <Label>Workflow</Label>
              </div>
              <div style={{ flex: 1, paddingLeft: 32 }}>
                <Label>&nbsp;</Label>
                <Label>Data Field</Label>
              </div>
            </div>
            <div style={styles.colHdrInput}>
              <div style={{ paddingLeft: 32 }}>
                <Label uppercase={true}>Input To:</Label>
                <Label>{destination.name}</Label>
              </div>
            </div>
          </div>

          {inputs.map((input, i) => {

            const outputMap = Object.keys(mapping).find(mapId => mapping[mapId] === input.name);
            const selectedOutput = outputMap && getOutputName(outputMap);
            const selectedNode = outputMap ? outputMap.split('|')[0] : sourceNode.id;

            const importNode = chainNodes.find(n => n.id === selectedNode && !!n.operation.startup);
            const isImportNode = !!importNode;

            return (
              <IOMap
                key={i}
                inputName={input.name}
                input={input}
                selectedOutput={selectedOutput}
                outputs={outputs}
                isLocked={isLocked}
                sourceNode={sourceNode}
                handleChangeMap={(output, input, oldNode, newNode) => {
                  return handleChangeMap(output, input, outputMap, oldNode, newNode);
                }}
                mappableNodes={mappableNodes}
                index={i}
                selectedNode={selectedNode}
                mapping={mapping}
                isImportNode={isImportNode}
                importNode={importNode}
                chainNodes={chainNodes}
                destinationName={destination.name}
              />
            );

          })}
        </div>
      </div>
    );
  }
}

const styles = {
  mapContainer: {
    borderTop: `2px solid ${colors.border}`,
    borderBottom: `2px solid ${colors.border}`,
    margin: '30px 0 26px',
    paddingBottom: 26,
  },
  titleCol: {
    display: 'flex',
    flex: 1,
    marginTop: 26,
  },
  colHdr: {
    flex: 2,
    paddingRight: 32,
    display: 'flex',
  },
  colHdrInput: {
    flex: 1,
  },
  titleText: {
    marginTop: 20,
    marginBottom: 8,
  },
  divider: {
    border: 0,
    height: 1,
    margin: '35px 0 0',
    color: colors.border,
    backgroundColor: colors.border,
    borderWidth: 0,
  },
};

export default WorkflowSchemaMapping;
