import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IconButton = function ({icon, label, inline, handleClick, white}) {
  return (
    <div
      className={classNames('icon-button', {'-inline': inline, '-white': white})}
      onClick={handleClick}>
      <i className={`fa fa-${icon}`}/>
      <span>{label}</span>
    </div>
  );
};

IconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  white: PropTypes.bool,
  inline: PropTypes.bool,
  handleClick: PropTypes.func.isRequired
};
IconButton.defaultProps = {
  handleClick: () => {
  }
};
export default IconButton;
