import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';

import reducer from './';
import callAPIMiddleware from '../utils/callAPIMiddleware';

export default function (initialState) {
  // Note: only Redux >= 3.1.0 supports passing enhancer as third argument.
  // See https://github.com/rackt/redux/releases/tag/v3.1.0
  return compose(
    applyMiddleware(
      callAPIMiddleware,
      thunkMiddleware
    ),
  )(createStore)(reducer, initialState);
};
