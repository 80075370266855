import Listener from '../../utils/Listener';
import config from './config';
import uuid from 'uuid';

// Attach $ to window for JSPlumb to work.
// if (!window.$) {
//   window.$ = require('jquery');
// }

/**
 * ChainBuilder
 * Binds a JSPlumb editor to the page
 *
 * Properties:
 * - domNode - Element to attach the editor to
 *
 * Events:
 * - clickAddNode - Called when the user clicks the button to add a new node
 * - removedNodes - Called when the user removes a nodes from the editor
 * - clickConnectionLabel - Called when the user clicks the edit button between two nodes
 */
export default class ChainBuilder extends Listener {
  constructor(chain, domNode, chainState, workflows, miniview) {
    super();
    this.chain = chain;
    this.domNode = domNode; // Element to attach the builder to
    this.chainState = chainState;
    this.listeners = new Map();
    this.workflows = workflows;
    this.miniview = miniview;

    window.jsPlumbToolkit.ready(this.initialize.bind(this));
  }

  initialize() {
    // Setup JSPlumb Editor
    this.toolkit = window.jsPlumbToolkit.newInstance();
    this.instance = window.jsPlumb.getInstance();
    this.renderer = this.toolkit.render({ ...config, container: this.domNode, miniview: {container: this.miniview} });
    this.renderer.storePositionsInModel();

    /* Use this to enable/disable dragging */
    // this.renderer.setElementsDraggable(true);

    // Bind Events
    this.toolkit.bind('nodeAdded', this._nodeAdded.bind(this));
    this.toolkit.bind('nodeRemoved', this._nodeRemoved.bind(this));
    this.toolkit.bind('dataLoadEnd', this._dataLoadEnd.bind(this));

    const v = this;
    this.instance.on(this.domNode, 'tap', '.add-workflow', function () {
      v._handleClickAddNode(this);
    });
    this.instance.on(this.domNode, 'tap', '.delete', function () {
      v._handleClickRemoveNode(this);
    });
    this.instance.on(this.domNode, 'tap', '.connection-label', function (e) {
      v._handleClickConnectionLabel(e);
    });
    this.instance.on(this.domNode, 'tap', '.node-name', function (e) {
      v._handleClickNode(e);
    });

    // Add initial nodes & edges
    const data = transformForUse(this.chain, this.workflows);
    this.toolkit.load({ data, onload: this.zoom.bind(this) });

    /* If nodePositions available, reposition nodes to correspond with their x, y coordinates */
    if (this.chain.nodePositions.length) {
      this.setNodePositions(this.chain.nodePositions);
    }

    // This is used for testing, comment out before PROD
    // window.instance = this.instance;
    // window.renderer = this.renderer;
  }

  /**
   * Public Methods
   */

  updateData(data) {
    const d = transformForUse(data, this.workflows);
    this.toolkit.clear().load({ data: d, onload: this.zoom.bind(this) });

    if (data.nodePositions.length) {
      this.setNodePositions(data.nodePositions);
    }
  }

  // TODO description
  exportData() {
    return this.toolkit.exportData();
  }

  // TODO description
  zoom() {
    setTimeout(() => {
      this.renderer.zoomToFit();
    });
  }

  // TODO description
  addNode(data) {
    this.toolkit.addNode(data);
  }

  // TODO description
  connectNodes(sourceId, targetId) {
    if (sourceId === 'start') {
      return;
    }
    const edge = {
      source: sourceId.toString().replace('ghost-', ''),
      target: targetId.toString(),
      data: { type: 'normal', label: '' }
    };
    this.toolkit.addEdge(edge);
    return this.exportData();
  }

  getNodePositions() {
    const getNodes = this.exportData().nodes;

    let nodesWithPositionsArray = [];
    let newNodeWithPosition = {};
    let position;

    getNodes.forEach((n) => {
      position = this.renderer.getPosition(n.id);
      newNodeWithPosition.node = n.id;
      newNodeWithPosition.left = position[0];
      newNodeWithPosition.top = position[1];
      nodesWithPositionsArray.push(newNodeWithPosition);
      newNodeWithPosition = {};
    });

    return nodesWithPositionsArray;
  }

  setNodePositions(nodePositions) {
    /* Get chain nodes minus ghost nodes */
    const noGhostNodePositons = nodePositions.filter(n => {
      return !n.node.includes('ghost-');
    });

    /* List of actual nodes on canvas */
    const canvasNodesWithGhosts = this.exportData().nodes;
    const canvasNodesNoGhosts = canvasNodesWithGhosts.filter(n => !n.id.includes('ghost-'));
    const canvasNodes = this.chainState !== 'editable' ? canvasNodesNoGhosts : canvasNodesWithGhosts;

    /* List of nodes from the chain */
    const nodeList = this.chainState !== 'editable' ? noGhostNodePositons : nodePositions;

    /* Map through chain nodes and make sure each node is in the canvas nodes */
    nodeList.map(n => {
      if (canvasNodes.find(node => node.id === n.node)) {
        this.renderer.setPosition(n.node, n.left, n.top);
      }
    });

    this.zoom();
  }

  /* Validates edges with type: invalid if there are any */
  validateEdge(targetId) {
    const desiredEdge = this.toolkit.getEdges({ target: targetId });
    if (desiredEdge[0].data.type === 'invalid') {
      this.toolkit.updateEdge(desiredEdge[0], { type: 'normal' });
    }
  }

  /* After updateNodes response comes back from Chain Editor trigger revalidateEdges */
  revalidateEdges(edges, validationResult) {
    const erroredTargets = getInvalidNodes(validationResult);
    edges.forEach((edge, i) => {
      if (erroredTargets.includes(edge.target)) {
        const desiredEdge = this.toolkit.getEdges({ target: edge.target });
        this.toolkit.updateEdge(desiredEdge[i], { type: 'invalid' });
      }
    });
  }

  setChainState(editable) {
    const newState = editable ? 'editable' : 'static';
    if(this.chainState !== newState) {
      this.chainState = newState;
      this._changeView();
    }
  }

  /** JSPlumb Events **/
  // Called after a node is added to builder
  _nodeAdded(node) {
    // Don't go further for ghost nodes
    if (node.data.id.includes('ghost')) {
      return this.zoom();
    }

    // Add ghost nodes and edges once data loaded
    if (this.chainState === 'editable' && !!this.chain.nodes.length) {
      this._handleAddingGhostElements(node);
    }
  }

  // If on manage chains page, remove unnecessary buttons
  _dataLoadEnd() {
    this._toggleChainActions();
  }

  _changeView() {
    const allNodes = [...this.toolkit.getNodes()];

    if (this.chainState !== 'editable') {
      allNodes.forEach(node => {
        if (node.id.toString().includes('ghost') || node.id.toString().includes('start')) {
          this.toolkit.remove(node);
        }
      });
    } else {
      allNodes.forEach(node => {
        this._handleAddingGhostElements(node);
      });
    }
    this._toggleChainActions();
    this.zoom();
  }

  _toggleChainActions() {
    if (this.chainState !== 'editable') {
      $('.delete').hide();
      $('.chain-lock-overlay').show();
      $('.node-wrap').css('background-image', 'none');
      $('.default .node-name').css('width', '132px');
      $('.plugin-icon').css('left', '-5px');
      $('.type-plugin .node-name').css('width', '180px');
    } else {
      $('.delete, .connection-label').show();
      $('.chain-lock-overlay').hide();
      $('.node-wrap').css('background-image', 'url("/img/drag-bars.png")');
      $('.node-name').css('width', '90px');
      $('.type-plugin .node-name').css('width', '120px');
    }
  }

  _handleAddingGhostElements(node) {
    // Attach ghost to this node.
    this.toolkit.addNode({
      id: `ghost-${node.data.id}`,
      type: 'ghost',
      name: node.data.type === 'startup' ? 'Add Workflow' : 'Connect'
    });
    this.toolkit.addEdge({
      source: node.data.id,
      target: `ghost-${node.data.id}`,
      data: { type: 'ghost' }
    });
  }

  _nodeRemoved() {
    if (!this.toolkit.exportData().nodes.length) {
      this.toolkit.addNode({ id: 'start', type: 'ghost', name: 'Add Workflow' });
    }
    this.zoom();
  }

  /** User Events **/
  _handleClickAddNode(obj) {
    const toolkit = this.toolkit;
    const info = toolkit.getObjectInfo(obj);
    const clickedNode = info.id.toString().replace('ghost-', '');

    this._emit('clickAddNode', clickedNode, this._handleUsedNodes(clickedNode));
  }

  _handleUsedNodes(targetNode) {
    /*  Get edges of a target node and get their target ids and loop through all the nodes,
        and any node whose descendants contain targetNode, add to list of unavailableNodes
    */
    const unavailableNodes = [];
    const toolkit = this.toolkit;
    // Yes I want to keep it here for now
    
    // const directEdges = toolkit.getNode(targetNode).getEdges().filter(t => !t.target.id.includes('ghost-'));
    //
    // if (!!directEdges.length) {
    //   directEdges.map(t => unavailableNodes.push(t.target.id));
    // }

    const nodes = this.exportData().nodes.filter(n => !n.id.includes('ghost-'));

    nodes.map(n => {
      const descendants = toolkit.selectDescendants(n.id, true).getNodes().filter(
        n => !n.id.includes('ghost-')
      );
      descendants.find(d => {
        if (targetNode === d.id) {
          unavailableNodes.push(n.id);
        }
      });
    });

    return unavailableNodes;
  }

  _handleClickNode(e) {
    const nodeId = e.target.parentNode.parentNode.dataset.jtkNodeId;
    this._emit('clickNode', nodeId);
  }

  _handleClickRemoveNode(obj) {
    if (confirm('Are you sure you want to delete this workflow and all its children?')) {
      const { id } = this.toolkit.getObjectInfo(obj);
      const node = this.toolkit.getNode(id);
      const descendants = this.toolkit.selectDescendants(node, true);

      // Remove the node and descendants from the builder
      window.d = descendants;
      // Emit the nodes that have been deleted
      const removedNodes = descendants.getNodes().map(n => n.data).filter(node => {
        const isGhostNode = node.id.toString().includes('ghost');
        const isStartNode = node.id.toString().includes('start');
        return !isGhostNode && !isStartNode;
      });
      this._emit('removedNodes', removedNodes);

      this.toolkit.remove(descendants);
    }
  }

  _handleClickConnectionLabel(e) {
    let source = e.target.dataset.source;
    let target = e.target.dataset.target;
    this._emit('clickConnectionLabel', source, target, this._handleUsedNodes(target));
  }
}

function handleMergingEdges(destinationEdges, sourceEdges) {
  return [...new Set([...destinationEdges ,...sourceEdges])];
}

function getWorkflowState(node, workflows) {
  return node.operation.swfWorkflow ? workflows[node.operation.swfWorkflow.entityId].state : 'static';
}

function backfillStartupNode(startupNode, chain) {

  // Set position of startup node and ghost add workflow node
  chain.nodePositions.unshift(
    { left: -150, node: startupNode.id, top: 0 },
    { left: 0, node: `${'ghost-' + startupNode.id}`, top: -40 }
  );

  // Backfill routes for the startup node
  startupNode.routes.push(
    {
      conditions: [],
      destinationNode: `${chain.nodes[0].id}`,
      mapping: {}
    }
  );

  // Backfill the destinations of startup node which is the id of first node
  startupNode.destinations.push(`${chain.nodes[0].id}`);

  // Backfill the sources of the originally first node
  chain.nodes[0].sources.push(startupNode.id);

  // Finally, add startup node as first in chain
  chain.nodes.unshift(startupNode);
}

/* Utility function */
function transformForUse(chain, workflows) {
  // Return a single ghost node if this is an empty chain
  let startupNode = {
    'operation': {
      'startup': {
        'inputs': [],
        'outputs': [],
      }
    },
    'name': 'Import',
    'id': uuid(),
    'routes': [],
    'destinations': [],
    'sources': []
  };

  // Is there a startup node in the chain already
  const isThereStartupNode = chain.nodes.find(node => node.operation.startup);

  if (!chain.nodes.length) {
    // return startNode();
    chain.nodes.push(startupNode);
  } else if (!isThereStartupNode) {
    backfillStartupNode(startupNode, chain);
  }

  const nodes = [];
  const destinationEdges = [];
  const sourceEdges = [];

  chain.nodes.forEach(node => {
    const workflowState = getWorkflowState(node, workflows);
    let nodeType = node.operation.startup ? 'startup' : node.operation.plugin ? 'type-plugin' : 'default';
    nodes.push({ id: node.id, name: node.name, state: workflowState, type: nodeType });
    // Loop through each destination,
    // adding an edge between this node and its destination
    node.destinations.forEach(destination => destinationEdges.push({
      source: node.id,
      target: destination,
      data: {
        //TODO MOCK check if this is an invalid source
        type: 'normal',
        // Find the matching route
        route: node.routes.find(route => route.destinationNode === destination)
      }
    }));
    node.sources.forEach((source, i) => {
      if (i > 0) {
        sourceEdges.push({
          source: source,
          target: node.id,
          data: {
            //TODO MOCK check if this is an invalid source
            type: 'normal',
            // Find the matching route
            route: node.routes && node.routes.find(route => route.destinationNode === source)
          }
        });
      }
    });
  });

  // Merge edges and eliminate dupes
  const edges = handleMergingEdges(destinationEdges, sourceEdges);
  invalidateEdges(edges, chain.validationResult);
  return { nodes, edges };
}

/* Turn edges 'invalid' if there are errors in mapping */
function invalidateEdges(edges, validationResult) {
  const erroredTargets = getInvalidNodes(validationResult);
  edges.forEach(edge => {
    if (erroredTargets.includes(edge.target)) {
      edge.data.type = 'invalid';
    }
  });
}

/* Provides ids of invalid edge target ids */
function getInvalidNodes(validationResult = []) {
  let invalidIdsArray = [];

  validationResult.map(error => {
    if (error.type !== 'NoChainNodes') {
      invalidIdsArray.push(error.destination);
    }
  });

  return invalidIdsArray;
}
