import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import colors from '../styles/colors';
import { Label } from './type';

/**
 * Table
 */

const Table = ({ headerCols, children }) => {
  return (
    <table className={css(styles.table)}>
      <thead>
        <tr className={css(styles.headerRow)}>
          {headerCols.map((col, idx) => {
            return (
              <th
                style={{ width: col.width ? col.width : 'auto'}}
                key={idx}
                className={css(idx !== 0 ? styles.th : Object.assign({}, styles.th, styles.thFirst))}
              >
                <Label>{col.label}</Label>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {children}
      </tbody>
    </table>
  );
};

export const Row = ({ children, fresh }) => {
  return (
    <tr className={css(!fresh ? styles.row : Object.assign({}, styles.row, styles.newerRow))}>
      {children}
    </tr>
  );
};

export const Column = ({ label, first, children, align}) => {
  return (
    <td
      style={{ textAlign: align ? align : 'left '}}
      className={css(!first ? styles.td : Object.assign({}, styles.td, styles.tdFirst))}
    >
      {label ? <Label>{label}</Label> : children}
    </td>
  );
};

const styles = StyleSheet.create({
  table: {
    width: '100%',
  },
  body: {
    overflowY: 'auto',
  },
  row: {
    background: 'none',
    borderTop: `2px solid ${colors.border}`,
  },
  newerRow: {
    background: colors.secondary(),
    borderTop: `2px solid ${colors.border}`,
  },
  headerRow: {
    borderTop: 0,
  },
  th: {
    borderLeft: `2px solid ${colors.border}`,
    padding: '0 15px 10px 15px',
  },
  td: {
    borderLeft: `2px solid ${colors.border}`,
    padding: '30px 15px',
  },
  tdFirst: {
    borderLeft: 0,
    paddingLeft: 0,
  },
  thFirst: {
    borderLeft: 0,
    padding: '0 15px 10px 0px',
  }

});

/* PropTypes */
Table.propTypes = {
  headerCols: PropTypes.array,
};

Column.propTypes = {
  label: PropTypes.string,
  first: PropTypes.bool,
  align: PropTypes.string,
};


export default Table;


