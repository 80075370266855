/**
 * makeActionCreator
 * Returns an action creator.
 *
 * Params:
 *   type - The action type to dispatch. Should be a constant.
 *   ...argNames - Properties of the dispatched action
 *
 * Example:
 *   export const doTheThing = makeActionCreator(DOING_THING, 'thingToDO');
 */

export default function makeActionCreator(type, ...argNames) {
  return function (...args) {
    let action = { type };
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index];
    });
    return action;
  };
}
