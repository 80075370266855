import { updateState } from '../utils/reducerHelpers';

import {
  FETCH_TEAMS_LIST_REQUEST,
  FETCH_TEAMS_LIST_SUCCESS,
  FETCH_TEAMS_LIST_FAILURE,
} from '../constants/actionTypes';

const initialState = {
  items: {},
  isFetching: false,
  hasFetched: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_TEAMS_LIST_REQUEST:
      return updateState.fetchRequest(state);
    case FETCH_TEAMS_LIST_SUCCESS:
      return updateState.fetchSuccess(state, action.teams);
    case FETCH_TEAMS_LIST_FAILURE:
      return updateState.fetchFailure(state);
    default:
      return state;
  }
}