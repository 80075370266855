import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';

import objectToArray from '../../utils/_objectToArray';
import stateHelpers from '../../utils/stateHelpers';
import { fetchAdmins } from '../../actions/company';
import { fetchProjects } from '../../actions/projects';
import colors from '../../styles/colors';

import Button from '../../components/Button';
import IconButton from '../../components/IconButton';
import TabSection from '../../containers/TabSection';
import HelpPanel from '../../containers/HelpPanel';
import EmptyState from '../../containers/EmptyState';
import Wrapper from '../../containers/Wrapper';
import LoadState from '../../containers/LoadState';
import { Heading } from '../../components/type';

import HelperPanelDescription from './HelperPanelDescription';
import ProjectDetails from './ProjectDetails';

const helpPanelLinks = [
  {
    href: 'https://onespace.helpdocs.com/getting-started/creating-a-project',
    label: 'Creating a Project'
  },
  {
    href: 'https://onespace.helpdocs.com/getting-started/project-cloning-the-basics',
    label: 'Project Cloning: The Basics'
  },
  {
    href: 'https://onespace.helpdocs.com/getting-started/video-the-hierarchy-of-work-on-project-center',
    label: 'VIDEO: The Hierarchy of Work on Project Center'
  }
];

/* Helper Methods */
const isActive = project => project.status === 'active';
const isInactive = project => project.status === 'inactive';
const isDraft = project => project.status === 'draft';
const sortByCreatedAt = (a, b) => new Date(b.created_at) - new Date(a.created_at);

/**
 * Projects
 * Displays the user's projects
 */
class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allCompanyProjects: true
    };

    this.shouldShowProject = this.shouldShowProject.bind(this);
    this.toggleAllCompanyProjects = this.toggleAllCompanyProjects.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchProjects());
    this.props.dispatch(fetchAdmins());
  }

  toggleAllCompanyProjects() {
    this.setState({ allCompanyProjects: !this.state.allCompanyProjects });
  }

  shouldShowProject(project) {
    const manager = project.id_project_manager.toString() === this.props.currentUserId.toString();
    return this.state.allCompanyProjects || manager;
  }

  getProjectDetailsList(status, projects) {
    const allCompanyProjects = this.state.allCompanyProjects;
    return (
      <div>
        <div style={styles.allCompanyToggle}>
          <IconButton
            color="white"
            hoverColor="white"
            icon={allCompanyProjects ? 'check-square-o' : 'square-o'}
            handleClick={this.toggleAllCompanyProjects}>
            <div style={{ textDecoration: 'none !important', display: 'inline-block' }}>
              View All Company Projects
            </div>
          </IconButton>
        </div>
        {!projects.filter(this.shouldShowProject).length &&
        <EmptyState label={`No ${status} Projects`}/>
        }
        <div style={styles.projectDetails}>
          {projects.filter(this.shouldShowProject).map((project, i) => (
            <div
              key={project.id}
              style={{ marginBottom: i === projects.length - 1 ? 0 : 16 }}>
              <ProjectDetails
                project={project}
                companyAdmins={this.props.companyAdmins}
                userCanEdit={project.userCanEdit}
                notes={this.state.note}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }

  render() {
    const activeProjects = this.props.projects.filter(isActive);
    const inactiveProjects = this.props.projects.filter(isInactive);
    const drafts = this.props.projects.filter(isDraft);

    return (
      <DocumentTitle title="Projects | OneSpace Project Center">
        <div className="projects">
          {/* Help Panel */}
          <HelpPanel
            pageTitle="Projects"
            links={helpPanelLinks}
            pageDescription={<HelperPanelDescription />}
          />

          <Wrapper>
            {/* Page Title */}
            <div style={styles.title}>
              <Heading color="dark">Projects</Heading>
              <Button handleClick={() => window.location.href = '/projects/new'}>
                Create Project
              </Button>
            </div>

            {/* Tabs */}
            {this.props.loadingProjects ?
              <LoadState label="Loading Projects" top={50} /> :
              <TabSection
                tabLabels={[
                  'Active Projects',
                  'Inactive Projects',
                  'Drafts',
                ]}
                tabClasses={[
                  'active-tab',
                  'inactive-tab',
                  'drafts-tab',
                ]}
                tabContent={[
                  this.getProjectDetailsList('Active', activeProjects),
                  this.getProjectDetailsList('Inactive', inactiveProjects),
                  this.getProjectDetailsList('Draft', drafts),
                ]}
              />
            }
          </Wrapper>
        </div>
      </DocumentTitle>
    );
  }
}

/* Styles */
const styles = {
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  allCompanyToggle: {
    height: 76,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 32,
    paddingRight: 32,
    boxSizing: 'border-box',
    background: colors.dark,
    marginTop: 40,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  projectDetails: {
    border: '1px solid #ebebeb',
    background: colors.background,
    padding: '32px 16px',
  }
};

/* PropTypes */
Projects.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    status: PropTypes.oneOf(['active', 'inactive', 'draft']).isRequired,
  })).isRequired,
  loadingProjects: PropTypes.bool,
  companyAdmins: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
};
Projects.defaultProps = {
  companyAdmins: [],
};

/* Map State To Props */
function select(state) {
  const projects = objectToArray(state.projects.items)
    .sort(sortByCreatedAt)
    .map(project => Object.assign({}, project, {
      userCanEdit: stateHelpers.canEdit(project, state)
    }));
  return {
    projects,
    loadingProjects: state.projects.isFetching,
    companyAdmins: state.app.companyAdmins,
    currentUserId: stateHelpers.getUser(state).id,
  };
}
export default connect(select)(Projects);
