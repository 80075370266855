import React from 'react';
import PropTypes from 'prop-types';

import colors from '../styles/colors';
import { Label } from '../components/type';

/**
 * Note
 * Used to show a positive or negative note to the user
 */
const Note = ({ note, type, handleClose, align }) => (
  <Label color={type === 'notification' ? 'dark' : 'white'} className="react-c-note">
    <div style={styles.note(type, align)}>
      <span style={styles.icon} className="fa fa-exclamation-circle"/>
      <div style={styles.message} dangerouslySetInnerHTML={{ __html: note }}/>

      {handleClose && <div style={styles.close} onClick={handleClose}>Close</div>}
    </div>
  </Label>
);

/* Styles */
const styles = {
  note: (type, align) => ({
    display: 'flex',
    alignItems: 'center',
    minHeight: 32,
    width: '100%',
    padding: '8px 16px',
    background: type === 'error' ? colors.negative : type === 'notification' ? '#F8B85F' : colors.positive,
    boxSizing: 'border-box',
    textAlign: align ? align : 'left',
    lineHeight: 'normal',
  }),
  message: {
    flex: 1,
  },
  close: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  icon: {
    marginRight: 5,
  },
};

/* PropTypes */
Note.propTypes = {
  note: PropTypes.string.isRequired,
  align: PropTypes.string,
  type: PropTypes.oneOf(['error', 'success', 'notification']),
  handleClose: PropTypes.func,
};
Note.defaultProps = {
  type: 'error',
};

export default Note;
