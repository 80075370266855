import uuid from 'uuid';

export default class Rule {
  constructor({
    lhs = '',
    lhs_type = 'workflow',
    comparison = '',
    rhs = '',
    rhs_type = 'string'
  }, options = {}) {
    this.id = uuid();
    this.lhs = lhs;
    this.lhs_type = lhs_type;
    this.comparison = comparison;
    this.rhs = rhs;
    this.rhs_type = rhs_type;
    this.handleChange = options.handleChange || function() {};
  }

  changed = false;

  getLHS() {
    return this.lhs;
  }

  setLHS(lhs) {
    this.lhs = lhs;
    this.handleChange();
  }

  getLHSType() {
    return this.lhs_type;
  }

  getRHS() {
    if(this.isComparisonBlank()) {
      this.rhs = '';
    }
    return this.rhs;
  }

  setRHS(rhs) {
    this.rhs = rhs;
    this.handleChange();
  }

  getRHSType() {
    return this.rhs_type;
  }

  getComparison() {
    return this.comparison;
  }

  setComparison(comparison) {
    this.comparison = comparison;
    this.handleChange();
  }

  isComparisonBlank() {
    return(this.comparison === 'not_exists' || this.comparison === 'exists');
  }

  isValid() {
    if(this.isComparisonBlank()) {
      return this.lhs && this.comparison;
    }
    return this.lhs && this.rhs && this.comparison;
  }

  toJSON() {
    return {
      lhs: this.getLHS(),
      lhs_type: this.getLHSType(),
      comparison: this.getComparison(),
      rhs: this.getRHS(),
      rhs_type: this.getRHSType(),
    };
  }
}
