import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
// import { StyleSheet, css } from 'aphrodite';
import { connect } from 'react-redux';

import { forgotPassword } from '../../actions/login';

import { Heading } from '../../components/type';
import { Large } from '../../components/type';
import InputCaption from '../../components/InputCaption';
import Button from '../../components/Button';
import Toast from '../../containers/Toast';

const emailNotFound = 'The email address you entered was not found.';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      submissionNote: '',
      submitting: false,
      emailError: '',
    };
    this.submitForgotPassword = this.submitForgotPassword.bind( this );
  };

  submitForgotPassword(e) {
    if(!!e){
      e.preventDefault();
    }
    const { email } = this.state;
    this.setState({
      submitting: !this.state.submitting,
      submissionNote: '',
      emailError: '',
    });
    const emailCheck = email.match(/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/g);

    if(emailCheck === null){
      this.setState({
        emailError: 'Please enter a valid email address',
        submitting: false,
      });
    }else {
      this.props.dispatch(forgotPassword(email))
        .then(() => {
          return this.setState({
            submissionNote: 'An email with instructions to reset your password has been sent!',
            submitting: !this.state.submitting
          });
        })
        .catch(() => {
          this.setState({
            emailError: emailNotFound,
            submitting: false,
            submissionNote: '',
          });
        });
    }
  }

  render() {
    const {email, submissionNote, submitting} = this.state;

    return (
      <DocumentTitle title="Forgot Password | OneSpace Project Center">
        <div style={{ display: 'flex', justifyContent: 'center'}}>
          <form style={{ width: '33%' }} onSubmit={this.submitForgotPassword}>
            <div style={{ marginTop: 36, marginBottom: 36 }}>
              <Heading color="dark">Forgot Password</Heading>
            </div>
            {submissionNote !== '' &&
              <div style={{ paddingBottom: 10, width: '100%' }}>
                <Toast label={submissionNote} bold={true} />
              </div>
            }
            <div style={{marginBottom: 16 }}>
              <Large>Enter the verified email address associated to your account.</Large>
            </div>
              <InputCaption
                handleChange={email => this.setState({email})}
                label={'Email Address'}
                value={email}
                error={this.state.emailError}
                invalid={this.state.emailError !== ''}
              />
            <Button
              handleClick={this.submitForgotPassword}
              loading={submitting}>
              Send Email
            </Button>
          </form>
        </div>
      </DocumentTitle>
    );
  }
}

function select( ) {
  return {};
}

export default connect(select)( ForgotPassword );