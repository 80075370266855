import uuid from 'uuid';

import Rule from './Rule/Rule';

export default class RuleSet {
  constructor({operation = 'AND', ruleSets = [], rules = []}, options = {}) {
    // For parent to locate this rule set
    this.id = uuid();

    // Individually set options properties
    this.handleChange = options.handleChange || function() {};
    this.parentRuleSet = options.parentRuleSet;
    this.canModify = options.canModify || this.isValid.bind(this);
    this.attemptedRuleChange = options.attemptedRuleChange || (changed => {
      this.ruleSets.forEach(ruleSet => ruleSet.markRulesChanged(changed));
    });

    // Set properties of rule set
    this.operation = operation;
    this.ruleSets = ruleSets.map(ruleSet => new RuleSet(ruleSet, {
      canModify: this.canModify,
      attemptedRuleChange: this.attemptedRuleChange,
      handleChange: this.handleChange,
      parentRuleSet: this,
    }));
    this.rules = rules.map(rule => new Rule(rule, {
      handleChange: this.handleChange,
    }));
  }

  markRulesChanged(changed) {
    this.rules.forEach(rule => rule.changed = changed);
  }

  getOperation() {
    return this.operation;
  }

  setOperation(operation) {
    this.operation = operation;
    this.handleChange();
  }

  /* RuleSets */
  addRuleSet(ruleSet = {}) {
    this.attemptedRuleChange(true);
    if (!this.canModify()) {
      return this.handleChange();
    }
    this.ruleSets.push(new RuleSet(ruleSet, {
      handleChange: this.handleChange,
      parentRuleSet: this,
    }));
    this.handleChange();
  }

  getRuleSets() {
    return this.ruleSets;
  }

  removeRuleSet(id) {
    const indexToRemove = this.ruleSets.findIndex(ruleSet => ruleSet.id === id);
    this.ruleSets.splice(indexToRemove, 1);
  }

  /* Rules */
  addRule(rule = {}) {
    this.attemptedRuleChange(true);
    if (!this.canModify()) {
      return this.handleChange();
    }
    this.rules.push(new Rule(rule, {
      handleChange: this.handleChange,
    }));
    this.handleChange();
  }

  getRules() {
    return this.rules;
  }

  removeRule(index) {
    this.rules.splice(index, 1);
    if (this.rules.length === 0 && this.parentRuleSet) {
      this.parentRuleSet.removeRuleSet(this.id);
    }
    this.handleChange();
  }

  reset() {
    this.rules = [];
    this.ruleSets = [];
    this.handleChange();
  }

  containsInvalidRule() {
    // Look through all rules and find first invalid rule
    return this.getRules().some(rule => !rule.isValid());
  }

  containsInvalidRuleSet() {
    // Look through all ruleSets and find first invalid ruleSet
    return this.getRuleSets().some(ruleSet => !ruleSet.isValid());
  }

  hasRulesOrRuleSets() {
    return this.getRules().length > 0 || this.getRuleSets().length > 0;
  }

  isValid() {
    return !this.containsInvalidRule() && !this.containsInvalidRuleSet();
  }

  toJSON() {
    return {
      operation: this.getOperation(),
      ruleSets: this.getRuleSets().map(ruleSet => ruleSet.toJSON()),
      rules: this.getRules().map(rule => rule.toJSON()),
    };
  }
}
