import React from 'react';

import InlineButton from '../../components/InlineButton';
import colors from '../../styles/colors';

const ChainNavButtons = ({projectId, chainId, archived}) => (
  <div className="chainMonitorBtn" style={styles.chainActions}>
    <InlineButton
      handleClick={() => {
        window.location = `/projects/${projectId}/chains/${chainId}?activeTab=monitor`;
      }}>
      { !archived ? 'Monitor' : 'View' }
    </InlineButton>
    <div className="chainBatchesBtn" style={{marginLeft: 8}}>
      <InlineButton
        handleClick={() => {
          window.location = `/projects/${projectId}/chains/${chainId}?activeTab=batches`;
        }}>
        { !archived ? 'Upload / Download' : 'Download' }
      </InlineButton>
    </div>
    {!archived &&
    <div className="chainEditorBtn" style={{margin: '0 8px 0 8px'}}>
      <InlineButton
        handleClick={() => {
          window.location = `/projects/${projectId}/chains/${chainId}`;
        }}>
        Edit
      </InlineButton>
    </div>
    }
  </div>
);

const styles= {
  chainActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${colors.divider}`,
    padding: '16px 0 16px 0',
    margin: '0 16px 0 16px',
  }
};

export default ChainNavButtons;