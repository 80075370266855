import moment from 'moment';
import _ from 'underscore';

export function estimateTimeToCompletion(workflow) {
  if (!Object.keys(workflow.itemStatus).length) {
    return 'N/A';
  }
  const statuses = workflow.itemStatus;
  const staged = statuses.staged || 0;
  const step1 = statuses['step 1'] || 0;
  const step2 = statuses['step 2'] || 0;
  const step3 = statuses['step 3'] || 0;
  const open = step1 + step2 + step3;

  const hasOpenItems = open + staged > 0;
  if (!workflow.completionEstimate && !hasOpenItems) {
    return 'Completed';
  }
  if (!workflow.completionEstimate && hasOpenItems) {
    return 'N/A';
  }
  return moment.unix(workflow.completionEstimate).fromNow(true);
}

export function getReleasableItemCount(stagedItemCount, costPerItem, availableBalance) {
  if(costPerItem === 0) {
    return stagedItemCount;
  }

  const releasableCount = availableBalance > 0 ? availableBalance / costPerItem : 0;
  return releasableCount > stagedItemCount ? stagedItemCount : Math.floor(releasableCount);
}

function costPerAssignment(assignment) {
  const advancedCost = Math.max.apply(null, [
    parseFloat(assignment.beginner || 0),
    parseFloat(assignment.intermediate || 0),
    parseFloat(assignment.advanced || 0),
    parseFloat(assignment.expert || 0),
  ]);
  return assignment.advancedPay ? advancedCost : parseFloat(assignment.payPerTask);
}

export function costPerItem(workflowDefinition, rate) {
  if (!workflowDefinition) {
    return 0;
  }
  const assignmentCosts = workflowDefinition.steps.map(step => {
    return costPerAssignment(step.assignment.settings.paySettings);
  });
  return (1 + rate) * _.reduce(assignmentCosts, (a, b) => a + b);
}