import React from 'react';
import PropTypes from 'prop-types';
import {StyleSheet, css} from 'aphrodite';

import colors from '../styles/colors';
import {Label} from './type';

/**
 * InlineSelect
 * A smaller select used inline with other components
 */
const InlineSelect = (props, i) => (
  <div className={css(styles.inlineSelect)}>
    <Label>
      <select
        style={{height: props.height ? props.height : 32}}
        className={
          css(styles.select, props.disabled && styles.disabled, props.invalid && styles.invalid)
        }
        value={props.value}
        disabled={props.disabled}
        onMouseDown={e => {
          (props.handleOnMouseDown && !props.disabled) && props.handleOnMouseDown(e.target.value);
        }}
        onChange={e => {
          return !props.disabled && props.handleSelect(e.target.value);
        }}>
        {props.showDefault && <option value="">{props.defaultLabel}</option>}
        {props.options.map((option, i) => {
          if (!option.hide) {
            return (
              <option key={i} value={option.value} title={option.title && option.title}>{option.label}</option>
            );
          }
        })}
      </select>
    </Label>

    <div
      className={css(styles.arrow, props.disabled && styles.arrowDisabled)}
      style={{height: props.height ? props.height : 32}}
    >
      <i className="fa fa-caret-down"/>
    </div>
  </div>
);

/* Styles */
const styles = StyleSheet.create({
  inlineSelect: {
    position: 'relative',
    borderRadius: 4,
    overflow: 'hidden',
  },
  select: {
    width: '100%',
    paddingLeft: 8,
    paddingRight: 40,
    background: colors.background,
    border: `2px solid ${colors.border}`,
    WebkitAppearance: 'none',
    ':focus': {
      outline: 'none',
    },
    borderRadius: 5,
  },
  invalid: {
    border: `2px solid ${colors.negative}`,
  },
  disabled: {
    color: colors.lighter,
  },
  arrow: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    background: colors.light,
    color: 'white',
    fontSize: 20,
    cursor: 'pointer',
    pointerEvents: 'none',
  },
  arrowDisabled: {
    background: colors.lighter,
  },
});

/* PropTypes */
InlineSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  })).isRequired,
  disabled: PropTypes.bool,
  defaultLabel: PropTypes.string,
  showDefault: PropTypes.bool,
  handleSelect: PropTypes.func.isRequired,
};
InlineSelect.defaultProps = {
  showDefault: true,
  defaultLabel: 'Select',
};

export default InlineSelect;
