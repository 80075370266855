import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import DocumentTitle from 'react-document-title';
import { parse } from 'qs';

import Wrapper from '../../containers/Wrapper';
import BreadCrumb from '../../containers/BreadCrumb';
import { Heading, MediumLabel } from '../../components/type';
import HelpPanel from '../../containers/HelpPanel';
import Panel from '../../containers/Panel';
import colors from '../../styles/colors';
import Select from '../../components/Select';
import InputLabel from '../../components/InputLabel';
import Button from '../../components/Button';
import { fetchWorkflows } from '../../actions/workflows';
import { fetchChains } from '../../actions/chains';
import objectToArray from '../../utils/_objectToArray';
import LoadState from '../../containers/LoadState';
import { createExport, fetchExportHistory } from '../../actions/advancedExports';
import {
  pageDescription,
  searchData,
  resetSearchData,
  headerCols,
  sortByDate,
  dateErrorText,
  statusErrorText,
  legitStatuses,
  noHistoryText,
} from './constants';
import Note from '../../containers/Note';
import FilterByDate from './FilterByDate';
import FilterByStatus from './FilterByStatus';
import AdditionalOutputs from './AdditionalOutputs';
import Table, { Row, Column } from '../../components/Table';
import { Label, Content } from '../../components/type';
import InlineButton from '../../components/InlineButton';
import IconButton from '../../components/IconButton';
import InlineList from '../../components/InlineList';
import RadioCheckbox from '../../components/RadioCheckbox';

/**
 * Advanced Exports
 */

class AdvancedExports extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      searchData,
      selectedOption: 'workflow',
      dateToggle: false,
      statusToggle: false,
      notes: [],
      isCreatingExport: false,
      collapsed: true,
      creatingExport: false,
    };

    this.handleRadioButtons = this.handleRadioButtons.bind( this );
    this.createExport = this.createExport.bind( this );
    this.updateSearchData = this.updateSearchData.bind( this );
    this.handleWorkflowsOrChainsSelect = this.handleWorkflowsOrChainsSelect.bind( this );
    this.handleCreatingName = this.handleCreatingName.bind( this );
    this.handleValidation = this.handleValidation.bind( this );
    this.handleToggle = this.handleToggle.bind( this );
  }

  componentDidMount() {
    const { dispatch, location } = this.props;
    this.props.dispatch( fetchChains(false) );
    dispatch( fetchWorkflows() ).then(({ workflows }) => {
      this.handlePreselectingWorkflowOrChain(location, workflows);
    });
    dispatch( fetchExportHistory() );
  }

  handlePreselectingWorkflowOrChain(location, workflows) {
    const locationQuery = parse(location.search, {ignoreQueryPrefix: true});
    if(locationQuery) {
      if(locationQuery.workflowId) {
        const workflowSwfId = workflows.find((w) => w.id.toString() === locationQuery.workflowId).id_swf;
        this.updateSearchData('workflowId', workflowSwfId);
      }
      if(locationQuery.chainId) {
        const chainId = locationQuery.chainId;
        this.setState({ selectedOption: 'chain' }, () => {
          this.updateSearchData('chainId', chainId);
        });
      }
    }
  }

  handleRadioButtons(e) {
    searchData.additionalOutputs = [];
    // Make sure workflowId or chainId are wiped after new option selected
    let newState;
    if((this.state.selectedOption === 'workflow' && e === 'chain' && this.state.searchData.workflowId) ||
      (this.state.selectedOption === 'chain' && e === 'workflow' && !!this.state.searchData.chainId)
    ) {
      newState = resetSearchData({...this.state.searchData});
      this.setState({ searchData: newState });
    }
    this.setState({ selectedOption: e, dateToggle: false, statusToggle: false });
  }

  handleValidation(body) {
    let newNoteState = [...this.state.notes];
    let pass = true;
    // If Date toggle is on, but date fields aren't selected
    if(this.state.dateToggle && (!body.dates[0].dateType || !body.dates[0].start || !body.dates[0].end)) {
      newNoteState.push({ type: 'error', note: dateErrorText });
      pass = false;
    }
    // If Status toggle is on, and no selections are made
    if(this.state.statusToggle && !body.status.length) {
      newNoteState.push({ type: 'error', note: statusErrorText });
      pass = false;
    }
    this.setState({ notes: newNoteState });
    return pass;
  }

  createExport(body) {
    /*
      * Handle validation
      * Make Request
      * Handle bad response
      * Handle successful response
      * Show email notification
     */
    if(this.handleValidation(body)) {
      let newBody = JSON.parse(JSON.stringify(body));

      this.setState({ isCreatingExport: true });
      newBody.name = !body.name ? this.handleCreatingName(body) : body.name;
      newBody.dates[0].end = moment(body.dates[0].end, 'MM-DD-YYYY').add(1, 'days');
      if(!body.dates[0].dateType || !body.dates[0].start || !body.dates[0].end) {
        newBody.dates = [];
      }

      if(newBody.workflowId) {
        delete newBody.chainId;
      }
      if(newBody.chainId) {
        delete newBody.workflowId;
      }

      this.props.dispatch(createExport(newBody))
        .then(() => {
          if(this.state.notes.length) {
            this.setState({ notes: [] });
          }
          const newNotes = [...this.state.notes];
          newNotes.push({
            type: 'success',
            note: 'Your export is processing. Once it is ready, we will email it to you, or you ' +
            'can download it directly from this page.',
          });
          this.setState({
            notes: newNotes,
            isCreatingExport: false,
            dateToggle: false,
            statusToggle: false,
            creatingExport: true
          });
          resetSearchData(body);
          this.props.dispatch( fetchExportHistory() ).then(() => {
            this.setState({ creatingExport: false });
          });
        }).catch((err) => {
          if(err) {
            let errorsArray = [];
            if(err.data && err.data.errors.length) {
              err.data.errors.forEach(error => {
                errorsArray.push({ type: 'error', note: error });
              });
            }
            if(err.workflowId) {
              err.workflowId.forEach(error => {
                errorsArray.push({ type: 'error', note: error });
              });
            }
            if(err.chainId) {
              err.chainId.forEach(error => {
                errorsArray.push({ type: 'error', note: error });
              });
            }
            if(err.message) {
              errorsArray.push({ type: 'error', note: err.message });
            }
            this.setState({ notes: errorsArray, isCreatingExport: false });
          }
        });
      }
  }

  handleWorkflowsOrChainsSelect() {
    const workflowsOrChains =
      this.state.selectedOption === 'workflow' ? this.props.workflows : this.props.chains;
    const newArray = [];
    workflowsOrChains.forEach(item => {
      newArray.push({label: item.name, value: this.state.selectedOption === 'chain' ? item.id : item.id_swf});
    });
    return newArray;
  }

  handleCreatingName(searchData) {
    /* If user doesn't edit the export file name we create a name for them in the following format:
    start: MM/DD/YYYY - end: MM/DD/YYY - Workflow Name
     */
    let name;
    let wfName = `${!!searchData.workflowId ? 
      this.props.workflows.find(w => w.id_swf === searchData.workflowId).name : 
      !!searchData.chainId ? 
        this.props.chains.find(c => c.id === searchData.chainId).name : 
        ''}`;

    // Remove [0] keys if we ever support multiple date filters
    if( !searchData.dates[0].start || !searchData.dates[0].end ) {
      name = wfName;
    } else {
      name = `${moment(searchData.dates[0].start).format('L')} - ${moment(searchData.dates[0].end).format('L')} - ${wfName}`;
    }

    return name;
  }

  updateSearchData(key, value, index) {
    const newState = {...this.state.searchData};
    switch (key) {
      case 'workflowId':
      case 'chainId': {
        newState[key === 'workflowId' ? 'chainId' : 'workflowId'] = null;
        newState[key] = value;
        newState.status = [];
        newState.dates[0] = {dateType: '', start: null, end: null };
        break;
      }
      case 'dateType': {
        newState.dates[index].dateType = value;
        break;
      }
      case 'start':
      case 'end': {
        newState.dates[index][key] = value;
        break;
      }
      case 'status': {
        this.handleUpdatingArrays(newState.status, value, newState, key);
        break;
      }
      case 'additionalOutputs': {
        this.handleUpdatingArrays(newState.additionalOutputs, value, newState, key);
        break;
      }
      case 'wipeDateSelection': {
        newState.dates = [{ dateType: '', start: null, end: null }];
        break;
      }
      case 'wipeStatusSelection': {
        newState.status = [];
        break;
      }
      default: {
        newState[key] = value;
      }
    }

    this.setState({ searchData: newState });
  }

  handleUpdatingArrays(arrayToUpdate, value, newState, key) {
    if(arrayToUpdate.includes(value)) {
      const indexer = newState[key].findIndex(s => s === value);
      newState[key].splice(indexer, 1);
    } else {
      newState[key].push(value);
    }
  }

  // Returning "true" from this will cause the status to be unavailable.
  handleStatusCheckboxes(searchData, status) {
    /*
      Allowed statuses:
      
      No date selected / created all time - all statuses (completed, staged, open, canceled, paused)
      Created date selected - all statuses (completed, staged, open, canceled, paused)
      Released date selected - completed, open, canceled, paused
      Completed date selected - completed
      Canceled date selected - canceled
    */
    const selectedDateType = searchData.dates[0].dateType;
    const statusToCheck = status.value;

    switch(selectedDateType) {    
     case 'releasedOn':
      if (statusToCheck === 'staged') {
          return true;
      }
      
      break;
    case 'completedOn':
      if (statusToCheck !== 'completed') {
        return true;
      }

      break;
    case 'canceledOn':
      if (statusToCheck !== 'canceled') {
        return true;
      }

      break;
    }

    // We didn't find a reason to turn the status off, it's available.
    return false;
  }

  rerunExport(exportParameters) {
    let newExportParameters = JSON.parse(JSON.stringify(exportParameters));
    newExportParameters.dates = [{ start: null, end: null, dateType: '' }];
    newExportParameters.name = null;

    this.setState({
      statusToggle: !!exportParameters.status.length,
      selectedOption: exportParameters.chainId ? 'chain' : 'workflow',
      searchData: newExportParameters
    });

    window.scrollTo(0, 0);
  }

  handleToggle(filterType, toggle) {
    switch (filterType) {
      case 'dateFilter':
        this.setState({dateToggle: toggle});
        if(!toggle) {
          this.updateSearchData('wipeDateSelection');
        }
        break;
      case 'statusFilter': {
        this.setState({statusToggle: toggle});
        if(!toggle) {
          this.updateSearchData('wipeStatusSelection');
        }
      }
    }
  }

  handleShowingBreadCrumb(locationQuery) {
    if(!!locationQuery.workflowId) {
      return [
        { href: `/workflows/${locationQuery.workflowId}`, label: 'Manage Workflow' },
        { href: `/workflows/${locationQuery.workflowId}/batch`, label: 'Workflow Batch Management' },
        { label: 'Advanced Export' }
      ];
    }
    return [
      { href: `/projects/${locationQuery.projectId}/chains/${locationQuery.chainId}`, label: 'Chain Batch Management' },
      { label: 'Advanced Export' }
    ];
  }

  render() {
    const { workflows, isLoadingWorkflows, fetchingChains, exportHistory, isFetchingExportHistory, location } = this.props;
    const { searchData } = this.state;

    const locationQuery = parse(location.search, {ignoreQueryPrefix: true});

    if(isLoadingWorkflows || fetchingChains) {
      return (<div style={{ marginTop: 50 }}><LoadState label="Loading Advanced Export"/></div>);
    }

    return (
      <DocumentTitle title="Advanced Export | OneSpace Project Center">
        <div>
          <HelpPanel
            pageTitle="Advanced Export"
            pageDescription={pageDescription}
            links={[
              {
                href: 'https://onespace.helpdocs.com/managing-your-work/advanced-export-how-it-works',
                label: 'Advanced Export: How It Works',
              }
            ]}
          />
          <Wrapper>
            <div className="chains chain-editor">
              {locationQuery && (locationQuery.workflowId || locationQuery.chainId) &&
                <BreadCrumb
                  links={this.handleShowingBreadCrumb(locationQuery) || []}
                />
              }
            </div>
            {/* Page Title */}
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}}>
              <Heading color="dark">Advanced Export</Heading>
              <div style={{ marginBottom: 10 }}>
                <IconButton
                  fontWeight={800}
                  icon={'arrow-down'}
                  iconPosition="right"
                  underline={false}
                  align="right"
                  handleClick={() => {
                    document.querySelector('.export-history').scrollIntoView({ block: 'start', behavior: 'smooth' });
                  }}>
                  My Exports
                </IconButton>
              </div>
            </div>

            <Panel includeHeader={false}>
              <div style={styles.mainWrapper}>
                <MediumLabel color="dark" uppercase>SELECT A WORKFLOW</MediumLabel>
                {/* Workflow/Chain Selector */}
                <div style={{ display: 'flex', marginTop: 10, marginBottom: 30 }}>
                  {/* LEAVE THIS COMMENTED OUT UNTIL CHAIN EXPORT IS ALLOWED */}
                  <div style={{ flex: 4, marginRight: 20 }}>
                    <div style={styles.shadedGrayBg}>
                      <InlineList>
                        <div style={{ flex: 1 }}>
                          <RadioCheckbox
                            checked={this.state.selectedOption === 'workflow'}
                            id="workflowSelect"
                            value="workflow"
                            label="Individual Workflow"
                            handleChange={this.handleRadioButtons}
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <RadioCheckbox
                            checked={this.state.selectedOption === 'chain'}
                            id="chainSelect"
                            value="chain"
                            label="Workflow Chain"
                            handleChange={this.handleRadioButtons}
                          />
                        </div>
                      </InlineList>
                    </div>
                  </div>
                  <div style={{ flex: 7, paddingRight: 20 }}>
                    {(!!workflows.length) &&
                      <Select
                        value={
                          searchData.workflowId ? searchData.workflowId : searchData.chainId ? searchData.chainId : ''
                        }
                        searchable
                        height={45}
                        options={this.handleWorkflowsOrChainsSelect() || [{
                          label: 'Select',
                          value: ''
                        }]}
                        handleSelect={(val) => this.updateSearchData(this.state.selectedOption === 'workflow' ? 'workflowId' : 'chainId', val)}
                      />
                    }
                  </div>
                </div>

                <div>
                  {/* Filter by Date Section */}
                  <FilterByDate
                    searchData={searchData}
                    toggle={this.state.dateToggle}
                    handleToggle={(toggle) => this.handleToggle('dateFilter', toggle)}
                    updateSearchData={(property, value, index) => this.updateSearchData(property, value, index && index)}
                  />

                  {/* Filter by Status Section */}
                  <FilterByStatus
                    searchData={searchData}
                    toggle={this.state.statusToggle}
                    handleToggle={(toggle) => this.handleToggle('statusFilter', toggle)}
                    updateSearchData={(property, value, index) => this.updateSearchData(property, value, index && index)}
                    styles={styles}
                    handleStatusCheckboxes={(searchData, status) => this.handleStatusCheckboxes(searchData, status)}
                  />
                </div>

                {/* Additional Outputs Section */}
                <div style={styles.collapser}>
                  <IconButton
                    fontWeight={800}
                    icon={this.state.collapsed ? 'chevron-down' : 'chevron-up'}
                    iconPosition="right"
                    underline={false}
                    align="left"
                    handleClick={() => {
                      this.setState({collapsed: !this.state.collapsed});
                    }}>
                    Additional Outputs
                  </IconButton>
                </div>
                <div style={{height: this.state.collapsed ? 0 : 'auto', overflow: 'hidden'}}>
                  <AdditionalOutputs
                    updateSearchData={(property, value, index) => this.updateSearchData(property, value, index && index)}
                    selectedId={searchData.workflowId || searchData.chainId}
                    additionalOutputs={searchData.additionalOutputs}
                    nodeType={this.state.selectedOption}
                  />
                </div>

                {/* Name Section */}
                <div style={{ marginTop: 20 }}>
                  <div style={{ maxWidth: 500 }}>
                    <InputLabel
                      label="Export Name"
                      name="name"
                      value={searchData.name !== null ? searchData.name : this.handleCreatingName(searchData)}
                      handleChange={name => this.updateSearchData('name', name)}
                      disabled={false}
                    />
                  </div>

                  { this.state.notes.map( ( note, i ) => (
                    <div key={i} style={{ marginBottom: 1 }}>
                      <Note
                        type={note.type}
                        note={note.note}
                        handleClose={() => {
                          const notes = [ ...this.state.notes ];
                          notes.splice( i, 1 );
                          this.setState( { notes } );
                        }}
                      />
                    </div>
                  ) )}

                  <div style={{ marginTop: 20 }}>
                    <Button
                      height={56}
                      loading={this.state.isCreatingExport}
                      disabled={!(searchData.workflowId || searchData.chainId) || this.state.isCreatingExport || isFetchingExportHistory}
                      handleClick={() => this.createExport(searchData)}>
                      {'Create Export'}
                    </Button>
                  </div>
                </div>

              </div>
            </Panel>

            <div className="export-history" style={{ marginTop: 20 }}>
              <Panel small title="MY EXPORTS">
                <div style={styles.mainWrapper}>

                  {(isFetchingExportHistory && !this.state.creatingExport) ?
                    <LoadState label="Fetching Advanced Export History"/>
                    :
                    <div style={{ margin:  '20px 0' }}>
                      {!exportHistory.length ? 
                        <Label>{noHistoryText}</Label>
                        :
                        <Table headerCols={headerCols}>
                          {exportHistory.map((item, idx) => {
                            const lessThanMinuteOld = moment.duration(moment().diff(item.creationDate)).asMinutes() < 1;
                            return (
                              <Row fresh={lessThanMinuteOld} key={idx}>
                                <Column first={true}>
                                  <Content>{moment(item.creationDate).format('MM/DD/YYYY, h:mm:ss a')}</Content>
                                </Column>
                                <Column><Label>{item.exportParameters.name}</Label></Column>
                                <Column><Label>{item.exportStatus}</Label></Column>
                                <Column align="center">
                                  <InlineButton
                                    disabled={!legitStatuses.includes(item.exportStatus) || fetchingChains}
                                    handleClick={() => this.rerunExport(item.exportParameters)}>
                                    <i className="fa fa-repeat"/>
                                  </InlineButton>
                                </Column>
                                <Column align="center">
                                  <InlineButton
                                    disabled={!legitStatuses.includes(item.exportStatus)}
                                    handleClick={() => window.open(item.downloadUrl)}>
                                    <i className="fa fa-download"/>
                                  </InlineButton>
                                </Column>
                              </Row>
                            );
                          })}
                        </Table>
                      }
                    </div>
                  }

                </div>
              </Panel>
            </div>

          </Wrapper>
        </div>
      </DocumentTitle>
    );
  }
}

const styles = {
  mainWrapper: {
    padding: 16,
  },
  shadedGrayBg: {
    display: 'flex',
    padding: '10px 16px',
    marginLeft: 20,
    backgroundColor: colors.shaded,
    borderRadius: 5,
  },
  radioBtn: {
    marginRight: 4,
    width: 16,
    position: 'relative',
    top: -2,
  },
  collapser: {
    margin: '30px 0 0',
    overflow: 'hidden',
  },
};

function select(state) {
  const workflows = !!Object.keys(state.workflows.items) && state.workflows.items;
  const chains = !!Object.keys(state.chains.items) && state.chains.items;
  return {
    workflows: objectToArray(workflows).filter(w => !!w.launched && w.state !== 'archived'),
    chains: objectToArray(chains),
    fetchingChains: state.chains.isFetching,
    isLoadingWorkflows: state.workflows && state.workflows.isFetching,
    exportHistory: state.advancedExports.items && objectToArray(state.advancedExports.items).sort(sortByDate),
    isFetchingExportHistory: state.advancedExports.isFetching,
  };
}

export default connect( select )( AdvancedExports );
