export const createNewInputObject = (input, newInputName) => {
  const newInput = {...input};
  newInput.name = newInputName;
  return [newInput];
};

export const removeUnusedIOsFromImportNode = (chainNodes, importNode) => {
  let importNodeInputs = importNode.operation.startup.inputs;
  let importNodeOutputs = importNode.operation.startup.outputs;

  const allAvailableMappingsArray = [];
  const usedOutputs = [];
  let mappingObject;

  /* Loop through all chain nodes and each route and find
   * the mapping then push it to allAvailableMappingsArray */
  chainNodes.forEach(node => {
    node.routes && node.routes.forEach(route => {
      mappingObject = Object.keys(route.mapping);
      if (!!mappingObject.length) {
        allAvailableMappingsArray.push(mappingObject);
      }
    });
  });

  // If there are any mapping at all
  if (!!allAvailableMappingsArray.length) {

    // Concat the array of arrays
    const allAvailableMappings = allAvailableMappingsArray.reduce((a, b) => a.concat(b));

    // If mapping is importNode mapping, push input names to usedOutputs array
    allAvailableMappings.forEach(am => {
      if (am.split('|')[0] === importNode.id) {
        usedOutputs.push(am.split('|')[2]);
      }
    });

    // Only keep the unused inputs
    importNode.operation.startup.inputs = [...importNodeInputs].filter(input => {
      return usedOutputs.includes(input.name);
    });

    // Only keep the unused outputs
    importNode.operation.startup.outputs = [...importNodeOutputs].filter(output => {
      return usedOutputs.includes(output.name);
    });
  }
};