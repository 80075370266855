import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import colors from '../styles/colors';
import InlineList from '../components/InlineList';
import { Label, Caption } from './type';

/* HelperMethods */
const getTotal = progresses => {
  let amountTotal = 0;
  progresses.filter(p => !!p.amount).forEach(p => amountTotal += p.amount);
  return amountTotal;
};
const getPercent = (amount, total) => {
  return (amount / total) * 100;
};

/**
 * StackedProgressBar
 * Horizontal pie chart.
 * This component calculates percentages
 */
const StackedProgressBar = ({ progresses, showLegend, handleClickSegment }) => {
  const total = getTotal(progresses);
  const filteredProgresses = progresses.filter(progress => progress.amount >= 0);

  return (
    <div style={{ width: '100%', flex: 1 }}>
      <div className={css(styles.stackedProgressBar)}>
        {filteredProgresses.map((progress, i) => (
          <Progress
            key={i}
            percent={getPercent(progress.amount, total)}
            first={i === 0}
            last={i === filteredProgresses.length - 1}
            handleClick={handleClickSegment}
            {...progress}
          />
        ))}
      </div>
      {showLegend &&
      <div className={css(styles.legend)}>
        {filteredProgresses.map((progress, i) => (
          <div key={i} style={{ marginLeft: 8, marginRight: 8}}>
            <LegendItem {...progress}/>
          </div>
        ))}
      </div>
      }
    </div>
  );
};
const Progress = ({ amount, percent, color, label, handle, first, last, handleClick }) => (
  <div
    onClick={() => handleClick && handleClick(handle)}
    style={{
      backgroundColor: color,
      width: `${percent >= 0 ? percent : 100}%`,
      minWidth: 24,
      borderRadius: getBorderRadius(first, last),
      cursor: handleClick ? 'pointer' : 'initial',
    }}
    className={css(styles.progress)}>
    <Label color="white">{amount.toLocaleString()}</Label>
    {label &&
    <div className={css(styles.labelWrapper)}>
      <div className={css(styles.label)}><Label>{label}</Label></div>
    </div>
    }
  </div>
);
const LegendItem = ({color, amount, label}) => (
  <InlineList spacing={8}>
    <div style={{background: color, height: 16, width: 16}} />
    <Label>{amount.toLocaleString()}</Label>
    <Caption>{label}</Caption>
  </InlineList>
);
function getBorderRadius(first, last) {
  if (first && last) {
    return 4;
  }
  if (first) {
    return '4px 0 0 4px';
  }
  if (last) {
    return '0 4px 4px 0';
  }
  return 0;
}

/* Styles */
const styles = StyleSheet.create({
  stackedProgressBar: {
    display: 'flex',
    height: 32,
    borderRadius: 2,
  },

  progress: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    transition: 'width 0.4s ease-in',
  },

  labelWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    transition: 'opacity 0.2s ease',
    ':hover': {
      opacity: 1,
    }
  },

  label: {
    position: 'absolute',
    top: -34,
    display: 'flex',
    alignItems: 'center',
    height: 32,
    paddingLeft: 8,
    paddingRight: 8,
    background: colors.background,
    border: `1px solid ${colors.border}`,
  },
  legend: {
    display: 'flex',
    flexWrap: 'wrap',
    border: `1px solid ${colors.border}`,
    borderRadius: 4,
    padding: 8,
    marginTop: 16,
    marginBottom: 16,
  }
});

/* PropTypes */
StackedProgressBar.propTypes = {
  progresses: PropTypes.arrayOf(PropTypes.shape({
    amount: PropTypes.number,
    color: PropTypes.string,
    label: PropTypes.string
  })).isRequired,
  handleClickSegment: PropTypes.func,
};

export default StackedProgressBar;
