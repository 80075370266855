import React, { Component } from 'react';

import ChainMonitorBuilder from './ChainMonitorBuilder';

class Chain extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {
      chain,
      handleClickUpload,
      handleClickRelease,
      handleClickDownload,
      handleReady,
      workflows,
      workstationUrl,
      pluginMetrics,
    } = this.props;

    // Create a new Chain Builder component
    this.chainBuilder = new ChainMonitorBuilder(
      chain, this.canvas, workflows, this.mini, workstationUrl, pluginMetrics
    );
    // Listen to when the user clicks the release button
    this.chainBuilder.addListener('releaseButton', handleClickRelease);
    // Listen to when the user clicks the upload button
    this.chainBuilder.addListener('uploadButton', handleClickUpload);
    // Listen to when the user clicks the upload button
    this.chainBuilder.addListener('downloadButton', handleClickDownload);

    if (handleReady) {
      handleReady(this.chainBuilder);
    }
  }

  render() {
    const { chain } = this.props;
    return (
      <div style={{ position: 'relative' }}>
        <div
          ref={ele => this.canvas = ele}
          id={`canvas-${chain.id}`}
          className="jtk-demo-canvas">
          <div ref={element => this.mini = element} className="miniview"/>
        </div>
      </div>
    );
  }
}

export default Chain;