import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import InlineList from '../components/InlineList';
import { Label } from '../components/type';

/**
 * BreadCrumb
 * Display a list of links
 */
const BreadCrumb = ({ links }) => (
  <InlineList spacing={12}>
    {links.map((link, i) => <Crumb key={i} {...link} showArrow={i > 0}/>)}
  </InlineList>
);

const Crumb = ({ href, label, showArrow, refresh }) => (
  <div style={styles.crumb}>
    {showArrow && <div style={styles.arrow}><Label color="dark">></Label></div>}
    {href && refresh && <Label underline={true} href={href} color="dark">{label}</Label>}
    {href && !refresh && <Link to={href}><Label underline={true} color="dark" isLink={true}>{label}</Label></Link>}
    {!href && <Label color="dark">{label}</Label>}
  </div>
);

/* Styles */
const styles = {
  crumb: {
    display: 'flex',
    alignItems: 'center',
  },
  arrow: {
    marginRight: 12,
  },
};

/* PropTypes */
BreadCrumb.propTypes = {
  links: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string.isRequired,
    refresh: PropTypes.bool,
  })).isRequired]),
};

export default BreadCrumb;

