import React from 'react';
import Input from '../../Form/Input';
import Settings from '../Settings';
import SupportRoleOptions from './SupportRoleOptions';


class HiddenInput extends React.Component {
  componentDidMount() {
    if (this.props.selected) {
      this.inline.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.selected && this.props.selected) {
      this.inline.focus();
    }
  }

  render() {
    const {data = {}, selected, updateElement, handleSelectInput, isSupportRole} = this.props;
    return (
      <div>
        <Input
          ref={ele => this.inline = ele}
          disabled={!selected}
          mockTextArea={true}
          placeholder="Add hidden input"
          value={data.value}
          onChange={value => updateElement({value})}
          onFocus={() => handleSelectInput('value')}
        />
        <Settings show={selected} customHeight="auto">
          {isSupportRole &&
          <SupportRoleOptions
            data={data}
            updateElement={updateElement}
            handleSelectInput={handleSelectInput}
          />
          }
        </Settings>
      </div>
    );
  }
}

export default HiddenInput;