import React from 'react';
import PropTypes from 'prop-types';

import colors from '../styles/colors';
import { Label } from './type';

/**
 * InlineButton
 * Smaller than "Button". Used inline with other components
 */
const InlineButton = ({ children, disabled, handleClick, loading, title }) => (
  <div style={styles.button(disabled)} onClick={e => !disabled && handleClick(e)}>
    <Label
      title={title}
      color={!disabled ? 'white' : 'lighter'}
      isLink={true}>
      <div style={{display: 'flex', alignItems: 'center'}}>
        {loading &&
        <div style={{paddingRight: 8}}>
          <i className="fa fa-spin fa-spinner" />
        </div>
        }
        {children}
      </div>
    </Label>
  </div>
);

/* Styles */
const styles = {
  button: disabled => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 32,
    minWidth: 32,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 8,
    background: disabled ? colors.background : colors.primary(),
    cursor: 'pointer',
  }),
};

/* PropTypes */
InlineButton.propTypes = {
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
  children: PropTypes.node,
};

InlineButton.defaultProps = {
  loading: false,
  disabled: false,
};

export default InlineButton;
