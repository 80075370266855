import React, { Component } from 'react';
import PropTypes from 'prop-types';

import colors from '../styles/colors';
import IconButton from '../components/IconButton';
import InlineList from '../components/InlineList';
import HamburgerMenu from '../components/HamburgerMenu';
import Input from '../components/Input';
import Button from '../components/Button';
import FlatButton from '../components/FlatButton';
import { PanelAttention, SmallPanelAttention } from '../components/type';

/**
 * PanelHeader
 * Used inside a Panel Component at the top to display a title
 * This component takes an array of actions to show on the right
 */
class PanelHeader extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      editable: false,
      panelTitle: props.title || '',
      savingName: false,
      errorMsg: '',
    };
    this.handleMenuSelect = this.handleMenuSelect.bind( this );
  }

  /*Handle the selection of a menu option. If the option selected is edit we want to open the edit view instead of
   calling the function passed in trhough props*/
  handleMenuSelect( option ) {
    if ( option === 'edit' ) {
      this.setState( { editable: true } );
    } else {
      this.props.handleSelectOption( option );
    }
  }

  /*This function calls a save funciton passed from props and catches the response success or error and handles that */
  saveNameChange( name ) {
    if ( this.props.validateWorkflowName ) {
      this.props.validateWorkflowName( name ).then( res => {
        if ( res.success ) {
          this.props.saveTitle( name ).then(() => {
            this.setState({savingName: false, editable: false});
          });
        } else {
          this.setState( { errorMsg: 'Workflow name already is use.', savingName: false } );
        }
      } );
    } else {
      //this will need work when we add a new editable title
      this.props.saveTitle( name ).then(() => this.setState({savingName: false}));
    }
  }

  render() {
    const { title, titleHref, actions, simple, color, small, options } = this.props;
    const { editable, panelTitle, errorMsg, savingName } = this.state;
    return (
      <div style={styles}>

        {/* Panel Title */}
        {!editable &&
        <div>
          {small ? (
              <SmallPanelAttention
                color={!!color ? 'light' : (!!titleHref ? 'darker' : 'dark')}
                hoverColor={!!titleHref ? 'primary' : 'dark'}
                href={titleHref}
                underline={!!titleHref}>
                {title}
              </SmallPanelAttention>
            ) :
            (<PanelAttention
              color={!!color ? 'light' : (!!titleHref ? 'darker' : 'dark')}
              hoverColor={!!titleHref ? 'primary' : 'dark'}
              href={titleHref}
              underline={!!titleHref}>
              {title}
            </PanelAttention>)
          }
        </div>
        }

        {/* Edit Panel Header */}
        {editable &&
        <div style={{ display: 'flex', alignItems: errorMsg ? 'flex-start' : 'center' }}>
          <div style={{ width: 450 }}>
            <Input
              value={panelTitle}
              handleChange={val => this.setState( { panelTitle: val } )}
              error={errorMsg}
              invalid={!!errorMsg}/>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: errorMsg ? 2 : 0 }}>
            <div style={{ margin: '0px 12px' }}>
              <Button
                loading={savingName}
                handleClick={() => this.setState( {
                  savingName: true,
                  errorMsg: ''
                }, () => this.saveNameChange( panelTitle ) )}>
                Save
              </Button>
            </div>
            <FlatButton
              handleClick={() => this.setState( { editable: false, errorMsg: '', panelTitle: title } )}>
              Cancel
            </FlatButton>
          </div>
        </div>
        }


        {/* Panel Actions */}
        {!editable &&
        <InlineList spacing={16} justify="flex-end">
          {actions.map( ( action, i ) => (
            <IconButton
              key={i}
              icon={action.icon}
              href={action.href}
              target={action.target}
              bold={simple}
              handleClick={action.handleClick}
              underline={action.underline}>
              {action.label}
            </IconButton>
          ) )}
        </InlineList>
        }

        {/* Panel Hamburger Menu */}
        {(options.length > 0 && !editable) &&
        <div style={{ margin: '0 8px 0 8px', justifyContent: 'flex-end' }}>
          <HamburgerMenu options={options} handleSelect={ option => this.handleMenuSelect( option ) }/>
        </div>
        }

      </div>
    );
  }
}

/* Styles */
const styles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '18px 16px',
  borderBottom: `2px solid ${colors.divider}`,
  boxSizing: 'border-box',
  lineHeight: 1.25,
};

/* PropTypes */
PanelHeader.propTypes = {
  title: PropTypes.string.isRequired,
  titleHref: PropTypes.string,
  actions: PropTypes.arrayOf( PropTypes.shape( {
    icon: PropTypes.string.isRequired,
    href: PropTypes.string,
    handleClick: PropTypes.func,
  } ) ),
  color: PropTypes.string,
  handleSelectOption: PropTypes.func,
};
PanelHeader.defaultProps = {
  actions: [],
  options: [],
};

export default PanelHeader;