import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ChainBuilder from './ChainBuilder';

class Chain extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { chain, workflows, } = this.props;

    // Create a new Chain Builder component
    this.chainBuilder = new ChainBuilder(chain, this.canvas, workflows);
  }

  render() {
    const { chain, handleClick } = this.props;
    return (
      <div style={{ position: 'relative' }}>
        <div
          ref={ele => this.canvas = ele}
          id={`canvas-${chain.id}`}
          className="jtk-demo-canvas" />
        <div
          className="chain-lock-overlay"
          style={{
            display: 'block',
            cursor: 'pointer',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }}
          onClick={handleClick}
        />
      </div>
    );
  }
}

Chain.propTypes = {
  handleClick: PropTypes.func,
};

export default Chain;
