import React from 'react';
import Input from '../../Form/Input';
import Settings from '../Settings';
import SupportRoleOptions from './SupportRoleOptions';


class Paragraph extends React.Component {
  componentDidMount() {
    if (this.props.selected) {
      this.inline.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.selected && this.props.selected) {
      this.inline.focus();
    }
  }

  render() {
    const {data = {}, selected, updateElement, handleSelectInput, isSupportRole} = this.props;
    return (
      <div>
        <Input
          ref={ele => this.inline = ele}
          disabled={!selected}
          mockTextArea={true}
          placeholder="Add paragraph text"
          value={data._innerValue}
          onChange={_innerValue => updateElement({_innerValue})}
          onFocus={() => handleSelectInput('_innerValue')}
        />
        <Settings show={selected} customHeight="auto">
          {isSupportRole &&
          <SupportRoleOptions
            data={data}
            updateElement={updateElement}
            handleSelectInput={handleSelectInput}
          />
          }
        </Settings>
      </div>
    );
  }
}

export default Paragraph;
