import React from 'react';

/**
 * Wrapper
 * Allows content of page to have a max width
 */
const Wrapper = ({ className, children }) => (
  <div className={className} style={styles}>
    {children}
  </div>
);

/* Styles */
const styles = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: 1072,
  margin: '0 auto',
  paddingLeft: 16,
  paddingRight: 16,
  boxSizing: 'border-box',
};

export default Wrapper;
