export const ItemTypes = {
  ELEMENT: 'ELEMENT',
  INPUT: 'INPUT'
};

export const nonselfdroppablelayoutTypes = [
  'ONECOLUMNLAYOUT',
  'TWOCOLUMNLAYOUT',
  'THREECOLUMNLAYOUT',
  'FOURCOLUMNLAYOUT',
];

export const correspondingIcons = {
  ONECOLUMNLAYOUT: 'single_column',
  TWOCOLUMNLAYOUT: 'two_column',
  THREECOLUMNLAYOUT: 'three_column',
  FOURCOLUMNLAYOUT: 'four_column',
  HR: 'HR',
  HEADING: 'header',
  PARAGRAPH: 'paragraph3',
  IMAGE: 'image',
  LINK: 'link',
  CHECKBOX: 'checkbox',
  DROPLIST: 'droplist',
  TEXTFIELD: 'formbox',
  TEXTAREA: 'textbox',
  RADIOBUTTON: 'radio',
  TEXTDIFFERENCE: 'textdiff',
  EMAIL: 'envelope',
  UPLOAD: 'cloud',
};

export const ElementTypes = {
  ONECOLUMNLAYOUT: 'ONECOLUMNLAYOUT',
  TWOCOLUMNLAYOUT: 'TWOCOLUMNLAYOUT',
  THREECOLUMNLAYOUT: 'THREECOLUMNLAYOUT',
  FOURCOLUMNLAYOUT: 'FOURCOLUMNLAYOUT',
  HR: 'HR',
  HEADING: 'HEADING',
  PARAGRAPH: 'PARAGRAPH',
  IMAGE: 'IMAGE',
  LINK: 'LINK',
  CHECKBOX: 'CHECKBOX',
  DROPLIST: 'DROPLIST',
  TEXTFIELD: 'TEXTFIELD',
  TEXTAREA: 'TEXTAREA',
  RADIOBUTTON: 'RADIOBUTTON',
  TEXTDIFFERENCE: 'TEXTDIFFERENCE',
  EMAIL: 'EMAIL',
  UPLOAD: 'UPLOAD',
  HIDDENINPUT: 'HIDDENINPUT',
  HIDDENOUTPUT: 'HIDDENOUTPUT',
};

export const ElementOptions = [
  /* LAYOUT ELEMENTS */
  {
    icon: 'single_column',
    type: ElementTypes.ONECOLUMNLAYOUT,
    name: 'one column',
    defaultData: {
      columns: [
        {
          data: {},
          children: []
        },
      ]
    },
    cat: 'layout',
  },
  {
    icon: 'two_column',
    type: ElementTypes.TWOCOLUMNLAYOUT,
    name: 'two column',
    defaultData: {
      columns: [
        {
          data: {},
          children: []
        },
        {
          data: {},
          children: []
        },
      ]
    },
    cat: 'layout',
  },
  {
    icon: 'three_column',
    type: ElementTypes.THREECOLUMNLAYOUT,
    name: 'three column',
    defaultData: {
      columns: [
        {
          data: {},
          children: []
        },
        {
          data: {},
          children: []
        },
        {
          data: {},
          children: []
        },
      ]
    },
    cat: 'layout',
  },
  {
    icon: 'four_column',
    type: ElementTypes.FOURCOLUMNLAYOUT,
    name: 'four column',
    defaultData: {
      columns: [
        {
          data: {},
          children: []
        },
        {
          data: {},
          children: []
        },
        {
          data: {},
          children: []
        },
        {
          data: {},
          children: []
        },
      ]
    },
    cat: 'layout',
  },

  /* DISPLAY ELEMENTS */
  {
    icon: 'header',
    type: ElementTypes.HEADING,
    name: 'heading',
    defaultData: {
      style: 'heading'
    },
    cat: 'display',
  },
  {
    icon: 'paragraph3',
    type: ElementTypes.PARAGRAPH,
    name: 'paragraph',
    defaultData: {},
    cat: 'display',
  },
  {
    icon: 'image',
    name: 'image',
    type: ElementTypes.IMAGE,
    defaultData: {
      maxWidth: 320,
      maxHeight: 320,
      showFullImage: true,
    },
    cat: 'display',
  },
  {
    icon: 'textdiff',
    name: 'text diff',
    type: ElementTypes.TEXTDIFFERENCE,
    defaultData: {},
    cat: 'display',
  },
  {
    icon: 'hr',
    type: ElementTypes.HR,
    name: 'line',
    defaultData: {},
    cat: 'display',
  },
  {
    icon: 'formbox',
    type: ElementTypes.HIDDENINPUT,
    name: 'HIDDEN INPUT',
    defaultData: {},
    cat: 'display',
  },

  /* COLLECT ELEMENTS */
  {
    icon: 'checkbox',
    name: 'checkbox',
    type: ElementTypes.CHECKBOX,
    output: true,
    defaultData: {
      options: [
        {
          label: ''
        },
        {
          label: ''
        },
        {
          label: ''
        }
      ]
    },
    cat: 'collect',
  },
  {
    icon: 'radio',
    name: 'radio button',
    type: ElementTypes.RADIOBUTTON,
    output: true,
    defaultData: {
      options: [
        {
          label: ''
        },
        {
          label: ''
        },
        {
          label: ''
        }
      ],
      required: true
    },
    cat: 'collect',
  },
  {
    icon: 'droplist',
    name: 'droplist',
    type: ElementTypes.DROPLIST,
    output: true,
    defaultData: {
      required: true,
      options: [
        {
          label: ''
        },
        {
          label: ''
        }
      ]
    },
    cat: 'collect',
  },
  {
    icon: 'formbox',
    name: 'text field',
    type: ElementTypes.TEXTFIELD,
    output: true,
    defaultData: {
      fieldwidth: 'large',
      limitType: 'words',
      required: true,
      spellcheck: true
    },
    cat: 'collect',
  },
  {
    icon: 'textbox',
    name: 'text area',
    type: ElementTypes.TEXTAREA,
    output: true,
    defaultData: {
      limitType: 'words',
      required: true,
      spellcheck: true
    },
    cat: 'collect',
  },
  {
    icon: 'envelope',
    name: 'email',
    type: ElementTypes.EMAIL,
    output: true,
    defaultData: {
      required: true
    },
    cat: 'collect',
  },
  {
    icon: 'cloud',
    name: 'upload',
    type: ElementTypes.UPLOAD,
    output: true,
    defaultData: {
      type: 'document',
      required: true
    },
    cat: 'collect',
  },
  {
    icon: 'link',
    name: 'link',
    type: ElementTypes.LINK,
    output: true,
    defaultData: {
      required: true
    },
    cat: 'collect',
  },
  {
    icon: 'formbox',
    type: ElementTypes.HIDDENOUTPUT,
    name: 'HIDDEN OUTPUT',
    defaultData: {},
    cat: 'collect',
  },
];
