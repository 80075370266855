import React from 'react';

import InlineButton from '../../components/InlineButton';

import {Caption} from '../../components/type';
import colors from '../../styles/colors';

const DisabledPanel = ({description, btnText, handleClick}) => {
  return (
    <div style={styles.archivedContainer}>
      <div style={styles.archivedContent}>
        <div style={{display: 'flex', justifyContent: 'center', marginBottom: 5}}>
          <Caption color='white'>
            {description}
          </Caption>
        </div>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <InlineButton handleClick={() => handleClick()} >
            {btnText}
          </InlineButton>
        </div>
      </div>
    </div>
  );
};

const styles = {
  archivedContainer: {
    display: 'flex',
    flex: 6,
    background: colors.light,
    justifyContent: 'center',
    alignItems: 'center'
  },
  archivedContent: {
    background: colors.dark,
    height: 90,
    width: 300,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 5,
    flexDirection: 'column',
  },
};

export default DisabledPanel;