import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import update from 'immutability-helper';
import Autocompelete from 'react-autocomplete';

import post from '../../../../utils/post';
import MainButton from '../MainButton';
import InputLabel from '../InputLabel';
import IconButton from '../IconButton';
import RichEditor from '../RichEditor';
import ToTile from '../ToTile';

const banned = ['ade,', 'adp,', 'bat,', 'chm,', 'cmd,', 'com,', 'cpl,', 'exe,', 'hta,', 'ins,', 'isp,', 'jar,', 'jse,', 'lib,', 'lnk,', 'mde,', 'msc,', 'msp,', 'mst,', 'pif,', 'scr,', 'sct,', 'shb,', 'sys,', 'vb,', 'vbe,', 'vbs,', 'vxd,', 'wsc,', 'wsf,', 'wsh'];

class ComposeMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      to: props.to || [],
      toSearch: '',
      subject: props.subject,
      uploading: false,
      attachments: [],
      attachmentError: null,
      teams: props.teams || [],
      teamName: props.teamName || null,
      teamId: props.teamId || null,
      toSearching: false,
      toResults: [],
      toSearchQ: '',
      saving: false
    };
    this.removeTeam = this.removeTeam.bind(this);
  }

  componentWillUnmount() {

    if (!this.state.saving) {
      this.draftMessage();
    }
  }

  componentDidMount() {
    window.onbeforeunload = () => {
      if (!this.state.saving) {
        this.draftMessage();
      }
    };
  }

  draftMessage() {
    const body = this.editor.getBody();
    if (body && body !== '<p><br></p>') {
      this.handleSave(false, true);
    }
  }
  
  shouldComponentUpdate(n, s) {
    return (JSON.stringify(n) !== JSON.stringify(this.props)) || (JSON.stringify(s) !== JSON.stringify(this.state));
  }

  addAttachment(files = []) {
    this.setState({attachmentError: null});
    if (files.length) {
      this.setState({uploading: true});
      let attachmentErrorTypes = [];
      files.forEach(file => {
        if (file.type && banned.indexOf(file.type.split('/')[1].toLowerCase()) < 0) {
          this.getSignedRequest(file);
        } else {
          if (!file.type) {
            attachmentErrorTypes.push(file.name);
          } else if (attachmentErrorTypes.indexOf(file.type) < 0) {
            attachmentErrorTypes.push(file.type);
          }
          this.setState({uploading: false});
        }
      });
      if (attachmentErrorTypes.length) {
        this.setState({attachmentError: attachmentErrorTypes});
      }
    }
  }

  getSignedRequest(file) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', '/api/inbox/get-temporary-credentials?fileName=' + Date.now() + file.name + '&fileType=' + file.type);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          var response = JSON.parse(xhr.responseText);
          this.uploadFile(file, response.signedRequest, response.url);
        } else {
          this.setState({uploading: false});
          console.log('error', xhr);
        }
      }
    };
    xhr.send();
  }

  uploadFile(file, signed_request, url) {

    var xhr = new XMLHttpRequest();
    xhr.open('PUT', signed_request);
    xhr.setRequestHeader('x-amz-acl', 'public-read');
    xhr.onload = () => {
      if (xhr.status === 200) {
        this.setState({
          uploading: false,
          attachments: [{filename: file.name, s3Path: url}, ...this.state.attachments]
        });
      }
    };
    const v = this;
    xhr.onerror = function () {
      v.setState({uploading: false});
    };
    xhr.send(file);
  }

  handleKeyCommand(command, shouldFocus) {
    const message = RichUtils.handleKeyCommand(this.state.message, command);
    if (shouldFocus) {
      this.editor.focus();
    }
    if (message) {
      this.setState({message});
      return true;
    }
    return false;
  }

  handleSave(send = true, unmounting = false) {
    var doSave = () => {

      const message = {
        body: this.editor.getBody(),
        subject: this.state.subject,
        toUsers: Array.isArray(this.state.to) ? this.state.to.map(t => ({
          id: t.id,
          first: t.first,
          last: t.last,
          profile: t.profile
        })) :  [],
        attachments: this.state.attachments,
        teams: this.state.teams.map( team => team.teamId ),
      };

      if (this.props.id) {
        message.id = this.props.id;
      }

      if (this.props.handleSendingMessage && send) {
        this.props.handleSendingMessage();
      }
      if (send) {
        this.props.handleClose();
      }
      post('/api/inbox/message', message)
        .then(res => {
          if (res.status > 200) {
            throw(res);
          }
          return res.json();
        })
        .then(response => {
          if (send) {
            message.toUsers = [...new Set([...message.toUsers, ...response.toUsers])];
            post('/api/inbox/message/send', {messageId: response.id, message: message})
              .then(() => {
                if (this.props.handleSentMessage) {
                  this.props.handleSentMessage({id: response.id, message});
                }
              });
          }
        })
        .catch(() => this.props.handleSendFail && this.props.handleSendFail());
      //this.props.handleSentMessage(message);
    };
    if(!unmounting) {
      this.setState({saving: true}, () => {
        doSave();
      });
    } else {
      doSave();
    }
  }

  searchTo(value) {
    if (!value.length && value !== ' ') {
      return this.setState({toSearching: false, toSearchQ: []});
    }
    $.get(`/api/search/contributors/suggest/${value}`, {size: 5}, (res) => {
      if (res) {
        this.setState({toResults: res}, () => {
          if (this.state.toSearchQ) {
            const searchQ = this.state.toSearchQ;
            this.setState({toSearchQ: ''}, () => this.searchTo(searchQ));
          } else {
            this.setState({toSearching: false});
          }
        });
      } else {
        this.setState({toSearching: false});
      }
    }).fail(() => {
      if (this.state.toSearchQ) {
        const searchQ = this.state.toSearchQ;
        this.setState({toSearchQ: ''}, () => this.searchTo(searchQ));
      } else {
        this.setState({toSearching: false});
      }
    });
  }

  removeTeam(teamIndex) {
    let newTeamArray = [...this.state.teams];
    delete newTeamArray[teamIndex];
    this.setState({teams: newTeamArray});
  }

  render() {
    const {to, subject, attachments, teams} = this.state;
    return (
      <div className="compose-message">
        <div className="input-label">
          <span>To</span>
          {this.state.toSearching && <i className="fa fa-spin fa-spinner to-auto-spin"/>}
          {(!!to.length || !!teams) &&
          <div className="tos">
            <div className="list">
              {!!teams &&
                teams.map((team, i) => {
                  return (
                    <ToTile
                      name={team.teamName}
                      image=''
                      handleRemove={() => this.removeTeam(i)}
                      isTeamTile={true} />
                  );
                })
              }
              {to.map((t, i) => (
                <ToTile
                  key={t.id}
                  name={t.name || t.first}
                  image={t.image}
                  handleRemove={() => {
                    const tempTo = [...to];
                    tempTo.splice(i, 1);
                    this.setState({to: tempTo});
                  }}
                />
              ))}
            </div>
          </div>
          }
          <div className="auto-complete-wrapper">
            <Autocompelete
              value={this.state.toSearch}
              items={this.state.toResults || []}
              onChange={(event, value) => {
                  this.setState({toSearch: value});
                  if(!this.state.toSearching) {
                    this.setState({toSearching: true});
                    this.searchTo(value);
                  } else {
                    this.setState({toSearchQ: value});
                  }
              }}
              onSelect={(value, item) => {
                this.setState({toSearch: '', toResults: [], to: [...this.state.to, {id: item.id, image: item.photoUrl, name: item.displayName, first: item.displayName}]});
              }}
              renderItem={(item) => (
                <div key={item.id} className="item-render">
                  <span className="image" style={{display: 'inline-block', backgroundImage: `url("${item.photoUrl || '/img/placeholder.png'}")`, width: 24, height: 24, backgroundSize: 'cover', borderRadius: '50%', marginRight: 8}}/>
                  {item.displayName}
                </div>
              )}
              getItemValue={item => item.id}
            />
            {!this.state.toSearching && this.state.toSearch && !this.state.toResults.length &&
            <div className="auto-complete-empty">
              No contributors found
            </div>
            }
          </div>
        </div>
        <InputLabel
          label="Subject"
          value={subject}
          handleChange={subject => this.setState({subject})}
        />
        {this.state.attachmentError &&
        <div style={{fontSize: 12, color: 'red', marginBottom: 8}}>
          {`Not able to add attachment of type: ${this.state.attachmentError.join(' ')}`}
        </div>
        }
        <div className="attachments">
          <Dropzone
            style={{}}
            onDrop={files => this.addAttachment(files)}>
            <IconButton
              icon={this.state.uploading ? 'spin fa-spinner' : 'plus-square'}
              label="Add Attachment"
            />
          </Dropzone>
          {attachments.map((a, i) => (
            <div className="attachment">
              <a href={a.s3path} download={a.filename}>{a.filename}</a>
              <i
                className="fa fa-times"
                onClick={() => this.setState({attachments: update(attachments, {$splice: [[i, 1]]})})}
              />
            </div>
          ))}
        </div>
        <div className="editor">

          <div className="field">
            <RichEditor ref={ele => this.editor = ele} default={this.props.body}/>
          </div>

        </div>
        <div className="bottom">
          <div className="cancel" onClick={() => this.props.handleClose()}>Cancel</div>
          <MainButton
            disabled={!to}
            label="Send"
            handleClick={() => this.handleSave()}
            large={true}
          />
        </div>
      </div>
    );
  }
}

ComposeMessage.defaultProps = {
  handleSendingMessage: () => {
  },
  handleSentMessage: () => {
  }
};
export default ComposeMessage;