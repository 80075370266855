import React from 'react';

export default ({label, labelBold}) => {
  return (
    <div style={{
      fontSize: 13,
      fontWeight: labelBold ? 900 : 600,
      marginBottom: 4,
      fontStyle: 'italic',
    }}>{label}</div>
  );
};
