import {
  FETCH_PROFILE_REQUEST,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE
} from '../constants/actionTypes';

export function fetchProfile() {
  return {
    types: [FETCH_PROFILE_REQUEST, FETCH_PROFILE_SUCCESS, FETCH_PROFILE_FAILURE],
    fetchRoute: '/api/profile',
    shouldCallAPI: state => !state.profile.hasFetched,
  };
}
