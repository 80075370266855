import React, { Component } from 'react';
import PropTypes from 'prop-types';

import colors from '../../../styles/colors';
import { Attention } from '../../../components/type';
import IconButton from '../../../components/IconButton';
import InputGroup from './InputGroup';
import OutputGroup from './OutputGroup';
import Menu from '../../../components/Menu';

/**
 * Table
 * Simple Table component. Used in modals to display data
 */

export default class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAllMenu: false,
      allSelected: true,
    };
  }

  componentDidMount() {
    let allSelected = true;
    this.props.inputs.forEach(out => {
      if(out.export === false) {
        allSelected = false;
      }
    });
    this.props.outputs.forEach(out => {
      if(out.export === false) {
        allSelected = false;
      }
    });
    this.setState({allSelected});
  }

  componentWillReceiveProps(nextProps) {
    let allSelected = true;
    nextProps.inputs.forEach(out => {
      if(out.export === false) {
        allSelected = false;
      }
    });
    nextProps.outputs.forEach(out => {
      if(out.export === false) {
        allSelected = false;
      }
    });
    this.setState({allSelected});
  }

  allIOSelection(value) {
    if(value === 'selectAll') {
      this.props.selectAll('all');
    } else if(value === 'unSelectAll') {
      this.props.unSelectAll('all');
    }
    this.setState({openAllMenu: false});
  }

  render() {
    const {inputs, outputs, handleClickCell, wfList, resetData, selectAll, unSelectAll} = this.props;
    const {openAllMenu, allSelected} = this.state;
    return (
      <div style={{marginRight: 4}}>

        <div style={styles.allIOContainer}>
          <div style={styles.centerFlex}>
            <div>
              <div style={styles.menuAction} onClick={() => this.setState({openAllMenu: !openAllMenu})}>
                <div style={{padding: '2 8 4 8'}}>
                  <input
                    style={{height: 12}}
                    type="checkbox"
                    checked={allSelected}
                    readOnly={true} />
                </div>
                <span style={styles.checkboxContainer}>
                <IconButton icon={openAllMenu ? 'caret-up' : 'caret-down'} />
              </span>
              </div>
              {openAllMenu &&
              <div style={styles.menu}>
                <Menu
                  options={[
                    {label: <div><strong>Select All </strong> chain inputs and outputs</div>, value: 'selectAll'},
                    {label: <div><strong>Unselect All </strong> chain inputs and outputs</div>, value: 'unSelectAll'}
                  ]}
                  handleSelect={val => this.allIOSelection(val)} />
              </div>
              }
            </div>
            <Attention color="dark">All Inputs and Outputs</Attention>
          </div>
          <div>
            <IconButton
              underline={true}
              icon="refresh"
              handleClick={() => resetData('all')}>
              Reset
            </IconButton>
          </div>
        </div>

        <div className="inputs" style={{marginLeft: 24}}>
          <InputGroup
            groupTitle="Chain Inputs"
            inputs={inputs}
            handleClickCell={handleClickCell}
            handleResetGroup={resetData}
            handleSelectAll={selectAll}
            handleUnSelectAll={unSelectAll}
          />
        </div>

        <div className="exports" style={{marginLeft: 24}}>
          {wfList.map(wf => {
            return (
              <OutputGroup
                key={wf.id}
                wf={wf}
                outputs={outputs.filter(o => o.nodeId === wf.id)}
                handleClickCell={handleClickCell}
                handleResetGroup={resetData}
                handleSelectAll={selectAll}
                handleUnSelectAll={unSelectAll}
              />
            );
          })
          }
        </div>
      </div>
    );
  }
}

const styles = {
  allIOContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 24px',
    marginBottom: 32,
    background: colors.shaded,
    height: 64,
    borderRadius: 5,
  },
  centerFlex: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  menuAction: {
    display: 'flex',
    border: `1px solid ${colors.light}`,
    background: colors.shaded,
    borderRadius: 4,
    marginRight: 16
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    borderLeft: `1px solid ${colors.light}`,
    padding: 4
  },
  menu: {
    position: 'absolute',
    zIndex: 1,
  },
};

/* PropTypes */
Table.propTypes = {
  inputs: PropTypes.array.isRequired,
  outputs: PropTypes.array.isRequired,
  handleClickCell: PropTypes.func,
};