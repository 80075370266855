import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

const propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  selected: PropTypes.bool,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.number
};

class InlineInput extends Component {
  focus() {
    this.input.focus();
  }
  render() {
    const { disabled, value, handleChange, placeholder, selected, inlineNoBorder, initialWidth, height, fontSize, fontWeight, onFocus, maxLength, minLength } = this.props;
    return (
      <input
        ref={ele => this.input = ele}
        type="text"
        disabled={disabled}
        style={[styles.main, selected && styles.selected, {
          fontSize,
          fontWeight,
          width: initialWidth || '100%',
          height: height || fontSize + 4
        }, inlineNoBorder && styles.inlineNoBorder]}
        value={value || ''}
        onChange={e => handleChange(e.target.value)}
        placeholder={placeholder}
        onFocus={onFocus}
        maxLength={maxLength}
        minLength={minLength}
      />
    );
  }
}

const styles = {
  main: {
    height: 'auto',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 4,
    paddingBottom: 4,
    color: '#505553',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'none',
    background: 'transparent',
    borderRadius: 0,
    transition: 'all 0.2s ease',
    cursor: 'pointer',
    lineHeight: 'initial',
    boxSizing: 'content-box',
    whiteSpace: 'word-wrap',
    ':focus': {
      outline: 'none'
    }
  },
  selected: {
    borderBottom: '1px solid rgba(0,0,0,0.8)',
    cursor: 'initial',
    ':focus': {
      borderBottom: '1px solid black',
      outline: 'none'
    }
  },
  inlineNoBorder: {
    borderBottom: 'none',
    ':focus': {
      borderBottom: 'none'
    }
  }
};

InlineInput.propTypes = propTypes;
export default Radium(InlineInput);
