import React from 'react';
import Input from '../../../../components/Input';
import { Label } from '../../../../components/type';

const Integer = ({ data, updateChainPlugin, index, readOnly }) => (
  <div style={{marginBottom: 30}}>
    {data.map((d, idx) => {
      return (
        <div key={idx}>
          <Label color="light">Value</Label>
          <div style={{ width: 100 }}>
            <Input
              type="number"
              disabled={readOnly}
              value={d.value}
              handleChange={val => {
                updateChainPlugin('typeSettings|eventOptions', val, [index, idx]);
              }}
            />
          </div>
        </div>
      );
    })}
  </div>
);

export default Integer;

