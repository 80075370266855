import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';

import { login } from '../../actions/login';

import colors from '../../styles/colors';
import { Heading, Caption } from '../../components/type';
import LoginInput from '../../components/LoginInput';
import Toast from '../../containers/Toast';
import Button from '../../components/Button';

const errorCodes = [
  { code: 401, message: 'We can\'t find that email address and password combination. For your protection,' +
  ' we will lock your account after too many unsuccessful login attempts. Passwords are case sensitive.' },
  { code: 403, message: 'We can\'t find that email address and password combination. For your protection,' +
  ' we will lock your account after too many unsuccessful login attempts. Passwords are case sensitive. Please try again in 1 minute.'}
];

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errors: '',
      submitting: false,
      emailError: '',
      passwordError: '',
    };
    this.submitLogin = this.submitLogin.bind( this );
    this.handleErrors = this.handleErrors.bind( this );
  }

  handleErrors(res) {
    if ( res.success === false ) {
      const error = errorCodes.find(errorCode => {
        return errorCode.code === res.code;
      });
      const errorMessage = !!error ? error.message : 'An error has occurred.';
      return this.setState({
        errors: errorMessage,
        submitting: false,
      });
    } else if(res.success === true && res.role === 'onespace-account') {
      window.location.href = '/manage/company';
    } else {
      window.location.reload();
    }
  }

  submitLogin(e) {
    if(!!e){
      e.preventDefault();
    }
    const { email, password } = this.state;
    let formErrors = false;
    this.setState({
      submitting: !this.state.submitting,
      emailError: '',
      passwordError: '',
      errors: '',
    });

    const emailCheck = email.match(/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/g);

    if(emailCheck === null) {
      formErrors = true;
      this.setState({
        emailError: 'Please enter a valid email address.',
        submitting: false,
      });
    } else if (password === '') {
      formErrors = true;
      this.setState({
        passwordError: 'Please enter a valid password.',
        submitting: false,
      });
    } else if(!formErrors) {
      this.props.dispatch( login( email, password ) )
        .then((res) => {
          this.handleErrors(res);
        } )
        .catch((res) => {
          this.handleErrors(res);
      });
    }
  }

  render() {
    const { email, password, errors, submitting } = this.state;

    return (
      <DocumentTitle title="Log In | OneSpace Project Center">
        <div style={{fontFamily: 'Open Sans'}}>
          <div style={styles.container}>
            <Heading color="dark"> <div style={styles.title}>Please log in to your account</div></Heading>
            <form style={styles.formGroup} onSubmit={this.submitLogin}>
              {errors.length > 0 &&
              <Toast label={errors} type="error" bold={false}/>
              }
              <div className="username">
                <LoginInput
                  handleChange={email => this.setState({email})}
                  label={'Email'}
                  value={email}
                  error={this.state.emailError}
                  invalid={this.state.emailError !== ''}
                />
              </div>
              <div className="password" style={{paddingBottom: 10}}>
                <LoginInput
                  handleChange={password => this.setState({password})}
                  label={'Password'}
                  value={password}
                  type="password"
                  error={this.state.passwordError}
                />
              </div>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div className="submit">
                  <Button
                    height={56}
                    loading={submitting}
                    disabled={submitting}
                    handleClick={this.submitLogin}>
                    {submitting ? ' Logging In' : 'Login'}
                  </Button>
                </div>
                <Caption
                  href="/forgotpassword"
                  underline={ true } >
                  Forgot your password?
                </Caption>
              </div>
              <button type="submit" style={{display: 'none'}}/>
            </form>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

const styles = {
  container: {
    border: `2px solid #ebebeb`,
    borderRadius: 5,
    maxWidth: 620,
    margin: `75 auto`,
    fontWeight: 900,
  },
  title: {
    textAlign: 'center',
    padding: `0 30`,
    marginTop: 36,
  },
  formGroup: {
    position: 'relative',
    marginRight: 70,
    marginLeft: 70,
    marginBottom: '3em',
    boxShadow: 'none',
  },
  label: {
    color: colors.dark,
    fontSize: '0.85em',
    fontWeight: 700,
    position: 'relative',
    left: 0,
    top: 50,
  },
  forgotPW: {
    color: colors.dark,
    textDecoration: 'underline',
    transition: `all 0.3s ease-out`,
    fontWeight: 700,
  },
};

function select(  ) {
  return {};
}

export default connect(select)( Login );