// Packages
import Clipboard from 'clipboard';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// Components
import Button from '../../components/Button';
import InlineButton from '../../components/InlineButton';
import InlineList from '../../components/InlineList';
import Input from '../../components/Input';
import InputCaption from '../../components/InputCaption';
import Tooltip from '../../components/Tooltip';
import { Caption, Content, Label, Title } from '../../components/type';
import RadioCheckbox from '../../components/RadioCheckbox';

// Misc
import Panel from '../../containers/Panel';
import { overview } from './Constants';

new Clipboard('.copy');

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: this.props.securityType,
      selectedWebhookOption: this.setWebhookOption(),
      webhookValue: this.props.webhooks ? this.props.webhooks.companyUrl : '',
      authEmail: '',
      authPassword: '',
      authUrl: this.props.oAuthUrl,
      emailError: '',
      passwordError: '',
      companyWebhookError: '',
      urlError: '',
      copying: false,
      accessToken: '',
      accessError: '',
      generating: false,
    };
    this.copy = this.copy.bind(this);
  }

  setWebhookOption() {
    const {webhooks} = this.props;
    if(webhooks && webhooks.hasOwnProperty('webhookEnabled')) {
      return webhooks.webhookEnabled;
    };
    return true;
  }

  copy() {
    this.setState({ copying: false });
  }

  render() {
    const {
      apiCredentials,
      generateAPIUser,
      saveSettings,
      hmacToken,
      saving,
      resetAPIPassword,
      apiUsername,
      noApiUser,
      token
    } = this.props;
    const {
      selectedOption,
      selectedWebhookOption,
      webhookValue,
      authEmail,
      authPassword,
      passwordError,
      emailError,
      companyWebhookError,
      urlError,
      authUrl,
      copying,
      accessToken,
      accessError,
      generating,
    } = this.state;

    return (
      <div>
        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 40, marginBottom: '3%' }}>
          <div style={{ width: '65%', marginRight: '3%' }}>
            <Panel
              title={overview.apiTitle}
              actions={[{ icon: 'cogs' }]}>
              {!!noApiUser ?
                <div style={{
                  display: 'flex',
                  padding: 25,
                  justifyContent: 'center',
                }}>
                  <InlineButton
                    loading={generating}
                    handleClick={() => {
                      this.setState({ generating: true });
                      generateAPIUser();
                    }}>
                    {overview.generateApi}
                  </InlineButton>
                </div>
                :
                <div style={{
                  display: 'flex',
                  padding: 25,
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                  <InlineButton
                    disabled={!!apiCredentials.password}
                    handleClick={() => resetAPIPassword()}>{overview.resetAPI}</InlineButton>
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <Label right={5}>{overview.username}</Label>
                    <Content>{apiUsername}</Content>
                  </div>
                  <div>
                    {!!apiCredentials.password &&
                      <div style={{ display: 'flex', alignItems: 'baseline' }}>
                        <Label right={5}>{overview.password}</Label>
                        <Content>{apiCredentials.password}</Content>
                      </div>
                    }
                  </div>
                </div>
              }
            </Panel>
          </div>
          <div style={{ width: '32%' }}>
            <Panel
              title={overview.documentation}
              actions={[{ icon: 'file-text' }]}>
              <div style={{ padding: `25 0 30 0` }}>
                <Title
                  left={16}
                  underline={true}
                  target="_blank"
                  href="http://developer.onespace.com/">{overview.viewDocs}</Title>
              </div>
            </Panel>
          </div>
        </div>
        <div style={{ width: '65%' }}>
          <Panel
            title={overview.webhookSettings}>
            <div style={{ margin: 16 }}>
              {!noApiUser ?
                <div>
                  <div style={{ width: '60%', marginBottom: 10 }}>
                    {overview.companyWebhookOptions.map((option, i) => (
                      <Label key={i}>
                        <RadioCheckbox
                          key={i}
                          disabled={false}
                          checked={!this.state.selectedWebhookOption}
                          id="disableWebhook"
                          value={this.state.selectedWebhookOption}
                          label={option.caption}
                          type="checkbox"
                          right={true}
                          handleChange={() => {
                            this.setState({ selectedWebhookOption: !this.state.selectedWebhookOption });
                          }}
                        />
                      </Label>
                    ))}
                    {this.state.selectedWebhookOption &&
                    <div>
                    <InlineList>
                      <Caption right={5}>{overview.defaultWebhooks}</Caption>
                      <Tooltip tooltipText={overview.defaultWebhookTooltip} right={true} />
                    </InlineList>
                    <Input 
                      disabled={!this.state.selectedWebhookOption}
                      value={webhookValue}
                      handleChange={webhookValue => this.setState({
                        webhookValue,
                        companyWebhookError: '',
                      })}
                      error={companyWebhookError} 
                    />
                    </div>
                    }
                  </div>
                  {this.state.selectedWebhookOption &&
                  <Caption italic={true} top={-10}>{overview.specificWebhooksPossible}</Caption>
                  }
                  <div style={{ marginTop: 20 }}>
                    <Caption bottom={-15} color="light">{overview.securityOptions}</Caption>
                    <InlineList justify='space-between'>
                      <div style={{ display: 'flex' }}>
                        {overview.radioOptions.map((option, i) => (
                          <Label key={i}>
                            <div style={styles.radioBtn}>
                              <Input
                                height={14}
                                type="radio"
                                value={option.value}
                                name={option.name}
                                checked={selectedOption == option.value}
                                handleChange={e => {
                                  this.setState({ selectedOption: e });
                                }} />
                            </div>
                            <span
                              style={styles.labels}
                              onClick={() => {
                                this.setState({ selectedOption: option.value.toString() });
                              }}>{option.caption}</span>
                          </Label>
                        ))}
                      </div>
                    </InlineList>
                    {selectedOption === '2' &&
                      <div style={{ width: '60%', paddingBottom: 20 }}>
                        <InputCaption
                          label={overview.url}
                          value={authUrl}
                          handleChange={authUrl => this.setState({ authUrl, urlError: '' })}
                          error={urlError} />
                        <InputCaption
                          label={overview.username}
                          value={authEmail}
                          handleChange={authEmail => this.setState({ authEmail, emailError: '' })}
                          error={emailError} />
                        <InputCaption
                          label={overview.password}
                          type='password'
                          value={authPassword}
                          handleChange={authPassword => this.setState({
                            authPassword,
                            passwordError: ''
                          })}
                          error={passwordError} />
                        <div style={{ display: 'flex' }}>
                          <Caption right={5} color="light">{overview.accessToken}</Caption>
                          <Tooltip tooltipText={overview.tokenTooltip} right={true} />
                        </div>
                        <Input
                          value={accessToken}
                          handleChange={accessToken => this.setState({
                            accessToken,
                            accessError: ''
                          })}
                          error={accessError} />
                      </div>
                    }
                    {(!!hmacToken && selectedOption === '1') &&
                      <div style={{ paddingBottom: 10 }}>
                        <div style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}>
                          <div style={{ width: '60%' }}>
                            <InputCaption label={overview.hmacTokenTitle} disabled={true}
                              value={hmacToken || token} handleChange={() => {
                              }} />
                          </div>
                          <div className='copy' data-clipboard-text={hmacToken}>
                            <InlineButton
                              loading={copying}
                              handleClick={() => {
                                this.setState({ copying: true });
                                setTimeout(this.copy, 500);
                              }}>{overview.copy}</InlineButton>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  <div style={{ display: 'flex', paddingBottom: 30 }}>
                    <Button
                      loading={saving}
                      handleClick={() => {
                        if (!!webhookValue) {
                          if (selectedOption === '2' && (!authEmail || !authPassword || !authUrl)) {
                            if (!authEmail) {
                              this.setState({ emailError: overview.emailError });
                            }
                            if (!authPassword) {
                              this.setState({ passwordError: overview.passwordError });
                            }
                            if (!authUrl) {
                              this.setState({ urlError: overview.urlError });
                            }
                            if (!accessToken) {
                              this.setState({ accessError: overview.accessError });
                            }
                          } else {
                            saveSettings(webhookValue, selectedOption, authEmail, authPassword, authUrl, accessToken, selectedWebhookOption);
                          }
                        } else {
                          if(selectedWebhookOption) {
                            this.setState({ companyWebhookError: overview.companyWebhookError });
                          } else {
                            saveSettings(webhookValue, selectedOption, authEmail, authPassword, authUrl, accessToken, selectedWebhookOption);
                          }
                        }
                      }}
                    >
                      {overview.save}
                    </Button>
                  </div>
                </div>
                :
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 150
                }}>
                  <Caption>{overview.noApiUser}</Caption>
                </div>
              }
            </div>
          </Panel>
        </div>
      </div>
    );
  }
}

const styles = {
  radioBtn: {
    flex: 1,
    width: 15,
    height: 15,
    margin: `15 10 0 0`,
  },
  labels: {
    margin: `0 20`,
    position: 'relative',
    bottom: 15,
    cursor: 'default',
  },
};

Overview.propTypes = {
  company: PropTypes.object,
  generateAPIUser: PropTypes.func,
  resetAPIPassword: PropTypes.func,
  reset: PropTypes.bool,
  companyUrl: PropTypes.string,
  apiUsername: PropTypes.string,
  apiPassword: PropTypes.string,
  saveSettings: PropTypes.func,
  hmacToken: PropTypes.string,
  submitting: PropTypes.bool,
  saving: PropTypes.bool,
};

export default Overview;