export const pageDescription = 'This page allows you to export your items from any workflow or chain, ' +
  'regardless of the batch. There are a variety of filters to choose from to customize the export ' +
  'to fit your needs.';

export const searchData = {
  name: null,
  chainId: '',
  workflowId: '',
  dates: [
    {
      dateType: '',
      start: null,
      end: null,
    },
  ],
  status: [],
  additionalOutputs: [],
};

export const resetSearchData = (data) => {
  data.name = null;
  data.chainId = '';
  data.workflowId = '';
  data.dates = [{ dateType: '', start: null, end: null}];
  data.status = [];
  return data;
};

export const statuses = [
  {
    value: 'completed',
    label: 'Completed',
    tooltipText: 'Completed items cannot be included in an export when the date type is set to ' +
    '“canceled.” If you would like to include canceled items in your export, please ' +
    'update your date type above to “created,” “released,” or “completed.”'
  },
  {
    value: 'staged',
    label: 'Staged',
    tooltipText: 'Staged items cannot be included in an export when the date type is set to ' +
    '“completed,” “released,” or “canceled.” If you would like to include staged items in your export, please ' +
    'update your date type above to “created.”'
  },
  {
    value: 'open',
    label: 'Open',
    tooltipText: 'Open items cannot be included in an export when the date type is set to ' +
    '“completed” or “canceled.” If you would like to include open items in your export, please update your date ' +
    'type above to “created” or “released.”'
  },
  {
    value: 'canceled',
    label: 'Canceled',
    tooltipText: 'Canceled items cannot be included in an export when the date type is set to ' +
    '“completed.” If you would like to include canceled items in your export, please ' +
    'update your date type above to “created,” “released,” or “canceled.”'
  },
  {
    value: 'paused',
    label: 'Paused',
    tooltipText: 'Paused items cannot be included in an export when the date type is set to ' +
    '“completed” or “canceled.” If you would like to include paused items in your export, please update your date ' +
    'type above to “created” or “released.”'
  }
];

export const headerCols = [
  {
    label: 'Timestamp',
    width: '20%',
  },
  {
    label: 'Name',
    width: '50%',
  },
  {
    label: 'Status',
    width: '10%',
  },
  {
    label: 'Rerun',
    width: '10%',
  },
  {
    label: 'Download',
    width: '10%',
  },
];

export const sortByDate = (a, b) => new Date(b.creationDate) - new Date(a.creationDate);

export const dateErrorText = 'If filtering by date, all date fields are required. Please complete ' +
  'all related fields or turn off this filter.';

export const statusErrorText = 'If filtering by status, at least one status option must be selected. ' +
  'Please select a status option or turn off this filter.';

export const legitStatuses = ['Completed', 'complete'];

export const noHistoryText = 'You have not created any exports yet.';

export const workflowOutputs = [
  {'label': 'Batch Name', 'value': 'batchName'},
  {'label': 'Workflow Batch ID', 'value': 'workflowBatchId'},
  {'label': 'Workflow Item ID', 'value': 'id'},
  {'label': 'Workflow Item Cost', 'value': 'cost'},
  {'label': 'Task Count', 'value': 'taskCount'},
  {'label': 'Rejection Count', 'value': 'rejectionCount'},
  {'label': 'Rework Count', 'value': 'reworkCount'},
  {'label': 'Item Flagged Count', 'value': 'flaggedTaskCount'},
  {'label': 'Time Per Workflow Item', 'value': 'workflowItemTime'},
  {'label': 'Time Per Task', 'value': 'taskTimeInHours'},
  {'label': 'Contributor Name', 'value': 'contributor'},
  {'label': 'Contributor ID', 'value': 'contributorId'},
  {'label': 'Feedback/Comments', 'value': 'feedback'},
];

export const chainOutputs = [
  {'label': 'Batch Name', 'value': 'batchName'},
  {'label': 'Chain Batch ID', 'value': 'batchId'},
  {'label': 'Chain Item ID', 'value': 'chainItemId'},
  {'label': 'Chain Item Cost', 'value': 'itemCost'},
  {'label': 'Task Count', 'value': 'taskCount'},
  {'label': 'Rejection Count', 'value': 'rejectionCount'},
  {'label': 'Item Flagged Count', 'value': 'flaggedTaskCount'},
  {'label': 'Time Per Chain Item', 'value': 'timePerChainItem'},
  {'label': 'Contributor Name', 'value': 'contributor'},
  {'label': 'Contributor ID', 'value': 'contributorId'},
  {'label': 'Feedback/Comments', 'value': 'feedback'},
];