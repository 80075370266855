import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';

import colors from '../../styles/colors';

const DatePickerComponent = ({
    handleDateChange,
    date,
    startDate,
    endDate,
    selectsStart,
    selectsEnd,
    placeholderText,
    disabled,
}) => {
  return(
    <div>
      <DatePicker
        onChange={handleDateChange}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        startDate={startDate}
        endDate={endDate}
        disabled={disabled && disabled}
        selected={date}
        className={css(styles.datePicker)}
        dayClassName={() => css(styles.day)}
        placeholderText={placeholderText && placeholderText}/>
    </div>
  );
};

const styles = StyleSheet.create({
  datePicker: {
    width: 100,
    height: 45,
    marginBottom: 0,
    padding: 10,
    boxSizing: 'border-box',
    border: `solid 2px ${colors.border}`,
    borderRadius: 5,
    backgroundColor: 'white',
    fontFamily: 'Open Sans',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 2,
    webkitTransition: 'border-color 0.2s ease',
    mozTransition: 'border-color 0.2s ease',
    transition: 'border-color 0.2s ease',
    ':focus': {
      borderColor: colors.dark,
      outline: 0
    }
  },
  day: {
    display: 'inline-block',
    height: 'auto',
  }
});

export default DatePickerComponent;