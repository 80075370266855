import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const MainButton = function ({label, size, inline, handleClick, disabled}) {
  return (
    <div
      className={classNames('main-button', {'-large': size === 'large', '-inline': inline, '-disabled': disabled})}
      onClick={() => !disabled && handleClick()}>
      {label}
    </div>
  );
};

MainButton.propTypes = {
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  inline: PropTypes.bool,
  handleClick: PropTypes.func.isRequired
};
MainButton.defaultProps = {
  size: 'medium',
  handleClick: () => {}
};

export default MainButton;

