import fetch, { checkStatus, parseJSON } from '../utils/fetch';
import { uploadWorkflowBatch, standardizeWorkflow } from '../actions/projects';
import {
  RECEIVED_DEFINITION,
  FETCH_ACTIVE_WORKFLOWS_REQUEST,
  FETCH_ACTIVE_WORKFLOWS_SUCCESS,
  FETCH_ACTIVE_WORKFLOWS_FAILURE,
  FETCH_WORKFLOW_REQUEST,
  FETCH_WORKFLOW_SUCCESS,
  FETCH_WORKFLOW_FAILURE,
  FETCH_WORKFLOWS_REQUEST,
  FETCH_WORKFLOWS_SUCCESS,
  FETCH_WORKFLOWS_FAILURE,
  FETCH_WORKFLOW_BATCHES_REQUEST,
  FETCH_WORKFLOW_BATCHES_SUCCESS,
  FETCH_WORKFLOW_BATCHES_FAILURE,
  EXPORT_BATCH_REQUEST,
  EXPORT_BATCH_SUCCESS,
  EXPORT_BATCH_FAILURE,
  IMPORT_WORKFLOW_BATCH_REQUEST,
  IMPORT_WORKFLOW_BATCH_SUCCESS,
  IMPORT_WORKFLOW_BATCH_FAILURE,
  RELEASE_WORKFLOW_BATCH_SUCCESS,
  CANCELED_BATCH,
  CANCEL_WORKFLOW_ITEM_REQUEST,
  CANCEL_WORKFLOW_ITEM_SUCCESS,
  CANCEL_WORKFLOW_ITEM_FAILURE,
  PAUSE_BATCH,
  RESUME_BATCH,
  ARCHIVE_WORKFLOW_REQUEST,
  ARCHIVE_WORKFLOW_SUCCESS,
  ARCHIVE_WORKFLOW_FAILURE,
  FETCH_PROJECT_WORKFLOWSSCHEMA_REQUEST,
  FETCH_PROJECT_WORKFLOWSSCHEMA_SUCCESS,
  FETCH_PROJECT_WORKFLOWSSCHEMA_FAILURE,
  FETCH_VALIDATE_WORKFLOW_NAME_REQUEST,
  FETCH_VALIDATE_WORKFLOW_NAME_SUCCESS,
  FETCH_VALIDATE_WORKFLOW_NAME_FAILURE,
  UPDATE_WORKFLOW_NAME_SUCCESS,
  UPDATE_WORKFLOW_BATCH_NOTIFICATION_REQUEST,
  UPDATE_WORKFLOW_BATCH_NOTIFICATION_SUCCESS,
  UPDATE_WORKFLOW_BATCH_NOTIFICATION_FAILURE
} from '../constants/actionTypes';

import { formatBatch } from './chains';

export const flattenWorkflow = (workflow) => {
  const flatWorkflow = Object.assign({}, workflow.workflow, workflow);
  delete flatWorkflow.workflow;
  return flatWorkflow;
};

export function fetchWorkflow( id ) {
  return {
    types: [ FETCH_WORKFLOW_REQUEST, FETCH_WORKFLOW_SUCCESS, FETCH_WORKFLOW_FAILURE ],
    payload: { id },
    fetchRoute: `/api/workflow/${id}`,
    getFormattedResponse: ( { workflow } ) => {
      return { workflow: Object.assign( {}, workflow, { id: workflow.id || workflow.workflowId || id } ) };
    }
  };
}

export function fetchWorkflows() {
  return {
    types: [ FETCH_WORKFLOWS_REQUEST, FETCH_WORKFLOWS_SUCCESS, FETCH_WORKFLOWS_FAILURE ],
    fetchRoute: '/api/workflows/full',
    getFormattedResponse: ( { workflows } ) => {
      return {
        workflows: workflows.map( flattenWorkflow ),
      };
    },
  };
}

export function fetchActiveWorkflows() {
  return {
    types: [ FETCH_ACTIVE_WORKFLOWS_REQUEST, FETCH_ACTIVE_WORKFLOWS_SUCCESS, FETCH_ACTIVE_WORKFLOWS_FAILURE ],
    fetchRoute: '/workflows',
    getFormattedResponse: ( { workflows } ) => {
      return {
        workflows: workflows.workflowResponses.map( flattenWorkflow )
      };
    },
  };
}

export function fetchProjectWorkflowsSchema( projectId ) {
  return {
    types: [ FETCH_PROJECT_WORKFLOWSSCHEMA_REQUEST, FETCH_PROJECT_WORKFLOWSSCHEMA_SUCCESS, FETCH_PROJECT_WORKFLOWSSCHEMA_FAILURE ],
    fetchRoute: `/api/projects/${projectId}/workflows/schema`,
    payload: { id: projectId },
    getFormattedResponse: ( { workflows } ) => {
      return {
        workflows: workflows.workflows.map( workflow => standardizeWorkflow( workflow, projectId ) )
      };
    },
  };
}

export function fetchDefinition( id ) {
  return ( dispatch, getState ) => new Promise( ( resolve, reject ) => {
    const definition = getState().workflowDefinitions[ getState().workflows.items[ id ].s3_filename ];
    if ( definition ) {
      return resolve();
    }
    fetch( `/api/workflow/${id}`, 'get' )
      .then( checkStatus )
      .then( parseJSON )
      .then( ( { workflow:definition } ) => {
        dispatch( {
          type: RECEIVED_DEFINITION,
          definition
        } );
        resolve( definition );
      } )
      .catch( reject );
  } );
}

export function releaseItems( id, row_count ) {
  return () => new Promise( ( resolve, reject ) => {
    fetch( `/api/workflow/${id}/release`, 'post', { row_count } )
      .then( checkStatus )
      .then( parseJSON )
      .then( res => {
        resolve( res );
      } )
      .catch( reject );
  } );
}

export function releaseWorkflowBatchItems( workflowId, batchId, row_count ) {
  return ( dispatch ) => new Promise( ( resolve, reject ) => {
    fetch( `/api/workflow/${workflowId}/batch/${batchId}/release`, 'post', { row_count } )
      .then( checkStatus )
      .then( parseJSON )
      .then( res => {
        dispatch( {
          type: RELEASE_WORKFLOW_BATCH_SUCCESS,
          batchId,
          releasedCount: Number( row_count ),
        } );
        resolve( res );
      } )
      .catch( reject );
  } );
}

/**
 * launchWorkflow
 * Attempts to launch a workflow
 */
export function launchWorkflow( id, isChain ) {
  return () => new Promise( ( resolve, reject ) => {
    const body = {};
    if ( isChain ) {
      body.chain = 1;
    }
    fetch( `/api/workflow/${id}/launch`, 'post', body )
      .then( checkStatus )
      .then( parseJSON )
      .then( res => {
        if ( !res.success ) {
          throw new Error( res.message );
        }
        resolve( res.workflow );
      } )
      .catch( res => {
        res.then( err => {
          err.workflow = id;
          reject( err );
        } );
      } );
  } );
}

export function fetchWorkflowBatches( workflowId ) {
  return {
    types: [ FETCH_WORKFLOW_BATCHES_REQUEST, FETCH_WORKFLOW_BATCHES_SUCCESS, FETCH_WORKFLOW_BATCHES_FAILURE ],
    fetchRoute: `/api/workflow/${workflowId}/batch`,
    payload: { workflowId },
    getFormattedResponse: ( batches ) => {
      return { batches: batches.batches.map( formatBatch ) };
    },
  };
}

export function exportBatch( workflowId, batchId, type, includeContributorInfo ) {
  return {
    types: [ EXPORT_BATCH_REQUEST, EXPORT_BATCH_SUCCESS, EXPORT_BATCH_FAILURE ],
    fetchRoute: `/api/workflow/${workflowId}/batch/${batchId}/export`,
    payload: { workflowId, batchId },
    fetchMethod: 'post',
    fetchBody: { type, includeContributorInfo },
    getFormattedResponse: res => res,
  };
}

export function importWorkflowBatch( projectId, workflowId, batch ) {
  return dispatch => {
    const tempId = Date.now();
    dispatch( { type: IMPORT_WORKFLOW_BATCH_REQUEST, workflowId, file: batch, tempId } );
    return dispatch( uploadWorkflowBatch( batch, workflowId, tempId ) )
      .then( ( data ) => {
        const s3_filename = data.filename;
        const directory = data.folder;
        const prefix = data.prefix;
        return dispatch( {
          types: [ IMPORT_WORKFLOW_BATCH_REQUEST, IMPORT_WORKFLOW_BATCH_SUCCESS, IMPORT_WORKFLOW_BATCH_FAILURE ],
          fetchRoute: `/api/workflow/${workflowId}/batch`,
          fetchMethod: 'post',
          fetchBody: {
            original_filename: batch.name,
            s3_filename,
            prefix,
            directory,
          },
          payload: {
            file: batch,
            projectId,
            workflowId,
            tempId,
          },
          getFormattedResponse: ( { batch } ) => {
            batch.filename = batch.original_filename;
            batch.items = { pending: batch.row_count };
            return { batch };
          }
        } );
      } ).catch( xhr => xhr );
  };
}

export function cancelWorkflowBatch( id ) {
  return ( dispatch, getState ) => new Promise( ( resolve ) => {
    const batch = getState().batches.items[ id ];
    fetch( `/api/workflow/${batch.id_workflow}/batch/${id}/cancel`, 'post' )
      .then( () => {
        resolve();
        dispatch( {
          type: CANCELED_BATCH,
          batchId: id,
        } );
      } );
  } );
}

export function cancelWorkflowItems( workflowItemIds ) {
  return {
    types: [ CANCEL_WORKFLOW_ITEM_REQUEST, CANCEL_WORKFLOW_ITEM_SUCCESS, CANCEL_WORKFLOW_ITEM_FAILURE ],
    fetchRoute: `/api/workflow-item/cancel`,
    fetchMethod: 'post',
    fetchBody: { workflowItemIds: workflowItemIds }
  };
}

export function pauseWorkflowItems( workflowId, batchId ) {
  return ( dispatch ) => new Promise( ( resolve ) => {
    fetch( `/api/workflow/${workflowId}/batch/${batchId}/pause`, 'post' )
      .then( () => {
        resolve();
        dispatch( {
          type: PAUSE_BATCH,
          batchId,
        } );
      } );
  } );
}

export function resumeWorkflowItems( workflowId, batchId ) {
  return ( dispatch ) => new Promise( ( resolve ) => {
    fetch( `/api/workflow/${workflowId}/batch/${batchId}/resume`, 'post' )
      .then( () => {
        resolve();
        dispatch( {
          type: RESUME_BATCH,
          batchId,
        } );
      } );
  } );
}

//To archive pass true, to unarchive pass false
export function archiveWorkflow( workflowId, archive ) {
  return {
    types: [ ARCHIVE_WORKFLOW_REQUEST, ARCHIVE_WORKFLOW_SUCCESS, ARCHIVE_WORKFLOW_FAILURE ],
    fetchRoute: `/api/workflow/${workflowId}/archive`,
    payload: { workflowId },
    fetchMethod: 'put',
    fetchBody: { archive },
  };
}

export function validateWorkflowName( name ) {
  return {
    types: [ FETCH_VALIDATE_WORKFLOW_NAME_REQUEST, FETCH_VALIDATE_WORKFLOW_NAME_SUCCESS, FETCH_VALIDATE_WORKFLOW_NAME_FAILURE ],
    fetchRoute: '/api/workflow/validate-name',
    fetchMethod: 'post',
    fetchBody: { name: [ name ] },
  };
}

export function updateWorkflowName( name, id ) {
  return ( dispatch ) => new Promise( ( resolve ) => {
    fetch( '/api/workflow-names', 'put', { workflows: [ { name, id } ] } )
      .then( () => {
        resolve();
        dispatch( {
          type: UPDATE_WORKFLOW_NAME_SUCCESS,
          workflowId: id,
          name
        } );
      } );
  } );
}

export function workflowBatchNotification( workflowId, batchNotification ) {
  return {
    types: [ UPDATE_WORKFLOW_BATCH_NOTIFICATION_REQUEST, UPDATE_WORKFLOW_BATCH_NOTIFICATION_SUCCESS, UPDATE_WORKFLOW_BATCH_NOTIFICATION_FAILURE ],
    fetchRoute: `/api/workflow/${workflowId}/batch-notification`,
    fetchMethod: 'put',
    fetchBody: { batchNotification },
    payload: { workflowId, batchNotification }
  };
}
