import React from 'react';
import PropTypes from 'prop-types';
import { Caption } from './type';
import Tooltip from './Tooltip';

const RadioCheckbox = (props) => (
    <div>
      {(props.disabled && props.tooltip && props.tooltipText) ?
        <div style={{display: 'inline-block', marginRight: 3}}>
          <Tooltip
            tooltipText={props.tooltipText}
            right={props.right}
            left={props.left}
          />
        </div> : <input
          style={styles.inputStyle}
          checked={props.checked}
          type={props.type ? props.type : 'radio'}
          id={props.id}
          name={props.name}
          value={props.value}
          onChange={e => {
            !props.disabled && props.handleChange(e.target.value);
          }}
          disabled={props.disabled && props.disabled}
        />
      }
      <label style={styles.labelStyle(props.disabled)}
             htmlFor={props.id}><Caption>{props.label}</Caption></label>
    </div>
);

/* Styles */
const styles = {
  inputStyle: {
    marginRight: 5,
    position: 'relative',
    top: 2,
  },
  labelStyle: (disabled) => ({
    marginBottom: 0,
    cursor: 'default',
    opacity: disabled ? 0.5 : 1,
  }),
};

/* PropTypes */
RadioCheckbox.propTypes = {
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['radio', 'checkbox']),
  name: PropTypes.string, // Input name attribute
  checked: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default RadioCheckbox;
