import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css} from 'aphrodite';

import SelectLabel from '../../components/SelectLabel';
import Button from '../../components/Button';
import Panel from '../../containers/Panel';
import { fetchCompaniesList, fetchCompanyLedger, loginAsCompany } from '../../actions/company';
import objectToArray from '../../utils/_objectToArray';
import LoadState from '../../containers/LoadState';
import Wrapper from '../../containers/Wrapper';
import APICredentials from './APICredentials';
import { Heading, Title, Regular, Subheading } from '../../components/type';

class CompanySupport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCompanyId: '',
      selectedCompany: {}
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.accessAccountAsAdmin = this.accessAccountAsAdmin.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(fetchCompaniesList());
  }

  /* Handle DropDown Select */
  handleSelect(id) {
    let selectedCompany = this.props.companyList.find(company => company.companyId == id);
    this.setState({selectedCompanyId: id, selectedCompany});
    this.props.dispatch(fetchCompanyLedger(id));
  }

  /* Handle Access Account Button */
  accessAccountAsAdmin() {
    localStorage.clear();
    this.props.dispatch(loginAsCompany(this.state.selectedCompanyId))
      .then(res => {
        if(res.success) {
          window.location.href = '/dashboard';
        }
      });
  }

  render() {
    const {selectedCompanyId, selectedCompany} = this.state;
    const companyList = this.props.companyList.map(ele => {
      return {
        label: !!ele.name ? ele.name : '',
        value: ele.companyId,
      };
    });
    const {ledger, isFetchingLedger, hasFetchedLedger} = this.props;

    return(
      <div style={{paddingTop: 64}}>
        <Wrapper>
          <Heading color="dark" bottom={20}>Welcome to Client Management</Heading>
          <div style={{width: 500}}>
            <SelectLabel
              value={selectedCompanyId}
              label="Select a client to get started"
              options={!!companyList ? companyList : []}
              handleSelect={id => this.handleSelect(id)}
              searchable={true} />
          </div>
          {(!!this.state.selectedCompanyId && hasFetchedLedger ) ?
            (
              <div>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Subheading color="dark">{selectedCompany.name}</Subheading>
                  <Button handleClick={this.accessAccountAsAdmin}>Access Account</Button>
                </div>
                < hr />
                <Panel title="Current Plan" color="light">
                  <div style={{padding: 16}}>
                    <ul className={css(styles.unorderedList)}>
                      <li className={css(styles.listItem)} >
                        <Title color="light">Administrator Seats</Title>
                        <Title color="dark">{selectedCompany.adminSeats}</Title>
                      </li>
                      <li className={css(styles.listItem)}>
                        <Title color="light">Private Contributor Seats</Title>
                        <Title color="dark">{selectedCompany.contributorSeats}</Title>
                      </li>
                      <li className={css(styles.listItem)}>
                        <Title color="light">Transaction Fee</Title>
                        <Title color="dark">{ledger.transactionFeeRate * 100}%</Title>
                      </li>
                      <li className={css(styles.listItem)}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                          <Title color="light">Available Balance</Title>
                          <Regular italic={true}>(includes pending transactions)</Regular>
                        </div>
                        <Title color="dark">$ {ledger.usableBalance}</Title>
                      </li>
                      <li  className={css(styles.listItem)}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                          <Title color="light">Current Balance</Title>
                          <Regular italic={true}>(actual amount in account)</Regular>
                        </div>
                        <Title color="dark">$ {ledger.balance}</Title>
                      </li>
                      <li style={{padding: '2em 0 1em'}}>
                        <APICredentials companyId={selectedCompanyId} swfId={ledger.companyId}/>
                      </li>
                    </ul>
                  </div>
                </Panel>
              </div>
            ) : (isFetchingLedger &&
            <LoadState label="Loading Company Support" />
          )}
        </Wrapper>
      </div>
    );
  }
}

/* Styles */
const styles = StyleSheet.create({
  listItem: {
    borderBottom: '1px solid #ebebeb',
    padding: '2em 0 1em',
    display: 'flex',
    justifyContent: 'space-between'
  },
  unorderedList: {
    backgroundColor: '#f4f5f5',
    listStyle: 'none',
    marginBottom: 0,
    padding: '0px 16px'
  }
});

/* Map State to Props */
function select(state) {
  const companyList = objectToArray(state.companies.items);
  const ledger = state.ledger;
  return {
    companyList,
    ledger: objectToArray(ledger.items)[0],
    isFetchingLedger: ledger.isFetching,
    hasFetchedLedger: ledger.hasFetched,
  };
}

export default connect(select)(CompanySupport);