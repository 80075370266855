import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HTML5Backend from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-dnd';
import update from 'immutability-helper';

import ElementSelector from './ElementSelector';
import Editor from './Editor';
import IO from './IO';
import { newElementAt, moveElementTo, updateTaskUIElement, deleteElement, makeRepeatable } from './actions/elements';
import { addLibraryElement, deleteLibraryElement, fetchLibraryElements, updateLibraryElement, elementChanged } from './actions/libraryElements';

import { selectElement, selectInput } from './actions/editor';
import { addInput } from './actions/io';
import { nonselfdroppablelayoutTypes } from './constants';

import SaveToLibraryModal from './modals/SaveToLibrary';
import MakeRepeatableModal from './modals/MakeRepeatable';
import Note from '../../../../containers/Note';

class TaskUI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      openRepeatableModal: false,
      libraryElement: {},
      allProjects: true,
      libraryElementSaving: false,
      repeatableElement: {},
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchLibraryElements());
  }

  componentDidUpdate(prevProps) {
    let outputElements = this.props.elements.find(element => {
      return !!element.data.output;
    });
    const errorWrapper = $('.consensusError');
    if(!!outputElements && this.props.consensusEnabled) {
      errorWrapper.html( '<div>Collect elements are not supported when you have Consensus Quality Control ' +
        'enabled. Please remove the collect element from your canvas.</div>' );
      errorWrapper.show();
    } else if(outputElements == null && this.props.consensusEnabled) {
      errorWrapper.hide();
    }
    const isDifferentLength = this.props.elements.length !== prevProps.elements.length;
    const isDifferentElements = this.props.elements.find((element, i) => element !== prevProps.elements[i]);
    if (isDifferentLength || isDifferentElements) {
      const elementArray = Object.keys(this.props.elements).map(index => this.props.elements[index]);
      this.props.handleChangeElements(elementArray);
    }
  }

  layoutIsEmpty() {
    const confirmed = confirm('Are you sure you want to delete this layout? Doing so ' +
      'will delete all of the elements inside of the layout. To avoid this, cancel this ' +
      'and move the items out of the layout before deleting.');
    return confirmed ? confirmed : false;
  }

  render() {
    const {
      inputs,
      outputs,
      elements,
      libraryElements,
      selected,
      selectedChild,
      input,
      inputIndex,
      dispatch,
      selectedColumn,
      assignmentName,
      isSupportRole,
      elementChange,
    } = this.props;
    const { repeatableElement, openRepeatableModal } = this.state;
    return (
      <div style={styles.main}>
        {(elementChange === 'updated' || elementChange === 'saved') &&
          <div style={{ top: 0, position: 'absolute', zIndex: 10, width: '100%' }} >
            <Note
              align="center"
              note={
                elementChange === 'updated' ?
                'Your element has been updated.' :
                'Your element has been saved to your library.'
              }
              type="notification" />
          </div>
        }
        <ElementSelector
          libraryElements={libraryElements}
          quickAdd={(type, element) => {
            dispatch(newElementAt(elements.length, type, null, element));}
          }
          deleteLibraryElement={libraryElementId => dispatch(deleteLibraryElement(libraryElementId))}
        />
        <div style={styles.body}>
          <Editor
            padding={40}
            borderRight="1px solid rgba(0, 0, 0, 0.1)"
            selected={selected}
            selectedChild={selectedChild}
            elements={elements}
            inputs={inputs}
            previousOutputs={outputs}
            handleNewElement={(type, i, targetIndex, element) => {
              return dispatch(newElementAt(i, type, targetIndex, element));
            }}
            handleMoveElement={(from, to) => dispatch(moveElementTo(to, from))}
            handleChange={(index, change) => dispatch(updateTaskUIElement(index, change))}
            handleAddToLibrary={(element) => {
              this.setState({ openModal: true, libraryElement: element });
            }}
            handleMakeRepeatable={(element) => {
              this.setState({ openRepeatableModal: true, repeatableElement: element });
            }}
            handleUpdateElement={(element) => {
              return dispatch(updateLibraryElement(element, element.customId)).then(() => {
                dispatch(elementChanged('updated'));
                setTimeout(() => {
                  dispatch(elementChanged(''));
                }, 1500);
              });
            }}
            handleDeleteElement={(index, element) => {
              /* if an element is a layout, check to see if empty before deletion */
              let elementsArray = [];
              if(element.data.columns && Array.isArray(element.data.columns)) {
                for(let i=0; i < element.data.columns.length; i++) {
                  elementsArray.push(element.data.columns[i].children.length);
                }
              }
              const isItEmpty = element.data.columns ? elementsArray.some(el => el > 0) : false;
              if (nonselfdroppablelayoutTypes.includes(element.type) && isItEmpty) {
                if(this.layoutIsEmpty()) {
                  return dispatch(deleteElement(index));
                }
                return false;
              }
              return dispatch(deleteElement(index));
            }}
            handleSelectElement={index => {
              dispatch(selectElement(index));
            }}
            handleSelectInput={(input, inputIndex) => dispatch(selectInput(input, inputIndex))}
            dispatch={dispatch}
            selectedColumn={selectedColumn}
            inputIndex={inputIndex}
            isSupportRole={isSupportRole}
          />
          <IO
            assignmentName={assignmentName}
            selectedElement={elements[selected]}
            inputs={inputs}
            previousOutputs={outputs}
            elements={elements}
            selectedInput={input}
            handleAddLabel={label => {
              this.props.handleAddInput(label);
              dispatch(addInput(label));
            }}
            handleClick={label => {
              if(input) {

                const selectedElement = Number.isInteger(selectedChild) ?
                  [ selectedChild, selectedColumn, selected] :
                  selected;

                let current = (Array.isArray(selectedElement)) ?
                  elements[selectedElement[2]].data.columns[selectedElement[1]].children[selectedElement[0]].data[input] :
                  elements[selectedElement].data[input];
                
                label = label.replace(/\[[0-9]+(-[0-9]+)]/, '#{count}');
                const key = `\${${label}}`;

                if(Number.isInteger(inputIndex)) {
                  current = (Array.isArray(selectedElement)) ?
                    elements[selectedElement[2]].data.columns[selectedElement[1]].children[selectedElement[0]].data.options[inputIndex][input] :
                    elements[selectedElement].data.options[inputIndex][input];
                  return dispatch(
                    updateTaskUIElement(
                      selectedElement, {
                        options: update(
                          (Array.isArray(selectedElement)) ?
                            elements[selectedElement[2]].data.columns[selectedElement[1]].children[selectedElement[0]].data.options :
                            elements[selectedElement].data.options,
                          {[inputIndex]: {$merge: {[input]: current ? current + ` ${key}` : key}}}
                        )
                      }
                    )
                  );
                }
                dispatch(updateTaskUIElement(selectedElement, {[input]: current ? current + ` ${key}` : key}));
              }
            }}
          />
        </div>
        <SaveToLibraryModal
          isOpen={this.state.openModal}
          libraryElement={this.state.libraryElement}
          allProjects={this.state.allProjects}
          allProjectsSelected={allProjectsSelected => {
            this.setState({ allProjects: allProjectsSelected });
          }}
          saveElement={element => {
            this.setState({ libraryElementSaving: true });
            dispatch(addLibraryElement(element, this.state.allProjects)).then(() => {
              dispatch(elementChanged('saved'));
              this.setState({
                openModal: false,
                libraryElementSaving: false,
              });
              setTimeout(() => {
                dispatch(elementChanged(''));
              }, 1500);
            });
          }}
          getName={name => {
            const newElement = this.state.libraryElement;
            let newerElement = Object.assign({}, newElement, { name });
            this.setState({ libraryElement: newerElement });
          }}
          closeIt={() => this.setState({ openModal: false })}
          savingElement={this.state.libraryElementSaving}
        />
        <MakeRepeatableModal
          isOpen={openRepeatableModal}
          numberOfDuplicates={repeatableElement.numberOfDuplicates || '1'}
          startRange={repeatableElement.startRange || '1'}
          setDuplicate={number => {
            this.setState({ 
              repeatableElement: { 
                ...repeatableElement, 
                numberOfDuplicates: number,
              }
            });
          }}
          setRange={number => {
            this.setState({ 
              repeatableElement: { 
                ...repeatableElement, 
                startRange: number,
              }
            });
          }}
          closeIt={() => this.setState({ openRepeatableModal: false })}
          saveDuplicate={() => {
            let layoutIndex = elements.findIndex(e=>{
              return e.tempId === repeatableElement.tempId;
            });
            dispatch(
              makeRepeatable(
                layoutIndex, 
                repeatableElement.numberOfDuplicates, 
                repeatableElement.startRange || 1,
              )
            );
            this.setState({ 
              openRepeatableModal: false,
            });
          }}
        />
      </div>
    );
  }
}

const propTypes = {
  // Array of workflow inputs
  inputs: PropTypes.arrayOf(PropTypes.string),
  // Called when Elements has been updated
  handleChangeElements: PropTypes.func,
  handleAddInput: PropTypes.func
};

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    margin: 'auto',
    boxShadow: '0 2px 8px rgba(0,0,0,0.42)',
    zIndex: 10000,
  },
  body: {
    display: 'flex',
    height: '90%',
    flex: 'auto',
  },
};

TaskUI.propTypes = propTypes;
TaskUI.defaultProps = {
  onChangeElement: () => {
  },
  handleAddInput: () => {
  }
};

function select(state) {  
  return {
    selectedColumn: state.editor.selectedColumn,
    selectedChild: state.editor.selectedChild,
    selected: state.editor.selected,
    input: state.editor.input,
    inputIndex: state.editor.inputIndex,
    elements: Object.keys(state.elements).map(order => state.elements[order]),
    inputs: state.inputOutputs.inputs,
    outputs: state.inputOutputs.outputs,
    libraryElements: Object.keys(state.libraryElements.items).map(order => state.libraryElements.items[order]),
    elementChange: state.libraryElements.elementChanged,
  };
}
const connected = connect(select)(TaskUI);
export default DragDropContext(HTML5Backend)(connected);
