import React, { Component } from 'react';
import { connect } from 'react-redux';

import { workflowBatchNotification } from '../../actions/workflows';
import { chainBatchNotification } from '../../actions/chains';

import { Heading2, Regular, Label, Caption } from '../../components/type';
import Toggle from '../../components/Toggle';
import LoadState from '../../containers/LoadState';


const toggleText = notificationStatus => {
  return `${notificationStatus ? 'Deactivate' : 'Activate'} Batch Notifications`;
};
const contentText = (notificationStatus, isChain) => {
  return notificationStatus ?
    'Deactivate these notifications if you no longer wish to receive an email each time a batch completes for this ' +
    `${isChain ? 'chain' : 'workflow'}. You can turn these notifications back on at any time.` :
    'Activate these notifications to receive an email each time a batch completes for this ' +
    `${isChain ? 'chain' : 'workflow'}. You can turn these notifications off at any time.`;
};

class BatchNotifications extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      toggleState: props.batchNotificationStatus,
      error: '',
    };

    this.handleToggle = this.handleToggle.bind( this );
  }

  handleToggle() {
    const { workflowId, chainId } = this.props;
    const { toggleState } = this.state;
    if ( workflowId ) {
      this.props.dispatch( workflowBatchNotification( workflowId, !toggleState ) ).then( res => {
        if ( res.success ) {
          this.setState( { toggleState: !toggleState } );
        } else {
          this.setState( { error: `Batch Notifications could not be turned ${!toggleState ? 'On' : 'Off'}` } );
        }
      } );
    } else {
      this.props.dispatch( chainBatchNotification( chainId, !toggleState ) ).then( res => {
        if ( res.success ) {
          this.setState( { toggleState: !toggleState } );
        } else {
          this.setState( { error: `Batch Notifications could not be turned ${!toggleState ? 'On' : 'Off'}` } );
        }
      } );
    }
  }

  render() {
    const { isUpdating, batchNotificationStatus, chainId } = this.props;
    const { error } = this.state;

    return (
      <div style={styles.container}>
        <Heading2 color='dark'>
          {toggleText(batchNotificationStatus)}
        </Heading2>
        <Regular color='dark' center={true} top={16} bottom={16}>
          {contentText(batchNotificationStatus, !!chainId)}
        </Regular>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {isUpdating ?
            <LoadState fontSize={24} minHeight={0}/>
            :
            <Toggle toggle={batchNotificationStatus} handleToggle={this.handleToggle}/>
          }
          <Label color='dark' italic={true} left={12}>
            {toggleText(batchNotificationStatus)}
          </Label>
        </div>
        {!!error &&
        <Caption color='negative' left={114}>
          {error}
        </Caption>
        }
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0 155px',
    margin: '36px 0px'
  }
};

function select( state ) {
  const workflowId = state.app.modalMeta.workflowId || null;
  const chainId = state.app.modalMeta.chainId || null;
  const workflow = workflowId && state.workflows.items[ workflowId ];
  const chain = chainId && state.chains.items[ chainId ];
  return {
    workflowId,
    chainId,
    isUpdating: workflowId ? workflow.isUpdating : chain.isUpdating,
    batchNotificationStatus: workflowId ? workflow.batchNotification : chain.batchNotification,
  };
}

export default connect( select )( BatchNotifications );