export const ADD_FUNDS = 'ADD_FUNDS';
export const CANCEL_ITEMS = 'CANCEL_ITEMS';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const EXPORT_CHAIN_BATCH = 'EXPORT_CHAIN_BATCH';
export const RELEASE_ITEMS = 'RELEASE_ITEMS';
export const EXPORT_WORKFLOW_BATCH = 'EXPORT_WORKFLOW_BATCH';
export const CONTROL_MODAL_OVERLAY_CLICK = 'CONTROL_MODAL_OVERLAY_CLICK';
export const RESET_API_PASSWORD = 'RESET_API_PASSWORD';
export const RELEASE_NOTIFICATION_MODAL = 'RELEASE_NOTIFICATION_MODAL';
export const PAUSE_ITEMS = 'PAUSE_ITEMS';
export const RESUME_ITEMS = 'RESUME_ITEMS';
export const UNARCHIVE_WORKFLOW = 'UNARCHIVE_WORKFLOW';
export const ARCHIVE_CHAIN = 'ARCHIVE_CHAIN';
export const UNARCHIVE_CHAIN = 'UNARCHIVE_CHAIN';
export const ARCHIVE_WORKFLOW = 'ARCHIVE_WORKFLOW';
export const BATCH_NOTIFICATIONS = 'BATCH_NOTIFICATIONS';
