import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import colors from '../styles/colors';
import { Label } from './type';

const Input = (props) => (
  <div>
    <input
      id={props.id}
      checked={props.checked}
      className={css(styles.input, props.invalid && styles.invalid)}
      name={props.name}
      type={props.type}
      value={props.value}
      height={props.height ? props.height : 'auto'}
      min={props.min}
      max={props.max}
      placeholder={props.placeholder}
      disabled={props.disabled}
      width={props.width ? props.width : 'auto'}
      onChange={e => {
        !props.disabled && props.handleChange(e.target.value);
      }}
      style={{ height: props.height ? props.height : 45, width: props.width ? props.width : '100%'}}
      onKeyDown={e => {
        if(props.unallowedCharacters && props.unallowedCharacters.includes(e.keyCode)) {
          e.preventDefault();
        }
      }}
      ref={input => props.handleGetInput && props.handleGetInput(input)}
    />
    {props.error && <Label italic={true} color="negative">{props.error}</Label>}
  </div>
);

/* Styles */
const styles = StyleSheet.create({
  input: {
    marginBottom: 0, // TODO(pr) This is an override of old css
    padding: 10,
    boxSizing: 'border-box',
    border: `solid 2px ${colors.divider}`,
    borderRadius: 5,
    backgroundColor: 'white',
    fontFamily: 'Open Sans',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 2,
    transition: 'border-color 0.2s ease',
    ':focus': {
      borderColor: colors.dark,
      outline: 0
    }
  },
  invalid: {
    border: `2px solid ${colors.negative}`,
  },
});

/* PropTypes */
Input.propTypes = {
  name: PropTypes.string, // Input name attribute
  type: PropTypes.oneOf(['text', 'password', 'number', 'email', 'radio', 'checkbox']),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  checked: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleGetInput: PropTypes.func, // This component will pass itself to this function on mount
};

export default Input;
