import React from 'react';
import PropTypes from 'prop-types';
import colors from '../styles/colors';
import InlineList from './InlineList';
import Toggle from './Toggle';
import { MediumLabel } from './type';

const ToggleDropper = ({ handleToggle, toggle, children, label, disabled }) => (
  <div style={styles.mainWrapper}>
    <div style={styles.innerWrapper(toggle)}>
      <InlineList spacing={10}>
        <Toggle
          handleToggle={() => handleToggle(!toggle)}
          toggle={toggle}
          disabled={disabled && disabled}
        />
        {label && <div style={styles.labelWrap(disabled)}><MediumLabel uppercase>{label}</MediumLabel></div>}
      </InlineList>
        <div style={!toggle ? styles.childWrapper : Object.assign({}, styles.childWrapper, styles.revealed)}>
          {toggle && children}
        </div>
    </div>
  </div>
);

const styles = {
  mainWrapper: {
    padding: '4px 0',
    borderTop: `2px solid ${colors.border}`,
    borderBottom: `2px solid ${colors.border}`,
    marginBottom: -2,
  },
  innerWrapper: (toggle) => ({
    display: 'block',
    padding: '25px 20px',
    backgroundColor: !toggle ? colors.shaded : 'white',
  }),
  childWrapper: {
    marginLeft: 47,
    height: 0,
    maxHeight: 0,
    transition: 'max-height 0.15s ease-in',
  },

  revealed: {
    height: 'auto',
    maxHeight: 100,
    marginTop: 10,
    transition: 'max-height 0.15s ease-in',
  },
  labelWrap: (disabled) => ({
    opacity: disabled ? 0.5 : 1,
  }),
};

/* PropTypes */
ToggleDropper.propTypes = {
  handleToggle: PropTypes.func,
  toggle: PropTypes.bool,
  children: PropTypes.node, // Button label
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ToggleDropper;