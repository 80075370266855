import React, { Component } from 'react';
import PropTypes from 'prop-types';
/**
 * Tabs
 */
const Pane = (props) => {
  return <div style={{
    border: `${props.contentBorderColor ? `1px solid ${props.contentBorderColor}` : 0}`,
    padding: `${props.contentPadding ? `${props.contentPadding}` : 0}`
  }}>{props.children}</div>;
};

class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: this.props.selected };
  }

  renderTitles() {
    const {
      activeTabColor,
      activeTabBgColor,
      tabBgColor,
      tabLabelsBgColor,
      borderColor,
      verticalPadding,
      horizontalPadding,
      bold,
      tabBottomBorder,
      horizontalSpacing,
    } = this.props;
    function labels(child, idx) {
      let isActiveTab = this.state.selected === idx;
      return (
        <li style={styles.tabLabel} role="tab" key={idx} aria-controls={`panel${idx}`}>
          <div
            style={{
              color: (activeTabColor && isActiveTab) ? activeTabColor : 'initial',
              backgroundColor: isActiveTab ? activeTabBgColor : tabBgColor,
              position: 'relative',
              top: '1px',
              border: `1px solid ${borderColor}`,
              borderBottom: `${(tabBottomBorder && !isActiveTab) ? `1px solid ${tabBottomBorder}` : `1px solid ${activeTabBgColor}`}`,
              marginRight: `${horizontalSpacing ? horizontalSpacing : 2}`,
              padding: `${verticalPadding ? verticalPadding : 0}px ${horizontalPadding ? horizontalPadding : 0}px`,
              fontWeight: bold ? '800' : 'initial',
            }}
            onClick={this.onClick.bind(this, idx)}
          >
            {child.props.label}
          </div>
        </li>
      );
    }

    return (
      <ul style={{ display: 'flex', backgroundColor: tabLabelsBgColor, paddingLeft: 0, marginBottom: 0, listStyleType: 'none' }} role="tablist">
        {this.props.children.map(labels.bind(this))}
      </ul>
    );
  }

  onClick(index, event) {
    event.preventDefault();
    this.setState({
      selected: index
    });
  }

  render() {
    return (
      <div>
        {this.renderTitles()}
        <div>
          {this.props.children[this.state.selected]}
        </div>
      </div>
    );
  }
}

const styles = {
  tabLabel: {
    fontFamily: 'Open Sans',
    cursor: 'pointer',
    position: 'relative',
  },
};

Pane.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  activeTabBgColor: PropTypes.string,
  tabBgColor: PropTypes.string,
  tabLabelsBgColor: PropTypes.string,
  borderColor: PropTypes.string,
  verticalPadding: PropTypes.number,
  horizontalPadding: PropTypes.number,
  bold: PropTypes.bool,
  tabBottomBorder: PropTypes.number,
  contentBorderColor: PropTypes.string,
  contentPadding: PropTypes.number,
  horizontalSpacing: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
};

export default Tabs;
export { Pane };
