import React, { Component } from 'react';
import { connect } from 'react-redux';

import DocumentTitle from 'react-document-title';
import objectToArray from '../../utils/_objectToArray';
import TabSection from '../../containers/TabSection';
import Wrapper from '../../containers/Wrapper';
import LoadState from '../../containers/LoadState';
import { Heading } from '../../components/type';

import Overview from './Overview';
import IdLookup from './IdLookup';
import { apiAdminIndex } from './Constants';

import {
  createAPICredentials,
  defaultWebhook,
  fetchCompanyWebhooks,
  workflowOrChainWebhook,
  fetchCompany,
  setSecurity,
  fetchApiUser
} from '../../actions/company';
import { RESET_API_PASSWORD } from '../../constants/modalTypes';
import { toggleModal } from '../../actions/app';
import HelpPanel from '../../containers/HelpPanel';

class ApiAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reset: false,
      updating: false,
      saving: false,
      isWebhook: false,
      apiUserLoaded: false,
      webhooksLoaded: false,
      noApiUser: false,
      apiUsername: ''
    };
    this.tabView = this.tabView.bind(this);
    this.updateWorkflowOrChainWebhook = this.updateWorkflowOrChainWebhook.bind(this);
    this.resetAPIPassword = this.resetAPIPassword.bind(this);
    this.generateAPIUser = this.generateAPIUser.bind(this);
    this.saveSettings = this.saveSettings.bind(this);
  }

  componentDidMount(){
    const { dispatch } = this.props;
    dispatch(fetchCompany())
      .then(res => {
        dispatch(fetchCompanyWebhooks(res.company.id_swf))
          .then(() => this.setState({webhooksLoaded: true}));
        dispatch(fetchApiUser(res.company.id))
          .then((res) => {
          this.setState({
            noApiUser: !res.username,
            apiUsername: res.username,
            apiUserLoaded: true,
          });
        });
      });
  }

  tabView(input) {
    switch (input) {
      case 'overview':
        return <Overview
          company={this.props.company}
          generateAPIUser={this.generateAPIUser}
          resetAPIPassword={this.resetAPIPassword}
          reset={this.state.reset}
          webhooks={this.props.webhooks}
          apiCredentials={this.props.apiCredentials}
          saveSettings={this.saveSettings}
          hmacToken={this.props.hmacToken}
          submitting={this.state.updating}
          saving={this.state.saving}
          securityType={this.props.securityType || '0'}
          oAuthUrl={this.props.oAuthUrl}
          token={this.props.token}
          noApiUser={this.state.noApiUser}
          apiUsername={this.state.apiUsername}
          />;
      case 'guid':
        return <IdLookup
          chainWebhooks={this.props.webhooks ? objectToArray(this.props.webhooks.chains) : []}
          workflowWebhooks={this.props.webhooks ? objectToArray(this.props.webhooks.workflows) : []}
          updateWorkflowOrChainWebhook={this.updateWorkflowOrChainWebhook}
          updating={this.state.updating}
          noApiUser={this.state.noApiUser}
        />;
      default:
        break;
    }
  }

  updateWorkflowOrChainWebhook(workflowOrChainId, webhook, chain, webhookEnabled) {
    this.setState({updating: true});

    this.props.dispatch(workflowOrChainWebhook(this.props.company.id_swf, workflowOrChainId, webhook, chain, webhookEnabled))
      .then(() => {
        this.setState({updating: false});
    });
  }

  generateAPIUser() {
    const {company} = this.props;
    this.props.dispatch(createAPICredentials(company.id_swf))
      .then((res) => {
      if(!!res.username){
        this.setState({
          noApiUser: false,
          apiUsername: res.username,
        });
        this.props.dispatch(fetchCompanyWebhooks(company.id_swf));
      }
      });
  }

  resetAPIPassword() {
    const workerId = this.props.apiUser.workerId;
    this.props.dispatch(toggleModal(RESET_API_PASSWORD, {workerId}));
  }

  saveSettings(webhook, securityOption, authEmail, authPassword, authUrl, accessToken, webhookEnabled) {
    this.setState({saving: true});
    const {company} = this.props;
    this.props.dispatch(defaultWebhook(company.id_swf, webhook, webhookEnabled));
    this.props.dispatch(setSecurity(company.id_swf, securityOption, authEmail, authPassword, authUrl, accessToken))
      .then(() => {this.setState({saving: false});});
  }

  render() {
    return(
      <DocumentTitle title={apiAdminIndex.docTitle}>
        <div className="apiAdmin">
          <HelpPanel
            pageTitle={apiAdminIndex.pageTitle}
            pageDescription={apiAdminIndex.pageDescription}
            links={apiAdminIndex.helpPanelLinks}
          />
          <Wrapper>
            {/* Page Title */}
            <Heading color="dark">{apiAdminIndex.pageTitle}</Heading>

            {/* Tabs */}
            {(!this.state.apiUserLoaded || !this.state.webhooksLoaded) ?
              <LoadState/>
              :
              <TabSection
                tabLabels={[
                  'Overview',
                  'ID Lookup',
                ]}
                tabClasses={[
                  'overview-tab',
                  'guid-tab',
                ]}
                tabContent={[
                  this.tabView('overview'),
                  this.tabView('guid'),
                ]}
              />
            }
          </Wrapper>
        </div>
      </DocumentTitle>
    );
  }
}

function select(state) {
  return {
  company: state.app.company,
  apiCredentials: state.apiCredentials,
  hmacToken: state.companies.token,
  apiUser: state.companies.apiUser,
  token: state.companies.token,
  webhooks: state.companies.webhooks,
  securityType: state.companies.securityType,
  oAuthUrl: state.companies.url,
  };
}

export default connect(select) (ApiAdmin);