import fetch, { checkStatus, parseJSON } from '../utils/fetch';

import {
  FETCH_EXPORTHISTORY_REQUEST,
  FETCH_EXPORTHISTORY_SUCCESS,
  FETCH_EXPORTHISTORY_FAILURE,
} from '../constants/actionTypes';

export function createExport(exportData) {
  const data = Object.assign({}, exportData);
  return () => new Promise((resolve, reject) => {
    fetch(`/api/advanced-export`, 'POST', data)
      .then(checkStatus)
      .then(parseJSON)
      .then(res => {
        resolve(res);
      })
      .catch(res => {
        res.then(err => {
          reject(err);
        });
      });
  });
}

export function fetchExportHistory() {
  return {
    types: [FETCH_EXPORTHISTORY_REQUEST, FETCH_EXPORTHISTORY_SUCCESS, FETCH_EXPORTHISTORY_FAILURE],
    fetchRoute: '/api/advanced-export/history',
    getFormattedResponse: (history) => {
      return {
        history: history,
      };
    },
  };
}