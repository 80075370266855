import { updateState } from '../utils/reducerHelpers';
import {
  FETCH_PROJECTS_REQUEST,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_FAILURE,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAILURE,
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
  FETCH_PROJECT_WORKFLOWS_REQUEST,
  FETCH_PROJECT_WORKFLOWS_SUCCESS,
  FETCH_PROJECT_WORKFLOWS_FAILURE,
  FETCH_PROJECT_CHAINS_REQUEST,
  FETCH_PROJECT_CHAINS_SUCCESS,
  FETCH_PROJECT_CHAINS_FAILURE,
  FETCH_PROJECT_CHAINS_AND_WORKFLOWS_REQUEST,
  FETCH_PROJECT_CHAINS_AND_WORKFLOWS_SUCCESS,
  FETCH_PROJECT_CHAINS_AND_WORKFLOWS_FAILURE,
} from '../constants/actionTypes';

const initialState = {
  isFetching: false,
  hasFetched: false,
  items: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PROJECTS_REQUEST:
      return updateState.fetchRequest(state);
    case FETCH_PROJECTS_SUCCESS:
      return updateState.fetchSuccess(state, action.projects);
    case FETCH_PROJECTS_FAILURE:
      return updateState.fetchFailure(state);

    case UPDATE_PROJECT_REQUEST:
      return updateState.updateRequest(state, action.id);
    case UPDATE_PROJECT_SUCCESS:
      return updateState.updateSuccess(state, action.id, action.project);
    case UPDATE_PROJECT_FAILURE:
      return updateState.updateFailure(state, action.id);

    case DELETE_PROJECT_REQUEST:
      return updateState.deleteRequest(state, action.id);
    case DELETE_PROJECT_SUCCESS:
      return updateState.deleteSuccess(state, action.id);
    case DELETE_PROJECT_FAILURE:
      return updateState.deleteFailure(state, action.id);

    case FETCH_PROJECT_WORKFLOWS_REQUEST:
      return updateState.fetchChildrenRequest(state, action.id, 'workflows');
    case FETCH_PROJECT_WORKFLOWS_SUCCESS:
      return updateState.fetchChildrenSuccess(state, action.id, 'workflows');

    case FETCH_PROJECT_CHAINS_AND_WORKFLOWS_REQUEST: {
      let newState = { ...state };
      if (action.filter !== 'chains') {
        newState = updateState.fetchChildrenRequest(state, action.id, 'workflows', false);
      }
      if (action.filter !== 'workflows') {
        newState = updateState.fetchChildrenRequest(newState, action.id, 'chains');
      }
      return newState;
    }
    case FETCH_PROJECT_CHAINS_AND_WORKFLOWS_SUCCESS: {
      let newState = { ...state };
      if (action.workflows) {
        newState = updateState.fetchChildrenSuccess(state, action.id, 'workflows');
      }
      if (action.chains) {
        newState = updateState.fetchChildrenSuccess(newState, action.id, 'chains');
      }
      return newState;
    }

    case FETCH_PROJECT_CHAINS_AND_WORKFLOWS_FAILURE: {
      let newState = { ...state };
      if (action.filter !== 'chains') {
        newState = updateState.fetchChildrenFailure(state, action.id, 'workflows');
      }
      if (action.filter !== 'workflows') {
        newState = updateState.fetchChildrenFailure(newState, action.id, 'chains');
      }
      return newState;
    }

    case FETCH_PROJECT_WORKFLOWS_FAILURE:
      return updateState.fetchChildrenFailure(state, action.id, 'workflows');


    case FETCH_PROJECT_CHAINS_REQUEST:
      return updateState.fetchChildrenRequest(state, action.id, 'chains');
    case FETCH_PROJECT_CHAINS_SUCCESS:
      return updateState.fetchChildrenSuccess(state, action.id, 'chains');
    case FETCH_PROJECT_CHAINS_FAILURE:
      return updateState.fetchChildrenFailure(state, action.id, 'chains');


    default:
      return state;
  }
}
