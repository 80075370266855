const colors = {
  darker: '#383e3c',
  dark: '#505553',
  grey: '#686c6a',
  light: '#858484',
  lighter: '#a9abaa',
  placeholder: '#b4b4b4',
  border: '#d9d9d9',
  divider: '#DADADA',
  shaded: '#ebebeb',
  background: '#f5f5f5',
  positive: '#9adb54',
  positiveLight: '#dceab4',
  negative: '#F94D1E',
  negativeLight: '#FF9477',
  highlight: '#FAFBD6',
  transparent: `rgba(255,255,255,0)`,
  white: '#ffffff',

  // Status Colors
  purple: '#BBBDD4',
  darkerPurple: '#bea5d4',
  green: '#C2E897',
  orange: '#F8CCAF',
  darkerOrange: '#F8B85F',
  blue: '#93C4EB',
  openBlue: '#DEECF9',

  // Task UI
  typeLight: '#d8d8d8',
  typeLocked: '#b4b4b4',
  typeSelected: '#3b403e',
  tealBorder: 'rgba(60, 169, 183, 0.75)',
  hooverShadow: 'rgba(0, 0, 0, 0.2)',

  //Button
  disabled: '#7fcbde',
};

function getSite() {
  if (window.location.hostname.indexOf('work') === 0) {
    return {primary: '#EA8037', secondary: '#F8B85F'};
  }
  if (window.location.hostname.indexOf('projects') === 0) {
    return {primary: '#3ba8b6', secondary: '#d0ecf3'};
  }
  return {primary: '#3ba8b6', secondary: '#d0ecf3'};
}

export default {
  ...colors,
  primary: () => { return getSite().primary; },
  secondary: () => { return getSite().secondary; }
};
