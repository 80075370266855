import {
  FETCH_PROFILE_REQUEST,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE
} from '../constants/actionTypes';

export default function (state = {
  isFetching: false,
  hasFetched: false,
  country: {},
  personal: {},
}, action) {
  switch (action.type) {
    case FETCH_PROFILE_REQUEST: {
      return { ...state, isFetching: true, hasFetched: true };
    }
    case FETCH_PROFILE_SUCCESS: {
      return {
        ...state, isFetching: false, hasFetched: true,
        country: action.country || {},
        personal: action.personal || {}
      };
    }
    case FETCH_PROFILE_FAILURE: {
      return { ...state, isFetching: false };
    }
    default:
      return state;
  }
}
