import React from 'react';

import colors from '../styles/colors';
import InlineList from '../components/InlineList';
import Wrapper from './Wrapper';
import Logo from '../components/Logo';
import { Caption } from '../components/type';

const styles = {
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 80,
    background: 'white',
    borderTop: 1,
    borderTopStyle: 'solid',
    borderTopColor: colors.divider,
  },
  links: {
    display: 'flex',
    alignItems: 'center'
  },
  linkSpace: {
    width: 32
  }
};

export default () => (
  <Wrapper>
    <div style={styles.footer}>
      <InlineList spacing={64} justify="center">
        <Logo primary={colors.primary()} secondary={colors.secondary()} showText={true}/>
        <Caption color="light">© 2017 OneSpace. All Rights Reserved.</Caption>
        <div style={styles.links}>
          <Caption underline={true} color="grey" href="/terms">Terms</Caption>
          <div style={styles.linkSpace}/>
          <Caption underline={true} color="grey" href="/privacy-policy">Privacy</Caption>
          <div style={styles.linkSpace}/>
          <Caption underline={true} color="grey" href="https://onespace.helpdocs.com/">Support</Caption>
        </div>
      </InlineList>
    </div>
  </Wrapper>
);
