import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';

import colors from '../styles/colors';
import ItemList from './ItemList';
import Menu from './Menu';
import { Caption } from './type';

/**
 * Select
 * Fake select with search
 */
class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleSelect = this.handleSelect.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.setOpen = this.setOpen.bind(this);
  }

  handleClickOutside() {
    if (this.state.open) {
      this.setOpen(false);
    }
  }

  handleSelect(value) {
    const stayOpen = this.props.handleSelect(value);
    this.setOpen(stayOpen || false);
  }

  toggleOpen() {
    if (this.state.open || !this.props.disabled) {
      this.setOpen(!this.state.open);
    }
  }

  setOpen(open = true) {
    this.setState({ open });
  }

  render() {
    const { value, options, searchable, itemType, defaultLabel } = this.props;
    const selectedOption = options.find(o => o.value === value);
    return (
      <div style={styles.select}>
        <DropDown defaultLabel={defaultLabel} label={selectedOption && selectedOption.label} handleClick={this.toggleOpen}/>
        {this.state.open &&
        <div style={styles.itemList}>
          {!searchable &&
          <Menu
            options={options}
            handleSelect={this.handleSelect}
          />
          }
          {searchable &&
          <ItemList
            shadow={true}
            border={true}
            items={options}
            itemType={itemType}
            hideSearch={false}
            handleSelect={this.handleSelect}
          />
          }
        </div>
        }
      </div>
    );
  }
}

const DropDown = ({ label, handleClick, defaultLabel='Select' }) => (
  <div style={styles.dropDown} onClick={handleClick}>
    <div style={styles.dropDownLabel}><Caption>{label ? label : defaultLabel}</Caption></div>
    <i className="fa fa-caret-down" style={styles.dropDownToggle}/>
  </div>
);
DropDown.displayName = 'Select-DropDown';

/* Styles */
const styles = {
  select: {
    position: 'relative',
  },
  dropDown: {
    display: 'flex',
    height: 45,
    border: `2px solid ${colors.border}`,
    borderRadius: 8,
    boxSizing: 'border-box',
    cursor: 'pointer',
    ':focus': {
      borderColor: colors.dark,
      outline: 0
    }
  },
  dropDownLabel: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
  },
  dropDownToggle: {
    position: 'absolute',
    right: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 45,
    width: 40,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    color: 'white',
    background: colors.light,
    fontSize: 20,
  },
  itemList: {
    position: 'absolute',
    top: 40,
    width: '100%',
    maxHeight: 400,
    zIndex: 9,
  }
};

/* PropTypes */
Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.node.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    fixed: PropTypes.bool, // If true, won't be filtered
  })).isRequired]),
  disabled: PropTypes.bool,
  searchable: PropTypes.bool,
  handleSelect: PropTypes.func.isRequired,
};

export default onClickOutside(Select);
