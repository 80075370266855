import Listener from '../../utils/Listener';
import config from './config';
import uuid from 'uuid';

/**
 * ChainMonitorBuilder
 * Binds a JSPlumb editor to the page
 */

export default class ChainBuilder extends Listener {
  constructor(chain, domNode, workflows, miniview, workstationUrl, pluginMetrics) {
    super();
    this.chain = chain;
    this.domNode = domNode; // Element to attach the builder to
    this.listeners = new Map();
    this.workflows = workflows;
    this.miniview = miniview;
    this.workstationUrl = workstationUrl;
    this.pluginMetrics = pluginMetrics;

    window.jsPlumbToolkit.ready(this.initialize.bind(this));
  }

  initialize() {
    // Setup JSPlumb Editor
    this.toolkit = window.jsPlumbToolkit.newInstance();
    this.instance = window.jsPlumb.getInstance();
    this.renderer = this.toolkit.render({ ...config, container: this.domNode, miniview: {container: this.miniview} });
    this.renderer.storePositionsInModel();

    const v = this;
    this.instance.on(this.domNode, 'click', '.release-container', () => {
      v._handleClickReleaseButton(this);
    });
    this.instance.on(this.domNode, 'click', '.upload-container', () => {
      v._handleClickUpload();
    });
    this.instance.on(this.domNode, 'click', '.download-container', () => {
      v._handleClickDownload();
    });

    // Add initial nodes & edges
    const data = transformForUse(this.chain, this.workflows, this.workstationUrl, this.pluginMetrics);
    this.toolkit.load({ data, onload: this.zoom.bind(this) });
  }

  /**
   * Public Methods
   */
  zoom() {
    let startId = '';
    [...this.toolkit.getNodes()].forEach(node => {
      if(node.data.name === 'Import') {
        startId = node.data.id;
      }
    });
    setTimeout(() => {
      this.renderer.centerOnVertically(this.toolkit.getNode(startId));
    });
  }

  /** JSPlumb Events **/
  // Called after a node is added to builder
  _nodeAdded(node) {
    // Don't go further for ghost nodes
    if (node.data.id.includes('ghost')) {
      return this.zoom();
    }
  }

  /** User Events **/
  _handleClickReleaseButton() {
    this._emit('releaseButton');
  }

  _handleClickUpload() {
    this._emit('uploadButton');
  }
  _handleClickDownload() {
    this._emit('downloadButton');
  }
}

function handleMergingEdges(destinationEdges, sourceEdges) {
  return [...new Set([...destinationEdges ,...sourceEdges])];
}

function getWorkflowData(node, workflows) {
  if(node.operation.startup || node.operation.plugin) {
    return {
      state: 'static',
      itemStatus: node.operation.startup ? 'startup' : 'plugin',
      reviewName: '',
      stepCount: null,
      itemCounts: {},
    };
  }
  const workflow = workflows[node.operation.swfWorkflow.entityId];
  return {
    state: workflow.state,
    itemStatus: workflow.itemStatus,
    reviewName: workflow.review_assignment_name || '',
    stepCount: workflow.workflow_steps,
    itemCounts: workflow.itemStatus || {},
  };
}

function backfillStartupNode(startupNode, chain) {
  // Set position of startup node and ghost add workflow node
  chain.nodePositions.unshift(
    { left: -150, node: startupNode.id, top: 0 },
    { left: 0, node: `${'ghost-' + startupNode.id}`, top: -40 }
  );

  // Backfill routes for the startup node
  startupNode.routes.push(
    {
      conditions: [],
      destinationNode: `${chain.nodes[0].id}`,
      mapping: {}
    }
  );

  // Backfill the destinations of startup node which is the id of first node
  startupNode.destinations.push(`${chain.nodes[0].id}`);

  // Backfill the sources of the originally first node
  chain.nodes[0].sources.push(startupNode.id);

  // Finally, add startup node as first in chain
  chain.nodes.unshift(startupNode);
}

/* Utility function */
function transformForUse(chain, workflows, workstationUrl, pluginMetrics) {
  // Return a single ghost node if this is an empty chain
  let startupNode = {
    'operation': {
      'startup': {
        'inputs': [],
        'outputs': [],
      }
    },
    'name': 'Import',
    'id': uuid(),
    'routes': [],
    'destinations': [],
    'sources': [],
  };

  // Is there a startup node in the chain already
  const isThereStartupNode = chain.nodes.find(node => node.operation.startup);

  if (!chain.nodes.length) {
    // return startNode();
    chain.nodes.push(startupNode);
  } else if (!isThereStartupNode) {
    backfillStartupNode(startupNode, chain);
  }

  const nodes = [];
  const destinationEdges = [];
  const sourceEdges = [];

  chain.nodes.forEach(node => {
    const workflowData = getWorkflowData(node, workflows);
    const step1 = workflowData.itemCounts['step 1'] || 0;
    const step2 = workflowData.itemCounts['step 2'] || 0;
    const step3 = workflowData.itemCounts['step 3'] || 0;
    const paused = workflowData.itemCounts['paused'] || 0;
    const total = step1 + step2 + step3 + paused;

    if(node.operation.plugin) {
      nodes.push({
        id: node.id,
        name: node.name,
        type: 'plugin',
        total: ((!!pluginMetrics && pluginMetrics[node.id]) && pluginMetrics[node.id].total) ? pluginMetrics[node.id].total : 0,
      });
    } else {
      nodes.push({
        id: node.id,
        wfId: node.operation.swfWorkflow ? node.operation.swfWorkflow.entityId : '',
        swfId: node.operation.swfWorkflow ? node.operation.swfWorkflow.workflowId : '',
        name: node.name,
        state: workflowData.state,
        type: node.operation.startup ? 'startup' : 'stats',
        staged: chain.metrics.stagedItemCount,
        paused,
        step1,
        step2,
        step3,
        total,
        reviewHref: `${workstationUrl}find-work%3Fsearch%3D${workflowData.reviewName}`,
        stepCount: parseInt(workflowData.stepCount)
      });
    }
    // Loop through each destination,
    // adding an edge between this node and its destination
    node.destinations.forEach(destination => destinationEdges.push({
      source: node.id,
      target: destination,
      data: {
        //TODO MOCK check if this is an invalid source
        type: 'normal',
        // Find the matching route
        route: node.routes.find(route => route.destinationNode === destination)
      }
    }));
    node.sources.forEach((source, i) => {
      if (i > 0) {
        sourceEdges.push({
          source: source,
          target: node.id,
          data: {
            //TODO MOCK check if this is an invalid source
            type: 'normal',
            // Find the matching route
            route: node.routes && node.routes.find(route => route.destinationNode === source)
          }
        });
      }
    });
    if(!node.destinations.length) {
      destinationEdges.push({
        source: node.id,
        target: 'completed-node',
        data: {
          type: 'completed'
        }
      });
    }
  });

  const completedNode = {
    name: 'Completed',
    id : 'completed-node',
    type: 'completed',
    completed: chain.metrics.completedItemCount,
  };
  nodes.push(completedNode);

  // Merge edges and eliminate dupes
  const edges = handleMergingEdges(destinationEdges, sourceEdges);

  return { nodes, edges };
}
