import React, { Component } from 'react';
import { DragSource } from 'react-dnd';
import Radium from 'radium';

import { ItemTypes } from '../constants';

const elementSource = {
  beginDrag(props) {
    return {
      type: props.element.type,
      element: props.element,
    };
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

class Element extends Component {
  render() {
    const { element, connectDragSource, quickAdd, isDragging, isOtherDragging, index, isDeleting } = this.props;
    return connectDragSource(
      <div
        className="task-ui-element-selector-element"
        style={[
          styles.main,
          index === 0 && styles.first,
          index === 11 && styles.last,
          isDragging && styles.dragging,
          isOtherDragging && styles.disabled,
          isDeleting && styles.disabled,
        ]}
        onClick={quickAdd}>
        <i style={styles.icon} className={`os-${element.icon}`}/>
        <div style={styles.name}>{element.name}</div>
      </div>
    );
  }
}

const styles = {
  main: {
    display: 'inline-block',
    textAlign: 'center',
    height: 55,
    paddingTop: 12,
    background: '#444847',
    color: 'rgba(255,255,255,0.8)',
    cursor: 'pointer',
    zIndex: 1,
    boxShadow: 'none',
    transition: 'all 0.44s ease',
    ':hover': {
      background: '#4D504F',
    },
    width: 120,
  },
  first: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.2)'
  },
  last: {
    borderRight: 'none'
  },

  dragging: {
    transition: 'none',
    color: '#D7D7D7',
    background: '#D7D7D7',
    ':hover': {
      boxShadow: 'none'
    }
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.75,
  },

  icon: {
    fontSize: 20,
    marginBottom: 4,
    width: 20,
    height: 20
  },

  name: {
    textTransform: 'uppercase',
    fontSize: 10,
    fontWeight: 600
  }
};

export default DragSource(ItemTypes.ELEMENT, elementSource, collect)(Radium(Element));
