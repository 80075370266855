import {
  RECEIVED_COMPANY_ADMINS,
  FETCH_FUNDS_REQUEST,
  FETCH_FUNDS_SUCCESS,
  FETCH_FUNDS_FAILURE,
  FETCH_COMPANY_REQUEST,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_FAILURE,
  FETCH_COMPANIES_LIST_REQUEST,
  FETCH_COMPANIES_LIST_SUCCESS,
  FETCH_COMPANIES_LIST_FAILURE,
  FETCH_COMPANY_LEDGER_REQUEST,
  FETCH_COMPANY_LEDGER_SUCCESS,
  FETCH_COMPANY_LEDGER_FAILURE,
  FETCH_TEAMS_LIST_REQUEST,
  FETCH_TEAMS_LIST_SUCCESS,
  FETCH_TEAMS_LIST_FAILURE,
  CREATE_API_CREDENTIALS_REQUEST,
  CREATE_API_CREDENTIALS_SUCCESS,
  CREATE_API_CREDENTIALS_FAILURE,
  UPDATE_API_CREDENTIALS_REQUEST,
  UPDATE_API_CREDENTIALS_SUCCESS,
  UPDATE_API_CREDENTIALS_FAILURE,
  FETCH_CURATED_TEAMS_REQUEST,
  FETCH_CURATED_TEAMS_SUCCESS,
  FETCH_CURATED_TEAMS_FAILURE,
  UPDATE_COMPANY_PLAN_REQUEST,
  UPDATE_COMPANY_PLAN_SUCCESS,
  UPDATE_COMPANY_PLAN_FAILURE,
  UPDATE_COMPANY_LEDGER_REQUEST,
  UPDATE_COMPANY_LEDGER_SUCCESS,
  UPDATE_COMPANY_LEDGER_FAILURE,
  UPDATE_DEFAULT_WEBHOOK_REQUEST,
  UPDATE_DEFAULT_WEBHOOK_SUCCESS,
  UPDATE_DEFAULT_WEBHOOK_FAILURE,
  UPDATE_API_SECURITY_REQUEST,
  UPDATE_API_SECURITY_SUCCESS,
  UPDATE_API_SECURITY_FAILURE,
  UPDATE_CHAIN_WEBHOOK_REQUEST,
  UPDATE_CHAIN_WEBHOOK_SUCCESS,
  UPDATE_CHAIN_WEBHOOK_FAILURE,
  UPDATE_WORKFLOW_WEBHOOK_REQUEST,
  UPDATE_WORKFLOW_WEBHOOK_SUCCESS,
  UPDATE_WORKFLOW_WEBHOOK_FAILURE,
  FETCH_COMPANY_WEBHOOK_REQUEST,
  FETCH_COMPANY_WEBHOOK_SUCCESS,
  FETCH_COMPANY_WEBHOOK_FAILURE,
  FETCH_COMPANY_API_USER_REQUEST,
  FETCH_COMPANY_API_USER_SUCCESS,
  FETCH_COMPANY_API_USER_FAILURE,
} from '../constants/actionTypes';
import fetch, { checkStatus, parseJSON } from '../utils/fetch';

let hasFetchedAdmins = false;

const formatCompany = company => (Object.assign({}, company, {
  id: company.companyId,
}));

export function fetchCompany() {
  return {
    types: [FETCH_COMPANY_REQUEST, FETCH_COMPANY_SUCCESS, FETCH_COMPANY_FAILURE],
    fetchRoute: '/api/company',
    shouldCallAPI: state => !state.app.company,
    getFormattedResponse: company => ({ company })
  };
}

export function fetchFunds() {
  return {
    types: [FETCH_FUNDS_REQUEST, FETCH_FUNDS_SUCCESS, FETCH_FUNDS_FAILURE],
    fetchRoute: '/api/company/funds',
    shouldCallAPI: state => !state.app.hasFetchedFunds,
  };
}

export function fetchAdmins() {
  return dispatch => new Promise((resolve, reject) => {
    if (hasFetchedAdmins) {
      resolve();
    }
    hasFetchedAdmins = true;
    fetch('/api/profile/support-users/list', 'get')
      .then(checkStatus)
      .then(parseJSON)
      .then(admins => {
        resolve(admins);
        dispatch({
          type: RECEIVED_COMPANY_ADMINS,
          admins,
        });
      })
      .catch(reject);
  });
}

export function addFunds(request) {
  return () => new Promise((resolve, reject) => {
    fetch('/api/company/funds', 'post', request)
      .then(checkStatus)
      .then(parseJSON)
      .then(res => {
        resolve(res);
      })
      .catch(reject);
  });
}

export function fetchCompaniesList() {
  return {
    types: [FETCH_COMPANIES_LIST_REQUEST, FETCH_COMPANIES_LIST_SUCCESS, FETCH_COMPANIES_LIST_FAILURE],
    fetchRoute: '/manage/company/list',
    fetchMethod: 'get',
    getFormattedResponse: companyList => ({companyList: companyList.map(formatCompany)})
  };
}

export function fetchCompanyLedger(companyId) {
  return {
    types: [FETCH_COMPANY_LEDGER_REQUEST, FETCH_COMPANY_LEDGER_SUCCESS, FETCH_COMPANY_LEDGER_FAILURE],
    fetchRoute: `/manage/company/${companyId}/ledger`,
    fetchMethod: 'get',
    getFormattedResponse: ledger => ({ledger})
  };
}

export function updateCompanyLedger(companyId, swfId, amount, description, invoiceId) {
  return {
    types: [ UPDATE_COMPANY_LEDGER_REQUEST, UPDATE_COMPANY_LEDGER_SUCCESS, UPDATE_COMPANY_LEDGER_FAILURE ],
    fetchRoute: `/manage/company/${companyId}/ledger`,
    fetchMethod: 'put',
    fetchBody: { amount, description, invoiceId },
    payload: { swfId },
  };
}

export function updateCompanyPlan(companyId, swfId, newPlan) {
  return {
    types: [ UPDATE_COMPANY_PLAN_REQUEST, UPDATE_COMPANY_PLAN_SUCCESS, UPDATE_COMPANY_PLAN_FAILURE ],
    fetchRoute: `/manage/company/${companyId}/seats`,
    fetchMethod: 'post',
    fetchBody: newPlan,
    payload: {companyId, swfId}
  };
}

export function loginAsCompany(companyId) {
  return () => new Promise((resolve, reject) => {
    const body = {};
    body.companyId = companyId;
    fetch('/manage/company', 'post', body)
      .then(checkStatus)
      .then(parseJSON)
      .then(res => {
        resolve(res);
      })
      .catch(reject);
  });
}

export function fetchTeamsList() {
  return {
    types: [FETCH_TEAMS_LIST_REQUEST, FETCH_TEAMS_LIST_SUCCESS, FETCH_TEAMS_LIST_FAILURE],
    fetchRoute: '/api/teams',
    fetchMethod: 'get'
  };
}

export function fetchCuratedTeams() {
  return {
    types: [FETCH_CURATED_TEAMS_REQUEST, FETCH_CURATED_TEAMS_SUCCESS, FETCH_CURATED_TEAMS_FAILURE],
    fetchRoute: '/api/teams/public/active',
    fetchMethod: 'get'
  };
}

export function createAPICredentials(companyId) {
  return {
    types: [CREATE_API_CREDENTIALS_REQUEST, CREATE_API_CREDENTIALS_SUCCESS, CREATE_API_CREDENTIALS_FAILURE],
    fetchRoute: '/apiuser',
    fetchMethod: 'post',
    fetchBody: {companyId},
  };
}

export function updateAPICredentials(workerId) {
  return {
    types: [UPDATE_API_CREDENTIALS_REQUEST, UPDATE_API_CREDENTIALS_SUCCESS, UPDATE_API_CREDENTIALS_FAILURE],
    fetchRoute: `/apiuser/${workerId}`,
    fetchMethod: 'put',
  };
}

export function defaultWebhook(companyId, webhook, webhookEnabled) {
  return {
    types: [UPDATE_DEFAULT_WEBHOOK_REQUEST, UPDATE_DEFAULT_WEBHOOK_SUCCESS, UPDATE_DEFAULT_WEBHOOK_FAILURE],
    fetchRoute: `/company/${companyId}/webhookdefault`,
    fetchMethod: 'put',
    fetchBody: {webhook, webhookEnabled},
  };
}

export function setSecurity(companyId, securityType, username, password, url, accessToken) {
  return {
    types: [UPDATE_API_SECURITY_REQUEST, UPDATE_API_SECURITY_SUCCESS, UPDATE_API_SECURITY_FAILURE],
    fetchRoute: `/company/${companyId}/security-info`,
    fetchMethod: 'put',
    fetchBody: {securityType, username, password, url, accessToken},
    payload: {securityType, url}
  };
}

export function fetchCompanyWebhooks(companyId) {
  return {
    types: [FETCH_COMPANY_WEBHOOK_REQUEST, FETCH_COMPANY_WEBHOOK_SUCCESS, FETCH_COMPANY_WEBHOOK_FAILURE],
    fetchRoute: `/company/${companyId}/webhooks`,
    fetchMethod: 'get',
  };
}

export function workflowOrChainWebhook(companyId, workflowOrChainId, webhook, chain, webhookEnabled) {
  return {
    types: chain ?
      [UPDATE_CHAIN_WEBHOOK_REQUEST, UPDATE_CHAIN_WEBHOOK_SUCCESS, UPDATE_CHAIN_WEBHOOK_FAILURE] :
      [UPDATE_WORKFLOW_WEBHOOK_REQUEST, UPDATE_WORKFLOW_WEBHOOK_SUCCESS, UPDATE_WORKFLOW_WEBHOOK_FAILURE],
    fetchRoute: chain ?
      `/company/${companyId}/chain/${workflowOrChainId}/webhook` :
      `/company/${companyId}/workflow/${workflowOrChainId}/webhook`,
    fetchMethod: 'put',
    fetchBody: {webhook, webhookEnabled},
    payload: {workflowOrChainId}
  };
}

export function fetchApiUser(companyId) {
  return {
    types: [FETCH_COMPANY_API_USER_REQUEST, FETCH_COMPANY_API_USER_SUCCESS, FETCH_COMPANY_API_USER_FAILURE],
    fetchRoute: `/company/${companyId}/apiuser`,
    fetchMethod: 'get',
  };
}

