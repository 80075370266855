import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import colors from '../styles/colors';
import PanelHeader from './PanelHeader';

/**
 * Panel
 * A white card with a border and a title
 */
const Panel = (props) => (
  <div className={css(props.simple ? styles.simple : styles.panel)}>
    {props.includeHeader &&
      <PanelHeader {...props}/>
    }
    <div>{props.children}</div>
  </div>
);

/* Styles */
const styles = StyleSheet.create({
  simple: {
    position: 'relative',
    background: 'white',
    border: '1px solid #ebebeb',
  },
  panel: {
    position: 'relative',
    background: 'white',
    ':before': {
      content: '""',
      display: 'block',
      border: `2px solid ${colors.divider}`,
      borderRadius: 6,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
    }
  }
});

/* PropTypes */
Panel.propTypes = {
  title: PropTypes.string.isRequired,
  titleHref: PropTypes.string,
  actions: PropTypes.array, // See PanelHeader
  simple: PropTypes.bool,
  children: PropTypes.node.isRequired,
  includeHeader: PropTypes.bool,
};
Panel.defaultProps = {
  title: '',
  actions: [],
  includeHeader: true,
};

export default Panel;
