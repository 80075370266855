import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import colors from '../styles/colors';

class Tooltip extends Component{
  constructor(props) {
    super(props);
    this.state = {
      showTip: false,
    };
  }

  render() {
    const {tooltipText, tooltipObject, left, right} = this.props;

    return (
      <div className={css(styles.container)}>
        <div className= {css(!this.state.showTip ? styles.none : styles.popOver, !!right || !!left ? (!!right ? styles.right : styles.left) : '')}>
          { !!tooltipText &&
          <div className={css(styles.width)}>
            <h6 className={css(styles.text)}>{tooltipText}</h6>
          </div>
          }
          { !!tooltipObject &&
          <div>
            {tooltipObject}
          </div>
          }
        </div>
        <div
          className={css(styles.button)}
          onMouseEnter={() => {this.setState({ showTip: !this.state.showTip });}}
          onMouseOut={() => {this.setState({ showTip: !this.state.showTip });}}
        >
          ?
        </div>
      </div>
    );
  }
}

/* Styles */
const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  button: {
    borderRadius: '50%',
    background: colors.primary(),
    height: 16,
    width: 16,
    fontSize: 12,
    color: 'white',
    textAlign: 'center',
    fontWeight: 900,
    cursor: 'pointer',
  },
  popOver: {
    background: colors.dark,
    color: 'white',
    fontStyle: 'italic',
    fontSize: 12,
    borderRadius: 2,
    padding: 8,
    position: 'absolute',
    boxShadow: `0 2px 8px rgba(0,0,0,0.42)`,
  },
  width: {
    minWidth: 400,
  },
  left: {
    bottom: 20,
    right: 0,
  },
  right: {
    bottom: 20,
    left: 0,
  },
  none: {
    display: 'none',
  },
  text: {
    padding: `9px 14px`,
    lineHeight: 1.824,
  }
});

/* PropTypes */
Tooltip.propTypes = {
  tooltipText: PropTypes.string,
  tooltipObject: PropTypes.object,
  right: PropTypes.bool,
  left: PropTypes.bool,
};

export default Tooltip;