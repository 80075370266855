import { updateState } from '../utils/reducerHelpers';
import update from 'immutability-helper';
import objectToArray from '../utils/_objectToArray';

import {
  FETCH_COMPANIES_LIST_REQUEST,
  FETCH_COMPANIES_LIST_SUCCESS,
  FETCH_COMPANIES_LIST_FAILURE,
  UPDATE_COMPANY_PLAN_REQUEST,
  UPDATE_COMPANY_PLAN_SUCCESS,
  UPDATE_COMPANY_PLAN_FAILURE,
  UPDATE_DEFAULT_WEBHOOK_REQUEST,
  UPDATE_DEFAULT_WEBHOOK_SUCCESS,
  UPDATE_DEFAULT_WEBHOOK_FAILURE,
  UPDATE_CHAIN_WEBHOOK_REQUEST,
  UPDATE_CHAIN_WEBHOOK_SUCCESS,
  UPDATE_CHAIN_WEBHOOK_FAILURE,
  UPDATE_WORKFLOW_WEBHOOK_REQUEST,
  UPDATE_WORKFLOW_WEBHOOK_SUCCESS,
  UPDATE_WORKFLOW_WEBHOOK_FAILURE,
  UPDATE_API_SECURITY_REQUEST,
  UPDATE_API_SECURITY_SUCCESS,
  UPDATE_API_SECURITY_FAILURE,
  FETCH_COMPANY_WEBHOOK_REQUEST,
  FETCH_COMPANY_WEBHOOK_SUCCESS,
  FETCH_COMPANY_WEBHOOK_FAILURE,
  FETCH_COMPANY_API_USER_REQUEST,
  FETCH_COMPANY_API_USER_FAILURE,
  FETCH_COMPANY_API_USER_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  items: {},
  isFetching: false,
  hasFetched: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_COMPANIES_LIST_REQUEST:
      return updateState.fetchRequest(state);
    case FETCH_COMPANIES_LIST_SUCCESS:
      return updateState.fetchSuccess(state, action.companyList);
    case FETCH_COMPANIES_LIST_FAILURE:
      return updateState.fetchFailure(state);
    case UPDATE_COMPANY_PLAN_REQUEST:
      return updateState.updateRequest(state, action.companyId);
    case UPDATE_COMPANY_PLAN_SUCCESS:
      return updateState.updateSuccess(state, action.companyId, {
        adminSeats: action.admin_seats,
        contributorSeats: action.contributor_seats
      });
    case UPDATE_COMPANY_PLAN_FAILURE:
      return updateState.updateFailure(state, action.companyId);

    case FETCH_COMPANY_WEBHOOK_REQUEST:
      return updateState.fetchRequest(state);
    case FETCH_COMPANY_WEBHOOK_SUCCESS:
      return update(state.items, {$set: (action)});
    case FETCH_COMPANY_WEBHOOK_FAILURE:
      return updateState.fetchFailure(state);

    case UPDATE_DEFAULT_WEBHOOK_REQUEST:
      return updateState.fetchRequest(state);
    case UPDATE_DEFAULT_WEBHOOK_SUCCESS:
      return update(state, {webhooks: {companyUrl: {$set: action.webhookDefault}}});
    case UPDATE_DEFAULT_WEBHOOK_FAILURE:
      return updateState.fetchFailure(state);

    case UPDATE_API_SECURITY_REQUEST:
      return updateState.updateRequest(state);
    case UPDATE_API_SECURITY_SUCCESS:
      return update(state, {
        securityType: {$set: action.securityType},
        token: {$set: action.token},
        url: {$set: action.url}
      });
    case UPDATE_API_SECURITY_FAILURE:
      return updateState.updateFailure(state);

    case UPDATE_CHAIN_WEBHOOK_REQUEST:
      return updateState.fetchRequest(state);

    case UPDATE_CHAIN_WEBHOOK_SUCCESS:
      const chainIndex = objectToArray(state.webhooks.chains).findIndex(chain => chain.chainId === action.workflowOrChainId);
      if(chainIndex === -1) {
        return update(state, {webhooks: {chains: {$merge: [{chainId: action.workflowOrChainId, url: action.webhook}]}}});
      } else {
        return update(state, {webhooks: {chains: {[chainIndex]: {$merge: {url: action.webhook}}}}});
      }
    case UPDATE_CHAIN_WEBHOOK_FAILURE:
      return updateState.fetchFailure(state);

    case UPDATE_WORKFLOW_WEBHOOK_REQUEST:
      return updateState.fetchRequest(state);
    case UPDATE_WORKFLOW_WEBHOOK_SUCCESS:
      const workflowIndex = objectToArray(state.webhooks.workflows).findIndex(wf => wf.workflowId === action.workflowOrChainId);
      if(workflowIndex === -1) {
        return update(state, {webhooks: {workflows: {$merge: [{workflowId: action.workflowOrChainId, url: action.webhook}]}}});
      } else {
        return update(state, {webhooks: {workflows: {[workflowIndex]: {$merge: {url: action.webhook}}}}});
      }
    case UPDATE_WORKFLOW_WEBHOOK_FAILURE:
      return updateState.fetchFailure(state);

    case FETCH_COMPANY_API_USER_REQUEST:
      return updateState.fetchRequest(state);
    case FETCH_COMPANY_API_USER_SUCCESS:
      return update(state, { $merge: { apiUser: action } });
    case FETCH_COMPANY_API_USER_FAILURE:
      return updateState.fetchFailure(state);

    default:
      return state;
  }
}