import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import Wrapper from '../../containers/Wrapper';
import { Heading, Large, Attention } from '../../components/type';
import Button from '../../components/Button';
import { connect } from 'react-redux';
import objectToArray from '../../utils/_objectToArray';
import { fetchChainPlugins } from '../../actions/chainPlugins';
import Panel from '../../containers/Panel';
import LoadState from '../../containers/LoadState';

/**
 * Chain Plugin Library
 * Page where plugins are created
 */
class ChainPluginLibrary extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.dispatch(fetchChainPlugins());
  }

  editChainPlugin(id) {
    const history = this.props.history;
    history.push(`/manage/plugins/${id}`);
  }

  render() {
    const { chainPlugins, hasFetchedChainPlugins } = this.props;

    return (
      <DocumentTitle title="Plugin Library | OneSpace Project Center">
        <div style={{marginTop: 64}}>
          <Wrapper>
            <div style={styles.headingWrapper}>
              <Heading color="dark">Plugin Library</Heading>
              <a href="/manage/plugins/create" style={{textDecoration: 'none'}}>
                <Button>Create Plugin</Button>
              </a>
            </div>
            <div style={styles.cardContainer}>
              {!hasFetchedChainPlugins ?
                <LoadState/>
                :
                !chainPlugins.length ?
                  <div style={{display: 'flex', justifyContent: 'center', paddingTop: 24}}>
                    <Attention color="dark">You don't have any plugins.</Attention>
                  </div>
                  :
                  chainPlugins.map((plugin, idx) => {
                    return (
                      <div key={idx} style={{ marginBottom: 30 }}>
                        <Panel
                          title={plugin.name}
                          actions={[{
                            icon: 'pencil-square-o',
                            handleClick: () => this.editChainPlugin(plugin.id),
                            label: 'Edit'
                          }]}
                          simple={true}>
                          <div style={styles.panel}>
                            <Large color="dark" bottom={15}>{plugin.description}</Large>
                          </div>
                        </Panel>
                      </div>
                    );
                  })
              }
            </div>
          </Wrapper>
        </div>
      </DocumentTitle>
    );
  }
}

const styles = {
  headingWrapper: {
    display: 'flex',
    alignItem: 'flex-end',
    justifyContent: 'space-between',
    paddingBottom: 20
  },
  panel: {
    padding: 15,
  }
};

function select( state ) {
  const chainPlugins = objectToArray( state.chainPlugins.items );
  const hasFetchedChainPlugins = (!state.chainPlugins.isFetching && state.chainPlugins.hasFetched);
  return {
    chainPlugins,
    hasFetchedChainPlugins
  };
}

export default connect( select )( ChainPluginLibrary );
