import React, {Component} from 'react';
import {Label, SmallDescription} from './type.js';
import Input from './Input';

/**
 * Chaining Processor
 */
class ChainingProcessor extends Component {
  constructor(props) {
    super(props);
  }

  render() {
  const { data, handleUpdateProcessors, disabled, importFileError, preError, postError } = this.props;
    return (
      <div style={Object.assign({}, styles.processorWrap, disabled && styles.disabled)}>
        <div style={styles.inputField}>
          <Label color="light">Pre Processor Script</Label>
          <Input
            error={preError}
            disabled={disabled}
            handleChange={val => handleUpdateProcessors(val, 'pre')}
            value={data.pre || ''}
            unallowedCharacters={[32]}/>
        </div>
        <div style={styles.inputField}>
          <Label color="light">Post Processor Script</Label>
          <Input
            error={postError}
            disabled={disabled}
            handleChange={val => handleUpdateProcessors(val, 'post')}
            value={data.post || ''}
            unallowedCharacters={[32]}/>
        </div>
        <div style={styles.inputField}>
          <Label color="light">Upload a Sample Import File</Label>
          <div style={{marginBottom: 10}}>
            <SmallDescription color="light" italic="true">
              Once you have activated a preprocessor, the standard template file will no longer
              work.
              Copy/paste a URL to a new template that will function correctly through the
              preprocessor.
            </SmallDescription>
          </div>
          <Input
            disabled={disabled}
            handleChange={val => {
              handleUpdateProcessors(val, 'importFile');
            }}
            error={importFileError && importFileError}
            value={data.importFile || ''}
            unallowedCharacters={[32]}
            placeholder="http:// or https://"/>
        </div>
      </div>
    );
  }
};

const styles = {
  inputField: {
    margin: '15px 0',
    maxWidth: 500,
  },
  disabled: {
    opacity: 0.75,
  },
};

export default ChainingProcessor;
