import update from 'immutability-helper';
import moment from 'moment';

import { updateState } from '../utils/reducerHelpers';
import {
  FETCH_CHAIN_BATCHES_SUCCESS,
  IMPORTED_BATCH,
  RECEIVED_BATCH,
  RECEIVED_BATCHES,
  UPDATED_BATCH,
  CANCELED_BATCH,
  IMPORT_CHAIN_BATCH_REQUEST,
  IMPORT_CHAIN_BATCH_SUCCESS,
  IMPORT_CHAIN_BATCH_FAILURE,
  FETCH_CHAIN_BATCH_SUCCESS,
  FETCH_CHAIN_BATCH_FAILURE,
  RELEASE_CHAIN_BATCH_SUCCESS,
  RELEASE_WORKFLOW_BATCH_SUCCESS,
  FETCH_WORKFLOW_BATCHES_SUCCESS,
  IMPORT_WORKFLOW_BATCH_REQUEST,
  IMPORT_WORKFLOW_BATCH_SUCCESS,
  IMPORT_WORKFLOW_BATCH_FAILURE,
  EXPORT_BATCH_REQUEST,
  EXPORT_BATCH_SUCCESS,
  EXPORT_CHAIN_BATCH_REQUEST,
  EXPORT_CHAIN_BATCH_SUCCESS,
  EXPORT_CHAIN_BATCH_FAILURE,
  PAUSE_BATCH,
  RESUME_BATCH,
  PAUSE_CHAIN_BATCH_FAILURE,
  PAUSE_CHAIN_BATCH_SUCCESS,
  PAUSE_CHAIN_BATCH_REQUEST,
  RESUME_CHAIN_BATCH_REQUEST,
  RESUME_CHAIN_BATCH_SUCCESS,
  RESUME_CHAIN_BATCH_FAILURE,
} from '../constants/actionTypes';

const initialState = {
  items: {},
  pageCount: 1,
  isFetching: false,
  hasFetched: false,
};

function objCompare(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CHAIN_BATCHES_SUCCESS:
      const chainBatches = {};
      action.batches.forEach(batch => {
        chainBatches[batch.id] = Object.assign({}, state[batch.id], batch);
      });
      return update( state, {
        $set:  {
          items: chainBatches,
          pageCount: action.pageCount,
          hasFetched: false
        }
      } );
    case FETCH_CHAIN_BATCH_SUCCESS:
      const stateBatch = state.items[action.batch.id];
      const fetchedBatch = action.batch;

      fetchedBatch.created_at = stateBatch.created_at;

      if (!objCompare(stateBatch.items, fetchedBatch.items) || !objCompare(stateBatch.ioSchema, fetchedBatch.ioSchema) || stateBatch.state !== fetchedBatch.state) {
        return update(state, {
          items: {
            [action.batch.id]: {
              $merge: action.batch
            },
          },
        });
      }
      return state;
    case FETCH_CHAIN_BATCH_FAILURE:
      return state;
    case FETCH_WORKFLOW_BATCHES_SUCCESS:
      return updateState.fetchSuccess(state, action.batches);
    case IMPORT_CHAIN_BATCH_REQUEST:
    case IMPORT_WORKFLOW_BATCH_REQUEST:
      return update(state, {
        items: {
          [action.tempId]: {
            $set: {
              chainId: action.chainId,
              created: moment().utc().format(),
              created_at: moment().utc().format(),
              filename: action.file.name,
              tempId: action.tempId,
              items: {},
              state: 'uploading',
            },
          },
        },
      });
    case IMPORT_CHAIN_BATCH_SUCCESS:
    case IMPORT_WORKFLOW_BATCH_SUCCESS:
      const batches = update(state.items, { [action.batch.id]: { $set: action.batch } });
      delete batches[action.tempId];
      return { ...state, items: batches };

    case IMPORT_CHAIN_BATCH_FAILURE:
      return update(state, {
        items: {
          [action.tempId]: {
            $merge: {
              state: 'failed',
              error: action.error,
              dataType: action.dataType ? action.dataType : null,
            }
          }
        }
      });
    case IMPORT_WORKFLOW_BATCH_FAILURE:
      return update(state, {
        items: {
          [action.tempId]: {
            $merge: {
              state: 'failed',
              error: action.error,
            }
          }
        }
      });

    case RELEASE_CHAIN_BATCH_SUCCESS:
    case RELEASE_WORKFLOW_BATCH_SUCCESS:
      const newPendingCount = (state.items[action.batchId].items.pending || 0) + action.releasedCount;
      return update(state, {
        items: {
          [action.batchId]: {
            items: {
              $merge: {
                pending: newPendingCount,
              }
            }
          }
        }
      });

    case IMPORTED_BATCH: {
      return update(state, {
        [action.tempId]: {
          $set: Object.assign({}, state[action.tempId], action.batch)
        }
      });
    }
    case RECEIVED_BATCHES: {
      const batches = {};
      action.batches.forEach(batch => {
        batches[batch.id] = Object.assign({}, state[batch.id], batch);
      });
      return Object.assign({}, state, batches);
    }
    case RECEIVED_BATCH: {
      return update(state, {
        [action.tempId || action.batch.id]: {
          $set: Object.assign({}, state[action.tempId || action.batch.id], action.batch)
        }
      });
    }
    case UPDATED_BATCH: {
      return update(state, {
        [action.tempId]: { $merge: action.batch }
      });
    }
    case CANCELED_BATCH: {
      const batch = state.items[ action.batchId ];
      const pausedItemCount = !!batch.items && !!batch.items.paused ? batch.items.paused : 0;
      const openItemCount = !!batch.items && !!batch.items.open ? batch.items.open : 0;
      const stagedItemCount = !!batch.items && !!batch.items.staged ? batch.items.staged : 0;
      const rowCount = pausedItemCount + openItemCount + stagedItemCount;
      const completed = batch.items.completed ? { completed: batch.items.completed } : {};
      const items = Object.assign( {}, {
        canceled: rowCount,
      }, completed );
      return update( state, {
        items: {
          [action.batchId]: {
            $merge: {
              state: 'canceled',
              items
            }
          }
        }
      } );
    }
    case PAUSE_BATCH: {
      const batch = state.items[ action.batchId ];
      const completed = batch.items.completed ? { completed: batch.items.completed } : {};
      const staged = batch.items.staged ? { staged: batch.items.staged } : {};
      const canceled = batch.items.canceled ? { canceled: batch.items.canceled } : {};
      const paused = batch.items.paused;
      const items = Object.assign( {}, {
        paused: batch.items.open + (paused || 0),
        }, completed, staged, canceled );
      return update( state, {
        items: {
          [action.batchId]: {
            $merge: {
              items,
            }
          }
        }
      } );
    }
    case RESUME_BATCH: {
      const batch = state.items[ action.batchId ];
      const completed = batch.items.completed ? { completed: batch.items.completed } : {};
      const staged = batch.items.staged ? { staged: batch.items.staged } : {};
      const canceled = batch.items.canceled ? { canceled: batch.items.canceled } : {};
      const open = batch.items.open;
      const paused = batch.items.paused;
      const items = Object.assign( {}, {
        open: paused + (open || 0),
      }, completed, staged, canceled );
      return update( state, {
        items: {
          [action.batchId]: {
            $merge: {
              items,
            }
          }
        }
      } );
    }

    case PAUSE_CHAIN_BATCH_REQUEST:
      return updateState.fetchRequest(state);
    case PAUSE_CHAIN_BATCH_SUCCESS: {
      const batch = state.items[ action.batchId ];
      const completed = batch.items.completed ? { completed: batch.items.completed } : {};
      const staged = batch.items.staged ? { staged: batch.items.staged } : {};
      const canceled = batch.items.canceled ? { canceled: batch.items.canceled } : {};
      const paused = batch.items.paused;
      const items = Object.assign( {}, {
        paused: batch.items.open + (paused || 0),
      }, completed, staged, canceled );
      return update( state, {
        items: {
          [action.batchId]: {
            $merge: {
              items,
              pauseError: '',
            }
          }
        }
      } );
    }
    case PAUSE_CHAIN_BATCH_FAILURE:
      return update(state, {
        items: {
          [action.batchId]: {
            $merge: {
              pauseError: action.error.msg,
            }
          }
        }
      });
    
    case RESUME_CHAIN_BATCH_REQUEST:
      return updateState.fetchRequest(state);
    case RESUME_CHAIN_BATCH_SUCCESS: {
      const batch = state.items[ action.batchId ];
      const completed = batch.items.completed ? { completed: batch.items.completed } : {};
      const staged = batch.items.staged ? { staged: batch.items.staged } : {};
      const canceled = batch.items.canceled ? { canceled: batch.items.canceled } : {};
      const open = batch.items.open;
      const paused = batch.items.paused;
      const items = Object.assign( {}, {
        open: paused + (open || 0),
      }, completed, staged, canceled );
      return update( state, {
        items: {
          [action.batchId]: {
            $merge: {
              items,
              pauseError: '',
            }
          }
        }
      } );
    }
    case RESUME_CHAIN_BATCH_FAILURE:
      return update(state, {
        items: {
          [action.batchId]: {
            $merge: {
              pauseError: action.error.msg,
            }
          }
        }
      });

    case EXPORT_BATCH_REQUEST:
    case EXPORT_CHAIN_BATCH_REQUEST:
      return updateState.updateRequest(state, action.batchId);
    case EXPORT_BATCH_SUCCESS:
    case EXPORT_CHAIN_BATCH_SUCCESS:
      return updateState.updateSuccess(state, action.batchId, {});
    case EXPORT_CHAIN_BATCH_FAILURE:
      return updateState.updateFailure(state, action.batchId);

    default:
      return state;
  }
}
