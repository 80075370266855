import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';

import objectToArray from '../../utils/_objectToArray';
import { fetchWorkflows, validateWorkflowName, updateWorkflowName } from '../../actions/workflows';
import colors from '../../styles/colors';
import { toggleModal } from '../../actions/app';
import { BATCH_NOTIFICATIONS, ARCHIVE_WORKFLOW } from '../../constants/modalTypes';

import EmptyState from '../../containers/EmptyState';
import HelpPanel from '../../containers/HelpPanel';
import InlineList from '../../components/InlineList';
import IconPanel from '../../components/IconPanel';
import LoadState from '../../containers/LoadState';
import Wrapper from '../../containers/Wrapper';
import { Heading } from '../../components/type';
import WorkflowMetrics from '../../containers/WorkflowMetrics';
import Panel from '../../containers/Panel';

const pageDescription = 'This is your dashboard. Here, you can take important actions such as creating projects, inviting private contributors, and building teams of contributors. This page also displays the five workflows you’ve recently imported batches of work items to.';

/* Helper Methods */
const isWorkflowInProgress = workflow => (workflow.state === 'active' || workflow.state === 'inactive') && !!workflow.itemStatus;

/**
 * Dashboard
 * Index page for logged in users
 */
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workflowsToShow: 5,
    };

    this.sort = this.sort.bind( this );
  }

  componentDidMount() {
    this.props.location.pathname = '/dashboard';
    this.props.dispatch(fetchWorkflows());
  }

  handleMenuOption( option, workflow ) {
    switch ( option ) {
      case 'manage':
        window.location.href = `/workflows/${workflow.id}`;
        break;
      case 'archive':
        this.props.dispatch( toggleModal( ARCHIVE_WORKFLOW, {
          workflowId: workflow.id,
          archive: true
        } ) );
        break;
      case 'batchNote':
        this.props.dispatch( toggleModal( BATCH_NOTIFICATIONS, {
          workflowId: workflow.id,
          enableOverlayClick: true,
          batchNotificationStatus: workflow.batchNotification,
        } ) );
        break;
      default:
        break;
    }
  }

  sort( a, b ) {
    return new Date( b.last_batch ) - new Date( a.last_batch );
  }

  render() {
    const { workflows, loadingWorkflows } = this.props;
    const { workflowsToShow } = this.state;

    return (
      <DocumentTitle title="Dashboard | OneSpace">
        <div className="dashboard" style={styles.dashboard}>
          {/* Help Panel */}
          <HelpPanel
            pageTitle="Dashboard"
            pageDescription={pageDescription}
            links={[
              {
                label: 'Workflow Monitoring: How It Works',
                href: 'https://onespace.helpdocs.com/managing-your-work/workflow-monitoring-how-it-works'
              },
              {
                label: 'Creating a Project',
                href: 'https://onespace.helpdocs.com/getting-started/creating-a-project'
              },
              {
                label: 'Private Contributors: The Basics',
                href: 'https://onespace.helpdocs.com/account-management/private-contributors-the-basics'
              },
              {
                label: 'Creating a Team',
                href: 'https://onespace.helpdocs.com/teams-contributors/creating-a-team'
              },
            ]}
          />

          <div style={styles.wrapper}>
            <Wrapper>
              {/* Page Title */}
              <Heading color="dark" bottom={24}>Welcome to OneSpace</Heading>
              {/* Icon Panels */}
              <div style={styles.cardContainer}>
                <InlineList spacing={30} makeChildrenFill={true}>
                  <div style={styles.iconPanel}>
                    <IconPanel icon="file-o" label="Create a project" href="/projects/new"/>
                  </div>
                  <div style={styles.iconPanel}>
                    <IconPanel
                      icon="user-plus"
                      label="Invite contributors"
                      href="/company#contributors"
                    />
                  </div>
                  <div style={styles.iconPanel}>
                    <IconPanel icon="users" label="Build your team" href="/create-team"/>
                  </div>
                </InlineList>
              </div>

              {/* Recent Workflow */}
              {loadingWorkflows && <LoadState label="Loading your recent workflows..."/>}

              {(!loadingWorkflows && !workflows.length) &&
              <EmptyState
                label="No Workflows"
                description="You don't have any active workflows. Once you create and launch a workflow, you'll be able to see it here."
              />
              }

              {workflows.sort(this.sort).slice(0, workflowsToShow).map(workflow =>
                <div key={workflow.id} style={{marginTop: 16}}>
                  <Panel
                    title={workflow.name}
                    saveTitle={name => this.props.dispatch( updateWorkflowName( name, workflow.id ) )}
                    validateWorkflowName={name => this.props.dispatch( validateWorkflowName( name ) )}
                    options={
                      (workflow.state === 'archived' || workflow.launched === null) ?
                        []
                        :
                        [
                          { label: 'Manage Workflow', value: 'manage' },
                          { label: 'Edit Name', value: 'edit' },
                          { label: 'Archive Workflow', value: 'archive' },
                          {
                            label: `Batch Notifications: ${workflow.batchNotification ? 'On' : 'Off'}`,
                            value: 'batchNote'
                          }
                        ]
                    }
                    handleSelectOption={option => {
                      this.handleMenuOption( option, workflow );
                    }}>
                    <div style={{padding: 16}}>
                      <WorkflowMetrics workflow={workflow} />
                    </div>
                  </Panel>
                </div>
              )}
            </Wrapper>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

/* Styles */
const styles = {
  dashboard: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  cardContainer: {
    marginBottom: 32,
    padding: 16,
    boxSizing: 'border-box',
    border: `1px solid ${colors.border}`,
    borderRadius: 4,
    background: colors.background,
  },
  iconPanel: {
    flex: 1,
  },

  wrapper: {
    flex: 1,
    display: 'flex',
  },
  workflow: {
    marginBottom: 32
  },
};

/* PropTypes */
Dashboard.propTypes = {
  workflows: PropTypes.array.isRequired,
  loadingWorkflows: PropTypes.bool,
};

/* Map State To Props */
function select(state) {
  const workflows = objectToArray(state.workflows.items).filter(isWorkflowInProgress);
  return {
    workflows,
    loadingWorkflows: state.workflows.isFetching && !workflows.length,
  };
}

export default connect(select)(Dashboard);
