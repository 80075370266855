import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import Wrapper from '../../containers/Wrapper';
import { Heading } from '../../components/type';
import colors from '../../styles/colors';
import { connect } from 'react-redux';
import { createChainPlugin, fetchChainPlugin, updateChainPlugin, publishChainPlugin, fetchChainPluginVersion } from '../../actions/chainPlugins';
import { Fields, defaultState } from './PluginSettings/constants';
import LoadState from '../../containers/LoadState';
import Tabs, {Pane} from '../../components/Tabs';
import BreadCrumb from '../../containers/BreadCrumb';
import Settings from './TabContent/Settings';
import VersionHistory from './TabContent/VersionHistory';
import Note from '../../containers/Note';

const getCorrectPluginVersion = (chainPluginVersions, clickedVersion) => {
  const indexKey = chainPluginVersions.findIndex(v => v.version === clickedVersion);
  return !!chainPluginVersions ? chainPluginVersions[indexKey] : null;
};

const blankError = 'There are blank input or output fields, please remove them or provide names to save the plugin.',
      noInputOutputError = 'There must be at least one input and one output in order to save the plugin instance.',
      noNameError = 'The name field is required.',
      noDescriptionError = 'The description field is required.',
      noTypeError = 'The lambda function call(ARN:string) field is required.';
/**
 * Chain Plugin
 * Displays plugin page
 */
class ChainPlugin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chainPluginData: null,
      toggleVersionModal: false,
      clickedVersionData: null,
    };
    this.viewSpecificVersion.bind(this);
    this.handleValidation.bind(this);
  }

  componentDidMount() {
    if(this.props.match.params.plugin_id) {
      this.props.dispatch(fetchChainPlugin(this.props.match.params.plugin_id)).then(res => {
        this.setState({ chainPluginData: res.chainPlugin, serverError: null });
      }).catch((err) => {
        if(err.hasOwnProperty('success') && !err.success) {
          if(err.hasOwnProperty('message')) {
            this.setState({ serverError: err.message });
          }
        } else {
          this.setState({ serverError: 'Oops, something went wrong.' });
        }
      });
    } else {
      this.setState({ chainPluginData: defaultState });
    }
  }

  handleValidation(res) {
    let errorNotesArray = [];
    Object.keys(res).forEach(key => {
      res[key].forEach(note => {
        errorNotesArray.push(note);
      });
    });
    this.setState({notes: errorNotesArray});
  }

  saveValidation(plugin, save) {
    let res = {};
    if(!plugin.name) {
      res.noName = [noNameError];
    }
    if(!plugin.type) {
      res.noType = [noTypeError];
    }
    if(!plugin.description){
      res.noDescription = [noDescriptionError];
    }
    if(!plugin.inputs.length && plugin.inputMappingType === 'global') {
      res.none = [noInputOutputError];
    } else if(!plugin.outputs.length && plugin.outputMappingType === 'global') {
      res.none = [noInputOutputError];
    }
    if(plugin.inputMappingType === 'global') {
      plugin.inputs.forEach(input => {
        if (!input.name) {
          res.blank = [blankError];
        }
      });
    }
    if(plugin.outputMappingType === 'global') {
      plugin.outputs.forEach(output => {
        if (!output.name) {
          res.blank = [blankError];
        }
      });
    }

    if(!Object.keys(res).length) {
      {save === 'save' ? this.saveChainPlugin(plugin) : this.saveAndPublishChainPlugin(plugin);}
    } else {
      this.handleValidation(res);
    }
  }

  saveChainPlugin(plugin) {
    if(!plugin.id) {
      this.props.dispatch(createChainPlugin(plugin)).then((newChainPlugin) => {
        this.setState({ chainPluginData: newChainPlugin.plugin });
        this.goToNewPluginEditPage(newChainPlugin.plugin.id);
      }).catch(err => {
        this.handleValidation(err);
      });
    } else {
      this.props.dispatch(updateChainPlugin(plugin.id, plugin)).then(() => {
        this.setState({ notes: [] });
      }).catch((err) => {
        this.handleValidation(err);
      });
    }
  }

  saveAndPublishChainPlugin(plugin) {
    plugin.version = plugin.version + 1;
    this.props.dispatch(publishChainPlugin(plugin.id, plugin)).then(() => {
      this.setState({ notes: [] });
    }).catch(err => {
      this.handleValidation(err);
    });
  }

  viewSpecificVersion(toggleVersionModal, version) {
    this.setState({ toggleVersionModal, clickedVersion: version });
    if(!version) {
      return;
    }
    if(!getCorrectPluginVersion(this.props.chainPluginVersions, version)._id) {
      this.props.dispatch(fetchChainPluginVersion(this.props.match.params.plugin_id, version)).catch((err) => {
        console.log('error: ', err);
      });
    }
  }

  goToNewPluginEditPage(id) {
    this.props.history.push(`/manage/plugins/${id}`);
  }

  updateChainPlugin(propertyName, update, index) {
    const newState = {...this.state.chainPluginData};
    if(propertyName === 'inputs' || propertyName === 'outputs') {
      this.updateInputsOutputs(propertyName, update, index, newState);
    } else if(propertyName.split('|')[0] === 'typeSettings') {
      this.updateTypeSettings(propertyName, update, index, newState);
    } else {
      newState[propertyName] = update;
    }
    this.setState({ chainPluginData: newState });
  }

  updateInputsOutputs(propertyName, update, index, newState) {
    if(index === null) {
      newState[propertyName].push(update);
    } else if(update === 'removeIO') {
      // Delete input/output
      newState[propertyName].splice(index, 1);
    } else {
      let subPropertyName = update[0];
      newState[propertyName][index][subPropertyName] = update[1];
    }
  }

  handleCloseModal() {
    this.setState({ isClosing: true });
    setTimeout(() => this.props.handleClose(), 300);
  }

  updateTypeSettings(propertyName, update, index, newState) {
    const parentPropertyName = propertyName.split('|')[0];
    const childPropertyName = propertyName.split('|')[1];
    const grandchildPropertyName = propertyName.split('|')[2];
    let newFields;
    if(index === null && update === 'add') {
      newFields = Fields.slice();
      const textField = newFields.find(field => field.type === 'text');
      const clonedTextField = JSON.parse(JSON.stringify(textField));
      if(!newState[parentPropertyName].hasOwnProperty('eventOptions')) {
        newState[parentPropertyName].eventOptions = [];
      }
      newState[parentPropertyName][childPropertyName].push(clonedTextField);
    } else if(Array.isArray(update) && update[0] === 'addChild') {
      /* Add subField to checkbox, dropdown or radio */
      newFields = Fields.slice();
      const getType = update[1];
      const newChildField = newFields.find(field => field.type === getType);
      const clonedNewChildField = JSON.parse(JSON.stringify(newChildField));
      newState[parentPropertyName][childPropertyName][index].data.push(clonedNewChildField);
    } else if(Array.isArray(update) && update[0] === 'refreshChildren') {
      /* This happens when you change the event option type */
      newFields = Fields.slice();
      const getType = update[1];
      const newChildData = newFields.find(field => field.type === getType).data[0];
      const clonedNewChildData = JSON.parse(JSON.stringify(newChildData));
      newState[parentPropertyName][childPropertyName][index].type = getType;
      newState[parentPropertyName][childPropertyName][index].data = [];
      newState[parentPropertyName][childPropertyName][index].data.push(clonedNewChildData);
    } else if(Array.isArray(update) && update[0] === 'removeChild') {
      /* Remove subField from checkbox, dropdown or radio */
      newState[parentPropertyName][childPropertyName][index[0]].data.splice(index[1], 1);
    } else if(update === 'delete') {
      newState[parentPropertyName][childPropertyName].splice(index, 1);
    } else if(grandchildPropertyName) {
      newState[parentPropertyName][childPropertyName][index][grandchildPropertyName] = update;
    } else if(Array.isArray(index)) {
      if(Array.isArray(update)) {
        newState[parentPropertyName][childPropertyName][index[0]].data[index[1]][update[0]] = update[1];
      } else {
        newState[parentPropertyName][childPropertyName][index[0]].data[index[1]].value = update;
      }
    } else {
      newState[parentPropertyName][childPropertyName] = update;
    }
  }

  render() {
    const { chainPluginData, clickedVersion, notes, serverError } = this.state;
    const { savingChain, publishingChain, chainPluginVersions } = this.props;

    if(serverError) {
      return <Note type="error" note={serverError}/>;
    }

    if(!this.state.chainPluginData) {
      return <LoadState/>;
    }

    const liveSettingsLabel = 'Settings';
    const liveSettingsContent = <Settings
      chainPluginData={chainPluginData}
      styles={styles}
      updateChainPlugin={(type, update, index) => this.updateChainPlugin(type, update, index)}
      saveChainPlugin={chainPluginData => this.saveValidation(chainPluginData, 'save')}
      saveAndPublishChainPlugin={chainPluginData => this.saveValidation(chainPluginData, 'saveAndPublish')}
      savingChain={savingChain}
      publishingChain={publishingChain}
      notes={notes}
    />;

    const versionHistoryLabel = 'Version History';
    const versionHistoryContent = <VersionHistory
      chainPluginData={chainPluginData}
      chainPluginVersions={chainPluginVersions || []}
      toggleVersionModal={this.state.toggleVersionModal}
      handleCloseModal={this.handleCloseModal.bind(this)}
      viewVersion={(toggleVersionModal, version) => this.viewSpecificVersion(toggleVersionModal, version)}
      isClosing={this.state.isClosing}
      styles={styles}
      clickedVersionData={getCorrectPluginVersion(chainPluginVersions || [], clickedVersion)}
    />;

    const tabs = [{
      name: liveSettingsLabel,
      content: liveSettingsContent,
    }, {
      name: versionHistoryLabel,
      content: versionHistoryContent,
    }];

    return (
      <DocumentTitle title="Plugin | OneSpace Project Center">
        <div style={{marginTop: 64}}>
          <Wrapper>
            <div>
              <BreadCrumb
                links={[
                  { href: '/manage/plugins', label: 'Plugin Library' },
                  { label: chainPluginData.name },
                ]}
              />
              <div style={styles.headingWrapper}>
                <Heading color="dark">{chainPluginData.name}</Heading>
              </div>
              <div>
                <Tabs
                  selected={0}
                  tabBgColor={colors.background}
                  activeTabColor={colors.light}
                  activeTabBgColor={'#fff'}
                  tabLabelsBgColor={'#fff'}
                  borderColor={colors.border}
                  tabBottomBorder={colors.border}
                  verticalPadding={10}
                  horizontalPadding={20}
                  bold={true}
                  horizontalSpacing={-1}
                >
                  {tabs.map((tab, i) =>
                    <Pane
                      key={i}
                      label={tab.name}
                      contentBorderColor={colors.border}
                      contentPadding={15}
                    >
                      {tab.content}
                    </Pane>)
                  }
                </Tabs>
              </div>
            </div>
          </Wrapper>
        </div>
      </DocumentTitle>
    );
  }
}

const styles = {
  cardContainer: {
    padding: 16,
    boxSizing: 'border-box',
    border: `1px solid ${colors.border}`,
    borderRadius: 4,
    background: colors.background,
  },
  innerContainer: {
    padding: '24px 16px',
    boxSizing: 'border-box',
    border: `1px solid ${colors.border}`,
    borderRadius: 4,
    background: 'white'
  },
  headingWrapper: {
    display: 'flex',
    alignItem: 'flex-end',
    justifyContent: 'space-between',
    paddingBottom: 20
  },
  formField: {
    marginBottom: 20,
  },
  radioBtn: {
    flex: 1,
    width: 15,
    height: 15,
    marginRight: 10,
  },
  labels: {
    marginLeft: 20,
    marginRight: 10,
    position: 'relative',
    top: 1,
  },
};

function select( state, rr ) {
  const { plugin_id } = rr.match.params;
  const chainPlugin = state.chainPlugins.items[plugin_id] && state.chainPlugins.items[plugin_id];
  const hasFetchedChainPlugins = (!state.chainPlugins.isFetching && state.chainPlugins.hasFetched);
  const savingChain = state.chainPlugins.items[plugin_id] && state.chainPlugins.items[plugin_id].isUpdating;
  const publishingChain = state.chainPlugins.items[plugin_id] && state.chainPlugins.items[plugin_id].isCreating;
  const chainPluginVersions = (state.chainPlugins.items[plugin_id] && state.chainPlugins.items[plugin_id].versions) && state.chainPlugins.items[plugin_id].versions;
  return {
    chainPlugin,
    hasFetchedChainPlugins,
    plugin_id,
    savingChain,
    publishingChain,
    chainPluginVersions,
  };
}

export default connect( select )( ChainPlugin );
