import Listener from '../../utils/Listener';
import config from './config';
import uuid from 'uuid';

// Attach $ to window for JSPlumb to work.
if (!window.$) {
  window.$ = require('jquery');
}

/**
 * ChainBuilder
 * Binds a JSPlumb editor to the page
 *
 * Properties:
 * - domNode - Element to attach the editor to
 */
export default class ChainBuilder extends Listener {
  constructor(chain, domNode, workflows) {
    super();
    this.chain = chain;
    this.domNode = domNode; // Element to attach the builder to
    this.listeners = new Map();
    this.workflows = workflows;

    window.jsPlumbToolkit.ready(this.initialize.bind(this));
  }

  initialize() {
    // Setup JSPlumb Editor
    this.toolkit = window.jsPlumbToolkit.newInstance();
    this.instance = window.jsPlumb.getInstance();
    this.renderer = this.toolkit.render({ ...config, container: this.domNode });
    this.renderer.storePositionsInModel();

    /* Use this to enable/disable dragging */
    this.renderer.setElementsDraggable(false);

    // Bind Events
    this.toolkit.bind('dataLoadEnd', this._dataLoadEnd.bind(this));

    // Add initial nodes & edges
    const data = transformForUse(this.chain, this.workflows);
    this.toolkit.load({ data, onload: this.zoom.bind(this) });

    /* If nodePositions available, reposition nodes to correspond with their x, y coordinates */
    if (this.chain.nodePositions.length) {
      this.setNodePositions(this.chain.nodePositions);
    }
  }

  /**
   * Public Methods
   */

  // TODO description
  exportData() {
    return this.toolkit.exportData();
  }

  // TODO description
  zoom() {
    setTimeout(() => {
      this.renderer.zoomToFit();
    });
  }

  // TODO description
  connectNodes(sourceId, targetId) {
    if (sourceId === 'start') {
      return;
    }
    const edge = {
      source: sourceId.toString().replace('ghost-', ''),
      target: targetId.toString(),
      data: { type: 'normal', label: '' }
    };
    this.toolkit.addEdge(edge);
    return this.exportData();
  }

  getNodePositions() {
    const getNodes = this.exportData().nodes;

    let nodesWithPositionsArray = [];
    let newNodeWithPosition = {};
    let position;

    getNodes.forEach((n) => {
      position = this.renderer.getPosition(n.id);
      newNodeWithPosition.node = n.id;
      newNodeWithPosition.left = position[0];
      newNodeWithPosition.top = position[1];
      nodesWithPositionsArray.push(newNodeWithPosition);
      newNodeWithPosition = {};
    });

    return nodesWithPositionsArray;
  }

  setNodePositions(nodePositions) {

    /* List of actual nodes on canvas */
    const canvasNodesWithGhosts = this.exportData().nodes;
    const canvasNodes = canvasNodesWithGhosts;

    /* List of nodes from the chain */
    const nodeList = nodePositions;

    /* Map through chain nodes and make sure each node is in the canvas nodes */
    nodeList.map(n => {
      if (canvasNodes.find(node => node.id === n.node)) {
        this.renderer.setPosition(n.node, n.left, n.top);
      }
    });

    this.zoom();
  }

  /* Validates edges with type: invalid if there are any */
  validateEdge(targetId) {
    const desiredEdge = this.toolkit.getEdges({ target: targetId });
    if (desiredEdge[0].data.type === 'invalid') {
      this.toolkit.updateEdge(desiredEdge[0], { type: 'normal' });
    }
  }

  /* After updateNodes response comes back from Chain Editor trigger revalidateEdges */
  revalidateEdges(edges, validationResult) {
    const erroredTargets = getInvalidNodes(validationResult);
    edges.forEach((edge, i) => {
      if (erroredTargets.includes(edge.target)) {
        const desiredEdge = this.toolkit.getEdges({ target: edge.target });
        this.toolkit.updateEdge(desiredEdge[i], { type: 'invalid' });
      }
    });
  }

  /** JSPlumb Events **/

  // If on manage chains page, remove unnecessary buttons
  _dataLoadEnd() {
    this._toggleChainActions();
  }

  _toggleChainActions() {
    $('.delete, .connection-label').hide();
    $('.chain-lock-overlay').show();
    $('.node-wrap').css('background-image', 'none');
    $('.node-name').css('width', '132px');
  }
}

function handleMergingEdges(destinationEdges, sourceEdges) {
  return [...new Set([...destinationEdges ,...sourceEdges])];
}

function getWorkflowState(node, workflows) {
  return workflows[node.operation.swfWorkflow] ? workflows[node.operation.swfWorkflow.entityId].state : 'static';
}

function backfillStartupNode(startupNode, chain) {

  // Set position of startup node and ghost add workflow node
  chain.nodePositions.unshift(
    { left: -150, node: startupNode.id, top: 0 },
    { left: 0, node: `${'ghost-' + startupNode.id}`, top: -40 }
  );

  // Backfill routes for the startup node
  startupNode.routes.push(
    {
      conditions: [],
      destinationNode: chain.nodes[0] ? `${chain.nodes[0].id}` : `${chain.nodes[1].id}`,
      mapping: {}
    }
  );

  // Backfill the destinations of startup node which is the id of first node
  startupNode.destinations.push(chain.nodes[0] ? `${chain.nodes[0].id}` : `${chain.nodes[1].id}`);

  // Backfill the sources of the originally first node
  if(chain.nodes[0]) {
    chain.nodes[0].sources.push(startupNode.id);
    // Add startup node as first in chain
    chain.nodes.unshift(startupNode);
  } else {
    chain.nodes[1].sources.push(startupNode.id);
    // Replace startup node if null
    chain.nodes[0] = startupNode;
  }
}

/* Utility function */
function transformForUse(chain, workflows) {
  // Return a single ghost node if this is an empty chain
  let startupNode = {
    'operation': {
      'startup': {
        'inputs': [],
        'outputs': [],
      }
    },
    'name': 'Import',
    'id': uuid(),
    'routes': [],
    'destinations': [],
    'sources': []
  };

  // Is there a startup node in the chain already
  const isThereStartupNode = chain.nodes.find(node => !!node && node.operation.startup);

  if (!chain.nodes.length) {
    chain.nodes.push(startupNode);
  } else if (!isThereStartupNode) {
    backfillStartupNode(startupNode, chain);
  }

  const nodes = [];
  const destinationEdges = [];
  const sourceEdges = [];

  chain.nodes.forEach(node => {
    const workflowState = getWorkflowState(node, workflows);
    nodes.push({ id: node.id, name: node.name, state: workflowState, type: node.operation.startup ? 'startup' : 'default' });
    // Loop through each destination,
    // adding an edge between this node and its destination
    node.destinations.forEach(destination => destinationEdges.push({
      source: node.id,
      target: destination,
      data: {
        //TODO MOCK check if this is an invalid source
        type: 'normal',
        // Find the matching route
        route: node.routes.find(route => route.destinationNode === destination)
      }
    }));
    node.sources.forEach((source, i) => {
      if (i > 0) {
        sourceEdges.push({
          source: source,
          target: node.id,
          data: {
            //TODO MOCK check if this is an invalid source
            type: 'normal',
            // Find the matching route
            route: node.routes && node.routes.find(route => route.destinationNode === source)
          }
        });
      }
    });
  });

  // Merge edges and eliminate dupes
  const edges = handleMergingEdges(destinationEdges, sourceEdges);
  invalidateEdges(edges, chain.validationResult);
  return { nodes, edges };
}

/* Turn edges 'invalid' if there are errors in mapping */
function invalidateEdges(edges, validationResult) {
  const erroredTargets = getInvalidNodes(validationResult);
  edges.forEach(edge => {
    if (erroredTargets.includes(edge.target)) {
      edge.data.type = 'invalid';
    }
  });
}

/* Provides ids of invalid edge target ids */
function getInvalidNodes(validationResult = []) {
  let invalidIdsArray = [];

  validationResult.map(error => {
    if (error.type !== 'NoChainNodes') {
      invalidIdsArray.push(error.destination);
    }
  });

  return invalidIdsArray;
}
