import React from 'react';

export default function ({name, image, handleRemove, isTeamTile}) {
  return (
    <div className="to-tile">
      {!isTeamTile &&
        <span className="image" style={{backgroundImage: `url("${image || '/img/placeholder.png'}")`}}/>
      }
      <div className="name">{name}</div>
      <i className="closeX fa fa-times" onClick={handleRemove} />
    </div>
  );
}
