import React from 'react';
import Input from '../../Form/Input';
import colors from '../../../../../../styles/colors';

class SupportRoleOptions extends React.Component {
  render() {
    const { data, updateElement, handleSelectInput } = this.props;
    return (
      <div style={styles.adminArea}>
        <Input
          value={data.class}
          maxWidth="200"
          label="Class Name"
          onChange={className => updateElement({class: className})}
          onFocus={() => handleSelectInput('class')}
        />
        <div>
          <label>
            <input
              style={styles.inputField}
              type="checkbox" 
              checked={data.makeUneditable || false}
              onChange={e => updateElement({makeUneditable: e.target.checked})}
            />
            <span style={styles.labelText}>Make Uneditable
              <span style={styles.supportingText}>(to non-support users)</span>
            </span>
          </label>
        </div>
      </div>
    );
  }
}

const styles = {
  adminArea: {
    backgroundColor: `${colors.divider}`,
    padding: 12,
    borderTop: `1px solid ${colors.border}`,
    borderRadius: 5,
    marginBottom: 16,
  },
  inputField: {
    position: 'relative',
    top: '2',
  },
  labelText: {
    fontSize: 13,
    fontWeight: 600,
  },
  supportingText: {
    fontSize: 11,
    fontWeight: 300,
    fontStyle: 'italic',
    marginLeft: 6,
  },
};

export default SupportRoleOptions;
