import { updateState } from '../utils/reducerHelpers';

import {
  FETCH_CHAIN_WORKFLOWS_REQUEST,
  FETCH_CHAIN_WORKFLOWS_SUCCESS,
  FETCH_CHAIN_WORKFLOWS_FAILURE,
} from '../constants/actionTypes';

const initialState = {
  isFetching: false,
  hasFetched: false,
  items: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CHAIN_WORKFLOWS_REQUEST:
      return updateState.fetchRequest(state);
    case FETCH_CHAIN_WORKFLOWS_SUCCESS:
      return updateState.fetchSuccess(state, action.plugins);
    case FETCH_CHAIN_WORKFLOWS_FAILURE:
      return updateState.fetchFailure(state);

    default:
      return state;
  }
}
