import $ from '../assets/js/utils/jquery-mod';

import fetch, { checkStatus, parseJSON } from '../utils/fetch';
import {
  FETCH_PROJECTS_REQUEST,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_FAILURE,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAILURE,
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,

  FETCH_PROJECT_CHAINS_AND_WORKFLOWS_REQUEST,
  FETCH_PROJECT_CHAINS_AND_WORKFLOWS_SUCCESS,
  FETCH_PROJECT_CHAINS_AND_WORKFLOWS_FAILURE,
  FETCH_PROJECT_WORKFLOWS_REQUEST,
  FETCH_PROJECT_WORKFLOWS_SUCCESS,
  FETCH_PROJECT_WORKFLOWS_FAILURE,
  IMPORT_WORKFLOW_BATCH_FAILURE,
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAILURE,
} from '../constants/actionTypes';

const sortByDate = (a, b) => new Date(b.created_at) - new Date(a.created_at);
export const standardizeWorkflow = (workflow, projectId) => {
  workflow.id = workflow.id.toString();
  workflow.entityId = workflow.entityId.toString();
  workflow.id_project = projectId.toString();
  workflow.name = workflow.label || workflow.name;
  return workflow;
};

export function logErrorMessage(endpoint, data) {
  return () => new Promise((resolve, reject) => {
    fetch(`/api${endpoint}`, 'POST', {data})
      .then(resolve)
      .catch(reject);
  });
}

export function fetchProjects() {
  return {
    types: [FETCH_PROJECTS_REQUEST, FETCH_PROJECTS_SUCCESS, FETCH_PROJECTS_FAILURE],
    fetchRoute: '/api/projects',
    shouldCallAPI: state => !state.projects.hasFetched,
    getFormattedResponse: ({ projects }) => {
      return {
        projects: projects.map(project => {
          delete project.workflows;
          return project;
        }).sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      };
    },
  };
}

export function createProject(name, description) {
  return {
    types: [ CREATE_PROJECT_REQUEST, CREATE_PROJECT_SUCCESS, CREATE_PROJECT_FAILURE ],
    fetchRoute: '/api/projects',
    fetchMethod: 'post',
    fetchBody: { name, description }
  };
}

export function updateProject(id, update) {
  return {
    types: [UPDATE_PROJECT_REQUEST, UPDATE_PROJECT_SUCCESS, UPDATE_PROJECT_FAILURE],
    fetchRoute: `/api/projects/${id}`,
    fetchMethod: 'put',
    payload: { id },
    fetchBody: getState => Object.assign({}, getState().projects.items[id], update),
    getFormattedResponse: (project) => ({ project }),
  };
}

export function fetchProjectWorkflows(projectId) {
  return {
    types: [FETCH_PROJECT_WORKFLOWS_REQUEST, FETCH_PROJECT_WORKFLOWS_SUCCESS, FETCH_PROJECT_WORKFLOWS_FAILURE],
    fetchRoute: `/api/projects/${projectId}/workflows`,
    payload: { id: projectId },
    shouldCallAPI: state => {
      const project = state.projects.items[projectId];
      return !project || !project.workflows || !project.workflows.hasFetched;
    },
    getFormattedResponse: ({ workflows }) => {
      workflows.forEach(wf => {
        if(wf.archived && wf.archived.toString() === '1') {
          wf.state = 'archived';
        }
      });
      return { workflows: workflows.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) };
    }
  };
}


export function fetchProjectChainsAndWorkflows(projectId, filter) {
  return {
    types: [FETCH_PROJECT_CHAINS_AND_WORKFLOWS_REQUEST, FETCH_PROJECT_CHAINS_AND_WORKFLOWS_SUCCESS, FETCH_PROJECT_CHAINS_AND_WORKFLOWS_FAILURE],
    fetchRoute: `/api/projects/${projectId}/chains`,
    payload: { id: projectId, filter },
    getFormattedResponse: ({ chains, workflows }) => {
      const response = {};
      if (filter !== 'workflows') {
        response.chains = chains.sort(sortByDate);
      }
      if (filter !== 'chains') {
        response.workflows = workflows.map(workflow => standardizeWorkflow(workflow, projectId));
      }
      return response;
    },
  };
}

export function deleteProject(id) {
  return {
    types: [DELETE_PROJECT_REQUEST, DELETE_PROJECT_SUCCESS, DELETE_PROJECT_FAILURE],
    fetchRoute: `/api/projects/${id}`,
    fetchMethod: 'delete',
    payload: { id },
    shouldCallApi: state => !!state.projects.items[id],
  };
}

export function authorizeChainUpload(projectId, fileName) {
  return () => new Promise((resolve, reject) => {
    fetch(`/api/project/${projectId}/authorize-upload?fileName=${fileName}`, 'get')
      .then(checkStatus)
      .then(parseJSON)
      .then(resolve)
      .catch(reject);
  });
}

export function authorizeWorkflowUpload(workflowId, tempId, fileName) {
  return dispatch => new Promise((resolve, reject) => {
    $.ajax({
      method: 'GET',
      url: `/api/workflow/${workflowId}/authorize-upload?fileName=${fileName}`,
      success: resolve,
      error: res => {
        dispatch({type: IMPORT_WORKFLOW_BATCH_FAILURE, tempId, error: res.responseJSON.error});
        return reject;
      }
    });
  });
}

export function uploadChainBatch(file, projectId) {
  return dispatch => new Promise((resolve, reject) => {
    dispatch(authorizeChainUpload(projectId, file.name)).then(data => {
      const s3Form = getS3UploadForm(data, file);
      $.ajax({
        xhr: () => new window.XMLHttpRequest(),
        method: 'POST',
        contentType: false,
        processData: false,
        url: '//os-client-upload.s3-accelerate.amazonaws.com/',
        data: s3Form,
        success: () => resolve(Object.assign({}, {
          filename: data.prefix + file.name,
        }, data)),
        error: xhr => {
          reject(xhr);
        },
      });
    });
  });
}

export function uploadWorkflowBatch(file, workflowId, tempId) {
  return dispatch => new Promise((resolve, reject) => {
    dispatch(authorizeWorkflowUpload(workflowId, tempId, file.name)).then(data => {
      const s3Form = getS3UploadForm(data, file);
      $.ajax({
        xhr: () => new window.XMLHttpRequest(),
        method: 'POST',
        contentType: false,
        processData: false,
        url: '//os-client-upload.s3-accelerate.amazonaws.com/',
        data: s3Form,
        success: () => resolve(Object.assign({}, {
          filename: data.prefix + file.name,
        }, data)),
        error: xhr => {
          reject(xhr);
        },
      });
    })
      .catch(() => {
        return reject;
      });
  });
}

function getS3UploadForm(data, file) {
  const form = new FormData();
  form.append('acl', 'public-read');
  form.append('key', data.fileKey);
  form.append('policy', data.ticket.formInputs['Policy']);
  form.append('X-Amz-Signature', data.ticket.formInputs['X-Amz-Signature']);
  form.append('X-Amz-Algorithm', data.ticket.formInputs['X-Amz-Algorithm']);
  form.append('X-Amz-Date', data.ticket.formInputs['X-Amz-Date']);
  form.append('X-Amz-Credential', data.ticket.formInputs['X-Amz-Credential']);
  form.append('X-Amz-Security-Token', data.ticket.formInputs['X-Amz-Security-Token']);
  form.append('content-type', data.contentType);
  form.append('file', file);
  return form;
}