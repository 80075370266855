import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '../../components/Button';
import InlineButton from '../../components/InlineButton';
import InputLabel from '../../components/InputLabel';
import FlatButton from '../../components/FlatButton';
import ItemList from '../../components/ItemList';
import { Attention, Label, Caption } from '../../components/type';
import colors from '../../styles/colors';

import { createProject } from '../../actions/projects';

class CreateChain extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      allCompanyProjects: false,
      createChainMenu: false,
      createProjectMenu: false,
      projectName: '',
      projectDesc: '',
      creatingProject: false,
    };

    this.shouldShowProject = this.shouldShowProject.bind( this );
  }

  shouldShowProject( project ) {
    const manager = project.id_project_manager.toString() === this.props.currentUserId.toString();
    const allCompany = this.state.allCompanyProjects;
    return allCompany || manager;
  }

  getMenuOptions() {
    const allProjects = {
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="checkbox"
            checked={this.state.allCompanyProjects}
            style={{ marginTop: 0, marginRight: 4 }}
          />
          View All Company Projects
        </div>
      ),
      fixed: true,
      value: 'all',
      outside: true,
    };
    let projects = this.props.projects.filter( this.shouldShowProject )
      .sort( ( a, b ) => {
        return new Date( b.created_at ) - new Date( a.created_at );
      } ).map( p => {
        return {
          label: p.name,
          value: p.id,
        };
      } );
    return [ allProjects, ...projects ];
  }

  createProject( projectName, projectDesc ) {
    let nameError = '';
    let descriptionError = '';
    this.props.dispatch( createProject( projectName, projectDesc ) ).then( res => {
      if ( !!res.success ) {
        window.location.href = `/projects/${res.project.id}/create-workflow`;
      } else {
        if ( res.name ) {
          nameError = res.name[ 0 ];
        }
        if ( res.description ) {
          descriptionError = res.description[ 0 ];
        }
        this.setState( { projectNameError: nameError, projectDescError: descriptionError, creatingProject: false } );
      }
    } );
  }

  render() {
    const {
      createChainMenu,
      createProjectMenu,
      projectName,
      projectNameError,
      projectDesc,
      projectDescError,
      creatingProject,
    } = this.state;

    return (
      <div style={{ position: 'relative' }}>
        <Button
          handleClick={() => {
            if ( createProjectMenu ) {
              this.setState( { createProjectMenu: !createProjectMenu } );
            } else {
              this.setState( { createChainMenu: !createChainMenu } );
            }
          }
          }>
          Create a Workflow Chain
        </Button>
        {createChainMenu &&
        <div style={styles.createChainContainer}>
          <div style={styles.centerFlex}>
            <div style={styles.createProjectOptions}>
              <Label color="light">SELECT A PROJECT</Label>
              <div style={{ padding: '0px 10px' }}>
                <Caption italic={true}>or</Caption>
              </div>
              <InlineButton handleClick={() => this.setState( { createChainMenu: false, createProjectMenu: true } )}>
                Create a New Project
              </InlineButton>
            </div>
          </div>
          <ItemList
            items={this.getMenuOptions()}
            handleSelect={( val ) => {
              if ( val === 'all' ) {
                this.setState( { allCompanyProjects: !this.state.allCompanyProjects } );
              } else {
                this.props.handleCreateChain( val );
              }
            }}
            handleClose={( e ) => {
              if ( e.srcElement.innerText !== 'Create a New Project' ) {
                this.setState( { createProjectMenu: false, createChainMenu: false } );
              }
            }}
            smallList={true}/>
        </div>
        }
        {createProjectMenu &&
        <div style={styles.createProjectContainer}>
          <Attention color="dark">Create Your Project</Attention>
          <InputLabel
            value={projectName}
            error={projectNameError}
            label="Name"
            handleChange={name => this.setState( { projectName: name } )}/>
          <InputLabel
            value={projectDesc}
            error={projectDescError}
            label="Description"
            handleChange={desc => this.setState( { projectDesc: desc } )}/>
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <InlineButton
              loading={creatingProject}
              disabled={creatingProject}
              handleClick={
                () => this.setState( { creatingProject: true }, this.createProject( projectName, projectDesc ) )
              }>
              Save
            </InlineButton>
            <div style={{ paddingLeft: 16 }}>
              <FlatButton
                underline={true}
                handleClick={() => this.setState( {
                  createChainMenu: !createChainMenu,
                  createProjectMenu: !createProjectMenu,
                  projectName: '',
                  projectDesc: '',
                } )
                }>
                Cancel
              </FlatButton>
            </div>
          </div>
        </div>
        }
      </div>
    );
  }
}

const styles = {
  createChainContainer: {
    position: 'absolute',
    zIndex: 1,
    minWidth: 345,
    right: 0,
    marginTop: 5,
    overflow: 'auto',
    border: `1px solid ${colors.border}`,
    borderRadius: 5,
    boxShadow: '0 5px 10px rgba(0,0,0,.2)',
    background: 'white',
  },
  createProjectContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: 0,
    marginTop: 5,
    background: 'white',
    border: `1px solid ${colors.border}`,
    boxShadow: '0 5px 10px rgba(0,0,0,.2)',
    borderRadius: 5,
    width: 400,
    zIndex: 1,
    padding: 16,
  },
  createProjectOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 16px',
  },
  centerFlex: {
    display: 'flex',
    justifyContent: 'center',
    borderBottom: `1px solid ${colors.border}`
  },
};

CreateChain.defaultProps = {
  handleCreateChain: () => {
  },
};

CreateChain.propTypes = {
  currentUserId: PropTypes.number,
  projects: PropTypes.arrayOf( PropTypes.object ),
};

export default CreateChain;