import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import colors from '../styles/colors';
import { Attention } from './type';

/**
 * Button
 * Large button used as primary CTA
 */
const Button = ({ disabled, handleClick, loading, children, height }) => {
  return (
  <div className={css(styles.button, disabled && styles.disabled)} style={{height: height ? height : 40}}>
    <Attention
      color={!disabled ? 'white' : 'lighter'}
      handleClick={!disabled && handleClick}
      >
      {loading && <i className="fa fa-spinner fa-spin"/>}
      <span style={{ marginLeft: 5 }}>{children}</span>
    </Attention>
  </div>
);};

/* Styles */
const styles = StyleSheet.create({
  button: {
    display: 'inline-flex',
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 8,
    background: colors.primary(),
  },
  disabled: {
    background: colors.background,
  }
});

/* PropTypes */
Button.propTypes = {
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
  loading: PropTypes.bool,
  children: PropTypes.node, // Button label
  height: PropTypes.number,
};

Button.defaultProps = {
  loading: false,
  handleClick: () => {},
};

export default Button;
