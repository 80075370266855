import React, { Component } from 'react';
import { css, StyleSheet } from 'aphrodite';

import { MediumLabel } from './type';
import colors from '../styles/colors';
import FacetList from './FacetList';


const getMultipleOptions = ( selected, options, multiSelect ) => {
  return options.map( option => ({
    value: option.value,
    staticLabel: option.label,
    fixed: option.fixed,
    outside: option.outside,
    bold: option.bold,
    label: multiSelect
      ? (
        <ItemListLabel
          option={option}
          selected={selected && !!selected.find(value => value === option.value)}
        />
      )
      : (
        <ItemListLabel
          option={option}
          selected={selected === option.value}
        />
      ),
  }) );
};


const ItemListLabel = ( { option, selected } ) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <input
      type="checkbox"
      checked={selected}
      readOnly={true}
      style={{ marginRight: 4, marginTop: 0 }}
    />
    <span>&nbsp;{option.label}</span>
  </div>
);

class FacetItem extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      expanded: props.expanded ? props.expanded : false,
    };

    this.toggleFacet = this.toggleFacet.bind( this );
  }

  toggleFacet() {
    this.setState( ( prevState ) => {
      return { expanded: !prevState.expanded };
    } );
  }

  render() {
    const { expanded } = this.state;
    const { facet, titleStyle } = this.props;
    const icon = expanded ? 'chevron-up' : 'chevron-down';

    return (
      <div className={css(styles.item)}>
        <div className={css(styles.facetTitle, expanded && styles.noBorderBottom)} style={titleStyle}
             onClick={this.toggleFacet}>
          <MediumLabel color={expanded ? 'primary' : 'dark'} hoverColor="primary" isLink={true}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              {facet.name}
              <i className={`fa fa-${icon}`}/>
            </div>
          </MediumLabel>
        </div>
        {expanded &&
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <FacetList
            items={getMultipleOptions(facet.selected, facet.options, facet.multiple)}
            itemType={facet.name}
            hideSearch={facet.hideSearch}
            handleSelect={facet.handleSelect}/>

        </div>
        }
      </div>
    );
  }
}

const styles = StyleSheet.create( {
  facetTitle: {
    padding: '10px 16px',
    ':hover': {
      cursor: 'pointer',
      color: colors.primary(),
    }
  },
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  icon: {
    marginLeft: 8,
    fontSize: 12,
    textDecoration: 'none',
  },
  noBorderBottom: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottom: `1px solid ${colors.border}`,
  }
} );
export default FacetItem;