import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { parse, stringify } from 'qs';

import stateHelpers from '../../utils/stateHelpers';
import objectToArray from '../../utils/_objectToArray';
import { fetchProjects } from '../../actions/projects';
import { createChain, fetchChains } from '../../actions/chains';
import { fetchWorkflows } from '../../actions/workflows';

import LoadState from '../../containers/LoadState';
import Wrapper from '../../containers/Wrapper';
import HelpPanel from '../../containers/HelpPanel';
import { Heading, Subheading, ManagerAttention, Caption } from '../../components/type';
import Panel from '../../containers/Panel';
import Filter from '../../components/Filter';
import Facet from '../../components/Facet';
import colors from '../../styles/colors';

import Chain from './Chain';
import CreateChain from './CreateChain';
import ChainNavButtons from './ChainNavButtons';
import EmptyChainPanel from './EmptyChainPanel';
import EmptyChainManager from './EmptyChainManager';
import ArchivedChainPanel from './ArchivedChainPanel';
import { ARCHIVE_CHAIN } from '../../constants/modalTypes';
import { UNARCHIVE_CHAIN, BATCH_NOTIFICATIONS } from '../../constants/modalTypes';
import { toggleModal } from '../../actions/app';


const pageDescription = `This page allows you to access all of your workflow chains regardless of status. 
Browse through the list of workflow chains and use the filters in the rail to find the exact chain you want. 
You can also create a new workflow chain from this page or easily access the monitoring, editing and upload 
activities for active chains.`;
const helpPanelLinks = [
  {
    label: 'Workflow Chains: The Basics',
    href: 'https://onespace.helpdocs.com/setting-up-workflow-chains/workflow-chains-the-basics'
  },
  {
    label: 'Chaining Workflows: How It Works',
    href: 'https://onespace.helpdocs.com/setting-up-workflow-chains/chaining-workflows-how-it-works'
  },
  {
    label: 'Launching a Workflow Chain',
    href: 'https://onespace.helpdocs.com/setting-up-workflow-chains/launching-a-workflow-chain'
  },
];

const statusFilters = {
  active: [ 'active' ],
  inactive: [ 'inactive' ],
  archived: [ 'archived' ],
};

const sortByCreatedAt = ( a, b ) => new Date( b.created_at ) - new Date( a.created_at );

export class ChainManager extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      isCreatingProject: false,
      showProjectPopover: false,
      currentProject: null,
      selectedProjects: [],
      selectedStatus: [],
      selectedSort: 'last-created',
      allCompanyProjects: true,
    };
    this.shouldShowProject = this.shouldShowProject.bind( this );
    this.toggleAllCompanyProjects = this.toggleAllCompanyProjects.bind( this );
    this.sort = this.sort.bind( this );
    this.filter = this.filter.bind( this );
    this.handleCheckingFilters = this.handleCheckingFilters.bind( this );
    this.handleSelect = this.handleSelect.bind( this );
  }

  componentDidMount() {
    this.props.dispatch( fetchProjects() );
    this.props.dispatch( fetchChains(true) );
    this.props.dispatch( fetchWorkflows() );

    this.handleCheckingFilters();
  }

  componentDidUpdate({}, prevState) {
    if (
      this.state.selectedProjects.length !== prevState.selectedProjects.length ||
      this.state.selectedStatus.length !== prevState.selectedStatus.length ||
      this.state.selectedSort !== prevState.selectedSort
    ) {
      this.handleUrlParams();
    }
  }

  handleUrlParams() {
    const { history, location } = this.props;
    const { selectedStatus, selectedProjects, selectedSort } = this.state;
    /* When any of the filters/facets changed, match url params with new changes
     * Whenever new filters or anything added, make sure they get added to query below. */
    history.replace({
      pathname: `${location.pathname}`,
      search: `?sort=${selectedSort}&${stringify({ status: selectedStatus, projects: selectedProjects })}`,
      query: {status: selectedStatus, projects: selectedProjects}
    });
  }

  handleCheckingFilters() {
    const { location } = this.props;
    const newLocationQuery = parse(location.search, {ignoreQueryPrefix: true});
    /* If these query properties exist, use them and push them into a state which automagically
     * checks what's needed. If a query property has only one item, it's a string so I'm checking
     * for that and turning it into an array with one object. */
    const queryProjects = newLocationQuery.projects ?
      Array.isArray( newLocationQuery.projects ) ? newLocationQuery.projects : [ newLocationQuery.projects ] :
      ['all'];
    const queryStatus =
      newLocationQuery.status ?
        Array.isArray( newLocationQuery.status ) ? newLocationQuery.status : [ newLocationQuery.status ] :
        [ 'active', 'inactive' ];

    this.setState(
      {
        selectedProjects: queryProjects,
        selectedStatus: queryStatus,
        selectedSort: newLocationQuery.sort ? newLocationQuery.sort : 'last-created',
      }
    );
  }

  handleCreateChain( projectId ) {
    const newProject = this.props.projects.find(proj => proj.id === projectId);
    this.props.dispatch( createChain( {
      name: `${newProject.name} - ${new Date().toLocaleString()}`,
      projectId,
    } ) ).then( ( { chain } ) => {
      this.setState( { isCreatingProject: false } );
      window.location = `/projects/${projectId}/chains/${chain.id}`;
    } );

  }

  shouldShowProject( project ) {
    const manager = project.id_project_manager.toString() === this.props.currentUserIntId.toString();
    return this.state.allCompanyProjects || manager;
  }

  toggleAllCompanyProjects() {
    this.setState( { allCompanyProjects: !this.state.allCompanyProjects } );
  }

  sort( a, b ) {
    const selectedSort = this.state.selectedSort;
    if ( selectedSort === 'last-created' ) {
      return new Date( b.createdAt ) - new Date( a.createdAt );
    }
    if ( selectedSort === 'a-z' ) {
      return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    }
    if ( selectedSort === 'z-a' ) {
      return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1;
    }
    throw new Error( `We are not handling ${selectedSort} as a sort` );
  }

  filter( chain ) {
    const { selectedProjects, selectedStatus } = this.state;
    const projectsFilter = selectedProjects.filter( s => s !== 'all' );
    if ( projectsFilter.length && !projectsFilter.includes( chain.projectId.toString() ) ) {
      return false;
    }

    let temp = false;
    selectedStatus.forEach( s => {
      if ( statusFilters[ s ].includes( chain.isActive ? 'active' : chain.archived ? 'archived' : 'inactive' ) ) {
        temp = true;
      }
    } );

    if ( selectedStatus.length > 0 && !temp ) {
      return false;
    }

    if ( !this.state.allCompanyProjects && chain.createdBy.toString() !== this.props.currentUserId.toString() ) {
      return false;
    }
    return true;
  }

  handleSelect(chain, option) {
    switch(option) {
      case 'archive':
        return this.props.dispatch(toggleModal(ARCHIVE_CHAIN, {
          chainId: chain.id,
          projectId: chain.projectId,
          archive: true,
          enableOverlayClick: true,
        } ) );
      case 'unarchive':
        return this.props.dispatch(toggleModal(UNARCHIVE_CHAIN, {
          chainId: chain.id,
          projectId: chain.projectId,
          archive: false,
          enableOverlayClick: true,
        } ) );
      case 'batchNote':
        return this.props.dispatch(toggleModal(BATCH_NOTIFICATIONS, {
          enableOverlayClick: true,
          chainId: chain.id,
          batchNotificationStatus: chain.batchNotification,
        }));
      default:
        break;
    }
  }

  render() {
    const { projects, chains, workflows, location } = this.props;
    const { isLoadingProjects, selectedProjects, selectedSort, selectedStatus } = this.state;
    const newLocationQuery = parse(location.search, {ignoreQueryPrefix: true});
    const viewableProjects = projects.filter( this.shouldShowProject ).sort( sortByCreatedAt );
    const chainsDisplayed = chains.filter( this.filter ).sort( this.sort );
    const hasChains = !!chains.length;
    const noChainTextHeader = 'It looks like you do not have any workflow chains.';
    const noChainTextBody = 'Create a new workflow chain by clicking the button above or adjust your ' +
      'filters in the rail.';

    if ( this.props.isLoadingChains || this.props.isLoadingProjects || this.props.isLoadingWorkflows ) {
      return <LoadState label="Loading Workflow Chains" top={50} />;
    }

    return (
      <DocumentTitle title="Workflow Chains | OneSpace Project Center">
        <div className="chains chains-manager">

          <HelpPanel
            pageTitle="Workflow Chains"
            pageDescription={pageDescription}
            links={helpPanelLinks}
          />
          <Wrapper>
            {/* Page Title */}
            <Heading color="dark" bottom={20}>Workflow Chains</Heading>

            <div style={styles.workflowCount}>
              <Subheading color="dark">{chainsDisplayed.length} Chains</Subheading>
              <CreateChain
                dispatch={this.props.dispatch}
                projects={projects}
                currentUserId={this.props.currentUserIntId}
                handleCreateChain={projectId => this.handleCreateChain( projectId )}/>
            </div>

            {/* Selected Project Panel */}

            <div style={{ display: 'flex' }}>
              {isLoadingProjects ?
                <div style={{ flex: 1, paddingRight: 8, maxWidth: '33%' }}>
                  <LoadState/>
                </div>
                :
                <div style={{ flex: 1, paddingRight: 8, maxWidth: '33%' }}>
                  <Facet
                    facets={[
                      {
                        name: 'Project',
                        multiple: true,
                        itemType: 'Project Name',
                        options: [
                          {
                            label: 'View All Company Projects',
                            fixed: true,
                            value: 'all',
                            outside: true,
                            bold: true,
                          },
                          ...viewableProjects.map( project => ({
                            label: project.name,
                            value: project.id.toString(),
                          }) )
                        ],
                        expanded: !!newLocationQuery.projects,
                        handleSelect: projectId => {
                          const selected = [ ...selectedProjects ];
                          let i;
                          (i = selectedProjects.indexOf( projectId )) < 0 ?
                            selected.push( projectId ) : selected.splice( i, 1 );
                          this.setState( { selectedProjects: selected } );
                          if ( projectId === 'all' ) {
                            this.toggleAllCompanyProjects();
                          }
                        },
                        selected: selectedProjects,
                      },
                      {
                        name: 'Status',
                        hideSearch: true,
                        multiple: true,
                        options: [
                          { label: 'Active', value: 'active' },
                          { label: 'Inactive', value: 'inactive' },
                          { label: 'Archived', value: 'archived' }
                        ],
                        expanded: !!newLocationQuery.status,
                        handleSelect: selected => {
                          const statuses = [ ...selectedStatus ];
                          let i;
                          (i = selectedStatus.indexOf( selected )) < 0 ?
                            statuses.push( selected ) : statuses.splice( i, 1 );
                          this.setState( { selectedStatus: statuses } );
                        },
                        selected: selectedStatus,
                      }
                    ]}
                  />
                </div>
              }

              <div style={{ display: 'flex', flexDirection: 'column', flex: 2, paddingLeft: 8 }}>
                <div style={styles.sortFilter}>
                  <Filter
                    name="Sort"
                    options={
                      [
                        { label: 'Last Created', value: 'last-created' },
                        { label: 'A to Z', value: 'a-z' },
                        { label: 'Z to A', value: 'z-a' }
                      ]
                    }
                    handleSelect={ selectedSort => this.setState( { selectedSort } ) }
                    selected={selectedSort}/>
                </div>
                {/* Chain List */}
                {!hasChains &&
                  <EmptyChainManager/>
                }
                {(chainsDisplayed.length === 0 && hasChains) ?
                  <div style={{paddingTop: 24}}>
                    <ManagerAttention color="dark" center={true}>{noChainTextHeader}</ManagerAttention>
                    <Caption color="dark" center={true}>{noChainTextBody}</Caption>
                  </div>
                  :
                  chainsDisplayed.map( chain => {
                  return (
                    <div key={chain.id} style={{ marginTop: 16 }} className="chain-manager-container">
                      <Panel
                        title={chain.name}
                        options={
                          (!!chain.archived) ? [] :
                          [
                            { label: 'Archive', value: 'archive' },
                            { label: `Batch Notifications: ${chain.batchNotification ? 'On' : 'Off'}`, value: 'batchNote'}
                          ]
                        }
                        handleSelectOption={ (option) => this.handleSelect(chain, option) }>
                        { !!chain.archived ?
                          <div style={{ height: 200, background: colors.shaded }}>
                            <ArchivedChainPanel
                              unarchive={() => this.handleSelect(chain, 'unarchive')}
                            />
                            <ChainNavButtons projectId={chain.projectId} chainId={chain.id} archived={chain.archived} />
                          </div>
                          :
                          (!chain.nodes.length || (chain.nodes[0] && !!chain.nodes[0].operation.startup && chain.nodes.length === 1)) ?
                            <EmptyChainPanel projectId={chain.projectId} chainId={chain.id} />
                            :
                            <div>
                              <div className="chainDisplay">
                                <Chain
                                  workflows={workflows}
                                  chain={chain}
                                  archived={chain.archived}
                                  handleClick={() => {
                                    if(!chain.archived) {
                                      window.location = `/projects/${chain.projectId}/chains/${chain.id}`;
                                      this.props.history.push(location);
                                    }
                                  }}/>
                              </div>
                              <ChainNavButtons projectId={chain.projectId} chainId={chain.id} archived={chain.archived} />
                            </div>
                        }
                      </Panel>
                    </div>
                  );
                } )}

              </div>
            </div>
          </Wrapper>
        </div>
      </DocumentTitle>
    );
  }
}

const styles = {
  workflowCount: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 16,
    height: 75,
    background: colors.background,
    paddingLeft: 16,
    paddingRight: 16,
    boxSizing: 'border-box',
    borderRadius: 5,
    alignItems: 'center'
  },
  sortFilter: {
    border: `1px solid ${colors.border}`,
    borderLeft: 'none',
    borderRight: 'none',
    padding: '16px 0px',
  },
  emptyChainsContainer: {
    border: `2px solid ${colors.divider}`,
    marginTop: 16,
    background: colors.light,
    height: 200,
    display: 'flex',
    flex: 6,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0 0 8px 8px',
  },
  emptyChainsContent: {
    background: colors.dark,
    height: 160,
    width: 300,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 5,
    flexDirection: 'column',
  },
};

function isLoadingChains( state, rr ) {
  const project = state.projects.items[ parse(rr.location.search, {ignoreQueryPrefix: true}).project ];
  if ( !project || !project.chains ) {
    return false;
  }
  return project.chains.isFetching;
}
function select( state, rr ) {
  const projectId = rr.match.params.project_id;
  return {
    currentUserId: state.app.user,
    currentUserIntId: stateHelpers.getUser( state ).id,
    isLoadingProjects: state.projects.isFetching,
    isLoadingChains: isLoadingChains( state, rr ),
    isLoadingWorkflows: state.workflows.isFetching,
    projects: objectToArray( state.projects.items ).filter( p => p.id ),
    workflows: state.workflows.items,
    chains: objectToArray( state.chains.items ),
    selectedProjectId: projectId,
    history: rr.history
  };
}

export default connect( select )( ChainManager );
