import React from 'react';
import Input from '../../../../components/Input';
import { Label } from '../../../../components/type';
import InlineList from '../../../../components/InlineList';
import IconButton from '../../../../components/IconButton';

const Checkbox = ({ data, styles, updateChainPlugin, index, readOnly }) => (
  <div style={{marginBottom: 30}}>
    <InlineList>
      <div style={{ flex: 2, paddingRight: 30 }}>
        <Label color="light">Label</Label>
      </div>
      <div style={{ flex: 2, paddingRight: 30 }}>
        <Label color="light">Value</Label>
      </div>
      <div style={{ flex: 1 }}>
        <Label color="light">Default</Label>
      </div>
    </InlineList>
    {data.map((d, idx) => {
      return (
        <div key={idx} style={{ position: 'relative' }}>
          <InlineList>
            <div style={{ flex: 2, paddingRight: 30 }}>
              <Input
                disabled={readOnly}
                value={d.label}
                handleChange={val => {
                  !readOnly ?
                    updateChainPlugin('typeSettings|eventOptions', ['label', val], [index, idx]) :
                    () => {};
                }}
              />
            </div>
            <div style={{ flex: 2, paddingRight: 30 }}>
              <Input
                disabled={readOnly}
                value={d.value}
                handleChange={val => {
                  !readOnly ?
                    updateChainPlugin('typeSettings|eventOptions', ['value', val], [index, idx]) :
                    () => {};
                }}
                unallowedCharacters={[32]}
              />
            </div>
            <div style={{ flex: 1 }}>
              <div style={styles.formField}>
                <div style={{ position: 'relative', top: 6, width: 15, marginLeft: 16 }}>
                  <Input
                    type="checkbox"
                    disabled={readOnly}
                    checked={d.default}
                    label={d.default}
                    value={d.default}
                    handleChange={() => {
                      !readOnly ?
                        updateChainPlugin('typeSettings|eventOptions', ['default', !d.default], [index, idx]) :
                        () => {};
                    }}
                  />
                </div>
              </div>
            </div>
          </InlineList>
          {!readOnly &&
            <div style={{ position: 'absolute', right: 0, top: 20 }}>
              <IconButton
                icon="minus-square"
                label="Add"
                handleClick={() => {
                  !readOnly ?
                  updateChainPlugin('typeSettings|eventOptions', ['removeChild'], [index, idx]) :
                    () => {};
                }}>
                <span style={{ textDecoration: 'underline !important' }}>Remove</span>
              </IconButton>
            </div>
          }
        </div>
      );
    })}
  </div>
);

export default Checkbox;

