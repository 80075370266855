import {
  FETCH_ALL_RELEASE_NOTES_REQUEST,
  FETCH_ALL_RELEASE_NOTES_SUCCESS,
  FETCH_ALL_RELEASE_NOTES_FAILURE,
  FETCH_RELEASE_NOTE_REQUEST,
  FETCH_RELEASE_NOTE_SUCCESS,
  FETCH_RELEASE_NOTE_FAILURE,
  CREATE_NEW_RELEASE_NOTE_REQUEST,
  CREATE_NEW_RELEASE_NOTE_SUCCESS,
  CREATE_NEW_RELEASE_NOTE_FAILURE,
  UPDATE_RELEASE_NOTE_REQUEST,
  UPDATE_RELEASE_NOTE_SUCCESS,
  UPDATE_RELEASE_NOTE_FAILURE,
  FETCH_LATEST_RELEASE_NOTES_REQUEST,
  FETCH_LATEST_RELEASE_NOTES_SUCCESS,
  FETCH_LATEST_RELEASE_NOTES_FAILURE,
  DELETE_RELEASE_NOTE_REQUEST,
  DELETE_RELEASE_NOTE_SUCCESS,
  DELETE_PROJECT_FAILURE,
} from '../constants/actionTypes';

export function fetchAllReleaseNotes() {
  return {
    types: [FETCH_ALL_RELEASE_NOTES_REQUEST, FETCH_ALL_RELEASE_NOTES_SUCCESS, FETCH_ALL_RELEASE_NOTES_FAILURE],
    fetchRoute: '/manage/release-notes',
    fetchMethod: 'get',
  };
}

export function fetchReleaseNote(releaseNoteId) {
  return {
    types: [FETCH_RELEASE_NOTE_REQUEST, FETCH_RELEASE_NOTE_SUCCESS, FETCH_RELEASE_NOTE_FAILURE],
    fetchRoute: `/manage/release-notes/${releaseNoteId}`,
    fetchMethod: 'get',
  };
}

export function createReleaseNote(title, body, date) {
  return {
    types: [CREATE_NEW_RELEASE_NOTE_REQUEST, CREATE_NEW_RELEASE_NOTE_SUCCESS, CREATE_NEW_RELEASE_NOTE_FAILURE],
    fetchRoute: '/manage/release-notes',
    fetchMethod: 'post',
    fetchBody: {title, body, date},
  };
}

export function updateReleaseNote(releaseNoteId, title, body, date, deactivate = false) {
  return {
    types: [UPDATE_RELEASE_NOTE_REQUEST, UPDATE_RELEASE_NOTE_SUCCESS, UPDATE_RELEASE_NOTE_FAILURE],
    fetchRoute: `/manage/release-notes/${releaseNoteId}`,
    fetchMethod: 'put',
    fetchBody: {title, body, date, deactivate},
  };
}

export function fetchLatestReleaseNotes() {
  return {
    types: [FETCH_LATEST_RELEASE_NOTES_REQUEST, FETCH_LATEST_RELEASE_NOTES_SUCCESS, FETCH_LATEST_RELEASE_NOTES_FAILURE],
    fetchRoute: '/api/release-note/latest',
    fetchMethod: 'get',
  };
}

export function deleteReleaseNote(releaseNoteId){
  return {
    types: [DELETE_RELEASE_NOTE_REQUEST, DELETE_RELEASE_NOTE_SUCCESS, DELETE_PROJECT_FAILURE],
    fetchRoute: '/manage/release-notes',
    fetchMethod: 'delete',
    fetchBody: {releaseNoteId},
  };
}