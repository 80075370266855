import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

import Input from './Input';

const propTypes = {
  label: PropTypes.string
};

class Checkbox extends Component {
  render() {
    const {disabled, label, value, selected, checked, onChangeLabel, onChangeValue, onDelete, onSelect, onFocus, radio} = this.props;
    return (
      <div style={styles.main}>
        <input
          disabled={disabled}
          checked={checked || false}
          type={radio ? 'radio' : 'checkbox'}
          onClick={e => onSelect(e.target.checked)}
          style={styles.checkbox}
        />
        <div style={[styles.label, !selected && styles.full]}>
          {selected ? 
          <Input
            value={label}
            placeholder={radio ? 'Radio Button Label' : 'Checkbox Label'}
            isInline={!selected}
            isCollapsed={true}
            onChange={l => onChangeLabel(l)}
            onFocus={() => onFocus('label')}
            maxLength={150}
          />
          :
          <div>{label}</div>
          }
        </div>
        <Input
          value={value}
          placeholder="Key (if different than label)"
          isHidden={!selected}
          onChange={key => onChangeValue(key)}
          isCollapsed={true}
          onFocus={() => onFocus('key')}
        />
        {selected &&
        <i style={styles.deleteIcon} className="fa fa-trash-o" onClick={onDelete}/>
        }
      </div>
    );
  }
}

const styles = {
  main: {
    display: 'flex',
    alignItems: 'center'
  },
  checkbox: {
    marginTop: 0,
    marginRight: 8,
    marginBottom: 2,
    cursor: 'pointer',
    width: 14,
    height: 14
  },
  label: {
    marginRight: 8,
    flex: 1
  },
  deleteIcon: {
    width: 32,
    height: 32,
    marginBottom: 2,
    lineHeight: '32px',
    textAlign: 'center'
  },
  full: {
    fontSize: 12,
  }
};

Checkbox.propTypes = propTypes;
export default Radium(Checkbox);
