import React, { Component } from 'react';
import PropTypes from 'prop-types';

import colors from '../../styles/colors';
import { Attention } from '../../components/type';
import InputLabel from '../../components/InputLabel';
import SelectLabel from '../../components/SelectLabel';
import InlineSaveState from '../../components/InlineSaveState';
import InlineButton from '../../components/InlineButton';
import FlatButton from '../../components/FlatButton';
import Note from '../../containers/Note';

class EditProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.project.name,
      description: props.project.description,
      manager: parseInt(props.project.id_project_manager),
    };
    this.handleSave = this.handleSave.bind(this);
  }

  render() {
    const { project, companyAdmins, handleCancel, note } = this.props;
    const { name, description, manager } = this.state;
    return (
      <div
        style={{
          background: 'white',
          padding: 16,
          boxSizing: 'border-box',
          border: `1px solid ${colors.border}`,
        }}>
        <div style={{ maxWidth: 576, margin: 'auto' }}>
          <Attention color="grey" bottom={24}>Edit {project.name}</Attention>
          {/* Form Inputs */}
          <InputLabel
            label="Name"
            name="name"
            value={name}
            handleChange={name => this.setState({ name })}
            disabled={project.isUpdating}
          />
          {this.props.note &&
          <div style={{marginTop: -16}}>
            <Note
              note={note}
              type="error"
            />
          </div>
          }
          <InputLabel
            label="Description"
            name="description"
            value={description}
            handleChange={description => this.setState({ description })}
            disabled={project.isUpdating}
          />
          <SelectLabel
            label="Project Admin"
            className="admin-select"
            value={manager}
            handleSelect={manager => this.setState({ manager })}
            options={companyAdmins.map(admin => ({value: parseInt(admin.id), label: `${admin.name} | ${admin.email}`}))}
            disabled={project.isUpdating}
            searchable
          />
          {/* Actions */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginLeft: -4,
              marginRight: -4,
            }}>
            <div style={{ marginRight: 4, marginLeft: 4 }}>
              <InlineSaveState
                savingLabel="Saving Project"
                savedLabel="Project Saved"
                saving={project.isUpdating}
                savedCallback={!this.props.note && handleCancel}
                hasErrors={!!this.props.note}
              />
            </div>
            <div style={{ marginRight: 4, marginLeft: 4 }}>
              <InlineButton
                className="save"
                disabled={project.isUpdating || !(!!this.state.name && !!this.state.description)}
                handleClick={this.handleSave}>
                Save
              </InlineButton>
            </div>
            <div style={{ marginRight: 4, marginLeft: 4 }}>
              <FlatButton
                disabled={project.isUpdating}
                handleClick={handleCancel}>
                Cancel
              </FlatButton>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleSave() {
    this.props.handleSave({
      name: this.state.name,
      description: this.state.description,
      id_project_manager: this.state.manager,
    });
  }
}

EditProject.propTypes = {
  project: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    id_project_manager: PropTypes.string.isRequired,
  }).isRequired,
  companyAdmins: PropTypes.array.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default EditProject;
