import React from 'react';
import ReactPaginate from 'react-paginate';

import LoadState from '../../containers/LoadState';

import './paginator.css';

class Pagination extends React.Component {
  constructor( props ) {
    super( props );
    this.state = {};
  }

  render() {
    const { children, pageCount, changePage, currentPage, loading } = this.props;
    return (
      <div>
        {loading ?
          <div style={{ marginTop: 24 }}>
            <LoadState label="Loading Chain Batches"/>
          </div>
          :
          <div>
            {children}
          </div>
        }
        {pageCount > 1 &&
        <div style={{ marginTop: 16 }} className={'paginator'}>
          <ReactPaginate
            previousLabel={currentPage === 1 ? '' : 'Prev'}
            nextLabel={currentPage === pageCount ? '' : 'Next'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            initialPage={currentPage - 1}
            marginPagesDisplayed={ 2}
            pageRangeDisplayed={ 5}
            onPageChange={changePage}
            disableInitialCallback={ true }/>
        </div>
        }
      </div>
    );
  }
}

export default Pagination;