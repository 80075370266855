import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import { Caption, } from './type';

/**
 * IconButton
 * Button with icon on the left
 */
const IconButton = (props) => props.href ?
  // If props.href we'll use an <a> tag as the wrapper
  <a href={props.href} target={props.target}><Content {...props}/></a> :
  // Otherwise we'll just use a div as the wrapper
  <div onClick={!props.disabled ? props.handleClick : undefined}><Content {...props}/></div>;

const Content = ({ disabled, icon, iconPosition, children, color, hoverColor, underline, align, smallIcon, loading, title, fontWeight }) => (
  <div style={{ float: align && align }}>
    <Caption
      title={title}
      className={css(styles.iconButton)}
      color={!disabled ? color : 'lighter'}
      hoverColor={!disabled ? hoverColor : 'lighter'}>
      <div className={css(iconPosition === 'right' ? styles.reverseContainer : styles.container)}>
        {/* Icon */}
        <div className={css(smallIcon ? styles.smallIcon : styles.icon)}>
          <i className={loading ? 'fa fa-spinner fa-spin' : `fa fa-${icon}`}/>
        </div>
        {/* Label */}
        <span style={{
          textDecoration: underline ? 'underline' : 'none',
          fontWeight: fontWeight ? fontWeight : 'inherit'
        }}>{children}</span>
      </div>
    </Caption>
  </div>
);

/* Styles */
const styles = StyleSheet.create({
  iconButton: {
    cursor: 'pointer',
  },
  icon: {
    marginRight: 4,
    marginLeft: 4,
    fontSize: 16,
    textDecoration: 'none',
  },
  smallIcon: {
    marginRight: 4,
    marginLeft: 4,
    fontSize: 12,
    textDecoration: 'none',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  reverseContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
  },
});

/* PropTypes */
IconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  href: PropTypes.string,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  underline: PropTypes.bool,
  handleClick: PropTypes.func,
  children: PropTypes.node,
};
IconButton.defaultProps = {
  handleClick: () => {},
  iconPosition: 'left',
  color: 'dark',
  hoverColor: 'primary',
  disabled: false,
};

export default IconButton;
