import React from 'react';
import PropTypes from 'prop-types';

import colors from '../styles/colors';
import { Title } from '../components/type';

/**
 * LoadState
 * A simple, consistent component used to express something is loading
 */
const LoadState = ({ label, fontSize, minHeight, top }) => (
  <div style={Object.assign({}, styles.loader(top), { minHeight: minHeight })}>
    <i style={{ fontSize: fontSize }} className={`fa fa-spin fa-circle-o-notch`}/>
    {label && <Title top={16} color="light">{label}</Title>}
  </div>
);

/* Styles */
const styles = {
  loader: (top) => ({
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    textAlign: 'center',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.lighter,
    flex: 1,
    marginTop: !!top ? top : 0,
  }),
};

/* PropTypes */
LoadState.propTypes = {
  label: PropTypes.string,
  fontSize: PropTypes.number,
};

LoadState.defaultProps = {
  fontSize: 40,
  minHeight: 64,
};

export default LoadState;