import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-modal';
import Input from '../Form/Input';
import Radio from '../Form/Radio';

/**
 * Save to Library Modal
 */
const SaveToLibraryModal = ({
  isOpen,
  libraryElement,
  allProjects,
  allProjectsSelected,
  saveElement,
  getName,
  closeIt,
  savingElement,
}) => (
  <div>
    <Modal
      isOpen={isOpen}
      style={modalStyles}
      onRequestClose={() => closeIt(true)}
      shouldCloseOnOverlayClick={true}
      contentLabel="Save to Library Modal"
    >
      <Input
        label="Create a name for your library element"
        placeholder="e.g., Featured Slideshow"
        value={ libraryElement && libraryElement.name ? libraryElement.name : ''}
        onChange={value => getName(value)}
        disabled={false}
        maxLength="21"
      />
      <Radio
        label="Make this library element available"
        labelBold={true}
        display="block"
        options={
          [
            {
              label: 'Across all projects attached to this account',
              checked: allProjects,
              onChange: () => allProjectsSelected(true)
            },
            {
              label: 'Only in this project',
              checked: !allProjects,
              onChange: () => allProjectsSelected(false)
            }
          ]
        }
      />
      <div
        className={!savingElement ? 'btn pull-right' : 'btn pull-right disabled'}
        onClick={() => saveElement(libraryElement)
      }>
        {savingElement &&
          <span className="fa fa-spin fa-spinner"/>
        }
        {!savingElement &&
          <span>Save</span>
        }
      </div>
    </Modal>
  </div>
);

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    zIndex: 10001,
  },
  content: {
    position: 'relative',
    top: '50%',
    left: 0,
    right: 'initial',
    bottom: 'initial',
    border: 0,
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    padding: 20,
    maxWidth: 500,
    maxHeight: '100%',
    margin: 'auto',
    transform: 'translateY(-50%)',
  }
};

/* PropTypes */
SaveToLibraryModal.propTypes = {
  isOpen: PropTypes.bool,
  libraryElement: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  allProjects: PropTypes.bool,
  allProjectsSelected: PropTypes.func,
  saveElement: PropTypes.func,
  getName: PropTypes.func,
  closeIt: PropTypes.func,
  savingElement: PropTypes.bool,
};

export default SaveToLibraryModal;
