import update from 'immutability-helper';

import {ADD_INPUT} from '../actionTypes';

const initialState = {
  inputs: [],
  outputs: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_INPUT:
      return update(state, {inputs: {$push: [action.input]}});
    default:
      return state;
  }
}
