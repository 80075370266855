import React, { Component } from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import objectToArray from '../../utils/_objectToArray';
import colors from '../../styles/colors';
import {Caption, Header4, Attention, Label} from '../../components/type';
import InlineButton from '../../components/InlineButton';
import LoadState from '../../containers/LoadState';
import Tooltip from '../../components/Tooltip';
import { toggleModal } from '../../actions/app';
import { UNARCHIVE_CHAIN } from '../../constants/modalTypes';

import { fetchChainWorkflows, fetchChainMetrics } from '../../actions/chains';

import Chain from './Chain';

const completionTime = ( sec, completed ) => {
  let time = null;
  if(!completed){
    return {time: 0, timeIncrement: 'seconds'};
  } else {
    time = sec / completed;
  }
  return calcTimeIncrement(time);
};

const calcTimeIncrement = (seconds) => {
  let time = 0;
  let timeIncrement = 'seconds';
  const secondsInMin = 60;
  const secondsInHour = 3600;
  const secondsInHourAndHalf = 5400;
  const secondsInDay = 86400;
  const secondsInDayAndHalf = 129600;

  if(seconds >= secondsInMin && seconds < secondsInHour) {
    timeIncrement = seconds > secondsInMin + 30 ? 'minutes' : 'minute';
    time = Math.round(seconds / secondsInMin);
  } else if(seconds >= secondsInHour && seconds < secondsInDay) {
    time = Math.round(seconds / secondsInHour);
    timeIncrement = seconds > secondsInHourAndHalf ? 'hours' : 'hour';
  } else if(seconds >= secondsInDay) {
    time = Math.round(seconds / secondsInDay);
    timeIncrement = seconds > secondsInDayAndHalf ? 'days' : 'day';
  }
  return {time, timeIncrement};
};

const timeSinceLaunch = (chainCreation, increment) => {
  const today = moment();
  return today.diff(chainCreation, increment);
};

const calcChainVelocity = (completed, chainCreation) => {
  const dayInMinutes = 1440;
  if(timeSinceLaunch(chainCreation, 'minutes') <= dayInMinutes) {
    return completed;
  } else {
    return Math.round(completed / timeSinceLaunch(chainCreation, 'days'));
  }
};

class ChainMonitor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 200
    };
  }

  componentDidMount() {
    const { chain, projectId, chainId } = this.props;
    if(chain.launched) {
      this.props.dispatch(fetchChainWorkflows(projectId, chainId));
      this.props.dispatch(fetchChainMetrics(chainId));
    }
  }

  render() {
    const {
      chain,
      workflows,
      handleClickRelease,
      handleClickUpload,
      handleClickDownload,
      handleReturnToEdit,
      workstationUrl,
      fetchingChains,
      fetchingWorkflows,
      pluginMetrics,
      isChainUpdating,
    } = this.props;

    const avgItemCost = 'Avg. Cost Per Item:';
    const avgCompletionTime = 'Avg. Completion Time:';
    const chainVelocity = 'Velocity';
    const canceledItems = 'Canceled Items';
    const pausedItems = 'Items Paused in Flight';
    const pausedItemCount = chain.metrics.pausedInFlightItemCount;
    const totalCost = chain.metrics.totalCost;
    const canceledItemCount = chain.metrics.canceledItemCount;
    const completedItemCount = chain.metrics.completedItemCount;
    const totalCompletionTime = calcTimeIncrement(chain.metrics.completionTime);
    const avgCostPerItem = !!completedItemCount ? totalCost / completedItemCount : 0;
    const avgCompletionValues = !!completedItemCount ?
      completionTime(chain.metrics.completionTime, completedItemCount) : {time: 0, timeIncrement: 'seconds'};
    const chainVelocityCalc = !!completedItemCount ? calcChainVelocity(completedItemCount, chain.createdAt) : 0;
    const daysSinceLaunch = Math.round(timeSinceLaunch(chain.createdAt, 'minutes')/1440);
    const displayMonitor = chain.launched && !chain.archived;
    const errorItems = 'Items in an Error State';
    const errorItemCount = chain.metrics.errorItemCount;
    const errorTooltip = 'This metric represents the count of workflow chain items that experienced ' + 
      'an error and can not be completed. Please contact support@onespace.com to troubleshoot items in an error state.';

    const pausedTooltip = 'This metric represents the count of workflow chain items that were paused while ' +
      'transitioning from one workflow in the chain to another. Once the workflow chain batch is resumed, ' +
      'the items will flow into the appropriate workflows.';

    const avgCostTooltip = <div style={styles.tooltip}>
      <div >
        <Caption color="white">Total Items Cost</Caption>
        <Attention color="white">{numeral(totalCost).format('$0,0.00')}</Attention>
      </div>
        <Caption color="white" left={15} right={15}>/</Caption>
      <div>
        <Caption color="white">Total Completed Items</Caption>
        <Attention color="white">{completedItemCount}</Attention>
      </div>
        <Caption color="white" left={15} right={15}>=</Caption>
      <div>
        <Caption color="white">Average Cost Per Item</Caption>
        <Attention color="white">{numeral(avgCostPerItem).format('$0,0.00')}</Attention>
      </div>
    </div>;

    const avgCompletionTooltip = <div style={styles.tooltip}>
      <div >
        <Caption color="white">Total Completion Time</Caption>
        <div style={styles.tooltipLabel}>
          <Attention color="white">{totalCompletionTime.time}</Attention>
          <Caption color="white" left={5} top={7}>{totalCompletionTime.timeIncrement}</Caption>
        </div>
      </div>
      <Caption color="white" left={15} right={15}>/</Caption>
      <div>
        <Caption color="white">Total Completed Items</Caption>
        <Attention color="white">{completedItemCount}</Attention>
      </div>
      <Caption color="white" left={15} right={15}>=</Caption>
      <div>
        <Caption color="white">Average Completion Time</Caption>
        <div style={styles.tooltipLabel}>
          <Attention color="white">{avgCompletionValues.time}</Attention>
          <Caption color="white" left={5} top={7}>{avgCompletionValues.timeIncrement}</Caption>
        </div>
      </div>
    </div>;

    const chainVelocityTooltip = <div style={styles.tooltip}>
      <div >
        <Caption color="white">Total Completed Items</Caption>
        <Attention color="white">{completedItemCount}</Attention>
      </div>
      <Caption color="white" left={15} right={15}>/</Caption>
      <div>
        <Caption color="white">Days Since Chain was Launched</Caption>
        <div style={styles.tooltipLabel}>
          <Attention color="white">{daysSinceLaunch}</Attention>
          <Caption color="white" left={5} top={7}>days</Caption>
        </div>
      </div>
      <Caption color="white" left={15} right={15}>=</Caption>
      <div>
        <Caption color="white">Workflow Chain Velocity</Caption>
        <div style={styles.tooltipLabel}>
          <Attention color="white">{chainVelocityCalc}</Attention>
          <Caption color="white" left={5} top={7}>items per day</Caption>
        </div>
      </div>
    </div>;

    if(fetchingChains || fetchingWorkflows) {
      return (
        <div style={{marginTop: 50}}>
          <LoadState label="Fetching Your Data" />
        </div>
      );
    }

    return (
      <div>
        {displayMonitor &&
          <Chain
            workflows={workflows}
            chain={chain}
            pluginMetrics={pluginMetrics}
            preview={false}
            workstationUrl={workstationUrl}
            handleClickRelease={handleClickRelease}
            handleClickUpload={handleClickUpload}
            handleClickDownload={handleClickDownload}/>
        }
        {chain.archived &&
          <div style={styles.archivedContainer}>
            <div style={styles.completedStats}>
              <div style={styles.completed}>
                <Label color='light'>Completed</Label>
                <Attention color='light'>{completedItemCount}</Attention>
              </div>
              <div style={styles.downloadContainer} onClick={handleClickDownload}>
                <span className="fa fa-download" style={{cursor: 'pointer'}}><span style={styles.downloadBtn}>Download</span></span>
              </div>
            </div>
            <div style={styles.archivedContent}>
              <div style={styles.archiveMessage}>
                <Caption color='white' center={true}>
                  This workflow chain is currently archived.
                </Caption>
              </div>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <InlineButton handleClick={() => {
                  this.props.dispatch(toggleModal( UNARCHIVE_CHAIN, {
                    chainId: chain.id,
                    projectId: chain.projectId,
                    archive: false,
                    enableOverlayClick: true,
                  }));
                }}>
                  Unarchive Chain
                </InlineButton>
              </div>
            </div>
          </div>
        }
        {!chain.launched && !chain.archived &&
          <div style={styles.blankContainer}>
            <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
              <Header4 center={true}>
                Before you can monitor your chain activity,<br/> you need to launch the chain and upload a batch.
              </Header4>
              <div style={{display: 'flex', justifyContent: 'center', marginTop: 16}}>
                <InlineButton handleClick={handleReturnToEdit} >
                  Return to Edit Chain
                </InlineButton>
              </div>
            </div>
          </div>
        }
        
        <div style={styles.chainStatsContainer}>
          {isChainUpdating ? (
            <LoadState label="Fetching Your Metrics" />
          ) : (
            <div style={{ display: 'flex', flex: 1 }}>
              <div style={{
                flex: 1,
                borderRight: `2px solid ${colors.border}`,
                padding: '4px 16px 4px 0',
                alignItems: 'flex-end'
              }}>
                <Caption color="light">{avgItemCost}</Caption>
                <div style={styles.metrics}>
                  <Attention color="dark" left={4} right={4}>{numeral(avgCostPerItem).format('$0,0.00')}</Attention>
                  <div style={styles.tooltipButton}>
                    <Tooltip tooltipObject={avgCostTooltip} right={true}/>
                  </div>
                </div>
              </div>
              <div style={{
                flex: 1,
                borderRight: `2px solid ${colors.border}`,
                padding: '4px 16px',
                alignItems: 'flex-end'
              }}>
                <Caption color="light">{avgCompletionTime}</Caption>
                <div style={styles.metrics}>
                  <Attention color="dark" left={4} right={4}>{avgCompletionValues.time}</Attention>
                  <Caption color="dark" right={3}>{avgCompletionValues.timeIncrement}</Caption>
                  <div style={styles.tooltipButton}>
                    <Tooltip tooltipObject={avgCompletionTooltip} right={true}/>
                  </div>
                </div>
              </div>
              <div style={{
                flex: 1,
                borderRight: `2px solid ${colors.border}`,
                padding: '4px 16px',
                alignItems: 'flex-end'
              }}>
                <Caption color="light">{chainVelocity}:</Caption>
                <div style={styles.metrics}>
                  <Attention color="dark" left={4} right={4}>{chainVelocityCalc}</Attention>
                  <Caption color="dark" right={3}>items per day</Caption>
                  <div style={styles.tooltipButton}>
                    <Tooltip tooltipObject={chainVelocityTooltip} left={true}/>
                  </div>
                </div>
              </div>
              <div style={{
                flex: 1,
                borderRight: `2px solid ${colors.border}`,
                padding: '4px 16px',
                alignItems: 'flex-end'}}>
                <Caption color="light">{canceledItems}:</Caption>
                <div style={styles.metrics}>
                  <Attention color="dark" left={4} right={4} underline={false}>{canceledItemCount}</Attention>
                </div>
              </div>
              <div style={{
                flex: 1,
                padding: '4px 16px',
                alignItems: 'flex-end'}}>
                <Caption color="light">{pausedItems}:</Caption>
                <div style={styles.metrics}>
                  <Attention color="dark" left={4} right={4} underline={false}>{pausedItemCount}</Attention>
                  <div style={styles.tooltipButton}>
                    <Tooltip tooltipText={pausedTooltip} left={true}/>
                  </div>
                </div>
              </div>
              { !!errorItemCount &&
              <div style={{
                flex: 1,
                borderLeft: `2px solid ${colors.border}`,
                padding: '4px 16px',
                alignItems: 'flex-end'}}>
                <Caption color="light">{errorItems}:</Caption>
                <div style={styles.metrics}>
                  <Attention color="dark" left={4} right={4} underline={false}>{errorItemCount}</Attention>
                  <div style={styles.tooltipButton}>
                    <Tooltip tooltipText={errorTooltip} left={true}/>
                  </div>
                </div>
              </div>
              }
            </div>
          )}
        </div>

      </div>
    );
  }
}

const styles = {
  chainStatsContainer: {
    display: 'flex',
    borderRadius: 5,
    backgroundColor: colors.shaded,
    marginTop: 20,
    padding: '36px 16px',
  },
  blankContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 400,
    border: `2px solid ${colors.divider}`,
    borderTop: 'none',
    borderRadius: '0 0 5px 5px'
  },
  archivedContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 400,
    border: `2px solid ${colors.divider}`,
    background: colors.light,
    borderTop: 'none',
    borderRadius: '0 0 5px 5px',
    overflow: 'auto',
  },
  archivedContent: {
    background: colors.dark,
    height: 150,
    width: 300,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 5,
    flexDirection: 'column',
    transform: `translate(20vw)`,

  },
  completedStats: {
    display: 'flex',
    flexDirection: 'column',
    width: 130,
    position: 'relative',
    left: 20,
  },
  completed: {
    backgroundColor: colors.green,
    color: colors.light,
    padding: `16px 0 16px 16px`,
    marginBottom: 2,
    borderRadius: `6px 6px 0 0`,
  },
  downloadContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    background: colors.green,
    color: colors.dark,
    borderRadius: `0 0 6px 6px`,
    padding: `16px 0 16px 16px`,
  },
  downloadBtn: {
      marginLeft: 5,
      textDecoration: 'underline',
      cursor: 'pointer',
      fontFamily: 'Open Sans'
  },
  archiveMessage: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 5,
    padding: '0 16px'
  },
  tooltip: {
    padding: `9px 14px`,
    display: 'flex',
    width: 'max-content',
  },
  tooltipLabel: {
    display: 'flex',
  },
  tooltipButton: {
    position: 'relative',
    bottom: 6,
  },
  metrics: {
    display: 'flex',
    alignItems: 'flex-end',
  }
};

/* PropTypes */
ChainMonitor.propTypes = {
  handleClickRelease: PropTypes.func,
  handleClickUpload: PropTypes.func,
  handleClickDownload: PropTypes.func,
};
ChainMonitor.defaultProps = {
  handleClickRelease: () => {},
  handleClickUpload: () => {},
  handleClickDownload: () => {},
};

function select(state, props) {
  const projectId = props.projectId;
  const chainId = props.chainId;
  const chains = objectToArray(state.chains.items).filter(chain => chain.projectId === projectId);
  return {
    workflows: state.workflows.items,
    chain: chains[0],
    chainId,
    projectId,
    fetchingWorkflows: state.workflows.isFetching,
    fetchingChains: state.chains.isFetching,
    workstationUrl: state.app.workstationUrl,
    allChains: state.chains.items,
    pluginMetrics: state.pluginMetrics.items && state.pluginMetrics.items,
    isChainUpdating: chains[0].isUpdating,
  };
}

export default connect(select)(ChainMonitor);