import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import HamburgerMenu from '../../components/HamburgerMenu';
import ShadedTextTile from '../../components/ShadedTextTile';
import { Label } from '../../components/type';


class NotificationDisplay extends Component {
  constructor( props ) {
    super( props );
    this.state = {};
  }

  render() {
    const { type, handleSelect } = this.props;
    const past = (type === 'past');
    return (
      <div>
        {this.props.notes.map( ( note, i ) => {
          const options = [
            { label: 'Preview', value: 'preview' },
            { label: 'Edit', href: `/manage/notification-center/${note.id}` },
            { label: 'Delete', value: 'delete' } ];
          const type = note.status;
          const date = note.active_at.toString() !== '0' ? moment.unix( note.active_at ).format( 'MM/DD/YYYY' ) : '';
          const time = note.active_at.toString() !== '0' ? moment.unix( note.active_at ).format( 'hh' ) : '';
          const amOrPm = note.active_at.toString() !== '0' ? moment.unix( note.active_at ).format( 'A' ) : '';
          return (
            <div key={i}>
              {i !== 0 &&
                <hr />
              }
              <div style={{display: 'flex', justifyContent: 'space-between', paddingBottom: 6}}>
                <Label underline={true} href={`/manage/notification-center/${note.id}`}>{note.title}</Label>
                <HamburgerMenu
                  options={type === 'active' ?
                    [...options, {label: 'Move to History', value: 'moveToHistory'}] : options }
                  handleSelect={value => handleSelect(value, note.id)}/>
              </div>
              <div style={{display: 'flex'}}>
                {!past &&
                <div style={{marginRight: 8}}>
                  <ShadedTextTile label={note.status.toUpperCase()}/>
                </div>
                }
                {!!date &&
                <div style={{display: 'flex'}}>
                  <div style={{marginRight: 8}}>
                    <ShadedTextTile label={date}/>
                  </div>
                  <div style={{marginRight: 8}}>
                    <ShadedTextTile label={time}/>
                  </div>
                  <div>
                    <ShadedTextTile label={amOrPm}/>
                  </div>
                </div>
                }
                {(!!date === false && note.status !== 'not scheduled') &&
                <ShadedTextTile label="Not Scheduled"/>
                }
              </div>
            </div>
          );
        } )}
      </div>
    );
  }
}

NotificationDisplay.propTypes = {
  type: PropTypes.string.isRequired,
  handleSelect: PropTypes.func,
  notes: PropTypes.array.isRequired,
};


export default NotificationDisplay;