import React from 'react';

import Input from '../../Form/Input';
import Select from '../../Form/Select';
import Settings from '../Settings';
import SupportRoleOptions from './SupportRoleOptions';

const DiffOn = [
  {label: 'Words', value: 'words'},
  {label: 'Characters', value: 'characters'},
  {label: 'Sentences', value: 'sentences'}
];

class TextDifference extends React.Component {
  componentDidMount() {
    if (this.props.selected) {
      this.inline.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.selected && this.props.selected) {
      this.inline.focus();
    }
  }

  render() {
    const {data = {}, selected, updateElement, handleSelectInput, isSupportRole} = this.props;
    return (
      <div style={styles.main}>

        <div style={styles.wrapper}>
          <Input
            ref={ele => this.inline = ele}
            disabled={!selected}
            selected={selected}
            value={data.origin}
            label="Original Value"
            onChange={origin => updateElement({origin})}
            onFocus={() => handleSelectInput('origin')}
          />
          <div style={styles.vs}>vs</div>
          <Input
            disabled={!selected}
            value={data.edited}
            label="Latest Value"
            onChange={edited => updateElement({edited})}
            onFocus={() => handleSelectInput('edited')}
          />
        </div>
        <Settings show={selected} customHeight="auto">
          <Select
            label="What do you want to diff on?"
            value={data.diffType}
            options={DiffOn}
            handleChange={diffType => updateElement({diffType})}
          />
          {isSupportRole &&
          <SupportRoleOptions
            data={data}
            updateElement={updateElement}
            handleSelectInput={handleSelectInput}
          />
          }
        </Settings>
      </div>
    );
  }
}

const styles = {
  main: {
    paddingTop: 8
  },
  labelWrapper: {
    marginBottom: 16
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  vs: {
    fontSize: 18,
    fontWeight: 800,
    marginLeft: 16,
    marginRight: 16
  }
};

export default TextDifference;
