import update from 'immutability-helper';

export const updateState = {
  fetchRequest,
  fetchSuccess,
  fetchFailure,
  updateRequest,
  updateSuccess,
  updateFailure,
  deleteRequest,
  deleteSuccess,
  deleteFailure,
  createRequest,
  createSuccess,
  createFailure,
  fetchChildrenRequest,
  fetchChildrenSuccess,
  fetchChildrenFailure,
  fetchVersionSuccess,
  createVersionSuccess,
};

function fetchRequest(previousState, isRequestForAll = true) {
  const state = { items: {}, ...previousState };
  return merge(state, {
    isFetching: true,
    hasFetched: isRequestForAll || state.hasFetched || false
  });
}
function fetchSuccess(previousState, objects) {
  const state = { items: {}, ...previousState };
  const normalizedObjects = {};
  objects.forEach((object) => {
    const objectId = object.customId ? object.customId : object.id ? object.id : object._id;
    return normalizedObjects[objectId] = merge(state.items[objectId], object);
  });
  const response = {
    items: { ...state.items, ...normalizedObjects },
    isFetching: false
  };
  if (state.hasFetched) {
    response.hasFetched = true;
  }
  return response;
}
function fetchVersionSuccess(state, object) {
  const versionKey = state.items[object.id].versions.findIndex(v => v.version === object.version);
  const newItem = merge(state.items[object.id].versions[object.version], object);
  return update(state, { items: { [object.id]: { versions: { [versionKey]: { $set: newItem } } }}});
}
function fetchFailure(state) {
  return merge(state, { isFetching: false });
}
function updateRequest(state, id) {
  return mergeItem(state, id, { isUpdating: true });
}
function updateSuccess(state, id, update) {
  return setItem(state, id, merge(state.items[id], update, { isUpdating: false }));
}
function updateFailure(state, id) {
  return mergeItem(state, id, { isUpdating: false });
}

function deleteRequest(state, id) {
  return mergeItem(state, id, { isDeleting: true });
}
function deleteSuccess(state, id) {
  return deleteItem(state, id);
}
function deleteFailure(state, id) {
  return mergeItem(state, id, { isDeleting: false });
}
function createRequest(state, id) {
  return mergeItem(state, id, { isCreating: true });
}
function createFailure(state, id) {
  return mergeItem(state, id, { isCreating: false });
}
function createSuccess(state, item) {
  const itemId = item.customId ? item.customId : item.id;
  item.isCreating = false;
  return setItem(state, itemId, item);
}
function createVersionSuccess(state, item) {
  const itemId = item.customId ? item.customId : item.id;
  item.isCreating = false;
  state.items[item.id].versions = state.items[item.id].versions ? state.items[item.id].versions : [];
  const oldVersions = [...state.items[item.id].versions];
  item.versions = update(oldVersions, {$unshift: [item]});
  return setItem(state, itemId, item);
}
function fetchChildrenRequest(state, id, type, isRequestForAll = true) {
  const prevFetched = state[id] && state[id][type].hasFetched; 
  return mergeItem(state, id, {
    [type]: {
      isFetching: true,
      hasFetched: isRequestForAll || prevFetched || false
    }
  });
}
function fetchChildrenSuccess(state, id, type) {
  const response = { isFetching: false };
  if (state.items[id][type] && state.items[id][type].hasFetched) {
    response.hasFetched = true;
  }
  return mergeItem(state, id, { [type]: response });
}
function fetchChildrenFailure(state, id, type) {
  const response = { isFetching: false, fetchingFailed: true };
  if (state.items[id][type] && state.items[id][type].hasFetched) {
    response.hasFetched = true;
  }
  return mergeItem(state, id, { [type]: response });
}

const merge = (...rest) => Object.assign({}, ...rest);
// Replace state[id] with newItem
const setItem = (state, id, newItem) => {
  if (!state.items) {
    return update(state, { $merge: { items: { [id]: newItem } } });
  }
  return update(state, { items: { [id]: { $set: newItem } } });
};

// Set state[id] to be a merge between state[id] and update
const mergeItem = (state, id, update) => {
  return setItem(state, id, merge(state.items[id], update));
};
const deleteItem = (state, id) => {
  const items = { ...state.items };
  delete items[id];
  return merge(state, { items });
};

