import { updateState } from '../utils/reducerHelpers';
import update from 'immutability-helper';

import {
  FETCH_COMPANY_LEDGER_REQUEST,
  FETCH_COMPANY_LEDGER_SUCCESS,
  FETCH_COMPANY_LEDGER_FAILURE,
  UPDATE_COMPANY_LEDGER_REQUEST,
  UPDATE_COMPANY_LEDGER_SUCCESS,
  UPDATE_COMPANY_LEDGER_FAILURE,
  UPDATE_COMPANY_PLAN_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  items: {},
  isFetching: false,
  hasFetched: false,
};

export default function (state= initialState, action) {
  switch(action.type) {
    case FETCH_COMPANY_LEDGER_REQUEST:
      return updateState.fetchRequest(state, false);
    case FETCH_COMPANY_LEDGER_SUCCESS:
      return update(state, {
        $set: {
          items: {[action.ledger.companyId]: action.ledger},
          isFetching: false,
          hasFetched: true,
        }
      });
    case FETCH_COMPANY_LEDGER_FAILURE:
      return updateState.fetchFailure(state);
    case UPDATE_COMPANY_LEDGER_REQUEST:
      return updateState.updateRequest(state, action.swfId);
    case UPDATE_COMPANY_LEDGER_SUCCESS:
      return updateState.updateSuccess(state, action.swfId, {availableBalance: action.balance});
    case UPDATE_COMPANY_LEDGER_FAILURE:
      return updateState.updateFailure(state, action.swfId);
    case UPDATE_COMPANY_PLAN_SUCCESS:
      return updateState.updateSuccess(state, action.swfId, {
        transactionFeeRate: action.transactionFee,
        minimumTransactionFee: action.transactionFloor,
        privateContributorsRespectMinimum: action.privateContributorsRespectMinimum,
      });
    default:
      return state;
  }
}