export const CREATED_CHAIN = 'CREATED_CHAIN';
export const CREATED_NODE = 'CREATED_NODE';
export const FAILED_BATCH = 'FAILED_BATCH';

//Login, ForgotPassword, SignUP
export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

//Chains
export const FETCH_CHAIN_REQUEST = 'FETCH_CHAIN_REQUEST';
export const FETCH_CHAIN_SUCCESS = 'FETCH_CHAIN_SUCCESS';
export const FETCH_CHAIN_FAILURE = 'FETCH_CHAIN_FAILURE';
export const LAUNCHED_CHAIN = 'LAUNCHED_CHAIN';
export const CREATE_CHAIN_REQUEST = 'CREATE_CHAIN_REQUEST';
export const CREATE_CHAIN_SUCCESS = 'CREATE_CHAIN_SUCCESS';
export const CREATE_CHAIN_FAILURE = 'CREATE_CHAIN_FAILURE';
export const UPDATE_CHAIN_REQUEST = 'UPDATE_CHAIN_REQUEST';
export const UPDATE_CHAIN_SUCCESS = 'UPDATE_CHAIN_SUCCESS';
export const UPDATE_CHAIN_FAILURE = 'UPDATE_CHAIN_FAILURE';
export const LAUNCH_CHAIN_REQUEST = 'LAUNCH_CHAIN_REQUEST';
export const LAUNCH_CHAIN_SUCCESS = 'LAUNCH_CHAIN_SUCCESS';
export const LAUNCH_CHAIN_FAILURE = 'LAUNCH_CHAIN_FAILURE';
export const FETCH_IS_CHAIN_ACTIVE_REQUEST = 'FETCH_IS_CHAIN_ACTIVE_REQUEST';
export const FETCH_IS_CHAIN_ACTIVE_SUCCESS = 'FETCH_IS_CHAIN_ACTIVE_SUCCESS';
export const FETCH_IS_CHAIN_ACTIVE_FAILURE = 'FETCH_IS_CHAIN_ACTIVE_FAILURE';
export const ARCHIVE_CHAIN_REQUEST = 'ARCHIVE_CHAIN_REQUEST';
export const ARCHIVE_CHAIN_SUCCESS = 'ARCHIVE_CHAIN_SUCCESS';
export const ARCHIVE_CHAIN_FAILURE = 'ARCHIVE_CHAIN_FAILURE';
export const UNARCHIVE_CHAIN_REQUEST = 'UNARCHIVE_CHAIN_REQUEST';
export const UNARCHIVE_CHAIN_SUCCESS = 'UNARCHIVE_CHAIN_SUCCESS';
export const UNARCHIVE_CHAIN_FAILURE = 'UNARCHIVE_CHAIN_FAILURE';


// Chains/batches
export const FETCH_CHAINS_REQUEST = 'FETCH_CHAINS_REQUEST';
export const FETCH_CHAINS_SUCCESS = 'FETCH_CHAINS_SUCCESS';
export const FETCH_CHAINS_FAILURE = 'FETCH_CHAINS_FAILURE';

export const FETCH_CHAIN_WORKFLOWS_REQUEST = 'FETCH_CHAIN_WORKFLOWS_REQUEST';
export const FETCH_CHAIN_WORKFLOWS_SUCCESS = 'FETCH_CHAIN_WORKFLOWS_SUCCESS';
export const FETCH_CHAIN_WORKFLOWS_FAILURE = 'FETCH_CHAIN_WORKFLOWS_FAILURE';

export const FETCH_CHAIN_BATCHES_REQUEST = 'FETCH_CHAIN_BATCHES_REQUEST';
export const FETCH_CHAIN_BATCHES_SUCCESS = 'FETCH_CHAIN_BATCHES_SUCCESS';
export const FETCH_CHAIN_BATCHES_FAILURE = 'FETCH_CHAIN_BATCHES_FAILURE';

export const FETCH_CHAIN_BATCH_REQUEST = 'FETCH_CHAIN_BATCH_REQUEST';
export const FETCH_CHAIN_BATCH_SUCCESS = 'FETCH_CHAIN_BATCH_SUCCESS';
export const FETCH_CHAIN_BATCH_FAILURE = 'FETCH_CHAIN_BATCH_FAILURE';

export const IMPORT_CHAIN_BATCH_REQUEST = 'IMPORT_CHAIN_BATCH_REQUEST';
export const IMPORT_CHAIN_BATCH_SUCCESS = 'IMPORT_CHAIN_BATCH_SUCCESS';
export const IMPORT_CHAIN_BATCH_FAILURE = 'IMPORT_CHAIN_BATCH_FAILURE';

export const RELEASE_CHAIN_BATCH_REQUEST = 'RELEASE_CHAIN_BATCH_REQUEST';
export const RELEASE_CHAIN_BATCH_SUCCESS = 'RELEASE_CHAIN_BATCH_SUCCESS';
export const RELEASE_CHAIN_BATCH_FAILURE = 'RELEASE_CHAIN_BATCH_FAILURE';

export const UPDATE_CHAIN_BATCH_REQUEST = 'UPDATE_CHAIN_BATCH_REQUEST';
export const UPDATE_CHAIN_BATCH_SUCCESS = 'UPDATE_CHAIN_BATCH_SUCCESS';
export const UPDATE_CHAIN_BATCH_FAILURE = 'UPDATE_CHAIN_BATCH_FAILURE';

export const EXPORT_CHAIN_BATCH_REQUEST = 'EXPORT_CHAIN_BATCH_REQUEST';
export const EXPORT_CHAIN_BATCH_SUCCESS = 'EXPORT_CHAIN_BATCH_SUCCESS';
export const EXPORT_CHAIN_BATCH_FAILURE = 'EXPORT_CHAIN_BATCH_FAILURE';

export const FETCH_CHAIN_METRICS_REQUEST = 'FETCH_CHAIN_METRICS_REQUEST';
export const FETCH_CHAIN_METRICS_SUCCESS = 'FETCH_CHAIN_METRICS_SUCCESS';
export const FETCH_CHAIN_METRICS_FAILURE = 'FETCH_CHAIN_METRICS_FAILURE';

export const RESUME_CHAIN_BATCH_REQUEST = 'RESUME_CHAIN_BATCH_REQUEST';
export const RESUME_CHAIN_BATCH_SUCCESS = 'RESUME_CHAIN_BATCH_SUCCESS';
export const RESUME_CHAIN_BATCH_FAILURE = 'RESUME_CHAIN_BATCH_FAILURE';

export const PAUSE_CHAIN_BATCH_REQUEST = 'PAUSE_CHAIN_BATCH_REQUEST';
export const PAUSE_CHAIN_BATCH_SUCCESS = 'PAUSE_CHAIN_BATCH_SUCCESS';
export const PAUSE_CHAIN_BATCH_FAILURE = 'PAUSE_CHAIN_BATCH_FAILURE';

export const UPDATE_CHAIN_BATCH_NOTIFICATION_REQUEST = 'UPDATE_CHAIN_BATCH_NOTIFICATION_REQUEST';
export const UPDATE_CHAIN_BATCH_NOTIFICATION_SUCCESS = 'UPDATE_CHAIN_BATCH_NOTIFICATION_SUCCESS';
export const UPDATE_CHAIN_BATCH_NOTIFICATION_FAILURE = 'UPDATE_CHAIN_BATCH_NOTIFICATION_FAILURE';

// Batches
export const CANCELED_BATCH = 'CANCELED_BATCH';
export const RELEASE_WORKFLOW_BATCH_SUCCESS = 'RELEASE_WORKFLOW_BATCH_SUCCESS';
export const RESUME_BATCH = 'RESUME_BATCH';
export const PAUSE_BATCH = 'PAUSE_BATCH';

//Projects
export const FETCH_PROJECTS_REQUEST = 'FETCH_PROJECTS_REQUEST';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE';
export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAILURE = 'UPDATE_PROJECT_FAILURE';
export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE';
export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE';

// Projects/chains
export const FETCH_PROJECT_CHAINS_REQUEST = 'FETCH_PROJECT_CHAINS_REQUEST';
export const FETCH_PROJECT_CHAINS_SUCCESS = 'FETCH_PROJECT_CHAINS_SUCCESS';
export const FETCH_PROJECT_CHAINS_FAILURE = 'FETCH_PROJECT_CHAINS_FAILURE';

export const CREATE_PLUGININSTANCE_REQUEST = 'CREATE_PLUGININSTANCE_REQUEST';
export const CREATE_PLUGININSTANCE_SUCCESS = 'CREATE_PLUGININSTANCE_SUCCESS';
export const CREATE_PLUGININSTANCE_FAILURE = 'CREATE_PLUGININSTANCE_FAILURE';

export const DELETE_PLUGININSTANCE_REQUEST = 'DELETE_PLUGININSTANCE_REQUEST';
export const DELETE_PLUGININSTANCE_SUCCESS = 'DELETE_PLUGININSTANCE_SUCCESS';
export const DELETE_PLUGININSTANCE_FAILURE = 'DELETE_PLUGININSTANCE_FAILURE';

export const FETCH_POPULATENEWINSTANCE_REQUEST = 'FETCH_POPULATENEWINSTANCE_REQUEST';
export const FETCH_POPULATENEWINSTANCE_SUCCESS = 'FETCH_POPULATENEWINSTANCE_SUCCESS';
export const FETCH_POPULATENEWINSTANCE_FAILURE = 'FETCH_POPULATENEWINSTANCE_FAILURE';

export const UPDATE_PLUGININSTANCE = 'UPDATE_PLUGININSTANCE';

export const CANCEL_UNSAVEDPLUGININSTANCE = 'CANCEL_UNSAVEDPLUGININSTANCE';

// Chain Plugins
export const FETCH_CHAINPLUGINS_REQUEST = 'FETCH_CHAINPLUGINS_REQUEST';
export const FETCH_CHAINPLUGINS_SUCCESS = 'FETCH_CHAINPLUGINS_SUCCESS';
export const FETCH_CHAINPLUGINS_FAILURE = 'FETCH_CHAINPLUGINS_FAILURE';

export const FETCH_CHAINPLUGIN_REQUEST = 'FETCH_CHAINPLUGIN_REQUEST';
export const FETCH_CHAINPLUGIN_SUCCESS = 'FETCH_CHAINPLUGIN_SUCCESS';
export const FETCH_CHAINPLUGIN_FAILURE = 'FETCH_CHAINPLUGIN_FAILURE';

export const FETCH_CHAINPLUGINVERSION_REQUEST = 'FETCH_CHAINPLUGINVERSION_REQUEST';
export const FETCH_CHAINPLUGINVERSION_SUCCESS = 'FETCH_CHAINPLUGINVERSION_SUCCESS';
export const FETCH_CHAINPLUGINVERSION_FAILURE = 'FETCH_CHAINPLUGINVERSION_FAILURE';

export const CREATE_CHAINPLUGIN_REQUEST = 'CREATE_CHAINPLUGIN_REQUEST';
export const CREATE_CHAINPLUGIN_SUCCESS = 'CREATE_CHAINPLUGIN_SUCCESS';
export const CREATE_CHAINPLUGIN_FAILURE = 'CREATE_CHAINPLUGIN_FAILURE';

export const UPDATE_CHAINPLUGIN_REQUEST = 'UPDATE_CHAINPLUGIN_REQUEST';
export const UPDATE_CHAINPLUGIN_SUCCESS = 'UPDATE_CHAINPLUGIN_SUCCESS';
export const UPDATE_CHAINPLUGIN_FAILURE = 'UPDATE_CHAINPLUGIN_FAILURE';

export const PUBLISH_CHAINPLUGIN_REQUEST = 'PUBLISH_CHAINPLUGIN_REQUEST';
export const PUBLISH_CHAINPLUGIN_SUCCESS = 'PUBLISH_CHAINPLUGIN_SUCCESS';
export const PUBLISH_CHAINPLUGIN_FAILURE = 'PUBLISH_CHAINPLUGIN_FAILURE';

// Projects/workflows
export const FETCH_PROJECT_WORKFLOWS_REQUEST = 'FETCH_PROJECT_WORKFLOWS_REQUEST';
export const FETCH_PROJECT_WORKFLOWS_SUCCESS = 'FETCH_PROJECT_WORKFLOWS_SUCCESS';
export const FETCH_PROJECT_WORKFLOWS_FAILURE = 'FETCH_PROJECT_WORKFLOWS_FAILURE';

export const FETCH_PROJECT_CHAINS_AND_WORKFLOWS_REQUEST = 'FETCH_PROJECT_CHAINS_AND_WORKFLOWS_REQUEST';
export const FETCH_PROJECT_CHAINS_AND_WORKFLOWS_SUCCESS = 'FETCH_PROJECT_CHAINS_AND_WORKFLOWS_SUCCESS';
export const FETCH_PROJECT_CHAINS_AND_WORKFLOWS_FAILURE = 'FETCH_PROJECT_CHAINS_AND_WORKFLOWS_FAILURE';
// Profile
export const FETCH_PROFILE_REQUEST = 'FETCH_PROFILE_REQUEST';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';

// Workflows
export const FETCH_WORKFLOWS_REQUEST = 'FETCH_WORKFLOWS_REQUEST';
export const FETCH_WORKFLOWS_SUCCESS = 'FETCH_ACTIVE_WORKFLOWS_SUCCESS';
export const FETCH_WORKFLOWS_FAILURE = 'FETCH_WORKFLOWS_FAILURE';

export const FETCH_ACTIVE_WORKFLOWS_REQUEST = 'FETCH_ACTIVE_WORKFLOWS_REQUEST';
export const FETCH_ACTIVE_WORKFLOWS_SUCCESS = 'FETCH_ACTIVE_WORKFLOWS_SUCCESS';
export const FETCH_ACTIVE_WORKFLOWS_FAILURE = 'FETCH_ACTIVE_WORKFLOWS_FAILURE';

export const FETCH_WORKFLOW_BATCHES_REQUEST = 'FETCH_WORKFLOW_BATCHES_REQUEST';
export const FETCH_WORKFLOW_BATCHES_SUCCESS = 'FETCH_WORKFLOW_BATCHES_SUCCESS';
export const FETCH_WORKFLOW_BATCHES_FAILURE = 'FETCH_WORKFLOW_BATCHES_FAILURE';

export const EXPORT_BATCH_REQUEST = 'EXPORT_BATCH_REQUEST';
export const EXPORT_BATCH_SUCCESS = 'EXPORT_BATCH_SUCCESS';
export const EXPORT_BATCH_FAILURE = 'EXPORT_BATCH_FAILURE';

export const CANCEL_WORKFLOW_ITEM_REQUEST = 'CANCEL_WORKFLOW_ITEM_REQUEST';
export const CANCEL_WORKFLOW_ITEM_SUCCESS = 'CANCEL_WORKFLOW_ITEM_SUCCESS';
export const CANCEL_WORKFLOW_ITEM_FAILURE = 'CANCEL_WORKFLOW_ITEM_FAILURE';

export const ARCHIVE_WORKFLOW_REQUEST = 'ARCHIVE_WORKFLOW_REQUEST';
export const ARCHIVE_WORKFLOW_SUCCESS = 'ARCHIVE_WORKFLOW_SUCCESS';
export const ARCHIVE_WORKFLOW_FAILURE = 'ARCHIVE_WORKFLOW_FAILURE';

export const FETCH_PROJECT_WORKFLOWSSCHEMA_REQUEST = 'FETCH_PROJECT_WORKFLOWSSCHEMA_REQUEST';
export const FETCH_PROJECT_WORKFLOWSSCHEMA_SUCCESS = 'FETCH_PROJECT_WORKFLOWSSCHEMA_SUCCESS';
export const FETCH_PROJECT_WORKFLOWSSCHEMA_FAILURE = 'FETCH_PROJECT_WORKFLOWSSCHEMA_FAILURE';

export const FETCH_VALIDATE_WORKFLOW_NAME_REQUEST = 'FETCH_VALIDATE_WORKFLOW_NAME_REQUEST';
export const FETCH_VALIDATE_WORKFLOW_NAME_SUCCESS = 'FETCH_VALIDATE_WORKFLOW_NAME_SUCCESS';
export const FETCH_VALIDATE_WORKFLOW_NAME_FAILURE = 'FETCH_VALIDATE_WORKFLOW_NAME_FAILURE';

export const UPDATE_WORKFLOW_NAME_SUCCESS = 'UPDATE_WORKFLOW_NAME_SUCCESS';

export const UPDATE_WORKFLOW_BATCH_NOTIFICATION_REQUEST = 'UPDATE_WORKFLOW_BATCH_NOTIFICATION_REQUEST';
export const UPDATE_WORKFLOW_BATCH_NOTIFICATION_SUCCESS = 'UPDATE_WORKFLOW_BATCH_NOTIFICATION_SUCCESS';
export const UPDATE_WORKFLOW_BATCH_NOTIFICATION_FAILURE = 'UPDATE_WORKFLOW_BATCH_NOTIFICATION_FAILURE';


// Inbox
export const FETCH_INBOX_REQUEST = 'FETCH_INBOX_REQUEST';
export const FETCH_INBOX_SUCCESS = 'FETCH_INBOX_SUCCESS';
export const FETCH_INBOX_FAILURE = 'FETCH_INBOX_FAILURE';

// Funds
export const FETCH_FUNDS_REQUEST = 'FETCH_FUNDS_REQUEST';
export const FETCH_FUNDS_SUCCESS = 'FETCH_FUNDS_SUCCESS';
export const FETCH_FUNDS_FAILURE = 'FETCH_FUNDS_FAILURE';

// Workflows
export const FETCH_WORKFLOW_REQUEST = 'FETCH_WORKFLOW_REQUEST';
export const FETCH_WORKFLOW_SUCCESS = 'FETCH_WORKFLOW_SUCCESS';
export const FETCH_WORKFLOW_FAILURE = 'FETCH_WORKFLOW_FAILURE';

export const IMPORT_WORKFLOW_BATCH_REQUEST = 'IMPORT_WORKFLOW_BATCH_REQUEST';
export const IMPORT_WORKFLOW_BATCH_SUCCESS = 'IMPORT_WORKFLOW_BATCH_SUCCESS';
export const IMPORT_WORKFLOW_BATCH_FAILURE = 'IMPORT_WORKFLOW_BATCH_FAILURE';

// Company
export const FETCH_COMPANY_REQUEST = 'FETCH_COMPANY_REQUEST';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_FAILURE = 'FETCH_COMPANY_FAILURE';

export const FETCH_COMPANIES_LIST_REQUEST = 'FETCH_COMPANIES_LIST_REQUEST';
export const FETCH_COMPANIES_LIST_SUCCESS = 'FETCH_COMPANIES_LIST_SUCCESS';
export const FETCH_COMPANIES_LIST_FAILURE = 'FETCH_COMPANIES_LIST_FAILURE';

export const FETCH_COMPANY_LEDGER_REQUEST = 'FETCH_COMPANY_LEDGER_REQUEST';
export const FETCH_COMPANY_LEDGER_SUCCESS = 'FETCH_COMPANY_LEDGER_SUCCESS';
export const FETCH_COMPANY_LEDGER_FAILURE = 'FETCH_COMPANY_LEDGER_FAILURE';

export const UPDATE_COMPANY_LEDGER_REQUEST = 'UPDATE_COMPANY_LEDGER_REQUEST';
export const UPDATE_COMPANY_LEDGER_SUCCESS = 'UPDATE_COMPANY_LEDGER_SUCCESS';
export const UPDATE_COMPANY_LEDGER_FAILURE = 'UPDATE_COMPANY_LEDGER_FAILURE';

export const UPDATE_COMPANY_PLAN_REQUEST = 'UPDATE_COMPANY_PLAN_REQUEST';
export const UPDATE_COMPANY_PLAN_SUCCESS = 'UPDATE_COMPANY_PLAN_SUCCESS';
export const UPDATE_COMPANY_PLAN_FAILURE = 'UPDATE_COMPANY_PLAN_FAILURE';


export const FETCH_TEAMS_LIST_REQUEST = 'FETCH_TEAMS_LIST_REQUEST';
export const FETCH_TEAMS_LIST_SUCCESS = 'FETCH_TEAMS_LIST_SUCCESS';
export const FETCH_TEAMS_LIST_FAILURE = 'FETCH_TEAMS_LIST_FAILURE';

export const FETCH_CURATED_TEAMS_REQUEST = 'FETCH_CURATED_TEAMS_REQUEST';
export const FETCH_CURATED_TEAMS_SUCCESS = 'FETCH_CURATED_TEAMS_SUCCESS';
export const FETCH_CURATED_TEAMS_FAILURE = 'FETCH_CURATED_TEAMS_FAILURE';

export const CREATE_API_CREDENTIALS_REQUEST = 'CREATE_API_CREDENTIALS_REQUEST';
export const CREATE_API_CREDENTIALS_SUCCESS = 'CREATE_API_CREDENTIALS_SUCCESS';
export const CREATE_API_CREDENTIALS_FAILURE = 'CREATE_API_CREDENTIALS_FAILURE';

export const UPDATE_API_CREDENTIALS_REQUEST = 'UPDATE_API_CREDENTIALS_REQUEST';
export const UPDATE_API_CREDENTIALS_SUCCESS = 'UPDATE_API_CREDENTIALS_SUCCESS';
export const UPDATE_API_CREDENTIALS_FAILURE = 'UPDATE_API_CREDENTIALS_FAILURE';

export const UPDATE_DEFAULT_WEBHOOK_REQUEST = 'UPDATE_DEFAULT_WEBHOOK_REQUEST';
export const UPDATE_DEFAULT_WEBHOOK_SUCCESS = 'UPDATE_DEFAULT_WEBHOOK_SUCCESS';
export const UPDATE_DEFAULT_WEBHOOK_FAILURE = 'UPDATE_DEFAULT_WEBHOOK_FAILURE';

export const UPDATE_API_SECURITY_REQUEST = 'UPDATE_API_SECURITY_REQUEST';
export const UPDATE_API_SECURITY_SUCCESS = 'UPDATE_API_SECURITY_SUCCESS';
export const UPDATE_API_SECURITY_FAILURE = 'UPDATE_API_SECURITY_FAILURE';

export const UPDATE_CHAIN_WEBHOOK_REQUEST = 'UPDATE_CHAIN_WEBHOOK_REQUEST';
export const UPDATE_CHAIN_WEBHOOK_SUCCESS = 'UPDATE_CHAIN_WEBHOOK_SUCCESS';
export const UPDATE_CHAIN_WEBHOOK_FAILURE = 'UPDATE_CHAIN_WEBHOOK_FAILURE';

export const UPDATE_WORKFLOW_WEBHOOK_REQUEST = 'UPDATE_WORKFLOW_WEBHOOK_REQUEST';
export const UPDATE_WORKFLOW_WEBHOOK_SUCCESS = 'UPDATE_WORKFLOW_WEBHOOK_SUCCESS';
export const UPDATE_WORKFLOW_WEBHOOK_FAILURE = 'UPDATE_WORKFLOW_WEBHOOK_FAILURE';

export const FETCH_COMPANY_WEBHOOK_REQUEST = 'FETCH_COMPANY_WEBHOOK_REQUEST';
export const FETCH_COMPANY_WEBHOOK_SUCCESS = 'FETCH_COMPANY_WEBHOOK_SUCCESS';
export const FETCH_COMPANY_WEBHOOK_FAILURE = 'FETCH_COMPANY_WEBHOOK_FAILURE';

export const FETCH_COMPANY_API_USER_REQUEST = 'FETCH_COMPANY_API_USER_REQUEST';
export const FETCH_COMPANY_API_USER_SUCCESS = 'FETCH_COMPANY_API_USER_SUCCESS';
export const FETCH_COMPANY_API_USER_FAILURE = 'FETCH_COMPANY_API_USER_FAILURE';

export const FETCH_ASSIGNMENTS_REQUEST = 'FETCH_ASSIGNMENTS_REQUEST';
export const FETCH_ASSIGNMENTS_SUCCESS = 'FETCH_ASSIGNMENTS_SUCCESS';
export const FETCH_ASSIGNMENTS_FAILURE = 'FETCH_ASSIGNMENTS_FAILURE';

export const PROMOTE_CONTRIBUTORS_REQUEST = 'PROMOTE_CONTRIBUTORS_REQUEST';
export const PROMOTE_CONTRIBUTORS_SUCCESS = 'PROMOTE_CONTRIBUTORS_SUCCESS';
export const PROMOTE_CONTRIBUTORS_FAILURE = 'PROMOTE_CONTRIBUTORS_FAILURE';

export const ADD_TOAST = 'ADD_TOAST';
export const CLEAR_TOAST = 'CLEAR_TOAST';

export const IMPORTED_BATCH = 'IMPORTED_BATCH';
export const RECEIVED_BATCH = 'RECEIVED_BATCH';
export const RECEIVED_BATCHES = 'RECEIVED_BATCHES';
export const RECEIVED_CHAIN = 'RECEIVED_CHAIN';
export const RECEIVED_COMPANY_ADMINS = 'RECEIVED_COMPANY_ADMINS';
export const RECEIVED_DEFINITION = 'RECEIVED_DEFINITION';
export const RECEIVED_WORKFLOWS = 'RECEIVED_WORKFLOWS';
export const REMOVED_NODES = 'REMOVED_NODES';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const UPDATED_BATCH = 'FAILED_BATCH';
export const UPDATED_NODE = 'UPDATED_NODE';
export const CONNECT_NODES = 'CONNECT_NODES';

//Notifications
export const FETCH_ALL_RELEASE_NOTES_SUCCESS = 'FETCH_ALL_RELEASE_NOTES_SUCCESS';
export const FETCH_ALL_RELEASE_NOTES_REQUEST = 'FETCH_ALL_RELEASE_NOTES_REQUEST';
export const FETCH_ALL_RELEASE_NOTES_FAILURE = 'FETCH_ALL_RELEASE_NOTES_FAILURE';

export const FETCH_RELEASE_NOTE_SUCCESS = 'FETCH_RELEASE_NOTE_SUCCESS';
export const FETCH_RELEASE_NOTE_REQUEST = 'FETCH_RELEASE_NOTE_REQUEST';
export const FETCH_RELEASE_NOTE_FAILURE = 'FETCH_RELEASE_NOTE_FAILURE';

export const CREATE_NEW_RELEASE_NOTE_REQUEST = 'CREATE_NEW_RELEASE_NOTE_REQUEST';
export const CREATE_NEW_RELEASE_NOTE_SUCCESS = 'CREATE_NEW_RELEASE_NOTE_SUCCESS';
export const CREATE_NEW_RELEASE_NOTE_FAILURE = 'CREATE_NEW_RELEASE_NOTE_FAILURE';

export const UPDATE_RELEASE_NOTE_REQUEST = 'UPDATE_RELEASE_NOTE_REQUEST';
export const UPDATE_RELEASE_NOTE_SUCCESS = 'UPDATE_RELEASE_NOTE_SUCCESS';
export const UPDATE_RELEASE_NOTE_FAILURE = 'UPDATE_RELEASE_NOTE_FAILURE';

export const FETCH_LATEST_RELEASE_NOTES_REQUEST = 'FETCH_LATEST_RELEASE_NOTES_REQUEST';
export const FETCH_LATEST_RELEASE_NOTES_SUCCESS = 'FETCH_LATEST_RELEASE_NOTES_SUCCESS';
export const FETCH_LATEST_RELEASE_NOTES_FAILURE = 'FETCH_LATEST_RELEASE_NOTES_FAILURE';

export const DELETE_RELEASE_NOTE_REQUEST = 'DELETE_RELEASE_NOTE_REQUEST';
export const DELETE_RELEASE_NOTE_SUCCESS = 'DELETE_RELEASE_NOTE_SUCCESS';
export const DELETE_RELEASE_NOTE_FAILURE = 'DELETE_RELEASE_NOTE_FAILURE';

// Advanced Exports
export const FETCH_EXPORTHISTORY_REQUEST = 'FETCH_EXPORTHISTORY_REQUEST';
export const FETCH_EXPORTHISTORY_SUCCESS = 'FETCH_EXPORTHISTORY_SUCCESS';
export const FETCH_EXPORTHISTORY_FAILURE = 'FETCH_EXPORTHISTORY_FAILURE';




