import React from 'react';
import PropTypes from 'prop-types';

import { newestFirst } from '../../utils/sorts';
import BatchOverview from '../../containers/BatchOverview';
import DropZone from '../../containers/DropZone';
import Panel from '../../containers/Panel';
import LoadState from '../../containers/LoadState';
import Note from '../../containers/Note';

/**
 * ChainBatchManagement
 */
const Batches = ( {
    batches,
    workflowId,
    workflowName,
    handleUploadBatch,
    loading,
    projectId,
    uploadErrors,
    archived,
    batchNotification,
    handleOptionsSelect
  } ) => {
    const projectIdString = projectId ? projectId.toString() : '';
    return (
      <Panel
        title={workflowName}
        options={[
          { label: 'Download CSV Template', value: 'csv' },
          { label: 'Advanced Export', value: 'advancedExport' },
          { label: `Batch Notifications: ${batchNotification ? 'On' : 'Off'}`, value: 'batchNote' },
          { label: 'Bulk Item Cancel', value: 'bulkCancel' }
        ]}
        handleSelectOption={ handleOptionsSelect } >
        <div style={styles.chainBatchManagement}>
          <DropZone
            handleDrop={handleUploadBatch}
            locked={archived}
            lockedLabel={'Your workflow is currently archived'}/>
          {!batches.length && loading && <LoadState/>}

          {!!uploadErrors &&
          <div>
            {uploadErrors.map( ( err, i ) => (
              <div key={i} style={{ marginBottom: 1 }}>
                <Note
                  type={err.type}
                  note={err.note}
                />
              </div>
            ) )}
          </div>
          }

          {batches.sort( newestFirst ).map( ( batch, i ) => (
            <div key={i} style={styles.batch}>
              <BatchOverview
                projectId={projectIdString}
                batchType={'workflowBatch'}
                disableActions={!!batch.id_chain}
                batch={batch}
                workflowId={workflowId}
              />
            </div>
          ) )}
        </div>
      </Panel>
    );
};

/* Styles */
const styles = {
  chainBatchManagement: {
    padding: 16,
  },
  batch: {
    marginTop: 16,
  }
};

/* PropTypes */
Batches.propTypes = {
  batches: PropTypes.arrayOf( PropTypes.object ).isRequired,
  handleUploadBatch: PropTypes.func.isRequired,
};

export default Batches;
