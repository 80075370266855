import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import {
  EXPORT_CHAIN_BATCH,
  RELEASE_ITEMS,
  CANCEL_ITEMS,
  PAUSE_ITEMS,
  EXPORT_WORKFLOW_BATCH,
  RESUME_ITEMS,
} from '../constants/modalTypes';
import { toggleModal } from '../actions/app';
import { fetchChainBatch } from '../actions/chains';
import { exportBatch, fetchWorkflowBatches, fetchWorkflow } from '../actions/workflows';
import colors from '../styles/colors';
import InlineButton from '../components/InlineButton';
import InlineList from '../components/InlineList';
import InlineSelect from '../components/InlineSelect';
import Tile from '../components/Tile';
import GreenOverlay from '../components/GreenOverlay';
import StackedProgressBar from '../components/StackedProgressBar';
import Note from '../containers/Note';
import { Caption, Label } from '../components/type';


const batchStates = {
  'canceled': 'Canceled',
  'open': 'Active',
  'active': 'Active',
  'new': 'New',
  'staged': 'Staged',
  'pending': 'Pending',
  'completed': 'Completed',
  'uploading': 'Uploading',
  'failed': 'Failed',
  'processing': 'Processing',
  'paused': 'Paused',
};

const unknownColumnsMsg = 'Your uploaded items have been imported successfully. Please note that there were ' +
  'unrecognized columns included in the file. The data from the following columns was not ' +
  'included in the import: ';

/**
 * BatchOverview
 */
class BatchOverview extends React.Component {
  constructor( props ) {
    super( props );
    this.handleAction = this.handleAction.bind( this );
    this.handleDownloadWorkflowBatch = this.handleDownloadWorkflowBatch.bind( this );
    this.handleDownloadChainBatch = this.handleDownloadChainBatch.bind( this );
    this.state = {
      removeOverlay: false,
    };
  }

  componentWillMount() {
    const createdDate = moment.utc(this.props.batch.created || this.props.batch.created_at);
    const currentDate = moment().format();
    const timeDifference = Math.abs(createdDate.diff(currentDate, 'seconds'));

    if ( (this.props.batch.state === 'new' || this.props.batch.state === 'processing') && timeDifference < 86400 ) {
      setTimeout( () => {
        this.refetchState();
      }, 2000 );
    }
  }

  componentWillUpdate( nextProps ) {
    let stillOpening = (
      !!nextProps.batch.items &&
      !!this.props.batch.items &&
      !!nextProps.batch.items.open) && (
        nextProps.batch.items.open !== this.props.batch.items.open
    );
    if ( nextProps.batch.items && nextProps.batch.items.pending && !this.props.batch.items.pending ) {
      this.setState( { temp: true } );
      setTimeout( () => {
        this.refetchState(stillOpening);
      }, 2000 );
    }
    if ( nextProps.batch.isExporting ) {
      setTimeout( () => {
        this.setState( { removeOverlay: true } );
      }, 3000 );
    }
  }

  refetchState(stillOpening) {
    if (this.isFetching) {
      return false;
    }

    if (!this.refetchCount) {
      this.refetchCount = 0;
    }

    this.isFetching = true;

    return this.props.batchType === 'workflowBatch' ? this.refetchWorkflowBatch() : this.refetchChainBatch(stillOpening);
  }

  refetchWorkflowBatch() {
    const action = fetchWorkflowBatches(this.props.batch.id_workflow);

    this.props.dispatch( action )
      .then( ( { batch } ) => {
        this.isFetching = false;
        if ( batch && batch.state === 'new' && this.refetchCount < 6 ) {
          this.refetchCount++;
          setTimeout( () => this.refetchState(), 8000 );
        } else {
          this.syncWorkflow();
        }
      } );
  }

  refetchChainBatch(stillOpening) {
    const action = fetchChainBatch(this.props.batch.chainId, this.props.batch.id);

    this.props.dispatch( action )
      .then( ( { batch } ) => {
        this.isFetching = false;
        if (
          batch && batch.state === 'new' ||
          batch.state === 'processing' ||
          batch.items.hasOwnProperty('pending') ||
          (batch.state === 'staged' && batch.items.hasOwnProperty('staged') && batch.items.staged !== batch.rowCount) ||
          (batch.state === 'staged' && !batch.items.hasOwnProperty('staged')))
        {
          setTimeout( () => this.refetchState(), 5000 );
        } else if (batch && (batch.state === 'staged' || batch.state === 'open' || stillOpening)) {
          setTimeout( () => {
            this.props.dispatch( fetchChainBatch (this.props.batch.chainId, this.props.batch.id ));
          }, 5000);
        }
      } );
  }

  syncWorkflow() {
    this.props.dispatch( fetchWorkflow( this.props.workflowId ) );
  }

  handleDownloadWorkflowBatch() {
    const items = this.props.batch.items ? this.props.batch.items : null;
    if ( items ) {
      this.toggleExportWorkflowBatch();
    } else {
      this.exportAll();
    }
  }

  toggleExportWorkflowBatch() {
    this.props.dispatch( toggleModal( EXPORT_WORKFLOW_BATCH, {
      batchId: this.props.batch.id,
      workflowId: this.props.workflowId,
    } ) );
  }

  exportAll() {
    this.props.dispatch( exportBatch( this.props.workflowId, this.props.batch.id, 'all' ) );
  }

  handleDownloadChainBatch() {
    this.props.dispatch( toggleModal( EXPORT_CHAIN_BATCH, {
      batchId: this.props.batch.id,
      chainId: this.props.batch.chainId,
      projectId: this.props.projectId,
      enableOverlayClick: true,
    } ) );
  }

  handleAction( action ) {
    if ( action === 'release' ) {
      return this.toggleReleaseModal();
    }
    if ( action === 'pause' ) {
      return this.togglePauseModal( 'pause' );
    }
    if ( action === 'resume' ) {
      return this.togglePauseModal( 'resume' );
    }
    return this.toggleCancelModal();
  }

  toggleReleaseModal() {
    this.props.dispatch( toggleModal( RELEASE_ITEMS, {
      batchType: this.props.batchType,
      workflowId: this.props.workflowId,
      batch: this.props.batch,
      stagedItemCount: this.props.batch.items.staged,
    } ) );
  }

  toggleCancelModal() {
    this.props.dispatch( toggleModal( CANCEL_ITEMS, {
      batch: this.props.batch,
      batchType: this.props.batchType,
    } ) );
  }

  togglePauseModal( type ) {
    if ( type === 'pause' ) {
      return this.props.dispatch( toggleModal( PAUSE_ITEMS, {
        batch: this.props.batch,
        type,
        workflowId: this.props.workflowId,
        enableOverlayClick: true,
      } ) );
    }
    return this.props.dispatch( toggleModal( RESUME_ITEMS, {
      batch: this.props.batch,
      type,
      workflowId: this.props.workflowId,
      enableOverlayClick: true,
    } ) );

  }

  getProgresses( batch ) {
    const batchItems = batch.items || {};
    if ( Object.keys( batchItems ).length && batch.state !== 'failed' ) {
      return [
        { label: 'Staged', amount: batchItems.staged, color: colors.purple },
        { label: 'Paused', amount: batchItems.paused, color: colors.darkerOrange },
        { label: 'Open', amount: batchItems.open, color: colors.blue },
        { label: 'Completed', amount: batchItems.completed, color: colors.green },
        { label: 'Canceled', amount: batchItems.canceled, color: colors.orange },
        { label: 'New', amount: batchItems.new, color: colors.purple },
        { label: 'Pending', amount: batchItems.pending, color: colors.placeholder },
        { label: 'Error', amount: batchItems.error, color: colors.negativeLight },
      ];
    }
    return [ { label: 'No Items', amount: 0, color: colors.lighter } ];
  }

  getActions() {
    const actions = [];
    if ( this.props.batch.items && this.props.batch.items.staged && ( !this.props.batch.items.paused || (this.props.batchType && this.props.batchType === 'workflowBatch' ) ) ) {
      actions.push(
        {
          label: 'Release to Contributors',
          value: 'release',
          title: 'Releasing items makes them available for contributors to work. You will be liable to pay for completed work.',
        });
    }
    if ( this.props.batch.state !== 'canceled' ) {
      actions.push(
        {
          label: 'Cancel Available Items',
          value: 'cancel',
          title: 'Canceling items removes those items from the queue. Partially completed items may ' +
          'incur some costs. If you cancel items when dynamic pay is enabled, contributors may not ' +
          'be paid appropriately for their work.',
        });
    }
    if ( this.props.batch.items && this.props.batch.items.open ) {
      actions.push(
        {
          label: 'Pause Open Items',
          value: 'pause',
          title: 'Pausing items makes them unavailable for contributors to work. You can resume these items later on.',
        } );
    }
    if ( this.props.batch.items && this.props.batch.items.paused ) {
      actions.push(
        {
          label: 'Resume Paused Items',
          value: 'resume',
          title: 'Resuming paused items makes them available for contributors to work. You will be liable to pay for completed work.',
        } );
    }
    return actions;
  }

  get nameError() {
    const batch = this.props.batch;
    return batch.error_filename || batch.errorFileKey;
  }

  render() {
    const batch = this.props.batch;
    const { disableActions, batchType, projectId } = this.props;
    const progresses = this.getProgresses( batch );

    const ifChainBatchErrors = batch.validationErrors && !!batch.validationErrors.length && batchType !== 'workflow';
    const unknownColumnFields = batch.unknownColumns ? batch.unknownColumns.join(', ') : '';
    const ifUnknownColumns = (batch.unknownColumns && batch.unknownColumns.length > 0);

    return (
      <div style={styles.batchOverview}>
        <div style={styles.wrapper}>

          {batch.isExporting && !this.state.removeOverlay &&
          <GreenOverlay
            content={'This batch has been queued for export. ' +
            'You will receive an email with instructions to download your export.'}/>
          }

          <InlineList spacing={16}>
            {/* Status */}
            <Tile label={batchStates[batch.state] || batch.state} failed={batch.state === 'failed'}/>
            {/* Info */}
            <div style={styles.info}>
              <div style={ styles.wrapText }>
                <Caption color="dark" title={batch.filename}>{batch.filename}</Caption>
              </div>
              <Caption
                italic={true}
                color="light">
                Created {moment.utc( batch.createdAt || batch.created_at ).fromNow()}
              </Caption>
            </div>
            {/* Progress */}
            <div style={styles.progress}>
              <StackedProgressBar
                progresses={progresses}
              />
            </div>

            {/* Actions */}
            <div style={styles.actions}>
              {!disableActions &&
              <InlineSelect
                disabled={
                  disableActions ||
                  batch.state === 'completed' ||
                  batch.state === 'canceled' ||
                  batch.state === 'failed' ||
                  batch.state === 'new' ||
                  batch.state === 'processing' ||
                  (batch.hasOwnProperty('releasable') && !batch.releasable) ||
                  batch.hasOwnProperty('items') && batch.items.hasOwnProperty('pending')
                }
                value=""
                defaultLabel="Actions"
                handleSelect={this.handleAction}
                options={this.getActions()}
              />
              }
              {disableActions && batchType === 'workflowBatch' && !!projectId &&
              <div style={{ paddingLeft: 8 }}>
                <Label href={`/projects/${projectId}/chains/${batch.id_chain}`}>Edit Chain</Label>
              </div>
              }
            </div>
            {/* Download */}
            <InlineButton
              disabled={batch.state === 'failed'}
              handleClick={() => {
                if (batchType !== 'workflowBatch') {
                  this.handleDownloadChainBatch();
                } else {
                  this.handleDownloadWorkflowBatch();
                }
              }}>
              <i className="fa fa-download"/>
            </InlineButton>
          </InlineList>
        </div>
        {this.nameError &&
        <div style={{ marginLeft: 16, marginBottom: 8 }}>
          <Caption italic={true} color="negative">
            Some of the items you uploaded are invalid. The valid items have been staged.
            &nbsp;
            <a
              style={{ color: colors.darker, fontWeight: 800, textDecoration: 'underline' }}
              href={this.nameError}
              target="_blank"
            >
              Download invalid items.
            </a>
          </Caption>
        </div>
        }
        {batch.error &&
        <Note note={batch.error} type="error"/>
        }
        {ifChainBatchErrors &&
        batch.validationErrors.map( ( e, i ) => typeof(e) !== 'string' ?
          <Note key={i} note={'Your batch upload failed. Please try to upload the file again.'+ 
          ' Contact support@onespace.com if this issue persists.'} type="error"/>
          :
          <Note key={i} note={e} type="error"/>
         )
        }
        {(ifUnknownColumns && batch.state !== 'failed') &&
        <Note note={unknownColumnsMsg + unknownColumnFields + '.'} type="notification"/>
        }
        {batch.pauseError &&
        <Note note={batch.pauseError} type="notification"/>
        }
      </div>
    );
  }
}

/* Styles */
const styles = {
  batchOverview: {
    border: `2px solid ${colors.divider}`,
    borderRadius: 4,

  },
  wrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    minHeight: 96,
    paddingLeft: 16,
    paddingRight: 16,
    boxSizing: 'border-box',
  },
  wrapText: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    height: 50,
    overflow: 'hidden',
    minWidth: 200,
  },
  info: { flex: 1 },
  progress: {
    width: 320,
  },
  actions: {
    width: 150,
  },
  errorNote: {}
};

/* PropTypes */
BatchOverview.propTypes = {
  batch: PropTypes.shape( {
    id: PropTypes.number,
    chainId: PropTypes.string, // Only if chain batch
    state: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
    created_at: PropTypes.oneOfType( [ PropTypes.string, PropTypes.instanceOf(Date) ] ).isRequired,
    items: PropTypes.shape( {
      new: PropTypes.number
    } ),
  } ),
  workflowId: PropTypes.string,
  projectId: PropTypes.string,
  disable: PropTypes.bool,
};

export default connect( () => {return {};} )( BatchOverview );
