import React from 'react';
import ReactDOM from 'react-dom';

import {AppContainer} from 'react-hot-loader';
import { Provider } from 'react-redux';
import Routes from './routes';
import configureStore from './reducers/configureStore';
import reducers from './reducers'; // Or wherever you keep your reducers

const is = document.getElementById('is') && document.getElementById('is').innerHTML;
const store = configureStore(JSON.parse(is), reducers);

const renderApp = (Component) => {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <Component/>
      </Provider>
    </AppContainer>,
    document.getElementById('app')
  );
};

renderApp(Routes);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./routes', () => {
    renderApp(require('./routes').default);
  });
}