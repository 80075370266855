import {
  FETCH_ASSIGNMENTS_REQUEST,
  FETCH_ASSIGNMENTS_SUCCESS,
  FETCH_ASSIGNMENTS_FAILURE,
  PROMOTE_CONTRIBUTORS_REQUEST,
  PROMOTE_CONTRIBUTORS_SUCCESS,
  PROMOTE_CONTRIBUTORS_FAILURE,
} from '../constants/actionTypes';

export function fetchAssignments() {
  return {
    types: [FETCH_ASSIGNMENTS_REQUEST, FETCH_ASSIGNMENTS_SUCCESS, FETCH_ASSIGNMENTS_FAILURE],
    fetchRoute: `/api/company/assignments?all=true`,
    getFormattedResponse: ( assignments ) => ({ assignments: assignments }),
  };
}

export function promoteContributors(workerIds, level, contributorNote, internalNote, skillId, skillName) {
  return {
    types: [PROMOTE_CONTRIBUTORS_REQUEST, PROMOTE_CONTRIBUTORS_SUCCESS, PROMOTE_CONTRIBUTORS_FAILURE],
    fetchRoute: `/api/contributors/skills/${skillId}`,
    fetchMethod: 'post',
    fetchBody: {
      workerIds,
      skillStanding: level,
      freelancerNote: contributorNote,
      internalNote,
      skillId,
      skillName,
    }
  };
}