import React from 'react';
import PropTypes from 'prop-types';

import colors from '../styles/colors';
import Button from '../components/Button';
import { Title, Regular } from '../components/type';

const EmptyState = ({ label, description, ctaLabel, handleClickCTA }) => (
  <div style={styles.emptyState}>
    <Title color="dark">{label}</Title>
    {description &&
    <Regular top={40}>{description}</Regular>
    }
    {(ctaLabel && handleClickCTA) &&
    <div style={styles.cta}>
      <Button handleClick={handleClickCTA}>{ctaLabel}</Button>
    </div>
    }
  </div>
);

/* Styles */
const styles = {
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 64,
    paddingBottom: 64,
    border: `1px solid ${colors.border}`,
    borderRadius: 8,
    background: 'white',
  },
  cta: {
    marginTop: 24,
  },
};

/* PropTypes */
EmptyState.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  ctaLabel: PropTypes.string,
  handleClickCTA: PropTypes.func,
};

export default EmptyState;
