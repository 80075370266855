import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DELETE_PROJECT } from '../../constants/modalTypes';
import { toggleModal } from '../../actions/app';
import { deleteProject } from '../../actions/projects';
import Button from '../../components/Button';
import InlineSaveState from '../../components/InlineSaveState';
import { Label } from '../../components/type';

class DeleteProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Storing the project in state so we have it after delete
      project: props.project,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleClose() {
    this.props.dispatch(toggleModal(DELETE_PROJECT));
  }

  handleDelete() {
    this.props.dispatch(deleteProject(this.props.projectId));
  }

  render() {
    const project = this.state.project;
    return (
      <div style={styles.deleteProject}>
        {/* Confirmation */}
        <Label bottom={16}>Are you sure you want to delete {project.name}?</Label>

        {(!this.props.project || this.props.project.isDeleting) ?
          <InlineSaveState
            saving={this.props.project && this.props.project.isDeleting}
            savingLabel={`Deleting ${project.name}`}
            savedLabel={`Deleted ${project.name}`}
            savedCallback={this.handleClose}
          /> :
          <Button
            disabled={this.props.project.isDeleting}
            handleClick={this.handleDelete}>
            Delete Project
          </Button>
        }
        <Label
          top={16}
          italic={true}>
          All workflows associated with this project will be deleted. This process is irreversible.
        </Label>
        <Label
          top={16}
          underline={true}
          handleClick={this.handleClose}>
          Nevermind! I don't want to delete this project.
        </Label>
      </div>
    );
  }
}

/* Styles */
const styles = {
  deleteProject: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

/* PropTypes */
DeleteProject.propTypes = {
  projectId: PropTypes.string.isRequired,
};

/* Map State to Props */
function select(state) {
  const projectId = state.app.modalMeta.projectId;
  if (!projectId) {
    throw new Error('projectId must be passed into DeleteProject modal');
  }
  return {
    projectId,
    project: state.projects.items[projectId],
  };
}

export default connect(select)(DeleteProject);
