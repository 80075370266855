import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';

import {toggleModal} from '../../actions/app';
import { RESET_API_PASSWORD } from '../../constants/modalTypes';
import { Title, Regular, Label } from '../../components/type';
import Button from '../../components/Button';
import { updateAPICredentials } from '../../actions/company';

import createTypeComponent from '../../utils/createTypeComponent';
export const ThinNavTitle = createTypeComponent('nav-title', 24, 400, 32);

class ResetAPIPassword extends Component {

  updateAPIPassword() {
    const { workerId} = this.props;
    this.props.dispatch(updateAPICredentials(workerId));
    this.props.dispatch(toggleModal(RESET_API_PASSWORD));
  }

  render() {
    return (
      <div className={css(styles.innerModalStyle)}>
        <div style={{paddingBottom: 20}}>
          <ThinNavTitle color="dark">
            This action cannot be undone.
          </ThinNavTitle>
        </div>
        <Title color="dark">
          Resetting the API password will invalidate the client’s current credentials.
        </Title>
        <Regular color="dark">
          They will not be able to use their API until after they make the appropriate updates with the new credentials.
        </Regular>
        <div className={css(styles.twentyPadding)}>
          <Label italic={true} color="dark">
            Are you sure you want to reset the API password for this client?
          </Label>
        </div>
        <div style={{display: 'flex'}}>
          <div style={{marginRight: 20}}>
            <Button
              handleClick={() => {this.updateAPIPassword();}}>
              Yes
            </Button>
          </div>
          <Button
            handleClick={() => {this.props.dispatch(toggleModal(RESET_API_PASSWORD));}}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  innerModalStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 40,
    paddingBottom: 40
  },
  twentyPadding: {
    display: 'flex',
    paddingBottom: 20,
    paddingTop: 20
  }
});

function select(state) {
  const workerId = state.app.modalMeta.workerId;
  return {workerId};
}

export default connect(select)(ResetAPIPassword);