import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import uuid from 'uuid';
import TaskUI from './taskui';
import reducer from './reducers';
import Static from './Static';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import callAPIMiddleware from '../../../../utils/callAPIMiddleware';

export default function(inputOutputs, elements, assignmentName) {
  const keyedElements = {};
  elements.forEach((e, i) => {
    let hasChars = !!(e.data.maxchars || e.data.minchars);
    let hasWords = !!(e.data.maxwords || e.data.minwords);
    if(hasChars && hasWords) {
      if(e.data.limitType) {
        if(e.data.limitType === 'characters') {
          delete e.data.maxwords;
          delete e.data.minwords;
        } else {
          delete e.data.minchars;
          delete e.data.maxchars;
        }
      } else {
        delete e.data.minchars;
        delete e.data.maxchars;
      }
    }
    if((hasChars || hasWords) && !e.data.limitType) {
      if(hasChars) {
        e.data.limitType = 'characters';
      }
      if(hasWords) {
        e.data.limitType = 'words';
      }
    }
    if(!e.tempId) {
      e.tempId = uuid.v4();
    }
    keyedElements[i] = e;
  });

  let enhancer;
  if (process.env.NODE_ENV === 'production') {
    enhancer = compose(
      applyMiddleware(
        callAPIMiddleware,
        thunkMiddleware
      ),
    );
  } else {
    enhancer = composeWithDevTools(
      applyMiddleware(
        callAPIMiddleware,
        thunkMiddleware
      ),
    );
  }

  const store = createStore(
    reducer,
    {inputOutputs, elements: keyedElements},
    enhancer
  );

  class Main extends React.Component {
    render() {
      const { edit, handleAddInput, handleChangeElements, toggleTaskUI, consensusEnabled} = this.props;
      if(!edit) {
        return (
          <div
            onClick={this.props.clickComponent}
            style={{
              marginBottom: 32,
              cursor: 'pointer'
            }}>
            <Provider store={store}>
              <Static elements={elements} isStatic={true} toggleTaskUI={toggleTaskUI} />
            </Provider>
          </div>
        );
      }

      return (
        <Provider store={store}>
          <TaskUI
            handleAddInput={handleAddInput}
            handleChangeElements={handleChangeElements}
            assignmentName={assignmentName}
            isSupportRole={window.role === 'onespace-support' || window.location.pathname === '/taskui'}
            consensusEnabled={consensusEnabled} />
        </Provider>
      );
    }
  }
  return Main;
}
