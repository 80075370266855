import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';

import colors from '../styles/colors';
import { toggleModal } from '../actions/app';
import { Title } from '../components/type';
import IconButton from '../components/IconButton';

class ModalWrapper extends Component {
  close() {
    this.props.dispatch( toggleModal( this.props.modal ) );
  }

  render() {
    const { title, enableOverlayClick } = this.props;
    return (
      <div>
        <div className={css( styles.modalHeader )}>
          <Title uppercase={true} color="dark">{title}</Title>
          {enableOverlayClick &&
          <IconButton color="dark" icon="times" label="" underline={true} handleClick={() => this.close()}>
            Close
          </IconButton>
          }
        </div>
        <div style={{ padding: 16, boxSizing: 'border-box', overflow: 'auto' }}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create( {
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 64,
    minWidth: '100vw',
    boxSizing: 'border-box',
    paddingLeft: 16,
    paddingRight: 16,
    borderBottom: `1px solid ${colors.border}`,
    '@media (min-width: 576px)': {
      minWidth: 576,
    },
  },
} );

function select( state ) {
  return {
    modal: state.app.modal,
    enableOverlayClick: state.app.modalMeta.enableOverlayClick,
  };
}

export default connect( select )( ModalWrapper );
