import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';

import stateHelpers from '../utils/stateHelpers';
import objectToArray from '../utils/_objectToArray';

import { fetchProfile } from '../actions/profile';
import { fetchInbox } from '../actions/inbox';
import { fetchCompany } from '../actions/company';
import { fetchLatestReleaseNotes } from '../actions/releaseNotes';
import { toggleModal, removeToastAtIndex } from '../actions/app';

import modalStyle from '../styles/modal';

import modals from '../containers/modals';
import Header from '../containers/Header';

import ToastContainer from '../containers/ToastContainer';

import { RELEASE_NOTIFICATION_MODAL } from '../constants/modalTypes';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navOffsetTop: 0
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchInbox());
    this.props.dispatch(fetchProfile())
      .then(res => {
        if(res && !res.seen_latest_release_note) {
        this.props.dispatch(fetchLatestReleaseNotes())
          .then(() => {
            this.props.dispatch(toggleModal(RELEASE_NOTIFICATION_MODAL, {
              enableOverlayClick: true,
              preview: false,
            }));
          });
        }
      });

    this.props.dispatch(fetchCompany());
  }

  handleCloseModal() {
    const { dispatch, modal } = this.props;
    this.setState({ isClosingModal: true });
    setTimeout(() => {
      dispatch(toggleModal(modal));
      this.setState({ isClosingModal: false });
    }, 300);
  }

  render() {
    const { userName, userPicture, unreadInboxCount, modal, fullAdmin, apiAdmin, enableOverlayClick, userRole, companyName } = this.props;
    return (
      <div
        style={styles}>
        <Header
          userName={userName}
          fullAdmin={fullAdmin}
          apiAdmin={apiAdmin}
          userPicture={userPicture}
          messageCount={unreadInboxCount || 0}
          handleSubmitSearch={search => {
            window.location.href = `/search/contributors?q=${search}`;
          }}
          userRole={userRole}
          companyName={companyName}
        />
        {this.props.children}
        <Modal
          shouldCloseOnOverlayClick={enableOverlayClick}
          contentLabel="Modal"
          isOpen={!!modal}
          style={modalStyle}
          overlayClassName={this.state.isClosing ? 'ReactModal__Overlay--closing' : ''}
          onRequestClose={() => this.handleCloseModal()}
          ariaHideApp={false}>
          {modals[modal]}
        </Modal>
        {!!this.props.toasts.length &&
        <ToastContainer
          toasts={this.props.toasts}
          handleClear={(index) => this.props.dispatch(removeToastAtIndex(index))}
        />
        }
      </div>
    );
  }
}


/* Styles */
const styles = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  position: 'absolute',
  width: '100%',
  top: 0,
  left: 0,
};

/* PropTypes */
App.propTypes = {
  modal: PropTypes.string, // constants/modalType
  userName: PropTypes.string,
  fullAdmin: PropTypes.bool,
  userPicture: PropTypes.string,
  unreadInboxCount: PropTypes.number,
  inboxFetching: PropTypes.bool,
  children: PropTypes.node.isRequired, // Actual Page
  userRole: PropTypes.string,
  companyName: PropTypes.string,
};
App.defaultProps = {
  userPicture: '/no-user-image.gif',
};

/* Map State To Props */
function select(state) {
  const currentUser = state.users.items && state.users.items[state.app.user];
  const personalProfile = state.profile.personal;
  return {
    enableOverlayClick: state.app.modalMeta && state.app.modalMeta.enableOverlayClick,
    modal: state.app.modal,
    toasts: state.app.toasts || [],
    userName: currentUser && currentUser.name,
    fullAdmin: stateHelpers.getUserRole(state) === 'admin' || stateHelpers.getUserRole(state) === 'onespace-support',
    apiAdmin: stateHelpers.isApiAdmin(state),
    userPicture: personalProfile && personalProfile.photoUrl,
    unreadInboxCount: state.app.unreadInboxCount,
    inboxFetching: state.app.inboxFetching,
    userRole: stateHelpers.getUserRole(state),
    companyName: stateHelpers.getCompanyName(state),
    latestReleaseNotes: objectToArray(state.releaseNotes.latestReleaseNotes),
  };
}

export default connect(select)(App);
