import React from 'react';
import PropTypes from 'prop-types';
import ChainingProcessor from '../../../../components/ChainingProcessor';

const Processors = ({ data, handleUpdateProcessors, disabled, importFileError, preError, postError }) => (
  <ChainingProcessor
    data={data}
    handleUpdateProcessors={(val, property) => handleUpdateProcessors(val, property)}
    disabled={disabled}
    importFileError={importFileError}
    preError={preError}
    postError={postError}
  />
);

/* PropTypes */
Processors.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  disabled: PropTypes.bool,
  handleUpdateProcessors: PropTypes.func,
  styles: PropTypes.object,
};

export default Processors;

