import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Input from './Input';
import IconButton from './IconButton';
import InlineButton from './InlineButton';
import FlatButton from './FlatButton';
import InlineList from './InlineList';
import { ChainHeading } from './type';

/**
 * InlineEdit
 * Displays the label in a Title component with an edit button
 * When edit is clicked an Input field shows
 * Error handling is provided
 */
class InlineEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempTitle: '',
      editing: props.isEditing,
    };
    this.setEditing = this.setEditing.bind(this);
    this.setTempTitle = this.setTempTitle.bind(this);
  }

  componentWillUpdate(nextProps) {
    // Focus the input when starting to edit
    if (!this.props.editing && nextProps.editing) {
      this.input.focus();
    }
    // If successfully saved, stop editing
    if (!nextProps.isSaving && this.props.isSaving && !nextProps.error) {
      this.setState({ tempTitle: '', editing: false });
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.isEditing && nextProps.isEditing !== this.state.editing) {
      this.setEditing();
    }
  }

  setEditing(editing = true) {
    this.setState({ editing: true, tempTitle: editing ? this.props.label : '' });
  }

  setTempTitle(tempTitle) {
    const { error, handleClearError, handleChange } = this.props;
    if (error && handleClearError) {
      handleClearError();
    }
    if (handleChange) {
      handleChange(tempTitle);
    }
    this.setState({ tempTitle });
  }

  render() {
    const { label, isSaving, error, handleSave, handleClearError, hide } = this.props;
    return (
      <div style={styles.inlineEditTitle}>

        <InlineList style={styles.staticTitle} spacing={16}>
          {/* Static Label */}
          <div style={{margin: '16px 0px'}}>
            <ChainHeading color="dark" className='t-inline-edit-label'>{label}</ChainHeading>
          </div>
          {/* Edit Button */}
          {!hide &&
          <IconButton
            icon="edit"
            handleClick={this.setEditing}>
            Edit
          </IconButton>
          }

        </InlineList>

        {/* Editing View */}
        {this.state.editing &&
        <div style={styles.editing}>
          {/* Input */}
          <InlineList spacing={16}>
            <div style={styles.input}>
              <Input
                disabled={isSaving}
                handleGetInput={input => this.input = input}
                handleChange={this.setTempTitle}
                value={this.state.tempTitle}
                error={error}
              />
            </div>
            {/* Save Button */}
            <InlineButton
              loading={isSaving}
              handleClick={() => handleSave(this.state.tempTitle)}>
              {isSaving ? 'Saving...' : 'Save Name'}
            </InlineButton>
            <FlatButton
              handleClick={() => {
                if (handleClearError) {
                  handleClearError();
                }
                this.setState({ editing: false });
              }}>
              Cancel
            </FlatButton>
          </InlineList>
        </div>
        }
      </div>
    );
  }
}

/* Styles */
const styles = {
  inlineEditTitle: {
    position: 'relative',
  },
  staticTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  editing: {
    position: 'absolute',
    top: 0,
    left: 0,
    background: 'white',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    width: 380
  },
};

/* PropTypes */
InlineEdit.propTypes = {
  label: PropTypes.string.isRequired,
  saving: PropTypes.bool,
  error: PropTypes.string,
  handleSave: PropTypes.func.isRequired,
  handleChange: PropTypes.func,
  handleClearError: PropTypes.func,
  hide: PropTypes.bool,
};

export default InlineEdit;
