import React from 'react';
import { Label, Caption } from '../../../components/type';
import Input from '../../../components/Input';
import TextArea from '../../../components/TextArea';
import PluginSettings from '../PluginSettings';
import IOSettings from '../IOSettings';
import { mappingTypeOptions } from '../PluginSettings/constants';

const SettingsReadOnly = ({ chainPluginData, styles }) => (
  <div style={styles.innerContainer}>
    <div style={{ marginBottom: 20 }}>
      <Label bold italic color="light">You're viewing version {chainPluginData.version}</Label>
    </div>
    <div style={styles.formField}>
      <Label color="light">Name</Label>
      <Input
        disabled={true}
        value={chainPluginData.name}
        label="Name"
        handleChange={() => {}}
      />
    </div>
    <div style={styles.formField}>
      <Label color="light">Description</Label>
      <TextArea disabled={true} value={chainPluginData.description} handleChange={() => {}} rows={4} />
    </div>
    <div style={styles.formField}>
      <Label color="light">Instructions</Label>
      <TextArea disabled={true} value={chainPluginData.instructions} handleChange={() => {}} rows={4} />
    </div>
    <div style={styles.formField}>
      <Label color="light">Plugin Type</Label>
      <div style={{ display: 'inline-block' }}>
        <label style={{ flex: 1 }}>
          <div style={ styles.radioBtn }>
            <Input
              disabled={true}
              checked={chainPluginData.type === 'lambda'}
              type="radio"
              value="lambda"
              handleChange={() => {}}/>
          </div>
          <div style={styles.labels}>
            <Caption>Lambda Function</Caption>
          </div>
        </label>
        <label style={{ flex: 1 }}>
          <div style={ styles.radioBtn }>
            <Input
              disabled={true}
              checked={chainPluginData.type === 'api'}
              type="radio"
              value="api"
              handleChange={() => {}}/>
          </div>
          <div style={styles.labels}>
            <Caption>External API Call</Caption>
          </div>
        </label>
      </div>
    </div>
    <div>
      <PluginSettings
        readOnly={true}
        data={chainPluginData.typeSettings}
        styles={styles}
      />

      {[{'type':'inputs'}, {'type':'outputs'}].map((item, i) => {
        return (
          <IOSettings
            readOnly={true}
            key={i}
            type={item.type}
            inputMappingType={chainPluginData.inputMappingType}
            outputMappingType={chainPluginData.outputMappingType}
            options={item.type === 'inputs' ? chainPluginData.inputs : chainPluginData.outputs}
            mappingTypeOptions={mappingTypeOptions}
            styles={styles}
            chainPluginData={chainPluginData}
          />
        );
      })}

    </div>
  </div>
);

export default SettingsReadOnly;

