import React, {Component} from 'react';
import {DropTarget} from 'react-dnd';
import Radium from 'radium';

import {ItemTypes, nonselfdroppablelayoutTypes} from '../constants';

const previewTarget = {
  canDrop(props, monitor) {
    const { type } = monitor.getItem();
    const isOverNestedElement = Array.isArray(props.index);
    if(nonselfdroppablelayoutTypes.includes(type) && !monitor.isOver({shallow:true})) {
      return false;
    }
    return !(isOverNestedElement && nonselfdroppablelayoutTypes.includes(type));
  },
  drop(props, monitor, component) {
    const hasDroppedOnChild = monitor.didDrop();
    const {type, element, index} = monitor.getItem();
    // Return undefined if the drop is a layout, on a layout or nested element.
    if((nonselfdroppablelayoutTypes.includes(type) && nonselfdroppablelayoutTypes.includes(props.elementType)) &&
      !Number.isInteger(props.index)
    ) {
      return;
    }
    // Handle move if dropping an element that is not new, also don't allow moving if this is a bubbled event, aka child
    if (typeof props.index !== 'undefined' && typeof index !== 'undefined' && !hasDroppedOnChild) {
      let dropIndex = props.index;

      if(!component.state.top) {
        if(Array.isArray(props.index)) {
          dropIndex[0] = props.index[0] + 1;
        } else {
          dropIndex = props.index > index ? props.index : props.index + 1;
        }
      }

      return props.handleMoveElement(
        index,
        dropIndex,
      );
    }

    let targetIndex;

    if (hasDroppedOnChild) {
      targetIndex = props.index;
    }

    props.handleNewElement(
      type,
      component.state.top ? props.index : Array.isArray(props.index) ? props.index[0] + 1 : props.index + 1,
      targetIndex,
      element,
    );

    component.setState({
      hasDropped: true,
      hasDroppedOnChild,
    });
  },
  hover(props, monitor, component) {
    const clientOffset = monitor.getClientOffset();
    const {top, height} = component.targetChildren.getBoundingClientRect();
    const y = clientOffset.y - top;
    if (!component.state.top && y < height / 2) {
      component.setState({top: true, bottom: false});
    } else if (!component.state.bottom && y > height / 2) {
      component.setState({top: false, bottom: true});
    }
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  };
}

class PreviewTarget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      top: false,
      bottom: false,
      hasDropped: false,
      hasDroppedOnChild: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.isOver && this.props.isOver) {
      this.setState({top: false, bottom: false});
    }
  }

  render() {
    const {connectDropTarget} = this.props;
    const {top, bottom} = this.state;

    return connectDropTarget(
      <div style={styles.main}>
        <span style={[styles.spacer, top && styles.open]}/>
        <div ref={ele => this.targetChildren = ele}>
          {this.props.children}
        </div>
        <span style={[styles.spacer, bottom && styles.open]}/>
      </div>
    );
  }
}

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column'
  },
  spacer: {
    height: 16,
    transition: 'all 0.2s ease-in'
  },
  open: {
    height: 100,
  }
};

export default DropTarget([ItemTypes.ELEMENT, ItemTypes.INPUT], previewTarget, collect)(Radium(PreviewTarget));
