import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { StyleSheet, css } from 'aphrodite';
import moment from 'moment';

import Wrapper from '../../containers/Wrapper';
import { Heading, SubSectionHeading, Label } from '../../components/type';
import Button from '../../components/Button';
import colors from '../../styles/colors';
import Panel from '../../containers/Panel';
import NotificationDisplay from './NotificationDisplay';

import { fetchAllReleaseNotes, updateReleaseNote, deleteReleaseNote } from '../../actions/releaseNotes';
import objectToArray from '../../utils/_objectToArray';
import { RELEASE_NOTIFICATION_MODAL } from '../../constants/modalTypes';
import { toggleModal } from '../../actions/app';


class NotificationCenter extends Component {
  constructor( props ) {
    super( props );
    this.state = {};

    this.handleSelectMenu = this.handleSelectMenu.bind( this );
  }

  componentDidMount() {
    this.props.dispatch( fetchAllReleaseNotes() );
  }

  handleSelectMenu( val, noteId ) {
    switch ( val ) {
      case 'preview':
        const previewNote = this.props.releaseNotes.find( note => note.id === noteId );
        this.props.dispatch( toggleModal( RELEASE_NOTIFICATION_MODAL, {
          preview: true,
          body: previewNote.body,
          enableOverlayClick: true
        } ) );
        return;
      case 'history':
        const historyNote = this.props.releaseNotes.find( note => note.id === noteId );
        return this.props.dispatch( updateReleaseNote(
          noteId,
          historyNote.title,
          historyNote.body,
          moment.unix( historyNote.active_at ).format( 'YYYY/MM/DD hh:mm A' ),
          true
        ) ).then( res => {
          if ( res.success === true ) {
            window.location.href = '/manage/notification-center';
          }
        } );
      case 'delete':
        return this.props.dispatch( deleteReleaseNote( noteId ) )
          .then( res => {
            if ( res.success === true ) {
            }
            window.location.href = '/manage/notification-center';
          } );
    }
  }

  render() {
    const activeNote = this.props.releaseNotes.filter( note => note.status === 'active' );
    const savedNotes = this.props.releaseNotes
      .filter( note => (note.status !== 'active' && note.status !== 'released') );
    const releasedNotes = this.props.releaseNotes
      .filter( note => note.status === 'released' );

    return (
      <DocumentTitle title="Notification Center | OneSpace Project Center">
        <div className="notification-center" style={{marginTop: 64}}>
          <Wrapper>
            <div className={css(styles.headingWrapper)}>
              <Heading color="dark">Release Notification Center</Heading>
              <a href="/manage/notification-center/create" style={{textDecoration: 'none'}}>
                <Button>New Notification</Button>
              </a>
            </div>
            <div className={css(styles.cardContainer)}>
              <div className={css(styles.innerContainer)}>
                <div style={{marginBottom: -10}}>
                  <SubSectionHeading color="grey">Currently Live</SubSectionHeading>
                </div>
                <hr />
                {activeNote.length !== 0 ? (
                    <NotificationDisplay
                      notes={activeNote}
                      type="active"
                      handleSelect={this.handleSelectMenu}/>
                  ) : (
                    <Label>Nothing is currently live.</Label>
                  )
                }

              </div>
            </div>
            <div>
              <hr />
            </div>
            <div style={{display: 'flex'}}>
              <div style={{flex: 2, marginRight: 24}}>
                <Panel title="SAVED" color="light" small={true}>
                  <div style={{padding: 16}}>
                    <NotificationDisplay
                      notes={savedNotes}
                      type="saved"
                      handleSelect={this.handleSelectMenu}/>

                  </div>
                </Panel>
              </div>
              <div style={{flex: 1}}>
                <Panel title="HISTORY" color="light" small={true}>
                  <div style={{padding: 16}}>
                    <NotificationDisplay
                      notes={releasedNotes}
                      type="past"
                      handleSelect={this.handleSelectMenu}/>
                  </div>
                </Panel>
              </div>
            </div>
          </Wrapper>
        </div>
      </DocumentTitle>
    );
  }
}

const styles = StyleSheet.create( {
  cardContainer: {
    padding: 16,
    boxSizing: 'border-box',
    border: `1px solid ${colors.border}`,
    borderRadius: 4,
    background: colors.background,
  },
  innerContainer: {
    padding: '24px 16px',
    boxSizing: 'border-box',
    border: `1px solid ${colors.border}`,
    borderRadius: 4,
    background: 'white'
  },
  headingWrapper: {
    display: 'flex',
    alignItem: 'flex-end',
    justifyContent: 'space-between',
    paddingBottom: 20
  }
} );

function select( state ) {
  const releaseNotes = objectToArray( state.releaseNotes.items );
  const hasFetchedNotes = (!state.releaseNotes.isFetching && state.releaseNotes.hasFetched);
  return {
    releaseNotes,
    hasFetchedNotes
  };
}

export default connect( select )( NotificationCenter );