import React from 'react';
import PropTypes from 'prop-types';

import colors from '../styles/colors';
import InlineList from '../components/InlineList';
import { Regular, Label } from '../components/type';

/**
 * Toast
 */
const Toast = ({ label, type, handleClear, bold }) => (
  <div style={styles.toast(type)}>
    <InlineList spacing={16}>
      <div style={styles.label}>
        {!!bold ? <Label color="white">{label}</Label> : <Regular color="white">{label}</Regular>}
      </div>
      {!!handleClear &&
        <Label handleClick={handleClear} color="white">Clear</Label>
      }
    </InlineList>
  </div>
);

/* Styles */
const styles = {
  toast: type => ({
    padding: 16,
    boxSizing: 'border-box',

    background: type === 'success' ? colors.positive : colors.negative,
    borderRadius: 4,
  }),
  label: {
    flex: 1,
  },
};

/* PropTypes */
Toast.propTypes = {
  label: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['success', 'error']),
  handleClear: PropTypes.func,
};
Toast.defaultProps = {
  type: 'success',
};

export default Toast;
