import React from 'react';

/**
 * HelperPanelDescription
 * Project's page helper panel description
 */
const HelperPanelDescription = () => (
  <div>
    <div>
      This page contains a comprehensive list of all of your projects. From here, you can
      edit the project’s name, description and designated admin.
    </div>
    <div>
      By default, this page shows a list of your active projects. You can use the tabs at
      the top to filter by status:
    </div>
    <ul>
      <li>
        <strong>Active:&nbsp;</strong>
        The project has been launched, and workflow items are open and available for
        contributors to work on.
      </li>
      <li>
        <strong>Inactive:&nbsp;</strong>
        The project has been launched, but there aren’t any workflow items open and
        available for contributors to work on. This typically means all workflow items
        are either staged or completed.
      </li>
      <li>
        <strong>Drafts:&nbsp;</strong>
        The project has not been launched yet.
      </li>
    </ul>
  </div>
);

export default HelperPanelDescription;
