import React from 'react';

import Input from '../../Form/Input';
import InlineInput from '../../Form/InlineInput';
import Switch from '../../Form/Switch';
import Settings from '../Settings';
import SupportRoleOptions from './SupportRoleOptions';

class Email extends React.Component {
  componentDidMount() {
    this.inline.focus();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.selected && this.props.selected) {
      this.inline.focus();
      this.props.handleChangeOutput(this.props.data.output);
    }
  }

  render() {
    const {data = {}, selected, updateElement, handleSelectInput, outputError, isSupportRole, handleChangeOutput} = this.props;
    return (
      <div style={styles.main}>
        <div style={styles.label}>
          <InlineInput
            ref={ele => this.inline = ele}
            value={data.label}
            selected={selected}
            handleChange={label => updateElement({label})}
            placeholder="Add a label"
            fontSize={16}
            fontWeight={800}
            disabled={!selected}
            onFocus={() => handleSelectInput('label')}
          />
        </div>

        <div style={styles.label}>
          <InlineInput
            value={data.instructions}
            selected={selected}
            handleChange={instructions => updateElement({instructions})}
            placeholder="Add instructions or tips for the contributors"
            fontSize={13}
            fontWeight={600}
            disabled={!selected}
            onFocus={() => handleSelectInput('instructions')}
          />
        </div>
        <Input
          value={data.value}
          placeholder="example@account.com"
          onChange={value => updateElement({value})}
          onFocus={() => handleSelectInput('value')}
          disabled={!selected}
        />
        <Settings show={selected} customHeight="auto">
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Input
              value={data.output}
              label="Output Name"
              onChange={output => {
                handleChangeOutput(output);
                updateElement({output});
              }}
              maxWidth={280}
              error={outputError}
            />
            <Switch
              label="Required"
              isOn={data.required}
              handleToggle={() => updateElement({required: !data.required})}
            />
          </div>
          {isSupportRole &&
          <SupportRoleOptions
            data={data}
            updateElement={updateElement}
            handleSelectInput={handleSelectInput}
          />
          }
        </Settings>
      </div>
    );
  }
}

const styles = {
  main: {
    paddingTop: 8
  },
  label: {
    marginBottom: 8
  }
};

export default Email;
