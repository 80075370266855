import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import colors from '../styles/colors';
import { Title } from '../components/type';

/**
 * IconPanel
 * Large button with an icon and label
 * Hovering will slide the icon off screen
 */
const IconPanel = ({ icon, label, href }) => href ?
  <a href={href} className={css(styles.iconPanel)}><Content icon={icon} label={label}/></a> :
  <div className={css(styles.iconPanel)}><Content icon={icon} label={label}/></div>;

const Content = ({ icon, label }) => (
  <div>
    <div className={`icon-panel-icon ${css(styles.animatedElement)}`}>
      <i className={`fa fa-${icon} ${css(styles.iconPanelIcon)}`} style={{ margin: 8 }}/>
    </div>
    <div className={`icon-panel-label ${css(styles.animatedElement)}`}>
      <Title className="icon-panel-label-text" color="primary"  center={true}>{label}</Title>
    </div>
  </div>
);

/* Styles */
const styles = StyleSheet.create({
  iconPanel: {
    position: 'relative',
    display: 'block',
    flex: 1,
    width: '100%',
    paddingTop: 16,
    paddingBottom: 16,
    overflow: 'hidden',
    textAlign: 'center',
    color: colors.primary(),
    background: 'white',
    border: `2px solid #ebebeb`,
    borderRadius: 4,
    boxSizing: 'border-box',
    textDecoration: 'none',
    transition: 'all 0.2s ease',
    ':hover': {
      background: colors.secondary(),
      color: colors.dark,
      cursor: 'pointer'
    },
    ':hover .icon-panel-icon': {
      top: -60
    },
    ':hover .icon-panel-label': {
      top: -25,
    },
    ':hover .icon-panel-label-text': {
      color: `${colors.dark} !important`,
      cursor: 'pointer',
    }
  },
  iconPanelIcon: {
    height: 50,
    width: 50,
    backgroundColor: colors.secondary(),
    borderRadius: '50%',
    lineHeight: '50px',
    color: 'white',
    fontSize: 22
  },
  animatedElement: {
    transition: 'all 0.2s ease',
    position: 'relative',
    top: 0
  }
});

/* PropTypes */
IconPanel.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  href: PropTypes.string,
};

export default IconPanel;