import React from 'react';
import { SmallDescription, Title } from '../../../../components/type';
import InlineList from '../../../../components/InlineList';
import IconButton from '../../../../components/IconButton';

const CollapsedInstance = ({ instance, handleExpanding, collapsed, originalPluginName }) => (
    <div>
      <InlineList>
        <div style={{flex: 18}}>
          {!!instance.pluginId &&
            <div style={{marginBottom: 20}}>
              <Title>{instance.name}</Title>
              <SmallDescription color="light" italic="true">
                {originalPluginName ? `This is an instance of ${originalPluginName} - Version ${instance.version}` : `Version ${instance.version}`}
              </SmallDescription>
            </div>
          }
        </div>
        <div style={{ flex: 1 }}>
          <IconButton
            disabled={false}
            icon={collapsed ? 'chevron-down' : 'chevron-up'}
            label="Setup New Plugin"
            handleClick={() => {
              handleExpanding(collapsed ? false : true);
            }}>
            <span style={{textDecoration: 'underline !important'}}>Details</span>
          </IconButton>
        </div>
      </InlineList>
    </div>
);

export default CollapsedInstance;