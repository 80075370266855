import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

import Label from './Label';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool
};

class Input extends Component {
  focus() {
    this.input.focus();
  }
  render() {
    const {
      value,
      onChange,
      placeholder,
      label,
      disabled,
      onFocus,
      isInline,
      isHidden,
      isCollapsed,
      maxWidth,
      maxLength,
      minLength,
      mockTextArea,
      error
    } = this.props;
    return (
      <div style={[styles.main, isCollapsed && styles.collapsed]}>
        {label && <Label label={label}/>}
        {mockTextArea ?
          <textarea
            ref={ele => this.input = ele}
            value={value || ''}
            onChange={e => onChange(e.target.value)}
            placeholder={placeholder}
            onFocus={onFocus}
            disabled={disabled || isInline || isHidden}
            style={[styles.input, isInline && styles.inline, isHidden && styles.hidden, maxWidth && {maxWidth}, styles.textArea, error && styles.error]}
          />
          :
          <input
            ref={ele => this.input = ele}
            disabled={disabled || isInline || isHidden}
            style={[styles.input, isInline && styles.inline, isHidden && styles.hidden, maxWidth && {maxWidth}, error && styles.error]}
            value={value || ''}
            onChange={e => onChange(e.target.value)}
            placeholder={placeholder}
            onFocus={onFocus}
            maxLength={maxLength}
            minLength={minLength}
          />
        }
        {error &&
        <div style={styles.errorMessage}>
          {error}
        </div>
        }
      </div>
    );
  }
}

const styles = {
  main: {
    marginBottom: 16,
    flex: 1,
    width: '100%'
  },
  collapsed: {
    marginBottom: 2
  },
  input: {
    border: '2px solid #242726',
    height: 32,
    boxSizing: 'border-box',
    opacity: 1,
    transition: 'all 0.2s ease-in',
    ':focus': {
      outline: 'none'
    }
  },

  error: {
    border: '2px solid #f94d1f',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },

  errorMessage: {
    background: '#f94d1f',
    color: 'white',
    padding: 4,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    fontSize: 12,
    maxWidth: 280
  },

  inline: {
    background: 'none',
    border: 'none'
  },
  hidden: {
    opacity: 0
  },

  textArea: {
    height: 120
  }
};

Input.propTypes = propTypes;
export default Radium(Input);
