import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import colors from '../styles/colors';
import { Caption } from '../components/type';

/**
 * InlineSearch
 * Borderless search bar with a search icon
 * Used inside components and fills width
 */
class InlineSearch extends Component {
  render() {
    const { value, handleChange, handleKeyDown } = this.props;

    return (
      <div className={css(styles.inlineSearch)}>
        {/* Search Input */}
        <Caption>
          <input
            ref={(input) => {
              this.input = input;
            }}
            className={css(styles.input)}
            value={value}
            onChange={e => handleChange(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Search"
          />
        </Caption>
      </div>
    );
  }
}

/* Styles */
const styles = StyleSheet.create({
  inlineSearch: {
    display: 'block',
    paddingLeft: 8,
    paddingRight: 8,
  },
  searchIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 32,
    width: 32,
    color: colors.light,
    fontSize: 18,
  },
  input: {
    width: '100%',
    flex: 1,
    height: 32,
    border: 'none',
    boxSizing: 'border-box',
    fontSize: '1em',
    ':focus': {
      outline: 'none',
    },
  },
});

/* InlineSearch */
InlineSearch.propTypes = {
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleKeyDown: PropTypes.func,
};
InlineSearch.defaultProps = {
  handleKeyDown: () => {
  }
};

export default InlineSearch;
