import update from 'immutability-helper';
import { updateState } from '../../../../../utils/reducerHelpers';

import {
  ADD_LIBRARY_ELEMENT_REQUEST,
  ADD_LIBRARY_ELEMENT_FAILURE,
  ADD_LIBRARY_ELEMENT_SUCCESS,
  FETCH_LIBRARY_ELEMENTS_REQUEST,
  FETCH_LIBRARY_ELEMENTS_SUCCESS,
  FETCH_LIBRARY_ELEMENTS_FAILURE,
  DELETE_LIBRARY_ELEMENT_REQUEST,
  DELETE_LIBRARY_ELEMENT_SUCCESS,
  DELETE_LIBRARY_ELEMENT_FAILURE,
  UPDATE_LIBRARY_ELEMENT_REQUEST,
  UPDATE_LIBRARY_ELEMENT_SUCCESS,
  UPDATE_LIBRARY_ELEMENT_FAILURE,
  ELEMENT_CHANGED,
} from '../actionTypes';

const initialState = {
  isFetching: false,
  hasFetched: false,
  items: {},
  elementChanged: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_LIBRARY_ELEMENTS_REQUEST:
      return updateState.fetchRequest(state);
    case FETCH_LIBRARY_ELEMENTS_FAILURE:
      return updateState.fetchFailure(state);
    case FETCH_LIBRARY_ELEMENTS_SUCCESS:
      return updateState.fetchSuccess(state, action.elements);
    case ADD_LIBRARY_ELEMENT_REQUEST:
      return state;
    case ADD_LIBRARY_ELEMENT_FAILURE:
      return updateState.createFailure(state, action.id);
    case ADD_LIBRARY_ELEMENT_SUCCESS:
      // Change type to correct element type
      action.element.type = action.elementType;
      return updateState.createSuccess(state, action.element);
    case DELETE_LIBRARY_ELEMENT_REQUEST:
      return updateState.deleteRequest(state, action.id);
    case DELETE_LIBRARY_ELEMENT_FAILURE:
      return updateState.deleteFailure(state, action.id);
    case DELETE_LIBRARY_ELEMENT_SUCCESS:
      return updateState.deleteSuccess(state, action.id);
    case UPDATE_LIBRARY_ELEMENT_REQUEST:
      return updateState.updateRequest(state, action.id);
    case UPDATE_LIBRARY_ELEMENT_FAILURE:
      return updateState.updateFailure(state, action.id);
    case UPDATE_LIBRARY_ELEMENT_SUCCESS:
      return updateState.updateSuccess(state, action.id, action.element);
    case ELEMENT_CHANGED:
      return update(state, { elementChanged: { $set: action.change }});
    default:
      return state;
  }
}
